import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../../../Variables/Global";
import { toast } from "react-toastify";
import axiosInstance from '../../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../../utils/utils';
import { AiOutlineFileExcel } from "react-icons/ai"

const CustomerAccountStatment = () => {

    const pId = useParams()
    const [accountStatment, setAccountStatment] = useState([])
    const [permissions, setPermissions] = useState([]);

    const columns = [
        {
            name: "Invoice",
            // selector: row => row.performa,
            selector: row => {
                if (row.commercialInvoice == null || row.commercialInvoice == '') {
                    return (
                        <>
                            <span className="text-wrap">#{row.performa}</span>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div>
                                <span className="text-wrap">#{row.performa}</span>
                                <br />
                                <span className="text-wrap">#{row.commercialInvoice}</span>
                            </div>
                        </>
                    )
                }
            },
            width: "160px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },

        {
            name: 'Shipment',
            selector: row => {
                if (row.blReferenceNo == null) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span> </span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 0) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Pending</b></span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 20) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>In Progress</b></span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 30) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#292b2c', backgroundColor: '#d3d5d6', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 35) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#41b6d9', backgroundColor: '#eff9fc', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 40) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#936b16', backgroundColor: '#fdf0d3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 45) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 50) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 60) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
            }, conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
            width: "160px",
        },
        // {
        //     name: "Commercial Invoice",
        //     selector: row => row.commercialInvoice,
        //     width: "160px",
        //     conditionalCellStyles: [
        //         {
        //             when: row => row.debit != 0,
        //             style: {
        //                 backgroundColor: '#fbdde2',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => row.credit != 0,
        //             style: {
        //                 backgroundColor: '#def8e6',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
        //             style: {
        //                 backgroundColor: '#e7ddfb',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //     ],
        // },
        {
            name: "Description",
            selector: row => (row.commission != null && row.commission > 0 ?
                <>
                    <span className="text-wrap" style={{ whiteSpace: 'pre-wrap' }}>
                        {(row.fcl != null ? row.fcl : "") + " " + (row.description != null ? row.description : "")}
                        <br />
                        Commission: {row.commission}
                    </span>
                </>
                : (row.otherCharges != null && row.otherCharges > 0 ?
                    <>
                        <span className="text-wrap" style={{ whiteSpace: 'pre-wrap' }}>
                            {row.description != null ? row.description : ""}
                            {/* <br/> */}
                            {/* Commission: {row.commission} */}
                        </span>
                    </>
                    : <>
                        <span className="text-wrap" style={{ whiteSpace: 'pre-wrap' }}>
                            {((row.fcl != null ? row.fcl : "") + " " + (row.description != null ? row.description : ""))}
                        </span>
                    </>)),
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Quantity",
            selector: row => (row.price == null || row.price == 0) ? "" : (row.quantity == null ? "" : row.quantity.toLocaleString() + " " + row.unit),
            width: "140px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Price",
            // selector: row => row.price == null ? "" : (row.price.toLocaleString() + " " + currency.currencySuffix),
            selector: row => (row.price == null || row.price == 0) ? "" : row.price.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Debit",
            // selector: row => row.debit == null ? "" : (row.debit.toLocaleString() + " " + currency.currencySuffix),
            selector: row => row.debit == null ? "" : row.debit.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Credit",
            // selector: row => row.credit == null ? "" : (row.credit.toLocaleString() + " " + currency.currencySuffix),
            selector: row => row.credit == null ? "" : row.credit.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Balance",
            // selector: row => row.balance == null ? "" : (row.balance.toLocaleString() + " " + currency.currencySuffix),
            selector: row => row.balance == null ? "" : row.balance.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        // {
        //     name: "Notes",
        //     selector: row => row.notes
        // },
        {
            name: "Supplier Account",
            // selector: row => row.supplierAccount,
            selector: row => {
                if (row.supplierAccount == null || row.supplierAccount == '') {
                    return (
                        <>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div>
                                <span className="text-wrap">{row.supplierName} - {row.supplierAccount} - <b>#{row.supplierInvoice}</b></span>
                                {/* <br /> */}
                                {/* <span className="text-wrap"></span> */}
                            </div>
                        </>
                    )
                }
            },
            // width: "160px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Notes",
            // selector: row => row.supplierAccount,
            selector: row => {
                return (
                    <>
                        <div>
                            <span className="text-wrap">{row.notes ?? ''}</span>
                        </div>
                    </>
                )
            },
            // width: "160px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        // {
        //     name: "Supplier Invoice",
        //     selector: row => row.supplierInvoice,
        //     width: "160px", conditionalCellStyles: [
        //         {
        //             when: row => row.debit != 0,
        //             style: {
        //                 backgroundColor: '#fbdde2',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => row.credit != 0,
        //             style: {
        //                 backgroundColor: '#def8e6',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
        //             style: {
        //                 backgroundColor: '#e7ddfb',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //     ],
        // },
        {
            name: "Date",
            selector: row => row.date != null ? row.date.split("T")[0] : row.createdDate.split("T")[0],
            width: "120px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
    ]

    const GetAccountStatment = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'CustomerAccountStatment/GetCustomerAccountStatement/' + accountId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccountStatment(response.data);
            })
            .catch(function (error) {
                console.log(error);
                toast.warning("Something went wrong");
                toast.warning(error);
            });

    }

    const [accountInfo, setAccountInfo] = useState("");
    const GetAccountInfo = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'CustomerAccount/GetCustomerAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setAccountInfo(response.data);
                GetCurrency(response.data.currencyId)
                document.title = response.data.customerName + ' - ' + response.data.customerAccount1;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [currency, setCurrency] = useState("");
    const GetCurrency = (currencyId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'Currency/GetCurrency/' + currencyId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const DownloadCSV = (accountId) => {
        // var axios = require('axios');

        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'CustomerAccountStatment/DownloadCustomerAccountCSV/' + accountId,
            responseType: "arraybuffer",
            headers,
        };

        axiosInstance(config)
            .then(function (response) {
                const outputFilename = `${accountInfo.customerAccount1} Customer Account Statment.xlsx`;

                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const DownloadPDF = (accountId) => {
        // var axios = require('axios');

        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'CustomerAccountStatment/DownloadCustomerAccountPDF/' + accountId,
            responseType: "arraybuffer",
            headers,
        };

        axiosInstance(config)
            .then(function (response) {
                const outputFilename = `${accountInfo.customerAccount1} Customer Account Statment.pdf`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAccountStatment(pId.AccountId);
        GetAccountInfo(pId.AccountId);
    }, [])
    return (
        <>
            {permissions.viewCustomerStatement == null || permissions.viewCustomerStatement == false ? (
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <h1>You Have No Permission</h1>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="page-title-box">
                                    <h4>Clients</h4>
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                        <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                        <li className="breadcrumb-item active">{accountInfo.customerName} - {accountInfo.customerAccount1}</li>
                                        {/* <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                                    <li className="breadcrumb-item active">{account.supplierAccount1}</li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='row mb-3'>
                                            <div className='col-12 col-md-6'>
                                                <h3 className="card-title">{accountInfo.customerName}</h3>
                                                <h4 className="card-title">{accountInfo.customerAccount1} Statement of Account</h4>
                                            </div>
                                            <div className="col-6 text-right">
                                                <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Payments"} className="btn btn-primary mr-3">View Payments</Link>
                                                    <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Discounts"} className="btn btn-danger mr-3">View Discounts</Link>
                                                    <button onClick={() => DownloadCSV(pId.AccountId)} className="btn btn-primary mr-3"><AiOutlineFileExcel /> Export CSV</button>
                                                    <button onClick={() => DownloadPDF(pId.AccountId)} className="btn btn-primary"><AiOutlineFileExcel /> Export PDF</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <hr />
                                            <DataTable
                                                columns={columns}
                                                data={accountStatment} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default CustomerAccountStatment;