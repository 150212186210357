import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from "react-select";
import axiosInstance from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { Global } from '../../Variables/Global';

const LowProformaDataModal = ({ show, handleClose, handleSave, Invoice }) => {

    useEffect(() => {
        setProducts(Invoice.products);
    }, [Invoice.products]);

    const [products, setProducts] = useState(Invoice.products);

    const handlePriceChange = (e, index) => {
        const newPrice = parseFloat(e.target.value); // Convert the input value to a number
        const updatedProducts = products.map((product, i) => {
            if (i === index) {
                const total = newPrice * product.productQuantity;
                return { ...product, unitPrice: newPrice, totalPrice: total };
            } else {
                return product;
            }
        });
        setProducts(updatedProducts);
    };

    const handleGenerate= () => {
        handleSave(products);
    };

    return (
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Please set the below required data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <strong>Invoice Number:</strong> {Invoice.invoiceNumber}
                    </div>
                    <div>
                        <ul style={{ listStyleType: 'none', padding: '0' }}>
                            {Invoice.products.map((product, index) => (
                                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <div style={{ flex: '1', marginRight: '10px', fontWeight: 'bold' }}>
                                        {product.productName}
                                    </div>
                                    <div style={{ flex: '1', marginRight: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Original Price:</span> {Invoice.currencySuffix || ""} {product.unitPrice.toLocaleString()}
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <input
                                            type="text"
                                            placeholder="New Price"
                                            style={{ width: '100%', padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                                            onChange={(e) => handlePriceChange(e, index)}
                                            title="Enter a new price for this product"
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button variant="primary" onClick={handleGenerate}>
                    Generate
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LowProformaDataModal;