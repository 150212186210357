import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Global } from "../../../../Variables/Global";
import ListAccountSupplier from "../SupplierAccounts/ListAccountSupplier";
import ManageAccountSupplier from "../SupplierAccounts/ManageAccountSupplier";
import axiosInstance from '../../../../api/axiosConfig';

const ViewSupplier = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [supplierName, setSupplierName] = useState("");
    const [setPhoneNumber] = useState("");
    const [setAddress] = useState("");
    const [setCompanyId] = useState(0);

    const [showSupplierAccountList, setShowSupplierAccountList] = useState(false)
    const [supplierAccountId, setSupplierAccountId] = useState(0)

    const GetSupplier = (supplierId) => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers/" + supplierId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierName(response.data.supplierName)
                setPhoneNumber(response.data.phone)
                setAddress(response.data.address)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        if (pId.SupplierId != "0") {
            GetSupplier(pId.SupplierId)
        }
        else {
            navigate("/Accounting/Supplier")
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Suppliers</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                    <li className="breadcrumb-item active">{supplierName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">Supplier: {supplierName}</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Accounting/Supplier" className="btn btn-secondary mr-3">Cancel</Link>
                                        </div>

                                    </div>
                                    <div className="mb-3 row">
                                        <hr></hr>
                                        <div className="col-12">
                                            {showSupplierAccountList == false ? (<ListAccountSupplier supplierId={pId.SupplierId} setShowSupplierAccountList={setShowSupplierAccountList} setSupplierAccountId={setSupplierAccountId} />) : (<ManageAccountSupplier supplierId={pId.SupplierId} setSupplierAccountId={setSupplierAccountId} supplierAccountId={supplierAccountId} setShowSupplierAccountList={setShowSupplierAccountList} />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>)
}
export default ViewSupplier