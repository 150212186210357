import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Global } from "../../../../Variables/Global";
import moment from 'moment';
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from '../../../../api/axiosConfig';

const ManageCustomerOrder = (props) => {
     
    const pId = useParams();
    const navigate = useNavigate();
    const [pageType, setPageType] = useState("Add")

    const [date, setDate] = useState()
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [commission, setCommission] = useState(0.0)
    const [notes, setNotes] = useState("")

    const GetCustomerOrder = (customerOrderId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrder/" + customerOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                var _date = response.data.date
                setInvoiceNumber(response.data.invoiceNumber)
                setNotes(response.data.notes)
                setCommission(response.data.commission)
                // setDate(_date.format("yyyy-MM-DD"))
                setDate(_date.split("T")[0])
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValidCustomerOrder()) {
            const body = { 
                invoiceNumber: invoiceNumber, 
                date: date, 
                commission: commission, 
                notes: notes, 
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!"), 
                customerAccountId: pId.AccountId 
            }
            if (pId.OrderId == "0") { //Add Customer Address
                var config = {
                    method: 'post',
                    url: "CustomerOrder/AddCustomerOrder",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            // navigate("/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Orders")
                            navigate("/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/View/Order/" + response.data)
                            toast.success("Order Added Successfully")

                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            } else { // Update Customer Address
                var config = {
                    method: 'put',
                    url: "CustomerOrder/EditCustomerOrder/" + pId.OrderId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            navigate("/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Orders")
                            toast.success("Order Updated Successfully")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }

    }



    const [customer, setCustomer] = useState("")
    const GetCustomer = (customerId) => {
        var config = {
            method: 'get',
            url: "Customer/GetCustomers/" + customerId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setCustomer(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [account, setAccount] = useState("")
    const GetAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccount/" + accountId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data);
                GetCurrency(response.data.currencyId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const IsValidCustomerOrder = () => {
        if (invoiceNumber == "" && date == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (invoiceNumber == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (date == "") {
            toast.error("Enter Date");
            return false;
        }
        return true;
    }

    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        var _date = moment();
        setDate(_date.format("yyyy-MM-DD"))
        if (pId.OrderId != 0) {
            GetCustomerOrder(pId.OrderId)
            setPageType("Edit");
        }

        GetCustomer(pId.CustomerId)
        GetAccount(pId.AccountId)
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Client</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + account.customerAccountId + "/Orders"}>{customer.customerName} - {account.customerAccount1} Orders</Link>
                                    </li>
                                    {invoiceNumber != null && invoiceNumber != "" &&
                                        <li className="breadcrumb-item active">#{invoiceNumber}</li>
                                    }
                                    <li className="breadcrumb-item active">{pageType}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Client Order</h4>
                                            <h3>{customer.customerName} - {account.customerAccount1}</h3>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link className="btn btn-secondary mr-3" to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Orders"}>Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Invoice Number</label>
                                            <input className="form-control" type="text" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Date</label>
                                            <input className="form-control" type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label className="col-md-12 col-form-label">Commission per 1 FCL (Optional)</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-text">{currency.currencySuffix}</div>
                                                <input type="number" className="form-control" value={commission}
                                                    onChange={(e) => {
                                                        setCommission(e.target.value);
                                                    }}>
                                                </input>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <label className="col-md-12 col-form-label">Notes</label>
                                            <textarea className="form-control" type="text" value={notes} onChange={(e) => setNotes(e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default ManageCustomerOrder