import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import * as ProformaInvoiceService from '../../api/ProformaInvoiceService';
import dateFormat from 'dateformat';
import { MetaTags } from 'react-meta-tags'
import axiosInstance from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import MissingDataModal from './MissingDataModal';
import LowProformaDataModal from './LowProformaDataModal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PrintInvoice = () => {
    const pId = useParams();
    const [invoice, setInvoice] = useState({
        invoiceNumber: "",
        products: [],
        currencySuffix: "",
        shippingCosts: [],
        supplierInvoices: [],
    });
    const [orginalInvoice, setOrginalInvoice] = useState({
        invoiceNumber: "",
        products: [],
        currencySuffix: "",
        shippingCosts: [],
        supplierInvoices: [],
    });

    //const loadInvoice = (printInvoiceId) => {
    //    return ProformaInvoiceService.Get({ printInvoiceId })
    //        .then(data => {
    //            setInvoice(data);
    //            setOrginalInvoice(data); //inorder to get the original pricing
    //        }).catch(err => {
    //            alert('Failed to load invoice');
    //        })
    //}

    const [missingInvoice, setMissingInvoice] = useState({})
    const [showModal, setShowModal] = useState(false);
    const IsValidProformaInvoice = (printInvoiceId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/ValidateCustomerOrderProformaInvoice",
            headers: {},
            params: { printInvoiceId }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.data.validData === true) {
                    setInvoice(response.data.data)
                    setOrginalInvoice(response.data.data);
                }
                else {
                    //Handle the missing data
                    setShowModal(true);
                    setMissingInvoice(response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const IsValidCommercialInvoice = (printInvoiceId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/ValidateCustomerOrderCommercialInvoice",
            headers: {},
            params: { printInvoiceId }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.data.validData === true) {
                    setInvoice(response.data.data)
                    setOrginalInvoice(response.data.data); //inorder to get the original pricing
                }
                else {
                    //Handle the missing data
                    setShowModal(true);
                    setMissingInvoice(response.data.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleModalClose = () => {
        setShowModal(false);
        setMissingInvoice({});
    }
    const handleSave = () => {
        console.log('User chose to save in the missing data');
        setShowModal(false);
        // Add logic to handle "Yes" button click
    };


    useEffect(() => {
        if (pId.InvoiceType === "Proforma")
            IsValidProformaInvoice(pId.PrintInvoiceId)
        if (pId.InvoiceType === "Commercial")
            IsValidCommercialInvoice(pId.PrintInvoiceId)
    }, []);

    //Print the Invoice
    const printInvoice = () => {
        window.print()
    }

    const [showLowModal, setShowLowModal] = useState(false);
    const lowInvoice = () => {
        setShowLowModal(true);
    }

    const handleLowModalClose = () => {
        setShowLowModal(false);
    }

    const handleGenerate = (updatedProducts) => {
        setInvoice(prevState => ({
            ...prevState,
            products: updatedProducts
        }));
        setShowLowModal(false); // Close the modal
    };

    const totalPrice = () =>
        invoice.products.reduce(
            (sum, product) => sum + product.totalPrice,
            0
        )
    const totalQTY = () =>
        invoice.products.reduce(
            (sum, product) => sum + product.productQuantity,
            0
        )
    const totalFCL = () =>
        invoice.products.reduce(
            (sum, product) => sum + product.fcl,
            0
        )
    const totalPricCoveringShippingCosts = () =>
        totalPrice() + invoice.shippingCosts.reduce(
            (sum, shippingCost) => sum + shippingCost.totalAmount,
            0
        )


    const downloadAsPDF = async () => {
        try {
            const input = document.getElementById('invoice');

            if (!input) {
                throw new Error('Invoice element not found');
            }

            const canvas = await html2canvas(input, {
                scale: 2, // Increase resolution
                useCORS: true // Use CORS if there are images from different origins
            });

            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait', // or 'landscape'
                unit: 'mm',
                format: 'a4' // or other formats like 'letter'
            });

            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const margin = 10; // 10 mm margin
            const pdfHeight = (imgProps.height * (pdfWidth - 2 * margin)) / imgProps.width;

            pdf.addImage(imgData, 'PNG', margin, 0, pdfWidth - 2 * margin, pdfHeight);
            pdf.save(`invoice-${invoice.invoiceNumber}.pdf`);

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };


    return (

        <div className="page-content">
            <div className="container-fluid">
                <div className="d-print-none d-flex">
                    <div className="mr-3">
                        <Link to="#" onClick={lowInvoice} className="btn btn-success waves-effect waves-light mb-2">
                            Generate Low Proforma
                        </Link>
                    </div>
                    <div className="mr-3">
                        <Link to="#" onClick={downloadAsPDF} className="btn btn-success waves-effect waves-light mb-2">
                            Download as PDF
                        </Link>
                    </div>
                    {/*<div>*/}
                    {/*    <Link to="#" onClick={printInvoice} className="btn btn-success waves-effect waves-light mb-2">*/}
                    {/*        Print*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
                <React.Fragment>
                    <MetaTags>
                        <title>Invoice | WMH Trading Group</title>
                    </MetaTags>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <div style={{ marginLeft: '10px', marginRight: '10px' }} id="invoice">
                                        <Row>
                                            <Col xs="12">
                                                <div className="invoice-title mt-3 text-center"> {/* Added text-center class */}
                                                    <h4 className="float-end">
                                                        <img src="/images/wmh-logo.png" alt="logo" height="80" />
                                                    </h4>
                                                    <h4 className="font-size-30 mt-1"><strong>{pId.InvoiceType} INVOICE</strong></h4>
                                                </div>
                                                <Row className="mt-1"> {/* Added justify-content-center class */}
                                                    <Col xs="8" className="mt-1">
                                                        <address style={{ display: 'block' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ minWidth: '80px', flex: '0 0 auto' }}>Ref. No:</span>
                                                                <strong style={{ marginLeft: '10px' }}>#{invoice.invoiceNumber}</strong>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                <span style={{ minWidth: '80px', flex: '0 0 auto' }}>BL. No:</span>
                                                                <strong style={{ marginLeft: '10px' }}>#{invoice.blNumber}</strong>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                                <span style={{ minWidth: '80px', flex: '0 0 auto' }}>Date:</span>
                                                                <span style={{ marginLeft: '10px' }}>{dateFormat(invoice.date, "dddd, mmmm dd,yyyy")}</span>
                                                            </div>
                                                        </address>
                                                    </Col>
                                                </Row>

                                                <hr />
                                                <Row className="mt-1">
                                                    <Col xs="6">
                                                        <div className="bg-light p-2 rounded">
                                                            <strong>BILL TO</strong><br />
                                                            <span style={{ textTransform: 'uppercase' }}>{invoice.billingConsignee ?? ""}</span><br />
                                                            <span style={{ textTransform: 'uppercase' }}>{invoice.billingAddress ?? ""}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6">
                                                        <div className="bg-light p-2 rounded">
                                                            <strong>SHIP TO</strong><br />
                                                            <span style={{ textTransform: 'uppercase' }}>{invoice.shippingConsignee ?? ""}</span><br />
                                                            <span style={{ textTransform: 'uppercase' }}>{invoice.shippingAddress ?? ""}</span>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <hr />
                                                <Row className="mt-1">
                                                    <Col xs="6">
                                                        <div className="bg-light p-2 rounded">
                                                            <div className="row mb-1">
                                                                <div className="col-3"><strong>COUNTRY OF ORIGIN:</strong></div>
                                                                <div className="col-9">{invoice.countryOfOrigin}</div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-3"><strong>PORT OF LOADING:</strong></div>
                                                                <div className="col-9">{invoice.portOfLoading}</div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-3"><strong>DATE OF LOADING:</strong></div>
                                                                <div className="col-9">{invoice.dateOfLoading}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-3"><strong>PAYMENT TERMS:</strong></div>
                                                                <div className="col-9">{invoice.paymentTermsName}</div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6">
                                                        <div className="bg-light p-2 rounded">
                                                            <div className="row mb-1">
                                                                <div className="col-4"><strong>INCOTERM:</strong></div>
                                                                <div className="col-8">{invoice.incoterm}</div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-4"><strong>COUNTRY OF DESTINATION:</strong></div>
                                                                <div className="col-8">{invoice.countryOfDistination}</div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-4"><strong>PORT OF DISCHARGE:</strong></div>
                                                                <div className="col-8">{invoice.portOfDischarge}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-4"><strong>FINAL PLACE OF DELIVERY:</strong></div>
                                                                <div className="col-8">{invoice.finalPlaceOfDelivery}</div>
                                                            </div>
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div>
                                                    <div className="">
                                                        <div className="table-responsive">
                                                            <table className="table table-sm">
                                                                <thead className="bg-light">
                                                                    <tr>
                                                                        <th className="text-center" style={{ width: '15%' }}>PACKING<br />(Carton)</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>QUANTITY</th>
                                                                        <th>PRODUCT DESCRIPTION</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>UNIT PRICE<br />FOB ({invoice.currencySuffix})</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>AMOUNT<br />({invoice.currencySuffix})</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {invoice.products.map((p, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-center" >{p.packing || "-"}</td>
                                                                            <td className="text-center">{p.productQuantity.toLocaleString()} {p.unit || "-"}</td>
                                                                            <td><strong>{p.productName}</strong></td>
                                                                            <td className="text-center">{p.unitPrice.toLocaleString()}</td>
                                                                            <td className="text-center">{p.totalPrice.toLocaleString()}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <table className="table table-sm mt-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" style={{ width: '15%' }}></th>
                                                                        <th className="text-center" style={{ width: '15%' }}></th>
                                                                        <th></th>
                                                                        <th className="text-first" style={{ width: '10%' }}>FOB</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>{invoice.currencySuffix || ""} {totalPrice().toLocaleString()}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {invoice.shippingCosts.map((sc, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-center" style={{ width: '15%' }}></td>
                                                                            <td className="text-center" style={{ width: '15%' }}></td>
                                                                            <td></td>
                                                                            <td className="text-first" style={{ width: '10%' }}>{sc.name}</td>
                                                                            <td className="text-center" style={{ width: '15%' }}>{sc.totalAmount}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>

                                                            <table className="table table-sm">
                                                                <thead className="bg-light">
                                                                    <tr>
                                                                        <th className="text-center" style={{ width: '15%' }}>-</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>{totalQTY().toLocaleString()}</th>
                                                                        <th>{totalFCL().toLocaleString()} FCL ({invoice.containerTEU} TEU)</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>TOTAL {invoice.currencySuffix} - {invoice.incoterm}</th>
                                                                        <th className="text-center" style={{ width: '15%' }}>{invoice.currencySuffix || ""} {totalPricCoveringShippingCosts().toLocaleString()}</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div style={{ margin: 0, padding: 0 }}>
                                                            <p style={{ margin: 0, padding: 0 }}><strong>REMARKS</strong></p>
                                                            <p style={{ margin: 0, padding: 0 }}>The current proforma invoice must be considered non-binding by and between the parties until:</p>
                                                            <p style={{ margin: 0, padding: 0 }}>1. The signature of the authorized representative of the customer</p>
                                                            <p style={{ margin: 0, padding: 0 }}>2. If applicable, customer has made any prepayment/deposit to WMH Trading group as per the Terms of this proforma</p>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="bg-light me-1" style={{ flex: 2, borderRadius: '5px', margin: 0, padding: 0, marginTop: '10px' }}>
                                                                <address style={{ padding: '10px', marginRight: '5px' }}>
                                                                    <table style={{ width: '100%' }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><strong>BENEFICIARY NAME</strong></td>
                                                                                <td>{invoice.beneficiaryName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>IBAN</strong></td>
                                                                                <td>{invoice.iban}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>SWIFT CODE</strong></td>
                                                                                <td>{invoice.swiftCode}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>BANK</strong></td>
                                                                                <td>{invoice.bankName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>ADDRESS</strong></td>
                                                                                <td>{invoice.bankAddress}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </address>
                                                            </div>
                                                            <div style={{ flex: 1, marginLeft: 'auto', textAlign: 'center', marginTop: '10px' }}>
                                                                <div className="bg-light" style={{ padding: '5px', borderRadius: '5px' }}>
                                                                    <strong>Seller Signature</strong>
                                                                    {/* You can add additional styling if needed */}
                                                                </div>
                                                                {/* This div is for the signature */}
                                                            </div>
                                                        </div>
                                                        <div xs="8" style={{ display: 'flex', marginTop: '10px' }}>
                                                            <div style={{ flex: 3, margin: 0, padding: 0 }}>
                                                                <div className="terms" style={{ padding: '10px', borderRadius: '5px' }}>
                                                                    <p><strong>TERMS AND CONDITIONS</strong></p>
                                                                    <ul style={{ paddingLeft: '20px', margin: 0 }}>
                                                                        <li>Seller agrees that all goods shall be freshly produced and comply with government inspection standards and are of sound merchantable quality as per contract specification.</li>
                                                                        <li>Seller shall not be responsible for any shipping delays including port demurrage, et al., due to any cause beyond seller's control including untimely payments of buyer to seller.</li>
                                                                        <li>The seller shall not be liable for any loss/claims due to buyers contractual noncompliance.</li>
                                                                        <li>Ocean freight, bunker surcharges & currency adjustments. All increases after purchase are for account of the buyer.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div style={{ flex: 1, marginLeft: 'auto', textAlign: 'center' }}>
                                                                <div>
                                                                    <strong>{invoice.companyName}</strong><br />
                                                                    {invoice.city},{invoice.country}<br />
                                                                    {invoice.street}<br />
                                                                    {invoice.floor}<sup>th</sup>, {invoice.building}<br />
                                                                    {invoice.website}<br />
                                                                    {invoice.email}<br />
                                                                    {invoice.phone}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ textAlign: 'right', marginTop: '10px', fontSize: '8px' }}>
                                                            Only for {invoice.companyName} Ref. {invoice.supplierInvoices.map(inv => inv.invoiceNumber).join(', ')}
                                                        </div>

                                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                            THANK YOU FOR YOUR BUSINESS
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            </div>
            <div>
                {missingInvoice.customerId != null && missingInvoice.customerId > 0 &&
                    <MissingDataModal
                        show={showModal}
                        handleClose={handleModalClose}
                        handleSave={handleSave}
                        missingInvoice={missingInvoice}
                    />
                }
            </div>
            <div>
                <LowProformaDataModal
                    show={showLowModal}
                    handleClose={handleLowModalClose}
                    handleSave={handleGenerate}
                    Invoice={orginalInvoice}
                />
            </div>
        </div>
    );
}

export default PrintInvoice;