
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Global } from '../../Variables/Global';
import { getDecodedToken } from '../../utils/utils';
import axiosInstance from '../../api/axiosConfig';
import { toast } from "react-toastify";
import Company from "./Comapny";

const ManageCompanyAddress = () => {

    var pId = useParams();
    var navigate = useNavigate();

    const [pageType, setPageType] = useState("Add");
    const [companyAddress, setCompanyAddress] = useState({
        country: "",
        city: "",
        street: "",
        floor: "",
        building: "",
    })

    const GetCompanyAddress = (CompanyAddressId) => {
        var config = {
            method: 'get',
            url: "Company/GetCompanyAddress/" + CompanyAddressId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanyAddress(response.data);
                console.log("API response:", response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        if (pId.CompanyAddressId != "0") {
            document.title = "Edit Company Address";
            setPageType("Edit");
            GetCompanyAddress(pId.CompanyAddressId)
        } else {
            document.title = "Add Company Address";
        }
    }, [])

    const IsValid = () => {
        if (companyAddress.country === "" || companyAddress.city === "" || companyAddress.street === "" || companyAddress.floor === "" || companyAddress.building === "") {
            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = {
                CompanyAddressId: companyAddress.companyAddressId,
                CompanyId: pId.CompanyId,
                country: companyAddress.country,
                city: companyAddress.city,
                street: companyAddress.street,
                floor: companyAddress.floor,
                building: companyAddress.building,
                Company: pId.CompanyId
            }

            var config = {
                method: 'post',
                url: "Company/AddEditCompanyAddress",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: body
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Company Address Updated Successfully")
                        navigate("/Company")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
        }
    }
    return (
        <>
            {permissions.viewCompany == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Company Address</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Company Address</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">{pageType} Company Address Details</h4>
                                                </div>
                                                <div className="col-12 col-md-6 text-right">
                                                    <Link to={`/Company/${pId.CompanyId}`} className="btn btn-secondary mr-3">Cancel</Link>
                                                    <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-4">
                                                    <label htmlFor="Country" className="col-md-12 col-form-label">Country</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={companyAddress.country}
                                                        id="Country"
                                                        required
                                                        onChange={(e) => {
                                                            setCompanyAddress((prevState) => ({
                                                                ...prevState,
                                                                country: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="City" className="col-md-12 col-form-label">City</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={companyAddress.city}
                                                        id="City"
                                                        required
                                                        onChange={(e) => {
                                                            setCompanyAddress((prevState) => ({
                                                                ...prevState,
                                                                city: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="Street" className="col-md-12 col-form-label">Street</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={companyAddress.street}
                                                        id="Street"
                                                        required
                                                        onChange={(e) => {
                                                            setCompanyAddress((prevState) => ({
                                                                ...prevState,
                                                                street: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="Floor" className="col-md-12 col-form-label">Floor</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={companyAddress.floor}
                                                        id="Floor"
                                                        required
                                                        onChange={(e) => {
                                                            setCompanyAddress((prevState) => ({
                                                                ...prevState,
                                                                floor: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="Building" className="col-md-12 col-form-label">Building</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={companyAddress.building}
                                                        id="Building"
                                                        required
                                                        onChange={(e) => {
                                                            setCompanyAddress((prevState) => ({
                                                                ...prevState,
                                                                building: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </>
    )
}
export default ManageCompanyAddress