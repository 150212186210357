import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { toast } from "react-toastify";
import { FiEdit, FiTrash2, FiPlus } from 'react-icons/fi';
import { Global } from "../../../../Variables/Global";
import { formatDate } from "../../../../Classes/Util";
import axiosInstance from '../../../../api/axiosConfig';
import { Tabs, Tab, Button, Alert, Badge } from 'react-bootstrap';
import { Card, CardBody, Col, Row } from "reactstrap"
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import Dropzone from "react-dropzone"
import { getDecodedToken } from '../../../../utils/utils';

const ViewSupplierOrder = (props) => {
    var pId = useParams();
    var navigate = useNavigate();

    const [supplierName, setSupplierName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [sameCurrency, setSameCurrency] = useState(true)
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [permissions, setPermissions] = useState([]);

    const [account, setAccount] = useState({
        supplierAccountId: pId.AccountId,
        supplierAccount1: "",
        currencyId: 0
    })

    const [order, setOrder] = useState({
        supplierOrderId: 0,
        supplierOrder1: "",
        invoiceNumber: "",
        date: "",
        currencySuffix: "",
        otherCharges: 0,
        totalAmount: 0
    })
    const GetSupplier = (supplierId) => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers/" + supplierId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierName(response.data.supplierName)
                setCompanyId(response.data.companyId)
                GetSupplierAccount(pId.AccountId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    const [showAddCommercialProduct, setShowAddCommercialProduct] = useState(false);
    const handleCloseAddCommercialProduct = () => setShowAddCommercialProduct(false);
    const handleShowAddCommercialProduct = () => setShowAddCommercialProduct(true);
    const [showEditCommercialProduct, setShowEditCommercialProduct] = useState(false);
    const handleCloseEditCommercialProduct = () => setShowEditCommercialProduct(false);
    const handleShowEditCommercialProduct = () => setShowEditCommercialProduct(true);
    const [showDeleteCommercialProduct, setShowDeleteCommercialProduct] = useState(false);
    const handleShowDeleteCommercialProduct = () => setShowDeleteCommercialProduct(true);
    const handleCloseDeleteCommercialProduct = () => setShowDeleteCommercialProduct(false);
    const [showAddCommercialInvoice, setShowAddCommercialInvoice] = useState(false);
    const [showDeleteCommercialInvoice, setShowDeleteCommercialInvoice] = useState(false);
    const handleCloseAddCommercialInvoice = () => setShowAddCommercialInvoice(false);
    const handleShowAddCommercialInvoice = () => setShowAddCommercialInvoice(true);
    const handleCloseDeleteInvoice = () => setShowDeleteCommercialInvoice(false);
    const handleShowDeleteInvoice = () => setShowDeleteCommercialInvoice(true);
    const [editingCommercialId, setEditingCommercialId] = useState(0);
    const [deletingCommercialId, setDeletingCommercialId] = useState(0);


    const [showAddCommercialInvoicePayment, setShowAddCommercialInvoicePayment] = useState(false);
    const handleCloseAddCommercialInvoicePayment = () => setShowAddCommercialInvoicePayment(false);
    const handleShowAddCommercialInvoicePayment = () => setShowAddCommercialInvoicePayment(true);
    const [showAddCommercialDiscount, setShowAddCommercialDiscount] = useState(false);
    const handleCloseAddCommercialDiscount = () => setShowAddCommercialDiscount(false);
    const handleShowAddCommercialDiscount = () => setShowAddCommercialDiscount(true);

    const [commercialDiscount, setCommercialDiscount] = useState({
        commercialInvoiceNumber: "",
        remainingTotal: 0,
        commercialInvoiceId: 0,
        commercialInvoiceDiscount: 0,
        discountDate: formatDate(new Date()),
        notes: "",
        commercialInvoice: "",
        proforma: "",
        userId: localStorage.getItem("uTsReD"),
        addToStatement: false
    })

    const [commercialInvoicePayment, setCommercialInvoicePayment] = useState({
        commercialInvoiceId: 0,
        paymentValue: 0,
        paymentDate: formatDate(new Date()),
        notes: "",
        commercialInvoicePaymentBankId: 0,
        commercialInvoicePaymentBankAccountId: 0,
        bankAmount: 0,
        commercialInvoiceExchangeRate: 1,
        userId: localStorage.getItem("uTsReD"),
        invoiceNumber: "",
        remainingTotal: 0,
        commercialInvoiceNumber: "",
        supplierOrderId: 0,
        supplierId: pId.SupplierId,
        supplierAccountId: pId.AccountId,
        supplierName: "",
        supplierAccountName: ""
    });

    const [deletingCommercialInvoiceCostId, setDeletingCommercialInvoiceCostId] = useState(0);
    const [showDeleteCommercialInvoiceCost, setShowDeleteCommercialInvoiceCost] = useState(false);
    const handleCloseDeleteCommercialInvoiceCost = () => setShowDeleteCommercialInvoiceCost(false);
    const handleShowDeleteCommercialInvoiceCost = () => setShowDeleteCommercialInvoiceCost(true);

    const [editingCommercialInvoiceCostId, setEditingCommercialInvoiceCostId] = useState(0);
    const [showAddCommercialInvoiceCost, setShowAddCommercialInvoiceCost] = useState(false);
    const handleCloseAddCommercialInvoiceCost = () => setShowAddCommercialInvoiceCost(false);
    const handleShowAddCommercialInvoiceCost = () => setShowAddCommercialInvoiceCost(true);

    const [commercialInvoiceCost, setCommercialInvoiceCost] = useState({
        supplierCommercialInvoiceId: 0,
        amount: 0,
        shippingCostOptionId: 0,
        userId: localStorage.getItem("uTsReD"),
    });

    const [shippingCostTypes, setShippingCostTypes] = useState([])
    const GetShippingCostTypes = () => {
        var config = {
            method: 'get',
            url: "ShippingCostType/GetShippingCostsOptions?companyId=" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingCostTypes(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitCommercialInvoiceCostForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidCommercialInvoiceCost()) {
            if (editingCommercialInvoiceCostId > 0) {
                var config = {
                    method: 'put',
                    url: "SupplierCommercialInvoice/EditSupplierCommercialInvoiceShippingCost",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    params: {
                        'SupplierCommercialInvoiceShippingCostId': editingCommercialInvoiceCostId
                    },
                    data: commercialInvoiceCost
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Cost Updated Successfully")
                        handleCloseAddCommercialInvoiceCost();
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                        setSubmitDisabled(false);
                    });
            } else {
                var config = {
                    method: 'post',
                    url: "SupplierCommercialInvoice/AddSupplierCommercialInvoiceShippingCost",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: commercialInvoiceCost
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Cost Added Successfully")
                        handleCloseAddCommercialInvoiceCost();
                        // setShipmentShippingCosts(response.data);
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data)
                        setSubmitDisabled(false);
                    });
            }
        } else {
            setSubmitDisabled(false);
        }
    }

    const IsValidCommercialInvoiceCost = () => {
        if (commercialInvoiceCost.shippingCostOptionId === 0 && commercialInvoiceCost.amount === 0.0) {
            toast.error("Enter all required fields");
            return false;
        }
        else if (commercialInvoiceCost.shippingCostOptionId === 0) {
            toast.error("Select the shipping cost invoice type.");
            return false;
        } else if (commercialInvoiceCost.amount <= 0.0) {
            toast.error("Enter the amount.");
            return false;
        }
        return true;
    }

    const [commercialProduct, setCommercialProduct] = useState({
        supplierProductId: 0,
        performa: "",
        productQuantity: 0,
        fcl: 0,
        commercialInvoiceId: 0,
        userId: localStorage.getItem("uTsReD"),
        commercialInvoice: ""
    });

    const [editCommercialProduct, setEditCommercialProduct] = useState({
        supplierProductId: 0,
        performa: "",
        productQuantity: 0,
        fcl: 0,
        commercialInvoiceId: 0,
        userId: localStorage.getItem("uTsReD"),
        commercialInvoice: ""
    });

    const [commercialInvoice, setCommercialInvoice] = useState({
        sellOrderId: pId.OrderId,
        invoiceNumber: "",
        date: formatDate(new Date()),
        proforma: "",
        userId: localStorage.getItem("uTsReD"),
        notes: ""
    });


    const DeleteSupplierOrder = (supplierOrderId) => {
        var config = {
            method: 'delete',
            url: "SupplierOrder/DeleteSupplierOrder/" + supplierOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // navigate("/Accounting/Supplier/View/" + pId.SupplierId + "/Account/" + pId.AccountId)
                toast.success("Order deleted successfully.");
                navigate(-1);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
            });
    }

    const DeleteCommercialInvoiceCost = (costId) => {
        var config = {
            method: 'delete',
            url: "SupplierCommercialInvoice/DeleteSupplierCommercialInvoiceShippingCost?SupplierCommercialInvoiceShippingCostId=" + costId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                toast.success("Cost deleted successfully.");
                GetCommercialInvoices(pId.OrderId);
                handleCloseDeleteCommercialInvoiceCost();
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
            });
    }


    const AddCommercialDiscount = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "SupplierCommercialDiscount/AddSupplierCommercialDiscount/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: commercialDiscount
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully");
                    setShowAddCommercialDiscount(false);
                    // GetCommercialDiscounts(pId.InvoiceId)
                    // props.GetSupplierCommericalInvoiceTotal(pId.InvoiceId)
                    GetCommercialInvoices(pId.OrderId);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const CheckCurrency = (accountId) => {
        var config = {
            method: 'get',
            url: 'BankAccount/GetBankAccountCurrencyId/' + accountId,
            headers: {}
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode == 200) {
                    setSameCurrency(response.data.data.currencyId == account.currencyId)
                    if (response.data.data.currencyId == account.currencyId) {
                        setCommercialInvoicePayment((prevState) => ({
                            ...prevState,
                            commercialInvoiceExchangeRate: 1,
                            bankAmount: commercialInvoicePayment.paymentValue
                        }))
                    }
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const AddCommercialInvoicePayment = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "CommercialInvoicePayment/AddCommercialInvoicePayment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: commercialInvoicePayment
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully");
                    setShowAddCommercialInvoicePayment(false);
                    GetCommercialInvoices(pId.OrderId);
                    // GetCommercialInvoicePayments(pId.InvoiceId);
                    // AddSupplierAccountAdvancedPayment();
                    // GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong");
            });
    }

    const AddCommercialProduct = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "SupplierCommercialProduct/AddSupplierCommercialProduct/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: commercialProduct
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully");
                    setShowAddCommercialProduct(false);
                    // GetInvoiceProducts(pId.InvoiceId);
                    GetCommercialInvoices(pId.OrderId);
                    GetSupplierOrderProducts(pId.OrderId);
                    // GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
                if (response.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }

                setSubmitDisabled(false);
            })
            .catch(function (error) {
                if (error.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                } else {
                    toast.error(error.response.data);
                }
                setSubmitDisabled(false);
            });
    }
    const EditCommercialProduct = (e) => {
        e.preventDefault();
        var config = {
            method: 'put',
            url: "SupplierCommercialProduct/EditSupplierCommercialProduct/" + editCommercialProduct.supplierCommercialProductsId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editCommercialProduct
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Updated Successfully");
                    setShowEditCommercialProduct(false);
                    // GetInvoiceProducts(pId.InvoiceId);
                    GetCommercialInvoices(pId.OrderId);
                    GetSupplierOrderProducts(pId.OrderId);
                    // GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
                if (response.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }
            })
            .catch(function (error) {
                if (error.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }
            });
    }

    const AddCommericalInvoice = (e) => {
        e.preventDefault();

        setSubmitDisabled(true);

        if (editingCommercialId > 0) {
            var config = {
                method: 'put',
                url: "SupplierCommercialInvoice/EditSupplierCommercialInvoice/" + editingCommercialId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Updated Successfully")
                        setShowAddCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);

                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                });
        } else {
            var config = {
                method: 'post',
                url: "SupplierCommercialInvoice/AddSupplierCommercialInvoice/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Added Successfully")
                        setShowAddCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                });
        }
    }

    const DeleteInvoice = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (deletingCommercialId > 0) {
            var config = {
                method: 'delete',
                url: "SupplierCommercialInvoice/DeleteSupplierCommercialInvoice",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                params: {
                    'CommercialInvoiceId': deletingCommercialId,
                    'UserId': localStorage.getItem("uTsReD"),
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Deleted Successfully")
                        setShowDeleteCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                    setShowDeleteCommercialInvoice(false)
                });
        } else {
            var config = {
                method: 'post',
                url: "SupplierCommercialInvoice/AddSupplierCommercialInvoice/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Added Successfully")
                        setShowAddCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        }
    }

    const [showAdvancedDiscount, setShowAdvancedDiscount] = useState(false);
    const handleCloseAdvancedDiscount = () => setShowAdvancedDiscount(false);
    const handleShowAdvancedDiscount = () => setShowAdvancedDiscount(true);

    // const [advancedDiscounts, setAdvancedDiscounts] = useState([]);
    const [advancedDiscount, setAdvancedDiscount] = useState({
        advancedDiscount: 0,
        discountDate: formatDate(new Date()),
        notes: "",
        orderId: pId.OrderId,
        performa: "",
        userId: localStorage.getItem("uTsReD"),
        addToStatement: false
    })

    const [showAddAdvancedPayment, setShowAddAdvancedPayment] = useState(false);
    const handleCloseAddAdvancedPayment = () => setShowAddAdvancedPayment(false);
    const handleShowAddAdvancedPayment = () => setShowAddAdvancedPayment(true);
    const [advancedPayment, setAdvancedPayment] = useState({
        supplierAdvancedPayment: 0,
        supplierAdvancedPaymentPercentage: 0,
        supplierAdvancedPaymentDate: formatDate(new Date()),
        notes: "",
        supplierOrderId: pId.OrderId,
        supplierAdvancedPaymentBankId: 0,
        supplierAdvancedPaymentExchangeRate: 1,
        supplierAdvancedBankPayment: 0,
        supplierAdvancedPaymentBankAccountId: 0,
        performa: "",
        supplierId: pId.SupplierId,
        userId: localStorage.getItem("uTsReD"),
        supplierAccountId: pId.AccountId,
        supplierName: "",
        supplierAccount: ""
    })


    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const handleCloseAddProductModal = () => setShowAddProductModal(false);
    const handleShowAddProductModal = () => setShowAddProductModal(true);
    const [product, setProduct] = useState({
        supplierOrderId: pId.OrderId,
        productId: 0,
        productSizeId: 0,
        productUnitId: 0,
        productTypeId: 0,
        numberOfContainers: null,
        quantity: 0,
        unitPrice: 0,
        pendingFCL: 0,
        pendingQuantity: 0,
        userId: localStorage.getItem("uTsReD"),
        packing: ""
    })

    const AddAdvancedPayments = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "SupplierAdvancedPayment/AddSupplierAdvancedPayment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: advancedPayment
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Payment Added Successfully");
                    setShowAddAdvancedPayment(false);
                    GetAdvancedPayments(pId.OrderId);
                    GetSupplierOrderTotal(pId.OrderId);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data);
            });
    }

    const [banks, setBanks] = useState([]);
    const GetBanks = (companyId) => {
        var config = {
            method: 'get',
            url: "Bank/GetCompanyBanks/" + companyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data)
                setBanks(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [bankAccounts, setBankAccounts] = useState([]);
    const GetBankAccounts = (bankId) => {
        var config = {
            method: 'get',
            url: "BankAccount/GetBankAccounts/" + bankId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode == 200) {
                    setBankAccounts(response.data.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [bankCurrency, setBankCurrency] = useState('')

    const reloadBankAmountCommercial = (originalPayment) => {
        var x = originalPayment;
        var y = commercialInvoicePayment.commercialInvoiceExchangeRate;
        var z = x * y;
        console.log(z)
        setCommercialInvoicePayment((prevState) => ({ ...prevState, bankAmount: z }))
    }

    const CalculateExchangeRateCommercial = (bankAmount) => {
        var x = bankAmount;
        var y = commercialInvoicePayment.paymentValue;
        var z = x / y;
        setCommercialInvoicePayment((prevState) => ({ ...prevState, commercialInvoiceExchangeRate: z }))
    }

    const CalculateBankAmountCommercial = (exchangeRate) => {
        var x = exchangeRate;
        var y = commercialInvoicePayment.paymentValue;
        var z = x * y;
        setCommercialInvoicePayment((prevState) => ({ ...prevState, bankAmount: z }))
    }

    const handleExchangeRate = (bankAmount) => {
        var x = advancedPayment.supplierAdvancedPayment;
        var y = bankAmount;
        var z = y / x;
        setAdvancedPayment((prevState) => ({ ...prevState, supplierAdvancedPaymentExchangeRate: z }))
    }

    const handleBankAmount = (rate) => {
        var x = advancedPayment.supplierAdvancedPayment;
        var y = rate;
        var z = x * y;
        console.log(z)
        setAdvancedPayment((prevState) => ({ ...prevState, supplierAdvancedBankPayment: z }))
        console.log(advancedPayment)
    }

    const reloadBankAmount = (originalPayment) => {
        var percentage = originalPayment * 100.0 / orderTotal.totalAmount;
        var x = originalPayment;
        var y = advancedPayment.supplierAdvancedPaymentExchangeRate;
        var z = x * y;
        console.log(z)
        setAdvancedPayment((prevState) => ({
            ...prevState,
            supplierAdvancedBankPayment: z,
            supplierAdvancedPaymentPercentage: percentage
        }))
        console.log(advancedPayment)
    }

    const reloadAmountFromPercentage = (percentage) => {
        var originalPayment = orderTotal.remainingTotalAmount * percentage / 100.0;
        var x = originalPayment;
        var y = advancedPayment.supplierAdvancedPaymentExchangeRate;
        var z = x * y;
        console.log(z)
        setAdvancedPayment((prevState) => ({
            ...prevState,
            supplierAdvancedPayment: originalPayment,
            supplierAdvancedBankPayment: z,
        }))
        console.log(advancedPayment)
    }

    const [products, setProducts] = useState([])
    const GetProducts = () => {
        var config = {
            method: 'get',
            url: "Product/GetProducts",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setProducts(response.data);
                GetSizes();

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [sizes, setSizes] = useState([])
    const GetSizes = () => {
        var config = {
            method: 'get',
            url: "ProductSize/GetProductSize",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSizes(response.data);
                GetTypes();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [types, setType] = useState([])
    const GetTypes = () => {
        var config = {
            method: 'get',
            url: "ProductType/GetProductTypes",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setType(response.data);
                GetUnits();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [units, setUnits] = useState([])
    const GetUnits = () => {
        var config = {
            method: 'get',
            url: "ProductUnit/GetProductUnit",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setUnits(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const GetSupplierAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data);
                GetCurreny(response.data.currencyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSupplierAccountOrderEditProduct = (e) => {
        e.preventDefault();
        var _description = "";
        var _size = editProduct.productSizeName;
        var _type = editProduct.productTypeName;
        var prod = editProduct.productName;
        _description = " FCL, " + prod + ", " + _size + ", " + _type;

        setEditProduct((prevState) => ({
            ...prevState,
            description: _description
        }));
    }

    const AddProduct = (e) => {
        if (validateAddProductForm() == true) {
            setSubmitDisabled(true);

            console.log(product)
            e.preventDefault();
            var config = {
                method: 'post',
                url: "SupplierOrderProduct/AddSupplierOrderProduct/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: product
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        GetSupplierOrderProducts(pId.OrderId);
                        GetSupplierOrderTotal(pId.OrderId);
                        // AddSupplierAccountOrderProduct(response.data);
                        toast.success("Added Successfully")
                        setShowAddProductModal(false)
                    }
                    setSubmitDisabled(false);
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        }
    }

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const handleSupplierAccountOrderProduct = (e) => {
        e.preventDefault();
        console.log(product)
        var _description = "";
        var _fcl = product.numberOfContainers;
        var _productId = product.productId;
        var _productSizeId = product.productSizeId;
        var _productUnitId = product.productUnitId;
        var _productTypeId = product.productTypeId;
        var _unit = product.unit;

        var _size = "";
        sizes.forEach(element => {
            if (element.productSizeId == _productSizeId) {
                _size = element.productSizeName;
            }
        });

        var _type = "";
        types.forEach(element => {
            if (element.productTypeId == _productTypeId) {
                _type = element.productTypeName;
            }
        });

        var _unit = "";
        units.forEach(element => {
            if (element.productUnitId == _productUnitId) {
                _unit = element.productUnit1;
            }
        })

        var prod = "";
        products.forEach(element => {
            if (element.productId == _productId) {
                prod = element.productName;
            }
        })

        _description = " FCL, " + prod + ", " + _size + ", " + _type;

        setProduct((prevState) => ({
            ...prevState,
            description: _description,
        }));

        setProduct((prevState) => ({
            ...prevState,
            unit: _unit,
        }));
    }

    const [currency, setCurrency] = useState("")
    const GetCurreny = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                GetSupplierOrder(pId.OrderId);
                var companyId = localStorage.getItem("C0m8N!");
                GetBanks(companyId);
            })
            .catch(function (error) {
                console.log(error);
                GetSupplierOrder(pId.OrderId);
            });
    }

    const GetSupplierOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrder(response.data);
                document.title = '#' + response.data.invoiceNumber;
                GetSupplierOrderTotal(pId.OrderId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitSupplierOrderForm = (e) => {
        e.preventDefault();
        if (IsValidSupplierOrderForm()) {
            var config = {
                method: 'put',
                url: "SupplierOrder/EditSupplierOrder/" + pId.OrderId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: editingOrder
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Order Updated Successfully")
                        setOrder(response.data);
                        GetSupplierOrderTotal(pId.OrderId);
                        setShowEditSupplierOrder(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    toast.error(error.response.data)
                });
        }

    }
    const IsValidSupplierOrderForm = () => {
        if (editingOrder.invoiceNumber == "" && editingOrder.date == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (editingOrder.invoiceNumber == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (editingOrder.date == "") {
            toast.error("Enter Date");
            return false;
        }
        return true;
    }

    const [orderTotal, setOrderTotal] = useState({
        orderProducts: "",
        otherCharges: "",
        totalAmount: "",
        orderAdvancedPayments: "",
        orderAdvancedDiscounts: "",
        dueTotal: ""
    });


    const GetSupplierOrderTotal = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrderTotals/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrderTotal(response.data);
                GetSupplierOrderProducts(orderId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [orderProducts, setOrderProducts] = useState([]);
    const [advancedPayments, setAdvancedPayments] = useState([]);
    const [advancedDiscounts, setAdvancedDiscounts] = useState([]);
    const [commercialInvoices, setCommercialInvoices] = useState([]);

    const [deleteCommercialProductId, setDeleteommercialProductId] = useState(0);
    const DeleteCommercialProduct = (e) => {
        e.preventDefault();
        var config = {
            method: 'delete',
            url: "SupplierCommercialProduct/DeleteSupplierCommercialProduct",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'SupplierCommercialProductsId': deleteCommercialProductId,
                'UserId': localStorage.getItem("uTsReD")
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Deleted Successfully");
                    setShowDeleteCommercialProduct(false);
                    handleCloseDeleteSupplierOrderModal();
                    // GetInvoiceProducts(pId.InvoiceId);
                    GetCommercialInvoices(pId.OrderId);
                    GetSupplierOrderProducts(pId.OrderId);
                    // props.GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }

    const GetCommercialInvoices = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoicesWithDetails",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'OrderId': orderId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setCommercialInvoices(response.data);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data);
            });
    }

    const DeleteSupplierOrderProduct = (productId) => {
        var config = {
            method: 'delete',
            url: "SupplierOrderProduct/DeleteSupplierOrderProduct/" + productId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                GetSupplierOrderProducts(pId.OrderId);
                handleCloseDeleteOrderProductModal()
            })
            .catch(function (error) {
                console.log(error);
                handleCloseDeleteOrderProductModal()
                toast.error(error.response.data.message);
            });
    }


    const UpdateProduct = (e) => {
        if (validateEditOrderProductForm() == true) {
            console.log(editProduct)
            e.preventDefault();
            var config = {
                method: 'post',
                url: "SupplierOrderProduct/UpdateSupplierOrderProduct",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: editProduct
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        GetSupplierOrderProducts(pId.OrderId);
                        GetSupplierOrderTotal(pId.OrderId);
                        toast.success("Updated Successfully")
                        setShowEditProduct(false)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error)
                });
        }
    }
    const validateAddProductForm = () => {
        if (product.productId == null || product.productId == 0) {
            toast.warn("Please select a product.");
            return false;
        }
        if (product.productSizeId == null || product.productSizeId == 0) {
            toast.warn("Please select the product size.");
            return false;
        }
        if (product.productUnitId == null || product.productUnitId == 0) {
            toast.warn("Please select the product unit.");
            return false;
        }
        if (product.productTypeId == null || product.productTypeId == 0) {
            toast.warn("Please select the product type.");
            return false;
        }
        if (product.numberOfContainers == null) {
            toast.warn("Enter the number of FCL.");
            return false;
        }
        if (product.numberOfContainers < 0) {
            toast.warn("Number of FCL can't be negative.");
            return false;
        }
        if (product.quantity == null || product.quantity == 0) {
            toast.warn("Quantity can't be zero.");
            return false;
        }
        if (product.unitPrice == null || product.unitPrice == 0) {
            toast.warn("Price can't be zero.");
            return false;
        }
        if (product.packing == null || product.packing == "") {
            toast.warn("Please enter the packing type.");
            return false;
        }

        return true;
    }
    const validateEditOrderProductForm = () => {
        if (editProduct.productId == null || editProduct.productId == 0) {
            toast.warn("Please select a product.");
            return false;
        }
        if (editProduct.productSizeId == null || editProduct.productSizeId == 0) {
            toast.warn("Please select the product size.");
            return false;
        }
        if (editProduct.productUnitId == null || editProduct.productUnitId == 0) {
            toast.warn("Please select the product unit.");
            return false;
        }
        if (editProduct.productTypeId == null || editProduct.productTypeId == 0) {
            toast.warn("Please select the product type.");
            return false;
        }
        if (editProduct.numberOfContainers == null) {
            toast.warn("Enter the number of FCL.");
            return false;
        }
        if (editProduct.numberOfContainers < 0) {
            toast.warn("Number of FCL can't be negative.");
            return false;
        }
        if (editProduct.quantity == null || editProduct.quantity == 0) {
            toast.warn("Quantity can't be zero.");
            return false;
        }
        if (editProduct.unitPrice == null || editProduct.unitPrice == 0) {
            toast.warn("Price can't be zero.");
            return false;
        }
        if (editProduct.packing == null || editProduct.packing == "") {
            toast.warn("Please enter the packing type.");
            return false;
        }

        return true;
    }

    // const [editingSupplierOrderId, setEditingSupplierOrderId] = useState(0);
    const [showEditSupplierOrder, setShowEditSupplierOrder] = useState(false);
    const handleCloseEditSupplierOrder = () => setShowEditSupplierOrder(false);
    const handleShowEditSupplierOrder = () => setShowEditSupplierOrder(true);
    const [showDeleteSupplierOrderModal, setShowDeleteSupplierOrderModal] = useState(false);
    const handleCloseDeleteSupplierOrderModal = () => setShowDeleteSupplierOrderModal(false);
    const handleShowDeleteSupplierOrder = () => setShowDeleteSupplierOrderModal(true);



    const [editingOrder, setEditingOrder] = useState({
        // salesOrderId: 0,
        // payImmediately: false,
        invoiceNumber: order.invoiceNumber,
        otherCharges: order.otherCharges,
        notes: order.notes,
        paymentTermsId: order.paymentTermsId,
        paymentTermsName: order.paymentTermsName,
        date: order.date,
        proformaFileURL: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        supplierAccountId: pId.AccountId,
    })

    const [paymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [deletingSupplierOrderProductId, setDeletingSupplierOrderProductId] = useState(0);
    const [showDeleteOrderProductModal, setShowDeleteOrderProductModal] = useState(false);
    const handleCloseDeleteOrderProductModal = () => setShowDeleteOrderProductModal(false);

    const [showEditProduct, setShowEditProduct] = useState(false);
    const handleCloseEditProduct = () => setShowEditProduct(false);
    const [editProduct, setEditProduct] = useState({
        supplierOrderProductId: 0,
        supplierOrderId: pId.OrderId,
        productId: 0,
        productSizeId: 0,
        productUnitId: 0,
        productTypeId: 0,
        numberOfContainers: null,
        quantity: "",
        unitPrice: "",
        pendingFCL: 0,
        pendingQuantity: 0,
        packing: ""
    })

    const GetSupplierOrderProducts = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrderProduct/GetSupplierOrderProduct/" + orderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data)
                setOrderProducts(response.data);
                GetAdvancedPayments(orderId);
                // GetSupplierOrder(pId.OrderId)
            })
            .catch(function (error) {
                console.log(error);
                // GetSupplierOrder(pId.OrderId)
            });
    }
    const GetAdvancedPayments = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierAdvancedPayment/GetSupplierAdvancedPayments/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAdvancedPayments(response.data);
                GetAdvancedDiscounts(orderId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const AddAdvancedDiscount = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "SupplierAdvancedDiscount/AddSupplierAdvancedDiscount/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: advancedDiscount
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully")
                    setShowAdvancedDiscount(false)
                    GetAdvancedDiscounts(pId.OrderId);
                    GetSupplierOrderTotal(pId.OrderId)
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }
    const GetAdvancedDiscounts = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierAdvancedDiscount/GetSupplierAdvancedDiscounts/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAdvancedDiscounts(response.data);
                GetCommercialInvoices(orderId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        if (pId.OrderId !== "0") {
            GetSupplier(pId.SupplierId);
            GetProducts();
            GetSupplierAttachment(pId.OrderId);
            setAdvancedPayment((prevState) => ({
                ...prevState,
                performa: order.invoiceNumber,
                supplierAccountId: pId.AccountId,
                supplierId: pId.SupplierId,
                supplierName: supplierName,
                supplierAccount: account.supplierAccount1,
            }));
            GetAdvancedPayments(pId.OrderId);

            setAdvancedDiscount((prevState) => ({
                ...prevState,
                proforma: order.invoiceNumber
            }));

            GetShippingCostTypes();

            GetAdvancedDiscounts(pId.OrderId);
        }
        else {
            navigate("/Accounting/Supplier/View/" + pId.SupplierId + "/Account/" + pId.AccountId)
        }
    }, [])



    const [selectedFiles, setselectedFiles] = useState([])
    const handleAcceptedFiles = (acceptedFiles) => {

        //Mapped the preview before uploading 
        const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        }));
        setselectedFiles(prevFiles => [...prevFiles, ...mappedFiles]);

        uploadFiles(acceptedFiles);
    };
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const uploadFiles = async (files) => {
        const companyCode = localStorage.getItem("C0m8N!");
        const userCode = localStorage.getItem("uTsReD");
        const supplierOrderId = pId.OrderId;

        files.forEach(async (file) => {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('companycode', companyCode);
            formData.append('usercode', userCode);
            formData.append('supplierOrderId', supplierOrderId);

            const config = {
                method: 'post',
                url: `${Global.API_URL}SupplierSalesOrder/Upload`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentCompleted
                    }));
                }
            };

            try {
                const response = await axiosInstance(config);
                if (response.status === 200) {
                    toast.success(`Upload Succeed for ${file.name}`);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: 100
                    }));
                    GetSupplierAttachment(pId.OrderId);
                    console.log(`File successfully uploaded: ${file.name}`, response.data);
                }
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                toast.error(`Something Went Wrong with ${file.name}`);
            }
        });
    };
    const GetSupplierAttachment = (supplierOrderId) => {
        var config = {
            method: 'get',
            url: 'SupplierOrder/GetSupplierAttachment',
            headers: {
                'Content-Type': 'application/json'
            },
            params: { supplierOrderId }
        };

        axiosInstance(config)
            .then(function (response) {
                setUploadedFiles(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const handleDelete = (attachmentId) => {
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteAttachment/" + attachmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetSupplierAttachment(pId.OrderId);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }
    const renderFilePreview = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return (
                    <a href={`${file.path}`} target="_blank" rel="noopener noreferrer">
                        <img
                            src={`${file.path}`}
                            alt={file.name}
                            height="50"
                            width="100"
                            className="auth-logo-light"
                            style={{ marginRight: '10px', marginTop: '10px' }}
                        />
                    </a>
                );
            case 'pdf':
            case 'txt':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'csv':
                return (
                    <a
                        href={`${file.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="auth-logo-light"
                        style={{ marginRight: '10px', marginTop: '10px' }}
                    >
                        {file.name}
                    </a>
                );
            default:
                return <span>Unsupported file type</span>;
        }
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4>Suppliers</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                    <li className="breadcrumb-item">
                                        {/* <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li> */}
                                        <Link onClick={() => { navigate(-2) }}>{supplierName}</Link></li>
                                    {/* <li className="breadcrumb-item"><a href="#">Account</a></li> */}
                                    <li className="breadcrumb-item">
                                        {/* <Link to={"/Accounting/Supplier/" + supplierName + "/" + pId.SupplierId + "/Account/" + account.supplierAccount1 + "/" + pId.AccountId + "/Orders"}>{account.supplierAccount1}</Link> */}
                                        <Link onClick={() => { navigate(-1) }}>{account.supplierAccount1}</Link>
                                    </li>
                                    {/* <li className="breadcrumb-item"><a href="#">Order</a></li> */}
                                    <li className="breadcrumb-item"><a href="#">#{order.invoiceNumber}</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <Row >
                        <Col sm={5} className="flex">
                            <Card>
                                <CardBody>
                                    <table cellPadding={10}>
                                        <tr>
                                            <td>
                                                <div role="alert">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            {/* fas fa-info-circle */}
                                                            <h5 class="alert-heading font-18"><i class="fas fa-file-invoice" /> Order Details</h5>
                                                        </div>
                                                        <Col md={2} className="text-right">
                                                            {permissions.manageSupplierOrder === true && (
                                                                <div className="d-flex justify-content-end">
                                                                    <Button variant="outline-warning" size="sm" className="me-1"
                                                                        onClick={() => {
                                                                            // setEditingCommercialId(c.commercialInvoiceId);
                                                                            // setCommercialInvoice((prevState) => ({
                                                                            //     ...prevState,
                                                                            //     sellOrderId: pId.OrderId,
                                                                            //     invoiceNumber: c.invoiceNumber,
                                                                            //     date: c.date.split("T")[0],
                                                                            //     notes: c.notes,
                                                                            // }));
                                                                            // handleShowAddCommercialInvoice();
                                                                            GetPaymentTerms();

                                                                            setEditingOrder((prevState) => ({
                                                                                ...prevState,
                                                                                invoiceNumber: order.invoiceNumber,
                                                                                otherCharges: order.otherCharges,
                                                                                notes: order.notes,
                                                                                paymentTermsId: order.paymentTermsId,
                                                                                paymentTermsName: order.paymentTermsName,
                                                                                date: order.date.split('T')[0],
                                                                            }));

                                                                            handleShowEditSupplierOrder();
                                                                        }
                                                                        } ><FiEdit /></Button>
                                                                    <Button variant="outline-danger" size="sm" onClick={() => {
                                                                        // setDeletingCommercialId(c.commercialInvoiceId);
                                                                        handleShowDeleteSupplierOrder();
                                                                    }
                                                                    }><FiTrash2 /></Button>
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <p>
                                                            Invoice Number: <b>{order.invoiceNumber != null && order.invoiceNumber.length > 0 ? '#' + order.invoiceNumber : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Order Date: <b>{order.date != null && order.date.length > 0 ? order.date.split('T')[0] : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Products Total: <b>{orderTotal != null && orderTotal.orderProducts != null ? currency.currencySuffix + " " + orderTotal.orderProducts.toLocaleString() : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Other Charges: <b>{orderTotal != null && orderTotal.otherCharges != null ? currency.currencySuffix + " " + orderTotal.otherCharges.toLocaleString() : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Total: <b>{orderTotal != null && orderTotal.totalAmount != null ? currency.currencySuffix + " " + orderTotal.totalAmount.toLocaleString() : <Skeleton width={100} />}</b>
                                                        </p>
                                                        <table cellPadding={10}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div role="alert">
                                                                            <div className="row" style={{ display: 'flex', height: '100%' }}>
                                                                                <div className="col-6" style={{ flex: 1, display: 'flex' }}>
                                                                                    <div className="alert alert-warning fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                        <h6 className="alert-heading font-18"><i class="mdi mdi-cash-lock" /> Payment Terms</h6>
                                                                                        <span className="text-secondary">{order.paymentTermsName}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6" style={{ flex: 1, display: 'flex' }}>
                                                                                    <div className="alert alert-warning fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                        <h6 className="alert-heading font-18"><i className="fas fa-pencil-alt" /> Notes</h6>
                                                                                        <span className="text-secondary">{order.notes}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <Tabs defaultActiveKey="products" className="mt-3 ms-2">
                                                            <Tab
                                                                eventKey="products"
                                                                title={
                                                                    <span>
                                                                        Products{' '}
                                                                        {
                                                                            orderTotal.orderProducts != null && orderTotal.orderProducts > 0 &&
                                                                            <Badge className="rounded-pill bg-primary badge">{orderTotal.orderProducts.toLocaleString() || 0}</Badge>
                                                                        }
                                                                    </span>
                                                                }>
                                                                <div className="mt-3">
                                                                    {orderProducts != null && orderProducts.length > 0 && orderProducts.map((p, index) => {
                                                                        return (
                                                                            <Alert key={index} variant="primary" className="fade show">
                                                                                <Row>
                                                                                    <Col md={10}>
                                                                                        <h6 className="alert-heading font-18">
                                                                                            <b>{p.numberOfContainers} FCL ({p.quantity.toLocaleString()} {p.productUnit1}) {p.productName}, {p.productTypeName}, {p.productSizeName}</b>
                                                                                        </h6>
                                                                                        <span className="text-secondary">Unit Price: <b>{currency.currencySuffix} {p.unitPrice.toLocaleString()}</b></span><br />
                                                                                        <span className="text-secondary">Total Price: <b>{currency.currencySuffix} {(p.quantity * p.unitPrice).toLocaleString()}</b></span><br />
                                                                                    </Col>
                                                                                    <Col md={2} className="text-right">
                                                                                        {permissions.manageSupplierOrder === true && (
                                                                                            <div className="d-flex justify-content-end">
                                                                                                <Button variant="warning" size="sm" className="me-1" onClick={(e) => {

                                                                                                    setEditProduct((prevState) => ({
                                                                                                        ...prevState,
                                                                                                        supplierOrderProductId: p.supplierOrderProductId,
                                                                                                        supplierOrderId: p.supplierOrderId,
                                                                                                        productId: p.productId,
                                                                                                        productSizeId: p.sizeId,
                                                                                                        productUnitId: p.unitId,
                                                                                                        productTypeId: p.typeId,
                                                                                                        productTypeName: p.productTypeName,
                                                                                                        productSizeName: p.productSizeName,
                                                                                                        unit: p.productUnit1,
                                                                                                        productName: p.productName,
                                                                                                        numberOfContainers: p.numberOfContainers,
                                                                                                        quantity: p.quantity,
                                                                                                        unitPrice: p.unitPrice,
                                                                                                        packing: p.packing,
                                                                                                        description: " FCL, " + p.productName + ", " + p.productSizeName + ", " + p.productTypeName
                                                                                                    }));
                                                                                                    setShowEditProduct(true);
                                                                                                }}><FiEdit /></Button>
                                                                                                <Button variant="danger" size="sm" onClick={() => {
                                                                                                    setDeletingSupplierOrderProductId(p.supplierOrderProductId);
                                                                                                    setShowDeleteOrderProductModal(true);
                                                                                                }}><FiTrash2 /></Button>
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Alert>
                                                                        );
                                                                    })}
                                                                    {permissions.manageSupplierOrder === true && (
                                                                        <Button variant="outline-primary" className="w-100 mt-3"

                                                                            onClick={() => {
                                                                                setProduct((prevState) => ({
                                                                                    ...prevState,
                                                                                    supplierOrderId: pId.OrderId
                                                                                }))
                                                                                handleShowAddProductModal()
                                                                            }}>
                                                                            <b><FiPlus /> Add New Product</b>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </Tab>

                                                            <Tab
                                                                eventKey="payments"
                                                                title={
                                                                    <span>
                                                                        Advanced Payments{' '}
                                                                        {/* <Badge className="rounded-pill bg-primary badge">{advancedPayments?.length || 0}</Badge> */}
                                                                        {
                                                                            orderTotal.orderAdvancedPayments != null && orderTotal.orderAdvancedPayments > 0 &&
                                                                            <Badge className="rounded-pill bg-primary badge">{orderTotal.orderAdvancedPayments.toLocaleString() || 0}</Badge>
                                                                        }
                                                                    </span>
                                                                }
                                                            >
                                                                <div className="mt-3">
                                                                    {advancedPayments != null && advancedPayments.length > 0 && advancedPayments.map((p, index) => {
                                                                        return (
                                                                            <Alert key={index} variant="primary" className="fade show">
                                                                                <Row>
                                                                                    <Col md={9}>
                                                                                        <h6 className="alert-heading font-18">
                                                                                            Amount: <b>{currency.currencySuffix} {p.supplierAdvancedPayment.toLocaleString()}</b>
                                                                                        </h6>
                                                                                        <span className="text-secondary"><i className="mdi mdi-bank" /> {p.bankAccount}</span><br />
                                                                                        {p.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {p.notes}</span>}
                                                                                    </Col>
                                                                                    <Col md={3} className="text-right">
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <h6 className="alert-heading font-14"><b>{p.supplierAdvancedPaymentDate.split("T")[0]}</b></h6>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Alert>
                                                                        );
                                                                    })}
                                                                    {permissions.manageSupplierOrder === true && (
                                                                        <Button variant="outline-primary" className="w-100 mt-3" onClick={handleShowAddAdvancedPayment}>
                                                                            <b><FiPlus /> Add New Payment</b>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </Tab>

                                                            <Tab
                                                                eventKey="discounts"
                                                                title={
                                                                    <span>
                                                                        Advanced Discounts{' '}
                                                                        {/* <Badge className="rounded-pill bg-primary badge">{advancedDiscounts?.length || 0}</Badge> */}
                                                                        {
                                                                            orderTotal.orderAdvancedDiscounts != null && orderTotal.orderAdvancedDiscounts > 0 &&
                                                                            <Badge className="rounded-pill bg-primary badge">{orderTotal.orderAdvancedDiscounts.toLocaleString() || 0}</Badge>
                                                                        }
                                                                    </span>
                                                                }
                                                            >
                                                                <div className="mt-3">
                                                                    {advancedDiscounts != null && advancedDiscounts.length > 0 && advancedDiscounts.map((d, index) => {
                                                                        return (
                                                                            <Alert key={index} variant="primary" className="fade show">
                                                                                <Row>
                                                                                    <Col md={9}>
                                                                                        <h6 className="alert-heading font-18">
                                                                                            Amount: <b>{currency.currencySuffix} {d.advancedDiscount.toLocaleString()}</b>
                                                                                        </h6>
                                                                                        {d.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {d.notes}</span>}
                                                                                    </Col>
                                                                                    <Col md={3} className="text-right">
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <h6 className="alert-heading font-14"><b>{d.discountDate.split("T")[0]}</b></h6>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Alert>
                                                                        );
                                                                    })}
                                                                    {permissions.manageSupplierOrder === true && (
                                                                        <Button variant="outline-primary" className="w-100 mt-3" onClick={handleShowAdvancedDiscount}>
                                                                            <b><FiPlus /> Add New Discount</b>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </Tab>
                                                        </Tabs>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={7} className="flex">
                            <Card>
                                <CardBody>
                                    <table cellPadding={10}>
                                        <tr>
                                            <td>
                                                <div role="alert">
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <h5 class="alert-heading font-18"><i class="fas fa-file-alt" /> Commercial Invoices</h5>
                                                        </div>
                                                        <div className="mt-3">
                                                            {commercialInvoices != null && commercialInvoices.length > 0 && commercialInvoices.map((c, index) => {
                                                                return (
                                                                    <Alert key={index} style={{ borderWidth: 3, backgroundColor: '#FAFAFA' }} variant="outline-success" className="fade show">
                                                                        <Row>
                                                                            <Col md={10}>
                                                                                <p className="text-secondary">
                                                                                    Invoice Number: <b>{c.invoiceNumber != null && c.invoiceNumber.length > 0 ? '#' + c.invoiceNumber : <Skeleton width={100} />}</b>
                                                                                    <br />
                                                                                    Customer Invoice Number: <b>{c.customerCommercialInvoice1}</b>
                                                                                    <br />
                                                                                    Date: <b>{c.date != null && c.date.length > 0 ? c.date.split('T')[0] : <Skeleton width={100} />}</b>
                                                                                    <br />
                                                                                    Invoice Total: <b>{currency.currencySuffix} {c.invoiceTotals.totalAmount.toLocaleString()}</b>
                                                                                    <br />
                                                                                    Advance Payment: <b>{currency.currencySuffix} {c.invoiceTotals.advancedPayment.toLocaleString()}</b>
                                                                                    <br />
                                                                                    Remaining To Pay: <b>{currency.currencySuffix} {c.invoiceTotals.remainingTotal.toLocaleString()}</b>
                                                                                    <br />
                                                                                    {
                                                                                        c.notes != null && c.notes.length > 0 && ("Notes: " + c.notes)
                                                                                    }
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="text-right">
                                                                                {permissions.manageSupplierOrder === true && (
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <Button variant="outline-warning" size="sm" className="me-1"
                                                                                            onClick={() => {
                                                                                                setEditingCommercialId(c.commercialInvoiceId);
                                                                                                setCommercialInvoice((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    sellOrderId: pId.OrderId,
                                                                                                    invoiceNumber: c.invoiceNumber,
                                                                                                    date: c.date.split("T")[0],
                                                                                                    notes: c.notes,
                                                                                                }));
                                                                                                handleShowAddCommercialInvoice();
                                                                                            }
                                                                                            } ><FiEdit /></Button>
                                                                                        <Button variant="outline-danger" size="sm" onClick={() => {
                                                                                            setDeletingCommercialId(c.commercialInvoiceId);
                                                                                            handleShowDeleteInvoice();
                                                                                        }
                                                                                        }><FiTrash2 /></Button>
                                                                                    </div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Tabs defaultActiveKey="products" className="mt-3 ms-2">
                                                                                <Tab
                                                                                    eventKey="products"
                                                                                    title={
                                                                                        <span>
                                                                                            Products{' '}
                                                                                            {/* <Badge className="rounded-pill bg-primary badge">{c.products?.length || 0}</Badge> */}
                                                                                            {
                                                                                                c.invoiceTotals?.productsTotal > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.invoiceTotals?.productsTotal.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }>
                                                                                    <div className="mt-3">
                                                                                        {c.products != null && c.products.length > 0 && c.products.map((p, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={10}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                <b>{p.fcl} FCL ({p.productQuantity.toLocaleString()} {p.productUnitName}) {p.productName}, {p.productTypeName}, {p.productSizeName}</b>
                                                                                                            </h6>
                                                                                                            <span className="text-secondary">Unit Price: <b>{currency.currencySuffix} {p.unitPrice.toLocaleString()}</b></span><br />
                                                                                                            <span className="text-secondary">Total Price: <b>{currency.currencySuffix} {(p.productQuantity * p.unitPrice).toLocaleString()}</b></span><br />
                                                                                                        </Col>
                                                                                                        <Col md={2} className="text-right">
                                                                                                            {permissions.manageSupplierOrder === true && (
                                                                                                                <div className="d-flex justify-content-end">
                                                                                                                    <Button variant="warning" size="sm" className="me-1" onClick={(e) => {
                                                                                                                        setEditCommercialProduct((prevState) => ({
                                                                                                                            ...prevState,
                                                                                                                            supplierCommercialProductsId: p.supplierCommercialProductsId,
                                                                                                                            fcl: p.fcl,
                                                                                                                            productQuantity: p.productQuantity,
                                                                                                                            supplierProductId: p.supplierProductId,
                                                                                                                        }));
                                                                                                                        handleShowEditCommercialProduct();
                                                                                                                    }}><FiEdit /></Button>
                                                                                                                    <Button variant="danger" size="sm" onClick={(e) => {
                                                                                                                        setDeleteommercialProductId(p.supplierCommercialProductsId);
                                                                                                                        handleShowDeleteCommercialProduct();
                                                                                                                    }}><FiTrash2 /></Button>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        {permissions.manageSupplierOrder === true && (
                                                                                            <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                                setCommercialProduct((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    commercialInvoiceId: c.commercialInvoiceId,
                                                                                                    performa: order.invoiceNumber,
                                                                                                    commercialInvoice: c.invoiceNumber
                                                                                                }));
                                                                                                handleShowAddCommercialProduct();
                                                                                            }}>
                                                                                                <b><FiPlus /> Add New Product</b>
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                </Tab>

                                                                                <Tab
                                                                                    eventKey="payments"
                                                                                    title={
                                                                                        <span>
                                                                                            Payments{' '}
                                                                                            {/* <Badge className="rounded-pill bg-primary badge">{c.payments?.length || 0}</Badge> */}
                                                                                            {
                                                                                                c.invoiceTotals?.productTotalPayment > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.invoiceTotals?.productTotalPayment.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <div className="mt-3">
                                                                                        {c.payments != null && c.payments.length > 0 && c.payments.map((p, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={9}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                Amount: <b>{currency.currencySuffix} {p.paymentValue.toLocaleString()}</b>
                                                                                                            </h6>
                                                                                                            <span className="text-secondary"><i className="mdi mdi-bank" /> {p.bankName}</span><br />
                                                                                                            {p.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {p.notes}</span>}
                                                                                                        </Col>
                                                                                                        <Col md={3} className="text-right">
                                                                                                            <div className="d-flex justify-content-end">
                                                                                                                <h6 className="alert-heading font-14"><b>{p.invoiceDate.split("T")[0]}</b></h6>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        {permissions.manageSupplierOrder === true && (
                                                                                            <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                                setCommercialInvoicePayment((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    commercialInvoiceId: c.commercialInvoiceId,
                                                                                                    commercialInvoiceNumber: c.invoiceNumber,
                                                                                                    invoiceNumber: order.invoiceNumber,
                                                                                                    remainingTotal: c.invoiceTotals.remainingTotal,
                                                                                                    supplierName: supplierName,
                                                                                                    supplierOrderId: order.supplierOrderId,
                                                                                                    supplierAccountName: account.supplierAccount1,

                                                                                                }));
                                                                                                handleShowAddCommercialInvoicePayment();
                                                                                            }}>
                                                                                                <b><FiPlus /> Add New Payment</b>
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                </Tab>

                                                                                <Tab
                                                                                    eventKey="discounts"
                                                                                    title={
                                                                                        <span>
                                                                                            Discounts{' '}
                                                                                            {/* <Badge className="rounded-pill bg-primary badge">{c.discounts?.length || 0}</Badge> */}
                                                                                            {
                                                                                                c.invoiceTotals?.productTotalDiscount > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.invoiceTotals?.productTotalDiscount.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <div className="mt-3">
                                                                                        {c.discounts != null && c.discounts.length > 0 && c.discounts.map((d, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={9}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                Amount: <b>{currency.currencySuffix} {d.commercialInvoiceDiscount.toLocaleString()}</b>
                                                                                                            </h6>
                                                                                                            {d.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {d.notes}</span>}
                                                                                                        </Col>
                                                                                                        <Col md={3} className="text-right">
                                                                                                            <div className="d-flex justify-content-end">
                                                                                                                <h6 className="alert-heading font-14"><b>{d.discountDate.split("T")[0]}</b></h6>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        {permissions.manageSupplierOrder === true && (
                                                                                            <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                                setCommercialDiscount((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    commercialInvoiceNumber: c.invoiceNumber,
                                                                                                    remainingTotal: c.invoiceTotals.remainingTotal,
                                                                                                    commercialInvoiceId: c.commercialInvoiceId,
                                                                                                    commercialInvoiceDiscount: 0,
                                                                                                    discountDate: formatDate(new Date()),
                                                                                                    notes: "",
                                                                                                    commercialInvoice: c.invoiceNumber,
                                                                                                    proforma: order.invoiceNumber,
                                                                                                    userId: localStorage.getItem("uTsReD"),
                                                                                                    addToStatement: false
                                                                                                }));
                                                                                                handleShowAddCommercialDiscount();
                                                                                            }}>
                                                                                                <b><FiPlus /> Add New Discount</b>
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                </Tab>
                                                                                <Tab
                                                                                    eventKey="costs"
                                                                                    title={
                                                                                        <span>
                                                                                            Costs{' '}
                                                                                            {/* <Badge className="rounded-pill bg-primary badge">{c.payments?.length || 0}</Badge> */}
                                                                                            {
                                                                                                c.invoiceTotals?.commercialTotalCosts > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.invoiceTotals?.commercialTotalCosts.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <div className="mt-3">
                                                                                        {c.otherCharges != null && c.otherCharges.length > 0 && c.otherCharges.map((p, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={10}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                {p.name}: <b>{currency.currencySuffix} {p.amount.toLocaleString()}</b>
                                                                                                            </h6>
                                                                                                            {/* <span className="text-secondary"><i className="mdi mdi-bank" /> {p.bankName}</span><br />
                                                                                                            {p.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {p.notes}</span>} */}
                                                                                                        </Col>
                                                                                                        <Col md={2} className="text-right">
                                                                                                            {permissions.manageSupplierOrder === true && (
                                                                                                                <div className="d-flex justify-content-end">
                                                                                                                    <Button variant="warning" size="sm" className="me-1" onClick={(e) => {
                                                                                                                        setEditingCommercialInvoiceCostId(p.supplierCommercialInvoiceShippingCostId)

                                                                                                                        setCommercialInvoiceCost((prevState) => ({
                                                                                                                            ...prevState,
                                                                                                                            supplierCommercialInvoiceId: c.commercialInvoiceId,
                                                                                                                            amount: p.amount,
                                                                                                                            shippingCostOptionId: p.shippingCostsOptionId,
                                                                                                                            shippingCostOptionName: p.name
                                                                                                                        }));

                                                                                                                        handleShowAddCommercialInvoiceCost();
                                                                                                                    }}><FiEdit /></Button>
                                                                                                                    <Button variant="danger" size="sm" onClick={() => {
                                                                                                                        setDeletingCommercialInvoiceCostId(p.supplierCommercialInvoiceShippingCostId)
                                                                                                                        handleShowDeleteCommercialInvoiceCost();
                                                                                                                    }}><FiTrash2 /></Button>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        {permissions.manageSupplierOrder === true && (
                                                                                            <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                                setEditingCommercialInvoiceCostId(0)
                                                                                                setCommercialInvoiceCost((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    supplierCommercialInvoiceId: c.commercialInvoiceId,
                                                                                                    amount: 0,
                                                                                                    shippingCostOptionId: 0,
                                                                                                    shippingCostOptionName: ""
                                                                                                }));
                                                                                                handleShowAddCommercialInvoiceCost();
                                                                                            }}>
                                                                                                <b><FiPlus /> Add New Cost</b>
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                </Tab>
                                                                            </Tabs>
                                                                        </Row>
                                                                    </Alert>
                                                                );
                                                            })}
                                                            {permissions.manageSupplierOrder === true && (
                                                                <Button variant="primary" className="w-100 mt-3" onClick={(e) => {
                                                                    handleShowAddCommercialInvoice();
                                                                    setEditingCommercialId(0);
                                                                    setCommercialInvoice((prevState) => ({
                                                                        ...prevState,
                                                                        invoiceNumber: "",
                                                                        date: formatDate(new Date()),
                                                                        notes: "",
                                                                    }));
                                                                }}>
                                                                    <b><FiPlus /> Add New Invoice</b>
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={12} style={{ flex: 1, display: 'flex' }}>
                            <Card style={{ flex: 1 }}>
                                <CardBody>
                                    <h5 className=" mb-3 alert-heading font-18"><i class="ion ion-md-attach" /> Attachments</h5>

                                    <Dropzone onDrop={handleAcceptedFiles}>
                                        {({ getRootProps, getInputProps }) => {
                                            const rootProps = getRootProps();
                                            const inputProps = getInputProps();

                                            return (
                                                <div {...rootProps}>
                                                    {permissions.manageSupplierOrder === true && (
                                                        <button
                                                            onClick={rootProps.onClick}
                                                            className="btn btn-primary mb-2 mt-3"
                                                            style={{ width: '100%' }}
                                                        >
                                                            <b><FiPlus /> Upload Attachments</b>
                                                        </button>
                                                    )}
                                                    <input {...inputProps} style={{ display: 'none' }} />
                                                </div>
                                            );
                                        }}
                                    </Dropzone>
                                    <br />
                                    <Row>
                                        {uploadedFiles.map((file, index) => (
                                            <Col sm={3} key={index} className="mb-1">
                                                <Card style={{ position: 'relative' }}>
                                                    {permissions.manageSupplierOrder === true && (
                                                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                                            <button
                                                                className="btn btn-danger btn-md"
                                                                onClick={() => handleDelete(file.attachmentId)}
                                                            >
                                                                <FiTrash2 />
                                                            </button>
                                                        </div>
                                                    )}
                                                    <CardBody>
                                                        <span>{file.name}</span>
                                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {renderFilePreview(file)}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal show={showEditProduct} onHide={handleCloseEditProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Order Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Product</label>
                            <select className="form-control mb-3" required value={editProduct.productId}
                                onChange={(e) => {
                                    const index = e.target.selectedIndex - 1;
                                    const selectedItem = products.at(index);
                                    editProduct.productId = selectedItem.productId;
                                    editProduct.productName = selectedItem.productName;
                                    handleSupplierAccountOrderEditProduct(e);
                                    console.log("editProduct", editProduct);
                                }}>
                                <option value="0" key="0">-- Select Product --</option>
                                {products.length > 0 && (
                                    products.map((c, index) => {
                                        return (<>
                                            <option value={c.productId} key={index}>{c.productName}</option>
                                        </>)
                                    })
                                )}
                            </select>

                            <label>Size</label>
                            <select className="form-control mb-3" required value={editProduct.productSizeId}
                                onChange={(e) => {
                                    const index = e.target.selectedIndex - 1;
                                    const selectedItem = sizes.at(index);
                                    editProduct.productSizeId = selectedItem.productSizeId;
                                    editProduct.productSizeName = selectedItem.productSizeName;
                                    handleSupplierAccountOrderEditProduct(e);
                                }}>
                                <option value="0" key="0">-- Select Size --</option>
                                {sizes.length > 0 && (
                                    sizes.map((c, index) => {
                                        return (<>
                                            <option value={c.productSizeId} key={index}>{c.productSizeName}</option>
                                        </>)
                                    })
                                )}
                            </select>
                            <label>Type</label>
                            <select className="form-control mb-3" required value={editProduct.productTypeId}
                                onChange={(e) => {
                                    const index = e.target.selectedIndex - 1;
                                    const selectedItem = types.at(index);
                                    editProduct.productTypeId = selectedItem.productTypeId;
                                    editProduct.productTypeName = selectedItem.productTypeName;
                                    handleSupplierAccountOrderEditProduct(e);
                                }}>
                                <option value="0" key="0">-- Select Type --</option>
                                {types.length > 0 && (
                                    types.map((c, index) => {
                                        return (<>
                                            <option value={c.productTypeId} key={index}>{c.productTypeName}</option>
                                        </>)
                                    })
                                )}
                            </select>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Packing (Carton)</label>
                                    <input type="text"
                                        className="form-control"
                                        value={editProduct.packing}
                                        onChange={(e) => {
                                            setEditProduct((prevState) => ({
                                                ...prevState,
                                                packing: e.target.value
                                            }));
                                        }}></input>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label>Number of Containers</label>
                                    <input className="form-control" required value={editProduct.numberOfContainers}
                                        onChange={(e) => {
                                            setEditProduct((prevState) => ({
                                                ...prevState,
                                                numberOfContainers: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-6">
                                    <label>Unit</label>
                                    <select className="form-control" required value={editProduct.productUnitId}
                                        onChange={(e) => {
                                            const index = e.target.selectedIndex - 1;
                                            const selectedItem = units.at(index);
                                            setEditProduct((prevState) => ({
                                                ...prevState,
                                                productUnitId: selectedItem.productUnitId,// Number(e.target.value)
                                                unit: selectedItem.productUnit1
                                            }))
                                        }}>
                                        <option value="0" key="0">-- Select Units --</option>
                                        {units.length > 0 && (
                                            units.map((u, index) => {
                                                return (<>
                                                    <option value={u.productUnitId} key={index}>{u.productUnit1}</option>
                                                </>)
                                            })
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-6">
                                    <label>Quantity</label>
                                    <input className="form-control" required value={editProduct.quantity}
                                        onChange={(e) => {
                                            setEditProduct((prevState) => ({
                                                ...prevState,
                                                quantity: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-6">
                                    <label>Unit Price</label>
                                    <div className="input-group">
                                        <span className="input-group-text">{currency.currencySuffix}</span>
                                        <input className="form-control" required value={editProduct.unitPrice}
                                            onChange={(e) => {
                                                setEditProduct((prevState) => ({
                                                    ...prevState,
                                                    unitPrice: e.target.value
                                                }));
                                            }}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseEditProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => UpdateProduct(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteOrderProductModal} onHide={handleCloseDeleteOrderProductModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this product?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOrderProductModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteSupplierOrderProduct(deletingSupplierOrderProductId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddProductModal} onHide={handleCloseAddProductModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Order Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Product</label>
                            <Select
                                placeholder="-- Select Product --"
                                isSearchable={true}
                                options={products.map(c => ({
                                    value: c.productId,
                                    label: c.productName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    // handleSupplierChange(e.value);
                                    setProduct((prevState) => ({
                                        ...prevState,
                                        productId: e.value
                                    }));
                                }}>
                            </Select>

                            <label className="mt-3" >Size</label>
                            <Select
                                placeholder="-- Select Size --"
                                isSearchable={true}
                                options={sizes.map(c => ({
                                    value: c.productSizeId,
                                    label: c.productSizeName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    // handleSupplierChange(e.value);
                                    setProduct((prevState) => ({
                                        ...prevState,
                                        productSizeId: e.value
                                    }));
                                }}>
                            </Select>
                            <label className="mt-3">Type</label>
                            <Select
                                placeholder="-- Select Type --"
                                isSearchable={true}
                                options={types.map(c => ({
                                    value: c.productTypeId,
                                    label: c.productTypeName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    // handleSupplierChange(e.value);
                                    setProduct((prevState) => ({
                                        ...prevState,
                                        productTypeId: e.value
                                    }));
                                }}>
                            </Select>
                            <label className="mt-3">Packing (Carton)</label>
                            <input type="text" className="form-control"
                                onChange={(e) => {
                                    setProduct((prevState) => ({
                                        ...prevState,
                                        packing: e.target.value
                                    }));
                                }}></input>
                            <div className="row mb-3 mt-3">
                                <div className="col-6">
                                    <label>Number of Containers</label>
                                    <input type="number" className="form-control"
                                        onChange={(e) => {
                                            setProduct((prevState) => ({
                                                ...prevState,
                                                numberOfContainers: e.target.value
                                            }));

                                        }}></input>
                                </div>
                                <div className="col-6">
                                    <label>Unit</label>
                                    <Select
                                        placeholder="-- Select Unit --"
                                        isSearchable={true}
                                        options={units.map(c => ({
                                            value: c.productUnitId,
                                            label: c.productUnit1
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            // handleSupplierChange(e.value);
                                            setProduct((prevState) => ({
                                                ...prevState,
                                                productUnitId: e.value
                                            }));
                                        }}>
                                    </Select>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-6">
                                    <label>Quantity</label>
                                    <input type="number" className="form-control"
                                        onChange={(e) => {
                                            setProduct((prevState) => ({
                                                ...prevState,
                                                quantity: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-6">
                                    <label>Unit Price</label>
                                    <div className="input-group">
                                        <span className="input-group-text">{currency.currencySuffix}</span>
                                        <input type="number" className="form-control"
                                            onChange={(e) => {
                                                setProduct((prevState) => ({
                                                    ...prevState,
                                                    unitPrice: e.target.value
                                                }));

                                                handleSupplierAccountOrderProduct(e);
                                            }}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddProductModal}>
                        Close
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => AddProduct(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddAdvancedPayment} onHide={handleCloseAddAdvancedPayment}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment for {order.invoiceNumber}
                        {(orderTotal != null && orderTotal.remainingTotalAmount != null) &&
                            <h5><small>Pending Due Total: {orderTotal.remainingTotalAmount.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <>
                            <div className="col-6">
                                <label>Amount</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">{currency.currencySuffix}</span>
                                    <input type="number" className="form-control" required
                                        value={advancedPayment.supplierAdvancedPayment}
                                        onChange={(e) => {
                                            setAdvancedPayment((prevState) => ({
                                                ...prevState,
                                                supplierAdvancedPayment: e.target.value
                                            }));
                                            reloadBankAmount(e.target.value)
                                        }}>
                                    </input>
                                </div>
                            </div>
                            <div className="col-6">
                                <label>Percentage</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">%</span>
                                    <input type="number" className="form-control" required
                                        value={advancedPayment.supplierAdvancedPaymentPercentage}
                                        onChange={(e) => {
                                            setAdvancedPayment((prevState) => ({
                                                ...prevState,
                                                supplierAdvancedPaymentPercentage: e.target.value
                                            }));
                                            reloadAmountFromPercentage(e.target.value)
                                        }}>
                                    </input>
                                </div>
                            </div>
                        </>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3" type="date" value={advancedPayment.supplierAdvancedPaymentDate}
                                onChange={(e) => {
                                    setAdvancedPayment((prevState) => ({
                                        ...prevState,
                                        supplierAdvancedPaymentDate: e.target.value
                                    }));
                                }} />
                        </div>
                        <div className="col-12">
                            <label>Bank</label>
                            <select className="form-control mb-3" value={advancedPayment.supplierAdvancedPaymentBankId}
                                onChange={(e) => {
                                    setAdvancedPayment((prevState) => ({
                                        ...prevState,
                                        supplierAdvancedPaymentBankId: e.target.value
                                    }));
                                    setBankAccounts([])
                                    GetBankAccounts(e.target.value);
                                }}>
                                <option value="">-- Select Bank --</option>
                                {banks.map((b) => (
                                    <option value={b.bankId}>{b.bankName}</option>
                                )

                                )}
                            </select>
                        </div>
                        <div className="col-12">
                            <label>Bank Account</label>
                            <select className="form-control mb-3" value={advancedPayment.supplierAdvancedPaymentBankAccountId}
                                onChange={(e) => {
                                    setAdvancedPayment((prevState) => ({
                                        ...prevState,
                                        supplierAdvancedPaymentBankAccountId: e.target.value
                                    }));
                                    const bankAccount = bankAccounts.find(x => x.bankAccountId === Number(e.target.value));
                                    if (bankAccount) {
                                        setSameCurrency(bankAccount.currencyId == currency.currencyId);
                                        setBankCurrency(bankAccount.currencySuffix)
                                        if (bankAccount.currencyId == currency.currencyId) {
                                            setAdvancedPayment((prevState) => ({
                                                ...prevState,
                                                supplierAdvancedPaymentExchangeRate: 1,
                                                supplierAdvancedBankPayment: advancedPayment.supplierAdvancedPayment
                                            }))
                                        }
                                    } else {
                                        setSameCurrency(true);
                                        setAdvancedPayment((prevState) => ({
                                            ...prevState,
                                            supplierAdvancedPaymentExchangeRate: 1,
                                            supplierAdvancedBankPayment: advancedPayment.supplierAdvancedPayment
                                        }))
                                    }
                                }}>
                                <option value="">-- Select Bank Account --</option>
                                {bankAccounts.map((b) => (
                                    <option key={b.bankAccountId} value={b.bankAccountId}>{b.bankAccount1}</option>
                                ))}
                            </select>
                        </div>
                        {sameCurrency == false && (
                            <>
                                <div className="col-6">
                                    <label>Amount in Bank</label>
                                    <div className="input-group  mb-3">
                                        <span class="input-group-text">{bankCurrency}</span>
                                        <input type="number" className="form-control" required
                                            value={advancedPayment.supplierAdvancedBankPayment}
                                            onChange={(e) => {
                                                setAdvancedPayment((prevState) => ({
                                                    ...prevState,
                                                    supplierAdvancedBankPayment: e.target.value
                                                }));
                                                handleExchangeRate(e.target.value)
                                            }}>
                                        </input>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label>Exchange Rate</label>
                                    <input type="number" className="form-control mb-3" required
                                        value={advancedPayment.supplierAdvancedPaymentExchangeRate}
                                        onChange={(e) => {
                                            setAdvancedPayment((prevState) => ({
                                                ...prevState,
                                                supplierAdvancedPaymentExchangeRate: e.target.value
                                            }));
                                            handleBankAmount(e.target.value)
                                        }}>
                                    </input>
                                </div>
                            </>
                        )}

                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                onChange={(e) => {
                                    setAdvancedPayment((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddAdvancedPayment}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => AddAdvancedPayments(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAdvancedDiscount} onHide={handleCloseAdvancedDiscount}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Advanced Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Advanced Value</label>
                            <input type="number" className="form-control mb-3" required
                                onChange={(e) => {
                                    setAdvancedDiscount((prevState) => ({
                                        ...prevState,
                                        advancedDiscount: e.target.value
                                    }));
                                }}>
                            </input>
                        </div>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3" type="date"
                                value={advancedDiscount.discountDate}
                                onChange={(e) => setAdvancedDiscount((prevState) => ({
                                    ...prevState,
                                    discountDate: e.target.value
                                }))}></input>
                        </div>
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                onChange={(e) => {
                                    setAdvancedDiscount((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                        <div className="col-12" class="form-check form-switch form-switch-md mt-3 ms-3">
                            <input className="form-check-input" type="checkbox" id="customSwitch2" checked={advancedDiscount.addToStatement}
                                onChange={(e) =>
                                    setAdvancedDiscount((prevState) => ({
                                        ...prevState, addToStatement: e.target.checked
                                    })
                                    )} />
                            <label class="form-check-label" for="customSwitch2">Add discount to account statement</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAdvancedDiscount}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => AddAdvancedDiscount(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCommercialInvoice} onHide={handleCloseAddCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Commercial Invoice for Proforma #{order.invoiceNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="row ">
                                <div className="col-12 mb-3">
                                    <label>Invoice Number</label>
                                    <input className="form-control"
                                        placeholder="Enter commercial invoice number"
                                        value={commercialInvoice.invoiceNumber}
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                invoiceNumber: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-12 mb-3">
                                    <label>Date</label>
                                    <input className="form-control" type="date" defaultValue={commercialInvoice.date}
                                        onChange={(e) => setCommercialInvoice((prevState) => ({
                                            ...prevState,
                                            date: e.target.value
                                        }))}></input>
                                </div>
                                <div className="col-12">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        placeholder="Your notes (Optional)"
                                        value={commercialInvoice.notes}
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                notes: e.target.value
                                            }));
                                        }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialInvoice}>
                        Close
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => AddCommericalInvoice(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteCommercialInvoice} onHide={handleCloseDeleteInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this commercial invoice?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseDeleteInvoice}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => DeleteInvoice(e)}>
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCommercialProduct} onHide={handleCloseAddCommercialProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Product</label>
                            <select className="form-control mb-3" required
                                onChange={(e) => {
                                    setCommercialProduct((prevState) => ({
                                        ...prevState,
                                        supplierProductId: e.target.value,
                                        unit: orderProducts.find(x => x.supplierOrderProductId == e.target.value).productUnit1
                                    }));
                                }}>
                                <option value="0" key="0">-- Select Product --</option>
                                {orderProducts.length > 0 && (
                                    orderProducts.map((c, index) => {
                                        return (<>
                                            <option value={c.supplierOrderProductId} key={index}>{c.productName + ", " + c.productSizeName + ", " + c.productTypeName} {"- FCL:"} {c.pendingCfcl} {"- Qty:"} {c.pendingCquantity} {"- Total Price:"} {(c.pendingCquantity * c.unitPrice).toLocaleString()}</option>
                                        </>)
                                    })
                                )}
                            </select>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Number of Containers</label>
                                    <input type="number" className="form-control"
                                        onChange={(e) => {
                                            setCommercialProduct((prevState) => ({
                                                ...prevState,
                                                fcl: e.target.value
                                            }));
                                        }}></input>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-12">
                                    {commercialProduct.unit == null ? <label>Quantity</label> : <label>Quantity ({commercialProduct.unit})</label>}
                                    <input type="number" className="form-control"
                                        onChange={(e) => {
                                            setCommercialProduct((prevState) => ({
                                                ...prevState,
                                                productQuantity: e.target.value
                                            }));
                                        }}></input>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => AddCommercialProduct(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditCommercialProduct} onHide={handleCloseEditCommercialProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Product</label>
                            <select className="form-control mb-3" disabled={true} value={editCommercialProduct.supplierProductId}>
                                <option value="0" key="0">-- Select Product --</option>
                                {orderProducts.length > 0 && (
                                    orderProducts.map((c, index) => {
                                        return (<>
                                            <option value={c.supplierOrderProductId} key={index}>{c.productName}</option>
                                        </>)
                                    })
                                )}
                            </select>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Number of Containers</label>
                                    <input type="number"
                                        className="form-control"
                                        value={editCommercialProduct.fcl}
                                        onChange={(e) => {
                                            setEditCommercialProduct((prevState) => ({
                                                ...prevState,
                                                fcl: e.target.value
                                            }));

                                        }}></input>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-12">
                                    {commercialProduct.unit == null ? <label>Quantity</label> : <label>Quantity ({commercialProduct.unit})</label>}
                                    <input type="number" className="form-control"
                                        value={editCommercialProduct.productQuantity}
                                        onChange={(e) => {
                                            setEditCommercialProduct((prevState) => ({
                                                ...prevState,
                                                productQuantity: e.target.value
                                            }));
                                        }}></input>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseEditCommercialProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => EditCommercialProduct(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteCommercialProduct} onHide={handleCloseDeleteCommercialProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this product?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseDeleteCommercialProduct}>
                        Cancel
                    </button>
                    <button className="btn btn-danger" onClick={(e) => DeleteCommercialProduct(e)}>
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCommercialInvoicePayment} onHide={handleCloseAddCommercialInvoicePayment}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment for #{commercialInvoicePayment.commercialInvoiceNumber}
                        <h5><small>Remaining Total: {currency.currencySuffix} {commercialInvoicePayment.remainingTotal.toLocaleString()}</small></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label>Amount</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">{currency.currencySuffix}</span>
                                        <input className="form-control" type="number" step="0.01"
                                            onChange={(e) => {
                                                setCommercialInvoicePayment((prevState) => ({
                                                    ...prevState,
                                                    paymentValue: e.target.value
                                                }));
                                                reloadBankAmountCommercial(e.target.value)
                                            }}></input>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label>Date</label>
                                    <input className="form-control" type="date" value={commercialInvoicePayment.paymentDate}
                                        onChange={(e) => {
                                            setCommercialInvoicePayment((prevState) => ({
                                                ...prevState,
                                                paymentDate: e.target.value
                                            }));
                                        }}></input>
                                </div>

                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Bank</label>
                                    <select className="form-control"
                                        onChange={(e) => {
                                            setCommercialInvoicePayment((prevState) => ({
                                                ...prevState,
                                                commercialInvoicePaymentBankId: e.target.value
                                            }));
                                            GetBankAccounts(e.target.value)
                                        }}>
                                        <option>-- Select Bank --</option>
                                        {banks.map((b) => (
                                            <option value={b.bankId}>{b.bankName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Bank Accounts</label>
                                    <select className="form-control"
                                        onChange={(e) => {
                                            setCommercialInvoicePayment((prevState) => ({
                                                ...prevState,
                                                commercialInvoicePaymentBankAccountId: e.target.value
                                            }));
                                            CheckCurrency(e.target.value)
                                        }}>
                                        <option>-- Select Bank Account --</option>
                                        {bankAccounts.map((ba) => (
                                            <option value={ba.bankAccountId}>{ba.bankAccount1}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {sameCurrency == false && (
                                <div className="row mb-3">
                                    <div className="col-6">
                                        <label>Bank Amount</label>
                                        <input className="form-control" type="number"
                                            value={commercialInvoicePayment.bankAmount}
                                            onChange={(e) => {
                                                setCommercialInvoicePayment((prevState) => ({
                                                    ...prevState,
                                                    bankAmount: e.target.value
                                                }));
                                                CalculateExchangeRateCommercial(e.target.value)
                                            }}></input>
                                    </div>
                                    <div className="col-6">
                                        <label>Exchange Rate</label>
                                        <input className="form-control" type="number"
                                            value={commercialInvoicePayment.commercialInvoiceExchangeRate}
                                            onChange={(e) => {
                                                setCommercialInvoicePayment((prevState) => ({
                                                    ...prevState,
                                                    commercialInvoiceExchangeRate: e.target.value
                                                }));
                                                CalculateBankAmountCommercial(e.target.value)
                                            }}></input>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-12">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        onChange={(e) => {
                                            setCommercialInvoicePayment((prevState) => ({
                                                ...prevState,
                                                notes: e.target.value
                                            }));
                                        }}></textarea>
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialInvoicePayment}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => AddCommercialInvoicePayment(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCommercialDiscount} onHide={handleCloseAddCommercialDiscount}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Discount for #{commercialDiscount.commercialInvoiceNumber}
                        <h5><small>Remaining Total: {currency.currencySuffix} {commercialDiscount.remainingTotal.toLocaleString()}</small></h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Amount</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">{currency.currencySuffix}</span>
                                <input className="form-control"
                                    type="number"
                                    step="1"
                                    placeholder="Enter the discount amount"
                                    onChange={(e) => {
                                        setCommercialDiscount((prevState) => ({
                                            ...prevState,
                                            commercialInvoiceDiscount: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3" type="date" value={commercialDiscount.discountDate}
                                onChange={(e) => {
                                    setCommercialDiscount((prevState) => ({
                                        ...prevState,
                                        discountDate: e.target.value
                                    }));
                                }}></input>
                        </div>
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                placeholder="Your notes (Optional)"
                                onChange={(e) => {
                                    setCommercialDiscount((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                        <div className="col-12" class="form-check form-switch form-switch-md mt-3 ms-3">
                            <input className="form-check-input" type="checkbox" id="customSwitch2" checked={commercialDiscount.addToStatement}
                                onChange={(e) =>
                                    setCommercialDiscount((prevState) => ({
                                        ...prevState, addToStatement: e.target.checked
                                    })
                                    )} />
                            <label class="form-check-label" for="customSwitch2">Add discount to account statement</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialDiscount}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => AddCommercialDiscount(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditSupplierOrder} onHide={handleCloseEditSupplierOrder}>
                <Modal.Header closeButton>
                    <Modal.Title>Update info for #{order.invoiceNumber}
                        {/* <h5><small>Remaining Total: {currency.currencySuffix} {commercialDiscount.remainingTotal.toLocaleString()}</small></h5> */}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Invoice Number</label>
                            <div className="input-group mb-3">
                                {/* <span className="input-group-text">{currency.currencySuffix}</span> */}
                                <input className="form-control"
                                    type="text"
                                    placeholder="Enter the invoice number"
                                    value={editingOrder.invoiceNumber}
                                    onChange={(e) => {
                                        setEditingOrder((prevState) => ({
                                            ...prevState,
                                            invoiceNumber: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3"
                                type="date"
                                value={editingOrder.date}
                                onChange={(e) => {
                                    setEditingOrder((prevState) => ({
                                        ...prevState,
                                        date: e.target.value
                                    }));
                                }}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Payment Terms</label>
                            <Select
                                placeholder="-- Select Payment Terms --"
                                isSearchable={true}
                                value={editingOrder.paymentTermsId > 0 ? { label: editingOrder.paymentTermsName, value: editingOrder.paymentTermsId } : null}
                                options={paymentTerms.map(p => ({
                                    value: p,
                                    label: p.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingOrder((prevState) => ({
                                        ...prevState,
                                        paymentTermsId: e.value.paymentTermsId,
                                        paymentTermsName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                        <div className="col-12">
                            <label>Other Charges</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">{currency.currencySuffix}</span>
                                <input className="form-control"
                                    type="number"
                                    step="1"
                                    value={editingOrder.otherCharges}
                                    placeholder="Other charges (Optional)"
                                    onChange={(e) => {
                                        setEditingOrder((prevState) => ({
                                            ...prevState,
                                            otherCharges: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                        </div>

                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                placeholder="Your notes (Optional)"
                                value={editingOrder.notes}
                                onChange={(e) => {
                                    setEditingOrder((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseEditSupplierOrder}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => submitSupplierOrderForm(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteSupplierOrderModal} onHide={handleCloseDeleteSupplierOrderModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this order?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteSupplierOrderModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteSupplierOrder(pId.OrderId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal size="md" show={showAddCommercialInvoiceCost} onHide={handleCloseAddCommercialInvoiceCost}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Cost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Cost Type</label>
                            <Select
                                required
                                placeholder="-- Select Type --"
                                defaultValue={commercialInvoiceCost.shippingCostOptionName ? { label: commercialInvoiceCost.shippingCostOptionName, value: commercialInvoiceCost.shippingCostOptionId } : null}
                                isSearchable={true}
                                options={shippingCostTypes.map(f => ({
                                    value: f,
                                    label: f.name
                                }))}

                                valuekey
                                onChange={(e) => {
                                    setCommercialInvoiceCost((prevState) => ({
                                        ...prevState,
                                        shippingCostOptionId: e.value.shippingCostsOptionId,
                                        shippingCostOptionName: e.value.name,
                                    }));
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Amount</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    {currency.currencySuffix}
                                </span>

                                <input type="number" className="form-control" required
                                    value={commercialInvoiceCost.amount}
                                    // disabled={shippingCost.isPaid === true}
                                    onChange={(e) => {
                                        setCommercialInvoiceCost((prevState) => ({
                                            ...prevState,
                                            amount: e.target.value
                                        }))
                                    }}>
                                </input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddCommercialInvoiceCost}>
                        Close
                    </Button>
                    <Button variant="primary"
                        disabled={submitDisabled} onClick={(e) =>
                            submitCommercialInvoiceCostForm(e)
                        }>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal size="md" show={showDeleteCommercialInvoiceCost} onHide={handleCloseDeleteCommercialInvoiceCost}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Cost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this cost?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCommercialInvoiceCost}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteCommercialInvoiceCost(deletingCommercialInvoiceCostId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

        </>)
}
export default ViewSupplierOrder