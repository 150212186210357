import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';

const ManageShippingCompanies = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [shippingCompanyName, setShippingCompanyName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [companies, setCompanies] = useState([]);

    const GetShippingCompany = (shippingCompanyId) => {
        var config = {
            method: 'get',
            url: "ShippingCompany/GetShippingCompany/" + shippingCompanyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingCompanyName(response.data.shippingCompanyName)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCompanies = () => {
        var config = {
            method: 'get',
            url: "Company/GetCompany",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { 
                shippingCompanyName: shippingCompanyName,
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!") 
            }
            if (pId.ShippingCompanyId == "0") { //Add Product Size
                var config = {
                    method: 'post',
                    url: "ShippingCompany/AddShippingCompany",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Forwarder Added Successfully")
                            navigate("/Logistics/ShippingCompany")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Product Size
                var config = {
                    method: 'put',
                    url: "ShippingCompany/EditShippingCompany/" + pId.ShippingCompanyId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Forwarder Updated Successfully")
                            navigate("/Logistics/ShippingCompany")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
if (shippingCompanyName == "") {
            toast.error("Enter Forwarder Name");
            return false;
        }
        return true;
    }

    useEffect(() => {
        document.title = "Manage Forwarder";
        GetCompanies();
        if (pId.PositionId != "0") {
            setPageType("Edit");
            GetShippingCompany(pId.ShippingCompanyId)
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Forwarders</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Logistics/Forwarder">Forwarders</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Forwarders</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Forwarders</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Logistics/ShippingCompany" className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-12">
                                            <label htmlFor="txtShippingCompanyName" className="col-md-6 col-form-label">Forwarder name</label>
                                            <input className="form-control" type="text" value={shippingCompanyName} id="ShippingCompanyName" onChange={(e) => setShippingCompanyName(e.target.value)} required />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label className="col-md-2 col-form-label">Company</label>
                                            <select className="form-control" value={companyId} onChange={(e) => setCompanyId(e.target.value)} required>
                                                <option value="0" key="0">-- Select Company Name --</option>
                                                {companies.length > 0 && (
                                                    companies.map((c, index) => {
                                                        return (<>
                                                            <option value={c.companyId} key={index}>{c.companyName}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
export default ManageShippingCompanies