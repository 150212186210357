import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../Variables/Global"
import axiosInstance from '../../../../api/axiosConfig';

const ManageAccountCustomer = (props) => {
     
    const pId = useParams();
    const navigate = useNavigate();
    const [pageType, setPageType] = useState("Add")
    const [accountName, setAccountName] = useState("")
    const [currencyId, setCurrencyId] = useState(0)
    const [companyId, setCompanyId] = useState(0)
    const [currencies, setCurrencies] = useState([])

    const [companies, setCompanies] = useState([])

    const GetCompanies = () => {
        var config = {
            method: 'get',
            url: "Company/GetCompany",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data)
                setCompanies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCurrencies = () => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrencies",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data)
                setCurrencies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCustomerAccount = (customerAccountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccount/" + customerAccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccountName(response.data.customerAccount1)
                setCurrencyId(response.data.currencyId)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = {
                accountName: accountName,
                accountName: accountName,
                currencyId: currencyId,
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!")
            }
            if (pId.AccountId == "0") { //Add Customer Account
                var config = {
                    method: 'post',
                    url: "CustomerAccount/AddCustomerAccount/" + pId.CustomerId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Account Added Successfully")
                            // navigate("/Accounting/Customer?CustomerId=" + pId.CustomerId);
                            // navigate("/Accounting/Customer?CustomerId=" + pId.CustomerId);
                            // navigate(`/Accounting/Customer/${pId.CustomerId}/Account/${response.data}/Order/0`);
                            navigate(`/Accounting/Customer/${pId.CustomerId}/Account/${response.data}/Orders`);
                            
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Customer Account
                var config = {
                    method: 'put',
                    url: "CustomerAccount/EditCustomerAccount/" + pId.AccountId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Account Updated Successfully")
                            props.setShowCustomerAccountList(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }

    }
    const IsValid = () => {
        if (accountName == "" && currencyId == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (accountName == "") {
            toast.error("Enter Account Name");
            return false;
        }
        else if (currencyId == "") {
            toast.error("Enter Currency");
            return false;
        }
        return true;
    }
    useEffect(() => {
        GetCompanies();
        GetCurrencies();
        console.log(pId.AccountId)
        if (pId.AccountId != 0) {
            GetCustomerAccount(pId.AccountId)
            setPageType("Edit");
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    <li className="breadcrumb-item"></li>
                                    <li className="breadcrumb-item active">Client Account</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Client Account</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link className="btn btn-secondary mr-3" to={"/Accounting/Customer"}>Back</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Account Name</label>
                                            <input className="form-control" type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Currency</label>
                                            <select className="form-control" value={currencyId} onChange={(e) => setCurrencyId(e.target.value)} required>
                                                <option value="0" key="0">-- Select Currency Name --</option>
                                                {currencies.length > 0 && (
                                                    currencies.map((c, index) => {
                                                        return (<>
                                                            <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default ManageAccountCustomer