
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Global } from '../../Variables/Global';
import { getDecodedToken } from '../../utils/utils';
import axiosInstance from '../../api/axiosConfig';
import { Button, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select from "react-select";


const ManageCompany = () => {

    var pId = useParams();
    var navigate = useNavigate();

    const [pageType, setPageType] = useState("Add");

    const [company, setCompany] = useState({
        companyId: "",
        companyName: "",
        logo: null,
        phone: "",
        website: "",
        email: "",
        suffix: "",
    });


    const GetCompanies = (CompanyId) => {
        var config = {
            method: 'get',
            url: "Company/GetCompany/" + CompanyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setCompany(response.data);
                setCompanyAddresses(response.data.companyAddresses);
                console.log("API response:", response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCurrencies = () => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrenciesByBank",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrencies(response.data);
                console.log("API response:", response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        if (pId.CompanyId != "0") {
            document.title = "Edit Company";
            setPageType("Edit");
            setSelectedCurrency("");
            GetCompanies(pId.CompanyId);
            GetCurrencies();
        } else {
            document.title = "Add Company";
        }
    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file selected
        setCompany({ ...company, logo: file });
    };

    const IsValid = () => {
        if (company.companyName == "" || company.phone == "" || company.email == "" || company.website == "" || company.logo == "") {
            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const [selectedFiles, setselectedFiles] = useState([])
    const handleAcceptedFiles = (acceptedFiles) => {

        //Mapped the preview before uploading 
        const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        }));
        setselectedFiles(prevFiles => [...prevFiles, ...mappedFiles]);

        submitForm(acceptedFiles);
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (IsValid()) {
            const formData = new FormData();
            formData.append('logo', company.logo);
            formData.append('companyId', company.companyId);
            formData.append('companyName', company.companyName);
            formData.append('phone', company.phone);
            formData.append('email', company.email);
            formData.append('website', company.website);
            formData.append('userId', localStorage.getItem("uTsReD"));

            var config = {
                method: 'post',
                url: "Company/AddEditCompany",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: formData
            };

            try {
                const response = await axiosInstance(config);
                if (response.status === 200) {
                    toast.success("Company Updated Successfully");
                    navigate("/Company");

                }
            } catch (error) {
                
                navigate("/Company");
                console.log(error);
                toast.error("Something Went Wrong");
            }
        }
    };
    const [companyAddresses, setCompanyAddresses] = useState([])
    const columns = [
        //{
        //    name: '#',
        //    selector: row => row.companyAddressId,
        //    width: "40px"
        //},
        {
            name: 'Country',
            selector: row => row.country,
        },
        {
            name: 'City',
            selector: row => row.city,
        },
        {
            name: 'Street',
            selector: row => row.street,
        },
        {
            name: 'Floor',
            selector: row => row.floor,
        },
        {
            name: 'Building',
            selector: row => row.building,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {permissions.editCompany == true && (
                            <Link to={"/company/" + pId.CompanyId + "/" + row.companyAddressId} className="btn btn-outline-warning mr-3">
                                <FiEdit />
                            </Link>
                        )}

                        {permissions.editCompany == true &&
                            <button type="button" className="btn btn-outline-danger" onClick={() => {
                                handleDeleteAddressClick(row.companyAddressId);
                            }}>
                                <FiTrash2 />
                            </button>}
                    </>
                )
            },
        },
    ];

    const [currencies, setCurrencies] = useState([])
    const currencyColumns = [
        //{
        //    name: '#',
        //    selector: row => row.companyAddressId,
        //    width: "40px"
        //},
        {
            name: 'CurrencyName',
            selector: row => row.currencyName,
        },
        {
            name: 'CurrencySuffix',
            selector: row => row.currencySuffix,
        },
        {
            name: 'Invoice Bank Description',
            selector: row => row.currencyInvoiceBankDescription,
        },
        {
            name: 'Iban',
            selector: row => row.iban,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>

                        {permissions.addCustomer === true &&
                            <button className='btn btn-outline-warning mr-3' onClick={() => {
                                handleAddBankAccount(row.currencyId);
                                //GetCustomerAddresses(c.customerId);
                                //setCustomerAddress({});
                            }}><FiEdit /></button>
                        }
                    </>
                )
            },
        },
    ];
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [showBankAccountsModal, setShowBankAccountsModal] = useState(false);
    const [currencyInvoiceBank, setCurrencyInvoiceBank] = useState("");
    const [currencyInvoiceBankDescription, setCurrencyInvoiceBankDescription] = useState("");

    const handleAddBankAccount = (currencyId) => {
        setSelectedCurrency(currencyId);
        GetBankAccounts(currencyId);
        console.log(currencyId)
        setShowBankAccountsModal(true);
        GetCurrency(currencyId);
    }
    const handleCloseBankAccountsModal = () => {
        setShowBankAccountsModal(false)
        setSelectedCurrency("")
    }

    const [showDeleteCompanyAddressIdModal, setshowDeleteCompanyAddressIdModal] = useState(false);
    const [companyAddressIdToDelete, setCompanyAddressIdToDelete] = useState(null);

    const handleDeleteAddressClick = (companyAddressId) => {
        setCompanyAddressIdToDelete(companyAddressId);
        setshowDeleteCompanyAddressIdModal(true);
    };

    const handleConfirmDeleteCompanyAddress = () => {
        DeleteCompanyAddress(companyAddressIdToDelete);
        setshowDeleteCompanyAddressIdModal(false);
    };
    const DeleteCompanyAddress = (companyAddressIdToDelete) => {
        var config = {
            method: 'post',
            url: "Company/DeleteCompanyAddressId/" + companyAddressIdToDelete,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetCompanies(pId.CompanyId);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }

    const [BankAccounts, setBankAccounts] = useState([])
    const GetBankAccounts = (currencyId) => {
        var config = {
            method: 'Post',
            url: "Bank/GetBankByCurrencyID/" + currencyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setBankAccounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrencyInvoiceBank(response.data.currencyInvoiceBank)
                setCurrencyInvoiceBankDescription(response.data.currencyInvoiceBankDescription)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitBankAccountForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = {
                currencyId: selectedCurrency, companyId: localStorage.getItem("C0m8N!"),
                currencyInvoiceBank: currencyInvoiceBank, userId: localStorage.getItem("uTsReD"), currencyInvoiceBankDescription: currencyInvoiceBankDescription
            }
            var config = {
                method: 'post',
                url: "Currency/EditCurrencyBankAccount",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: body
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Bank Account Added Successfully")
                        setShowBankAccountsModal(false)
                        GetCurrencies();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
        }
    }

    return (
        <>
            {permissions.viewCompany == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Company Details</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Companies</li>
                                        </ol>
                                        {/*View the image logo*/}
                                        {/*<div style={{ display: 'flex', alignItems: 'center' }}>*/}
                                        {/*    <img*/}
                                        {/*        src={company.logo}*/}
                                        {/*        alt={company.logo}*/}
                                        {/*        height="50"*/}
                                        {/*        width="100"*/}
                                        {/*        className="auth-logo-light"*/}
                                        {/*        style={{ marginRight: '10px', marginTop: '10px' }}*/}
                                        {/*    />*/}
                                        {/*    <div style={{ marginLeft: '10px' }}>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">{pageType} Company Details</h4>
                                                </div>
                                                <div className="col-12 col-md-6 text-right">
                                                    <Link to="/Company" className="btn btn-secondary mr-3">Cancel</Link>
                                                    <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Save</button>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-4">
                                                    <label htmlFor="txtCompanyName" className="col-md-12 col-form-label">Company Name</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={company.companyName}
                                                        id="txtCompanyName"
                                                        required
                                                        onChange={(e) => {
                                                            setCompany((prevState) => ({
                                                                ...prevState,
                                                                companyName: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="txtCompanysuffix" className="col-md-12 col-form-label">Company suffix</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        disabled
                                                        value={company.suffix}
                                                        id="txtCompanysuffix"
                                                        required
                                                        onChange={(e) => {
                                                            setCompany((prevState) => ({
                                                                ...prevState,
                                                                suffix: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="txtCompanyphone" className="col-md-12 col-form-label">Phone Number</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={company.phone}
                                                        id="txtCompanyphone"
                                                        required
                                                        onChange={(e) => {
                                                            setCompany((prevState) => ({
                                                                ...prevState,
                                                                phone: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="txtCompanyEmail" className="col-md-12 col-form-label">Company Email</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={company.email}
                                                        id="txtCompanyEmail"
                                                        required
                                                        onChange={(e) => {
                                                            setCompany((prevState) => ({
                                                                ...prevState,
                                                                email: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="txtCompanywebsite" className="col-md-12 col-form-label">Company Website</label>
                                                    <input className="form-control"
                                                        type="text"
                                                        value={company.website}
                                                        id="txtCompanywebsite"
                                                        required
                                                        onChange={(e) => {
                                                            setCompany((prevState) => ({
                                                                ...prevState,
                                                                website: e.target.value,
                                                            }))
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="filelogo" className="col-md-12 col-form-label">Logo</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        required
                                                        id="filelogo"
                                                        onChange={handleFileChange}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="card-title">List of Address</label>
                                                <Link to={`/Company/${pId.CompanyId}/0`} className="btn btn-primary">
                                                    Create New Address
                                                </Link>
                                            </div>
                                            <div className="col-12">
                                                <DataTable columns={columns} data={companyAddresses} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <label className="card-title">Default Invoicing bank accounts</label>
                                                {/*<Link to={`/Company/${pId.CompanyId}/0`} className="btn btn-primary">*/}
                                                {/*    Create New Address*/}
                                                {/*</Link>*/}
                                            </div>
                                            <div className="col-12">
                                                <DataTable columns={currencyColumns} data={currencies} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={showDeleteCompanyAddressIdModal} onHide={() => setshowDeleteCompanyAddressIdModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete company address?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setshowDeleteCompanyAddressIdModal(false)}>
                                    No
                                </Button>
                                <Button variant="danger" onClick={handleConfirmDeleteCompanyAddress}>
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showBankAccountsModal} onHide={handleCloseBankAccountsModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Manage Account</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="mb-7 row">
                                    <div className="col-md-10">
                                        <label>Invoice Bank Account</label>
                                        <Select
                                            required
                                            placeholder="Select Invocie Bank"
                                            isSearchable={true}
                                            options={BankAccounts.map(i => ({
                                                value: i.bankAccountId,
                                                label: i.bankName
                                            }))}
                                            valuekey
                                            onChange={(e) => {
                                                setCurrencyInvoiceBank(e.value)
                                                setCurrencyInvoiceBankDescription(e.label)
                                            }}>
                                        </Select>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseBankAccountsModal}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={(e) => submitBankAccountForm(e)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </>
    )
}
export default ManageCompany