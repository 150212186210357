import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Global } from '../../../../Variables/Global';
import { formatDate } from "../../../../Classes/Util";
import axiosInstance from '../../../../api/axiosConfig';

const ListCommercialDiscount = (props) => {
    const pId = useParams();

     
    const [showAddCommercialDiscount, setShowAddCommercialDiscount] = useState(false);
    const handleCloseAddCommercialDiscount = () => setShowAddCommercialDiscount(false);
    const handleShowAddCommercialDiscount = () => setShowAddCommercialDiscount(true);
    const [commercialDiscounts, setCommercialDiscounts] = useState([])

    const [commercialDiscount, setCommercialDiscount] = useState({
        commercialInvoiceId: pId.InvoiceId,
        commercialInvoiceDiscount: "0",
        discountDate: formatDate(new Date()),
        notes: "",
        commercialInvoice: "",
        proforma: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        addToStatement: false
    })

    const GetCommercialDiscounts = (invoiceId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialDiscount/GetSupplierCommercialDiscount/" + invoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCommercialDiscounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const AddCommercialDiscount = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "SupplierCommercialDiscount/AddSupplierCommercialDiscount/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: commercialDiscount
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully")
                    setShowAddCommercialDiscount(false)
                    GetCommercialDiscounts(pId.InvoiceId)
                    props.GetSupplierCommericalInvoiceTotal(pId.InvoiceId)
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const columns = [
        {
            name: '#',
            selector: row => row.commercialInvoiceDiscountId,
            width: "60px"
        },
        {
            name: "Discount Value",
            selector: row => row.commercialInvoiceDiscount + " " + props.currencySuffix,
            width: "150px"
        },
        {
            name: "Date",
            selector: row => row.discountDate.split("T")[0],
            width: "150px"
        },
        {
            name: "Notes",
            selector: row => row.notes,
        }
    ]


    const GetSupplierOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCommercialDiscount((prevState) => ({
                    ...prevState,
                    proforma: response.data.invoiceNumber
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCommercialInvoice = (invoiceId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoice/" + invoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // setSupplierCommercialInvoice(response.data)
                setCommercialDiscount((prevState) => ({
                    ...prevState,
                    commercialInvoice: response.data.invoiceNumber
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetCommercialDiscounts(pId.InvoiceId);
        GetSupplierOrder(pId.OrderId)
        GetCommercialInvoice(pId.InvoiceId);
        // GetSupplierCommericalInvoiceTotal(pId.InvoiceId)
        console.log(commercialDiscount)
    }, [])
    return (
        <>
            <div className="row mt-5">
                <div className="col-12 col-md-6">
                    <h5>List Commercial Discount</h5>
                </div>
                <div className="col-12 col-md-6 text-right">
                    <button className="btn btn-primary" onClick={handleShowAddCommercialDiscount}>Add Discount</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-12'>
                    <DataTable data={commercialDiscounts} columns={columns} />
                </div>
            </div>
            <Modal show={showAddCommercialDiscount} onHide={handleCloseAddCommercialDiscount}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Add Commercial Discount</Modal.Title>
                </Modal.Header>*/}
                <Modal.Header closeButton> 
                    <Modal.Title>Add Discount for #{props.invoiceNumber}
                    <h5><small>Remaining Total: {props.remainingTotal.toLocaleString() + " " + props.currencySuffix}</small></h5>
                    </Modal.Title>
                </Modal.Header>
                                              
                <Modal.Body>
                    <div className="row">
                        {/* <div className="col-12">
                            <label>Discount Value</label>
                            <input className="form-control mb-3" required
                                onChange={(e) => {
                                    setDiscount((prevState) => ({
                                        ...prevState,
                                        commercialInvoiceDiscount: e.target.value
                                    }));
                                    setSupplierAccountCommercialDiscount((prevState) => ({
                                        ...prevState,
                                        supplierAdvancedDiscount: e.target.value
                                    }));
                                }}>
                            </input>
                        </div> */}
                        <div className="col-12">
                            <label>Amount</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">{props.currencySuffix}</span>
                                <input className="form-control" type="number" step="0.01"
                                    onChange={(e) => {
                                        setCommercialDiscount((prevState) => ({
                                            ...prevState,
                                            commercialInvoiceDiscount: e.target.value
                                        }));
                                    }}>
                                    </input>
                                </div>
                        </div>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3" type="date"  value={commercialDiscount.discountDate}
                                onChange={(e) => {
                                    setCommercialDiscount((prevState) => ({
                                        ...prevState,
                                        discountDate: e.target.value
                                    }));
                                }}></input>
                        </div>
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                onChange={(e) => {
                                    setCommercialDiscount((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                        {/* <div className="col-12">
                            <input className="mt-3" type="checkbox" checked={discount.addToStatement} 
                                    onChange={(e) =>
                                        setDiscount((prevState) => ({
                                            ...prevState, addToStatement: e.target.checked 
                                        })
                                    )} />
                            <label className="ms-1">Add discount to account statement</label>
                        </div> */}
                        <div className="col-12" class="form-check form-switch form-switch-md mt-3 ms-3">
                            <input className="form-check-input" type="checkbox" id="customSwitch2" checked={commercialDiscount.addToStatement} 
                                    onChange={(e) =>
                                        setCommercialDiscount((prevState) => ({
                                            ...prevState, addToStatement: e.target.checked 
                                        })
                                    )} />
                            <label class="form-check-label" for="customSwitch2">Add discount to account statement</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialDiscount}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => AddCommercialDiscount(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ListCommercialDiscount