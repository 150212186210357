import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Global } from '../../Variables/Global';
import axiosInstance from '../../api/axiosConfig';

const SelectCompany = (props) => {
     
    const [selectedComapany, setSelectedCompany] = useState(0)
    const [companies, setCompanies] = useState([]);
    const [user, setUser] = useState("");
    const navigate = useNavigate()
    const GetAssignedComapnies = (userId) => {
        var config = {
            method: 'get',
            url: "Company/GetAssignedCompanies/" + userId,
            headers: { },
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanies(response.data);
                if (response.data.length == 1) {
                    localStorage.setItem("C0m8N!", response.data[0].companyId)
                    props.setCompanyId(response.data[0].companyId);
                    navigate("/");
                }
                if (response.data.length > 1) {
                    localStorage.setItem("C0m8X!", 1)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetUser = (userId) => {
        var config = {
            method: 'get',
            url: 'User/GetUser/' + userId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    setUser(() => response.data);

                }
            })
            .catch(function (error) {
                toast.error(error)
            });
    }
    const Selected = (e) => {
        localStorage.setItem("C0m8N!", selectedComapany)
        props.setCompanyId(selectedComapany);
    }
    useEffect(() => {
        var userId = localStorage.getItem("uTsReD");
        GetAssignedComapnies(userId)
        GetUser(userId)
    }, [])
    return (
        <>
            <div class="account-pages my-5 pt-sm-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6 col-xl-5">
                            <div class="card overflow-hidden">
                                <div class="card-body pt-0">
                                    <div class="p-3">
                                        <h4 class="text-muted font-size-18 mb-4 text-center">Mercators</h4>
                                        <p class="text-muted text-center">Hello {user.fullName}, Select a company to continue!</p>

                                        <div class="user-thumb text-center mb-4">
                                            <select onChange={(e) => setSelectedCompany(e.target.value)} className="form-control">
                                                <option defaultValue>-- Select a company -- </option>
                                                {companies.map((c) => (
                                                    <option value={c.companyId}>{c.companyName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <button onClick={(e) => Selected(e)} className="btn btn-success">Go</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 text-center">
                                © <script>document.write(new Date().getFullYear())</script>2022 Mercators <span class="d-none d-sm-inline-block"> - Developed By: Taleb Atoui.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SelectCompany