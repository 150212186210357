import axios from "axios";
import React, { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import { forEach } from "lodash-es";
import { FiPlus } from 'react-icons/fi';
import { getDecodedToken } from '../../utils/utils';
// fas fa-anchor
import DataTable, { Alignment } from "react-data-table-component";
import axiosInstance from '../../api/axiosConfig';
// import 'morris.js/morris.css';
// import 'morris.js/morris.js';
/*const Dashboard = () => {

    useEffect(() => {
        const el = document.getElementById('myfirstchart');

        if (el.classList.contains('init')) {
            return;
        }

        window.Morris.Donut({
            element: el,
            data: [
                {
                    label: "a",
                    value: 49,
                },
                {
                    label: "b",
                    value: 51,
                },
            ],
            colors: ["#5f76e8", "#01caf1"],
            formatter: function (value, data) {
                return value + "%";
            },
        });

        el.classList.add('init');
    }, []);

    return (
        <>
            <div id="myfirstchart" style={{ height: '250px', marginTop: '100px' }} />
        </>
    )
}

export default Dashboard;*/


const Dashboard = () => {
    const [ordersCount, setOrdersCount] = useState(0);
    const [productsSoldCount, setProductsSoldCount] = useState(0);
    const [topProfitableClients, setTopProfitableClients] = useState([]);
    const [chartData1, setChartData1] = useState([{label:'test',value:4}]);
    const [chartData2, setChartData2] = useState([]);
    const GetDashboardReports = () => {
        // if(chartData1.length  > 1) return;
        var config = {
            method: 'get',
            url: 'Reports/GetDashboardReports/' + localStorage.getItem("C0m8N!"),
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // setCurrenciesProfits(response.data);
                setOrdersCount(response.data.ordersCount);
                setProductsSoldCount(response.data.productsSoldCount);
                setTopProfitableClients(response.data.topProfitableClients);
                
                const arr1 = response.data.topProfitableClients[0].chartData;
                const colors1 = response.data.topProfitableClients[0].chartColors;
                const total1 = response.data.topProfitableClients[0].totalCurrencyProfit;
                const arr2 = response.data.topProfitableClients[1].chartData;
                const colors2 = response.data.topProfitableClients[1].chartColors;
                const total2 = response.data.topProfitableClients[1].totalCurrencyProfit;
                
                const el1 = document.getElementById('myfirstchart1');
                if (el1.classList.contains('init')) {
                    return;
                }
        
                window.Morris.Donut({
                    element: el1,
                    data: arr1,
                    colors: colors1,//['#87d6c6', '#54cdb4','#1ab394', '#54cdb4','#1ab394', '#54cdb4','#1ab394'],
                    formatter: function (value, data) {
                        if(total1 != null){
                            return (value * 100 / total1).toFixed(2) + "%";
                        }else{
                            return value.toLocaleString();
                        }
                    },
                });
        
                el1.classList.add('init');

                const el2 = document.getElementById('myfirstchart2');
                if (el2.classList.contains('init')) {
                    return;
                }
        
                window.Morris.Donut({
                    element: el2,
                    data: arr2,
                    colors: colors2,//['#87d6c6', '#54cdb4','#1ab394', '#54cdb4','#1ab394', '#54cdb4','#1ab394'],
                    formatter: function (value, data) {
                        if(total2 != null ){
                            return (value * 100 / total2).toFixed(2) + "%";
                        }else{
                            return value.toLocaleString();
                        }
                    },
                });
        
                el2.classList.add('init');
                response.data.topProfitableClients[1].chartData.array.forEach(element => {
                    chartData2.push(element);
                });
               
                setChartData2(response.data.topProfitableClients[1].chartData);
                console.log(chartData1);
                // if(response.data.length > 0){
                //     setShowProfitForCurrency(response.data[0].currencyId);
                // }


                
                reloadReports();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const arrivingSoonColumns = [
        {
            // name: <FiAnchor /> ,
            name: 'Shipment Status',
            selector: row => {
                if(row.statusId == 20){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
                if(row.statusId == 30){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#292b2c', backgroundColor: '#d3d5d6', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
                if(row.statusId == 35){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#41b6d9', backgroundColor: '#eff9fc', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
                if(row.statusId == 40){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#936b16', backgroundColor: '#fdf0d3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
                if(row.statusId == 45){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#35834f', backgroundColor: '#def8e6', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
                if(row.statusId == 50){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
                if(row.statusId == 60){
                    return (
                        <>
                            <div>
                                <span><b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
                            </div>
                        </>
                    )
                }
            },
        },
        {
            name: 'POD',
            selector: row => {
                return (
                    <>
                        <div>
                            <span>{row.pod}</span>
                            <br />
                            <span><b>{row.arrivalDate}</b></span>
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Invoice Status',
            selector: row => {
                if(row.remainingTotal == 0.0){
                    return (
                        <>
                            <div>
                                <span><b>#{row.clientCommercialInvoice}</b></span>
                                <br />
                                <span style={{color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>Payment Settled</b></span>
                            </div>
                        </>
                    )
                }
                else{
                    return (
                        <>
                            <div>
                                <span><b>#{row.clientCommercialInvoice}</b></span>
                                <br />
                                <span style={{color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>Payment Required</b></span>
                            </div>
                        </>
                    )
                }
            },
        }
    ];

    const topProductsColumns = [
        {
            name: 'Product',
            selector: row => {
                return (
                    <>
                        <span>{row.productName}</span>
                    </>
                )
            },
        },
        {
            name: 'Quantity Sold',
            selector: row => {
                return (
                    <>
                        <span>{row.productQuantity.toLocaleString()}</span>
                    </>
                )
            },
        },
        {
            name: 'Avg. Sell Price',
            selector: row => {
                return (
                    <>
                        <span>{(row.customerPrice / row.productQuantity).toLocaleString()}</span>
                    </>
                )
            },
        },
        {
            name: 'Avg. Buy Price',
            selector: row => {
                return (
                    <>
                        <span>{(row.supplierPrice / row.productQuantity).toLocaleString()}</span>
                    </>
                )
            },
        },
        {
            name: 'Profit',
            selector: row => {
                return (
                    <>
                        <span>{row.profit.toLocaleString()}</span>
                    </>
                )
            },
        },
    ];

    const [shipments, setShipments] = useState([]);
    const GetShipments = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "TrackingInfo/GetArrivingShipments",
            headers: {},
            params: { companyId }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setShipments(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const ExportSuppliersDueTable = () => {

        var companyId = localStorage.getItem("C0m8N!");
        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'Reports/ExportSuppliersDueTable' ,
            responseType: "arraybuffer",
            headers,
            params:{
                'CompanyId': companyId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                const outputFilename = `Suppliers Due Table.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [topProducts, setTopProducts] = useState([]);
    const GetTopProductsReport = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "Reports/GetTopProductsReport/" + companyId,
            headers: {},
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setTopProducts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const reloadReports = () => {
        // const el1 = document.getElementById('myfirstchart1');
        // if (el1.classList.contains('init')) {
        //     return;
        // }

        // window.Morris.Donut({
        //     element: el1,
        //     data: chartData1,
        //     colors: ["#5f76e8", "#01caf1", "#afcaf1"],
        //     // formatter: function (value, data) {
        //     //     return value + "%";
        //     // },
        // });

        // el1.classList.add('init');

        // const el2 = document.getElementById('myfirstchart2');
        // if (el2.classList.contains('init')) {
        //     return;
        // }

        // window.Morris.Donut({
        //     element: el2,
        //     data: chartData2,
        //     colors: ["#5f76e8", "#01caf1", "#afcaf1"],
        //     // formatter: function (value, data) {
        //     //     return value + "%";
        //     // },
        // });

        // el2.classList.add('init');
    }
    
    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        document.title = "Dashboard"
        // var companyId = localStorage.getItem("C0m8N!");

        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        GetDashboardReports();
        GetShipments();
        GetTopProductsReport();
        // GetCurrencies();
        // GetBankAccounts();
        // console.log('chartData1: ' + chartData1);
        // const el = document.getElementById('myfirstchart1');

        // if (el.classList.contains('init')) {
        //     return;
        // }

        // window.Morris.Donut({
        //     element: el,
        //     data: [
        //         {
        //             label: "a",
        //             value: 49,
        //         },
        //         {
        //             label: "b",
        //             value: 51,
        //         },
        //     ],
        //     colors: ["#5f76e8", "#01caf1"],
        //     formatter: function (value, data) {
        //         return value + "%";
        //     },
        // });

        // el.classList.add('init');
    }, [])

    return (
        <>
        {permissions != null && permissions.viewDashboard == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
            <div class="page-content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="page-title-box">
                                <h4>Dashboard</h4>
                                <ol class="breadcrumb m-0">
                                    <li class="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li class="breadcrumb-item active">Dashboard</li>
                                </ol>
                                {/* <button onClick={() => {
                                        ExportSuppliersDueTable();
                                    }
                                    } className="ml-1 btn btn-primary"><FiPlus /> Export Suppliers Due Table</button> */}
                            </div>
                        </div>
                    </div>
                   <div class="row">
                            <div class="col-xl-6 col-sm-6">
                                <div class="card mini-stat bg-primary">
                                    <div class="card-body mini-stat-img">
                                        <div class="mini-stat-icon">
                                            <i class="mdi mdi-cube-outline float-end"></i>
                                        </div>
                                        <div class="text-white">
                                            <h6 class="text-uppercase mb-3 font-size-16 text-white">Total Orders</h6>
                                            <h2 class="mb-4 text-white">{ordersCount ? ordersCount.toLocaleString() : 'N/A'}</h2>
                                            {/* <span class="badge bg-info"> +11% </span> <span class="ms-2">From previous period</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-xl-3 col-sm-6">
                                <div class="card mini-stat bg-primary">
                                    <div class="card-body mini-stat-img">
                                        <div class="mini-stat-icon">
                                            <i class="mdi mdi-buffer float-end"></i>
                                        </div>
                                        <div class="text-white">
                                            <h6 class="text-uppercase mb-3 font-size-16 text-white">Revenue</h6>
                                            <h2 class="mb-4 text-white">$46,782</h2>
                                            <span class="badge bg-danger"> -29% </span> <span class="ms-2">From previous period</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6">
                                <div class="card mini-stat bg-primary">
                                    <div class="card-body mini-stat-img">
                                        <div class="mini-stat-icon">
                                            <i class="mdi mdi-tag-text-outline float-end"></i>
                                        </div>
                                        <div class="text-white">
                                            <h6 class="text-uppercase mb-3 font-size-16 text-white">Average Price</h6>
                                            <h2 class="mb-4 text-white">$15.9</h2>
                                            <span class="badge bg-warning"> 0% </span> <span class="ms-2">From previous period</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="col-xl-6 col-sm-6">
                                <div class="card mini-stat bg-primary">
                                    <div class="card-body mini-stat-img">
                                        <div class="mini-stat-icon">
                                            <i class="mdi mdi-briefcase-check float-end"></i>
                                        </div>
                                        <div class="text-white">
                                            <h6 class="text-uppercase mb-3 font-size-16 text-white">Product Sold</h6>
                                            <h2 class="mb-4 text-white">{productsSoldCount ? productsSoldCount.toLocaleString() : 'N/A'}</h2>
                                            {/* <span class="badge bg-info"> +89% </span> <span class="ms-2">From previous period</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div class="row">
                            <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Profits by Currency by Client</h4>
                                          <div class="row text-center">
                                          { topProfitableClients != null && topProfitableClients.length > 0 &&
                                                <div class="col-6">
                                                    <h5 class="font-size-20">{topProfitableClients[0].currencySuffix}</h5>
                                                    <p class="text-muted">{topProfitableClients[0].totalCurrencyProfit.toLocaleString()}</p>
                                                </div>
                                            }
                                            { topProfitableClients != null && topProfitableClients.length > 1 &&
                                                <div class="col-6">
                                                    <h5 class="font-size-20">{topProfitableClients[1].currencySuffix}</h5>
                                                    <p class="text-muted">{topProfitableClients[1].totalCurrencyProfit.toLocaleString()}</p>
                                                </div>
                                            }
                                        
                                            <div class="col-6" id="myfirstchart1" style={{ height: '250px' }} />
                                            <div class="col-6" id="myfirstchart2" style={{ height: '250px' }} />

                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Arriving soon</h4>
                                        <div class="table-responsive">
                                            <DataTable
                                                className="align-middle table-centered table-vertical table-nowrap mb-1 table"
                                                columns={arrivingSoonColumns}
                                                data={shipments}
                                                noHeader={true} />
                                            <div class="text-center">
                                                <a class="btn btn-sm btn-primary mt-2" href="/Logistics/Shipments">Load More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            {/* <div class="row">
                                <div class="col-xl-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4 class="card-title">Top Profiting Products</h4>
                                            <div class="table-responsive">
                                                <DataTable
                                                    className="align-middle table-centered table-vertical table-nowrap mb-1 table"
                                                    columns={topProductsColumns}
                                                    data={topProducts}
                                                    noHeader={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
 {/* 
                            <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Email Sent</h4>

                                        <div class="row text-center mt-4">
                                            <div class="col-4">
                                                <h5 class="font-size-20">$ 89425</h5>
                                                <p class="text-muted">Marketplace</p>
                                            </div>
                                            <div class="col-4">
                                                <h5 class="font-size-20">$ 56210</h5>
                                                <p class="text-muted">Total Income</p>
                                            </div>
                                            <div class="col-4">
                                                <h5 class="font-size-20">$ 8974</h5>
                                                <p class="text-muted">Last Month</p>
                                            </div>
                                        </div>

                                        <div id="morris-area-example" class="morris-charts morris-charts-height" dir="ltr"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Monthly Earnings</h4>

                                        <div class="row text-center mt-4">
                                            <div class="col-6">
                                                <h5 class="font-size-20">$ 2548</h5>
                                                <p class="text-muted">Marketplace</p>
                                            </div>
                                            <div class="col-6">
                                                <h5 class="font-size-20">$ 6985</h5>
                                                <p class="text-muted">Total Income</p>
                                            </div>
                                        </div>

                                        <div id="morris-bar-stacked" class="morris-charts morris-charts-height" dir="ltr"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-xl-4 col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-3">Inbox</h4>
                                        <div class="inbox-wid">
                                            <a href="#" class="text-dark">
                                                <div class="inbox-item">
                                                    <div class="inbox-item-img float-start me-3"><img src="assets/images/users/user-1.jpg" class="avatar-sm rounded-circle" alt="" /></div>
                                                    <h6 class="inbox-item-author mb-1 font-size-16">Misty</h6>
                                                    <p class="inbox-item-text text-muted mb-0">Hey! there I'm available...</p>
                                                    <p class="inbox-item-date text-muted">13:40 PM</p>
                                                </div>
                                            </a>
                                            <a href="#" class="text-dark">
                                                <div class="inbox-item">
                                                    <div class="inbox-item-img float-start me-3"><img src="assets/images/users/user-2.jpg" class="avatar-sm rounded-circle" alt="" /></div>
                                                    <h6 class="inbox-item-author mb-1 font-size-16">Melissa</h6>
                                                    <p class="inbox-item-text text-muted mb-0">I've finished it! See you so...</p>
                                                    <p class="inbox-item-date text-muted">13:34 PM</p>
                                                </div>
                                            </a>
                                            <a href="#" class="text-dark">
                                                <div class="inbox-item">
                                                    <div class="inbox-item-img float-start me-3"><img src="assets/images/users/user-3.jpg" class="avatar-sm rounded-circle" alt="" /></div>
                                                    <h6 class="inbox-item-author mb-1 font-size-16">Dwayne</h6>
                                                    <p class="inbox-item-text text-muted mb-0">This theme is awesome!</p>
                                                    <p class="inbox-item-date text-muted">13:17 PM</p>
                                                </div>
                                            </a>
                                            <a href="#" class="text-dark">
                                                <div class="inbox-item">
                                                    <div class="inbox-item-img float-start me-3"><img src="assets/images/users/user-4.jpg" class="avatar-sm rounded-circle" alt="" /></div>
                                                    <h6 class="inbox-item-author mb-1 font-size-16">Martin</h6>
                                                    <p class="inbox-item-text text-muted mb-0">Nice to meet you</p>
                                                    <p class="inbox-item-date text-muted">12:20 PM</p>
                                                </div>
                                            </a>
                                            <a href="#" class="text-dark">
                                                <div class="inbox-item">
                                                    <div class="inbox-item-img float-start me-3"><img src="assets/images/users/user-5.jpg" class="avatar-sm rounded-circle" alt="" /></div>
                                                    <h6 class="inbox-item-author mb-1 font-size-16">Vincent</h6>
                                                    <p class="inbox-item-text text-muted mb-0">Hey! there I'm available...</p>
                                                    <p class="inbox-item-date text-muted">11:47 AM</p>
                                                </div>
                                            </a>

                                            <a href="#" class="text-dark">
                                                <div class="inbox-item">
                                                    <div class="inbox-item-img float-start me-3"><img src="assets/images/users/user-6.jpg" class="avatar-sm rounded-circle" alt="" /></div>
                                                    <h6 class="inbox-item-author mb-1 font-size-16">Robert Chappa</h6>
                                                    <p class="inbox-item-text text-muted mb-0">Hey! there I'm available...</p>
                                                    <p class="inbox-item-date text-muted">10:12 AM</p>
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-4 col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Recent Activity Feed</h4>

                                        <ol class="activity-feed mb-0">
                                            <li class="feed-item">
                                                <div class="feed-item-list">
                                                    <span class="date">Jun 25</span>
                                                    <span class="activity-text">Responded to need “Volunteer Activities”</span>
                                                </div>
                                            </li>
                                            <li class="feed-item">
                                                <div class="feed-item-list">
                                                    <span class="date">Jun 24</span>
                                                    <span class="activity-text">Added an interest “Volunteer Activities”</span>
                                                </div>
                                            </li>
                                            <li class="feed-item">
                                                <div class="feed-item-list">
                                                    <span class="date">Jun 23</span>
                                                    <span class="activity-text">Joined the group “Boardsmanship Forum”</span>
                                                </div>
                                            </li>
                                            <li class="feed-item">
                                                <div class="feed-item-list">
                                                    <span class="date">Jun 21</span>
                                                    <span class="activity-text">Responded to need “In-Kind Opportunity”</span>
                                                </div>
                                            </li>
                                        </ol>

                                        <div class="text-center">
                                            <a href="#" class="btn btn-sm btn-primary">Load More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-4">
                                <div class="card widget-user">
                                    <div class="widget-user-desc p-4 text-center bg-primary position-relative">
                                        <i class="fas fa-quote-left h2 text-white-50"></i>
                                        <p class="text-white mb-0">The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc, Europe the same vocabulary. The languages only in their grammar.</p>
                                    </div>
                                    <div class="p-4">
                                        <div class="float-start mt-2 me-3">
                                            <img src="assets/images/users/user-2.jpg" alt="" class="rounded-circle avatar-sm" />
                                        </div>
                                        <h6 class="mb-1 font-size-16 mt-2">Marie Minnick</h6>
                                        <p class="text-muted mb-0">Marketing Manager</p>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Yearly Sales</h4>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div>
                                                    <h3>52,345</h3>
                                                    <p class="text-muted">The languages only differ grammar</p>
                                                    <a href="#" class="text-primary">Learn more <i class="mdi mdi-chevron-double-right"></i></a>
                                                </div>
                                            </div>
                                            <div class="col-md-8 text-end">
                                                <div id="sparkline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Latest Transactions</h4>

                                        <div class="table-responsive">
                                            <table class="table align-middle table-centered table-vertical table-nowrap">

                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/user-2.jpg" alt="user-image" class="avatar-xs rounded-circle me-2" /> Herbert C. Patton
                                                        </td>
                                                        <td><i class="mdi mdi-checkbox-blank-circle text-success"></i> Confirm</td>
                                                        <td>
                                                            $14,584
                                                            <p class="m-0 text-muted font-size-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                            <p class="m-0 text-muted font-size-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/user-3.jpg" alt="user-image" class="avatar-xs rounded-circle me-2" /> Mathias N. Klausen
                                                        </td>
                                                        <td><i class="mdi mdi-checkbox-blank-circle text-warning"></i> Waiting payment</td>
                                                        <td>
                                                            $8,541
                                                            <p class="m-0 text-muted font-size-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            10/11/2016
                                                            <p class="m-0 text-muted font-size-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/user-4.jpg" alt="user-image" class="avatar-xs rounded-circle me-2" /> Nikolaj S. Henriksen
                                                        </td>
                                                        <td><i class="mdi mdi-checkbox-blank-circle text-success"></i> Confirm</td>
                                                        <td>
                                                            $954
                                                            <p class="m-0 text-muted font-size-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            8/11/2016
                                                            <p class="m-0 text-muted font-size-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/user-5.jpg" alt="user-image" class="avatar-xs rounded-circle me-2" /> Lasse C. Overgaard
                                                        </td>
                                                        <td><i class="mdi mdi-checkbox-blank-circle text-danger"></i> Payment expired</td>
                                                        <td>
                                                            $44,584
                                                            <p class="m-0 text-muted font-size-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            7/11/2016
                                                            <p class="m-0 text-muted font-size-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/users/user-6.jpg" alt="user-image" class="avatar-xs rounded-circle me-2" /> Kasper S. Jessen
                                                        </td>
                                                        <td><i class="mdi mdi-checkbox-blank-circle text-success"></i> Confirm</td>
                                                        <td>
                                                            $8,844
                                                            <p class="m-0 text-muted font-size-14">Amount</p>
                                                        </td>
                                                        <td>
                                                            1/11/2016
                                                            <p class="m-0 text-muted font-size-14">Date</p>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-4">Latest Orders</h4>

                                        <div class="table-responsive">
                                            <table class="table align-middle table-centered table-vertical table-nowrap mb-1">

                                                <tbody>
                                                    <tr>
                                                        <td>#12354781</td>
                                                        <td>
                                                            <img src="assets/images/users/user-1.jpg" alt="user-image" class="avatar-xs me-2 rounded-circle" /> Riverston Glass Chair
                                                        </td>
                                                        <td><span class="badge rounded-pill bg-success">Delivered</span></td>
                                                        <td>
                                                            $185
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#52140300</td>
                                                        <td>
                                                            <img src="assets/images/users/user-2.jpg" alt="user-image" class="avatar-xs me-2 rounded-circle" /> Shine Company Catalina
                                                        </td>
                                                        <td><span class="badge rounded-pill bg-success">Delivered</span></td>
                                                        <td>
                                                            $1,024
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#96254137</td>
                                                        <td>
                                                            <img src="assets/images/users/user-3.jpg" alt="user-image" class="avatar-xs me-2 rounded-circle" /> Trex Outdoor Furniture Cape
                                                        </td>
                                                        <td><span class="badge rounded-pill bg-danger">Cancel</span></td>
                                                        <td>
                                                            $657
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#12365474</td>
                                                        <td>
                                                            <img src="assets/images/users/user-4.jpg" alt="user-image" class="avatar-xs me-2 rounded-circle" /> Oasis Bathroom Teak Corner
                                                        </td>
                                                        <td><span class="badge rounded-pill bg-warning">Shipped</span></td>
                                                        <td>
                                                            $8451
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>#85214796</td>
                                                        <td>
                                                            <img src="assets/images/users/user-5.jpg" alt="user-image" class="avatar-xs me-2 rounded-circle" /> BeoPlay Speaker
                                                        </td>
                                                        <td><span class="badge rounded-pill bg-success">Delivered</span></td>
                                                        <td>
                                                            $584
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>#12354781</td>
                                                        <td>
                                                            <img src="assets/images/users/user-6.jpg" alt="user-image" class="avatar-xs me-2 rounded-circle" /> Riverston Glass Chair
                                                        </td>
                                                        <td><span class="badge rounded-pill bg-success">Delivered</span></td>
                                                        <td>
                                                            $185
                                                        </td>
                                                        <td>
                                                            5/12/2016
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-secondary btn-sm waves-effect waves-light">Edit</button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> */}
                </div>
            </div>
            <footer class="footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            © <script>document.write(new Date().getFullYear())</script> Lexa <span class="d-none d-sm-inline-block"> - Crafted with <i class="mdi mdi-heart text-danger"></i> by Themesbrand.</span>
                        </div>
                    </div>
                </div>
            </footer>
        </>)
            }
        </>
    )

}
export default Dashboard;