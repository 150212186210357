import { Global } from '../Variables/Global';
import axiosInstance from './axiosConfig';
// const axios = require('axios').default;
// const token = localStorage.getItem('token');
// axios.defaults.headers.Authorization = `Bearer ${token}`;

export const Get = () => {
    var config = {
        method: 'get',
        url: "Currency/GetCurrencies",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': Global.Host
        },
    };

    return axiosInstance(config).then(response => response.data);
}