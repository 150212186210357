import React, { useEffect, useState } from "react";
import { FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import { Global } from '../../Variables/Global';
import { useNavigate, useParams, Link } from 'react-router-dom';
import * as CustomerService from '../../api/InvoicingCustomerService';
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { getDecodedToken } from '../../utils/utils';

const InvoicingCustomers = () => {
    const [customers, setCustomers] = useState([]);

    const [search, setSearch] = useState('');
    const [accountSearch, setAccountSearch] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [permissions, setPermissions] = useState([]);
    //const navigate = useNavigate();

    const columns = [
        //{
        //    name: '#',
        //    selector: row => row.customerId,
        //    width:"60px",
        //    style: {textAlign: 'center'}
        //},
        {
            name: 'Client Name',
            selector: row => {
                return (
                    <>
                        <div>
                            <span className="text-wrap">{row.customerName}</span>
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Orders',
            selector: row => {
                return (
                    <>
                        <div>
                            <button className='btn btn-success btn-sm' onClick={
                                () => window.location = `/Invoicing/Customers/${row.customerId}/Invoices/${row.customerName}`}><FiEye />{row.orderCount === 1 ? ` 1 Order` : ` ${row.orderCount} Orders`}</button>

                        </div>
                    </>
                )
            },
            width:"160px",
        },
    ];
    const LoadCustomers = (companyId) => {
        return CustomerService.Get({
            companyId,
            search,
            accountSearch,
            invoiceNumber
        }).then(data => {
            setCustomers(data);
        }).catch(err => {
            alert('Failed to load customers');
        })
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
    }, []);

    useEffect(() => {
        LoadCustomers(localStorage.getItem("C0m8N!"));
        document.title = 'Invoicing';
    }, [search, accountSearch, invoiceNumber]);

    return (
        <>
            {permissions.viewInvoicing == null || permissions.viewInvoicing == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Clients</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Invoicing</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    <div className='flex justify-end mb-2'>
                                        <input className='search-expand form-control ml-1' placeholder='Search by name, phone, and address' type="search" name="search" onChange={(e) => setSearch(e.target.value)} />
                                        <input className='search-expand form-control ml-1' placeholder='Search by account name' type="search" name="accountSearch" onChange={(e) => setAccountSearch(e.target.value)} />
                                        <input className='search-expand form-control ml-1' placeholder='Search by Invocie Number or Notes' type="search" name="invoiceNumber" onChange={(e) => setInvoiceNumber(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <section>
                            <div className="col-12">
                                    <DataTableExtensions
                                        columns={columns}
                                        data={customers}
                                        print={false}
                                        export={false}>
                                        <DataTable
                                            // columns={columns}
                                            // data={data}
                                            noHeader
                                            // defaultSortField="id"
                                            // defaultSortAsc={false}
                                            pagination
                                            paginationPerPage={50}
                                            paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                            highlightOnHover
                                        />
                                        </DataTableExtensions>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default InvoicingCustomers