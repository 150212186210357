import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from 'moment';
import { Global } from "../../../../Variables/Global";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axiosInstance from '../../../../api/axiosConfig';

const ManageSupplierOrder = (props) => {
     
    const pId = useParams();
    const navigate = useNavigate();
    const [pageType, setPageType] = useState("Add")
    // const [date, setDate] = useState(moment().format("yyyy-MM-DD"));
    const [date, setDate] = useState()
    const [otherCharges, setOtherCharges] = useState(0)
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [notes, setNotes] = useState("")
    const [selectedPaymentTerms, setSelectedPaymentTerms] = useState({
        paymentTermsId: 0,
        name: ""
    })
    
    const GetSupplierOrder = (supplierOrderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrder/" + supplierOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                var _date = response.data.date.split('T')[0];
                setInvoiceNumber(response.data.invoiceNumber);
                setOtherCharges(response.data.otherCharges);
                setNotes(response.data.notes);
                setSelectedPaymentTerms({
                    paymentTermsId: response.data.paymentTermsId,
                    name: response.data.paymentTermsName
                });
                setDate(_date);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [account, setAccount] = useState("")
    const GetAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccount/" + accountId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data);
                GetCurrency(response.data.currencyId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [paymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + props.companyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // const GetPaymentTermsById = (paymentTermsId) => {
    //     var config = {
    //         method: 'get',
    //         url: "PaymentTerms/GetPaymentTermsById/" + paymentTermsId,
    //         headers: {},
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setSelectedPaymentTerms({
    //                 paymentTermsId: response.data.paymentTermsId,
    //                 name: response.data.name
    //             });
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const submitSupplierOrderForm = (e) => {
        e.preventDefault();
        if (IsValidSupplierOrderForm()) {
            const body = { 
                invoiceNumber: invoiceNumber, 
                date: date, 
                notes: notes, 
                otherCharges: otherCharges, 
                companyId: props.companyId, 
                supplierAccountId: props.supplierAccountId, 
                userId: localStorage.getItem("uTsReD"),
                proformaFileURL: "" ,
                paymentTermsId: selectedPaymentTerms.paymentTermsId,
                paymentTermsName: selectedPaymentTerms.name
            }
            if (props.supplierOrderId == "0") { //Add Supplier order
                var config = {
                    method: 'post',
                    url: "SupplierOrder/AddSupplierOrder",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Order Added Successfully")
                            navigate("/Accounting/Supplier/" + pId.SupplierId + "/Account/" + props.supplierAccountId + "/Order/" + response.data)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Supplier Order
                var config = {
                    method: 'put',
                    url: "SupplierOrder/EditSupplierOrder/" + props.supplierOrderId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Order Updated Successfully")
                            // props.setShowSupplierOrderList(false)
                            navigate("/Accounting/Supplier/" + pId.SupplierId + "/Account/" + props.supplierAccountId + "/Order/" + props.supplierOrderId)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }

    }
    const IsValidSupplierOrderForm = () => {
        if (invoiceNumber == "" && date == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (invoiceNumber == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (date == "") {
            toast.error("Enter Date");
            return false;
        }
        return true;
    }
    useEffect(() => {
        var _date = moment();
        setDate(_date.format("yyyy-MM-DD"))
        GetAccount(props.supplierAccountId);
        GetPaymentTerms();
        if (props.supplierOrderId != 0) {
            GetSupplierOrder(props.supplierOrderId)
            setPageType("Edit");
        }
    }, [])
    return (
        <>
            <div className="mb-3 row">
                <div className="col-12 col-md-6">
                    <h4 className="card-title">{pageType} Supplier Order</h4>
                </div>
                <div className="col-12 col-md-6 text-right">
                    <button className="btn btn-secondary mr-3" onClick={() => props.setShowSupplierOrderList(1)}>Cancel</button>
                    <button className="btn btn-primary" onClick={(e) => submitSupplierOrderForm(e)}>Submit</button>
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Invoice Number</label>
                    <input className="form-control" type="text" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} required />
                </div>
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Date</label>
                    <input className="form-control" type="date" value={date} onChange={(e) =>
                         setDate(e.target.value)
                         } required />
                </div>
                <div className="col-md-6 mt-3">
                    <label className="col-md-6 col-form-label">Other charges</label>
                    <div className="input-group mb-3">
                        <div className="input-group-text">{currency.currencySuffix}</div>
                        <input type="number" className="form-control" value={otherCharges}
                            onChange={(e) => {
                                setOtherCharges(e.target.value);
                            }}>
                        </input>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <label className="col-md-6 col-form-label">Payment Terms</label>
                    {
                        (props.supplierOrderId != 0 && selectedPaymentTerms.paymentTermsId > 0) ?
                        <Select
                            placeholder="-- Select Payment Terms --"
                            isSearchable={true}
                            value={{label: selectedPaymentTerms.name, value: selectedPaymentTerms}}
                            options={paymentTerms.map(p => ({
                                value: p,
                                label: p.name
                            }))}
                            valuekey
                            onChange={(e) => {
                                // handleSupplierChange(e.value.supplierId);
                                setSelectedPaymentTerms({
                                    paymentTermsId: e.value.paymentTermsId,
                                    name: e.value.name
                                })
                            }}>
                        </Select>
                        :
                        <Select
                            placeholder="-- Select Payment Terms --"
                            isSearchable={true}
                            // isDisabled={true}
                            // defaultValue={{label: orderProduct.supplierName, value: orderProduct.supplierId}}
                            options={paymentTerms.map(p => ({
                                value: p,
                                label: p.name
                            }))}
                            valuekey
                            onChange={(e) => {
                                // handleSupplierChange(e.value.supplierId);
                                setSelectedPaymentTerms({
                                    paymentTermsId: e.value.paymentTermsId,
                                    name: e.value.name
                                })
                            }}>
                        </Select>
                    }
                </div>


                
                {/* <div className="col-md-6 mt-3">
                    <label className="col-md-6 col-form-label">Proforma Document</label>
                    <input className="form-control" type="file"></input>
                </div> */}
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Notes</label>
                    <textarea className="form-control" type="text" value={notes} onChange={(e) => setNotes(e.target.value)} required />
                </div>
            </div>
        </>
    )

}
export default ManageSupplierOrder