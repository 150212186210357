
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { BsShieldLock } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { getDecodedToken } from '../../../utils/utils';
import { Button, Modal, Form } from 'react-bootstrap';
import { TbLockOff, TbLockOpen, TbLockFilled, TbLock } from "react-icons/tb";
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axiosConfig';

const Employees = () => {

    const [users, setUsers] = useState([]);
    const columns = [
        {
            name: '#',
            selector: row => row.userId,
            width: "60px"
        },
        {
            name: 'Employee Name',
            selector: row => row.firstName + " " + row.lastName,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Phone Number',
            selector: row => row.mobile,
        },
        {
            name: 'Department',
            selector: row => row.departmentName,
        },
        {
            name: 'Position',
            selector: row => row.positionName,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        <Link to={"/HR/Employee/Permission/" + row.userPermissionId} className="btn btn-outline-success mr-3">
                            <BsShieldLock />
                        </Link>
                        <Link to={"/HR/Employee/" + row.userId} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </Link>
                        <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button>
                    </>
                )
            },
        },
    ];
    const GetUsers = () => {
        var config = {
            method: 'get',
            url: "User/GetUsers",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setUsers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        document.title = "Employees";
        GetUsers();
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
    }, [])

    const [userIdToBlock, setUserIdToBlock] = useState(null);
    const [isBlocking, setIsBlocking] = useState(false);
    const [blockingReason, setBlockingReason] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleBlockClick = (userID, isBlocking) => {
        setUserIdToBlock(userID);
        setIsBlocking(isBlocking);
        setShowModal(true);
    };

    const handleConfirmUserDelete = () => {
        BlockAndUnBlockUser(userIdToBlock, blockingReason);
        setShowModal(false);
    };

    const BlockAndUnBlockUser = (userIdToBlock, blockingReason) => {
        const body = {
            UserId: localStorage.getItem("uTsReD"),
            BlockedUserId: userIdToBlock,
            companyId: localStorage.getItem("C0m8N!"),
            BlockedReason: blockingReason
        }
        var config = {
            method: 'post',
            url: "User/BlockAndUnBlockUser",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetUsers();
                    toast.success(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }


    useEffect(() => {
        if (showModal) {
            setBlockingReason(''); // Reset the blocking reason when the modal opens
        }
    }, [showModal]); // Run this effect whenever showModal changes


    return (
        <>
            {permissions.viewEmployee == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Employees</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Employees</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    {permissions.addEmployee == true && (
                                        <Link to="/HR/Employee/0" className="btn btn-primary">Add Employee</Link>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {/* <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                        <h4 className="card-title">List Employees</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                            <Link to="/HR/Employee/0" className="btn btn-primary">Add Employee</Link>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={users}
                                    />
                                </div>
                            </div> */}
                                </div>
                                {users.map((u) => (
                                    <div className="col-md-6 col-xl-4">
                                        <div className="directory-card card">
                                            <div>
                                                <div className="directory-bg text-center">
                                                    <div className="directory-overlay">
                                                        {u.profile != null ? (
                                                            <>
                                                                <img className="rounded-circle avatar-md img-thumbnail" src={u.profile} alt={u.profile} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="rounded-circle avatar-md img-thumbnail noImg">{u.firstName.toUpperCase().split("")[0] + u.lastName.toUpperCase().split("")[0]}</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="directory-content text-center p-4">
                                                    <p className=" mt-4">{u.positionName}</p>
                                                    <h5 className="font-size-16">{u.firstName + " " + u.lastName}</h5>
                                                    <p className="text-muted">{u.departmentName} <br></br>{u.email}</p>
                                                    <ul className="social-links list-inline mb-0 mt-4">
                                                        {permissions.editPermission == true && (
                                                            <li className="list-inline-item">
                                                                <Link className="tooltips btn-primary" id="Facebook1" to={"/HR/Employee/Permission/" + u.userPermissionId} title="Permission">
                                                                    <BsShieldLock />
                                                                </Link>
                                                            </li>
                                                        )}
                                                        {permissions.editEmployee == true && (
                                                            <li className="list-inline-item">
                                                                <Link className="tooltips btn-info" id="Twitter1" to={"/HR/Employee/" + u.userId} title="Edit">
                                                                    <FiEdit />
                                                                </Link>
                                                            </li>
                                                        )}
                                                        {permissions.editEmployee === true && (
                                                            <li className="list-inline-item">
                                                                <Link
                                                                    className="tooltips btn-danger"
                                                                    onClick={() => handleBlockClick(u.userId, u.isBlocked)}
                                                                    title={u.isBlocked ? "Unblock" : "Block"}  // This line adds the hover text
                                                                >
                                                                    {u.isBlocked ? <TbLockOpen /> : <TbLock />}
                                                                </Link>
                                                            </li>
                                                        )}

                                                        <li className="list-inline-item">
                                                            {/* <a title="" className="tooltips btn-danger" id="mobile1" href="/pages-directory">
                                                        <FiTrash2 />
                                                    </a> */}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure you want to {isBlocking ? 'unblock' : 'block'} this user?
                                    <Form.Group controlId="blockingReason" style={{ marginTop: '20px' }}>
                                        <Form.Label>Please Provide a Reason</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter reason"
                                            value={blockingReason}
                                            onChange={(e) => setBlockingReason(e.target.value)}
                                        />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                        No
                                    </Button>
                                    <Button variant="danger" onClick={handleConfirmUserDelete}>
                                        Yes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>

                    </div>
                </>)
            }
        </>
    )
}
export default Employees