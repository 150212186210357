import { Global } from '../Variables/Global';
import axiosInstance from './axiosConfig';

export const GetCurrencies = () => {
    var config = {
        method: 'get',
        url: "Currency/GetCurrencies",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': Global.Host
        },
    };
    return axiosInstance(config).then(response => response.data);
}

export const GetSupplier = (supplierId) => {
    var config = {
        method: 'get',
        url: "Supplier/GetSuppliers",
        params: { supplierId },
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': Global.Host
        },
    };

    return axiosInstance(config).then(response => (response.data && response.data.length) ? response.data[0] : null);
}

export const GetSupplierOrders = (accountId) => {
    var config = {
        method: 'get',
        url: `${Global.API_URL}SupplierOrder/GetSupplierOrders/${accountId}`,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': Global.Host
        },
    };

    return axiosInstance(config).then(response => response.data);
}