import { Link, NavLink } from "react-router-dom"
import { BsPeopleFill } from 'react-icons/bs';
import { RiShipFill } from 'react-icons/ri';
import { GrMoney } from 'react-icons/gr';
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BiBuilding, BiCog, BiMoney } from 'react-icons/bi'
import { GiMoneyStack } from "react-icons/gi"
import React, { useEffect, useState } from "react";
import { getDecodedToken } from '../../utils/utils';

const VerticalMenu = () => {
    const [userPermissions, setUserPermissions] = useState({})

    const [menuHR, setMenuHR] = useState(false)
    const toogleHR = () => {
        menuHR == true ? setMenuHR(false) : setMenuHR(true)
    }

    const [menuAccounting, setMenuAccounting] = useState(false);
    const toogleAccounting = () => {
        menuAccounting == true ? setMenuAccounting(false) : setMenuAccounting(true)
    }

    const [menuSales, setMenuSales] = useState(false);
    const toogleSales = () => {
        menuSales == true ? setMenuSales(false) : setMenuSales(true)
    }

    const [menuLogistics, setMenuLogistics] = useState(false);
    const toogleLogistics = () => {
        menuLogistics == true ? setMenuLogistics(false) : setMenuLogistics(true)
    }

    const [menuInvoicing, setMenuInvoicing] = useState(false);
    const toggleInvoicing = () => {
        menuInvoicing == true ? setMenuInvoicing(false) : setMenuInvoicing(true)
    }

    const [menuSettings, setMenuSettings] = useState(false);
    const toogleSettings = () => {
        menuSettings == true ? setMenuSettings(false) : setMenuSettings(true)
    }

    const [menuFinance, setMenuFinance] = useState(false);
    const toogleFinance = () => {
        menuFinance == true ? setMenuFinance(false) : setMenuFinance(true)
    }
    useEffect(() => {
        setTimeout(() => {
            const decodedToken = getDecodedToken();
            const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
            var x = JSON.parse(pJoin);
            setUserPermissions(() => x);
        }, 500);
    }, [])
    return (<>
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {userPermissions.viewComapanies === true && (
                            <li>
                                <Link to="/Company" className="waves-effect">
                                    <i><BsPeopleFill /></i>
                                    <span>Company</span>
                                </Link>
                            </li>
                        )}

                        {userPermissions.viewDashboard === true && (
                            <li>
                                <Link to="/dashboard" className="waves-effect">
                                    <i className="mdi mdi-view-dashboard"></i>
                                    {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                        )}
                        {(userPermissions.viewDepartment === true || userPermissions.viewPosition === true || userPermissions.viewEmployee === true) && (
                            <li className={menuHR == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toogleHR()}>
                                    <i><BsPeopleFill /></i>
                                    <span>HR</span>
                                </a>
                                <ul className={menuHR == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {userPermissions.viewDepartment === true && (
                                        <li>
                                            <NavLink to="/hr/Departments">Departments</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewPosition === true && (
                                        <li>
                                            <NavLink to="/hr/Positions">Positions</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewEmployee === true && (
                                        <li>
                                            <NavLink to="/hr/Employees">Employees</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {(userPermissions.viewSupplier === true || userPermissions.viewCustomer === true || userPermissions.viewForwarders === true || userPermissions.viewExpenses === true ||
                             userPermissions.viewBanks === true || userPermissions.viewAllActiveOrders === true) && (
                                <li className={menuAccounting == true ? "mm-active" : ""}>
                                    <a className="has-arrow waves-effect" onClick={() => toogleAccounting()}>
                                        <i><BiMoney /></i>
                                        <span>Accounting</span>
                                    </a>
                                    <ul className={menuAccounting == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>

                                        {userPermissions.viewAllActiveOrders === true && (
                                            <li>
                                                <NavLink to="/Accounting/ActiveOrders">Active Orders</NavLink>
                                            </li>
                                        )}
                                        {userPermissions.viewSupplier === true && (
                                            <li >
                                                <NavLink to="/Accounting/Supplier">Supplier</NavLink >
                                            </li>)}
                                        {userPermissions.viewCustomer === true && (
                                            <li>
                                                <NavLink to="/Accounting/Customer">Client</NavLink >
                                            </li>)}
                                        {userPermissions.viewForwarders === true && (
                                            <li>
                                                <NavLink to="/Accounting/Forwarders">Forwarder</NavLink >
                                            </li>)}
                                        {userPermissions.viewExpenses === true && (
                                            <li>
                                                <NavLink to="/Accounting/Expenses">Expenses</NavLink >
                                            </li>)}
                                        {userPermissions.viewBanks === true && (
                                            <li>
                                                <NavLink to="/Accounting/Banks">Banks</NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                        {userPermissions.viewInvoicing === true && (
                            <li className={menuInvoicing == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toggleInvoicing()}>
                                    <i class="fas fa-file-invoice" />
                                    <span>Invoicing</span>
                                </a>
                                <ul className={menuInvoicing == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {userPermissions.viewInvoicing === true && (
                                        <li>
                                            <NavLink to="/Invoicing/Customers">Clients Orders</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {userPermissions.viewProfits === true && (
                            <li className={menuFinance == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toogleFinance()}>
                                    <i><GiMoneyStack /></i>
                                    <span>Finance</span>
                                </a>
                                <ul className={menuFinance == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {userPermissions.viewProfits === true && (
                                        <li>
                                            <NavLink to="/Finance/Profits">Profits</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {(userPermissions.viewProduct === true || userPermissions.viewSize === true || userPermissions.viewType === true || userPermissions.viewUnit === true) && (
                            <>
                                <li className={menuSales == true ? "mm-active" : ""}>
                                    <a className="has-arrow waves-effect" onClick={() => toogleSales()}>
                                        <i><BsPeopleFill /></i>
                                        <span>Sales</span>
                                    </a>
                                    <ul className={menuSales == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                        {userPermissions.viewSalesOrders === true && (
                                            <li>
                                                <NavLink to="Sales/Orders">Orders</NavLink>
                                            </li>
                                        )}
                                    </ul>
                                    <ul className={menuSales == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                        {userPermissions.viewSalesOrders === true && (
                                            <li>
                                                <NavLink to="Sales/SubmittedOrders">Submitted Orders</NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            </>
                        )}
                        {/* {(userPermissions.viewDepartment === true || userPermissions.viewPosition === true || userPermissions.viewEmployee === true) && (
                            <li className={menuHR == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toogleHR()}>
                                    <i><BsPeopleFill /></i>
                                    <span>HR</span>
                                </a>
                                <ul className={menuHR == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {userPermissions.viewDepartment === true && (
                                        <li>
                                            <NavLink to="/hr/Departments">Departments</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewPosition === true && (
                                        <li>
                                            <NavLink to="/hr/Positions">Positions</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewEmployee === true && (
                                        <li>
                                            <NavLink to="/hr/Employees">Employees</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {(userPermissions.viewSupplier === true || userPermissions.viewCustomer === true || userPermissions.viewForwarders === true || userPermissions.viewExpenses === true ||
                            userPermissions.viewExpensesType === true || userPermissions.viewBanks === true) && (
                                <li className={menuAccounting == true ? "mm-active" : ""}>
                                    <a className="has-arrow waves-effect" onClick={() => toogleAccounting()}>
                                        <i><BiMoney /></i>
                                        <span>Accounting</span>
                                    </a>
                                    <ul className={menuAccounting == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                        {userPermissions.viewSupplier === true && (
                                            <li >
                                                <NavLink to="/Accounting/Supplier">Supplier</NavLink >
                                            </li>)}
                                        {userPermissions.viewCustomer === true && (
                                            <li>
                                                <NavLink to="/Accounting/Customer">Client</NavLink >
                                            </li>)}
                                        {userPermissions.viewForwarders === true && (
                                            <li>
                                                <NavLink to="/Accounting/Forwarders">Forwarder</NavLink >
                                            </li>)}
                                        {userPermissions.viewExpenses === true && (
                                            <li>
                                                <NavLink to="/Accounting/Expenses">Expenses</NavLink >
                                            </li>)}
                                        {userPermissions.viewExpensesType === true && (
                                            <li>
                                                <NavLink to="/Accounting/ExpensesType">Expenses Types</NavLink >
                                            </li>)}
                                        {userPermissions.viewBanks === true && (
                                            <li>
                                                <NavLink to="/Accounting/Banks">Banks</NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}

                        {userPermissions.viewProfits === true && (
                            <li className={menuFinance == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toogleFinance()}>
                                    <i><GiMoneyStack /></i>
                                    <span>Finance</span>
                                </a>
                                <ul className={menuFinance == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {userPermissions.viewProfits === true && (
                                        <li>
                                            <NavLink to="/Finance/Profits">Profits</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )} */}
                        {(userPermissions.viewFreight === true || userPermissions.viewShippingLines === true || userPermissions.viewShipments === true) && (
                            <li className={menuLogistics == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toogleLogistics()}>
                                    <i><RiShipFill /></i>
                                    <span>Logistics</span>
                                </a>
                                <ul className={menuLogistics == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {/* The screen is working but stopped */}
                                    {/*{userPermissions.viewFreight === true && (*/}
                                    {/*    <li>*/}
                                    {/*        <NavLink to="/Logistics/Freights">Freights</NavLink>*/}
                                    {/*    </li>*/}
                                    {/*)}*/}
                                    {userPermissions.viewShipments === true && (
                                        <li>
                                            <NavLink to="/Logistics/Shipments">My Shipments</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}


                        {(userPermissions.viewCurrency === true || userPermissions.viewCompany === true) && (
                            <li className={menuSettings == true ? "mm-active" : ""}>
                                <a className="has-arrow waves-effect" onClick={() => toogleSettings()}>
                                    <i><BiCog /></i>
                                    <span>Settings</span>
                                </a>
                                <ul className={menuSettings == true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"}>
                                    {userPermissions.viewCompany === true && (
                                        //<li>
                                        //    <NavLink to={"/Company/View/" + localStorage.getItem("C0m8N!")} className="waves-effect">
                                        //        <span>Company</span>
                                        //    </NavLink>
                                        //</li>
                                        <li>
                                            <NavLink to={"/Company"} className="waves-effect">
                                                <span>Company</span>
                                            </NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewCurrency === true && (
                                        <li>
                                            <NavLink to="/Settings/Currency">Currency</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewProduct === true && (
                                        <li>
                                            <NavLink to="/Settings/Product">Product</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewSize === true && (
                                        <li>
                                            <NavLink to="/Settings/Size">Size</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewType === true && (
                                        <li>
                                            <NavLink to="/Settings/Type">Type</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewUnit === true && (
                                        <li>
                                            <NavLink to="/Settings/Unit">Unit</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewExpensesType === true && (
                                        <li>
                                            <NavLink to="/Accounting/ExpensesType">Expenses Types</NavLink >
                                        </li>
                                    )}
                                    {userPermissions.viewUnit === true && (
                                        <li>
                                            <NavLink to="/Settings/ShippingCostsOptions">Shipping Costs Options</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewShippingLines === true && (
                                        <li>
                                            <NavLink to="/Logistics/ShippingLines">Shipping Lines</NavLink>
                                        </li>
                                    )}
                                    {userPermissions.viewBanks === true && (
                                        <li>
                                            <NavLink to="/Settings/BankChargesTypes">Bank Charges Types</NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    </>)
}
export default VerticalMenu