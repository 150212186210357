import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../../../Variables/Global";
import { AiOutlineFileExcel } from "react-icons/ai"
import axiosInstance from '../../../../../api/axiosConfig';

const CustomerDueTable = () => {
     
    const pId = useParams()

    const DownloadCSV = (accountId) => {
        // var axios = require('axios');

        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'CustomerAccountStatment/DownloadCustomerAccountDueTableCSV/' + accountId,
            responseType: "arraybuffer",
            headers,
        };

        axiosInstance(config)
            .then(function (response) {
                const outputFilename = accountInfo.customerAccount1 + ` - Due Table` + `.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [dueTable, setDueTable] = useState([])
    const columns = [
        {
            name: "Invoice",
            // selector: row => row.performa,
            selector: row => {
                if(row.commercialInvoice == null || row.commercialInvoice == '') {
                    return (
                        <>
                            <span>#{row.performa}</span>
                        </>
                    )
                }else{
                    return (
                        <>
                            <div>
                                <span>#{row.performa}</span>
                                <br />
                                <span>#{row.commercialInvoice}</span>
                            </div>
                        </>
                    )
                }
            },
            width: "120px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Tracking Status',
            // selector: row => row.status,
            width: "130px",
            selector: row => {
                if(row.trackingStatusId == 20){
                    return (
                        <>
                            <div>
                                <span style={{color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.trackingStatus}</b></span> 
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                         
                        </>
                    )
                }
                if(row.trackingStatusId == 30){
                    return (
                        <>
                            <div>
                                <span style={{color: '#292b2c', backgroundColor: '#d3d5d6', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.trackingStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if(row.trackingStatusId == 35){
                    return (
                        <>
                            <div>
                                <span style={{color: '#41b6d9', backgroundColor: '#eff9fc', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.trackingStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if(row.trackingStatusId == 40){
                    return (
                        <>
                            <div>
                                <span style={{color: '#936b16', backgroundColor: '#fdf0d3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.trackingStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if(row.trackingStatusId == 45){
                    return (
                        <>
                            <div>
                                <span style={{color: '#35834f', backgroundColor: '#def8e6', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.trackingStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if(row.trackingStatusId == 50){
                    return (
                        <>
                            <div style={{color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>
                                <span><b>{row.trackingStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if(row.trackingStatusId == 60){
                    return (
                        <>
                            <div>
                                <span style={{color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.trackingStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
            },
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Description",
            selector: row => (row.fcl != null ? row.fcl : "") + " " + (row.description != null ? row.description : ""),
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Quantity",
            selector: row =>  (row.price == null || row.price == 0) ? "" : (row.quantity == null ? "" : row.quantity.toLocaleString() + " " + row.unit),
            width: "105px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Price",
            // selector: row => row.price == null ? "" : (row.price.toLocaleString() + " " + currency.currencySuffix),
            selector: row => (row.price == null || row.price == 0) ? "" : row.price.toLocaleString(),
            width: "90px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Commission",
            // selector: row => row.price == null ? "" : (row.price.toLocaleString() + " " + currency.currencySuffix),
            selector: row => (row.commission == null || row.commission == 0) ? "" : row.commission.toLocaleString(),
            width: "90px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Debit",
            // selector: row => row.debit == null ? "" : (row.debit.toLocaleString() + " " + currency.currencySuffix),
            selector: row => row.debit == null ? "" : row.debit.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Credit",
            // selector: row => row.credit == null ? "" : (row.credit.toLocaleString() + " " + currency.currencySuffix),
            selector: row => row.credit == null ? "" : row.credit.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Balance",
            // selector: row => row.balance == null ? "" : (row.balance.toLocaleString() + " " + currency.currencySuffix),
            selector: row => row.balance == null ? "" : row.balance.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Supplier Account",
            // selector: row => row.supplierAccount,
            selector: row => {
                if(row.supplierAccount == null || row.supplierAccount == '') {
                    return (
                        <>
                        </>
                    )
                }else{
                    return (
                        <>
                            <div>
                                <span>{row.supplierAccount}</span>
                                <br />
                                <span>#{row.supplierInvoice}</span>
                            </div>
                        </>
                    )
                }
            },
            width: "160px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Date",
            selector: row => row.date != null ? row.date.split("T")[0] : row.createdDate.split("T")[0],
            width: "120px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
    ]

    const GetAccountDueTable = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'CustomerAccountStatment/GetCustomerDueAccountStatement/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setDueTable(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [accountInfo, setAccountInfo] = useState("");
    const GetAccountInfo = (accountId) => {
        var config = {
            method: 'get',
            url: 'CustomerAccount/GetCustomerAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setAccountInfo(response.data);
                GetCurrency(response.data.currencyId)
                document.title = response.data.customerName + ' - ' + response.data.customerAccount1;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [currency, setCurrency] = useState("");
    const GetCurrency = (currencyId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'Currency/GetCurrency/' + currencyId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetAccountDueTable(pId.AccountId);
        GetAccountInfo(pId.AccountId);
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    {/* <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                                    <li className="breadcrumb-item active">{account.supplierAccount1}</li> */}
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row mb-3'>
                                        <div className='col-12 col-md-6'>
                                            <h3 className="card-title">{accountInfo.customerName}</h3>
                                            <h4 className="card-title">{accountInfo.customerAccount1} Due Table</h4>
                                        </div>
                                        {/* <div className="col-6 text-right">
                                            <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Payments"} className="btn btn-primary mr-3">View Payments</Link>
                                            <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Discounts"} className="btn btn-danger mr-3">View Discounts</Link>
                                        </div> */}
                                         <div className="col-4 col-md-6 text-right">
                                            <button onClick={() => DownloadCSV(pId.AccountId)} className="btn btn-primary mr-3"><AiOutlineFileExcel /> Export CSV</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <hr></hr>
                                        <DataTable
                                            columns={columns}
                                            data={dueTable} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default CustomerDueTable;