import DataTable from "react-data-table-component";
import Select from "react-select";
import { FiEdit, FiEye, FiTrash2, FiPlus } from 'react-icons/fi';
import React, { useEffect, useState } from "react";
import { Global } from "../../../../Variables/Global";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";
import axiosInstance from '../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../utils/utils';
import { formatDate } from "../../../Sales/SalesOrder";
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ListAllSuppliersOrders = (props) => {
    // var pId = useParams();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    
     // Handle search input change
     const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if(value == null || value == ''){
            setFilteredInvoices(orders);
        }
        // Filter invoices based on the search query
        const filtered = orders.filter(invoice =>
            invoice.invoiceNumber.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredInvoices(filtered);
    }

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setLoading(true);
         
        GetAllSuppliersOrders();
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
       
        document.title = "Orders"
    }, []); // This will run the effect only once when the component mounts
    

    const GetAllSuppliersOrders = () => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetAllSuppliersOrders",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'CompanyId': localStorage.getItem("C0m8N!"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setOrders(response.data);
                setFilteredInvoices(response.data); // Initialize filteredInvoices with all invoices
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
                setLoading(false);
            });
    }

    return (
        <>
        {permissions.viewSupplier == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <h4>Suppliers</h4>
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                <li className="breadcrumb-item"><Link onClick={() => {navigate(-1)}}>Suppliers</Link></li>
                                <li className="breadcrumb-item">All Orders</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container-fluid">
                        <div className="row mt-3">
                            <div className="col-12">
                                <input
                                    type="text"
                                    placeholder="Invoice Number"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                    style={{ marginBottom: '10px' }}
                                />
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                    {loading ? (
                                        Array.from({ length: 3 }).map((_, index) => (
                                            <div key={index} style={{
                                                border: '1px solid #ddd',
                                                borderRadius: '5px',
                                                padding: '15px',
                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                flex: '1 1 100%',
                                                boxSizing: 'border-box',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: '#fff'
                                            }}>
                                                <Skeleton height={30} style={{ marginBottom: '10px' }} />
                                                <Skeleton height={20} style={{ marginBottom: '10px' }} />
                                                <Skeleton count={3} height={20} />
                                            </div>
                                        ))
                                    ) : (
                                        filteredInvoices.length === 0 ? (
                                            <div>No invoices found</div>
                                        ) : (
                                            filteredInvoices.map(invoice => (
                                                <div key={invoice.supplierOrderId} style={{
                                                    border: '1px solid #ddd',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                    flex: '1 1 100%',
                                                    boxSizing: 'border-box',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    backgroundColor: '#fff'
                                                }}>
                                                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                        <a href={`/Accounting/Supplier/${invoice.SupplierId}/Account/${invoice.AccountId}/Order/${invoice.supplierOrderId}`} style={{ textDecoration: 'none', color: '#f5b225' }}>
                                                            <b>#{invoice.invoiceNumber}</b> - {invoice.supplierName}
                                                        </a>
                                                        <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                            {invoice.date ? invoice.date.split('T')[0] : 'No Date'}
                                                        </span>
                                                    </div>

                                                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                        <span style={{ whiteSpace: 'pre-wrap' }}>
                                                            {invoice.products}
                                                        </span>
                                                        <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                            {invoice.balance != null && invoice.balance <= 0 ?
                                                                <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Paid</b></span>
                                                                :
                                                                <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{invoice.currencySuffix} {invoice.balance.toLocaleString()}</b></span>
                                                            }
                                                            {invoice.pendingFCLs > 0 && <>
                                                                <br />
                                                                <span style={{ textAlign: 'right', color: '#936b16', backgroundColor: '#fdf0d3', borderColor: '#fce8be', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>{invoice.pendingFCLs == 1 ? "1 Pending FCL" : invoice.pendingFCLs + " Pending FCLs"} </span>
                                                            </>}
                                                        </span>
                                                    </div>
                                                    <hr />
                                                    <div style={{ flexGrow: 1 }}>
                                                        {invoice.commercialInvoices && invoice.commercialInvoices.length > 0 ? (
                                                            invoice.commercialInvoices.map(c => (
                                                                <div key={c.invoiceNumber} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <a href={`/Accounting/Supplier/${invoice.SupplierId}/Account/${invoice.AccountId}/Order/${invoice.supplierOrderId}`} style={{ textDecoration: 'none', color: '#3eb7ba' }}>
                                                                        <b>{c.invoiceNumber}</b>
                                                                        <span style={{ color: '#a3a3a3' }}>
                                                                            {" - " + (c.date ? c.date.split('T')[0] : 'No Date')}
                                                                        </span>
                                                                    </a>
                                                                    <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                        {c.invoiceTotals.remainingTotal <= 0 ?
                                                                            <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Paid</b></span>
                                                                            :
                                                                            <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{invoice.currencySuffix} {c.invoiceTotals.remainingTotal.toLocaleString()}</b></span>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <span style={{ color: '#a3a3a3' }}><i>No commercial invoices available</i></span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        </>
       )
}
</>
)
}
export default ListAllSuppliersOrders