import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const Expenses = () => {
     
    const [expenses, setExpenses] = useState();

    const columns = [
        {
            name: '#',
            selector: row => row.expnesesId,
            width: "60px"
        },
        {
            name: 'Expenses Type',
            selector: row => row.expensesType1,
        },
        {
            name: 'Description',
            selector: row => row.description
        },
        {
            name: 'Expenses Value',
            selector: row => row.paymentValue.toFixed(2) + " " + row.currencySuffix,
        },
        {
            name: 'Date',
            selector: row => row.date.split('T')[0],
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        <Link to={"/Accounting/Expenses/" + row.expnesesId} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </Link>
                        <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button>
                    </>
                )
            },
        },
    ];

    const GetExpenses = () => {
        var config = {
            method: 'get',
            url: "ProductExpenses/GetExpenses",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setExpenses(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [permissions, setPermissions] = useState([])
   
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetExpenses();
        document.title = "Expenses";
    }, [])
    return (
        <>
        {permissions.viewExpenses == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Expenses</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item active">Expenses</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <h4 className="card-title">List Expenses</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                        {permissions.addExpenses == true && (
                                           <Link to="/Accounting/Expenses/0" className="btn btn-primary">Add Expenses</Link>
                                        )}
                                        </div>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={expenses}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>)
            }
        </>
    )
}
export default Expenses