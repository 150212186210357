import axios from 'axios';
import { Global } from '../Variables/Global';
//import { createBrowserHistory } from 'history';

// Set the default authorization header
//axios.defaults.headers.Authorization = `Bearer ${token}`;

//const history = createBrowserHistory();

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: Global.API_URL,
});

//Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
let sessionExpiredNotified = false;

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401 && !sessionExpiredNotified) {
            //unauthorized error, redirect to login
            sessionExpiredNotified = true;
            alert('Your session has expired. You will be redirected to the login page.');
            sessionStorage.setItem('sessionExpired', 'true'); 
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;