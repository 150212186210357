import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Global } from '../../Variables/Global';
import { getDecodedToken } from '../../utils/utils';
import axiosInstance from '../../api/axiosConfig';

const ViewCompany = () => {
     
    const pId = useParams();
    const [company, setCompany] = useState("")
    const GetCompanyDetails = () => {
        // var axios = require('axios');
        var config = {
            method: 'get',
            url: 'Company/GetCompany/' + pId.CompanyId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setCompany(response.data);
                // document.title = response.data.CompanyName;
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Companies";
        GetCompanyDetails();
    }, [])
    return (
        <>
         {permissions.viewCompany == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
            <div className="page-content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="page-title-box">
                                <h4>Company</h4>
                                <ol class="breadcrumb m-0">
                                    <li class="breadcrumb-item">
                                        <a href="#">Mercator</a>
                                    </li>
                                    <li class="breadcrumb-item active">Company Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row mb-3">
                                        <div class="col-12 col-md-6">
                                            <h4 class="card-title">Company Details</h4>
                                        </div>
                                        <div class="col-12 col-md-6 text-right">
                                            {permissions.editCompany == true && (
                                                <a class="btn btn-primary">Edit Company Details</a>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <hr></hr>
                                        {company.length > 0 && (
                                            <>
                                                <div className='col-3'>
                                                    <h6>Company Name</h6>
                                                    <p>{company[0].companyName}</p>
                                                </div>
                                                <div className='col-3'>
                                                    <h6>Email</h6>
                                                    <p>{company[0].email}</p>
                                                </div>
                                                <div className='col-3'>
                                                    <h6>Phone Number</h6>
                                                    <p>{company[0].phone}</p>
                                                </div>
                                                <div className='col-3'>
                                                    <h6>Website</h6>
                                                    <p>{company[0].website}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
                )}
        </>
    )
}
export default ViewCompany