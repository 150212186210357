import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ShippingCostsTypes = () => {

    const [items, setItems] = useState([]);
    const [editingShippingCostOptionId, setEditingShippingCostOptionId] = useState(0);
    const [deletingShippingCostOptionId, setDeletingShippingCostOptionId] = useState(0);

    const columns = [
        {
            name: '#',
            selector: row => row.shippingCostsOptionId,
            width: "60px"
        },
        {
            name: 'Shipping Cost Type',
            selector: row => row.name,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {permissions.editShippingCostOption === true && (
                            <button type="button" className="btn btn-outline-warning" onClick={(e) => {
                                setEditingShippingCostOptionId(row.shippingCostsOptionId);
                                setShippingCostOption((prevState) => ({
                                    ...prevState,
                                    name: row.name
                                }))
                                setShowAddShippingCostOptionModal(true);
                            }
                            }><FiEdit /></button>
                        )}
                        {permissions.deleteShippingCostOption === true && (
                            <button type="button" className="btn btn-outline-danger ml-1" onClick={(e) => {
                                setDeletingShippingCostOptionId(row.shippingCostsOptionId);
                                setShowDeleteShippingCostOptionModal(true);
                            }
                            }><FiTrash2 /></button>
                        )}
                    </>
                )
            },
        },
    ];

    const [showAddShippingCostOptionModal, setShowAddShippingCostOptionModal] = useState(false)
    const handleCloseAddShippingCostOptionModal = () => {
        setShowAddShippingCostOptionModal(false);
    }

    const [showDeleteShippingCostOptionModal, setShowDeleteShippingCostOptionModal] = useState(false)
    const handleCloseDeleteShippingCostOptionModal = () => {
        setShowDeleteShippingCostOptionModal(false);
    }

    const [shippingCostOption, setShippingCostOption] = useState({
        name: '',
        companyId: localStorage.getItem("C0m8N!"),
        userId: localStorage.getItem("uTsReD"),
    });

    const GetItems = () => {
        var config = {
            method: 'get',
            url: "ShippingCostType/GetShippingCostsOptions",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'CompanyId': localStorage.getItem("C0m8N!")
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setItems(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const DeleteItem = () => {
        var config = {
            method: 'delete',
            url: "ShippingCostType/DeleteShippingCostOption",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'ShippingCostsOptionId': deletingShippingCostOptionId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setItems(response.data);
                handleCloseDeleteShippingCostOptionModal();
            })
            .catch(function (error) {
                console.log(error);
                handleCloseDeleteShippingCostOptionModal();
            });
    }

    const submitShippingCostOptionForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            if (editingShippingCostOptionId === 0) { //Add 
                var config = {
                    method: 'post',
                    url: "ShippingCostType/AddShippingCostOption",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: shippingCostOption
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            setItems(response.data);
                            toast.success("Shipping Cost Option Added Successfully")
                            handleCloseAddShippingCostOptionModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                    });
            }
            else { // Update Product
                var config = {
                    method: 'put',
                    url: "ShippingCostType/EditShippingCostOption",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    params: {
                        'ShippingCostsOptionId': editingShippingCostOptionId
                    },
                    data: shippingCostOption
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            setItems(response.data);
                            toast.success("Shipping Cost Option Updated Successfully");
                            handleCloseAddShippingCostOptionModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                        // handleCloseAddShippingCostOptionModal();
                    });
            }
        }
    }
    const IsValid = () => {
        if (shippingCostOption.name == "") {
            toast.error("Enter the shipping cost option name");
            return false;
        }
        return true;
    }
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
    }, []);

    useEffect(() => {
        document.title = "Shipping Costs Types";
        GetItems();
    }, []);


    return (
        <>
            {permissions.viewShippingCostOptions === false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Shipping Costs Types</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Shipping Costs Types</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">List Shipping Costs Types</h4>
                                                </div>
                                                <div className='col-12 col-md-6 text-right'>
                                                    {permissions.addShippingCostOption === true && (
                                                        <button type="button" className="btn btn-info" onClick={(e) => {
                                                            setEditingShippingCostOptionId(0);
                                                            setShippingCostOption((prevState) => ({
                                                                ...prevState,
                                                                name: ''
                                                            }))
                                                            setShowAddShippingCostOptionModal(true);
                                                        }
                                                        }><FiPlus /> Add New Option</button>
                                                    )}
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={items}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}

            <Modal size="md" show={showAddShippingCostOptionModal} onHide={handleCloseAddShippingCostOptionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingShippingCostOptionId === 0 ? "Add Shipping Cost" : "Edit Shipping Cost"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Shiping Cost Option Name</label>
                            <input className="form-control"
                                type="text"
                                placeholder='Enter name'
                                value={shippingCostOption.name}
                                onChange={(e) =>
                                    setShippingCostOption((prevState) => ({
                                        ...prevState,
                                        name: e.target.value
                                    }))
                                } required />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddShippingCostOptionModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) =>
                        submitShippingCostOptionForm(e)
                    }>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteShippingCostOptionModal} onHide={handleCloseDeleteShippingCostOptionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Shipping Cost Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this option?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteShippingCostOptionModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) =>
                        DeleteItem()
                    }>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ShippingCostsTypes