import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Global } from '../../../../Variables/Global';
import axiosInstance from '../../../../api/axiosConfig';

const ListCommercialProduct = (props) => {
     
    const pId = useParams();

    const [showAddCommercialProduct, setShowAddCommercialProduct] = useState(false);
    const handleCloseAddCommercialProduct = () => setShowAddCommercialProduct(false);
    const handleShowAddCommercialProduct = () => setShowAddCommercialProduct(true);

    

    const [showEditCommercialProduct, setShowEditCommercialProduct] = useState(false);
    const handleCloseEditCommercialProduct = () => setShowEditCommercialProduct(false);
    const handleShowEditCommercialProduct = () => setShowEditCommercialProduct(true);
    
    const [showDeleteCommercialProduct, setShowDeleteCommercialProduct] = useState(false);
    const handleShowDeleteCommercialProduct = () => setShowDeleteCommercialProduct(true);
    const handleCloseDeleteCommercialProduct = () => setShowDeleteCommercialProduct(false);


    const [submitDisabled, setSubmitDisabled] = useState(false);

    // setEditCommercialProduct

    const [commercialProduct, setCommercialProduct] = useState({
        supplierProductId: 0,
        performa: "",
        productQuantity: 0,
        fcl: 0,
        commercialInvoiceId: 0,
        userId: localStorage.getItem("uTsReD"),
        commercialInvoice: ""
    });

    const [editCommercialProduct, setEditCommercialProduct] = useState({
        supplierProductId: 0,
        performa: "",
        productQuantity: 0,
        fcl: 0,
        commercialInvoiceId: 0,
        userId: localStorage.getItem("uTsReD"),
        commercialInvoice: ""
    });

    const [deleteCommercialProductId, setDeleteommercialProductId] = useState(0);


    


    const [orderProducts, setOrderProducts] = useState([])
    const GetOrderProducts = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrderProduct/GetSupplierOrderProduct/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrderProducts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [units, setUnits] = useState([])
    const GetUnits = () => {
        var config = {
            method: 'get',
            url: "ProductUnit/GetProductUnit",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setUnits(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const AddCommercialProduct = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "SupplierCommercialProduct/AddSupplierCommercialProduct/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: commercialProduct
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully");
                    setShowAddCommercialProduct(false);
                    GetInvoiceProducts(pId.InvoiceId);
                    GetOrderProducts(pId.OrderId);
                    props.GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
                if (response.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }

                setSubmitDisabled(false);
            })
            .catch(function (error) {
                if (error.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }else{
                    toast.error(error.response.data);
                }
                setSubmitDisabled(false);
            });
        // }
    }

    
    const EditProduct = (e) => {
        e.preventDefault();
        // if (validateFCL()) {
        var config = {
            method: 'put',
            url: "SupplierCommercialProduct/EditSupplierCommercialProduct/" + editCommercialProduct.supplierCommercialProductsId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editCommercialProduct
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Updated Successfully");
                    setShowEditCommercialProduct(false);
                    GetInvoiceProducts(pId.InvoiceId);
                    GetOrderProducts(pId.OrderId);
                    // AddSupplierCommercialOrderProduct();
                    props.GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
                if (response.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }
            })
            .catch(function (error) {
                if (error.status === 401) {
                    toast.error("Number of containers should be equal or less than pending FCL's")
                }
            });
        // }
    }

    const DeleteCommercialProduct = (e) => {
        e.preventDefault();
        // if (validateFCL()) {
        var config = {
            method: 'delete',
            url: "SupplierCommercialProduct/DeleteSupplierCommercialProduct",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'SupplierCommercialProductsId': deleteCommercialProductId,
                'UserId': localStorage.getItem("uTsReD")
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Deleted Successfully");
                    setShowDeleteCommercialProduct(false);
                    GetInvoiceProducts(pId.InvoiceId);
                    GetOrderProducts(pId.OrderId);
                    // AddSupplierCommercialOrderProduct();
                    props.GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
                // if (response.status === 401) {
                //     toast.error("Number of containers should be equal or less than pending FCL's")
                // }
            })
            .catch(function (error) {
                toast.error(error.response.data);
                // if (error.status === 401) {
                //     toast.error("Number of containers should be equal or less than pending FCL's")
                // }
            });
        // }
    }

    // const handleNumberOfContainers = (e) =>{
    //     var selectedValue = e.target.value;
    //     products.forEach(element => {
    //         if(element.productId === commercialProduct.supplierCommercialProductsId){
    //             if(element.numberOfContainers >= selectedValue){

    //             }
    //             else{
    //                 toast.warning("Number of containers should be less or equal to " + element.numberOfContainers);
    //             }
    //         }
    //     });
    // }

    const validateFCL = () => {
        var productID = commercialProduct.supplierProductId;
        var selectedFCL = commercialProduct.fcl;
        orderProducts.forEach(element => {
            if (element.supplierOrderProductId == productID) {
                if (selectedFCL <= element.pendingCfcl) {
                    return true;
                }
                else {
                    toast.warning("FCL quanity should be less than or equal to selected FCL's")
                    return false;
                }
            }
        });
    }

    const GetInvoiceProducts = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialProduct/GetSupplierCommercialInvoiceProducts/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                props.setCommercialProducts(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const columns = [
        {
            name: '#',
            selector: row => row.supplierCommercialProductsId,
            width: "60px"
        },
        {
            name: 'Description',
            // selector: row => row.productName + ", " + row.productSizeName + ", " + row.productTypeName,
            selector: row => {
                return (
                    <>
                        <div className="mt-2 mb-2">
                             <span className="text-wrap">{row.productName + ", " + row.productSizeName + ", " + row.productTypeName}</span>
                        </div>
                    </>
                )
            },
        },
        {
            name: 'FCL',
            width: "200px",
            selector: row => row.fcl,

        },
        {
            name: "Quantity",
            selector: row => row.productQuantity.toLocaleString() + " " + row.productUnit1,
        },
        {
            name: "Unit Price",
            selector: row => row.unitPrice.toLocaleString() + " " + props.currencySuffix
        },
        {
            name: "Total Price",
            selector: row => (row.productQuantity * row.unitPrice).toLocaleString() + " " + props.currencySuffix
        },
        {
            name: 'Actions',
            width: "210px",
            selector: row => {
                return (
                    <>
                    {
                        (row.supplierAccountDetailId != null && row.supplierAccountDetailId > 0) &&
                            <>
                                <button onClick={(e) => { 
                                    setEditCommercialProduct((prevState) => ({
                                        ...prevState,
                                        supplierCommercialProductsId: row.supplierCommercialProductsId,
                                        fcl: row.fcl,
                                        productQuantity: row.productQuantity,
                                        supplierProductId: row.supplierProductId,
                                    }));
                                    handleShowEditCommercialProduct();
                                }} className="btn btn-outline-warning mr-3">
                                <FiEdit /> Edit
                            </button>
                        </>
                    }
                    {
                        (row.supplierAccountDetailId != null && row.supplierAccountDetailId > 0) &&
                            <>
                                <button onClick={(e) => { 
                                    setDeleteommercialProductId(row.supplierCommercialProductsId);
                                    handleShowDeleteCommercialProduct();
                                }} className="btn btn-outline-danger mr-3">
                                <FiTrash2 /> Delete
                            </button>
                        </>
                    }
                    </>
                )
            },
        },
    ];

    const [invoiceNumber, setInvoiceNumber] = useState("");
    const GetSupplierOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data);
                setInvoiceNumber(response.data.invoiceNumber);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [commercialInvoice, setCommercialInvoice] = useState("");
    const GetCommercialInvoice = (invoiceId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoice/" + invoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCommercialInvoice(response.data.invoiceNumber)

                setCommercialProduct((prevState) => ({
                    ...prevState,
                    commercialInvoice: response.data.invoiceNumber
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetOrderProducts(pId.OrderId);
        GetInvoiceProducts(pId.InvoiceId);
        GetUnits();
        setCommercialProduct((prevState) => ({
            ...prevState,
            commercialInvoiceId: pId.InvoiceId
        }))

        GetSupplierOrder(pId.OrderId);
        setCommercialProduct((prevState) => ({
            ...prevState,
            performa: invoiceNumber
        }));

        GetCommercialInvoice(pId.InvoiceId);
        setCommercialProduct((prevState) => ({
            ...prevState,
            commercialInvoice: commercialInvoice
        }));

    }, [invoiceNumber])
    return (
        <>
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <h5>List Commercial Product</h5>
                </div>
                <div className="col-12 col-md-6 text-right">
                    <button className="btn btn-primary" onClick={handleShowAddCommercialProduct}>Add Product</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-12'>
                    <DataTable columns={columns} data={props.commercialProducts} />
                </div>
            </div>
            <Modal show={showAddCommercialProduct} onHide={handleCloseAddCommercialProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Product</label>
                            <select className="form-control mb-3" required
                                onChange={(e) => {
                                    setCommercialProduct((prevState) => ({
                                        ...prevState,
                                        supplierProductId: e.target.value,
                                        unit: orderProducts.find(x => x.supplierOrderProductId == e.target.value).productUnit1
                                    }));
                                    // setCommercialProduct((prevState) => ({
                                    //     ...prevState,
                                    //     supplierOrderProductId: e.target.value
                                    // }));
                                }}>
                                <option value="0" key="0">-- Select Product --</option>
                                {orderProducts.length > 0 && (
                                    orderProducts.map((c, index) => {
                                        return (<>
                                            <option value={c.supplierOrderProductId} key={index}>{c.productName + ", " + c.productSizeName + ", " + c.productTypeName} {"- FCL:"} {c.pendingCfcl} {"- Qty:"} {c.pendingCquantity} {"- Total Price:"} {(c.pendingCquantity * c.unitPrice).toLocaleString()}</option>
                                        </>)
                                    })
                                )}
                            </select>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Number of Containers</label>
                                    <input type="number" className="form-control"
                                        onChange={(e) => {
                                            setCommercialProduct((prevState) => ({
                                                ...prevState,
                                                fcl: e.target.value
                                            }));
                                            // setSupplierCommercialInvoiceProducts((prevState) => ({
                                            //     ...prevState,
                                            //     fcl: e.target.value
                                            // }));
                                        }}></input>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-12">
                                {commercialProduct.unit == null ? <label>Quantity</label> : <label>Quantity ({commercialProduct.unit})</label>}
                                    <input type="number" className="form-control"
                                        onChange={(e) => {
                                            setCommercialProduct((prevState) => ({
                                                ...prevState,
                                                productQuantity: e.target.value
                                            }));
                                            // setSupplierCommercialInvoiceProducts((prevState) => ({
                                            //     ...prevState,
                                            //     productQuantity: e.target.value
                                            // }));
                                        }}></input>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary" 
                        disabled={submitDisabled}
                        onClick={(e) => AddCommercialProduct(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal show={showEditCommercialProduct} onHide={handleCloseEditCommercialProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Product</label>
                            <select className="form-control mb-3" disabled={true} value={editCommercialProduct.supplierProductId}>
                                <option value="0" key="0">-- Select Product --</option>
                                {orderProducts.length > 0 && (
                                    orderProducts.map((c, index) => {
                                        return (<>
                                            <option value={c.supplierOrderProductId} key={index}>{c.productName}</option>
                                        </>)
                                    })
                                )}
                            </select>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label>Number of Containers</label>
                                    <input type="number" 
                                        className="form-control"
                                        value={editCommercialProduct.fcl}
                                        onChange={(e) => {
                                            setEditCommercialProduct((prevState) => ({
                                                ...prevState,
                                                fcl: e.target.value
                                            }));
                                           
                                        }}></input>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-12">
                                {commercialProduct.unit == null ? <label>Quantity</label> : <label>Quantity ({commercialProduct.unit})</label>}
                                    <input type="number" className="form-control"
                                        value={editCommercialProduct.productQuantity}
                                        onChange={(e) => {
                                            setEditCommercialProduct((prevState) => ({
                                                ...prevState,
                                                productQuantity: e.target.value
                                            }));
                                        }}></input>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseEditCommercialProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) => EditProduct(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteCommercialProduct} onHide={handleCloseDeleteCommercialProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this product?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseDeleteCommercialProduct}>
                        Cancel
                    </button>
                    <button className="btn btn-danger" onClick={(e) => DeleteCommercialProduct(e)}>
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ListCommercialProduct