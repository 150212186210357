import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';

const ManageSupplier = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [supplierName, setSupplierName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [companyId, setCompanyId] = useState(0);

    const GetSupplier = (supplierId) => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers/" + supplierId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierName(response.data.supplierName)
                setPhoneNumber(response.data.phone)
                setAddress(response.data.address)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // const GetCompanies = () => {
    //     var config = {
    //         method: 'get',
    //         url: "Company/GetCompany",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': Global.Host
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setCompanies(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { 
                supplierName: supplierName, 
                phone: phoneNumber, 
                address: address, 
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!") 
            }
            if (pId.SupplierId === "0") { //Add Product Size
                var config = {
                    method: 'post',
                    url: "Supplier/AddSupplier",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Supplier Added Successfully")
                            navigate("/Accounting/Supplier/View/" + response.data)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Supplier
                var config1 = {
                    method: 'put',
                    url: "Supplier/EditSupplier/" + pId.SupplierId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config1)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Supplier Updated Successfully")
                            navigate("/Accounting/Supplier")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (supplierName === "" || companyId === "0" || phoneNumber === "" || address === "" ) {
            toast.error("Enter all required fields");
            return false;
        }
        else if (supplierName === "") {
            toast.error("Enter Supplier Name");
            return false;
        }
        else if (phoneNumber === "") {
            toast.error("Enter Phone Number");
            return false;
        }
        else if (address === "") {
            toast.error("Enter Address Number");
            return false;
        }
        return true;
    }

    useEffect(() => {
        // GetCompanies();
        if (pId.SupplierId !== "0") {
            setPageType("Edit");
            GetSupplier(pId.SupplierId)
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Suppliers</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Supplier</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Supplier</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Accounting/Supplier" className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Supplier Name</label>
                                            <input className="form-control" type="text" value={supplierName} onChange={(e) => setSupplierName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Phone Number</label>
                                            <input className="form-control" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Address</label>
                                            <input className="form-control" type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
                                        </div>
                                        {/* <div className="col-md-6">
                                                <label className="col-md-2 col-form-label">Company</label>
                                                <select className="form-control" value={companyId} onChange={(e) => setCompanyId(e.target.value)} required>
                                                    <option value="0" key="0">-- Select Company Name --</option>
                                                    {companies.length > 0 && (
                                                        companies.map((c, index) => {
                                                            return (<>
                                                                <option value={c.companyId} key={index}>{c.companyName}</option>
                                                            </>)
                                                        })
                                                    )}
                                                </select>
                                            </div>  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
export default ManageSupplier