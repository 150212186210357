import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { Link } from "react-router-dom";
import { Global } from "../../../../Variables/Global";
import { FiEdit, FiEye, FiFileText, FiTrash2 } from 'react-icons/fi';
import axiosInstance from '../../../../api/axiosConfig';

const ListAccountSupplier = (props) => {
     
    const [accounts, setAccounts] = useState([]);

    const columns = [
        {
            name: '#',
            selector: row => row.supplierAccountId,
            width: "60px"
        },
        {
            name: 'Account Name',
            selector: row => row.supplierAccount1,
        },
        {
            name: 'Currency',
            selector: row => row.currencySuffix + " - " + row.currencyName,
        },
        {
            name: 'Options',
            width: "210px",
            selector: row => {
                return (
                    <>
                        <Link className="btn btn-outline-info mr-3" to={"/Accounting/Supplier/" + props.supplierId + "/Account/" + row.supplierAccountId + "/Statment"}><FiFileText /></Link>
                        <Link className="btn btn-outline-success mr-3" to={"/Accounting/Supplier/" + props.supplierId + "/Account/" + row.supplierAccountId}><FiEye /></Link>
                        <button onClick={() => { props.setSupplierAccountId(row.supplierAccountId); props.setShowSupplierAccountList(true) }} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </button>
                        <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button>
                    </>
                )
            },
        },
    ];

    const GetSupplierAccounts = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccounts/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setAccounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        GetSupplierAccounts(props.supplierId);
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <h4 className="card-title">List Supplier Accounts</h4>
                </div>
                <div className='col-12 col-md-6 text-right'>
                    <button className="btn btn-primary" onClick={() => props.setShowSupplierAccountList(true)}>Add Supplier Account</button>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={accounts}
            />
        </>
    )
}
export default ListAccountSupplier