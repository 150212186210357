import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';

const Permissions = () => {
     
    var navigate = useNavigate();
    const pId = useParams();
    const [permission, setPermission] = useState({})

    const submitForm = (e) => {
        console.log(permission)
        e.preventDefault();
        var config = {
            method: 'put',
            url: "UserPermission/EditUserPermission/" + pId.PermissionId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: permission
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Employee Permission Updated Successfully")
                    navigate("/HR/Employees")
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const GetUserPermissions = () => {
        var config = {
            method: 'get',
            url: "UserPermission/GetUserPermissionByPermissionId/" + pId.PermissionId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data.pJoin)
                setPermission(response.data.pJoin)
                // console.log(localStorage.getItem("uTsReP"))
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [disableSupplier, setDisableSupplier] = useState(false)
    const handleSupplierPermission = (value) => {
        if (value == false) {
            setPermission((prevState) => ({ ...prevState, addSupplier: false }))
            setPermission((prevState) => ({ ...prevState, editSupplier: false }))
            setDisableSupplier(true)
        }
        else {
            setDisableSupplier(false)
        }
    }

    useEffect(() => {
        document.title = "User Permissions";
        GetUserPermissions();
        // var x = JSON.parse(localStorage.getItem("uTsReP"));
        // setPermission(() => x);
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Permissions</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Mercator</li>
                                    <li className="breadcrumb-item active">Permissions</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <div className="card permissions">

                                <div className="card-body">
                                
                                    <div className="row">
                                        <div className="col-6">
                                            <h4 className="card-title">Employee Permissions</h4>
                                        </div>
                                        <div className="col-6 text-right">
                                            <Link to={"/HR/Employees"} className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Dashboard</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                <h5>
                                                    Dashboard</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewDashboard} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewDashboard: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <h3>
                                                    Human Resources</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                <h5 className="mb-3">
                                                    Departments</h5>
                                                <div>
                                                    <input type="checkbox" checked={permission.viewDepartment} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewDepartment: e.target.checked }))} />
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={permission.addDepartment} onChange={(e) => setPermission((prevState) => ({ ...prevState, addDepartment: e.target.checked }))} disabled={permission.viewDepartment == false ? "disabled" : ""} />
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={permission.editDepartment} onChange={(e) => setPermission((prevState) => ({ ...prevState, editDepartment: e.target.checked }))} disabled={permission.viewDepartment == false ? "disabled" : ""} />
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                {/* <div>
                                                    <label >
                                                        <input type="checkbox" />
                                                        

                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div> */}
                                            </div>
                                            <div className="col-3">
                                                <h5 className="mb-3">
                                                    Positions</h5>
                                                <div>
                                                    <input type="checkbox" checked={permission.viewPosition} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewPosition: e.target.checked }))} />

                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={permission.addPosition} onChange={(e) => setPermission((prevState) => ({ ...prevState, addPosition: e.target.checked }))} />

                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={permission.editPosition} onChange={(e) => setPermission((prevState) => ({ ...prevState, editPosition: e.target.checked }))} />

                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                {/* <div>
                                                    <label >
                                                        <input type="checkbox" />
                                                        

                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div> */}
                                            </div>
                                            <div className="col-3">
                                                <h5 className="mb-3">
                                                    Employee</h5>
                                                <div>
                                                    <input type="checkbox" checked={permission.viewEmployee} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewEmployee: e.target.checked }))} />

                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={permission.addEmployee} onChange={(e) => setPermission((prevState) => ({ ...prevState, addEmployee: e.target.checked }))} />

                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="checkbox" checked={permission.editEmployee} onChange={(e) => setPermission((prevState) => ({ ...prevState, editEmployee: e.target.checked }))} />

                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editPermission} onChange={(e) => setPermission((prevState) => ({ ...prevState, editPermission: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Permissions
                                                    </label>
                                                </div>
                                                {/* <div>
                                                    <label >
                                                        <input type="checkbox" />
                                                        

                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Accounting</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                <h5>
                                                    Active Orders</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewAllActiveOrders} onChange={(e) => { setPermission((prevState) => ({ ...prevState, viewAllActiveOrders: e.target.checked })); handleSupplierPermission(e.target.checked) }} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Supplier</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewSupplier} onChange={(e) => { setPermission((prevState) => ({ ...prevState, viewSupplier: e.target.checked })); handleSupplierPermission(e.target.checked) }} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addSupplier} onChange={(e) => setPermission((prevState) => ({ ...prevState, addSupplier: e.target.checked }))} disabled={disableSupplier} />

                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editSupplier} onChange={(e) => setPermission((prevState) => ({ ...prevState, editSupplier: e.target.checked }))} disabled={disableSupplier} />

                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.deleteSupplier} onChange={(e) => setPermission((prevState) => ({ ...prevState, deleteSupplier: e.target.checked }))} disabled={disableSupplier} />

                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewSupplierOrders} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewSupplierOrders: e.target.checked }))} disabled={disableSupplier} />

                                                    </label>
                                                    <label>
                                                        View Orders
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewSupplierStatement} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewSupplierStatement: e.target.checked }))} disabled={disableSupplier} />

                                                    </label>
                                                    <label>
                                                        View Statement
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.manageSupplierOrder} onChange={(e) => setPermission((prevState) => ({ ...prevState, manageSupplierOrder: e.target.checked }))} disabled={disableSupplier} />

                                                    </label>
                                                    <label>
                                                        Manage Supplier Order
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Client</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewCustomer} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewCustomer: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addCustomer} onChange={(e) => setPermission((prevState) => ({ ...prevState, addCustomer: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editCustomer} onChange={(e) => setPermission((prevState) => ({ ...prevState, editCustomer: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.deleteCustomer} onChange={(e) => setPermission((prevState) => ({ ...prevState, deleteCustomer: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewCustomerOrders} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewCustomerOrders: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View Orders
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewCustomerStatement} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewCustomerStatement: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View Statement
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                    <input type="checkbox" checked={permission.manageCustomerOrder} onChange={(e) => setPermission((prevState) => ({ ...prevState, manageCustomerOrder: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                    Manage Customer Order
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Forwarder</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewForwarders} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewForwarders: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addForwarder} onChange={(e) => setPermission((prevState) => ({ ...prevState, addForwarder: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editForwarder} onChange={(e) => setPermission((prevState) => ({ ...prevState, editForwarder: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addFreightPayment} onChange={(e) => setPermission((prevState) => ({ ...prevState, addFreightPayment: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add Freight Payment
                                                    </label>
                                                </div>
                                            </div>
                                            
                                            <div className="col-3">
                                                <h5>
                                                    Expenses</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewExpenses} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewExpenses: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addExpenses} onChange={(e) => setPermission((prevState) => ({ ...prevState, addExpenses: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editExpenses} onChange={(e) => setPermission((prevState) => ({ ...prevState, editExpenses: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-3">
                                                <h5>
                                                    Expenses Types</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewExpensesType} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewExpensesType: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addExpensesType} onChange={(e) => setPermission((prevState) => ({ ...prevState, addExpensesType: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editExpensesType} onChange={(e) => setPermission((prevState) => ({ ...prevState, editExpensesType: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Banks</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewBanks} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewBanks: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addBank} onChange={(e) => setPermission((prevState) => ({ ...prevState, addBank: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add Bank
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editBank} onChange={(e) => setPermission((prevState) => ({ ...prevState, editBank: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Edit Bank
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Bank Accounts</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewBankAccountStatement} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewBankAccountStatement: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View Account Statement
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addBankAccount} onChange={(e) => setPermission((prevState) => ({ ...prevState, addBankAccount: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add Bank Account
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addPayments} onChange={(e) => setPermission((prevState) => ({ ...prevState, addPayments: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add Multiple Payments
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addWithdrawal} onChange={(e) => setPermission((prevState) => ({ ...prevState, addWithdrawal: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add Withdrawal
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addDeposit} onChange={(e) => setPermission((prevState) => ({ ...prevState, addDeposit: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add Deposit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Invoicing</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewInvoicing} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewInvoicing: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View Invoicing
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Finance</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                <h5>
                                                    Profits</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewProfits} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewProfits: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Sales</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                <h5>
                                                    Sales Orders</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewSalesOrders} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewSalesOrders: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewAllSalesOrders} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewAllSalesOrders: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View All Orders
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addSalesOrder} onChange={(e) => setPermission((prevState) => ({ ...prevState, addSalesOrder: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editSalesOrder} onChange={(e) => setPermission((prevState) => ({ ...prevState, editSalesOrder: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.deleteSalesOrder} onChange={(e) => setPermission((prevState) => ({ ...prevState, deleteSalesOrder: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Logistics</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                        <div className="col-3">
                                                <h5>
                                                    Freight</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewFreight} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewFreight: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addFreight} onChange={(e) => setPermission((prevState) => ({ ...prevState, addFreight: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editFreight} onChange={(e) => setPermission((prevState) => ({ ...prevState, editFreight: e.target.checked }))} />

                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Shipping Lines</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewShippingLines} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewShippingLines: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editShippingLines} onChange={(e) => setPermission((prevState) => ({ ...prevState, editShippingLines: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                               
                                                
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    My Shipments</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewShipments} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewShipments: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.showAllShipments} onChange={(e) => setPermission((prevState) => ({ ...prevState, showAllShipments: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View All Shipments
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addShipment} onChange={(e) => setPermission((prevState) => ({ ...prevState, addShipment: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editShipment} onChange={(e) => setPermission((prevState) => ({ ...prevState, editShipment: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.deleteShipment} onChange={(e) => setPermission((prevState) => ({ ...prevState, deleteShipment: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.exportAllShipments} onChange={(e) => setPermission((prevState) => ({ ...prevState, exportAllShipments: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Export All
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Settings</h3>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                        <div className="col-3">
                                                <h5>
                                                    Company</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewCompany} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewCompany: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editCompany} onChange={(e) => setPermission((prevState) => ({ ...prevState, editCompany: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Currency</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewCurrency} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewCurrency: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addCurrency} onChange={(e) => setPermission((prevState) => ({ ...prevState, addCurrency: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editCurrency} onChange={(e) => setPermission((prevState) => ({ ...prevState, editCurrency: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Product</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewProduct} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewProduct: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addProduct} onChange={(e) => setPermission((prevState) => ({ ...prevState, addProduct: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editProduct} onChange={(e) => setPermission((prevState) => ({ ...prevState, editProduct: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Size</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewSize} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewSize: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addSize} onChange={(e) => setPermission((prevState) => ({ ...prevState, addSize: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editSize} onChange={(e) => setPermission((prevState) => ({ ...prevState, editSize: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>

                                                    Type</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewType} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewType: e.target.checked }))} />



                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addType} onChange={(e) => setPermission((prevState) => ({ ...prevState, addType: e.target.checked }))} />



                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editType} onChange={(e) => setPermission((prevState) => ({ ...prevState, editType: e.target.checked }))} />



                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Unit</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewUnit} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewUnit: e.target.checked }))} />



                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addUnit} onChange={(e) => setPermission((prevState) => ({ ...prevState, addUnit: e.target.checked }))} />



                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editUnit} onChange={(e) => setPermission((prevState) => ({ ...prevState, editUnit: e.target.checked }))} />



                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Shipping Costs</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewShippingCostOptions} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewShippingCostOptions: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addShippingCostOption} onChange={(e) => setPermission((prevState) => ({ ...prevState, addShippingCostOption: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editShippingCostOption} onChange={(e) => setPermission((prevState) => ({ ...prevState, editShippingCostOption: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.deleteShippingCostOption} onChange={(e) => setPermission((prevState) => ({ ...prevState, deleteShippingCostOption: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Bank Charge</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.viewBankChargeOptions} onChange={(e) => setPermission((prevState) => ({ ...prevState, viewBankChargeOptions: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        View
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.addBankChargeOption} onChange={(e) => setPermission((prevState) => ({ ...prevState, addBankChargeOption: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Add
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.editBankChargeOption} onChange={(e) => setPermission((prevState) => ({ ...prevState, editBankChargeOption: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Edit
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.deleteBankChargeOption} onChange={(e) => setPermission((prevState) => ({ ...prevState, deleteBankChargeOption: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Delete
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <hr></hr>
                                                <h3>
                                                    Notifications</h3>
                                            </div>
                                            <div className="col-3">
                                                <h5>
                                                    Emails</h5>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.receiveAccountingSuppliersNotifications} onChange={(e) => setPermission((prevState) => ({ ...prevState, receiveAccountingSuppliersNotifications: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Accounting Suppliers
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.receiveAccountingClientsNotifications} onChange={(e) => setPermission((prevState) => ({ ...prevState, receiveAccountingClientsNotifications: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Accounting Clients
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.receiveLogisticsNotifications} onChange={(e) => setPermission((prevState) => ({ ...prevState, receiveLogisticsNotifications: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        Logistics
                                                    </label>
                                                </div>
                                                <div>
                                                    <label >
                                                        <input type="checkbox" checked={permission.receiveAllLogisticsNotifications} onChange={(e) => setPermission((prevState) => ({ ...prevState, receiveAllLogisticsNotifications: e.target.checked }))} />
                                                    </label>
                                                    <label>
                                                        All Logistics Team
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Permissions