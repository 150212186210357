import DataTable from "react-data-table-component";
import Select from "react-select";
import { FiEdit, FiEye, FiTrash2, FiPlus } from 'react-icons/fi';
import React, { useEffect, useState } from "react";
import { Global } from "../../../../Variables/Global";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";
import axiosInstance from '../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../utils/utils';
import { formatDate } from "../../../Sales/SalesOrder";
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ListSupplierOrders = (props) => {
    var pId = useParams();
    // const { orders } = props;
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [loading, setLoading] = useState(true);

    const [editingSupplierOrderId, setEditingSupplierOrderId] = useState(0);
    const [showAddSupplierOrder, setShowAddSupplierOrder] = useState(false);
    const handleCloseAddSupplierOrder = () => setShowAddSupplierOrder(false);
    const handleShowAddSupplierOrder = () => setShowAddSupplierOrder(true);

    const [supplierOrder, setSupplierOrder] = useState({
        salesOrderId: 0,
        payImmediately: false,
        invoiceNumber: "",
        otherCharges: 0,
        notes: "",
        paymentTermsId: 0,
        paymentTermsName: "",
        date: formatDate(new Date()),
        proformaFileURL: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        supplierAccountId: pId.AccountId,
    })

    const [paymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [currency, setCurrency] = useState("")
    const GetCurreny = () => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrencyBySupplierAccountId/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                GetSupplierOrders(pId.AccountId);
            })
            .catch(function (error) {
                console.log(error);
                GetSupplierOrders(pId.AccountId);
            });
    }

    const submitSupplierOrderForm = (e) => {
        e.preventDefault();
        if (IsValidSupplierOrderForm()) {
            if (editingSupplierOrderId > 0) { //Edit Supplier order
                var config = {
                    method: 'put',
                    url: "SupplierOrder/EditSupplierOrder/" + props.supplierOrderId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: supplierOrder
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Order Updated Successfully")
                            // props.setShowSupplierOrderList(false)
                            navigate("/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + editingSupplierOrderId)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                        toast.error(error.response.data)
                    });
            }
            else { // Add Supplier Order
                var config = {
                    method: 'post',
                    url: "SupplierOrder/AddSupplierOrder",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: supplierOrder
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Order Added Successfully")
                            navigate("/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + response.data)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }

    }
    const IsValidSupplierOrderForm = () => {
        if (supplierOrder.invoiceNumber == "" && supplierOrder.date == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (supplierOrder.invoiceNumber == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (supplierOrder.date == "") {
            toast.error("Enter Date");
            return false;
        }
        return true;
    }

    // Handle search input change
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if (value == null || value == '') {
            setFilteredInvoices(orders);
        }
        // Filter invoices based on the search query
        const filtered = orders.filter(invoice =>
            invoice.invoiceNumber.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredInvoices(filtered);
    }

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setLoading(true);
        GetCurreny();
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        document.title = "Orders"
    }, []); // This will run the effect only once when the component mounts


    const GetSupplierOrders = (supplierAccountId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrders/" + supplierAccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrders(response.data);
                setFilteredInvoices(response.data); // Initialize filteredInvoices with all invoices
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
                setLoading(false);
            });
    }

    const DeleteSupplierOrder = (supplierOrderId) => {
        var config = {
            method: 'delete',
            url: "SupplierOrder/DeleteSupplierOrder/" + supplierOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                GetSupplierOrders(pId.AccountId);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
            });
    }

    return (
        <>
            {permissions.viewSupplierOrders == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>Suppliers</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item"><Link onClick={() => { navigate(-1) }}>Suppliers</Link></li>
                                            <li className="breadcrumb-item">
                                                {/* <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{pId.SupplierName}</Link></li> */}
                                                <Link onClick={() => { navigate(-1) }}>{pId.SupplierName}</Link></li>

                                            <li className="breadcrumb-item">{pId.SupplierAccountName}</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>
                                        {permissions.manageSupplierOrder == true && (
                                            <button onClick={() => {
                                                GetPaymentTerms();
                                                handleShowAddSupplierOrder();
                                            }
                                            } className="ml-1 btn btn-primary"><FiPlus /> Add Supplier Order</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <section>
                                <div className="container-fluid">
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                placeholder="Invoice Number"
                                                value={searchQuery}
                                                onChange={handleSearchInputChange}
                                                style={{ marginBottom: '10px' }}
                                            />
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                                {loading ? (
                                                    Array.from({ length: 3 }).map((_, index) => (
                                                        <div key={index} style={{
                                                            border: '1px solid #ddd',
                                                            borderRadius: '5px',
                                                            padding: '15px',
                                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                            flex: '1 1 100%',
                                                            boxSizing: 'border-box',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: '#fff'
                                                        }}>
                                                            <Skeleton height={30} style={{ marginBottom: '10px' }} />
                                                            <Skeleton height={20} style={{ marginBottom: '10px' }} />
                                                            <Skeleton count={3} height={20} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    filteredInvoices.length === 0 ? (
                                                        <div>No invoices found</div>
                                                    ) : (
                                                        filteredInvoices.map(invoice => (
                                                            <div key={invoice.supplierOrderId} style={{
                                                                border: '1px solid #ddd',
                                                                borderRadius: '5px',
                                                                padding: '15px',
                                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                                flex: '1 1 100%',
                                                                boxSizing: 'border-box',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                backgroundColor: '#fff'
                                                            }}>
                                                                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <a href={`/Accounting/Supplier/${pId.SupplierId}/Account/${pId.AccountId}/Order/${invoice.supplierOrderId}`} style={{ textDecoration: 'none', color: '#f5b225' }}>
                                                                        <b>#{invoice.invoiceNumber}</b>
                                                                    </a>
                                                                    <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                        {invoice.date ? invoice.date.split('T')[0] : 'No Date'}
                                                                    </span>
                                                                </div>

                                                                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <span style={{ whiteSpace: 'pre-wrap' }}>
                                                                        {invoice.products}
                                                                    </span>
                                                                    <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                        {invoice.balance != null && invoice.balance <= 0 ?
                                                                            <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Paid</b></span>
                                                                            :
                                                                            <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{currency.currencySuffix} {invoice.balance.toLocaleString()}</b></span>
                                                                        }
                                                                        {invoice.pendingFCLs > 0 && <>
                                                                            <br />
                                                                            <span style={{ textAlign: 'right', color: '#936b16', backgroundColor: '#fdf0d3', borderColor: '#fce8be', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>{invoice.pendingFCLs == 1 ? "1 Pending FCL" : invoice.pendingFCLs + " Pending FCLs"} </span>
                                                                        </>}
                                                                        <br />
                                                                        <span style={{ textAlign: 'right', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                                            <i>{invoice.notes}</i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <hr />
                                                                <div style={{ flexGrow: 1 }}>
                                                                    {invoice.commercialInvoices && invoice.commercialInvoices.length > 0 ? (
                                                                        invoice.commercialInvoices.map(c => (
                                                                            <div key={c.invoiceNumber} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                <a href={`/Accounting/Supplier/${pId.SupplierId}/Account/${pId.AccountId}/Order/${invoice.supplierOrderId}`} style={{ textDecoration: 'none', color: '#3eb7ba' }}>
                                                                                    <b>{c.invoiceNumber}</b>
                                                                                    <span style={{ color: '#a3a3a3' }}>
                                                                                        {" - " + (c.date ? c.date.split('T')[0] : 'No Date')}
                                                                                    </span>
                                                                                </a>
                                                                                <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                                    {c.invoiceTotals.remainingTotal <= 0 ?
                                                                                        <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Paid</b></span>
                                                                                        :
                                                                                        <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{currency.currencySuffix} {c.invoiceTotals.remainingTotal.toLocaleString()}</b></span>
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <span style={{ color: '#a3a3a3' }}><i>No commercial invoices available</i></span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <Modal show={showAddSupplierOrder} onHide={handleCloseAddSupplierOrder}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Order for {pId.SupplierName}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <label>Invoice Number</label>
                                    <div className="input-group mb-3">
                                        <input className="form-control"
                                            type="text"
                                            placeholder="Enter the invoice number"
                                            value={supplierOrder.invoiceNumber}
                                            onChange={(e) => {
                                                setSupplierOrder((prevState) => ({
                                                    ...prevState,
                                                    invoiceNumber: e.target.value
                                                }));
                                            }}>
                                        </input>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label>Date</label>
                                    <input className="form-control mb-3"
                                        type="date"
                                        value={supplierOrder.date}
                                        onChange={(e) => {
                                            setSupplierOrder((prevState) => ({
                                                ...prevState,
                                                date: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-12 mb-3">
                                    <label>Payment Terms</label>
                                    <Select
                                        placeholder="-- Select Payment Terms --"
                                        isSearchable={true}
                                        value={supplierOrder.paymentTermsId > 0 ? { label: supplierOrder.paymentTermsName, value: supplierOrder.paymentTermsId } : null}
                                        options={paymentTerms.map(p => ({
                                            value: p,
                                            label: p.name
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            setSupplierOrder((prevState) => ({
                                                ...prevState,
                                                paymentTermsId: e.value.paymentTermsId,
                                                paymentTermsName: e.value.name
                                            }))
                                        }}>
                                    </Select>
                                </div>
                                <div className="col-12">
                                    <label>Other Charges</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">{currency.currencySuffix}</span>
                                        <input className="form-control"
                                            type="number"
                                            step="1"
                                            value={supplierOrder.otherCharges}
                                            placeholder="Other charges (Optional)"
                                            onChange={(e) => {
                                                setSupplierOrder((prevState) => ({
                                                    ...prevState,
                                                    otherCharges: e.target.value
                                                }));
                                            }}>
                                        </input>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        placeholder="Your notes (Optional)"
                                        value={supplierOrder.notes}
                                        onChange={(e) => {
                                            setSupplierOrder((prevState) => ({
                                                ...prevState,
                                                notes: e.target.value
                                            }));
                                        }}></textarea>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCloseAddSupplierOrder}>
                                Close
                            </button>
                            <button className="btn btn-primary" onClick={(e) => submitSupplierOrderForm(e)}>
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
            }
        </>
    )
}
export default ListSupplierOrders