import React, { useEffect, useState } from "react";
import { Global } from '../../Variables/Global';
import { FiPlus, FiTrash2, FiEdit } from 'react-icons/fi';
import { FcNext } from 'react-icons/fc';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import DataTable from "react-data-table-component";
import { getDecodedToken } from '../../utils/utils';
import moment from 'moment';
import { forEach } from "lodash-es";
import Dropzone from "react-dropzone"
import ImageComponent from './ImageComponent';
import { UncontrolledTooltip } from "reactstrap";
import axiosInstance from '../../api/axiosConfig';
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    CardTitle,
} from "reactstrap"
export function formatDate(date) {
    return date.toISOString().substring(0, 10);
}

const Orders = () => {

    const pId = useParams();
    const [createdAt, setCreatedAt] = useState()

    const [showModal, setShowModal] = useState(false);
    const [salesOrderIdToDelete, setSalesOrderIdToDelete] = useState(null);

    const handleDeleteClick = (salesOrderId) => {
        setSalesOrderIdToDelete(salesOrderId);
        setShowModal(true);
    };

    const handleConfirmSalesOrderDelete = () => {
        DeleteSalesOrder(salesOrderIdToDelete);
        setShowModal(false);
    };


    let { invoiceId } = useParams();
    const [localInvoices, setLocalInvoices] = useState('');
    const [settings, setSettings] = useState();
    const navigate = useNavigate();
    const GetAppSettings = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "Settings/GetAppSettings",
            headers: {},
            params: { companyId }
        };
        axiosInstance(config)
            .then(function (response) {
                setSettings(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const DeleteSalesOrder = (SalesOrderId) => {
        const body = {
            CompanyId: localStorage.getItem("C0m8N!"),
            UserId: localStorage.getItem("uTsReD"),
            SalesOrderId: SalesOrderId,
        }
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteSalesOrder",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    navigate('/Sales/Orders');
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }
    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliers/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [SupplierAccounts, setSupplierAccounts] = useState([])
    const GetSupplierAccounts = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccounts/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierAccounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [PaymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleSupplierChange = (supplierId) => {
        GetSupplierAccounts(supplierId);
    }
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const submitSupplierOrdersForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidSupplierOrders()) {
            const body = {
                supplierOrderId: supplierOrders.supplierOrderId,
                invoiceNumber: supplierOrders.invoiceNumber,
                date: supplierOrders.date,
                salesOrderId: pId.SalesOrderId,
                notes: supplierOrders.notes,
                otherCharges: supplierOrders.otherCharges,
                companyId: supplierOrders.companyId,
                supplierAccountId: supplierOrders.supplierAccountId,
                userId: localStorage.getItem("uTsReD"),
                payImmediately: supplierOrders.payImmediately,
                paymentTermsId: supplierOrders.paymentTermsId,
                paymentTermsName: supplierOrders.paymentTermsName,
                incotermId: supplierOrders.incotermId,
                incotermName: supplierOrders.incotermName,
                "products": orderProducts.map(orderProduct => ({
                    "ProductId": orderProduct.productId,
                    "SupplierOrderProductId": orderProduct.supplierOrderProductId,
                    "ProductName": orderProduct.productName,
                    "ProductSizeId": orderProduct.productSizeId,
                    "ProductSizeName": orderProduct.productSizeName,
                    "ProductTypeId": orderProduct.productTypeId,
                    "ProductTypeName": orderProduct.productTypeName,
                    "ProductUnitId": orderProduct.productUnitId,
                    "UnitTypeName": orderProduct.unitTypeName,
                    "NumberOfContainers": orderProduct.numberOfContainers,
                    "Quantity": orderProduct.quantity,
                    "Packing": orderProduct.packing,
                    "UnitPrice": orderProduct.unitPrice,
                    "Completed": 0,
                    "UserId": orderProduct.userId
                }))

            }
            var config = {
                method: 'post',
                url: "SupplierSalesOrder/AddSupplierSalesOrder",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: body
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        handleCloseOrdersModal();
                        setSupplierOrders(response.data);
                        GetSupplierSalesOrders();
                        toast.success(response.data.message)
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data)
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }


    const IsValidSupplierOrders = () => {
        if (supplierOrders.supplierAccountId === 0 || supplierOrders.invoiceNumber === 0 || supplierOrders.invoiceNumber === "" || orderProducts.length === 0) {

            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }
    const IsValidSupplierOrderProduct = () => {
        if (localOrderProduct.productName === "" || localOrderProduct.productSizeName === "" || localOrderProduct.productTypeName === "" || localOrderProduct.productUnitName === "" ||
            localOrderProduct.quantity === "" || localOrderProduct.unitPrice === "" || localOrderProduct.quantity === 0 || localOrderProduct.unitPrice === 0 ||
            supplierOrders.supplierAccountId === 0 || supplierOrders.invoiceNumber === 0 || supplierOrders.invoiceNumber === "") {

            toast.error("Enter all Product required fields");
            return false;
        }
        //if (checkIfSupplierProductExists()) {
        //    toast.error("Supplier Products already purchased");
        //    return false;
        //}
        return true;
    }
    const checkIfSupplierProductExists = () => {
        const { unitPrice, supplierOrderProductId } = localOrderProduct;
        const exists = orderProducts.some(product =>
            product.unitPrice === unitPrice && product.supplierOrderProductId === supplierOrderProductId
        );
        return exists;
    };

    const [permissions, setPermissions] = useState([]);
    const [showOrdersModal, setshowOrdersModal] = useState(false)
    const handleCloseOrdersModal = () => {
        setshowOrdersModal(false);
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        document.title = "Supplier Orders";
        var _date = moment();
        setCreatedAt(_date.format("yyyy-MM-DD"))
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAppSettings();
        GetSuppliers();
        GetPaymentTerms();
        GetProducts();
        GetCurrency();
        GetSupplierSalesOrders(localInvoices);
        GetCustomerSalesOrders();
        GetSalesOrderAttachment();
        GetIncoterms();
    }, [])

    const [supplierOrders, setSupplierOrders] = useState({
        companyId: localStorage.getItem("C0m8N!"),
        supplierAccountId: 0,
        invoiceNumber: "",
        date: formatDate(new Date()),
        notes: "",
        userId: localStorage.getItem("uTsReD"),
        createdAt: "",
        otherCharges: 0,
        paymentTermsId: 0,
        paymentTermsName: "",
        payImmediately: false,
        currencyId: 0,
        currencySuffix: "",
        SupplierId: 0,
        supplierName: "",
        SupplierAccountId: 0,
        supplierAccountName: "",
        incotermName: "",
        incotermId: 0,
        supplierOrderId: 0,
    });

    const [orderProducts, setOrderProducts] = useState([])
    const [localOrderProduct, setLocalOrderProduct] = useState({
        supplierOrderId: 0,
        supplierOrderProductId: 0,
        productId: 0,
        productSizeId: 0,
        productUnitId: 0,
        productTypeId: 0,
        numberOfContainers: "",
        quantity: "",
        unitPrice: "",
        pendingFCL: 0,
        pendingQuantity: 0,
        userId: localStorage.getItem("uTsReD"),
        productName: "",
        productSizeName: "",
        productTypeName: "",
        productUnitName: "",
        packing: "",
    })
    const handleProductAdd = () => {
        if (IsValidSupplierOrderProduct()) {
            if (localOrderProduct.supplierOrderId === 0 || typeof localOrderProduct.supplierOrderId === 'undefined') {
                setOrderProducts([
                    ...orderProducts,
                    {
                        supplierOrderId: localOrderProduct.supplierOrderId,
                        supplierOrderProductId: localOrderProduct.supplierOrderProductId,
                        productId: localOrderProduct.productId,
                        productName: localOrderProduct.productName,
                        productSizeId: localOrderProduct.productSizeId,
                        productSizeName: localOrderProduct.productSizeName,
                        productUnitId: localOrderProduct.productUnitId,
                        unitTypeName: localOrderProduct.productUnitName,
                        productUnitName: localOrderProduct.productUnitName,
                        productTypeId: localOrderProduct.productTypeId,
                        productTypeName: localOrderProduct.productTypeName,
                        numberOfContainers: localOrderProduct.numberOfContainers,
                        quantity: localOrderProduct.quantity,
                        unitPrice: localOrderProduct.unitPrice,
                        packing: localOrderProduct.packing,
                        userId: localStorage.getItem("uTsReD")
                    }
                ]);
                // Empty localOrderProduct fields
                setLocalOrderProduct({
                    supplierOrderId: 0,
                    productId: 0,
                    productSizeId: 0,
                    productUnitId: 0,
                    productTypeId: 0,
                    numberOfContainers: "",
                    quantity: "",
                    unitPrice: "",
                    pendingFCL: 0,
                    pendingQuantity: 0,
                    userId: localStorage.getItem("uTsReD"),
                    productName: "",
                    productSizeName: "",
                    productTypeName: "",
                    productUnitName: "",
                    packing: "",
                    supplierOrderProductId: 0,
                });
            } else {
                setOrderProducts(orderProducts.map(orderProduct => {
                    if (orderProduct.supplierOrderProductId === localOrderProduct.supplierOrderProductId) {
                        return {
                            ...orderProduct,
                            productId: localOrderProduct.productId,
                            supplierOrderProductId: localOrderProduct.supplierOrderProductId,
                            productName: localOrderProduct.productName,
                            productSizeId: localOrderProduct.productSizeId,
                            productSizeName: localOrderProduct.productSizeName,
                            productUnitId: localOrderProduct.productUnitId,
                            unitTypeName: localOrderProduct.productUnitName,
                            productTypeId: localOrderProduct.productTypeId,
                            productTypeName: localOrderProduct.productTypeName,
                            numberOfContainers: localOrderProduct.numberOfContainers,
                            quantity: localOrderProduct.quantity,
                            unitPrice: localOrderProduct.unitPrice,
                            packing: localOrderProduct.packing,
                            userId: localStorage.getItem("uTsReD")
                        };
                    } else {
                        return orderProduct;
                    }
                }));
                // Empty localOrderProduct fields
                setLocalOrderProduct({
                    supplierOrderId: 0,
                    productId: 0,
                    productSizeId: 0,
                    productUnitId: 0,
                    productTypeId: 0,
                    numberOfContainers: "",
                    quantity: "",
                    unitPrice: "",
                    pendingFCL: 0,
                    pendingQuantity: 0,
                    userId: localStorage.getItem("uTsReD"),
                    productName: "",
                    productSizeName: "",
                    productTypeName: "",
                    productUnitName: "",
                    packing: "",
                    supplierOrderProductId: 0,
                });
            }
        }
    };
    const handleCancelProductAdd = () => {
        // Empty localOrderProduct fields
        setLocalOrderProduct({
            supplierOrderId: 0,
            productId: 0,
            productSizeId: 0,
            productUnitId: 0,
            productTypeId: 0,
            numberOfContainers: "",
            quantity: "",
            unitPrice: "",
            pendingFCL: 0,
            pendingQuantity: 0,
            userId: localStorage.getItem("uTsReD"),
            productName: "",
            productSizeName: "",
            productTypeName: "",
            productUnitName: "",
            packing: "",
            supplierOrderProductId: 0,
        });
    };
    const GetSupplierSalesOrders = () => {
        var config = {
            method: 'get',
            url: 'SupplierSalesOrder/GetSupplierSalesOrder/' + localStorage.getItem("uTsReD") + '/' + pId.SalesOrderId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSalesOrders(response.data.data);
                console.log(response.data.data);
                GetCustomers();
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const [getProducts, setProducts] = useState([])
    const GetProducts = () => {
        var config = {
            method: 'get',
            url: "Product/GetProducts",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setProducts(response.data);
                GetSizes();

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [sizes, setSizes] = useState([])
    const GetSizes = () => {
        var config = {
            method: 'get',
            url: "ProductSize/GetProductSize",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSizes(response.data);
                GetTypes();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [types, setType] = useState([])
    const GetTypes = () => {
        var config = {
            method: 'get',
            url: "ProductType/GetProductTypes",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setType(response.data);
                GetUnits();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [units, setUnits] = useState([])
    const GetUnits = () => {
        var config = {
            method: 'get',
            url: "ProductUnit/GetProductUnit",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setUnits(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const deleteProduct = (index) => {
        let updatedOrderProducts = [...orderProducts];
        updatedOrderProducts.splice(index, 1); // Remove the product at the specified index
        setOrderProducts(updatedOrderProducts); // Update state with the modified orderProducts array
    }
    const EditProduct = (index) => {
        let updatedOrderProducts = [...orderProducts];
        var orderProductToUpdate = updatedOrderProducts[index]
        setLocalOrderProduct(orderProductToUpdate);
    }
    const [salesOrders, setSalesOrders] = useState([])
    const columns = [
        {
            name: 'Supplier',
            width: "300px",

            selector: row => {
                return (
                    <>
                        <div className="text-wrap mt-2 mb-2" >
                            <span><b>{row.supplierName}</b></span>
                            <br></br>
                            <span>{row.supplierAccountName}</span>
                            <br></br>
                            <span><b>#{row.invoiceNumber}</b></span>
                        </div>
                    </>)
            },
        },
        {
            name: 'Products',
            selector: row => {
                return (
                    <>
                        <div className="text-wrap mt-2 mb-2">
                            {
                                row.productsFullName.map((p) => {
                                    return <li dangerouslySetInnerHTML={{ __html: parseHtml(p).trim() }} />
                                })
                            }
                        </div>
                    </>)
            }
        },
        {
            name: "FCLs",
            width: "100px",
            selector: row => {
                return (
                    <>
                        <div className="text-wrap">
                            <span>{row.totalPendingFCLs}</span>
                        </div>
                    </>)
            },
        },
        {
            name: "Notes",
            width: "200px",
            selector: row => {
                return (
                    <>
                        <div className="text-wrap">
                            <span>{row.notes}</span>
                        </div>
                    </>)
            },
        },
        {
            name: 'Date',
            width: "140px",
            selector: row => row.date.split("T")[0],
        },
        {
            name: 'Options',
            width: "140px",
            selector: row => {
                return (
                    <>
                        {permissions.editSalesOrder === true && salesOrders[0]?.status !== 1 &&
                            <button type="button" disabled={customerOrderProducts.length > 0} className="btn btn-outline-warning mr-3" onClick={() => {
                                handleSupplierOrderEditClick(row.supplierOrderId);
                            }}>
                                <FiEdit />
                            </button>}

                        {permissions.deleteSalesOrder === true && salesOrders[0]?.status !== 1 &&
                            <button type="button" disabled={customerOrderProducts.length > 0} className="btn btn-outline-danger" onClick={() => {
                                handleSupplierOrderDeleteClick(row.supplierOrderId);
                            }}>
                                <FiTrash2 />
                            </button>}
                    </>
                )
            },
        }
    ];

    function parseHtml(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const content = doc.body.innerHTML; // Use innerHTML to preserve tags
        return content;
    }

    const handleSupplierOrderEditClick = (supplierOrderId) => {
        var config = {
            method: 'get',
            url: "SupplierSalesOrder/GetSupplierOrderBySupplierSalesOrderID/" + localStorage.getItem("uTsReD") + '/' + supplierOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setshowOrdersModal(true);
                setSupplierOrders(response.data.data);
                setSupplierOrders((prevState) => ({
                    ...prevState,
                    date: response.data.data.date.split('T')[0]
                }))
                setOrderProducts(response.data.data.supplierSalesOrderProduct);
                setLocalOrderProduct({
                    supplierOrderId: 0,
                    productId: 0,
                    productSizeId: 0,
                    productUnitId: 0,
                    productTypeId: 0,
                    numberOfContainers: "",
                    quantity: "",
                    unitPrice: "",
                    pendingFCL: 0,
                    pendingQuantity: 0,
                    userId: localStorage.getItem("uTsReD"),
                    productName: "",
                    productSizeName: "",
                    productTypeName: "",
                    productUnitName: "",
                    packing: "",
                    supplierOrderProductId: 0,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);
    const [supplierOrderIdToDelete, setSupplierOrderIdToDelete] = useState(null);

    const handleSupplierOrderDeleteClick = (SupplierOrderId) => {
        setSupplierOrderIdToDelete(SupplierOrderId);
        setShowDeleteSupplierModal(true);
    };

    const handleConfirmDelete = () => {
        DeleteSupplierOrder(supplierOrderIdToDelete);
        setShowDeleteSupplierModal(false);
    };
    const DeleteSupplierOrder = (supplierOrderIdToDelete) => {
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteSupplierOrder/" + supplierOrderIdToDelete,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetSupplierSalesOrders();
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }
    // Link to Clients Section

    const [supplierClientOrder, setSupplierClientOrder] = useState({
        companyId: localStorage.getItem("C0m8N!"),
        customerOrderId: 0,
        userId: localStorage.getItem("uTsReD"),
        supplierId: 0,
        clientProforma: "",
        notes: "",
        commission: 0,
        currencyId: 0,
        clientAccountId: 0,
        shippingAddressId: 0,
        billingAddressId: 0,
        shippingConsignee: "",
        billingConsignee: "",
        paymentTermsId: 0,
        paymentTermsName: "",
        supplierCurrencySuffix: "",
    });

    const [showClientsModal, setShowClientsModal] = useState(false)
    const handleCloseClientsModal = () => {
        setShowClientsModal(false);
    }

    const [customer, setCustomer] = useState([]);
    const GetCustomers = () => {
        var config = {
            method: 'get',
            url: 'Customer/GetCustomersByCompany/' + localStorage.getItem("C0m8N!"),
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomer(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [clientProducts, setclientProducts] = useState([])
    const [localClientProduct, setLocalClientProduct] = useState({
        CustomerOrderId: 0,
        supplierOrderProductId: 0,
        customerOrderProductId: 0,
        productId: 0,
        productSizeId: 0,
        productUnitId: 0,
        productTypeId: 0,
        quantity: "",
        unitPrice: "",
        pendingFCL: 0,
        pendingQuantity: 0,
        userId: localStorage.getItem("uTsReD"),
        productName: "",
        productSizeName: "",
        productTypeName: "",
        productUnitName: "",
        productFullDescription: "",
        exchangeRate: "",
        supplierId: 0,
    })
    const handleCancelClientProductAdd = () => {
        setLocalClientProduct({
            CustomerOrderId: 0,
            supplierOrderProductId: 0,
            customerOrderProductId: 0,
            productId: 0,
            productSizeId: 0,
            productUnitId: 0,
            productTypeId: 0,
            quantity: "",
            unitPrice: "",
            pendingFCL: 0,
            pendingQuantity: 0,
            userId: localStorage.getItem("uTsReD"),
            productName: "",
            productSizeName: "",
            productTypeName: "",
            productUnitName: "",
            productFullDescription: "",
            exchangeRate: "",
            supplierId: 0,
            numberOfContainers: ""
        })
    };

    const handleClientProductAdd = () => {
        if (IsValidClientOrderProduct()) {
            if (localClientProduct.customerOrderProductId === 0 || typeof localClientProduct.customerOrderProductId === 'undefined') {
                setclientProducts(prevClientProducts => {
                    const updatedClientProducts = [
                        ...prevClientProducts,
                        {
                            supplierOrderProductId: localClientProduct.supplierOrderProductId,
                            customerOrderProductId: localClientProduct.customerOrderProductId,
                            productId: localClientProduct.productId,
                            productName: localClientProduct.productName,
                            productSizeId: localClientProduct.productSizeId,
                            productSizeName: localClientProduct.productSizeName,
                            productUnitId: localClientProduct.productUnitId,
                            productUnitName: localClientProduct.productUnitName,
                            productTypeId: localClientProduct.productTypeId,
                            productTypeName: localClientProduct.productTypeName,
                            numberOfContainers: localClientProduct.numberOfContainers,
                            quantity: localClientProduct.quantity,
                            unitPrice: localClientProduct.unitPrice,
                            supplierInvoiceNumber: localClientProduct.supplierInvoiceNumber,
                            supplierPendingFCL: localClientProduct.supplierPendingFCL,
                            supplierUnitPrice: localClientProduct.supplierUnitPrice,
                            supplierPendingQuantity: localClientProduct.supplierPendingQuantity,
                            supplierCurrencySuffix: localClientProduct.supplierCurrencySuffix,
                            userId: localStorage.getItem("uTsReD"),
                            productFullDescription: localClientProduct.productFullDescription,
                            exchangeRate: localClientProduct.exchangeRate,
                            supplierId: localClientProduct.supplierId,
                            packing: localClientProduct.packing
                        }
                    ];
                    setclientProducts(() => updatePendingFCLandQTY(updatedClientProducts));
                    return updatedClientProducts;
                })
            } else {
                setclientProducts(prevClientProducts => {
                    const updatedClientProducts = prevClientProducts.map(clientProduct => {
                        if (clientProduct.customerOrderProductId === localClientProduct.customerOrderProductId) {
                            return {
                                ...clientProduct,
                                supplierOrderProductId: localClientProduct.supplierOrderProductId,
                                productId: localClientProduct.productId,
                                productName: localClientProduct.productName,
                                productSizeId: localClientProduct.productSizeId,
                                productSizeName: localClientProduct.productSizeName,
                                productUnitId: localClientProduct.productUnitId,
                                productUnitName: localClientProduct.productUnitName,
                                productTypeId: localClientProduct.productTypeId,
                                productTypeName: localClientProduct.productTypeName,
                                numberOfContainers: localClientProduct.numberOfContainers,
                                quantity: localClientProduct.quantity,
                                unitPrice: localClientProduct.unitPrice,
                                supplierInvoiceNumber: localClientProduct.supplierInvoiceNumber,
                                supplierPendingFCL: localClientProduct.supplierPendingFCL,
                                supplierUnitPrice: localClientProduct.supplierUnitPrice,
                                supplierPendingQuantity: localClientProduct.supplierPendingQuantity,
                                supplierCurrencySuffix: localClientProduct.supplierCurrencySuffix,
                                userId: localStorage.getItem("uTsReD"),
                                productFullDescription: localClientProduct.productFullDescription,
                                exchangeRate: localClientProduct.exchangeRate,
                                supplierId: localClientProduct.supplierId,
                                packing: localClientProduct.packing
                            };
                        }
                        return clientProduct;
                    });
                    setclientProducts(() => updatePendingFCLandQTY(updatedClientProducts));
                    return updatedClientProducts;
                });
            }
            // Empty localOrderProduct fields
            setLocalClientProduct({
                supplierOrdperProductId: 0,
                customerOrderProductId: 0,
                productId: 0,
                productSizeId: 0,
                productUnitId: 0,
                productTypeId: 0,
                numberOfContainers: "",
                quantity: "",
                unitPrice: "",
                pendingFCL: 0,
                pendingQuantity: 0,
                userId: localStorage.getItem("uTsReD"),
                productName: "",
                productSizeName: "",
                productTypeName: "",
                productUnitName: "",
                productFullDescription: "",
                exchangeRate: "",
                supplierId: 0,
                packing: ""
            });
        }
    }
    const updatePendingFCLandQTY = (updatedClientProducts) => {

        var list = supplierOrderProducts;
        list.forEach(sop => {
            // Filter the products by the specific supplierOrderProductId
            var relevantProducts = updatedClientProducts.filter(product => product.supplierOrderProductId === sop.supplierOrderProductId);

            if (relevantProducts != null && relevantProducts.length > 0) {
                // Calculate the sum of numberOfContainers
                var localPendingFCL = sop.pendingFCL - (updatedClientProducts == null || updatedClientProducts.length === 0 ? 0 : relevantProducts.reduce((acc, product) => acc + product.numberOfContainers, 0));
                var localPendingQty = sop.pendingQuantity - (updatedClientProducts == null || updatedClientProducts.length === 0 ? 0 : relevantProducts.reduce((acc, product) => acc + product.quantity, 0));

                sop.localPendingFCL = localPendingFCL;
                sop.localPendingQuantity = localPendingQty;
            }
        });

        setSupplierOrderProducts(list)
    }

    const deleteClientProduct = (index) => {
        let updatedClinetOrderProducts = [...clientProducts];
        updatedClinetOrderProducts.splice(index, 1); // Remove the product at the specified index
        setclientProducts(updatedClinetOrderProducts); // Update state with the modified orderProducts array
    }

    const EditClinetProduct = (index) => {
        let updatedClientOrderProducts = [...clientProducts];
        var orderClientProductToUpdate = updatedClientOrderProducts[index]
        setLocalClientProduct(orderClientProductToUpdate);
    }

    const [supplierOrderProducts, setSupplierOrderProducts] = useState([])
    const GetSupplierOrderProductBySupplierId = () => {
        var config = {
            method: 'get',
            url: "SupplierSalesOrder/GetSupplierOrderProductBySalesOrderId/" + pId.SalesOrderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setSupplierOrderProducts(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const checkIfProductExists = () => {
        const { unitPrice, supplierOrderProductId } = localClientProduct;
        const exists = clientProducts.some(product =>
            product.unitPrice === unitPrice && product.supplierOrderProductId === supplierOrderProductId
        );

        return exists;
    };

    const IsValidClientOrderProduct = () => {
        if (sameCurrency) {
            localClientProduct.exchangeRate = 1.0;
        }

        if (localClientProduct.supplierOrderProductId === 0 || localClientProduct.numberOfContainers == null || localClientProduct.numberOfContainers < 0 ||
            localClientProduct.quantity == null || localClientProduct.quantity <= 0 || localClientProduct.quantity === "" || localClientProduct.unitPrice == null ||
            localClientProduct.unitPrice <= 0 || localClientProduct.unitPrice === "" || localClientProduct.exchangeRate === "" || localClientProduct.exchangeRate === 0 || localClientProduct.exchangeRate == null) {
            toast.error("Enter all Product required fields");
            return false;
        }

        if (!sameCurrency) {
            if (localClientProduct.supplierUnitPrice > (localClientProduct.unitPrice * localClientProduct.exchangeRate)) {
                toast.warning("Supplier Price Grater than Client Price");
            }
        }
        else if (localClientProduct.supplierUnitPrice > localClientProduct.unitPrice) {
            toast.warning("Supplier Price Grater than Client Price");
        }

        var pendingFCL = supplierOrderProducts.filter(product => product.supplierOrderProductId === localClientProduct.supplierOrderProductId)[0].localPendingFCL;
        if (localClientProduct.numberOfContainers > pendingFCL) {
            toast.error("The entered number of containers exceeds the availibility");
            return false;
        }
        if (checkIfProductExists()) {
            toast.error("Product already purchased for this order!");
            return false;
        }
        return true;
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientSuffixInput, setClientSuffixInput] = useState("");

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const [error, setError] = useState('');
    const handleSuffixChange = (e) => {
        const value = e.target.value;
        if (value.length <= 4) {
            setClientSuffixInput(value);
            setError(''); // Clear any previous error message
        } else {
            setError('Client Suffix Code cannot exceed 4 characters.');
        }
    };
    const handleSuffixSubmit = () => {
        if (clientSuffixInput.trim() === "") {
            toast.error("Client Suffix cannot be empty");
            return;
        }
        submitCustomerSuffix(clientSuffixInput);
    };
    const submitCustomerSuffix = (clientSuffixInput) => {

        const body = {
            userId: localStorage.getItem("uTsReD"),
            companyId: localStorage.getItem("C0m8N!"),
            CustomerId: supplierClientOrder.clientId,
            SuffixCode: clientSuffixInput
        }

        var config1 = {
            method: 'post',
            url: "Customer/EditAddCustomerSuffix",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config1)
            .then(function (response) {
                if (response.status === 200) {
                    handleCloseModal();
                    toast.success("Client Suffix updated successfully");
                    GetClinetInvoiceNumber(response.data);
                    setSupplierClientOrder((prevState) => ({
                        ...prevState,
                        customerSuffixId: response.data,
                    }))
                }
            })
            .catch(function (error) {
                console.log(error.response.data);
                toast.error(error.response.data)
            });


    }
    const IsValidClientOrder = () => {
        if (supplierClientOrder.clientProforma == "") {
            toast.error("Make Sure to add Client Suffix");
            handleOpenModal();
            return false;
        }
        if (supplierClientOrder.clientAccountId == 0 || supplierClientOrder.clientAccountId == null || supplierClientOrder.clientProforma == "" ||
            supplierClientOrder.clientProforma == null || clientProducts.length === 0) {
            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }
    const submitSupplierClientOrdersForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidClientOrder()) {
            const body = {
                CustomerOrderId: supplierClientOrder.customerOrderId,
                CompanyId: localStorage.getItem("C0m8N!"),
                CustomerAccountId: supplierClientOrder.clientAccountId,
                InvoiceNumber: supplierClientOrder.clientProforma,
                UserId: localStorage.getItem("uTsReD"),
                CustomerSuffixId: supplierClientOrder.customerSuffixId,
                Commission: supplierClientOrder.commission,
                ShippingAddressId: supplierClientOrder.shippingAddressId,
                BillingAddressId: supplierClientOrder.billingAddressId,
                Notes: supplierClientOrder.notes,
                SalesOrderID: pId.SalesOrderId,
                PaymentTermsId: supplierClientOrder.paymentTermsId,
                "CustomerOrderProduct": clientProducts.map(clientProduct => ({
                    "CustomerOrderProductId": clientProduct.customerOrderProductId,
                    "SupplierOrderProductId": clientProduct.supplierOrderProductId,
                    "Fcl": clientProduct.numberOfContainers,
                    "ProductQuantity": clientProduct.quantity,
                    "UnitPrice": clientProduct.unitPrice,
                    "ExchangeRate": clientProduct.exchangeRate,
                    "SupplierId": clientProduct.supplierId,
                    "UserId": clientProduct.userId
                }))
            }
            var config = {
                method: 'post',
                url: "SupplierSalesOrder/AddSupplierClientOrder",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: body
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        handleCloseClientsModal();
                        GetSupplierSalesOrders(localInvoices);
                        GetCustomerSalesOrders();
                        setclientProducts([]);
                        toast.success(response.message)
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data)
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }
    //const [clinetInvoiceNumber, setClientInvoiceNumber] = useState(null)
    //const GetClinetInvoiceNumber = (customerId) => {
    //    var config = {
    //        method: 'get',
    //        url: "SupplierSalesOrder/GetClinetInvoiceNumber/" + customerId,
    //        headers: {},
    //    };

    //    axiosInstance(config)
    //        .then(function (response) {
    //            setClientInvoiceNumber(response.data.data);
    //            setSupplierClientOrder((prevState) => ({
    //                ...prevState,
    //                clientProforma: response.data.data,
    //            }))
    //            setClientSuffixInput("");
    //        })
    //        .catch(function (error) {
    //            console.log(error);
    //            setSupplierClientOrder((prevState) => ({
    //                ...prevState,
    //                clientProforma: "",
    //            }))
    //        });
    //}
    const [clinetInvoiceNumber, setClientInvoiceNumber] = useState(null)
    const GetClinetInvoiceNumber = (customerSuffixId) => {
        var config = {
            method: 'get',
            url: "SupplierSalesOrder/GetClinetInvoiceNumber/" + customerSuffixId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setClientInvoiceNumber(response.data.data);
                setSupplierClientOrder((prevState) => ({
                    ...prevState,
                    clientProforma: response.data.data,
                }))
                setClientSuffixInput("");
            })
            .catch(function (error) {
                console.log(error);
                setSupplierClientOrder((prevState) => ({
                    ...prevState,
                    clientProforma: "",
                }))
            });
    }
    const handleClientChange = (customerId) => {
        GetAccount(customerId);
        GetAddress(customerId);
        GetClientSuffix(customerId);
        setSupplierClientOrder((prevState) => ({
            ...prevState,
            clientProforma: "",

        }))
        setSupplierClientOrder((prevState) => ({
            ...prevState,
            customerSuffixId: null,
            suffixCode: null
        }))
        setSupplierClientOrder((prevState) => ({
            ...prevState,
            clientAccountId: null,
            customerAccountName: null,
            currencyId: null,
            currencySuffix: null,
        }));
        //GetClinetInvoiceNumber(customerId);

    }
    const [clientAccount, setClientAccount] = useState([])
    const GetAccount = (customerId) => {
        var config = {
            method: 'get',
            url: 'CustomerAccount/GetCustomerAccounts/' + customerId,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axiosInstance(config)
            .then(function (response) {
                setClientAccount(response.data);
            })
            .catch(function (error) {
                console.log(error);
                toast.warning("Something went wrong");
                toast.warning(error);
            });
    }
    const [clientAddress, setClientAddress] = useState([])
    const GetAddress = (customerId) => {

        var config = {
            method: 'get',
            url: 'Customer/GetCustomerAddresses/' + customerId,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axiosInstance(config)
            .then(function (response) {
                setClientAddress(response.data);
            })
            .catch(function (error) {
                console.log(error);
                toast.warning("Something went wrong");
                toast.warning(error);
            });
    }

    const [clientSuffix, setClientSuffix] = useState([])
    const GetClientSuffix = (customerId) => {

        var config = {
            method: 'get',
            url: 'Customer/GetCustomerSuffix/' + customerId,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axiosInstance(config)
            .then(function (response) {
                setClientSuffix(response.data);
            })
            .catch(function (error) {
                console.log(error);
                toast.warning("Something went wrong");
                toast.warning(error);
            });
    }


    const [sameCurrency, setSameCurrency] = useState(true)

    const [customerOrderProducts, setCustomerOrderProducts] = useState([])
    const clientColumns = [
        {
            name: 'Clients',
            width: "300px",

            selector: row => {
                return (
                    <>
                        <div className="text-wrap mt-2 mb-2" >
                            <span><b>{row.customerName}</b></span>
                            <br></br>
                            <span>{row.customerAccountName}</span>
                            <br></br>
                            <span><b>#{row.clientProforma}</b></span>
                        </div>
                    </>)
            },
        },
        {
            name: 'Products',
            selector: row => {
                return (
                    <>
                        <div className="text-wrap mt-2 mb-2">
                            {
                                row.productsFullName.map((p) => {
                                    return <li dangerouslySetInnerHTML={{ __html: parseHtml(p).trim() }} />
                                })
                            }
                        </div>
                    </>)
            }
        },
        {
            name: "Notes",
            width: "200px",
            selector: row => {
                return (
                    <>
                        <div className="text-wrap">
                            <span>{row.notes}</span>
                        </div>
                    </>)
            },
        },
        {
            name: 'Date',
            width: "140px",
            selector: row => row.createdAt.split("T")[0],
        },
        {
            name: 'Options',
            width: "140px",
            selector: row => {
                return (
                    <>
                        {permissions.editSalesOrder == true && salesOrders[0]?.status !== 1 && (
                            <button type="button" className="btn btn-outline-warning mr-3" onClick={() => {
                                handleClientOrderEditClick(row.customerOrderId);
                            }}>
                                <FiEdit />
                            </button>
                        )}

                        {permissions.deleteSalesOrder == true && salesOrders[0]?.status !== 1 &&
                            <button type="button" className="btn btn-outline-danger" disabled={submitDisabled} onClick={() => {
                                handleDeleteCustomerOrderProductClick(row.customerOrderId);
                            }}>
                                <FiTrash2 />
                            </button>}

                    </>
                )
            }
        }
    ];
    const handleClientOrderEditClick = (customerOrderId) => {
        var config = {
            method: 'get',
            url: "SupplierSalesOrder/GetCustomerOrderByCustomerOrderID/" + localStorage.getItem("uTsReD") + '/' + customerOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setShowClientsModal(true);
                setLocalClientProduct({
                    CustomerOrderId: 0,
                    supplierOrderProductId: 0,
                    customerOrderProductId: 0,
                    productId: 0,
                    productSizeId: 0,
                    productUnitId: 0,
                    productTypeId: 0,
                    quantity: "",
                    unitPrice: "",
                    pendingFCL: 0,
                    pendingQuantity: 0,
                    userId: localStorage.getItem("uTsReD"),
                    productName: "",
                    productSizeName: "",
                    productTypeName: "",
                    productUnitName: "",
                    productFullDescription: "",
                    exchangeRate: "",
                    supplierId: 0,
                    packing: ""
                });
                GetSupplierOrderProductBySupplierId();
                setSupplierClientOrder(response.data.data);
                setclientProducts(response.data.data.customerSalesOrderProductInfo);
                GetAddress(response.data.data.customerId);
                GetClientSuffix(response.data.data.customerId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [showDeleteCustomerOrderProductModal, setshowDeleteCustomerOrderProductModal] = useState(false);
    const [customerOrderProductIdToDelete, setCustomerOrderProductIdToDelete] = useState(null);

    const handleDeleteCustomerOrderProductClick = (CustomerOrderId) => {
        setCustomerOrderProductIdToDelete(CustomerOrderId);
        setshowDeleteCustomerOrderProductModal(true);
    };

    const handleConfirmDeleteCustomerOrderProduct = () => {
        DeleteCustomerOrderProduct(customerOrderProductIdToDelete);
        setshowDeleteCustomerOrderProductModal(false);
    };
    const DeleteCustomerOrderProduct = (customerOrderProductIdToDelete) => {
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteSupplierClientOrder/" + customerOrderProductIdToDelete,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetCustomerSalesOrders();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
                setSubmitDisabled(false);
            });
    }

    const GetCustomerSalesOrders = () => {
        var config = {
            method: 'get',
            url: 'SupplierSalesOrder/GetCustomerOrderProducts/' + localStorage.getItem("uTsReD") + '/' + pId.SalesOrderId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomerOrderProducts(response.data.data);
                console.log(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const EditClientProduct = (index) => {
        let updatedClientOrderProducts = [...clientProducts];
        var clientOrderProductToUpdate = updatedClientOrderProducts[index]
        setLocalClientProduct(clientOrderProductToUpdate);
    }
    // Upload Attachment Section

    const [selectedFiles, setselectedFiles] = useState([])

    //const handleAcceptedFiles = (files) => {
    //    const mappedFiles = files.map(file => Object.assign(file, {
    //        preview: URL.createObjectURL(file),
    //        formattedSize: formatBytes(file.size)
    //    }));
    //    setselectedFiles(prevFiles => [...prevFiles, ...mappedFiles]);
    //};
    /**
        * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const handleRemoveFile = (fileToRemove) => {
        setselectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    };

    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadProgressDiv, setUploadProgressDiv] = useState(false);

    const uploadFiles = async (files) => {
        setUploadProgressDiv(true);
        const companyCode = localStorage.getItem("C0m8N!");
        const userCode = localStorage.getItem("uTsReD");
        const salesOrderId = pId.SalesOrderId;

        files.forEach(async (file) => {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('companycode', companyCode);
            formData.append('usercode', userCode);
            formData.append('salesOrderId', salesOrderId);

            const config = {
                method: 'post',
                url: `${Global.API_URL}SupplierSalesOrder/Upload`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentCompleted
                    }));
                }
            };

            try {
                const response = await axiosInstance(config);
                if (response.status === 200) {
                    toast.success(`Upload Succeed for ${file.name}`);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: 100
                    }));
                    setUploadProgressDiv(false);
                    setselectedFiles([]);
                    GetSalesOrderAttachment();
                    console.log(`File successfully uploaded: ${file.name}`, response.data);
                }
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                toast.error(`Something Went Wrong with ${file.name}`);
            }
        });
    };

    const handleAcceptedFiles = (acceptedFiles) => {

        //Mapped the preview before uploading 
        const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        }));
        setselectedFiles(prevFiles => [...prevFiles, ...mappedFiles]);

        uploadFiles(acceptedFiles);
    };
    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const GetSalesOrderAttachment = () => {
        var config = {
            method: 'get',
            url: 'SupplierSalesOrder/GetSalesOrderAttachment/' + localStorage.getItem("uTsReD") + '/' + pId.SalesOrderId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setUploadedFiles(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleDelete = (attachmentId) => {
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteAttachment/" + attachmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetSalesOrderAttachment();
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }
    // Complete Sales Order

    const completeSalesOrder = () => {
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/CompleteSalesOrder/" + pId.SalesOrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success(response.data);
                    navigate('/Sales/Orders');
                }
            })
            .catch(function (error) {
                setSubmitDisabled(false);
                toast.error(error.response.data);
            });
    }
    const totalOrderAmount = orderProducts.reduce((total, product) => total + product.unitPrice * product.quantity, 0);
    const totalProductAmount = clientProducts.reduce((total, product) => total + product.unitPrice * product.quantity, 0);

    const renderFilePreview = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return (
                    <img
                        src={`${file.path}`}
                        alt={file.name}
                        height="50"
                        width="100"
                        className="auth-logo-light"
                        style={{ marginRight: '10px', marginTop: '10px' }}
                    />
                );
            case 'pdf':
            case 'txt':
                return (
                    <a
                        href={`${file.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="auth-logo-light"
                        style={{ marginRight: '10px', marginTop: '10px' }}
                    >
                        {file.name}
                    </a>
                );
            default:
                return <span>Unsupported file type</span>;
        }
    }

    const [incoterms, setIncoterms] = useState([])
    const GetIncoterms = () => {
        var config = {
            method: 'get',
            url: "Incoterms/GetIncoterms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setIncoterms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    return (
        <>
            {permissions.viewSalesOrders == null || permissions.viewSalesOrders == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content"  >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>List of Orders</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">List of Orders</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center'>
                                {permissions.deleteSalesOrder === true && salesOrders[0]?.status !== 1 && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger mr-5"
                                        style={{ marginRight: '20px' }}
                                        onClick={() => handleDeleteClick(pId.SalesOrderId)}
                                    >
                                        <FiTrash2 /> Delete Sales Order
                                    </button>
                                )}
                                {salesOrders[0]?.status !== 1 && (
                                    <button className="btn btn-success" disabled={submitDisabled} onClick={() => completeSalesOrder()}>
                                        Submit Sales Order
                                    </button>
                                )}
                            </div>
                            <div className='flex justify-between items-center mt-3'>
                                <h4>List of Supplier Orders</h4>
                                {permissions.addSalesOrder == true && salesOrders[0]?.status !== 1 && (
                                    <button onClick={() => {
                                        setSupplierOrders({
                                            companyId: localStorage.getItem("C0m8N!"),
                                            supplierAccountId: 0,
                                            supplierOrderId: 0,
                                            invoiceNumber: "",
                                            date: formatDate(new Date()),
                                            notes: "",
                                            userId: localStorage.getItem("uTsReD"),
                                            createdAt: null,
                                            debit: 0,
                                            credit: 0,
                                            pendingFCL: 0,
                                            otherCharges: 0,
                                            isPaid: 0,
                                            paymentTermsId: 0,
                                            paymentTermsName: "",
                                        });
                                        setOrderProducts([]);
                                        setLocalOrderProduct({
                                            supplierOrderId: 0,
                                            productId: 0,
                                            productSizeId: 0,
                                            productUnitId: 0,
                                            productTypeId: 0,
                                            numberOfContainers: "",
                                            quantity: "",
                                            unitPrice: "",
                                            pendingFCL: 0,
                                            pendingQuantity: 0,
                                            userId: localStorage.getItem("uTsReD"),
                                            productName: "",
                                            productSizeName: "",
                                            productTypeName: "",
                                            productUnitName: "",
                                            packing: "",
                                            supplierOrderProductId: 0,
                                        });
                                        setshowOrdersModal(true);
                                    }} className="ml-1 btn btn-primary"><FiPlus /> Add Supplier Orders</button>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <DataTable columns={columns} data={salesOrders} />
                                </div>
                            </div>
                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure you want to delete this item? All related Suppliers sales order will be deleted
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                        No
                                    </Button>
                                    <Button variant="danger" onClick={handleConfirmSalesOrderDelete}>
                                        Yes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal size="xl" show={showOrdersModal} onHide={handleCloseOrdersModal} backdrop="static">
                                <Modal.Header closeButton>
                                    <Modal.Title>Supplier Order</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label>Supplier</label>
                                                <Select
                                                    required
                                                    placeholder="Select Supplier"
                                                    isSearchable={true}
                                                    isDisabled={orderProducts.length > 0}
                                                    value={supplierOrders.supplierName ? { value: supplierOrders.supplierId, label: supplierOrders.supplierName } : null}
                                                    options={suppliers.map(c => ({
                                                        value: c,
                                                        label: c.supplierName
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setSupplierOrders((prevState) => ({
                                                            ...prevState,
                                                            supplierId: e.value.supplierId,
                                                            supplierName: e.value.supplierName
                                                        }))
                                                        handleSupplierChange(e.value.supplierId);
                                                    }}>
                                                </Select>
                                                
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label>Account</label>
                                                <Select
                                                    required
                                                    placeholder="Select Account"
                                                    isSearchable={true}
                                                    isDisabled={orderProducts.length > 0}
                                                    value={supplierOrders.supplierAccountName ? { value: supplierOrders.supplierAccountId, label: supplierOrders.supplierAccountName } : null}
                                                    options={SupplierAccounts.map(i => ({
                                                        value: i,
                                                        label: i.supplierAccount1
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setSupplierOrders((prevState) => ({
                                                            ...prevState,
                                                            supplierAccountId: e.value.supplierAccountId,
                                                            supplierAccountName: e.value.supplierAccount1,
                                                            currencyId: e.value.currencyId,
                                                            currencySuffix: e.value.currencySuffix,
                                                        }))
                                                    }}>
                                                </Select>
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label>Payment Terms</label>
                                                <Select
                                                    required
                                                    placeholder="Select Payment Terms"
                                                    isSearchable={true}
                                                    value={supplierOrders.paymentTermDescription ? { value: supplierOrders.paymentTermsId, label: supplierOrders.paymentTermDescription } : null}
                                                    options={PaymentTerms.map(i => ({
                                                        value: i.paymentTermsId,
                                                        label: i.name
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setSupplierOrders((prevState) => ({
                                                            ...prevState,
                                                            paymentTermsId: e.value,
                                                            paymentTermsName: e.label,
                                                            paymentTermDescription:e.label
                                                        }))
                                                    }}>
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label>Invoice Number</label>
                                                <input className="form-control"
                                                    type="text"
                                                    placeholder="Enter Invoice Number"
                                                    value={supplierOrders.invoiceNumber}
                                                    onChange={(e) => {
                                                        setSupplierOrders((prevState) => ({
                                                            ...prevState,
                                                            invoiceNumber: e.target.value,
                                                        }))
                                                    }} />
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label>Date</label>
                                                <input className="form-control"
                                                    type="date"
                                                    value={supplierOrders.date}
                                                    onChange={(e) => {
                                                        setSupplierOrders((prevState) => ({
                                                            ...prevState,
                                                            date: e.target.value
                                                        }));
                                                    }} />
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <div className="col-12">
                                                    <label>Incoterms</label>
                                                    <Select
                                                        required
                                                        placeholder="-- Select Incoterms --"
                                                        isSearchable={true}
                                                        value={supplierOrders.incotermName ? { value: supplierOrders.incotermId, label: supplierOrders.incotermName } : null}
                                                        options={incoterms.map(i => ({
                                                            value: i,
                                                            label: i.name
                                                        }))}
                                                        valuekey
                                                        onChange={(e) => {
                                                            setSupplierOrders((prevState) => ({
                                                                ...prevState,
                                                                incotermId: e.value.incotermId,
                                                                incotermName: e.value.name
                                                            }))
                                                        }}>
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label>Pay Immediately</label>
                                                <div className="form-check form-switch form-switch-md" onClick={() => {
                                                    setSupplierOrders((prevState) => ({
                                                        ...prevState,
                                                        payImmediately: !supplierOrders.payImmediately
                                                    }));
                                                }}>
                                                    <input
                                                        className="form-check-input checkbox"
                                                        type="checkbox"
                                                        checked={supplierOrders.payImmediately}
                                                        onChange={() => { }} />
                                                    <label className="form-check-label" style={{ cursor: 'pointer' }}>Notify Accounting</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label>Notes</label>
                                                <textarea className="form-control"
                                                    type="text"
                                                    placeholder="Add notes (optional)"
                                                    value={supplierOrders.notes}
                                                    onChange={(e) => {
                                                        setSupplierOrders((prevState) => ({
                                                            ...prevState,
                                                            notes: e.target.value,
                                                        }))
                                                    }} />
                                            </div>
                                        </div>

                                        <h5 htmlFor="txtPositionName" className="col-md-11 mt-4">Add Products</h5>
                                        <hr />

                                        <div className='row'>
                                            <div className="col-md-3 mb-3">
                                                <label>Product Name</label>
                                                <Select
                                                    placeholder="-- Select --"
                                                    isSearchable={true}
                                                    options={getProducts.map(c => ({
                                                        value: c,
                                                        label: c.productName
                                                    }))}
                                                    valuekey
                                                    value={localOrderProduct.productName ? { value: localOrderProduct.productId, label: localOrderProduct.productName } : null}
                                                    onChange={(e) => {
                                                        const selectedProduct = e.value;
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            productId: selectedProduct.productId,
                                                            productName: selectedProduct.productName
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label>Product Size</label>
                                                <Select
                                                    placeholder="-- Select --"
                                                    isSearchable={true}
                                                    options={sizes.map(c => ({
                                                        value: c,
                                                        label: c.productSizeName
                                                    }))}
                                                    valuekey
                                                    value={localOrderProduct.productSizeName ? { value: localOrderProduct.productSizeId, label: localOrderProduct.productSizeName } : null}
                                                    onChange={(e) => {
                                                        const selectedProduct = e.value;
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            productSizeId: selectedProduct.productSizeId,
                                                            productSizeName: selectedProduct.productSizeName
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label>Product Type</label>
                                                <Select
                                                    placeholder="-- Select --"
                                                    isSearchable={true}
                                                    options={types.map(c => ({
                                                        value: c,
                                                        label: c.productTypeName
                                                    }))}
                                                    valuekey
                                                    value={localOrderProduct.productTypeName ? { value: localOrderProduct.productTypeId, label: localOrderProduct.productTypeName } : null}
                                                    onChange={(e) => {
                                                        const selectedProduct = e.value;
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            productTypeId: selectedProduct.productTypeId,
                                                            productTypeName: selectedProduct.productTypeName
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label>Packing (Carton)</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={localOrderProduct.packing}
                                                    placeholder="Enter the packing type"
                                                    onChange={(e) => {
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            packing: e.target.value
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-md-3 mb-3">
                                                <label>Number of FCL</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={localOrderProduct.numberOfContainers}
                                                    placeholder="Enter number of FCL"
                                                    onChange={(e) => {
                                                        const newValue = parseInt(e.target.value);
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            numberOfContainers: newValue
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label>Unit Type</label>
                                                <Select
                                                    placeholder="-- Select --"
                                                    isSearchable={true}
                                                    options={units.map(c => ({
                                                        value: c,
                                                        label: c.productUnit1
                                                    }))}
                                                    valuekey
                                                    value={localOrderProduct.productUnitName ? { value: localOrderProduct.productUnitId, label: localOrderProduct.productUnitName } : null}
                                                    onChange={(e) => {
                                                        const selectedProduct = e.value;
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            productUnitId: selectedProduct.productUnitId,
                                                            productUnitName: selectedProduct.productUnit1
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label>Quantity</label>
                                                <input
                                                    type="number"
                                                    placeholder="Enter quantity"
                                                    className="form-control"
                                                    value={localOrderProduct.quantity}
                                                    step="0.01" // Allow decimal values
                                                    onChange={(e) => {
                                                        const newValue = parseFloat(e.target.value);
                                                        setLocalOrderProduct((prevState) => ({
                                                            ...prevState,
                                                            quantity: newValue
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label>Unit Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-text">{supplierOrders.currencySuffix}</div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter unit price"
                                                        step="0.1"
                                                        value={localOrderProduct.unitPrice}
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            setLocalOrderProduct((prevState) => ({
                                                                ...prevState,
                                                                unitPrice: newValue
                                                            }));
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-end">
                                                    <button className="btn btn-md btn-outline-danger mr-2" style={{ marginRight: '10px' }} onClick={() => handleCancelProductAdd()}>
                                                        Cancel
                                                    </button>
                                                    <button className="btn btn-lg btn-info" style={{ minWidth: '120px' }} onClick={() => handleProductAdd()}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="container mt-4">
                                            <h5 className="mb-4">Order Products</h5>
                                            <hr />
                                            <div className="row font-weight-bold">
                                                <div className="col-md-5">Product Description</div>
                                                <div className="col-md-2">Quantity</div>
                                                <div className="col-md-2">Unit Price</div>
                                                <div className="col-md-2">Total</div>
                                                <div className="col-md-1">Options</div>
                                            </div>
                                            {orderProducts.map((product, index) => (
                                                <div key={index} className="row mt-3 p-2 border rounded bg-light">
                                                    <div className="col-md-5">{product.numberOfContainers} FCL {product.productName}, {product.productSizeName}, {product.productTypeName}</div>
                                                    <div className="col-md-2">{product.quantity.toLocaleString()} {product.productUnitName}</div>
                                                    <div className="col-md-2">{supplierOrders.currencySuffix} {product.unitPrice.toLocaleString()}</div>
                                                    <div className="col-md-2">{supplierOrders.currencySuffix} {(product.unitPrice * product.quantity).toLocaleString()}</div>
                                                    <div className="col-md-1 d-flex justify-content-center">
                                                        <button className="btn btn-outline-warning btn-sm mr-2" onClick={() => EditProduct(index)}>
                                                            <FiEdit />
                                                        </button>
                                                        <button className="btn btn-outline-danger btn-sm" onClick={() => deleteProduct(index)}>
                                                            <FiTrash2 />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row mt-4">
                                                <div className="col-md-9 text-right"><strong>Total Order Amount:</strong></div>
                                                <div className="col-md-2 text-center"><strong>{supplierOrders.currencySuffix} {totalOrderAmount.toLocaleString()}</strong></div>
                                            </div>
                                        </div>
                                    </div>

                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseOrdersModal}>
                                        Close
                                    </Button>
                                    {
                                        <Button
                                            variant="primary"
                                            disabled={submitDisabled}
                                            onClick={(e) => {
                                                submitSupplierOrdersForm(e);
                                            }}
                                        >
                                            {supplierOrders.supplierOrderId ? 'Update Supplier Order' : 'Save Supplier Order'}
                                        </Button>
                                    }
                                </Modal.Footer>
                            </Modal>

                            <div className='flex justify-between items-center mt-3'>
                                <h4>List of client products</h4>
                                {permissions.addSalesOrder && salesOrders[0]?.status !== 1 && (
                                    <button
                                        onClick={() => {
                                            setSupplierClientOrder({
                                                companyId: localStorage.getItem("C0m8N!"),
                                                supplierId: 0,
                                                clientId: "",
                                                clientProforma: "",
                                                notes: "",
                                                commission: 0,
                                                shippingConsignee: "",
                                                billingConsignee: "",
                                                clientAccountId: 0,
                                                BillingAddressId: 0,
                                                ShippingAddressId: 0,
                                                paymentTermsId: 0,
                                                paymentTermsName: "",
                                            });
                                            GetSupplierOrderProductBySupplierId();
                                            setShowClientsModal(true);
                                            setclientProducts([]);
                                        }}
                                        disabled={salesOrders.length === 0}
                                        className="btn btn-primary flex items-center"
                                    >
                                        <FiPlus className="mr-1" /> Assign To Clients
                                    </button>
                                )}
                            </div>
                            <Modal size="xl" show={showClientsModal} onHide={handleCloseClientsModal} backdrop="static">
                                <Modal.Header closeButton>
                                    <Modal.Title>Client Order {supplierClientOrder.clientProforma != null && supplierClientOrder.clientProforma != "" && "#" + supplierClientOrder.clientProforma} </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="mb-4 row">
                                        <div className="row">
                                            <div className="col-4 mb-3 mt-3">
                                                <label htmlFor="clientName">Client Name</label>
                                                <Select
                                                    id="clientName"
                                                    required
                                                    isDisabled={clientProducts.length > 0}
                                                    placeholder="Select Client"
                                                    isSearchable={true}
                                                    value={supplierClientOrder.customerName ? { value: supplierClientOrder.clientId, label: supplierClientOrder.customerName } : null}

                                                    options={customer.map(i => ({
                                                        value: i,
                                                        label: i.customerName
                                                    }))}
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            clientId: e.value.customerId,
                                                            customerName: e.value.customerName,
                                                        }))
                                                        handleClientChange(e.value.customerId);
                                                    }}>
                                                </Select>
                                            </div>
                                            <div className="col-4 mb-3 mt-3">
                                                <label htmlFor="clientAccount">Client Account</label>
                                                <Select
                                                    id="clientAccount"
                                                    required
                                                    placeholder="Select Client Account"
                                                    isDisabled={clientProducts.length > 0}
                                                    isSearchable={true}
                                                    value={supplierClientOrder.customerAccountName ? { value: supplierClientOrder.clientAccountId, label: supplierClientOrder.customerAccountName } : null}
                                                    options={clientAccount.map(i => ({
                                                        value: i,
                                                        label: i.customerAccount1
                                                    }))}
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            clientAccountId: e.value.customerAccountId,
                                                            customerAccountName: e.value.customerAccount1,
                                                            currencyId: e.value.currencyId,
                                                            currencySuffix: e.value.currencySuffix,
                                                        }));
                                                        setSameCurrency(supplierClientOrder.currencyId !== e.value.currencyId);
                                                        setLocalClientProduct({
                                                            CustomerOrderId: 0,
                                                            supplierOrderProductId: 0,
                                                            customerOrderProductId: 0,
                                                            productId: 0,
                                                            productSizeId: 0,
                                                            productUnitId: 0,
                                                            productTypeId: 0,
                                                            quantity: "",
                                                            unitPrice: "",
                                                            pendingFCL: 0,
                                                            pendingQuantity: 0,
                                                            userId: localStorage.getItem("uTsReD"),
                                                            productName: "",
                                                            productSizeName: "",
                                                            productTypeName: "",
                                                            productUnitName: "",
                                                            productFullDescription: "",
                                                            exchangeRate: "",
                                                            supplierId: 0,
                                                        });
                                                    }}>
                                                </Select>
                                            </div>

                                            <div className="col-4 mb-3 mt-3">
                                                <label htmlFor="CustomerSuffix"> </label>
                                                <label>Customer Suffix</label>
                                                <Select
                                                    id="customerSuffix"
                                                    required
                                                    placeholder="Select Customer Suffix"
                                                    isSearchable={true}
                                                    isDisabled={clientProducts.length > 0}
                                                    value={supplierClientOrder.suffixCode ? { value: supplierClientOrder.customerSuffixId, label: supplierClientOrder.suffixCode } : null}
                                                    options={clientSuffix.map(i => ({
                                                        value: i,
                                                        label: i.suffixCode
                                                    }))}
                                                    onChange={(e) => {
                                                        GetClinetInvoiceNumber(e.value.customerSuffixId);
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            customerSuffixId: e.value.customerSuffixId,
                                                            suffixCode: e.value.suffixCode
                                                        }))
                                                    }}>
                                                </Select>
                                            </div>
                                            <div className="col-3 mb-3 mt-3">
                                                <label>Payment Terms</label>
                                                <Select
                                                    required
                                                    placeholder="Select Payment Terms"
                                                    isSearchable={true}
                                                    value={supplierClientOrder.paymentTermsName ? { value: supplierClientOrder.paymentTermsId, label: supplierClientOrder.paymentTermsName } : null}
                                                    options={PaymentTerms.map(i => ({
                                                        value: i.paymentTermsId,
                                                        label: i.name
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            paymentTermsId: e.value,
                                                            paymentTermsName: e.label
                                                        }))
                                                    }}>
                                                </Select>
                                            </div>
                                            <div className="col-3 mb-3 mt-3">
                                                <label htmlFor="billingAddress">Billing Address</label>
                                                <Select
                                                    id="billingAddress"
                                                    required
                                                    placeholder="Select Billing Address"
                                                    isSearchable={true}
                                                    value={supplierClientOrder.billingConsignee ? { value: supplierClientOrder.billingAddressId, label: supplierClientOrder.billingConsignee } : null}
                                                    options={clientAddress.map(i => ({
                                                        value: i,
                                                        label: i.consignee
                                                    }))}
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            billingAddressId: e.value.customerAddressId,
                                                            billingConsignee: e.value.consignee,
                                                        }));
                                                    }}>
                                                </Select>
                                            </div>
                                            <div className="col-3 mb-3 mt-3">
                                                <label htmlFor="shippingAddress">Shipping Address</label>
                                                <Select
                                                    id="shippingAddress"
                                                    required
                                                    placeholder="Select Shipping Address"
                                                    isSearchable={true}
                                                    value={supplierClientOrder.shippingConsignee ? { value: supplierClientOrder.shippingAddressId, label: supplierClientOrder.shippingConsignee } : null}
                                                    options={clientAddress.map(i => ({
                                                        value: i,
                                                        label: i.consignee
                                                    }))}
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            shippingAddressId: e.value.customerAddressId,
                                                            shippingConsignee: e.value.consignee,
                                                        }));
                                                    }}>
                                                </Select>
                                            </div>

                                            <div className="col-3 mb-3 mt-3">
                                                <label htmlFor="commission">Commission (Per FCL)</label>
                                                <input
                                                    id="commission"
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="-- Commission --"
                                                    value={supplierClientOrder.commission}
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            commission: e.target.value,
                                                        }))
                                                    }} />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label htmlFor="notes">Notes</label>
                                                <textarea
                                                    id="notes"
                                                    placeholder="Add notes (optional)"
                                                    className="form-control"
                                                    type="text"
                                                    value={supplierClientOrder.notes}
                                                    onChange={(e) => {
                                                        setSupplierClientOrder((prevState) => ({
                                                            ...prevState,
                                                            notes: e.target.value,
                                                        }))
                                                    }} />
                                            </div>
                                        </div>

                                        <h5 htmlFor="txtPositionName" className="col-md-11 mt-3">Add Products</h5>
                                        <hr />
                                        {
                                            <div className='row'>
                                                <div className={sameCurrency === true ? "col-md-6 mb-2 mt-3" : "col-md-4 mb-2 mt-3"}>
                                                    <label>Product Name</label>
                                                    <Select
                                                        placeholder="Select Product"
                                                        isSearchable={true}
                                                        value={localClientProduct.supplierInvoiceNumber ? {
                                                            label: (localClientProduct.supplierOrderProductId == null || localClientProduct.supplierOrderProductId == 0) ? "" : ('#' +
                                                                localClientProduct.supplierInvoiceNumber + ' - ' +
                                                                localClientProduct.productName + ', ' + localClientProduct.productSizeName + ', ' +
                                                                localClientProduct.productTypeName + ' (' + localClientProduct.supplierPendingFCL + ' FCL / ' + localClientProduct.supplierPendingQuantity + ' ' +
                                                                localClientProduct.productUnitName + ') (' + localClientProduct.supplierCurrencySuffix + ' ' + localClientProduct.supplierUnitPrice + ')')
                                                            , value: localClientProduct.productId
                                                        } : null}
                                                        options={supplierOrderProducts.map(c => ({
                                                            value: c,
                                                            label: ('#' + c.supplierInvoiceNumber + ' - ' + c.productName + ', ' + c.productSizeName + ', ' + c.productTypeName + ' (' +
                                                                c.localPendingFCL + ' FCL / ' + c.localPendingQuantity + ' ' + c.productUnitName + ') (' +
                                                                c.currencySuffix + ' ' + c.unitPrice + ')')
                                                        }))}
                                                        valuekey

                                                        onChange={(e) => {
                                                            setLocalClientProduct((prevState) => ({
                                                                ...prevState,
                                                                supplierOrderProductId: e.value.supplierOrderProductId,
                                                                customerOrderProductId: e.value.customerOrderProductId,
                                                                productName: e.value.productName,
                                                                productSizeName: e.value.productSizeName,
                                                                productTypeName: e.value.productTypeName,
                                                                productUnitName: e.value.productUnitName,
                                                                supplierInvoiceNumber: e.value.supplierInvoiceNumber,
                                                                supplierPendingFCL: e.value.localPendingFCL,
                                                                supplierUnitPrice: e.value.unitPrice,
                                                                supplierPendingQuantity: e.value.localPendingQuantity,
                                                                supplierCurrencySuffix: e.value.currencySuffix,
                                                                productFullDescription: e.value.productFullDescription,
                                                                supplierId: e.value.supplierId
                                                            }));
                                                            setSameCurrency(supplierClientOrder.currencyId === e.value.currencyId);
                                                        }}>
                                                    </Select>
                                                </div>
                                                <div className="col-md-2 mb-2 mt-3">
                                                    <label>FCL</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter FCL"
                                                        className="form-control"
                                                        value={localClientProduct.numberOfContainers}
                                                        onChange={(e) => {
                                                            const newValue = parseInt(e.target.value);
                                                            setLocalClientProduct((prevState) => ({
                                                                ...prevState,
                                                                numberOfContainers: newValue,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-2 mt-3">
                                                    {localClientProduct.productUnitName ? <label>Quantity (Per {localClientProduct.productUnitName})</label> : <label>Quantity</label>}
                                                    <input
                                                        type="number"
                                                        placeholder="Enter quantity"
                                                        className="form-control"
                                                        value={localClientProduct.quantity}
                                                        step="0.01" // Allow decimal values
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            setLocalClientProduct((prevState) => ({
                                                                ...prevState,
                                                                quantity: newValue
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-2 mb-2 mt-3">
                                                    {localClientProduct.productUnitName ? <label>Unit Price (Per {localClientProduct.productUnitName})</label> : <label>Unit Price</label>}
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-text">{supplierClientOrder.currencySuffix}</div>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter price"
                                                            step="0.1"
                                                            value={localClientProduct.unitPrice}
                                                            onChange={(e) => {
                                                                const newValue = parseFloat(e.target.value);
                                                                setLocalClientProduct((prevState) => ({
                                                                    ...prevState,
                                                                    unitPrice: newValue
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {sameCurrency == false && (
                                                    <>
                                                        <div className="col-2 mb-3 mt-3">
                                                            <label>{supplierClientOrder.currencySuffix} to {localClientProduct.supplierCurrencySuffix} Rate <i class="fas fa-info-circle" id="tooltip1" />
                                                                <UncontrolledTooltip placement="top" target="tooltip1">
                                                                    The client account currency is different than the supplier product currency, the exchange rate is required.
                                                                </UncontrolledTooltip></label>
                                                            <input type="number" className="form-control mb-3"
                                                                value={localClientProduct.exchangeRate}
                                                                onChange={(e) => {
                                                                    setLocalClientProduct((prevState) => ({
                                                                        ...prevState,
                                                                        exchangeRate: e.target.value
                                                                    }));
                                                                }}>
                                                            </input>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-md btn-outline-danger mr-2" style={{ marginRight: '10px' }} onClick={() => handleCancelClientProductAdd()}>
                                                    Cancel
                                                </button>
                                                <button className="btn btn-lg btn-info" style={{ minWidth: '120px' }} onClick={() => handleClientProductAdd()}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container mt-4">
                                        <h5 className="mb-4">Linked Products</h5>
                                        <hr />
                                        <div className="row font-weight-bold">
                                            <div className="col-md-5">Product Description</div>
                                            <div className="col-md-2">Quantity</div>
                                            <div className="col-md-2">Unit Price</div>
                                            <div className="col-md-2">Total</div>
                                            <div className="col-md-1">Options</div>
                                        </div>
                                        {clientProducts.map((product, index) => (
                                            <div key={product.customerOrderProductId} className="row mt-3 p-2 border rounded bg-light">
                                                <div className="col-md-5">{product.numberOfContainers} FCL {product.productName}, {product.productSizeName}, {product.productTypeName}</div>
                                                <div className="col-md-2">{product.quantity.toLocaleString()} {product.productUnitName}</div>
                                                <div className="col-md-2">{supplierOrders.supplierCurrencySuffix} {product.unitPrice.toLocaleString()}</div>
                                                <div className="col-md-2">{supplierOrders.supplierCurrencySuffix} {(product.unitPrice * product.quantity).toLocaleString()}</div>
                                                <div className="col-md-1 d-flex justify-content-center">
                                                    <button className="btn btn-outline-warning btn-sm mr-2" onClick={() => EditClinetProduct(index)}>
                                                        <FiEdit />
                                                    </button>
                                                    <button className="btn btn-outline-danger btn-sm" onClick={() => deleteClientProduct(index)}>
                                                        <FiTrash2 />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row mt-4">
                                            <div className="col-md-9 text-right"><strong>Total Amount:</strong></div>
                                            <div className="col-md-2 text-center"><strong>{supplierOrders.supplierCurrencySuffix} {totalProductAmount.toLocaleString()}</strong></div>
                                        </div>
                                    </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseClientsModal}>
                                        Close
                                    </Button>
                                    {
                                        <Button
                                            variant="primary"
                                            disabled={submitDisabled}
                                            onClick={(e) => {
                                                submitSupplierClientOrdersForm(e);
                                            }}
                                        >
                                            {supplierClientOrder.customerOrderId ? 'Update Client Order' : 'Save Client Order'}
                                        </Button>
                                    }
                                </Modal.Footer>
                            </Modal>
                            <div className="row">
                                <div className="col-12">
                                    <DataTable columns={clientColumns} data={customerOrderProducts} />
                                </div>
                            </div>

                            <br></br>
                            <React.Fragment>
                                <Row>
                                    <Col className="col-12 mt-3">
                                        <h4>List of Attachments</h4>
                                        <br></br>
                                        <Card>
                                            <CardBody>
                                                {salesOrders[0]?.status !== 1 && (
                                                    <Form>
                                                        <Dropzone onDrop={handleAcceptedFiles}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div className="dropzone dz-clickable">
                                                                    <div
                                                                        className="dz-message needsclick"
                                                                        {...getRootProps()}
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '100%',
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        <div className="mb-3">
                                                                            <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                                                        </div>
                                                                        <h4>Drop files here or click to upload.</h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                        {uploadProgressDiv && (
                                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                                {selectedFiles.map((f, i) => (
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-sm border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={i + "-file"}
                                                                    >
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    {f.type.startsWith('image/') ? (
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            alt={`Preview of ${f.name}`}
                                                                                            src={f.preview}
                                                                                        />
                                                                                    ) : (
                                                                                        <embed
                                                                                            data-dz-thumbnail=""
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            type="application/pdf"
                                                                                            src={f.preview}
                                                                                            width="80"
                                                                                            height="80"
                                                                                        />
                                                                                    )}
                                                                                </Col>
                                                                                <Col>
                                                                                    <Link to="#" className="text-muted font-weight-bold" title={f.name}>
                                                                                        {f.name}
                                                                                    </Link>
                                                                                    <p className="mb-0 text-truncate">
                                                                                        <strong>{f.formattedSize}</strong>
                                                                                    </p>
                                                                                    {uploadProgress[f.name] !== undefined && (
                                                                                        <div className="progress mt-2">
                                                                                            <div
                                                                                                className="progress-bar"
                                                                                                role="progressbar"
                                                                                                style={{ width: `${uploadProgress[f.name]}%` }}
                                                                                                aria-valuenow={uploadProgress[f.name]}
                                                                                                aria-valuemin="0"
                                                                                                aria-valuemax="100"
                                                                                                aria-label={`Upload progress: ${uploadProgress[f.name]}%`}
                                                                                            >
                                                                                                {uploadProgress[f.name]}%
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Col>
                                                                                <Col className="col-auto">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-danger btn-sm"
                                                                                        onClick={() => {
                                                                                            if (window.confirm(`Are you sure you want to delete ${f.name}?`)) {
                                                                                                handleRemoveFile(f);
                                                                                            }
                                                                                        }}
                                                                                        aria-label={`Delete ${f.name}`}
                                                                                    >
                                                                                        Delete
                                                                                    </button>
                                                                                </Col>

                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                ))}
                                                            </div>
                                                        )}

                                                    </Form>
                                                )}
                                                <div className="uploaded-files mt-4">
                                                    {uploadedFiles.map((file, index) => (
                                                        <div
                                                            key={index}
                                                            className="file-item"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                padding: '10px',
                                                                borderBottom: '1px solid #ccc'
                                                            }}
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {renderFilePreview(file)}
                                                                <div style={{ marginLeft: '10px' }}>
                                                                    <span>{file.name}</span>
                                                                    <div style={{ fontSize: '0.8em', color: '#888' }}>Size: {formatFileSize(file.size)}</div>
                                                                    <div style={{ fontSize: '0.8em', color: '#888' }}>
                                                                        Date: {new Date(file.createdAt).toISOString().slice(0, 10)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {permissions.deleteSalesOrder === true && salesOrders[0]?.status !== 1 && (
                                                                <button
                                                                    className="btn btn-danger btn-md"
                                                                    style={{ marginLeft: 'auto' }}
                                                                    onClick={() => handleDelete(file.attachmentId)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </React.Fragment>

                        </div>
                    </div>
                    <Modal show={showDeleteSupplierModal} onHide={() => setShowDeleteSupplierModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete supplier order?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteSupplierModal(false)}>
                                No
                            </Button>
                            <Button variant="danger" onClick={handleConfirmDelete}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showDeleteCustomerOrderProductModal} onHide={() => setshowDeleteCustomerOrderProductModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete customer order produc?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setshowDeleteCustomerOrderProductModal(false)}>
                                No
                            </Button>
                            <Button variant="danger" onClick={handleConfirmDeleteCustomerOrderProduct}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enter Client Suffix</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input
                                type="text"
                                value={clientSuffixInput}
                                onChange={handleSuffixChange}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleSuffixSubmit}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>)
            }
        </>

    )
}
export default Orders