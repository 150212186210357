import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from "react-select";
import axiosInstance from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import { Global } from '../../Variables/Global';

const MissingDataModal = ({ show, handleClose, handleSave, missingInvoice }) => {

    useEffect(() => {
        GetPaymentTerms();
        GetAddress(missingInvoice.customerId)
    }, [])

    const [missingData, setMissingData] = useState({
        companyId: localStorage.getItem("C0m8N!"),
        userId: localStorage.getItem("uTsReD"),
        invoiceNumber: missingInvoice.invoiceNumber,
        paymentTermsId: 0,
        paymentTermsName: "",
        billingAddressId: 0,
        billingConsignee: "",
        shippingAddressId: 0,
        shippingConsignee: ""
    });
    const SubmitForm = (e) => {
        e.preventDefault();
        const body = {
            InvoiceNumber: missingData.invoiceNumber,
            CompanyId: localStorage.getItem("C0m8N!"),
            UserId: localStorage.getItem("uTsReD"),
            PaymentTermsId: missingData.paymentTermsId,
            BillingAddressId: missingData.billingAddressId,
            shippingAddressId: missingData.shippingAddressId
        }
        var config = {
            method: 'post',
            url: "CustomerOrder/AddMissingInvoiceData",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success(response.data)
                    handleClose();
                    window.location.reload();
                }
            })
            .catch(function (error) {
                toast.error(error.response.data)
            });
    }

    const [PaymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [clientAddress, setClientAddress] = useState([])
    const GetAddress = (customerId) => {

        var config = {
            method: 'get',
            url: 'Customer/GetCustomerAddresses/' + customerId,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        axiosInstance(config)
            .then(function (response) {
                setClientAddress(response.data);
            })
            .catch(function (error) {
                toast.warning("Something went wrong");
                toast.warning(error);
            });
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Please fill the below required data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <strong>Invoice Number:</strong> {missingInvoice.invoiceNumber}
                </div>
                {(missingInvoice.paymentTermsId == null || missingInvoice.paymentTermsId === 0) &&
                    <div className="col-md-12 mb-3 mt-3">
                        <label>Payment Terms</label>
                        <Select
                            required
                            placeholder="Select Payment Terms"
                            isSearchable={true}
                            options={PaymentTerms.map(i => ({
                                value: i.paymentTermsId,
                                label: i.name
                            }))}
                            valuekey
                            onChange={(e) => {
                                setMissingData((prevState) => ({
                                    ...prevState,
                                    paymentTermsId: e.value,
                                    paymentTermsName: e.label
                                }))
                            }}>
                        </Select>
                    </div>
                }
                {(missingInvoice.billingAddressId == null || missingInvoice.billingAddressId === 0) &&
                    <div className="col-12 mb-3 mt-3">
                        <label htmlFor="billingAddress">Billing Address</label>
                        <Select
                            id="billingAddress"
                            required
                            placeholder="Select Billing Address"
                            isSearchable={true}
                            options={clientAddress.map(i => ({
                                value: i,
                                label: i.consignee
                            }))}
                            onChange={(e) => {
                                setMissingData((prevState) => ({
                                    ...prevState,
                                    billingAddressId: e.value.customerAddressId,
                                    billingConsignee: e.value.consignee,
                                }));
                            }}>
                        </Select>
                    </div>
                }
                {(missingInvoice.shippingAddressId == null || missingInvoice.shippingAddressId === 0) &&
                    <div className="col-12 mb-3 mt-3">
                        <label htmlFor="shippingAddress">Shipping Address</label>
                        <Select
                            id="shippingAddress"
                            required
                            placeholder="Select Shipping Address"
                            isSearchable={true}
                            options={clientAddress.map(i => ({
                                value: i,
                                label: i.consignee
                            }))}
                            onChange={(e) => {
                                setMissingData((prevState) => ({
                                    ...prevState,
                                    shippingAddressId: e.value.customerAddressId,
                                    shippingConsignee: e.value.consignee,
                                }));
                            }}>
                        </Select>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button variant="primary" onClick={SubmitForm}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MissingDataModal;