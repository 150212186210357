import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../../../Variables/Global";
import { AiOutlineFileExcel } from "react-icons/ai"
import axiosInstance from '../../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../../utils/utils';

const AccountStatment = () => {
    const pId = useParams()

    const [accountStatment, setAccountStatment] = useState([])
    const [permissions, setPermissions] = useState([]);
    const columns = [
        {
            name: "Invoice",
            // selector: row => row.performa,
            selector: row => {
                if (row.commercialInvoice == null || row.commercialInvoice == '') {
                    return (
                        <>
                            <span className="text-wrap">#{row.performa}</span>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div>
                                <span className="text-wrap">#{row.performa}</span>
                                <br />
                                <span className="text-wrap">#{row.commercialInvoice}</span>
                            </div>
                        </>
                    )
                }

            },
            width: "160px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: 'Shipment',
            selector: row => {
                if (row.blReferenceNo == null) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span> </span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 0) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Pending</b></span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 20) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>In Progress</b></span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 30) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#292b2c', backgroundColor: '#d3d5d6', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 35) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#41b6d9', backgroundColor: '#eff9fc', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 40) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#936b16', backgroundColor: '#fdf0d3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 45) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 50) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
                if (row.shipmentStatusId == 60) {
                    return (
                        <>
                            <div className="mt-2 mb-2">
                                <span>{row.shippingLine}</span>
                                <br />
                                <span>BL <b>#{row.blReferenceNo}</b></span>
                                <br />
                                <span style={{ color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{row.shipmentStatus}</b></span>
                                <br />
                                <span>{row.arrivalDate}</span>
                            </div>
                        </>
                    )
                }
            },
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
            width: "160px",
        },
        // {
        //     name: "Commercial Invoice",
        //     selector: row => row.commercialInvoice,
        //     width: "160px",
        //     conditionalCellStyles: [
        //         {
        //             when: row => row.debit != 0,
        //             style: {
        //                 backgroundColor: '#fbdde2',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => row.credit != 0,
        //             style: {
        //                 backgroundColor: '#def8e6',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
        //             style: {
        //                 backgroundColor: '#e7ddfb',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //     ],
        // },
        {
            name: "Description",
            selector: row => {
                return (<>
                    <div>
                        <span className="text-wrap">
                            {
                                (row.fcl != null ? row.fcl : "") + " " + (row.description != null ? row.description : "")
                            }
                        </span>
                    </div>
                </>
                )
            },
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Quantity",
            selector: row => row.quantity == null ? "" : row.quantity < 0 ? "0" : row.quantity.toLocaleString() + " " + row.unit,
            width: "140px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Price",
            selector: row => row.price == null ? "" : row.price.toLocaleString(),
            width: "100px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Debit",
            selector: row => row.debit == null ? "" : row.debit.toLocaleString(),
            width: "110px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Credit",
            selector: row => row.credit == null ? "" : row.credit.toLocaleString(),
            width: "110px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: "Balance",
            selector: row => row.balance == null ? "" : row.balance.toLocaleString(),
            width: "130px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        // {
        //     name: "Notes",
        //     selector: row => row.notes
        // },
        {
            name: "Client Account",
            // selector: row => row.customerAccount,
            selector: row => {
                if (row.customerAccount == null || row.customerAccount == '') {
                    return (
                        <>

                        </>
                    )
                } else {
                    return (
                        <>
                            <div >
                                <span className="text-wrap">{row.customerName} - {row.customerAccount} - <b>#{row.customerInvoice}</b></span>
                            </div>
                        </>
                    )
                }

            },
            // width: "140px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        // {
        //     name: "Client Invoice",
        //     selector: row => row.customerInvoice,
        //     width: "160px",
        //     conditionalCellStyles: [
        //         {
        //             when: row => row.debit != 0,
        //             style: {
        //                 backgroundColor: '#fbdde2',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => row.credit != 0,
        //             style: {
        //                 backgroundColor: '#def8e6',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //         {
        //             when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
        //             style: {
        //                 backgroundColor: '#e7ddfb',
        //                 color: 'black',
        //                 '&:hover': {
        //                     cursor: 'pointer',
        //                 },
        //             },
        //         },
        //     ],
        // },
        {
            name: "Date",
            selector: row => row.date != null ? row.date.split("T")[0] : row.createdDate.split("T")[0],
            width: "120px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => (row.commercialInvoice == null || row.commercialInvoice == '') && row.debit != 0,
                    style: {
                        backgroundColor: '#e7ddfb',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
    ]

    const GetAccountStatment = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'SupplierAccountStatement/GetSupplierAccountStatement/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setAccountStatment(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [accountInfo, setAccountInfo] = useState("");
    const GetAccountInfo = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'SupplierAccount/GetSupplierAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setAccountInfo(response.data);
                document.title = response.data.supplierName + ' - ' + response.data.supplierAccount1;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // const migrateAccountStatementWithCommercialInvoice = () => {

    //     var config = {
    //         method: 'get',
    //         url: 'SupplierAccountStatement/MigrateAccountStatementWithCommercialInvoice?CompanyId=1',
    //         headers: {}
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const DownloadCSV = (accountId) => {
        // var axios = require('axios');

        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'SupplierAccountStatement/DownloadSupplierAccountCSV/' + accountId,
            responseType: "arraybuffer",
            headers,
        };

        axiosInstance(config)
            .then(function (response) {
                const outputFilename = `${accountInfo.supplierName} - ${accountInfo.supplierAccount1} Statement of Account.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const DownloadPDF = (accountId) => {
        // var axios = require('axios');
        // migrateAccountStatementWithCommercialInvoice();
        const headers = { "Content-Type": "blob" };
        var config = {
            method: 'post',
            url: 'SupplierAccountStatement/DownloadSupplierAccountPDF/' + accountId,
            responseType: "arraybuffer",
            headers,
        };

        axiosInstance(config)
            .then(function (response) {
                const outputFilename = `${accountInfo.supplierName} - ${accountInfo.supplierAccount1} Supplier Account Statement.pdf`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", outputFilename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAccountStatment(pId.AccountId);
        GetAccountInfo(pId.AccountId);
    }, [])

    return (
        <>
            {permissions.viewSupplierStatement == null || permissions.viewSupplierStatement == false ? (
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <h1>You Have No Permission</h1>
                            </div>
                        </div>
                    </div>
                </div>
            ) :
                (
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Suppliers</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                            {/* <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                                    <li className="breadcrumb-item active">{account.supplierAccount1}</li> */}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row mb-3'>
                                                <div className='col-8 col-md-6'>
                                                    {/* {supplierName} - */}
                                                    <h4 className="card-title">{accountInfo.supplierName} - {accountInfo.supplierAccount1} Statement of Account</h4>
                                                </div>
                                                <div className="col-4 col-md-6 text-right">
                                                    <button onClick={() => DownloadCSV(pId.AccountId)} className="btn btn-primary mr-3"><AiOutlineFileExcel /> Export CSV</button>
                                                    <button onClick={() => DownloadPDF(pId.AccountId)} className="btn btn-primary"><AiOutlineFileExcel /> Export PDF</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <hr></hr>
                                                <DataTable
                                                    columns={columns}
                                                    data={accountStatment} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            }
        </>
    )
}
export default AccountStatment;