import React, { useEffect, useState } from "react";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Global } from '../../../Variables/Global';
import { Link } from 'react-router-dom';
import * as CurrencyService from '../../../api/CurrencyService';
import * as CustomerService from '../../../api/CustomerService';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Card, CardBody, Col, Row } from "reactstrap"
import { Tabs, Tab } from 'react-bootstrap';

const Customers = () => {
    const [customers, setCustomers] = useState([]);

    const [selectedCustomerRow, setSelectedCustomerRow] = useState({})

    const [customerName, setCustomerName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [customerSuffix, setCustomerSuffix] = useState("");

    // filter props
    const [search, setSearch] = useState('');
    const [accountSearch, setAccountSearch] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [currencyId, setCurrencyId] = useState(0);
    const [currencies, setCurrencies] = useState([]);
    const [permissions, setPermissions] = useState([]);

    // data fetching
    const LoadCurrencies = () => {
        return CurrencyService.Get().then(currencies => {
            setCurrencies(currencies);
        }).catch(err => {
            alert('Failed to load currencies');
        })
    }

    const [showAccountModal, setShowAccountModal] = useState(false)
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [showSuffixModal, setShowSuffixModal] = useState(false)
    const [editingAccount, setEditingAccount] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState("")
    const [editedCustomerAccountId, setEditedCustomerAccountId] = useState(0);
    // const [customerAccount, setCustomerAccount] = useState("")

    const handleCloseAccountModal = () => {
        setShowAccountModal(false)
        setEditingAccount(false)
    }
    const handleAddAccount = (customer) => {
        setSelectedCustomer(customer);
        console.log(customer)
        setShowAccountModal(true);
    }
    const handleCloseAddressModal = () => {
        setShowAddressModal(false)
        setEditingAccount(false)
    }
    const handleCloseSuffixModal = () => {
        setShowSuffixModal(false)
        setEditingAccount(false)
    }

    const [deletingAddressId, setDeletingAddressId] = useState(0);
    const [deletingSuffixId, setDeletingSuffixId] = useState(0);
    const [showDeleteCustomerAddress, setShowDeleteCustomerAddress] = useState(false);
    const [showDeleteCustomerSuffix, setShowDeleteCustomerSuffix] = useState(false);
    const handleShowDeleteCustomerAddress = () => {
        setShowDeleteCustomerAddress(true)
    }
    const handleCloseDeleteCustomerAddress = () => {
        setShowDeleteCustomerAddress(false)
    }
    const handleShowDeleteCustomerSuffix = () => {
        setShowDeleteCustomerSuffix(true)
    }
    const handleCloseDeleteCustomerSuffix = () => {
        setShowDeleteCustomerSuffix(false)
    }

    const handleAddAddress = (customer) => {
        setSelectedCustomer(customer);
        setCustomerAddress((prevState) => ({
            ...prevState,
            consignee: customer.customerName,
            country: '',
            dischargePort: '',
            finalPlaceOfDelivery: '',
            address: '',
            phoneNumber: '',
            customerAddressId: 0
        }));
        setShowAddressModal(true);
    }

    const handleAddSuffix = (customer) => {
        setClientSuffixInput();
        setCustomerSuffixId();
        setSelectedCustomer(customer);
        setCustomerSuffix((prevState) => ({
            ...prevState,
            consignee: customer.customerName,
            customerSuffixId: customer.customerSuffixId,
            suffixCode: ''
        }));
        setShowSuffixModal(true);
    }

    // const handleEditAddress = (address) => {
    //     // setSelectedCustomer(customer);
    //     // console.log(customer);
    //     setCustomerAddress((prevState) => ({
    //         ...prevState,
    //         consignee: address.consignee,
    //         country: '',
    //         dischargePort: '',
    //         finalPlaceOfDelivery: '',
    //         address: ''
    //     }));
    //     setShowAddressModal(true);
    // }

    const handleEditAccount = (accountId) => {
        setEditingAccount(true);
        setEditedCustomerAccountId(accountId);
        GetCustomerAccount(accountId)
        setShowAccountModal(true);
    }

    const submitAddCustomerAccountForm = (e) => {
        e.preventDefault();
        if (IsValidCustomerAccount()) {
            const body = {
                accountName: customerAccount.accountName,
                currencyId: customerAccount.currencyId,
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!")
            }
            if (!editingAccount) {
                var config = {
                    method: 'post',
                    url: "CustomerAccount/AddCustomerAccount/" + selectedCustomer.customerId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Customer Account Added Successfully");
                            LoadCustomers();
                            handleCloseAccountModal(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Customer Account
                var config = {
                    method: 'put',
                    url: "CustomerAccount/EditCustomerAccount/" + editedCustomerAccountId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Account Updated Successfully")
                            LoadCustomers();
                            handleCloseAccountModal(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValidCustomerAccount = () => {
        if (customerAccount.accountName == "" && currencyId == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (customerAccount.accountName == "") {
            toast.error("Enter Account Name");
            return false;
        }
        else if (customerAccount.currencyId == "") {
            toast.error("Enter Currency");
            return false;
        }
        return true;
    }

    const [customerAccount, setCustomerAccount] = useState({})
    const GetCustomerAccount = (accountId) => {
        var config = {
            method: 'get',
            url: 'CustomerAccount/GetCustomerAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomerAccount(response.data);
                setCustomerAccount((prevState) => ({
                    ...prevState,
                    accountName: response.data.customerAccount1,
                }));
                // setAccountName(response.data.customerAccount1)
                // setCurrencyId(response.data.currencyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    // const GetCustomerAddresses = (customerId) => {
    //     var config = {
    //         method: 'get',
    //         url: 'Customer/GetCustomerAddresses/' + customerId,
    //         headers: {}
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setCustomerAddresses(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const LoadCustomers = () => {
        return CustomerService.Get({
            search,
            accountSearch,
            invoiceNumber,
            currencyId
        }).then(data => {
            setCustomers(data);
            if (selectedCustomerRow != null && selectedCustomerRow.customerId > 0 && data != null && data.length > 0) {
                const row = data.filter(function (item) {
                    return item.customerId == selectedCustomerRow.customerId;
                })[0];
                setSelectedCustomerRow(row);
                // GetCustomerAddresses(row.customerId);
            }
        }).catch(err => {
            alert('Failed to load customers');
        })
    }

    const [showCustomerModal, setShowCustomerModal] = useState(false)
    const handleCloseCustomerModal = () => {
        setShowCustomerModal(false)
        setCustomerName("")
        //setPhoneNumber("")
        //setCustomerSuffix("")
        // setAddress("")
        localStorage.removeItem("Cl!8Nt")
    }

    // const GetCustomers = (customerId) => {
    //     var config = {
    //         method: 'get',
    //         url: "Customer/GetCustomers/" + customerId,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': Global.Host
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setCustomerName(response.data.customerName)
    //             setPhoneNumber(response.data.phone)
    //             setCustomerSuffix(response.data.suffix)
    //             // setAddress(response.data.address)
    //         })
    //         .catch(function (error) {
    //             console.log(error.response.data);
    //         });
    // }


    const submitCustomerForm = (e) => {
        e.preventDefault();
        if (IsValiCustomer()) {
            const body = {
                customerName: customerName,
                suffix: customerSuffix,
                address: "",
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!")
            }
            if (localStorage.getItem("Cl!8Nt") === null) { //Add
                var config = {
                    method: 'post',
                    url: "Customer/AddCustomer",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Client Added Successfully")
                            LoadCustomers();
                            handleCloseCustomerModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error.response.data);
                        toast.error(error.response.data)
                    });
            }
            else { // Update Client
                var config1 = {
                    method: 'put',
                    url: "Customer/EditCustomer/" + localStorage.getItem("Cl!8Nt"),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config1)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Client Updated Successfully")
                            handleCloseCustomerModal();
                            LoadCustomers();
                        }
                    })
                    .catch(function (error) {
                        console.log(error.response.data);
                        toast.error(error.response.data)
                    });
            }
        }
    }

    const IsValiCustomer = () => {
        if (customerName === "") {
            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        LoadCurrencies();
    }, []);

    useEffect(() => {
        LoadCustomers();
        document.title = 'Clients';
    }, [currencyId, search, accountSearch, invoiceNumber]);

    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 4) {
            setCustomerSuffix(value);
            setError(''); // Clear any previous error message
        } else {
            setError('Client Suffix Code cannot exceed 4 characters.');
        }
    };


    //Customer Address Section 
    const [editingCustomerAddressId, setEditingCustomerAddressId] = useState(0)
    const [customerAddress, setCustomerAddress] = useState({})
    // const [customerAddresses, setCustomerAddresses] = useState([])

    const IsValidAddress = () => {
        if (customerAddress.country === "" || customerAddress.address === "" || customerAddress.conignee === "") {
            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }


    const handleEditAddress = (address) => {
        // Set the local customer address with the found address
        if (address) {
            setCustomerAddress(address);
            setShowAddressModal(true);
        }
    }
    const handleEditSuffix = (suffix) => {
        if (suffix) {
            setSelectedCustomer((prevState) => ({
                ...prevState,
                customerId: suffix.customerId,
            }));
            setClientSuffixInput(suffix.suffixCode);
            setCustomerSuffixId(suffix.customerSuffixId);
            setShowSuffixModal(true);
        }
    }

    const submitAddressForm = (e) => {
        e.preventDefault();
        if (IsValidAddress()) {
            const body = {
                CustomerAddressId: customerAddress.customerAddressId,
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!"),
                CustomerId: selectedCustomer.customerId,
                country: customerAddress.country,
                Address: customerAddress.address,
                Consignee: customerAddress.consignee,
                PhoneNumber: customerAddress.phoneNumber,
                DischargePort: customerAddress.dischargePort,
                finalPlaceOfDelivery: customerAddress.finalPlaceOfDelivery,
            }

            var config = {
                method: 'post',
                url: "Customer/EditAddCustomerAddress",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: body
            };

            axiosInstance(config)
                .then(function (response) {
                    LoadCustomers();
                    handleCloseAddressModal();
                    customerAddress.customerAddressId > 0 ?
                        toast.success('Address updated successfully!')
                        :
                        toast.success('Address added successfully!');
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    handleCloseAddressModal();
                });
        }
    }
    const submitSuffixForm = (clientSuffixInput) => {

        const body = {
            CustomerSuffixId: customerSuffixId,
            userId: localStorage.getItem("uTsReD"),
            companyId: localStorage.getItem("C0m8N!"),
            CustomerId: selectedCustomer.customerId,
            SuffixCode: clientSuffixInput
        }

        var config = {
            method: 'post',
            url: "Customer/EditAddCustomerSuffix",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                LoadCustomers();
                handleCloseSuffixModal();
                setClientSuffixInput();
                setCustomerSuffixId();
            })
            .catch(function (error) {
                toast.error(error.response.data);
                handleCloseSuffixModal();
            });
    }

    const DeleteAddress = (addressIdToDelete) => {
        var config = {
            method: 'post',
            url: "Customer/DeleteCustomerAddress",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'AddressId': addressIdToDelete,
                'UserId': localStorage.getItem("uTsReD")
            }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    LoadCustomers();
                    handleCloseDeleteCustomerAddress();
                    toast.success('Address deleted.');
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
                handleCloseDeleteCustomerAddress();
            });
    }

    const DeleteSuffix = (suffixIdToDelete) => {
        var config = {
            method: 'post',
            url: "Customer/DeleteCustomerSuffix",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'SuffixId': suffixIdToDelete,
                'UserId': localStorage.getItem("uTsReD"),
                'companyId': localStorage.getItem("C0m8N!"),
            }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    LoadCustomers();
                    handleCloseDeleteCustomerSuffix();
                    toast.success('Suffix deleted.');
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
                handleCloseDeleteCustomerSuffix();
            });
    }

    const columns = [
        {
            name: 'List of Clients',
            selector: row => (
                <div
                    onClick={() => {
                        setSelectedCustomerRow(row);
                        // setCustomerAddresses([]);
                        // GetCustomerAddresses(row.customerId);
                    }
                    }
                    className="w-full p-3 hover:bg-gray-100 transition duration-300"
                    style={{ cursor: 'pointer' }}
                >
                    <div className="w-full">
                        <strong className="text-wrap text-lg text-gray-800">{row.customerName}</strong>
                        {
                            ((row.country != null && row.country.length > 0) || (row.dischargePort != null && row.dischargePort.length > 0)) &&
                            <>
                                <br />
                                <span className="text-wrap" style={{ color: "#777777" }}>
                                    {row.country} - {row.dischargePort}
                                </span>
                            </>
                        }

                    </div>
                </div>
            ),
            conditionalCellStyles: [
                {
                    when: row => row.customerId === selectedCustomerRow.customerId,
                    style: {
                        backgroundColor: '#CFF4FC',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: '',
            selector: row => (
                <div className="align-items-center justify-content-end" style={{ cursor: 'pointer' }}>
                    <i className="ti-angle-right"></i>
                </div>
            ),
            conditionalCellStyles: [
                {
                    when: row => row.customerId === selectedCustomerRow.customerId,
                    style: {
                        backgroundColor: '#CFF4FC',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
            width: "50px",
        },
    ];

    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer', // Set cursor to pointer to indicate clickable rows
            },
        },
    };
    const [clientSuffixInput, setClientSuffixInput] = useState("");
    const [customerSuffixId, setCustomerSuffixId] = useState("");
    const handleSuffixChange = (e) => {
        const value = e.target.value;
        if (value.length <= 4) {
            setClientSuffixInput(value);
            setError(''); // Clear any previous error message
        } else {
            toast.error('Client Suffix Code cannot exceed 4 characters.');
        }
    };
    const handleSuffixSubmit = () => {
        if (clientSuffixInput.trim() === "") {
            toast.error("Client Suffix cannot be empty");
            return;
        }
        submitSuffixForm(clientSuffixInput);
    };

    return (
        <>
            {permissions.viewCustomer == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) :
                (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>Clients</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Clients</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>

                                        <select style={{ maxWidth: '150px' }} className="form-control" onChange={(e) => setCurrencyId(e.target.value)} required>
                                            <option value="0" key="0">-- Currency --</option>
                                            {currencies.length > 0 && (
                                                currencies.map((c, index) => {
                                                    return (<>
                                                        <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                                    </>)
                                                })
                                            )}
                                        </select>

                                        <input className='search-expand form-control ml-1' placeholder='Search by name, phone, and address' type="search" name="search" onChange={(e) => setSearch(e.target.value)} />
                                        <input className='search-expand form-control ml-1' placeholder='Search by account name' type="search" name="accountSearch" onChange={(e) => setAccountSearch(e.target.value)} />
                                        <input className='search-expand form-control ml-1' placeholder='Search by Invocie Number or Notes' type="search" name="invoiceNumber" onChange={(e) => setInvoiceNumber(e.target.value)} />
                                        {permissions.addCustomer === true &&
                                            <button className='btn btn-primary ml-1' onClick={() => {
                                                setShowCustomerModal(true);
                                            }}><FiPlus /> Add Client</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ height: '75vh' }}>
                                {/* Clients List */}
                                <div className="col-sm-5" style={{ height: '100%', overflowY: 'auto' }}>
                                    <DataTableExtensions
                                        columns={columns}
                                        data={customers}
                                        print={false}
                                        export={false}>
                                        <DataTable
                                            onRowClicked={(row) => setSelectedCustomerRow(row)}
                                            noHeader
                                            pagination
                                            paginationPerPage={50}
                                            paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                            highlightOnHover
                                            customStyles={customStyles}
                                        />
                                    </DataTableExtensions>
                                </div>
                                {
                                    selectedCustomerRow != null && selectedCustomerRow.customerId > 0 &&
                                    <div className="col-sm-7 mt-5" style={{ height: '100%', overflowY: 'auto' }}>
                                        {/* Accounts List */}
                                        <Row>
                                            <Col sm={12} className="flex">
                                                <Card>
                                                    <CardBody>
                                                        <table cellPadding={10}>
                                                            <tr>
                                                                <td>
                                                                    <div role="alert">
                                                                        <div className="row">
                                                                            <div className="col-10">
                                                                                <h5 class="alert-heading font-18"><i class="fas fa-info-circle" /> Info</h5>
                                                                            </div>
                                                                            <div className='col-2 text-right'>
                                                                                <div className='flex justify-end'>
                                                                                    {permissions.editCustomer === true &&
                                                                                        <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                                                                            setShowCustomerModal(true);
                                                                                            setCustomerName(selectedCustomerRow.customerName)
                                                                                            //setPhoneNumber(selectedCustomerRow.phone)
                                                                                            //setCustomerSuffix(selectedCustomerRow.suffix)
                                                                                            localStorage.setItem("Cl!8Nt", selectedCustomerRow.customerId);
                                                                                        }}><FiEdit /> Edit</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <table cellPadding={10}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div role="alert">
                                                                                                <div className="row">
                                                                                                    <div className="col-12">
                                                                                                        <p>
                                                                                                            Customer Name: <b>{selectedCustomerRow.customerName}</b>
                                                                                                            {/*<br />*/}
                                                                                                            {/*Suffix: <b>{selectedCustomerRow.suffix}</b>*/}
                                                                                                            {/* <br /> */}
                                                                                                            {/* Port: <b>{selectedCustomerRow.dischargePort}</b> */}
                                                                                                            {/*<br />*/}
                                                                                                            {/*Phone: <b>{selectedCustomerRow.phone}</b>*/}
                                                                                                            {/* <br />
                                                                                                    Address: <b>{selectedCustomerRow.address}</b> */}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <Row>
                                                                                <Tabs defaultActiveKey="products" className="ms-2">
                                                                                    <Tab
                                                                                        eventKey="products"
                                                                                        title={
                                                                                            <h5 class="alert-heading font-18"><i class="mdi mdi-badge-account-horizontal" /> Accounts</h5>
                                                                                        }>
                                                                                        <div className="mt-3">
                                                                                            {
                                                                                                selectedCustomerRow != null && selectedCustomerRow.accounts != null && selectedCustomerRow.accounts.map((account) => {
                                                                                                    return (<>
                                                                                                        <div class="col-12 mt-1 alert alert-info fade show" role="alert">
                                                                                                            <div className="row">
                                                                                                                <div className="col-8">
                                                                                                                    <h5 class="alert-heading font-18">{account.customerAccount1}</h5>
                                                                                                                </div>
                                                                                                                <div className='col-4 text-right'>
                                                                                                                    <div className='flex justify-end'>
                                                                                                                        {permissions.viewCustomerOrders === true && (
                                                                                                                            <button className='btn btn-info  btn-sm'
                                                                                                                                onClick={() => {
                                                                                                                                    window.location = `/Accounting/Customer/${selectedCustomerRow.customerName}/${selectedCustomerRow.customerId}/Account/${account.customerAccount1}/${account.customerAccountId}/Orders`
                                                                                                                                }}
                                                                                                                            ><FiEye />{account.orderCount === 1 ? ` 1 Order` : ` ${account.orderCount} Orders`}</button>
                                                                                                                        )}
                                                                                                                        {permissions.viewCustomerStatement === true && (
                                                                                                                            <button className='btn btn-success ms-1 btn-sm'
                                                                                                                                onClick={() => {
                                                                                                                                    window.location = "/Accounting/Customer/" + selectedCustomerRow.customerId + "/Account/" + account.customerAccountId + "/Statment";
                                                                                                                                }}
                                                                                                                            ><FiEye /> Statement</button>
                                                                                                                        )}
                                                                                                                        {permissions.viewCustomerStatement === true && (
                                                                                                                            <button className='btn btn-danger ms-1 btn-sm'
                                                                                                                                onClick={() => {
                                                                                                                                    window.location = "/Accounting/Customer/" + selectedCustomerRow.customerId + "/Account/" + account.customerAccountId + "/Duetable";
                                                                                                                                }}
                                                                                                                            ><FiEye /> Due Statement</button>
                                                                                                                        )}
                                                                                                                        {permissions.editCustomer === true && (
                                                                                                                            <button className='btn btn-warning ms-1 btn-sm'
                                                                                                                                onClick={() => {
                                                                                                                                    handleEditAccount(account.customerAccountId)
                                                                                                                                }}
                                                                                                                            ><FiEdit /> Edit</button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <span className="text-secondary">Currency: <b>{account.currency.currencySuffix}</b></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>)
                                                                                                })
                                                                                            }
                                                                                            {permissions.addCustomer === true && (
                                                                                                <button onClick={() => {
                                                                                                    handleAddAccount(selectedCustomerRow)
                                                                                                }} className="btn btn-primary mb-2 mt-3" style={{ width: '100%' }} ><b><FiPlus /> Create New Account</b></button>
                                                                                            )}
                                                                                        </div>
                                                                                    </Tab>
                                                                                    <Tab
                                                                                        eventKey="addresses"
                                                                                        title={
                                                                                            <h5 class="alert-heading font-18"><i class="fas fa-location-arrow" /> Addresses</h5>
                                                                                        }>
                                                                                        <div className="mt-3">
                                                                                            {
                                                                                                selectedCustomerRow.addresses != null && selectedCustomerRow.addresses.length > 0 && selectedCustomerRow.addresses.map((a) => {
                                                                                                    return (<>
                                                                                                        <div class="col-12 mt-1 alert alert-info fade show" role="alert">
                                                                                                            <div className="row">
                                                                                                                <div className="col-8">
                                                                                                                    <h5 class="alert-heading font-18">{a.consignee}</h5>
                                                                                                                </div>
                                                                                                                <div className='col-4 text-right'>
                                                                                                                    <div className='flex justify-end'>
                                                                                                                        {permissions.editCustomer === true && (
                                                                                                                            <button className='btn btn-warning ms-1 btn-sm'
                                                                                                                                onClick={() => {
                                                                                                                                    handleEditAddress(a);
                                                                                                                                }}
                                                                                                                            ><FiEdit /> Edit</button>
                                                                                                                        )}
                                                                                                                        {permissions.deleteCustomer === true && (
                                                                                                                            <button className="btn btn-danger btn-sm ms-1" onClick={() => {
                                                                                                                                setDeletingAddressId(a.customerAddressId)
                                                                                                                                handleShowDeleteCustomerAddress();
                                                                                                                            }}>
                                                                                                                                <FiTrash2 /> Delete</button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <span className="text-secondary">Address: <b>{a.address}</b></span>
                                                                                                                <span className="text-secondary">Country: <b>{a.country}</b></span>
                                                                                                                <span className="text-secondary">Discharge Port: <b>{a.dischargePort}</b></span>
                                                                                                                <span className="text-secondary">Final POD: <b>{a.finalPlaceOfDelivery}</b></span>
                                                                                                                <span className="text-secondary">Phone Number: <b>{a.phoneNumber}</b></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>)
                                                                                                })
                                                                                            }
                                                                                            {permissions.addCustomer === true && (
                                                                                                <button onClick={() => {
                                                                                                    handleAddAddress(selectedCustomerRow)
                                                                                                }} className="btn btn-primary mb-2 mt-3" style={{ width: '100%' }} ><b><FiPlus /> Create New Address</b></button>
                                                                                            )}
                                                                                        </div>
                                                                                    </Tab>
                                                                                    <Tab
                                                                                        eventKey="Suffixes"
                                                                                        title={
                                                                                            <h5 class="alert-heading font-18"><i class="ion ion-md-barcode" /> Suffixes</h5>
                                                                                        }>
                                                                                        <div className="mt-3">
                                                                                            {
                                                                                                selectedCustomerRow.customerSuffix != null && selectedCustomerRow.customerSuffix.length > 0 && selectedCustomerRow.customerSuffix.map((a) => {
                                                                                                    return (<>
                                                                                                        <div class="col-12 mt-1 alert alert-info fade show" role="alert">
                                                                                                            <div className="row">
                                                                                                                <div className="col-8">
                                                                                                                    <h5 class="alert-heading font-18">{a.suffixCode}</h5>
                                                                                                                </div>
                                                                                                                <div className='col-4 text-right'>
                                                                                                                    <div className='flex justify-end'>
                                                                                                                        {permissions.editCustomer === true && (
                                                                                                                            <button className='btn btn-warning ms-1 btn-sm'
                                                                                                                                onClick={() => {
                                                                                                                                    handleEditSuffix(a);
                                                                                                                                }}
                                                                                                                            ><FiEdit /> Edit</button>
                                                                                                                        )}
                                                                                                                        {permissions.deleteCustomer === true && (
                                                                                                                            <button className="btn btn-danger btn-sm ms-1" onClick={() => {
                                                                                                                                setDeletingSuffixId(a.customerSuffixId)
                                                                                                                                handleShowDeleteCustomerSuffix();
                                                                                                                            }}>
                                                                                                                                <FiTrash2 /> Delete</button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>)
                                                                                                })
                                                                                            }
                                                                                            {permissions.addCustomer === true && (
                                                                                                <button onClick={() => {
                                                                                                    handleAddSuffix(selectedCustomerRow)
                                                                                                }} className="btn btn-primary mb-2 mt-3" style={{ width: '100%' }} ><b><FiPlus /> Create New Suffix</b></button>
                                                                                            )}
                                                                                        </div>
                                                                                    </Tab>
                                                                                </Tabs>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </>)
            }
            <Modal show={showCustomerModal} onHide={handleCloseCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Client Name</label>
                            <input className="form-control"
                                type="text"
                                value={customerName}
                                placeholder="Enter client name"
                                onChange={(e) => setCustomerName(e.target.value)} required />
                        </div>
                        {/*<div className="col-md-12">*/}
                        {/*    <label className="col-md-6 col-form-label">Phone Number</label>*/}
                        {/*    <input className="form-control"*/}
                        {/*        type="text"*/}
                        {/*        placeholder="Enter phone number"*/}
                        {/*        value={phoneNumber}*/}
                        {/*        onChange={(e) => setPhoneNumber(e.target.value)} required />*/}
                        {/*</div>*/}
                        {/*<div className="col-md-12">*/}
                        {/*    <label className="col-md-6 col-form-label">Client Suffix Code</label>*/}
                        {/*    <input className="form-control"*/}
                        {/*        type="text"*/}
                        {/*        placeholder="Enter Client Suffix Code"*/}
                        {/*        value={customerSuffix}*/}
                        {/*        onChange={handleInputChange}*/}
                        {/*        maxLength={4}*/}
                        {/*        required />*/}
                        {/*</div>*/}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCustomerModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => submitCustomerForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAccountModal} onHide={handleCloseAccountModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Account Name</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter account name"
                                value={customerAccount.accountName} onChange={(e) => {
                                    setCustomerAccount((prevState) => ({
                                        ...prevState,
                                        accountName: e.target.value,
                                    }));
                                }} required />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Currency</label>
                            <select className="form-control" value={customerAccount.currencyId} onChange={(e) => {
                                setCustomerAccount((prevState) => ({
                                    ...prevState,
                                    currencyId: e.target.value,
                                }));
                            }} required disabled={editingAccount}>
                                <option value="0" key="0">-- Select Currency --</option>
                                {currencies.length > 0 && (
                                    currencies.map((c, index) => {
                                        return (<>
                                            <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                        </>)
                                    })
                                )}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAccountModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => submitAddCustomerAccountForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showAddressModal} onHide={handleCloseAddressModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Address for {selectedCustomer.customerName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Consignee</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter Consignee name"
                                value={customerAddress.consignee} onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        consignee: e.target.value,
                                    }));
                                }} required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="col-md-6 col-form-label">Phone Number</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter Phone Number"
                                value={customerAddress.phoneNumber} onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        phoneNumber: e.target.value,
                                    }));
                                }} required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="col-md-6 col-form-label">Country</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter Country"
                                value={customerAddress.country} onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        country: e.target.value,
                                    }));
                                }} required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="col-md-6 col-form-label">Discharge port</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter Discharge port (Optional)"
                                value={customerAddress.dischargePort} onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        dischargePort: e.target.value,
                                    }));
                                }} required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="col-md-6 col-form-label">Final place of delivery</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter Final place of delivery (Optional)"
                                value={customerAddress.finalPlaceOfDelivery} onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        finalPlaceOfDelivery: e.target.value,
                                    }));
                                }} required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label className="col-md-12 col-form-label">Address</label>
                            {/* <input className="form-control"
                                type="text"
                                placeholder="Enter Address details"
                                value={customerAddress.address} onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        address: e.target.value,
                                    }));
                                }} required /> */}
                            <textarea className="form-control"
                                placeholder="Enter Address details"
                                value={customerAddress.address}
                                onChange={(e) => {
                                    setCustomerAddress((prevState) => ({
                                        ...prevState,
                                        address: e.target.value,
                                    }));
                                }}></textarea>
                        </div>
                        {/* <div className="container mt-4">
                            <h5 className="mb-4">Customer Addresses</h5>
                            <hr />
                            <div className="row font-weight-bold">
                                <div className="col-md-5">Address</div>
                                <div className="col-md-3">Consignee</div>
                                <div className="col-md-3">Country</div>
                                <div className="col-md-1">Options</div>
                            </div>
                            {customerAddresses.map((customerAddress, index) => (
                                <div key={customerAddress.customerAddressId} className="row mt-3 p-2 border rounded bg-light">
                                    <div className="col-md-5">{customerAddress.address}</div>
                                    <div className="col-md-3">{customerAddress.consignee}</div>
                                    <div className="col-md-3">{customerAddress.country}</div>
                                    <div className="col-md-1 d-flex justify-content-center">
                                        <button className="btn btn-outline-warning btn-sm mr-2" onClick={() => EditAddress(customerAddress.customerAddressId)}>
                                            <FiEdit />
                                        </button>
                                        <button className="btn btn-outline-danger btn-sm" onClick={() => DeleteAddress(customerAddress.customerAddressId)}>
                                            <FiTrash2 />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddressModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => submitAddressForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showSuffixModal} onHide={handleCloseSuffixModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Suffix for {customerSuffix.consignee}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Suffix</label>
                            <input className="form-control"
                                type="text"
                                placeholder="Enter Suffix code"
                                value={clientSuffixInput}
                                onChange={handleSuffixChange}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSuffixModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSuffixSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteCustomerAddress} onHide={handleCloseDeleteCustomerAddress}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this address?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCustomerAddress}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteAddress(deletingAddressId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteCustomerSuffix} onHide={handleCloseDeleteCustomerSuffix}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Suffix</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this suffix?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCustomerSuffix}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteSuffix(deletingSuffixId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default Customers