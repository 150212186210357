import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../Variables/Global";
import { FiTrash2, FiPlusSquare, FiPlus, FiEdit } from "react-icons/fi";
import Select from "react-select";
import { formatDate } from "../../../../Classes/Util";
import { getDecodedToken } from '../../../../utils/utils';
import axiosInstance from '../../../../api/axiosConfig';

const ListBankStatments = () => {
    const pId = useParams();

    const [bankStatment, setBankStatment] = useState([])
    const columns = [
        {
            name: 'Date',
            selector: row => row.statmentDate.split("T")[0],
            width: "120px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Debit',
            selector: row => row.debit == 0 ? "" : (row.debit.toLocaleString() + " " + currency.currencySuffix),
            width: "130px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Credit',
            selector: row => row.credit == 0 ? "" : (row.credit.toLocaleString() + " " + currency.currencySuffix),
            width: "130px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Balance',
            selector: row => row.balance == 0 ? "" : (row.balance.toLocaleString() + " " + currency.currencySuffix),
            width: "150px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Description',
            width: "500px",
            selector: row => (<>
                <ul>
                    <li>{row.description}</li>
                    {row.description1 != "" && row.description1 != null ? <li>{row.description1}</li> : ""}
                    {row.description2 != "" && row.description2 != null ? <li>{row.description2}</li> : ""}
                    {row.exchangeRate == null || row.exchangeRate == '' || row.exchangeRate === 1.0 || row.exchangeRate === 0.0 ? '' : <li>Rate: {row.exchangeRate}</li>}
                </ul>
            </>),
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Notes',
            width: "230px",
            // selector: row => row.note,
            selector: row => (<span style={{ whiteSpace: 'pre-wrap' }}>
                {row.note}
            </span>),
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
        },
        {
            name: 'Options',
            width: "120px",
            selector: row => (
                <>
                    {permissions.addPayments === true && row.forwarderId === null && row.debit === 0 && row.description !== "Withdrawal" &&
                        <button type="button" className="btn btn-outline-warning mr-3" onClick={() => {
                            if (row.bankChargesTypeId != null)
                                EditBankChargesPayments(row.bankStatmentId);
                            else
                                EditBankPayments(row.bankStatmentId);
                        }}>
                            <FiEdit />
                        </button>
                    }

                    {permissions.addPayments === true &&
                        <button type="button" disabled={submitDisabled} className="btn btn-outline-danger"
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this payment?')) {
                                    deleteBankPayments(row.bankStatmentId);
                                }
                            }}>
                            <FiTrash2 />
                        </button>}
                </>
            ),
            conditionalCellStyles: [
                {
                    when: row => row.debit !== 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit !== 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },

    ];

    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliers/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetBankChargesTypes = () => {
        var config = {
            method: 'get',
            url: "BankChargesTypes/GetBankChargesTypes",
            headers: {},
            params: {
                'CompanyId': localStorage.getItem("C0m8N!")
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setBankChargesTypes(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleSupplierChange = (supplier) => {
        GetSupplierAccountstBySupplierId(supplier.value);
        // setBankPayment((prevState) => ({ ...prevState, supplierId: supplierId }))
        setBankPayment((prevState) => ({
            ...prevState,
            supplierId: supplier.value,
            supplierName: supplier.label,
            supplierAccountId: 0,
            date: formatDate(new Date()),
            notes: "",
        }))
    }

    const [sameCurrency, setSameCurrency] = useState(true)
    const [supplierAccounts, setSupplierAccounts] = useState([])
    const GetSupplierAccountstBySupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccounts/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setSupplierAccounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSupplierAccountChange = (supplierAccount) => {
        GetProformasBySupplierAccountId(supplierAccount.supplierAccountId);
        setBankPayment((prevState) => ({
            ...prevState,
            supplierAccountId: supplierAccount.supplierAccountId,
            supplierAccountName: supplierAccount.supplierAccount1,
            // supplierId: supplierAccount.supplierId,
            currencyId: supplierAccount.currencyId,
            currencyName: supplierAccount.currencyName,
            currencySuffix: supplierAccount.currencySuffix,
            // companyId: supplierAccount.companyId
        }));
        // invoices = [];
        // const list = [];
        invoices.splice(0, invoices.length)
        handleInvoiceAdd();
        setSameCurrency(currency.currencyId == supplierAccount.currencyId);
        recalculateToralBankAmount(invoices, bankPayment.exchangeRate);
    }

    const [proformas, setProformas] = useState([])
    const GetProformasBySupplierAccountId = (supplierAccountId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrders/" + supplierAccountId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setProformas(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleProformaChange = (proformaId, editingInvoice, index) => {
        editingInvoice.commercialInvoiceId = 0
        editingInvoice.commercialInvoice = ""

        GetCommercialInvoicesByProformaId(proformaId, editingInvoice, index);
    }

    // const [proforma, setProformas] = useState([])
    const GetCommercialInvoicesByProformaId = (orderId, editingInvoice, index) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoices/" + orderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                // setProformas(response.data);
                editingInvoice.commercialInvoices = response.data
                handleInvoiceUpdate(editingInvoice, index);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetSupplierCommercialOrderTotalInfo = (orderId, commercialId, editingInvoice, index) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommerialOrderTotalInfo/" + commercialId + "/Order/" + orderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                // setProformas(response.data);
                editingInvoice.totalToPay = response.data.remainingTotal;
                handleInvoiceUpdate(editingInvoice, index);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleAmountClicked = (editingInvoice, index) => {
        if (editingInvoice.totalToPay != null) {
            editingInvoice.amount = editingInvoice.totalToPay;
            calculatePercentage(editingInvoice, index)
            // handleInvoiceUpdate(editingInvoice, index);
        }
    }
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const addMultiplePayments = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "BankAccountStatement/AddMultipleSupplierInvoicePayment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: {
                bankStatmentId: supplierPayment.bankStatmentId,
                payments: invoices,
                bankPayment: bankPayment
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully - " + response.data)
                    setShowMultipleInvoicesPayment(false)
                    setBankPayment((prevState) => ({
                        ...prevState,
                        supplierId: 0,
                        supplierAccountId: 0,
                        supplierName: "",
                        supplierAccountName: "",
                        bankAmount: 0.0,
                        originalAmount: 0.0,
                        exchangeRate: 1.0,
                        date: formatDate(new Date()),
                        notes: ""
                    }));
                    setInvoices([]);

                    setTotalBankAmount({
                        amount: 0.0,
                        originalAmount: 0.0
                    });
                    setBankPayment((prevState) => ({
                        ...prevState,
                        bankAmount: 0.0,
                        originalAmount: 0.0
                    }));
                    GetBankStatment();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setSubmitDisabled(false);
                toast.error("Something Went Wrong")
            });
    }

    const [supplierPayment, setSupplierPayment] = useState({});

    const deleteBankPayments = (bankStatmentId) => {
        setSubmitDisabled(true);
        var config = {
            method: 'Delete',
            url: "BankAccountStatement/DeleteSupplierInvoicePayment/" + bankStatmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Records deleted successfully.")
                    GetBankStatment();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setSubmitDisabled(false);
                toast.error("Something Went Wrong")
            });
    }

    const EditBankChargesPayments = (bankStatmentId) => {
        var config = {
            method: 'Get',
            url: "BankAccountStatement/GetBankChargesPayment/" + bankStatmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {

                    setBankCharges({
                        bankStatmentId: response.data.data.bankStatmentId,
                        bankChargesTypeId: response.data.data.bankChargesTypeId,
                        bankChargesTypeName: response.data.data.description,
                        amount: response.data.data.credit,
                        date: response.data.data.statmentDate,
                        notes: response.data.data.note,
                        companyId: localStorage.getItem("C0m8N!"),
                        userId: localStorage.getItem("uTsReD"),
                        bankAccountId: pId.BankAccountId
                    })
                    handleAddBankCharges()
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const EditBankPayments = (bankStatmentId) => {
        var config = {
            method: 'Get',
            url: "BankAccountStatement/GetSupplierInvoicePayment/" + bankStatmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    const newInvoices = [];

                    // Process commercial payments
                    response.data.commercialPayments.forEach(payment => {
                        payment.supplierAccountDetails.forEach(detail => {
                            newInvoices.push({
                                commercialInvoicePaymentId: payment.commercialInvoicePaymentId,
                                supplierAccountDetails: detail.supplierAccountDetails,
                                proformaId: detail.proformaId,
                                commercialInvoiceId: detail.commercialInvoiceId,
                                proforma: detail.performa,
                                commercialInvoice: detail.commercialInvoice,
                                currencyId: 0,
                                currency: "",
                                amount: detail.credit,
                                userId: localStorage.getItem("uTsReD"),
                                notes: detail.notes,
                                commercialInvoices: []
                            });
                        });
                    });

                    // Process advanced payments
                    response.data.advancedPayments.forEach(payment => {
                        payment.supplierAccountDetails.forEach(detail => {
                            newInvoices.push({
                                supplierAdvancedPaymentId: payment.supplierAdvancedPaymentId,
                                supplierAccountDetails: detail.supplierAccountDetails,
                                proformaId: detail.proformaId,
                                commercialInvoiceId: detail.commercialInvoiceId,
                                proforma: detail.performa,
                                commercialInvoice: detail.commercialInvoice,
                                currencyId: 0,
                                currency: "",
                                amount: detail.credit,
                                userId: localStorage.getItem("uTsReD"),
                                notes: detail.notes,
                                commercialInvoices: []
                            });
                        });
                    });

                    setSupplierPayment({
                        bankStatmentId: response.data.bankStatmentId,
                        supplierId: response.data.supplierId,
                        supplierAccountId: response.data.supplierAccountId,
                        supplierName: response.data.supplierName,
                        supplierAccountName: response.data.supplierAccountName,
                    })
                    
                    setBankPayment((prevState) => ({
                        ...prevState,
                        supplierAccountId: response.data.supplierAccounts.supplierAccountId,
                        supplierAccountName: response.data.supplierAccounts.supplierAccountName,
                        currencyId: response.data.supplierAccounts.currencyId,
                        currencyName: response.data.supplierAccounts.currencyName,
                        currencySuffix: response.data.supplierAccounts.currencySuffix,
                    }));

                    setInvoices(newInvoices);
                    GetProformasBySupplierAccountId(response.data.supplierAccountId)
                    setShowMultipleInvoicesPayment(true)

                    console.log(invoices)
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }


    const editMultiplePayments = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "BankAccountStatement/AddMultipleSupplierInvoicePayment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: {
                payments: invoices,
                bankPayment: bankPayment
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully - " + response.data)
                    setShowMultipleInvoicesPayment(false)
                    setBankPayment((prevState) => ({
                        ...prevState,
                        supplierId: 0,
                        supplierAccountId: 0,
                        supplierName: "",
                        supplierAccountName: "",
                        bankAmount: 0.0,
                        originalAmount: 0.0,
                        exchangeRate: 1.0,
                        date: formatDate(new Date()),
                        notes: ""
                    }));
                    setInvoices([]);

                    setTotalBankAmount({
                        amount: 0.0,
                        originalAmount: 0.0
                    });
                    setBankPayment((prevState) => ({
                        ...prevState,
                        bankAmount: 0.0,
                        originalAmount: 0.0
                    }));

                    GetBankStatment();
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }


    const [invoices, setInvoices] = useState([])
    const handleInvoiceAdd = () => {
        setInvoices([
            ...invoices,
            {
                supplierAdvancedPaymentId: 0,
                supplierAccountDetails: 0,
                commercialInvoicePaymentId: 0,
                proformaId: 0,
                commercialInvoiceId: 0,
                proforma: "",
                commercialInvoice: "",
                currencyId: 0,
                currency: "",
                amount: 0.0,
                userId: localStorage.getItem("uTsReD"),
                // exchangeRate: 1.0,
                // originalAmount: 0.0,
                // bankAmount: 0.0,
                notes: "",
                commercialInvoices: []
            }
        ])
    }
    const [totalBankAmount, setTotalBankAmount] = useState({
        amount: 0.0
    });
    const handleInvoiceUpdate = (invoice, index) => {
        const list = [...invoices];
        list[index] = invoice;
        setInvoices(list);
        recalculateToralBankAmount(invoices, bankPayment.exchangeRate);
    };

    const calculatePercentage = (editingInvoice, index) => {
        editingInvoice.percentage = (editingInvoice.amount * 100) / editingInvoice.totalToPay;
        handleInvoiceUpdate(editingInvoice, index);
    };

    const calculateAmount = (editingInvoice, index) => {
        editingInvoice.amount = (editingInvoice.totalToPay * editingInvoice.percentage) / 100;
        handleInvoiceUpdate(editingInvoice, index);
    };

    const recalculateToralBankAmount = (invoices, rate) => {
        var total = 0.0;
        var original = 0.0;
        invoices.forEach(invoice => {
            if (invoice.proformaId > 0) {
                total = total + (invoice.amount * rate);
                original = original + invoice.amount * 1.0;
            }
        });
        // console.log('total bank amount: %f', total);
        setTotalBankAmount({
            amount: total,
            originalAmount: original
        });
        setBankPayment((prevState) => ({
            ...prevState,
            bankAmount: total,
            originalAmount: original
        }));
    }

    const deleteInvoice = (index) => {
        let list = [...invoices];
        list.splice(index, 1);
        setInvoices(list);
        recalculateToralBankAmount(list, bankPayment.exchangeRate);
    }

    const GetBankStatment = () => {
        var config = {
            method: 'get',
            url: 'BankAccountStatement/GetBankAccountStatment/' + pId.BankAccountId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBankStatment(response.data.data);

                console.log(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const [bank, setBank] = useState("")
    const GetBank = (bankId) => {
        var config = {
            method: 'get',
            url: 'Bank/GetCompanyBank/' + bankId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBank(response.data);
                setCashDeposit((prevState) => ({
                    ...prevState,
                    bankId: response.data.bankId
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [bankPayment, setBankPayment] = useState({
        bankId: pId.BankId,
        bankAccountId: pId.BankAccountId,
        supplierId: 0,
        supplierAccountId: 0,
        supplierName: "",
        supplierAccountName: "",
        bankAmount: 0.0,
        originalAmount: 0.0,
        exchangeRate: 1.0,
        userId: localStorage.getItem("uTsReD"),
        date: formatDate(new Date()),
        notes: ""
    })

    const [bankAccountStatmentDetails, setBankAccountStatmentDetails] = useState("")
    const GetPaymentForMultipleInvoices = (BankStatementId) => {
        var config = {
            method: 'get',
            url: 'BankAccountStatement/GetBankAccountStatmentDetails/' + BankStatementId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBankAccountStatmentDetails(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: 'Currency/GetCurrency/' + currencyId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [show, setShow] = useState(false)
    const handleClose = () => { setShow(false) }
    const handleCashDeposit = () => {
        setShow(true)
    }
    const [showWithdrawal, setShowWithdrawal] = useState(false)
    const handleCloseWithdrawal = () => { setShowWithdrawal(false) }
    const handleWithdrawal = () => {
        setShowWithdrawal(true)
    }

    const [bankChargesTypes, setBankChargesTypes] = useState([]);

    const [showAddBankCharges, setShowAddBankCharges] = useState(false);
    const handleCloseAddBankCharges = () => { setShowAddBankCharges(false) }
    const handleAddBankCharges = () => {
        setShowAddBankCharges(true)
    }

    const [showMultipleInvoicesPayment, setShowMultipleInvoicesPayment] = useState(false)
    const handleCloseMultipleInvoicesPayment = () => { setShowMultipleInvoicesPayment(false) }
    const handleMultipleInvoicesPayment = () => {
        setShowMultipleInvoicesPayment(true)
    }

    const [showEditMultipleInvoicesPayment, setShowEditMultipleInvoicesPayment] = useState(false)
    const handleCloseEditMultipleInvoicesPayment = () => { setShowEditMultipleInvoicesPayment(false) }
    const handleEditMultipleInvoicesPayment = () => {
        setShowEditMultipleInvoicesPayment(true)
    }

    const [cashDeposit, setCashDeposit] = useState({
        cashDepositDate: formatDate(new Date()),
        cashDepositValue: "",
        cashDepositNotes: "",
        companyId: localStorage.getItem("C0m8N!"),
        bankAccountId: pId.BankAccountId,
        userId: localStorage.getItem("uTsReD")
    })
    const submitForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "CashDeposit/AddCashDeposit",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: cashDeposit
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Cash Deposit Added Successfully")
                    handleClose();
                    // AddCashDepositBankStatment();
                    setCashDeposit({})
                    GetBankStatment();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }

    const submitBankChargesForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "BankAccountStatement/AddBankCharges",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: bankCharges
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Bank Charges Added Successfully")
                    handleCloseAddBankCharges();
                    // AddCashDepositBankStatment();
                    // setCashDeposit({})
                    // GetBankStatment();
                    setBankStatment(response.data.data);
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }

    const [withdrawal, setWithdrawal] = useState({
        withdrawalDate: formatDate(new Date()),
        withdrawalAmount: "",
        withdrawalNotes: "",
        companyId: localStorage.getItem("C0m8N!"),
        userId: localStorage.getItem("uTsReD"),
        bankAccountId: pId.BankAccountId
    })

    const [bankCharges, setBankCharges] = useState({
        bankStatmentId:0,
        bankChargesTypeId: 0,
        bankChargesTypeName: '',
        date: formatDate(new Date()),
        amount: 0,
        notes: "",
        companyId: localStorage.getItem("C0m8N!"),
        userId: localStorage.getItem("uTsReD"),
        bankAccountId: pId.BankAccountId
    })

    const submitWithdrawalForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "Withdrawal/AddWithdrawal",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: withdrawal
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Withdrawal Added Successfully")
                    handleCloseWithdrawal();
                    AddWithdrawalBankStatment();
                    setWithdrawal({})
                    GetBankStatment();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }

    const [withdrawalStatment, setWithdrawalStatment] = useState({
        withdrawalDate: "",
        withdrawalAmount: "",
        withdrawalNotes: "",
        bankAccountId: pId.BankAccountId,
        companyId: localStorage.getItem("C0m8N!")
    })
    const AddWithdrawalBankStatment = () => {
        var config = {
            method: 'post',
            url: "BankAccountStatement/AddWithdrawal",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: withdrawalStatment
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetBankStatment();
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const [bankAccount, setBankAccount] = useState("")
    const GetBankAccount = () => {
        var config = {
            method: 'get',
            url: 'BankAccount/GetBankAccountCurrencyId/' + pId.BankAccountId,
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode == 200) {
                    document.title = response.data.data.bankAccount1;
                    setBankAccount(response.data.data);
                    GetCurrency(response.data.data.currencyId);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetBankStatment();
        GetBank(pId.BankId);
        GetBankAccount(pId.BankAccountId)
        setCashDeposit((prevState) => ({
            ...prevState,
            companyId: localStorage.getItem("C0m8N!")
        }))
        GetBankChargesTypes();
        GetSuppliers()
    }, [])

    return (
        <>
            {permissions.viewBankAccountStatement == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-8">
                                                    <h3>{bank.bankName + " - " + bankAccount.bankAccount1} Statement of Account</h3>
                                                </div>
                                                <div className="col-4 text-right">
                                                    {permissions.addPayments === true && (
                                                        <button
                                                            className="waves-effect waves-light btn btn-sm btn-info mr-3"
                                                            onClick={() => {
                                                                handleMultipleInvoicesPayment();
                                                                setSupplierPayment({
                                                                    bankStatmentId: 0,
                                                                    supplierId: null,
                                                                    supplierAccountId: null,
                                                                    supplierName: null,
                                                                    supplierAccountName: null,
                                                                });
                                                                setInvoices([]);
                                                            }}
                                                        >
                                                            Multiple Invoices Payment
                                                        </button>
                                                    )}


                                                    {permissions.addWithdrawal == true && (
                                                        <button className="btn btn-sm btn-warning mr-3"
                                                            onClick={() => {
                                                                setBankCharges({
                                                                    bankStatmentId:0,
                                                                    bankChargesTypeId: 0,
                                                                    bankChargesTypeName: '',
                                                                    date: formatDate(new Date()),
                                                                    amount: 0,
                                                                    notes: "",
                                                                    companyId: localStorage.getItem("C0m8N!"),
                                                                    userId: localStorage.getItem("uTsReD"),
                                                                    bankAccountId: pId.BankAccountId
                                                                });
                                                                handleAddBankCharges();

                                                            }}>Add Bank Charges</button>
                                                    )}

                                                    {permissions.addWithdrawal == true && (
                                                        <button className="btn btn-sm btn-danger mr-3" onClick={() => handleWithdrawal()}>Withdraw Money</button>
                                                    )}

                                                    {permissions.addDeposit == true && (
                                                        <button className="btn btn-sm btn-primary" onClick={() => handleCashDeposit()}>Deposit Money</button>
                                                    )}
                                                </div>
                                                <hr></hr>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <DataTable columns={columns} data={bankStatment} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal show={show} onHide={() => handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Cash Deposit</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label>
                                        <input className="form-control" type="number" value={cashDeposit.cashDepositValue} id="txtPositionName"
                                            onChange={(e) => {
                                                setCashDeposit((prevState) =>
                                                ({
                                                    ...prevState,
                                                    cashDepositValue: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Date</label>
                                        <input className="form-control" type="date" value={cashDeposit.cashDepositDate}
                                            onChange={(e) => {
                                                setCashDeposit((prevState) =>
                                                ({
                                                    ...prevState,
                                                    cashDepositDate: e.target.value
                                                }))
                                            }
                                            } required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Notes</label>
                                        <textarea className="form-control" type="text" value={cashDeposit.cashDepositNote} id="txtPositionName"
                                            onChange={(e) => {
                                                setCashDeposit((prevState) =>
                                                ({
                                                    ...prevState,
                                                    cashDepositNotes: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleClose()}>
                                    Close
                                </Button>
                                <Button variant="primary" disabled={submitDisabled} onClick={(e) => submitForm(e)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showWithdrawal} onHide={() => handleCloseWithdrawal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Withdrawal</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label>
                                        <input className="form-control" type="number" value={withdrawal.withdrawalAmount} id="txtPositionName"
                                            onChange={(e) => {
                                                setWithdrawal((prevState) =>
                                                ({
                                                    ...prevState,
                                                    withdrawalAmount: e.target.value
                                                }));
                                                setWithdrawalStatment((prevState) => ({
                                                    ...prevState,
                                                    withdrawalAmount: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Date</label>
                                        <input className="form-control" type="date" value={withdrawal.withdrawalDate}
                                            onChange={(e) => {
                                                setWithdrawal((prevState) =>
                                                ({
                                                    ...prevState,
                                                    withdrawalDate: e.target.value
                                                }))
                                                setWithdrawalStatment((prevState) => ({
                                                    ...prevState,
                                                    withdrawalDate: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Notes</label>
                                        <textarea className="form-control" type="text" value={withdrawal.withdrawalNotes} id="txtPositionName"
                                            onChange={(e) => {
                                                setWithdrawal((prevState) =>
                                                ({
                                                    ...prevState,
                                                    withdrawalNotes: e.target.value
                                                }));
                                                setWithdrawalStatment((prevState) => ({
                                                    ...prevState,
                                                    withdrawalNotes: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleCloseWithdrawal()}>
                                    Close
                                </Button>
                                <Button variant="primary" disabled={submitDisabled} onClick={(e) => submitWithdrawalForm(e)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={showAddBankCharges} onHide={() => handleCloseAddBankCharges()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Bank Charges</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <label>Charges Type</label>
                                        <Select
                                            required
                                            placeholder="-- Select Bank Charges Type --"
                                            isSearchable={true}
                                            defaultValue={bankCharges.bankChargesTypeName ? { value: bankCharges.bankChargesTypeId, label: bankCharges.bankChargesTypeName } : null}
                                            options={bankChargesTypes.map(c => ({
                                                value: c,
                                                label: c.name
                                            }))}
                                            valuekey
                                            onChange={(e) => {
                                                // handleSupplierAccountChange(e.value);
                                                setBankCharges((prevState) =>
                                                ({
                                                    ...prevState,
                                                    bankChargesTypeId: e.value.bankChargesTypeId,
                                                    bankChargesTypeName: e.value.name,
                                                }));
                                            }}>
                                        </Select>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label>
                                        <input className="form-control"
                                            type="number"
                                            value={bankCharges.amount}
                                            id="txtPositionName"
                                            onChange={(e) => {
                                                setBankCharges((prevState) =>
                                                ({
                                                    ...prevState,
                                                    amount: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Date</label>
                                        <input className="form-control" type="date" value={bankCharges.date}
                                            onChange={(e) => {
                                                setBankCharges((prevState) =>
                                                ({
                                                    ...prevState,
                                                    date: e.target.value
                                                }))
                                            }
                                            } required />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Notes</label>
                                        <textarea className="form-control" type="text" value={bankCharges.notes} id="txtPositionName"
                                            onChange={(e) => {
                                                setBankCharges((prevState) =>
                                                ({
                                                    ...prevState,
                                                    notes: e.target.value
                                                }));
                                            }
                                            } required />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleCloseAddBankCharges()}>
                                    Close
                                </Button>
                                <Button variant="primary" disabled={submitDisabled} onClick={(e) => submitBankChargesForm(e)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal size="xl" show={showMultipleInvoicesPayment} onHide={() => handleCloseMultipleInvoicesPayment()}>
                            <Modal.Header closeButton>
                                    <Modal.Title>Add Payment For Multiple Invoices ( {bank.bankName + " - " + bankAccount.bankAccount1} )</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="col-md-6 mb-3">
                                        {/* <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Supplier</label> */}
                                        <label>Supplier</label>
                                        <Select
                                            required
                                            placeholder="-- Select Supplier --"
                                            isSearchable={true}
                                            defaultValue={supplierPayment.supplierName ? { value: supplierPayment.supplierId, label: supplierPayment.supplierName } : null}
                                            options={suppliers.map(supplier => ({
                                                value: supplier.supplierId,
                                                label: supplier.supplierName
                                            }))}
                                            onChange={(supplier) => {
                                                handleSupplierChange(supplier);
                                            }}
                                            isDisabled={supplierPayment.supplierId !== null && supplierPayment.supplierId !== ""}
                                        />

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Account</label>
                                        <Select
                                            required
                                            placeholder="-- Select Supplier Account --"
                                            isSearchable={true}
                                            defaultValue={supplierPayment.supplierAccountName ? { value: supplierPayment.supplierAccountId, label: supplierPayment.supplierAccountName } : null}
                                            options={supplierAccounts.map(account => ({
                                                value: account,
                                                label: account.supplierAccount1
                                            }))}
                                            onChange={(e) => {
                                                handleSupplierAccountChange(e.value);
                                            }}
                                            isDisabled={supplierPayment.supplierId !== null && supplierPayment.supplierId !== ''}
                                        />

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Date</label>
                                        <input className="form-control mb-3" type="date" value={bankPayment.date}
                                            onChange={(e) => {
                                                setBankPayment((prevState) => ({
                                                    ...prevState,
                                                    date: e.target.value
                                                }));
                                            }}>
                                        </input>
                                    </div>
                                    {sameCurrency == false && (
                                        <>
                                            <div className="col-6">
                                                <label>Exchange Rate</label>
                                                <input type="number" className="form-control mb-3" required
                                                    value={bankPayment.exchangeRate}
                                                    onChange={(e) => {
                                                        setBankPayment((prevState) => ({
                                                            ...prevState,
                                                            exchangeRate: e.target.value
                                                        }));
                                                        recalculateToralBankAmount(invoices, e.target.value);
                                                    }}>
                                                </input>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <h5 htmlFor="txtPositionName" className="col-md-11 mt-3">Invoices</h5>
                                    <hr />
                                    <div className='row'>
                                        <div className="col-md-2">
                                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Proforma</label>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Commercial Invoice</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Percentage</label>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Notes</label>
                                        </div>
                                    </div>
                                    {
                                        invoices.map((invoice, index) => (
                                            <div key={index}>
                                                <div className='row'>
                                                    <div className="col-md-2 mb-2 mt-3">
                                                        {/* <label>Proforma</label> */}
                                                        <Select
                                                            required
                                                            placeholder="Select"
                                                            isSearchable={true}
                                                            options={proformas.map(p => ({
                                                                value: p,
                                                                label: p.invoiceNumber
                                                            }))}
                                                            valuekey
                                                            value={invoice.proforma ? { value: invoice.proformaId, label: invoice.proforma } : null}
                                                            onChange={(e) => {
                                                                const selectedProforma = e.value;
                                                                setInvoices(prevInvoices => {
                                                                    const updatedInvoices = [...prevInvoices];
                                                                    updatedInvoices[index] = {
                                                                        ...updatedInvoices[index],
                                                                        proformaId: selectedProforma.supplierOrderId,
                                                                        proforma: selectedProforma.invoiceNumber,
                                                                        totalToPay: selectedProforma.balance,
                                                                        commercialInvoiceId: 0,
                                                                        commercialInvoice: "",
                                                                    };
                                                                    handleProformaChange(selectedProforma.supplierOrderId, updatedInvoices[index], index);
                                                                    return updatedInvoices;
                                                                });
                                                            }}
                                                            isDisabled={invoice.supplierAccountDetails !== null && invoice.supplierAccountDetails !== "" && invoice.supplierAccountDetails !== 0}
                                                        >
                                                        </Select>
                                                        {/* <label>{invoice.proforma} - {invoice.proformaId}</label> */}
                                                    </div>
                                                    <div className="col-md-2 mb-2 mt-3">
                                                        {/* <label>Commercial Invoice</label> */}
                                                        <Select
                                                            required
                                                            placeholder="Select"
                                                            isSearchable={true}
                                                            // value={invoice.commercialInvoice}
                                                            options={invoice.commercialInvoices.map(p => ({
                                                                value: p,
                                                                label: p.invoiceNumber
                                                            }))}
                                                            valuekey
                                                            value={invoice.commercialInvoice ? { value: invoice.commercialInvoiceId, label: invoice.commercialInvoice } : null}
                                                            onChange={(e) => {
                                                                const selectedCommercial = e.value;
                                                                setInvoices(prevInvoices => {
                                                                    const updatedInvoices = [...prevInvoices];
                                                                    updatedInvoices[index] = {
                                                                        ...updatedInvoices[index],
                                                                        commercialInvoiceId: selectedCommercial.commercialInvoiceId,
                                                                        commercialInvoice: selectedCommercial.invoiceNumber,
                                                                        totalToPay: selectedCommercial.credit,
                                                                    };
                                                                    handleInvoiceUpdate(updatedInvoices[index], index);
                                                                    GetSupplierCommercialOrderTotalInfo(updatedInvoices[index].proformaId, selectedCommercial.commercialInvoiceId, updatedInvoices[index], index);
                                                                    return updatedInvoices;
                                                                });
                                                            }}
                                                            isDisabled={invoice.supplierAccountDetails !== null && invoice.supplierAccountDetails !== "" && invoice.supplierAccountDetails !== 0}
                                                        >
                                                        </Select>
                                                        {/* <label>{invoice.commercialInvoice} - {invoice.commercialInvoiceId}</label> */}
                                                    </div>
                                                    <div className="col-md-3 mb-2">
                                                        {/* <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label> */}
                                                        <i>Total to pay: {invoice.totalToPay != null &&
                                                            <a href="#" onClick={() => handleAmountClicked(invoice, index)}>{invoice.totalToPay.toLocaleString()}</a>
                                                        }
                                                        </i>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">
                                                                {bankPayment.currencySuffix}
                                                                {/* {invoice.totalToPay != null && 
                                                                <a href="#" onClick={() => handleAmountClicked(invoice, index)}>{"\n" + invoice.totalToPay.toLocaleString()}</a>
                                                            } */}
                                                            </span>

                                                            <input type="number" className="form-control" required
                                                                value={invoice.amount}
                                                                onChange={(e) => {
                                                                    invoice.amount = e.target.value;
                                                                    calculatePercentage(invoice, index);
                                                                    // handleInvoiceUpdate(invoice, index);
                                                                    // reloadBankAmount(e.target.value)
                                                                }}>
                                                            </input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 mb-2 mt-3">
                                                        {/* <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label> */}
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">%</span>
                                                            <input type="number" className="form-control" required /*disabled={invoice.commercialInvoiceId > 0}*/
                                                                value={invoice.percentage}
                                                                onChange={(e) => {
                                                                    invoice.percentage = e.target.value;
                                                                    calculateAmount(invoice, index);
                                                                    // handleInvoiceUpdate(invoice, index);
                                                                    // reloadBankAmount(e.target.value)
                                                                }}>
                                                            </input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 mb-2 mt-3">
                                                        {/* <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label> */}
                                                        <div className="input-group mb-3">
                                                            <input className="form-control" required
                                                                value={invoice.notes}
                                                                onChange={(e) => {
                                                                    invoice.notes = e.target.value;
                                                                    handleInvoiceUpdate(invoice, index);
                                                                    // reloadBankAmount(e.target.value)
                                                                }}>
                                                            </input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 mb-2 mt-3">
                                                        <button className="btn btn-danger btn-md" onClick={() => {
                                                            deleteInvoice(index);
                                                        }}>
                                                            <FiTrash2 />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div >
                                        <button className="waves-effect waves-light btn btn-md btn-info mr-3" onClick={() => handleInvoiceAdd()}><FiPlus /> Add Invoice</button>
                                        <hr />
                                        <h5 >Bank Payment: <b>{currency.currencySuffix} {totalBankAmount.amount.toLocaleString()}</b></h5>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => handleCloseMultipleInvoicesPayment()}>
                                    Close
                                </Button>
                                <Button variant="primary" disabled={submitDisabled} onClick={(e) => addMultiplePayments(e)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>



                        {/* <Modal size="xl" show={showEditMultipleInvoicesPayment} onHide={() => handleCloseEditMultipleInvoicesPayment()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Payment For Multiple Invoices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className="col-md-6 mb-3">
                                <label>Supplier</label>
                                <label>{editingPaymentForMultipleInvoices.bankStatement.supplierName}</label>
                                
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Account</label>
                                <label>{editingPaymentForMultipleInvoices.bankStatement.supplierAccountName}</label>
                                
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Date</label>
                                <input className="form-control mb-3" type="date"  value={editingPaymentForMultipleInvoices.bankStatement.statmentDate}
                                    onChange={(e) => {
                                        setBankPayment((prevState) => ({
                                            ...prevState,
                                            date: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                             {editingPaymentForMultipleInvoices.bankStatement.exchangeRate !== 1.0 && (
                                <>
                                    <div className="col-6">
                                        <label>Exchange Rate</label>
                                        <input type="number" className="form-control mb-3" required 
                                            value={editingPaymentForMultipleInvoices.bankStatement.exchangeRate}
                                            onChange={(e) => {
                                                setBankPayment((prevState) => ({
                                                    ...prevState,
                                                    exchangeRate: e.target.value
                                                }));
                                                recalculateToralBankAmount(invoices, e.target.value);
                                            }}>
                                        </input>
                                    </div>
                                </>
                            )}
                        </div>
                            <div>
                                <h5 htmlFor="txtPositionName" className="col-md-11 mt-3">Invoices</h5>
                                <hr/>
                                    <div className='row'>
                                       <div className="col-md-3">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Proforma</label>
                                       </div>
                                       <div className="col-md-3">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Commercial Invoice</label>
                                       </div>
                                       <div className="col-md-2">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label>
                                       </div>
                                       <div className="col-md-3">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Notes</label>
                                       </div>
                                   </div>
                                    {
                                    editingPaymentForMultipleInvoices.supplierAdvancedPayments.map((invoice, index) => (
                                        <div key={index}>
                                             <div className='row'>
                                                 <div className="col-md-3 mb-2">
                                                    <label>{invoice.invoiceNumber}</label>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label>N/A</label>
                                                </div>
                                                <div className="col-md-2 mb-2">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">{editingPaymentForMultipleInvoices.bankStatement.currencySuffix}</span>
                                                        <input type="number" className="form-control" required
                                                            value={invoice.supplierAdvancedPayment1}
                                                            onChange={(e) => {
                                                                invoice.supplierAdvancedPayment1 = e.target.value;
                                                                handleInvoiceUpdate(invoice, index);
                                                                // reloadBankAmount(e.target.value)
                                                            }}>
                                                        </input>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <div className="input-group mb-3">
                                                        <input className="form-control" required
                                                            value={invoice.notes}
                                                            onChange={(e) => {
                                                                invoice.notes = e.target.value;
                                                                handleInvoiceUpdate(invoice, index);
                                                                // reloadBankAmount(e.target.value)
                                                            }}>
                                                        </input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    editingPaymentForMultipleInvoices.supplierCommercialPayments.map((invoice, index) => (
                                        <div key={index}>
                                             <div className='row'>
                                                 <div className="col-md-3 mb-2">
                                                    <label>{invoice.invoiceNumber}</label>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <label>{invoice.commercialInvoiceNumber}</label>
                                                </div>
                                                <div className="col-md-2 mb-2">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text">{editingPaymentForMultipleInvoices.bankStatement.currencySuffix}</span>
                                                        <input type="number" className="form-control" required
                                                            value={invoice.paymentValue}
                                                            onChange={(e) => {
                                                                invoice.paymentValue = e.target.value;
                                                                handleInvoiceUpdate(invoice, index);
                                                                // reloadBankAmount(e.target.value)
                                                            }}>
                                                        </input>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-2">
                                                    <div className="input-group mb-3">
                                                        <input className="form-control" required
                                                            value={invoice.notes}
                                                            onChange={(e) => {
                                                                invoice.notes = e.target.value;
                                                                handleInvoiceUpdate(invoice, index);
                                                                // reloadBankAmount(e.target.value)
                                                            }}>
                                                        </input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                              
                                <div >
                                    <button className="waves-effect waves-light btn btn-md btn-info mr-3" onClick={() => handleInvoiceAdd()}><FiPlus /> Add Invoice</button>
                                    <hr/>
                                    <h5 >Bank Payment: <b>{currency.currencySuffix} {editingPaymentForMultipleInvoices.bankStatement.credit.toLocaleString()}</b></h5>
                                </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleCloseEditMultipleInvoicesPayment()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={(e) => editMultiplePayments(e)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                    </div>
                </>)
            }
        </>
    )
}
export default ListBankStatments;