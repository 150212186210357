import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../Variables/Global";
import { AiOutlineFileExcel } from "react-icons/ai"
import { FiPlus } from 'react-icons/fi';
import { toast } from "react-toastify";
import Select from "react-select";
import { formatDate } from "../../../Classes/Util";
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const ForwarderAccountStatment = () => {
     
    const pId = useParams()
    const [accountStatment, setAccountStatment] = useState([])
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const columns = [
        {
            name: "Invoice",
            // selector: row => row.performa,
            selector: row => {
                return (
                    <>
                        <div className="text-wrap">
                            <span><b>#{row.forwarderInvoice}</b></span>
                            {
                                row.isCredit === true && <>
                                    <br />
                                    <span><i>(Credit)</i></span> 
                                </>
                            }
                        </div>

                    </>
                )
            },
            // width: "200px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        {
            name: "Date",
            selector: row => row.date != null ? row.date.split("T")[0] : row.createdDate.split("T")[0],
            // width: "120px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        {
            name: "Debit",
            selector: row => row.debit === null ? "" : row.debit.toLocaleString(),
            // width: "110px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        {
            name: "Credit",
            selector: row => row.credit === null ? "" : row.credit.toLocaleString(),
            // width: "110px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        {
            name: "Balance",
            selector: row => row.balance === null ? "" : row.balance.toLocaleString(),
            // width: "130px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        {
            name: "Supplier",
            selector: row => {
                return (
                    <>
                        <div className="text-wrap">
                            <span>{row.supplierName}</span>
                            <br />
                            <span><b>#{row.supplierCommercialInvoice}</b></span>
                        </div>
                    </>
                )
            },
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        // {
        //     name: "Notes",
        //     selector: row => row.notes
        // },
        {
            name: "Client",
            // selector: row => row.customerAccount,
            selector: row => {
                return (
                    <>
                        <div className="text-wrap">
                            <span>{row.customerName}</span>
                            <br />
                            <span><b>#{row.customerCommercialInvoice}</b></span>
                        </div>
                    </>
                )
            },
            // width: "140px",
            conditionalCellStyles: [
                {
                    when: row => row.debit != 0,
                    style: {
                        backgroundColor: '#fbdde2',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.credit != 0,
                    style: {
                        backgroundColor: '#def8e6',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                }
            ],
        },
        
    ]

    const [showAddPayment, setShowAddPayment] = useState(false)
    const handleCloseAddPayment = () => { setShowAddPayment(false) }
    const handleAddPayment = () => {
        setShowAddPayment(true)
    }

    const [bankPayment, setBankPayment] = useState({
        bankId: 0,
        bankAccountId: 0,
        forwarderId: 0,
        forwarderAccountId: 0,
        forwarderName: "",
        forwarderAccountName: "",
        bankAmount: 0.0,
        originalAmount: 0.0,
        exchangeRate: 1.0,
        userId: Number(localStorage.getItem("uTsReD")),
        date: formatDate(new Date()),
        notes: ""
    })

    const [sameCurrency, setSameCurrency] = useState(true)

    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [selectedInvoicesIds, setSelectedInvoicesIds] = useState([])

    const addInvoice = (invoice) => {
        var list = selectedInvoices;
        list.push({
            shippingCostId: invoice.invoiceId,
            invoiceNumber: invoice.invoiceNumber,
            amount: invoice.amount,
            userId: Number(localStorage.getItem("uTsReD")),
            companyId: Number(localStorage.getItem("C0m8N!")),
            notes: "",
        });
        setSelectedInvoices(list);

        var ids = selectedInvoicesIds;
        ids.push(invoice.invoiceId);

        recalculateTotalBankAmount(selectedInvoices, bankPayment.exchangeRate);
    }
    const [banks, setBanks] = useState([]);
    const GetBanks = (companyId) => {
        var config = {
            method: 'get',
            url: "Bank/GetCompanyBanks/" + companyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data)
                setBanks(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const deleteInvoice = (invoiceId, index) => {
        let list = [...selectedInvoices];
        var i = 0;
        list.forEach(element => {
            if(element.shippingCostId === invoiceId){
                list.splice(i, 1);
            }
            i++;
        });
        // list.splice(index, 1);
        setSelectedInvoices(list);

        // let idIndex = selectedInvoicesIds.indexOf(invoiceId);
        let listIds = [...selectedInvoicesIds];
         i = 0;
         listIds.forEach(element => {
            if(element === invoiceId){
                listIds.splice(i, 1);
            }
            i++;
        });
        // listIds.splice(idIndex, 1);
        setSelectedInvoicesIds(listIds);

        recalculateTotalBankAmount(list, bankPayment.exchangeRate);
    }

    const handleInvoiceUpdate = (invoice, index) => {
        if(index >= 0){
            const list = [...selectedInvoices];
            list[index] = {
                shippingCostId: invoice.invoiceId,
                invoiceNumber: invoice.invoiceNumber,
                amount: invoice.amount,
                userId: Number(localStorage.getItem("uTsReD")),
                companyId: Number(localStorage.getItem("C0m8N!")),
                notes: invoice.notes,
            };
            setSelectedInvoices(list);
            recalculateTotalBankAmount(selectedInvoices, bankPayment.exchangeRate);
        }
    };

    const recalculateTotalBankAmount = (list, rate) =>{
        var total = 0.0;
        var original = 0.0;
        // if(list != null && list.size > 0){
            list.forEach(f => {
                if(f.shippingCostId > 0){
                    total = total + (f.amount * rate);
                    original = original + f.amount * 1.0;
                }
            });
        // }
        // console.log('total bank amount: %f', total);
        setBankPayment((prevState) => ({
            ...prevState,
            bankAmount: total,
            originalAmount: original
        }));
    }

    const GetAccountStatment = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'ForwarderAccount/GetForwarderAccountStatement/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setAccountStatment(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [accountInfo, setAccountInfo] = useState("");
    const GetAccountInfo = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'ForwarderAccount/GetForwarderAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setAccountInfo(response.data);
                setBankPayment((prevState) => ({
                    ...prevState,
                    forwarderId: response.data.forwarderId,
                    forwarderAccountId: response.data.forwarderAccountId,
                    forwarderName: response.data.forwarderName,
                    forwarderAccountName: response.data.accountName
                }));
               
                document.title = response.data.forwarderName + ' - ' + response.data.accountName;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [bankAccounts, setBankAccounts] = useState([]);
    const GetBankAccounts = (bankId) => {
        var config = {
            method: 'get',
            url: "BankAccount/GetBankAccounts/" + bankId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
         
        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    setBankAccounts(response.data.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [invoices, setInvoices] = useState([]);
    const GetUnpaidInvoices = (accountId) => {
        var config = {
            method: 'get',
            url: 'ShippingCost/GetUnpaidShippingCosts?forwarderAccountId=' + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setInvoices(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const DownloadCSV = (accountId) => {
        toast.warning("Coming soon!");
        // var axios = require('axios');

        // const headers = { "Content-Type": "blob" };
        // var config = {
        //     method: 'post',
        //     url: 'ForwarderAccount/DownloadForwarderAccountCSV/' + accountId,
        //     responseType: "arraybuffer",
        //     headers,
        // };

        // axiosInstance(config)
        //     .then(function (response) {
        //         const outputFilename = `ForwarderAccountStatement` + `.xlsx`;
        //         const url = URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         link.setAttribute("download", outputFilename);
        //         document.body.appendChild(link);
        //         link.click();
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }


    const DownloadPDF = (accountId) => {
        toast.success("Coming soon!");
        // var axios = require('axios');

        // const headers = { "Content-Type": "blob" };
        // var config = {
        //     method: 'post',
        //     url: 'SupplierAccountStatement/DownloadSupplierAccountPDF/' + accountId,
        //     responseType: "arraybuffer",
        //     headers,
        // };

        // axiosInstance(config)
        //     .then(function (response) {
        //         const outputFilename = `SupplierAccountStatement` + `.pdf`;
        //         const url = URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement("a");
        //         link.href = url;
        //         link.setAttribute("download", outputFilename);
        //         document.body.appendChild(link);
        //         link.click();
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }

    const addInvoicesPayments = (e) => {
        setSubmitDisabled(true);
        e.preventDefault();
        var config = {
            method: 'post',
            url: "ShippingCostPayment/AddMultipleInvoicesPayment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: {
                payments: selectedInvoices,
                bankPayment: bankPayment
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully");
                    setShowAddPayment(false)
                    setBankPayment((prevState) => ({
                        ...prevState,
                        // forwarderId: 0,
                        // forwarderAccountId: 0,
                        // forwarderName: "",
                        // forwarderAccountName: "",
                        bankAmount: 0.0,
                        originalAmount: 0.0,
                        exchangeRate: 1.0,
                        date: formatDate(new Date()),
                        notes: ""
                    }));
                    setSelectedInvoices([]);
                    GetAccountStatment(pId.AccountId);
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setSubmitDisabled(false);
                toast.error("Something Went Wrong")
            });
    }

    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAccountStatment(pId.AccountId);
        GetAccountInfo(pId.AccountId);
        GetBanks(localStorage.getItem("C0m8N!"));
        GetUnpaidInvoices(pId.AccountId);
    }, [])


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Forwarders</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Forwarders">Forwarders</Link></li>
                                    {/* <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                                    <li className="breadcrumb-item active">{account.supplierAccount1}</li> */}
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row mb-3'>
                                        <div className='col-8 col-md-6'>
                                        {/* {supplierName} - */}
                                            <h4 className="card-title">{accountInfo.forwarderName} - {accountInfo.accountName} Statement of Account</h4>
                                        </div>
                                        <div className="col-4 col-md-6 text-right">
                                            {permissions.addFreightPayment === true &&
                                                <button className="waves-effect waves-light btn btn-primary btn-info mr-3" onClick={() => handleAddPayment()}>
                                                    <FiPlus /> Add Payment
                                                </button>
                                            }
                                            <button onClick={() => DownloadCSV(pId.AccountId)} className="btn btn-primary mr-3"><AiOutlineFileExcel /> Export CSV</button>
                                            <button onClick={() => DownloadPDF(pId.AccountId)} className="btn btn-primary"><AiOutlineFileExcel /> Export PDF</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <hr></hr>
                                        <DataTable
                                            columns={columns}
                                            data={accountStatment} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal size="xl" show={showAddPayment} onHide={() => handleCloseAddPayment()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className="col-md-6 mb-3">
                                {/* <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Supplier</label> */}
                                <label>Bank</label>
                                <Select
                                    required
                                    placeholder="-- Select Bank --"
                                    isSearchable={true}
                                    options={banks.map(c => ({
                                        value: c,
                                        label: c.bankName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setBankPayment((prevState) => ({
                                            ...prevState,
                                            bankId: e.value.bankId,
                                            bankAccountId: 0
                                        }));
                                        setBankAccounts([])
                                        GetBankAccounts(e.value.bankId);
                                    }}>
                                </Select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Bank Account</label>
                                <Select
                                    required
                                    placeholder="-- Select Bank Account --"
                                    isSearchable={true}
                                    options={bankAccounts.map(c => ({
                                        value: c,
                                        label: c.bankAccount1
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        // handleBankAccountChange(e.value);
                                        setBankPayment((prevState) => ({
                                            ...prevState,
                                            bankAccountId: e.value.bankAccountId,
                                            currencySuffix: e.value.currencySuffix,
                                        }));

                                        setSameCurrency(e.value.currencyId === accountInfo.currencyId);
                                        if(sameCurrency){
                                            setBankPayment((prevState) => ({ 
                                                ...prevState, 
                                                exchangeRate: 1.0,
                                            }))
                                        }
                                        recalculateTotalBankAmount(selectedInvoices, bankPayment.exchangeRate);
                                    }}>
                                </Select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Date</label>
                                <input className="form-control mb-3" type="date"  value={bankPayment.date}
                                    onChange={(e) => {
                                        setBankPayment((prevState) => ({
                                            ...prevState,
                                            date: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                             {sameCurrency === false && (
                                <>
                                    <div className="col-6">
                                        <label>Exchange Rate</label>
                                        <input type="number" className="form-control mb-3" required 
                                            value={bankPayment.exchangeRate}
                                            onChange={(e) => {
                                                setBankPayment((prevState) => ({
                                                    ...prevState,
                                                    exchangeRate: Number(e.target.value)
                                                }));
                                                recalculateTotalBankAmount(selectedInvoices, e.target.value);
                                            }}>
                                        </input>
                                    </div>
                                </>
                            )}
                        </div>
                            <div>
                                <h5 htmlFor="txtPositionName" className="col-md-11 mt-3">Invoices</h5>
                                <hr/>
                                    <div className='row'>
                                       <div className="col-md-1">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label"> </label>
                                       </div>
                                       <div className="col-md-2">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Invoice</label>
                                       </div>
                                       <div className="col-md-2">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Amount</label>
                                       </div>
                                       <div className="col-md-2">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Date</label>
                                       </div>
                                       <div className="col-md-4">
                                           <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Notes</label>
                                       </div>
                                   </div>
                                    {
                                    invoices.map((invoice, index) => (
                                        <div key={index}>
                                             <div className='row'>
                                                <div className="col-md-1 mb-2 mt-2">
                                                    <input className="ms-5" 
                                                        type="checkbox" 
                                                        checked={selectedInvoicesIds.includes(invoice.invoiceId)}
                                                        onChange={(e) => e.target.checked ? addInvoice(invoice) : deleteInvoice(invoice.invoiceId, index)} />
                                                </div>
                                                <div className="col-md-2 mb-2 mt-2">
                                                    <label class="form-check-label" for="formrow-customCheck">#{invoice.invoiceNumber}</label>
                                                </div>
                                                <div className="col-md-2 mb-2 mt-2">
                                                    <label class="form-check-label" for="formrow-customCheck">{accountInfo.currencySuffix} {invoice.amount}</label>
                                                </div>
                                                <div className="col-md-2 mb-2 mt-2">
                                                   <label class="form-check-label" for="formrow-customCheck">{invoice.date.split('T')[0]}</label>
                                                </div>
                                                <div className="col-md-4 mb-2 mt-2">
                                                    <div className="input-group mb-3">
                                                        <input className="form-control" required
                                                            value={invoice.notes}
                                                            onChange={(e) => {
                                                                invoice.notes = e.target.value;
                                                                handleInvoiceUpdate(invoice, selectedInvoicesIds.indexOf(invoice.invoiceId));
                                                            }}>
                                                        </input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                              <hr/>
                                <div >
                                    {/* <button className="waves-effect waves-light btn btn-md btn-info mr-3" onClick={() => handleInvoiceAdd()}><FiPlus /> Add Invoice</button> */}
                                    {/* <hr/> */}
                                    <h5 >Total Payment: <b>{accountInfo.currencySuffix} {bankPayment.originalAmount.toLocaleString()}</b></h5>
                                    <h5 >Bank Payment: <b>{bankPayment.currencySuffix} {bankPayment.bankAmount.toLocaleString()}</b></h5>
                                </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleCloseAddPayment()}>
                            Close
                        </Button>
                        <Button variant="primary" disabled={submitDisabled} onClick={(e) => addInvoicesPayments(e)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>

        </>
    )
}
export default ForwarderAccountStatment;