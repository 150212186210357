import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../Variables/Global";
import ListSupplierOrders from "../SupplierOrders/ListSupplierOrders";
import ManageSupplierOrder from "../SupplierOrders/ManageSupplierOrder";
import ViewSupplierOrder from "../SupplierOrders/ViewSupplierOrder";
import axiosInstance from '../../../../api/axiosConfig';


const ManageAccountSupplier = (props) => {
     
    const [pageType, setPageType] = useState("Add")
    const [accountName, setAccountName] = useState("")
    const [currencyId, setCurrencyId] = useState(0)
    const [currencies, setCurrencies] = useState([])
    const [companies, setCompanies] = useState([])
    const navigate = useNavigate();

    const [supplierOrderId, setSupplierOrderId] = useState(0);
    const [showSupplierOrderList, setShowSupplierOrderList] = useState(1)
    const [supplierViewOrder, setSupplierViewOrder] = useState(false)

    const [tab, setTab] = useState(1)

    const GetCurrencies = () => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrencies",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                // console.log(response.data)
                setCurrencies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetSupplierAccount = (supplierAccountId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccount/" + supplierAccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                props.setSupplierAccountId(response.data.supplierAccountId)
                setAccountName(response.data.supplierAccount1)
                setCurrencyId(response.data.currencyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    // const GetCompanies = () => {
    //     var config = {
    //         method: 'get',
    //         url: "Company/GetCompany",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': Global.Host
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setCompanies(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { 
                accountName: accountName, 
                accountName: accountName, 
                currencyId: currencyId, 
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!") 
            }
            if (props.supplierAccountId == "0") { //Add Supplier Account
                var config = {
                    method: 'post',
                    url: "SupplierAccount/AddSupplierAccount/" + props.supplierId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Supplier Added Successfully")
                            navigate("/Accounting/Supplier/" + props.supplierId + "/Account/" + response.data + "/Add")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Supplier Account
                var config = {
                    method: 'put',
                    url: "SupplierAccount/EditSupplierAccount/" + props.supplierAccountId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Account Updated Successfully")
                            props.setShowSupplierAccountList(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }

    }
    const IsValid = () => {
        if (accountName == "" && currencyId == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (accountName == "") {
            toast.error("Enter Account Name");
            return false;
        }
        else if (currencyId == "") {
            toast.error("Enter Currency");
            return false;
        }
        return true;
    }
    useEffect(() => {
        GetCurrencies();
        if (props.supplierAccountId != 0) {
            GetSupplierAccount(props.supplierAccountId)
            setPageType("Edit");
        }
    }, [])
    return (
        <>
            <div className="mb-3 row">
                <div className="col-12 col-md-6">
                    <h4 className="card-title">{pageType} Supplier Account</h4>
                </div>
                <div className="col-12 col-md-6 text-right">
                    <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Save Account</button>
                </div>
            </div>
            <div className="mb-3 row">
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Account Name</label>
                    <input className="form-control" type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} required />
                </div>
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Currency</label>
                    <select className="form-control" value={currencyId} onChange={(e) => setCurrencyId(e.target.value)} required>
                        <option value="0" key="0">-- Select Currency Name --</option>
                        {currencies.length > 0 && (
                            currencies.map((c, index) => {
                                return (<>
                                    <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                </>)
                            })
                        )}
                    </select>
                </div>
                {/* <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Company</label>
                    <select className="form-control" value={companyId} onChange={(e) => setCompanyId(e.target.value)} required>
                        <option value="0" key="0">-- Select Company Name --</option>
                        {companies.length > 0 && (
                            companies.map((c, index) => {
                                return (<>
                                    <option value={c.companyId} key={index}>{c.companyName}</option>
                                </>)
                            })
                        )}
                    </select>
                </div> */}
            </div>
        </>
    )

}
export default ManageAccountSupplier