import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../../Variables/Global";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import axiosInstance from '../../../../api/axiosConfig';

const ListAddresses = (props) => {
     
    const pId = useParams();
    const [addresses, setAddresses] = useState([]);

    const columns = [
        {
            name: '#',
            selector: row => row.customerAddressId,
        },
        {
            name: 'Country',
            selector: row => row.country,
        },
        {
            name: 'City',
            selector: row => row.city,
        },
        {
            name: 'Street',
            selector: row => row.street,
        },
        {
            name: 'Building',
            selector: row => row.building,
        },
        {
            name: 'Floor',
            selector: row => row.floor,
        },
        {
            name: 'Options',
            selector: row => {
                return (
                    <>
                        <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Addresses/" + row.customerAddressId} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </Link>
                        <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button>
                    </>
                )
            },
        },
    ];

    const GetCustomerAddresses = (customerId) => {
        var config = {
            method: 'get',
            url: "CustomerAddress/GetCustomerAddresses/" + customerId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setAddresses(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        GetCustomerAddresses(pId.CustomerId);
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item active">Clients</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <h4 className="card-title">List Client Addresses</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                            <Link to={"/Accounting/Customer/"} className="btn btn-secondary mr-3">Cancel</Link>
                                            <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Addresses/0"} className="btn btn-primary">Add Client Address</Link>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={addresses}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ListAddresses