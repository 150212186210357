import axios from "axios";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Global } from "../../Variables/Global";
import axiosInstance from '../../api/axiosConfig';

const SetNewPassword = (props) => {
    var pId = useParams();
    const [body, setBody] = useState({
        newPassword:"",
        confirmPassword:"",
    })
    const navigate = useNavigate();
    const Submit = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "User/SetPassword/" + pId.EmployeeId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Password Updated Successfully")
                    props.setIsLogged(true);
                    localStorage.setItem("uTsReD", pId.EmployeeId)
                    navigate("/")
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }
    return (
        <>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden">
                                <div className="card-body pt-0">
                                <h3 className="text-center">
                                        <img className="mt-5" src="/images/meractor-logo.png" alt="" height="80" />
                                    </h3>
                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center">Reset Password</h4>
                                        <p className="text-muted text-center">Enter new password to sign in.</p>
                                        <form className="form-horizontal mt-4">
                                            <div className="mb-3">
                                                <label htmlFor="newpassword">New Password</label>
                                                <input type="password" className="form-control" id="userpassword" placeholder="New password" required
                                                    onChange={(e) => 
                                                        setBody((prevState) =>
                                                        ({
                                                            ...prevState,
                                                            newPassword: e.target.value
                                                        }))} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="confirmpassword">Confirm Password</label>
                                                <input type="password" className="form-control" id="userpassword" placeholder="Confirm password" required
                                                    onChange={(e) =>
                                                        setBody((prevState) =>
                                                        ({
                                                            ...prevState,
                                                            confirmPassword: e.target.value
                                                        }))} />
                                            </div>
                                            <div className="mb-3 row mt-4">
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={(e) => Submit(e)}>Submit</button>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 row">
                                                <div className="col-12 mt-4">
                                                    <Link to={"/Login"} className="text-muted"><BiArrowBack /> Back to Login</Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                                Copyrights © {new Date().getFullYear()} Mercator. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="bottom-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false} />
        </>
    )
}
export default SetNewPassword;