import React, { useEffect, useState } from "react";
import { Global } from "../../Variables/Global";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from '../../api/axiosConfig';
import { formatDateString } from '../../utils/utils';

const TopHeader = (props) => {
    var navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }
    const EditContact = () => {
        navigate("/HR/Employee/" + localStorage.getItem("uTsReD"))
    }
    const [fullName, setFullName] = useState("");
    const [profileURL, setProfileURL] = useState("");
    const [userAvatar, setUserAvatar] = useState("");
    const [multiCompanyFlag, setMultiCompanyFlag] = useState(0);
    const [isOpen, setIsOpen] = useState(true)
    const handleSideBarMenu = () => {
        if (isOpen !== true) {
            document.body.classList.remove('sidebar-enable', 'vertical-collpsed');
            setIsOpen(true)
        }
        else {
            document.body.classList.add('sidebar-enable', 'vertical-collpsed');
            setIsOpen(false)
        }
    }
    function toggleFullscreen(elem) {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    const [notifications, setNotifications] = useState([])
    const [notificationsBadge, setNotificationsBadge] = useState(0)
    const [unreadNotificationsBadge, setUnreadNotificationsBadge] = useState(0)
    
    const GetNotifications = () => {
        var config = {
            method: 'get',
            url: "Notifications/GetNotifications",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params:{
                'UserId': localStorage.getItem("uTsReD"),
                'Limit': 5
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setNotifications(response.data.notifications);
                setNotificationsBadge(response.data.badge);
                setUnreadNotificationsBadge(response.data.unread);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const resetNotificationsBadge = () => { 
        var config = {
            method: 'put',
            url: "Notifications/ResetNotificationsBadge",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params:{
                'UserId': localStorage.getItem("uTsReD"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setNotificationsBadge(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const SetNotificationRead = (notificationCenterId) => {
        var config = {
            method: 'put',
            url: "Notifications/SetNotificationRead",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params:{
                'UserId': localStorage.getItem("uTsReD"),
                'NotificationCenterId': notificationCenterId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setNotifications(response.data.notifications);
                // handleCloseDeleteAllNotificationsModal();
                // handleCloseDeleteNotificationModal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (props.user) {
            setFullName(props.user.fullName);
            setProfileURL(props.user.profile);
            setUserAvatar(props.user.userAvatar);
            setMultiCompanyFlag(localStorage.getItem("C0m8X!"));
            GetNotifications();
        }
    }, [props.user]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data && event.data.type === 'RELOAD_NOTIFICATIONS') {
              GetNotifications();
            }
          });
        }
      }, []); // Empty dependency array ensures it runs only on mount

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        {/* <!-- LOGO --> */}
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-light">
                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img className="mt-3" src="/images/logo-sm.png" alt="" height="30px" width="30px" />
                                    </span>
                                    <span className="logo-lg">
                                        <img className="mt-3" src="/images/logo-dark.png" alt="" height="45px" />
                                    </span>
                                </Link>
                            </Link>
                        </div>
                        <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn" onClick={() => handleSideBarMenu()}>
                            <i className="mdi mdi-menu"></i>
                        </button>
                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                                aria-labelledby="page-header-search-dropdown">
                            </div>
                        </div>

                        <div className="dropdown d-none d-lg-inline-block">
                            <button type="button" className="btn header-item noti-icon waves-effect" onClick={() => toggleFullscreen(this)}>
                                <i className="mdi mdi-fullscreen font-size-24"></i>
                            </button>
                        </div>

                        <div className="dropdown d-inline-block ms-1">
                            <button type="button" onClick={resetNotificationsBadge} className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ti-bell"></i>
                                {
                                    notificationsBadge > 0 &&
                                    <span className="badge bg-danger rounded-pill">{notificationsBadge}</span>
                                }
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-notifications-dropdown"
                                style={{ width: "500px"}}>
                                <div className="p-3">
                                    <div className="row border-bottom align-items-center">
                                    {/* <div className="p-2 border-top d-grid"> */}
                                    <div className="col">
                                            <h5 className="m-0 mb-3"> Notifications ({unreadNotificationsBadge}) </h5>
                                        </div>
                                    </div>
                                </div>
                                <div data-simplebar="init" style={{ width: "500px", height: "400px" }}>
                                    <div className="scrollable-content">
                                        {
                                            notifications.map((n, index) => (
                                                <div key={index} style={{ backgroundColor: (n.isRead === false ? 'rgba(245, 178, 37, 0.1)' : '#fff'), padding: '10px' }}>
                                                    <a href={Global.Host + n.actionValue} className="p-1 d-flex text-reset notification-item" onClick={(e) => { SetNotificationRead(n.notificationCenterId); }}>
                                                        <div className="flex-grow-1">
                                                            <div className="row">
                                                                <div className="col-5">
                                                                    <h6 className="mb-1">{n.title}</h6>
                                                                </div>
                                                                <div className="col-7 text-end text-muted font-size-10">
                                                                    <span style={{ color: '#936b16', backgroundColor: '#fdf0d3' , borderRadius: 5, padding: '0 5px' }}>{formatDateString(n.date)}</span>
                                                                </div>
                                                            </div>
                                                            {/* <div className="text-end text-muted font-size-10">
                                                                {formatDateString(n.date)}
                                                            </div> */}
                                                            <div className="mt-1 text-muted font-size-11">
                                                                <p className="mb-3" dangerouslySetInnerHTML={{ __html: n.body }}></p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    {index !== notifications.length - 1 && <hr className="my-1" />}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="p-2 border-top d-grid">
                                    <a className="btn btn-sm btn-link font-size-14 btn-block text-center" href="/NotificationsCenter">
                                        <i className="mdi mdi-arrow-right-circle me-1"></i> <b>View all</b>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {/*Hello, {fullName}*/}
                                {profileURL && profileURL !== "" ? (
                                    <img
                                        src={profileURL}
                                        alt={profileURL}
                                        width="40"
                                        className="rounded-circle"
                                        style={{ marginRight: '10px' }}
                                    />
                                ) : (
                                    <h4
                                        className="text-center rounded-circle img-thumbnail d-flex align-items-center justify-content-center"
                                        style={{
                                            width: 50,
                                            height: 50,
                                            lineHeight: 65,
                                            borderRadius: '50%',
                                            margin: 0,
                                        }}
                                    >
                                        {userAvatar ?? ""}
                                    </h4>
                                )}

                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                {/* <!-- item--> */}
                                {multiCompanyFlag == 1 && (
                                    <button className="dropdown-item" onClick={() => props.setCompanyId(0)}><i className="mdi mdi-account-convert font-size-17 text-muted align-middle me-1"></i> Switch Company</button>
                                )}
                                <button className="dropdown-item" onClick={() => EditContact()} ><i class="mdi mdi-account-circle font-size-17 text-muted align-middle me-1"></i>Profile</button>
                                <div className="dropdown-divider"></div>
                                <button className="dropdown-item text-danger" onClick={() => logout()}><i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger"></i> Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default TopHeader