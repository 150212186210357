import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiEdit, FiEye, FiFileText } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';


const ListBanks = () => {

     
    const [showBankModel, setShowBankModel] = useState(false)
    const [showBankAccountModel, setShowBankAccountModel] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isEditAccount, setIsEditAccount] = useState(false)
    const [bankList, setBankList] = useState([]);

    const handleClose = () => setShowBankModel(false);
    const handleCloseAccount = () => setShowBankAccountModel(false);
    const GetBanks = (companyId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'Bank/GetCompanyBanks/' + companyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setBankList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [currency, setCurrency] = useState([]);
    const GetCurrencies = () => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'Currency/GetCurrencies',
            headers: { },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // const columns = [
    //     {
    //         name: "#",
    //         selector: row => row.bankId,
    //         width: "90px"
    //     },
    //     {
    //         name: "Beneficiary Name",
    //         selector: row => row.beneficiaryName,
    //         sortable: true,
    //     },
    //     {
    //         name: "Bank Name",
    //         selector: row => row.bankName,
    //         sortable: true,
    //     },
    //     {
    //         name: "Currency",
    //         selector: row => row.currencyName + " - " + row.currencySuffix,
    //         width: "180px",
    //         sortable: true,
    //     },
    //     {
    //         name: "Bank Address",
    //         selector: row => row.bankAddress
    //     },
    //     {
    //         name: "Bank Country",
    //         selector: row => row.bankCountry,
    //         sortable: true,
    //     },
    //     {
    //         name: "Account Number",
    //         selector: row => row.accountNumber,
    //         sortable: true,
    //     },
    //     {
    //         name: "Swift Code",
    //         selector: row => row.swiftCode,
    //         sortable: true,
    //     },
    //     {
    //         name: "IBAN",
    //         selector: row => row.iban,
    //         sortable: true,
    //     },
    //     {
    //         name: "Options",
    //         selector: row =>
    //             <>
    //                 <Link to={"/Settings/Banks/Statment/" + row.bankId} className="btn btn-outline-primary btn-sm mr-3">
    //                     <FiFileText />
    //                 </Link>
    //                 <div className="btn btn-outline-success btn-sm mr-3">
    //                     <FiEye />
    //                 </div>
    //                 <div className="btn btn-outline-warning btn-sm" onClick={() => GetBankInfo(row.bankId)}>
    //                     <FiEdit />
    //                 </div>
    //             </>,
    //         width: "180px"

    //     },

    // ]

    const [bankId, setBankId] = useState("");

    const GetBankInfo = (bankId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'Bank/GetCompanyBank/' + bankId,
            headers: { },
        };

        axiosInstance(config)
            .then(function (response) {

                setBankDetails(response.data);
                setShowBankModel(true);
                setIsEdit(true);
                setBankId(bankId)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [bankAccountList, setBankAccountList] = useState([])
    const GetBankAccounts = () => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'BankAccount/GetBankAccounts',
            headers: { },
        };

        axiosInstance(config)
            .then(function (response) {
                setBankAccountList(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [bankDetails, setBankDetails] = useState({
        userId: localStorage.getItem("uTsReD"),
        beneficiaryName: "",
        bankName: "",
        bankCountry: "",
        bankAddress: "",
        iban: "",
        accountNumber: "",
        swiftCode: "",
        companyId: localStorage.getItem("C0m8N!"),
        currencyId: "",
        currencyName: "",
        currencySuffix: "",

    })

    const [bankAccountDetails, setBankAccountDetails] = useState({
        userId: localStorage.getItem("uTsReD"),
        beneficiaryName: "",
        bankAccount1: "",
        bankAccountId: 0,
        iban: "",
        swiftCode: "",
        companyId: localStorage.getItem("C0m8N!"),
        currencyId: "",
        bankId: 0
    })


    const SubmitBank = (e) => {
        e.preventDefault();
        // var axios = require('axios');
        if (!isEdit) {
            var config = {
                method: 'post',
                url: 'Bank/AddBank',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: bankDetails
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status == "200") {
                        toast.success("Bank Added");
                        GetBanks(localStorage.getItem("C0m8N!"));
                        setBankDetails({});
                        handleClose();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            var config2 = {
                method: 'put',
                url: 'Bank/EditBank/' + bankId,
                headers: {
                    'Content-Type': 'application/json'

                },
                data: bankDetails
            };

            axiosInstance(config2)
                .then(function (response) {
                    if (response.status == "200") {
                        toast.success("Bank Updated");
                        GetBanks(localStorage.getItem("C0m8N!"));
                        setBankDetails({});
                        handleClose();
                        setIsEdit(false);
                        setBankId("");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    const SubmitBankAccount = (e) => {
        console.log(bankAccountDetails)
        e.preventDefault();
        if (!isEditAccount) {
            var config = {
                method: 'post',
                url: 'BankAccount/AddBankAccount',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: bankAccountDetails
            };

            axiosInstance(config)
                .then(function (response) {
                    console.log(response)
                    if (response.data.statusCode == "200") {
                        toast.success("Bank Account Added");
                        setBankAccountDetails({});
                        handleCloseAccount();
                        GetBankAccounts();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error + "");
                });
        }
        else {
            // var config = {
            //     method: 'put',
            //     url: 'BankAccount/EditBankAccount/' + bankAccountId,
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     data: bankDetails
            // };

            // axiosInstance(config)
            //     .then(function (response) {
            //         if (response.status == "200") {
            //             toast.success("Bank Account Updated");
            //             setBankAccountDetails({});
            //             handleCloseAccount();
            //             setIsEditAccount(false);
            //             setBankId("");
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
        }

    }
    const DeleteBank = (bankId) => {
        var config = {
            method: 'delete',
            url: 'Bank/DeleteBank/' + bankId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode == "200") {
                    toast.success("Bank Account Deleted");
                    var companyId = localStorage.getItem("C0m8N!");
                    GetBanks(companyId);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        document.title = "Banks"
        var companyId = localStorage.getItem("C0m8N!");
        GetBanks(companyId);
        GetCurrencies();
        GetBankAccounts();
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
    }, [])
    return (
        <>
            {permissions.viewBanks == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box">
                                        <h4>Banks</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Mercator</li>
                                            <li className="breadcrumb-item">Banks</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <h5>List of Banks</h5>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                        {permissions.addBank == true && (
                                             <button onClick={() => setShowBankModel(true)} className="btn btn-primary mr-3">Add Bank</button>
                                        )}
                                           
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <hr></hr>
                                        {/* <DataTable data={bankList} columns={columns} pagination={true} /> */}
                                        {bankList.map((b) => (
                                            <>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <h5>{b.bankName} <small>- {b.bankAddress}</small></h5>
                                                            <h6>{b.title}</h6>
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            {/* <button className="btn btn-danger btn-sm mr-3" onClick={() => {
                                                                DeleteBank(b.bankId)
                                                            }}>
                                                                <FiTrash2 />
                                                            </button> */}
                                                            {/* ViewBankAccountStatement */}

                                                            {permissions.addBankAccount == true && (
                                                                <button className="btn btn-primary btn-sm"
                                                                onClick={() => {
                                                                    setShowBankAccountModel(true);
                                                                    setBankAccountDetails((prevState) => ({ ...prevState, bankId: b.bankId }))
                                                                }}>Add Bank Account</button>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <table className="table table-stripped">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>Bank Account</th>
                                                                <th>Beneficiary Name</th>
                                                                <th>Currency</th>
                                                                <th>IBAN</th>
                                                                <th>SWIFT Code</th>
                                                                <th>Options</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bankAccountList.length > 0 && (
                                                                <>
                                                                    {bankAccountList.filter((i) => i.bankId == b.bankId).map((bal) => (
                                                                        <>
                                                                            <tr>
                                                                                <td>{bal.bankAccount1}</td>
                                                                                <td>{bal.beneficiaryName}</td>
                                                                                <td>{bal.currencySuffix}</td>
                                                                                <td>{bal.iban}</td>
                                                                                <td>{bal.swiftCode}</td>
                                                                                <td>
                                                                                    {permissions.viewBankAccountStatement == true && (
                                                                                        <Link to={"/Accounting/Banks/Statment/" + b.bankId + "/Account/" + bal.bankAccountId} className="btn btn-outline-primary btn-sm"><FiFileText /></Link>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}</>
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <Modal show={showBankModel} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Manage Bank</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <label>Title</label>
                                            <input className="form-control"
                                                value={bankDetails.title}
                                                onChange={(e) =>
                                                    setBankDetails((prevState) => ({
                                                        ...prevState,
                                                        title: e.target.value
                                                    }))} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>Bank Name</label>
                                            <input className="form-control"
                                                value={bankDetails.bankName}
                                                onChange={(e) =>
                                                    setBankDetails((prevState) => ({
                                                        ...prevState,
                                                        bankName: e.target.value
                                                    }))} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>Bank Address</label>
                                            <textarea className="form-control"
                                                value={bankDetails.bankAddress}
                                                onChange={(e) =>
                                                    setBankDetails((prevState) => ({
                                                        ...prevState,
                                                        bankAddress: e.target.value
                                                    }))} />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={(e) => SubmitBank(e)}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showBankAccountModel} onHide={handleCloseAccount}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Manage Bank Account</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <label>Bank Account</label>
                                            <input className="form-control"
                                                value={bankAccountDetails.bankAccount1}
                                                onChange={(e) =>
                                                    setBankAccountDetails((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        bankAccount1: e.target.value
                                                    }))} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>Beneficiary Name</label>
                                            <input className="form-control"
                                                value={bankAccountDetails.beneficiaryName}
                                                onChange={(e) =>
                                                    setBankAccountDetails((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        beneficiaryName: e.target.value
                                                    }))} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>Currency</label>
                                            <select className="form-control"
                                                value={bankAccountDetails.currencyId}
                                                onChange={(e) => setBankAccountDetails((prevState) =>
                                                ({
                                                    ...prevState,
                                                    currencyId: e.target.value
                                                }))}>
                                                <option>-- Select Currency --</option>
                                                {currency.map((c) => (
                                                    <option value={c.currencyId}>{c.currencyName} - {c.currencySuffix}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>IBAN</label>
                                            <input className="form-control"
                                                value={bankAccountDetails.iban}
                                                onChange={(e) =>
                                                    setBankAccountDetails((prevState) => ({
                                                        ...prevState,
                                                        iban: e.target.value
                                                    }))} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>SWIFT Code</label>
                                            <input className="form-control"
                                                value={bankAccountDetails.swiftCode}
                                                onChange={(e) =>
                                                    setBankAccountDetails((prevState) => ({
                                                        ...prevState,
                                                        swiftCode: e.target.value
                                                    }))} />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseAccount}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={(e) => SubmitBankAccount(e)}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </>)}
        </>

    )
}
export default ListBanks;