
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const Positions = () => {
     
    const [positions, setPositions] = useState();
    const [pageType, setPageType] = useState("Add");
    const [positionName, setPositionName] = useState("");
    const [positionId, setPositionId] = useState(0);


    const [permissions, setPermissions] = useState([])

    const columns = [
        {
            name: '#',
            selector: row => row.userPositionId,
            width: "60px"
        },
        {
            name: 'Position Name',
            selector: row => row.name,
        },
        {
            name: permissions.editPosition == true || permissions.deletePosition == true ? 'Options' : '',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {permissions.editPosition == true && (
                            <button onClick={() => handleEditPosition(row.userPositionId)} className="btn btn-outline-warning mr-3">
                                <FiEdit />
                            </button>
                        )}
                        {permissions.deletePosition == true && (
                            <button type="button" className="btn btn-outline-danger">
                                <FiTrash2 />
                            </button>)}
                    </>
                )
            },
        },
    ];

    const handleEditPosition = (userPositionId) => {
        setPositionId(userPositionId);
        GetPosition(userPositionId);
        handleShow();

    }

    const GetPosition = (positionId) => {
        var config = {
            method: 'get',
            url: "Position/GetPositions/" + positionId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setPositionName(response.data.name)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetPositions = () => {
        var config = {
            method: 'get',
            url: "Position/GetPositions",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPositions(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { positionName: positionName, companyId: localStorage.getItem("C0m8N!"),userId: localStorage.getItem("uTsReD") }
            if (positionId == "0") { //Add Product Size
                var config = {
                    method: 'post',
                    url: "Position/AddPosition",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Position Added Successfully")
                            handleClose();
                            GetPositions();
                            setPositionName("")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Product Size
                var config = {
                    method: 'put',
                    url: "Position/EditPosition/" + positionId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Position Updated Successfully")
                            handleClose();
                            GetPositions();
                            setPositionName("")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (positionName == "") {
            toast.error("Enter Position Name");
            return false;
        }
        return true;
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        document.title = "Positions";
        GetPositions();
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        // console.log(permissions)
    }, [])
    return (
        <>
            {permissions.viewPosition == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Positions</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Positions</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6 mb-4'>
                                                    <h4 className="card-title">List Positions</h4>
                                                </div>
                                                <div className='col-12 col-md-6 text-right'>
                                                    {permissions.addPosition == true && (
                                                        <button className="btn btn-primary" onClick={() => handleShow()}>Add Positions</button>)}
                                                </div>
                                                <hr></hr>
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={positions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Position</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Position Name</label>
                                        <input className="form-control" type="text" value={positionName} id="txtPositionName" onChange={(e) => setPositionName(e.target.value)} required />
                                    </div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={(e) => submitForm(e)}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </>
    )
}
export default Positions