import {
    BrowserRouter,
} from "react-router-dom";
import AppRoutes from './Variables/AppRoutes';
import PrivateRoutes from './Variables/PrivateRoutes';
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopHeader from "./Pages/Layout/TopHeader";
import VerticalMenu from "./Pages/Layout/VerticalMenu";
import { Global } from "./Variables/Global";
import "./App.css"
import SelectCompany from "./Pages/Company/SelectCompany";
import axiosInstance from './api/axiosConfig';

function App() {
    const [user, setUser] = useState({});
    const [userPermissions, setUserPermissions] = useState([])
    // const axios = require('axios').default;
    // const token = localStorage.getItem('token');
    // axios.defaults.headers.Authorization = `Bearer ${token}`;
    
    const [isLogged, setIsLogged] = useState(false);
    const [companyId, setCompanyId] = useState(0);

    
    const publicVapidKey = 'BDlEg30zjBr9rVDExurUdJCWGZY-MrsLZFQ5AqxVcJmcbjO01tGUDhypWZkrgVV-hITGPp_NkSDmLR1khSyXOms';

   
      
    async function send() {
        if (isLogged) {
            const register = await navigator.serviceWorker.register('/service-worker.js', {
                scope: '/'
            });

            const subscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
            });

            var config = {
                method: 'post',
                url: "Notifications/subscribe",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: JSON.stringify(subscription),
                params: {
                    'UserId': localStorage.getItem("uTsReD"),
                    'CompanyId': localStorage.getItem("C0m8N!")
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    // setCommissionPerFCL(response.data.commission);
                    toast.success("Registered for push notifications.");
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error);
                });
        }
    }
      
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const GetUser = (userId) => {
        var config = {
            method: 'get',
            url: 'User/GetUser/' + userId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            }
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    localStorage.setItem("Def@ult$creeN", response.data.defaultScreenId)
                    setUser(() => response.data);
                }
            })
            .catch(function (error) {
                toast.error(error)
            });
    }
    const GetUserPermissions = (userId) => {
        var config = {
            method: 'get',
            url: "UserPermission/GetUserPermission/" + userId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setUserPermissions(response.data.pJoin)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        var companyId = localStorage.getItem("C0m8N!");

        var userId = localStorage.getItem("uTsReD");

        console.log('User id: %s', userId);

        if (userId != null && userId != '') {
            setIsLogged(true);
            GetUser(userId);
            setTimeout(() => {
                GetUserPermissions(userId);
            }, 500);
            if (companyId !== null && companyId !== '') {
                setCompanyId(companyId);
            }
        }
        else {
            setIsLogged(false);
        }

        // In index.js or App.js
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('Service Worker registered with scope:', registration.scope);
                send().catch(err => console.error(err));
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
        }
    }, [isLogged])
    return (
        <>
            <BrowserRouter>
                {isLogged ? (<React.Fragment>
                    {/* <!-- Begin page --> */}
                    {companyId != 0 ? (
                        <>
                            <div id="layout-wrapper">
                                <TopHeader user={user} setCompanyId={setCompanyId} />
                                <VerticalMenu userPermissions={userPermissions} />
                                <div className="main-content">
                                    <PrivateRoutes userPermissions={userPermissions} />
                                </div>
                                <footer className="footer">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                Version {Global.VersionNumber} Copyrights © {new Date().getFullYear()} Mercator. All rights reserved.
                                                
                                                {/* © <script>document.write(new Date().getFullYear())</script> <span>Mercator, All rights reserved</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </footer>
                            </div>
                        </>
                    ) : (
                        <SelectCompany setCompanyId={setCompanyId} />
                    )}


                </React.Fragment>) : (<React.Fragment>
                    <AppRoutes setUser={setUser} setIsLogged={setIsLogged} />
                </React.Fragment>)}
            </BrowserRouter>
            <ToastContainer position="bottom-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false} />
        </>
    );
}

export default App;
