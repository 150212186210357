import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { AiOutlineEye } from 'react-icons/ai';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../../Variables/Global";
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select from "react-select";
import moment from 'moment';
import axiosInstance from '../../../../api/axiosConfig';

const ListCommercialInvoice = (props) => {


     
    const pId = useParams();

    const [orders, setOrders] = useState([]);
    

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [showAddCommercialInvoice, setShowAddCommercialInvoice] = useState(false);
    const [showDeleteCommercialInvoice, setShowDeleteCommercialInvoice] = useState(false);
    const handleCloseAddCommercialInvoice = () => setShowAddCommercialInvoice(false);
    const handleShowAddCommercialInvoice = () => setShowAddCommercialInvoice(true);
    const handleCloseDeleteInvoice = () => setShowDeleteCommercialInvoice(false);
    const handleShowDeleteInvoice = () => setShowDeleteCommercialInvoice(true);

    const [commercialInvoice, setCommercialInvoice] = useState({
        sellOrderId: 0,
        invoiceNumber: "",
        date: moment(new Date()).format("yyyy-MM-DD"),
        proforma: "",
        otherCharges: 0.0,
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        notes: ""
    })
    const AddCommericalInvoice = (e) => {
        e.preventDefault();

        setSubmitDisabled(true);

        if(editingCommercialId > 0){
            var config = {
                method: 'put',
                url: "SupplierCommercialInvoice/EditSupplierCommercialInvoice/" + editingCommercialId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };
    
            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Updated Successfully")
                        setShowAddCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);

                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        }else{
            var config = {
                method: 'post',
                url: "SupplierCommercialInvoice/AddSupplierCommercialInvoice/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };
    
            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Added Successfully")
                        setShowAddCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);
                        // AddSupplierCommercialOrderProduct();
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        }
    }

    const DeleteInvoice = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if(deletingCommercialId > 0){
            var config = {
                method: 'delete',
                url: "SupplierCommercialInvoice/DeleteSupplierCommercialInvoice",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                params: {
                    'CommercialInvoiceId': deletingCommercialId,
                    'UserId': localStorage.getItem("uTsReD"),
                }
            };
    
            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Deleted Successfully")
                        setShowDeleteCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                    setShowDeleteCommercialInvoice(false)
                });
        }else{
            var config = {
                method: 'post',
                url: "SupplierCommercialInvoice/AddSupplierCommercialInvoice/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };
    
            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Added Successfully")
                        setShowAddCommercialInvoice(false)
                        GetCommercialInvoices(pId.OrderId);
                        // AddSupplierCommercialOrderProduct();
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        }
        
    }
    const [invoices, setInvoices] = useState([]);

    const GetCommercialInvoices = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoices/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setInvoices(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const columns = [
        {
            name: '#',
            selector: row => row.commercialInvoiceId,
            width: "60px"
        },
        {
            name: 'Invoice Number',
            width: "200px",
            selector: row => row.invoiceNumber,

        },
        {
            name: 'Other Charges',
            width: "200px",
            selector: row => {
                return (
                    <>
                        {currency.currencySuffix} {row.otherCharges}
                    </>
                )
            },

        },
        {
            name: 'Date',
            width: "150px",
            selector: row => row.date.split("T")[0],
        },
        {
            name: "Notes",
            selector: row => row.notes
        },

        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + pId.OrderId + "/CommercialInvoices/" + row.commercialInvoiceId} className="btn btn-outline-success mr-3" name="Paymount"><AiOutlineEye /></Link>
                        <button onClick={() => {
                                    setEditingCommercialId(row.commercialInvoiceId);
                                    setCommercialInvoice((prevState) => ({
                                        ...prevState,
                                        sellOrderId: pId.OrderId,
                                        otherCharges: row.otherCharges,
                                        invoiceNumber: row.invoiceNumber,
                                        date: row.date.split("T")[0],
                                        notes: row.notes,
                                    }));
                                    handleShowAddCommercialInvoice();
                                }
                              } className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </button>
                        <button onClick={() => {
                                    setDeletingCommercialId(row.commercialInvoiceId);
                                    handleShowDeleteInvoice();
                                }
                              } className="btn btn-outline-danger mr-3">
                            <FiTrash2 />
                        </button>
                    </>
                )
            },
        },
    ];
    const [supplierName, setSupplierName] = useState("");

    const [account, setAccount] = useState({
        supplierAccountId: 0,
        supplierAccount1: "",
        currencyId: 0
    })

    const [order, setOrder] = useState({
        supplierOrderId: 0,
        supplierOrder1: "",
        invoiceNumber: "",
        date: ""
    })
    const GetSupplier = (supplierId) => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers/" + supplierId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierName(response.data.supplierName)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetSupplierAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data)
                GetCurrency(response.data.currencyId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [currency, setCurrency] = useState("");
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                // GetCustomerOrder(pId.OrderId);
            })
            .catch(function (error) {
                console.log(error);
                // GetCustomerOrder(pId.OrderId);
            });
    }


    const [editingCommercialId, setEditingCommercialId] = useState(0);
    const [deletingCommercialId, setDeletingCommercialId] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const GetSupplierOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrder(response.data)
                setInvoiceNumber(response.data.invoiceNumber);
                document.title = '#' + response.data.invoiceNumber + " Invoices";
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    // const [today, setToday] = useState()

    /*const [shippingLines, setShippingLines] = useState([])
    const GetShippingLines = () => {
        var config = {
            method: 'get',
            url: "ShippingLine/GetEnabledShippingLinesByCompany/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingLines(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }*/

    useEffect(() => {
        // const date = new Date();
        // setToday(moment(date).format("yyyy-MM-DD"));
        GetSupplier(pId.SupplierId);
        GetSupplierAccount(pId.AccountId);
        GetSupplierOrder(pId.OrderId);
        setCommercialInvoice((prevState) => ({
            ...prevState,
            sellOrderId: pId.OrderId,
            proforma: invoiceNumber
        }));
        GetCommercialInvoices(pId.OrderId);
        // GetShippingLines();

        // setCommercialInvoice((prevState) => ({
        //     ...prevState,
        //     proforma: invoiceNumber,
        // }));


    }, [invoiceNumber])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4>Suppliers</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Mercator</li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item">Account</li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId}>{account.supplierAccount1}</Link>
                                    </li>
                                    <li className="breadcrumb-item">Order</li>
                                    <li className="breadcrumb-item">#{order.invoiceNumber}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <h5>Invoice Number: #{order.invoiceNumber}</h5>
                                </div>
                                <div className="col-12 col-md-6 text-right">
                                    <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + pId.OrderId} className="btn btn-secondary mr-3">Cancel</Link>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <hr></hr>
                                <div className='col-12 col-md-6'>
                                    <h4 className="card-title">List Commercial Invoices</h4>
                                </div>
                                <div className='col-12 col-md-6 text-right'>
                                    <button className="btn btn-primary" onClick={(e) =>{
                                            handleShowAddCommercialInvoice();
                                            setEditingCommercialId(0);
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                invoiceNumber: "",
                                                date: moment(new Date()).format("yyyy-MM-DD"),
                                                notes: "",
                                            }));
                                        }
                                    }>Add Commercial Invoice</button>
                                </div>
                            </div>
                            <hr></hr>
                            <DataTable
                                columns={columns}
                                data={invoices}
                            />
                        </div>
                    </div>

                    <Modal show={showAddCommercialInvoice} onHide={handleCloseAddCommercialInvoice}>
                        <Modal.Header closeButton>
                            <Modal.Title>Commercial Invoice for Proforma #{commercialInvoice.proforma}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row ">
                                        <div className="col-12 mb-3">
                                            <label>Invoice Number</label>
                                            <input className="form-control"
                                                value={commercialInvoice.invoiceNumber}
                                                onChange={(e) => {
                                                    setCommercialInvoice((prevState) => ({
                                                        ...prevState,
                                                        invoiceNumber: e.target.value
                                                    }));
                                                }}></input>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label className="col-md-6 col-form-label">Other charges</label>
                                            <div className="input-group ">
                                                <div className="input-group-text">{currency.currencySuffix}</div>
                                                <input type="number" className="form-control" value={commercialInvoice.otherCharges}
                                                    onChange={(e) => {
                                                        // setOtherCharges(e.target.value);
                                                        setCommercialInvoice((prevState) => ({
                                                            ...prevState,
                                                            otherCharges: e.target.value
                                                        }));
                                                    }}>
                                                </input>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>Date</label>
                                            <input className="form-control" type="date" defaultValue={commercialInvoice.date}
                                                onChange={(e) => setCommercialInvoice((prevState) => ({
                                                    ...prevState,
                                                    date: e.target.value
                                                }))}></input>
                                        </div>
                                        <div className="col-12">
                                            <label>Notes</label>
                                            <textarea className="form-control"
                                                value={commercialInvoice.notes}
                                                onChange={(e) => {
                                                    setCommercialInvoice((prevState) => ({
                                                        ...prevState,
                                                        notes: e.target.value
                                                    }));
                                                }}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCloseAddCommercialInvoice}>
                                Close
                            </button>
                            <button className="btn btn-primary"
                                disabled={submitDisabled}
                                onClick={(e) => AddCommericalInvoice(e)}>
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDeleteCommercialInvoice} onHide={handleCloseDeleteInvoice}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Commercial Invoice</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this commercial invoice?
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCloseDeleteInvoice}>
                                Cancel
                            </button>
                            <button className="btn btn-primary"
                                disabled={submitDisabled}
                                onClick={(e) => DeleteInvoice(e)}>
                                Yes, Proceed
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default ListCommercialInvoice