import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { Global } from "../../../Variables/Global";
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const ListProfitsByClientAccount = () => {
    var pId = useParams();
    const [clientName, setClientName] = useState("");
    const [clientAccount, setClientAccount] = useState("");
    const [currencySuffix, setCurrencySuffix] = useState("");
    const [accountProfits, setAccountProfits] = useState();
    const [invoices, setInvoices] = useState([]);
    const GetClientAccountProfits = () => {
        var config = {
            method: 'get',
            url: 'Profits/GetProfitsByClientAccount/' + pId.ClientAccountId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setInvoices(response.data.commercialInvoices);
                setAccountProfits(response.data.accountProfits);
                setCurrencySuffix(response.data.currencySuffix);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetClientAccountInfo = () => {
        var config = {
            method: 'get',
            url: 'CustomerAccount/GetCustomerAccount/' + pId.ClientAccountId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setClientName(response.data.customerName);
                setClientAccount(response.data.customerAccount1);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Profit";
        GetClientAccountInfo();
        GetClientAccountProfits();
        // GetCustomerAccounts();
    }, [])

    const columns = [
        {
            name: 'Invoice',
            selector: row => row.clientCommercialInvoice,
            // style: {textAlign: 'center'}
        },
        {
            name: 'Product',
            selector: row => {
                return (
                    <>
                        <span className="text-wrap" dangerouslySetInnerHTML={{ __html: row.productName }} />
                        {/* <span className="text-wrap">{row.productName}</span> */}
                    </>
                )
            },
            width: "300px",

            // selector: row => {
            //     return (
            //         <>
            //             <div>
            //                 <span>{row.productName}</span>
            //                 <br />
            //                 <span><b>#{row.blReferenceNo}</b></span>
            //             </div>
            //         </>
            //     )
            // },
        },
        // {
        //     name: 'Quantity',
        //     selector: row => <b>{row.quantity.toLocaleString()}</b>,
        // },
        // {
        //     name: 'Price',
        //     selector: row => row.clientUnitPrice.toLocaleString(),
        // },
        {
            name: 'Client\'s amount',
            selector: row => row.clientAmount.toLocaleString(),
        },
        {
            name: 'Supplier\'s amount',
            selector: row => row.supplierAmount.toLocaleString(),
        },
        {
            name: 'ShippingCost',
            selector: row => <>{
            //  (row.freightOnClient != null && row.freightOnClient > 0) ?
            //         <span class="badge bg-light text-body" data-toggle="tooltip" data-placement="top" title="Freight billed on client account">{row.freightOnClient.toLocaleString()} </span>
            //         // <i class="ion ion-ios-warning"></i>
            //     :
                <>
                {row.shippingCost != null && row.shippingCost > 0 &&
                    <span class="badge bg-danger">{row.shippingCost.toLocaleString()}</span>
                }
                </>
             }
            </>
        },
        {
            name: 'Profit',
            selector: row => <b>{row.productProfit.toLocaleString()}</b>,
        },
    ];

    return (
        <>
            {permissions.viewProfits == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="page-title-box">
                                        <h4>Profit Table for {clientName} - {clientAccount}</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Profits Table</li>
                                        </ol>
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        {accountProfits != null &&
                                            <h4>Total Profits: {currencySuffix} {accountProfits.toLocaleString()}</h4>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <DataTable columns={columns} data={invoices} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>)}
        </>
    )
}
export default ListProfitsByClientAccount;