
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Global } from '../../Variables/Global';
import { getDecodedToken } from '../../utils/utils';
import axiosInstance from '../../api/axiosConfig';

const CustomerInvoices = () => {

    const [company, setCompanies] = useState();

    const columns = [
        //{
        //    name: '#',
        //    selector: row => row.companyId,
        //    width: "40px"
        //},
        {
            name: 'Logo',  // Add a name for the column
            selector: row => (
                <img
                    src={row.logo}  // Assuming the logo URL is in row.logo
                    alt={row.companyName}  // It's better to use companyName as alt text
                    height="50"
                    width="100"
                    className="auth-logo-light"
                    style={{ marginRight: '10px', marginTop: '10px' }}
                />
            ),
        },
        {
            name: 'Company Name',
            selector: row => row.companyName,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Website',
            selector: row => row.website,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => (
                <>
                    {permissions.editCompany === true && (
                        <Link to={"/company/" + row.companyId} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </Link>
                    )}
                </>
            ),
        },
    ];

    const GetCompanies = () => {
        var config = {
            method: 'get',
            url: "Company/GetCompany",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanies(response.data);
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Companies";
        GetCompanies();
    }, [])
    return (
        <>
            {permissions.viewCompany == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Companies</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Companies</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">Company Details</h4>
                                                </div>
                                                {/*<div className='col-12 col-md-6 text-right'>*/}
                                                {/*    <Link to="/Company/0" className="btn btn-primary">Add Company</Link>*/}
                                                {/*</div>*/}
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={company}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </>
    )
}
export default CustomerInvoices