
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const Product = () => {

    const [product, setProduct] = useState([]);
    const [filteredProduct, setFilteredProduct] = useState([]);
    const [search, setSearch] = useState('');
    const [permissions, setPermissions] = useState([]);

    const columns = [
        {
            name: '#',
            selector: row => row.productId,
            width: "60px"
        },
        {
            name: 'Product Name',
            selector: row => row.productName,
        },
        {
            name: 'Description',
            selector: row => row.description,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {permissions.editProduct === true && (
                            <Link to={"/Settings/Product/" + row.productId} className="btn btn-outline-warning mr-3">
                                <FiEdit />
                            </Link>
                        )}
                        {/* <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button> */}
                    </>
                );
            },
        },
    ];

    const GetProducts = () => {
        var config = {
            method: 'get',
            url: "Product/GetProducts",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setProduct(response.data);
                setFilteredProduct(response.data); // Initialize filtered products
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(x);
        document.title = "Products";
        GetProducts();
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearch(value);

        const filtered = product.filter(p =>
            p.productName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredProduct(filtered);
    };

    return (
        <>
            {permissions.viewProduct == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Product</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Product</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">List Product</h4>
                                                </div>
                                                <div className='col-12 col-md-6 text-right'>
                                                    {permissions.addProduct == true && (
                                                        <Link to="/Settings/Product/0" className="btn btn-primary">Add Product</Link>
                                                    )}
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Search Product Name"
                                                value={search}
                                                onChange={handleSearch}
                                            />
                                            <DataTable
                                                columns={columns}
                                                data={filteredProduct}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </>
    )
}
export default Product