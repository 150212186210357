import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const ShippingCompany = () =>{
     
    const [shippingCompany, setShippingCompany] = useState();

    const columns = [
        {
            name: '#',
            selector: row => row.shippingCompanyId,
            width:"60px"
        },
        {
            name: 'Forwarder Name',
            selector: row => row.shippingCompanyName,
        },
        {
            name: 'Options',
            width:"170px",
            selector: row => {
                return (
                    <>
                        {permissions.addForwarder == true && (
                            <Link to={"/Logistics/ShippingCompany/" + row.shippingCompanyId} className="btn btn-outline-warning mr-3">
                                <FiEdit />
                            </Link>
                        )}
                        {/* <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button> */}
                    </>
                )
            },
        },
    ];

    const GetShippingCompanies = () => {
        var config = {
            method: 'get',
            url: "ShippingCompany/GetShippingCompany",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingCompany(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Forwarders";
        GetShippingCompanies();
    }, [])
    return (
        <>
         {permissions.viewForwarders == null || permissions.viewForwarders == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Forwarders</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item active">Forwarders</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                        <h4 className="card-title">List Forwarders</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                        {permissions.addForwarder == true && (
                                            <Link to="/Logistics/Forwarder/0" className="btn btn-primary">Add Forwarder</Link>
                                        )}
                                          
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={shippingCompany}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
                )}
        </>

    )
}
export default ShippingCompany