import React, { useEffect, useState } from "react";
import { Global } from '../../../Variables/Global';
import { GetCurrencies } from '../../../api/HttpService'
import { FiEdit, FiEye, FiFilter, FiPlus } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';


const Forwarder = () => {
     
    const pId = useParams();
    const [forwarder, setForwarder] = useState([]);
    const [showingForwarders, setShowingForwarders] = useState([]);
    const navigate = useNavigate();

    const [forwarderName, setForwarderName] = useState("");
    // const [phoneNumber, setPhoneNumber] = useState("");
    // const [address, setAddress] = useState("");

    const GetForwarders = () => {
        var config = {
            method: 'get',
            url: "Forwarder/GetForwardersWithDetails?companyId=" + localStorage.getItem("C0m8N!"),
            headers: {},
            params: {},
            // params: { search, accountSearch, invoiceNumber, currency }
        };

        axiosInstance(config)
            .then(function (response) {
                setForwarder(response.data);
                if(showUnpaidOnly){
                    setShowingForwarders(response.data.filter((i) => i.unpaidInvoicesCount > 0));
                }else{
                    setShowingForwarders(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [showAccountModal, setShowAccountModal] = useState(false)
    const [accountName, setAccountName] = useState("")
    const [currencyId, setCurrencyId] = useState(0)
    const [currencies, setCurrencies] = useState([])
    const [selectedForwarder, setSelectedForwarder] = useState("")
    // const [accountSearch, setAccountSearch] = useState("");
    // const [invoiceNumber, setInvoiceNumber] = useState("");
    // const [search, setSearch] = useState("");
    // const [currency, setCurrency] = useState("");


    const handleCloseAccountModal = () => {
        setShowAccountModal(false)
        setEditingAccount(false)
    }
    const handleAddAccount = (forwarder) => {
        setShowAccountModal(true);
        console.log(forwarder)
        setSelectedForwarder(forwarder);

    }


    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { 
                accountName: accountName, 
                currencyId: currencyId, 
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!")
             }
            if (!editingAccount) {
                var config = {
                    method: 'post',
                    url: "ForwarderAccount/AddForwarderAccount/" + selectedForwarder.forwarderId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Forwarder Account Added Successfully");
                            GetForwarders();
                            handleCloseAccountModal(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Forwarder Account
                var config = {
                    method: 'put',
                    url: "ForwarderAccount/EditForwarderAccount/" + editedForwarderAccountId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Account Updated Successfully")
                            GetForwarders();
                            handleCloseAccountModal(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (accountName == "" && currencyId == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (accountName == "") {
            toast.error("Enter Account Name");
            return false;
        }
        else if (currencyId == "") {
            toast.error("Enter Currency");
            return false;
        }
        return true;
    }

    const submitForwarderForm = (e) => {
        e.preventDefault();
        if (IsValidForwarder()) {
            const body = { 
                forwarderName: forwarderName, 
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!") 
            }
            if (localStorage.getItem("F0RWDR") === null) {
                var config = {
                    method: 'post',
                    url: "Forwarder/AddForwarder",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Forwarder Added Successfully")
                            // navigate("/Accounting/Forwarder/View/" + response.data)
                            GetForwarders();
                            handleCloseForwarderModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Forwarder
                var config1 = {
                    method: 'put',
                    url: "Forwarder/EditForwarder/" + localStorage.getItem("F0RWDR"),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config1)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Forwarder Updated Successfully")
                            handleCloseForwarderModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValidForwarder = () => {
        if (forwarderName === "") {
            toast.error("Enter Forwarder Name");
            return false;
        }
       
        return true;
    }

    const [editingAccount, setEditingAccount] = useState(false)
    const [editedForwarderAccountId, setEditedForwarderAccountId] = useState(0);
    const [permissions, setPermissions] = useState([]);

    const handleEdit = (accountId) => {
        setEditingAccount(true);
        setEditedForwarderAccountId(accountId);
        GetForwarderAccount(accountId)
        setShowAccountModal(true);
    }

    const [forwarderAccount, setForwarderAccount] = useState({})
    const GetForwarderAccount = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'ForwarderAccount/GetForwarderAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setForwarderAccount(response.data);
                setAccountName(response.data.accountName)
                setCurrencyId(response.data.currencyId)

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [showForwarderModal, setShowForwarderModal] = useState(false);
    const [showUnpaidOnly, setShowUnpaidOnly] = useState(false);

    const handleCloseForwarderModal = () => {
        setShowForwarderModal(false)
        setForwarderName("")
        // setPhoneNumber("")
        // setAddress("")
        // localStorage.removeItem("F0RWDR")
    }

    const GetForwarder = (forwarderId) => {
        var config = {
            method: 'get',
            url: "Forwarder/GetForwarders/" + forwarderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setForwarderName(response.data.name)
                // setPhoneNumber(response.data.phone)
                // setAddress(response.data.address)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetCurrencies().then(currencies => setCurrencies(currencies));
        document.title = "Forwarders"
    }, [])

    useEffect(() => {
        GetForwarders();
    }, []);//, [search, accountSearch, invoiceNumber, currency]);

    return (
        <>
            {permissions.viewForwarder == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>Forwarders</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Forwarders</li>
                                        </ol>

                                        <button onClick={() => {
                                                if(!showUnpaidOnly){
                                                    setShowingForwarders(forwarder.filter((i) => i.unpaidInvoicesCount > 0));
                                                }else{
                                                    setShowingForwarders(forwarder);
                                                }
                                                setShowUnpaidOnly(!showUnpaidOnly);
                                            }
                                        } className="mt-3 btn btn-outline-danger"><FiFilter/> {showUnpaidOnly === true ? "View All Invoices" : "View Unpaid Invoices"}</button>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>
                                        {permissions.addForwarder == true && (
                                            <button onClick={() => {
                                                setShowForwarderModal(true);
                                                localStorage.removeItem("F0RWDR")
                                            }
                                            } className="ml-1 btn btn-primary"><FiPlus/> Add Forwarder</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {showingForwarders.map((s) => (
                                <div key={s.id}>
                                    <div>

                                        <div className="flex justify-between items-center p-2">
                                            <span>
                                                <strong>{s.name}</strong>
                                                <br/>
                                                {permissions.addForwarder === true &&
                                                    <button className='btn btn-primary btn-sm' onClick={() => handleAddAccount(s)}><FiPlus/> Add Account</button>
                                                }
                                              
                                              {permissions.editForwarder === true &&
                                                    <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                                        localStorage.setItem("F0RWDR", s.forwarderId);
                                                        setShowForwarderModal(true);
                                                        GetForwarder(s.forwarderId);
                                                       
                                                    }}><FiEdit /> Edit</button>
                                                }
                                            </span>

                                        </div>
                                    </div>
                                    <div className='card'>
                                        <table className='table'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th width="40%">Account</th>
                                                    <th width="20%">Invoices</th>
                                                    <th width="20%">Unpaid</th>
                                                    <th width="20%">Options</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {s.accounts.filter((a) => (!showUnpaidOnly || a.unpaidInvoicesCount > 0)).map(x => <tr key={x.id}>
                                                    <td>{x.name}</td>
                                                    <td>
                                                        <div>
                                                            <span>{x.invoicesCount == 1 ? "1 Invoice" : x.invoicesCount.toLocaleString() + " Invoices"}</span>
                                                            <br />
                                                            <span><b>{x.currency.currencySuffix} {x.invoicesTotal.toLocaleString()}</b></span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {
                                                            x.unpaidInvoicesCount == 0 ? 
                                                            <div>
                                                                <span style={{color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>All Set!</b></span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <span style={{color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}>{x.unpaidInvoicesCount == 1 ? "1 Invoice" : x.unpaidInvoicesCount.toLocaleString() + " Invoices"}</span>
                                                                <br />
                                                                <span style={{color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{x.currency.currencySuffix} {x.unpaidInvoicesTotal.toLocaleString()}</b></span>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-primary btn-sm' onClick={() => window.location = "/Accounting/Forwarders/" + s.forwarderId + "/Account/" + x.forwarderAccountId + "/Statment"}><FiEye /> Account Statement</button>
                                                        
                                                    {permissions.editForwarder === true &&
                                                            <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                                            handleEdit(x.forwarderAccountId)
                                                        }}><FiEdit /> Edit</button>
                                                    }
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Modal show={showAccountModal} onHide={handleCloseAccountModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Manage Account</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <label className="col-md-6 col-form-label">Account Name</label>
                                                    <input className="form-control" type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-md-6 col-form-label">Currency</label>
                                                    <select className="form-control" value={forwarderAccount.currencyId} onChange={(e) => setCurrencyId(e.target.value)} required disabled={editingAccount}>
                                                        <option value="0" key="0">-- Select Currency Name --</option>
                                                        {currencies.length > 0 && (
                                                            currencies.map((c, index) => {
                                                                return (<>
                                                                    <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                                                </>)
                                                            })
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseAccountModal}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={(e) => submitForm(e)}>
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            ))}

                            <Modal show={showForwarderModal} onHide={handleCloseForwarderModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Forwarder</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="mb-3 row">
                                        <div className="col-md-12">
                                            <label className="col-md-6 col-form-label">Forwarder Name</label>
                                            <input className="form-control" type="text" value={forwarderName} onChange={(e) => setForwarderName(e.target.value)} required />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseForwarderModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={(e) => submitForwarderForm(e)}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </>)
            }
        </>
    )
}
export default Forwarder