import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { getDecodedToken } from '../../../utils/utils';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axiosConfig';

const BankChargesTypes = () => {

    const [items, setItems] = useState([]);
    const [editingBankChargesTypeId, setEditingBankChargesTypeId] = useState(0);
    const [deletingBankChargesTypeId, setDeletingBankChargesTypeId] = useState(0);

    const columns = [
        {
            name: '#',
            selector: row => row.bankChargesTypeId,
            width: "60px"
        },
        {
            name: 'Bank Charges Type',
            selector: row => row.name,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {permissions.editBankChargeOption === true && (
                            <button type="button" className="btn btn-outline-warning" onClick={(e) => {
                                setEditingBankChargesTypeId(row.bankChargesTypeId);
                                setBankChargesType((prevState) => ({
                                    ...prevState,
                                    name: row.name
                                }))
                                setShowAddBankChargesTypeModal(true);
                            }
                            }><FiEdit /></button>
                        )}
                        {permissions.deleteBankChargeOption === true && (
                            <button type="button" className="btn btn-outline-danger ml-1" onClick={(e) => {
                                setDeletingBankChargesTypeId(row.bankChargesTypeId);
                                setShowDeleteBankChargesTypeModal(true);
                            }
                            }><FiTrash2 /></button>
                        )}
                    </>
                )
            },
        },
    ];

    const [showAddBankChargesTypeModal, setShowAddBankChargesTypeModal] = useState(false)
    const handleCloseAddBankChargesTypeModal = () => {
        setShowAddBankChargesTypeModal(false);
    }

    const [showDeleteBankChargesTypeModal, setShowDeleteBankChargesTypeModal] = useState(false)
    const handleCloseDeleteBankChargesTypeModal = () => {
        setShowDeleteBankChargesTypeModal(false);
    }

    const [bankChargesType, setBankChargesType] = useState({
        name: '',
        companyId: localStorage.getItem("C0m8N!"),
        userId: localStorage.getItem("uTsReD"),
    });

    const GetItems = () => {
        var config = {
            method: 'get',
            url: 'BankChargesTypes/GetBankChargesTypes',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'CompanyId': localStorage.getItem("C0m8N!")
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setItems(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const DeleteItem = () => {
        var config = {
            method: 'delete',
            url: 'BankChargesTypes/DeleteBankChargesType',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'BankChargesTypeId': deletingBankChargesTypeId
            }
        };
        axiosInstance(config)
            .then(function (response) {
                setItems(response.data);
                handleCloseDeleteBankChargesTypeModal();
            })
            .catch(function (error) {
                console.log(error);
                handleCloseDeleteBankChargesTypeModal();
            });
    }

    const submitBankChargesTypeForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            if (editingBankChargesTypeId === 0) { //Add 
                var config = {
                    method: 'post',
                    url: 'BankChargesTypes/AddBankChargesType',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: bankChargesType
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            setItems(response.data);
                            toast.success("Bank Charges Option Added Successfully")
                            handleCloseAddBankChargesTypeModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                    });
            }
            else { // Update Product
                var config = {
                    method: 'put',
                    url: 'BankChargesTypes/EditBankChargesType',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    params: {
                        'BankChargesTypeId': editingBankChargesTypeId
                    },
                    data: bankChargesType
                };
                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            setItems(response.data);
                            toast.success("Bank Charges Option Updated Successfully");
                            handleCloseAddBankChargesTypeModal();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                        // handleCloseAddBankChargesTypeModal();
                    });
            }
        }
    }
    const IsValid = () => {
        if (bankChargesType.name == "") {
            toast.error("Enter the bank charges type name");
            return false;
        }
        return true;
    }
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        // var x = JSON.parse(localStorage.getItem("uTsReP"));
        // setPermissions(() => x);
    }, []);

    useEffect(() => {
        document.title = "Bank Charges Types";
        GetItems();
    }, []);


    return (
        <>
            {permissions.viewBankChargeOptions === false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Bank Charges Types</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Bank Charges Types</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">List Bank Charges Types</h4>
                                                </div>
                                                <div className='col-12 col-md-6 text-right'>
                                                    {permissions.addBankChargeOption === true && (
                                                        <button type="button" className="btn btn-info" onClick={(e) => {
                                                            setEditingBankChargesTypeId(0);
                                                            setBankChargesType((prevState) => ({
                                                                ...prevState,
                                                                name: ''
                                                            }))
                                                            setShowAddBankChargesTypeModal(true);
                                                        }
                                                        }><FiPlus /> Add New Option</button>
                                                    )}
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={items}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}

            <Modal size="md" show={showAddBankChargesTypeModal} onHide={handleCloseAddBankChargesTypeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingBankChargesTypeId === 0 ? "Add Bank Charges" : "Edit Bank Charges"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Bank Charges Type Name</label>
                            <input className="form-control"
                                type="text"
                                placeholder='Enter name'
                                value={bankChargesType.name}
                                onChange={(e) =>
                                    setBankChargesType((prevState) => ({
                                        ...prevState,
                                        name: e.target.value
                                    }))
                                } required />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddBankChargesTypeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) =>
                        submitBankChargesTypeForm(e)
                    }>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteBankChargesTypeModal} onHide={handleCloseDeleteBankChargesTypeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Bank Charges Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this option?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteBankChargesTypeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) =>
                        DeleteItem()
                    }>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default BankChargesTypes