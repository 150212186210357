import { jwtDecode } from 'jwt-decode';
var screenReloded = sessionStorage.getItem('screenReloded') === 'true';

export function getDecodedToken() {
    const token = localStorage.getItem('token');
    if (!token || token === 'undefined') {
        if (!screenReloded) {
            screenReloded = true;
            sessionStorage.setItem('screenReloded', 'true');
        }
        return null;
    }

    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null; 
    }
}

//const getRelativeTime = (date) => {
//    const now = new Date();
//    const diffInSeconds = Math.floor((now - date) / 1000);
//    const diffInMinutes = Math.floor(diffInSeconds / 60);
//    const diffInHours = Math.floor(diffInMinutes / 60);

//    if (diffInSeconds < 60) {
//      return `${diffInSeconds}s ago`;
//    } else if (diffInMinutes < 60) {
//      return `${diffInMinutes}m ago`;
//    } else if (diffInHours < 3) {
//        return `${diffInHours}h ago`;
//      } else {
//      return null;
//    }
//  };

//  // Function to format time
//  const formatTime = (date) => {
//    const hours = date.getHours();
//    const minutes = date.getMinutes();
//    const ampm = hours >= 12 ? 'PM' : 'AM';
//    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
//    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
//    return `${formattedHours}:${formattedMinutes} ${ampm}`;
//  };

//  // Function to format date
//  const formatDate = (date) => {
//    const options = { year: 'numeric', month: 'long', day: 'numeric' };
//    return date.toLocaleDateString(undefined, options);
//  };

//  // Main formatting function
//  export function formatDateString(dateString) {
//    const date = new Date(dateString);
//    const now = new Date();
//    const isToday = date.toDateString() === now.toDateString();

//    if (isToday) {
//      const relativeTime = getRelativeTime(date);
//      if (relativeTime) {
//        return relativeTime;
//      }
//      return formatTime(date);
//    } else {
//      const yesterday = new Date();
//      yesterday.setDate(now.getDate() - 1);
//      if (date.toDateString() === yesterday.toDateString()) {
//        return `Yesterday, ${formatTime(date)}`;
//      }
//      return `${formatDate(date)}, at ${formatTime(date)}`;
//    }
//  };

// Helper function to get relative time
const getRelativeTime = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInSeconds < 60) {
        return `${diffInSeconds}s ago`;
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes}m ago`;
    } else if (diffInHours < 3) {
        return `${diffInHours}h ago`;
    } else {
        return null;
    }
};

// Helper function to format time
const formatTime = (date) => {
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    return new Intl.DateTimeFormat(undefined, options).format(date);
};

// Helper function to format date
const formatDate = (date) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return new Intl.DateTimeFormat(undefined, options).format(date);
};

// Main formatting function
export function formatDateString(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
        const relativeTime = getRelativeTime(date);
        if (relativeTime) {
            return relativeTime;
        }
        return formatTime(date);
    } else {
        const yesterday = new Date();
        yesterday.setDate(now.getDate() - 1);
        if (date.toDateString() === yesterday.toDateString()) {
            return `Yesterday, ${formatTime(date)}`;
        }
        return `${formatDate(date)}, at ${formatTime(date)}`;
    }
}
