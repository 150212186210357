import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListSupplierOrders from "../SupplierOrders/ListSupplierOrders";
import ManageSupplierOrder from "../SupplierOrders/ManageSupplierOrder";
import { GetSupplier, GetSupplierOrders } from '../../../../api/HttpService'
import { getDecodedToken } from '../../../../utils/utils';

const ViewSupplierAccount = (props) => {
    const [permissions, setPermissions] = useState([]);
    var pId = useParams();
    var navigate = useNavigate();

    const [account, setAccount] = useState({
        supplierAccountId: 0,
        supplierAccount1: ""
    })

    const [companyId, setCompanyId] = useState(0);
    const [supplier, setSupplier] = useState({});
    const [orders, setOrders] = useState([]);



    const [showSupplierOrderList, setShowSupplierOrderList] = useState(1)
    const [supplierOrderId, setSupplierOrderId] = useState(0)



    useEffect(() => {

        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        if (props.isNew == true) {
            setShowSupplierOrderList(2);
        }

        if (pId.AccountId != "0") {
            GetSupplier(pId.SupplierId).then(supplier => {
                if (!supplier) {
                    alert('Failed to load supplier');
                    return;
                }

                setSupplier(supplier);
                setCompanyId(supplier.companyId)

                if (supplier.accounts.length) {
                    setAccount(supplier.accounts.find(x => x.supplierAccountId == pId.AccountId));
                    document.title = supplier.supplierName + ' - ' + supplier.accounts.find(x => x.supplierAccountId == pId.AccountId).supplierAccount1 + " Orders";
                }
            })

            GetSupplierOrders(pId.AccountId).then(orders => {
                setOrders(orders)
            });
        }
        else {
            navigate("/Accounting/Supplier/View/" + pId.SupplierId)
        }

    }, [])
    return (
        <>
            {permissions.viewSupplierStatement == null || permissions.viewSupplierStatement == false ? (
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <h1>You Have No Permission</h1>
                            </div>
                        </div>
                    </div>
                </div>
            ) :
                (
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Suppliers</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                            <li className="breadcrumb-item">
                                                <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplier.supplierName}</Link></li>
                                            <li className="breadcrumb-item"><a href="#">Account</a></li>
                                            <li className="breadcrumb-item active">{account.supplierAccount1}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mb-3 row">
                                                <div className="col-12 col-md-6">
                                                    <h4 className="card-title">Account: {account.supplierAccount1}</h4>
                                                </div>
                                                <div className="col-12 col-md-6 text-right">
                                                    <Link to={"/Accounting/Supplier"} className="btn btn-secondary mr-3">Cancel</Link>
                                                </div>

                                            </div>
                                            <div className="mb-3 row">
                                                <hr></hr>
                                                <div className="col-12">
                                                    {account.supplierAccountId != 0 && (<>
                                                        {showSupplierOrderList == 1 &&
                                                            (<ListSupplierOrders
                                                                orders={orders}
                                                                supplierId={pId.SupplierId}
                                                                setShowSupplierOrderList={setShowSupplierOrderList}
                                                                setSupplierOrderId={setSupplierOrderId}
                                                                supplierAccountId={account.supplierAccountId} />
                                                            )}
                                                        {showSupplierOrderList == 2 &&
                                                            (<ManageSupplierOrder
                                                                supplierId={pId.supplierId}
                                                                companyId={companyId}
                                                                supplierAccountId={account.supplierAccountId}
                                                                supplierOrderId={supplierOrderId}
                                                                setSupplierOrderId={setSupplierOrderId}
                                                                setShowSupplierOrderList={setShowSupplierOrderList} />)}
                                                    </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }
        </>
    )
}
export default ViewSupplierAccount