import { Global } from '../Variables/Global';
import axiosInstance from './axiosConfig';
// const axios = require('axios').default;
// const token = localStorage.getItem('token');
// axios.defaults.headers.Authorization = `Bearer ${token}`;

export const Get = (params = {}) => {
    var config = {
        method: 'get',
        url: "Customer/GetCustomers",
        headers: {},
        params,
    };

    return axiosInstance(config).then(response => response.data);
}

export const GetCustomerOrderCount = () => {
    var config = {
        method: 'get',
        url: "CustomerOrder/GetCustomerOrderSum/" + localStorage.getItem("C0m8N!"),
        headers: {},
    };

    return axiosInstance(config).then(response => response.data);
}