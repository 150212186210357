import React, { useEffect, useState } from "react";
import { Global } from '../../../Variables/Global';
import { GetCurrencies } from '../../../api/HttpService'
import { FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Card, CardBody, Col, Row } from "reactstrap"


const Supplier = () => {

    const pId = useParams();
    const [supplier, setSupplier] = useState([]);
    const navigate = useNavigate();

    const [supplierName, setSupplierName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [loadingPort, setLoadingPort] = useState("");
    const [country, setCountry] = useState("");

    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers",
            headers: {},
            params: { search, accountSearch, invoiceNumber, currency }
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplier(response.data);
                if (selectedSupplierRow != null && selectedSupplierRow.supplierId > 0 && response.data != null && response.data.length > 0) {
                    const row = response.data.filter(function (item) {
                        return item.supplierId == selectedSupplierRow.supplierId;
                    })[0];
                    setSelectedSupplierRow(row);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [showAccountModal, setShowAccountModal] = useState(false)
    const [accountName, setAccountName] = useState("")
    const [currencyId, setCurrencyId] = useState(0)
    const [currencies, setCurrencies] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [accountSearch, setAccountSearch] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [search, setSearch] = useState("");
    const [currency, setCurrency] = useState("");

    const handleCloseAccountModal = () => {
        setShowAccountModal(false)
        setEditingAccount(false)
    }
    const handleAddAccount = (supplier) => {
        setSelectedSupplier(supplier);
        console.log(supplier)
        setAccountName('');
        setCurrencyId(0);
        setShowAccountModal(true);
    }

    const submitForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValid()) {
            const body = {
                accountName: accountName,
                currencyId: currencyId,
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!")
            }
            if (!editingAccount) {
                var config = {
                    method: 'post',
                    url: "SupplierAccount/AddSupplierAccount/" + selectedSupplier.supplierId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Supplier Account Added Successfully");
                            GetSuppliers();
                            handleCloseAccountModal(false)
                            setSubmitDisabled(false);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                        setSubmitDisabled(false);
                    });
            }
            else { // Update Supplier Account
                var config = {
                    method: 'put',
                    url: "SupplierAccount/EditSupplierAccount/" + editedSupplierAccountId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Account Updated Successfully")
                            GetSuppliers();
                            handleCloseAccountModal(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (accountName == "" && currencyId == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (accountName == "") {
            toast.error("Enter Account Name");
            return false;
        }
        else if (currencyId == "") {
            toast.error("Enter Currency");
            return false;
        }
        return true;
    }

    const submitSupplierForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidSupplier()) {
            const body = {
                supplierName: supplierName,
                phone: phoneNumber,
                address: address,
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!"),
                country: country,
                loadingPort: loadingPort
            }
            if (localStorage.getItem("S0m8N!") === null) { //Add Product Size
                var config = {
                    method: 'post',
                    url: "Supplier/AddSupplier",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Supplier Added Successfully")
                            navigate("/Accounting/Supplier/View/" + response.data)
                            setSubmitDisabled(false);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                        setSubmitDisabled(false);
                    });
            }
            else { // Update Supplier
                var config1 = {
                    method: 'put',
                    url: "Supplier/EditSupplier/" + localStorage.getItem("S0m8N!"),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config1)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Supplier Updated Successfully")
                            handleCloseSupplierModal();
                            GetSuppliers();
                            setSubmitDisabled(false);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                        setSubmitDisabled(false);
                    });
            }
        }
        else {
            setSubmitDisabled(false);
        }
    }

    const IsValidSupplier = () => {

        // Check if any of the required fields are empty or null
        if (
            supplierName === "" ||
            phoneNumber === "" ||
            address === "" ||
            country === "" ||
            loadingPort === "" ||
            country === null ||
            loadingPort === null
        ) {
            toast.error("Enter all required fields");
            return false;
        }

        return true;
    }

    const [editingAccount, setEditingAccount] = useState(false)
    const [editedSupplierAccountId, setEditedSupplierAccountId] = useState(0);
    const [permissions, setPermissions] = useState([]);

    const handleEdit = (accountId) => {
        setEditingAccount(true);
        setEditedSupplierAccountId(accountId);
        GetSupplierAccount(accountId)
        setShowAccountModal(true);
    }

    const [supplierAccount, setSupplierAccount] = useState({})
    const GetSupplierAccount = (accountId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'SupplierAccount/GetSupplierAccount/' + accountId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierAccount(response.data);
                setAccountName(response.data.supplierAccount1)
                setCurrencyId(response.data.currencyId)

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [showSupplierModal, setShowSupplierModal] = useState(false)
    const handleCloseSupplierModal = () => {
        setShowSupplierModal(false)
        setSupplierName("")
        setPhoneNumber("")
        setAddress("")
        setLoadingPort("")
        setCountry("")
        localStorage.removeItem("S0m8N!")
    }

    const GetSupplier = (supplierId) => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers/" + supplierId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierName(response.data.supplierName)
                setPhoneNumber(response.data.phone)
                setAddress(response.data.address)
                setLoadingPort(response.data.loadingPort)
                setCountry(response.data.country)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleViewAllOrders = () => {
        // Your logic to handle viewing all orders for all suppliers
        console.log("Viewing all orders for all suppliers");

        navigate("/Accounting/Supplier/Orders");
    };

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        GetCurrencies().then(currencies => setCurrencies(currencies))
        document.title = "Suppliers"
    }, [])

    useEffect(() => {
        GetSuppliers();
    }, [search, accountSearch, invoiceNumber, currency]);

    const [selectedSupplierRow, setSelectedSupplierRow] = useState({})

    const columns = [
        {
            name: 'List of Suppliers',
            selector: row => (
                <div
                    onClick={() => setSelectedSupplierRow(row)}
                    className="w-full p-3 hover:bg-gray-100 transition duration-300"
                    style={{ cursor: 'pointer' }}
                >
                    <div className="w-full">
                        <strong className="text-wrap text-lg text-gray-800">{row.supplierName}</strong>
                        {
                            ((row.country != null && row.country.length > 0) || (row.loadingPort != null && row.loadingPort.length > 0)) &&
                            <>
                                <br />
                                <span className="text-wrap" style={{ color: "#777777" }}>
                                    {row.country} - {row.loadingPort}
                                </span>
                            </>
                        }

                    </div>
                </div>
            ),
            conditionalCellStyles: [
                {
                    when: row => row.supplierId === selectedSupplierRow.supplierId,
                    style: {
                        backgroundColor: '#CFF4FC',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: (
                // <div onClick={handleViewAllOrders} style={{ cursor: 'pointer' }}>
                //     View All Orders
                // </div>
                <></>
            ),
            selector: row => (
                <div className="align-items-center justify-content-end" style={{ cursor: 'pointer' }}>
                    <i className="ti-angle-right"></i>
                </div>
            ),
            conditionalCellStyles: [
                {
                    when: row => row.supplierId === selectedSupplierRow.supplierId,
                    style: {
                        backgroundColor: '#CFF4FC',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
            width: "50px",
        },
    ];

    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer', // Set cursor to pointer to indicate clickable rows
            },
        },
    };

    return (
        <>
            {permissions.viewSupplier == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) :
                (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>Suppliers</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Suppliers</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>

                                        <select style={{ maxWidth: '150px' }} className="form-control" onChange={(e) => setCurrency(e.target.value)} required>
                                            <option value="0" key="0">-- Currency --</option>
                                            {currencies.length > 0 && (
                                                currencies.map((c, index) => {
                                                    return (<>
                                                        <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                                    </>)
                                                })
                                            )}
                                        </select>

                                        <input className='search-expand form-control ml-1' placeholder='Search by name, phone, and address' type="search" name="search" onChange={(e) => setSearch(e.target.value)} />
                                        <input className='search-expand form-control ml-1' placeholder='Search by account name' type="search" name="accountSearch" onChange={(e) => setAccountSearch(e.target.value)} />
                                        <input className='search-expand form-control ml-1' placeholder='Search by Invocie Number or Notes' type="search" name="invoiceNumber" onChange={(e) => setInvoiceNumber(e.target.value)} />
                                        {permissions.addSupplier == true && (
                                            <button onClick={() => {
                                                setShowSupplierModal(true);
                                                localStorage.removeItem("S0m8N!")
                                            }
                                            } className="ml-1 btn btn-primary"><FiPlus /> Add Supplier</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ height: '75vh' }}>
                                {/* Suppliers List */}
                                <div className="col-sm-5" style={{ height: '100%', overflowY: 'auto' }}>
                                    <DataTableExtensions
                                        columns={columns}
                                        data={supplier}
                                        print={false}
                                        export={false}>
                                        <DataTable
                                            onRowClicked={(row) => setSelectedSupplierRow(row)}
                                            noHeader
                                            pagination
                                            paginationPerPage={50}
                                            paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                            highlightOnHover
                                            customStyles={customStyles}
                                        />
                                    </DataTableExtensions>
                                </div>
                                {
                                    selectedSupplierRow != null && selectedSupplierRow.supplierId > 0 &&
                                    <div className="col-sm-7 mt-5" style={{ height: '100%', overflowY: 'auto' }}>
                                        {/* Accounts List */}

                                        <Row>
                                            <Col sm={12} className="flex">
                                                <Card>
                                                    <CardBody>
                                                        <table cellPadding={10}>
                                                            <tr>
                                                                <td>
                                                                    <div role="alert">
                                                                        <div className="row">
                                                                            <div className="col-10">
                                                                                <h5 class="alert-heading font-18"><i class="fas fa-info-circle" /> Info</h5>
                                                                            </div>
                                                                            <div className='col-2 text-right'>
                                                                                <div className='flex justify-end'>
                                                                                    {permissions.editSupplier === true &&
                                                                                        <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                                                                            setShowSupplierModal(true);
                                                                                            // GetSupplier(selectedSupplierRow.supplierId);
                                                                                            setSupplierName(selectedSupplierRow.supplierName)
                                                                                            setPhoneNumber(selectedSupplierRow.phone)
                                                                                            setAddress(selectedSupplierRow.address)
                                                                                            setLoadingPort(selectedSupplierRow.loadingPort)
                                                                                            setCountry(selectedSupplierRow.country)
                                                                                            localStorage.setItem("S0m8N!", selectedSupplierRow.supplierId);
                                                                                        }}><FiEdit /> Edit</button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <table cellPadding={10}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div role="alert">
                                                                                                <div className="row">
                                                                                                    <div className="col-12">
                                                                                                        <p>
                                                                                                            Supplier Name: <b>{selectedSupplierRow.supplierName}</b>
                                                                                                            <br />
                                                                                                            Country: <b>{selectedSupplierRow.country}</b>
                                                                                                            <br />
                                                                                                            Port: <b>{selectedSupplierRow.loadingPort}</b>
                                                                                                            <br />
                                                                                                            Phone: <b>{selectedSupplierRow.phone}</b>
                                                                                                            <br />
                                                                                                            Address: <b>{selectedSupplierRow.address}</b>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-12">
                                                                                <h5 class="alert-heading font-18"><i class="mdi mdi-badge-account-horizontal" /> Accounts</h5>
                                                                            </div>
                                                                                {
                                                                                    selectedSupplierRow != null && selectedSupplierRow.accounts != null && selectedSupplierRow.accounts.map((account) => {
                                                                                        return (<>
                                                                                            <div class="col-12 mt-1 alert alert-info fade show" role="alert">
                                                                                                <div className="row">
                                                                                                    <div className="col-8">
                                                                                                        <h5 class="alert-heading font-18">{account.supplierAccount1}</h5>
                                                                                                    </div>
                                                                                                    <div className='col-4 text-right'>
                                                                                                        <div className='flex justify-end'>
                                                                                                            {permissions.viewSupplierOrders === true && (
                                                                                                                <button className='btn btn-info  btn-sm'
                                                                                                                    onClick={() => {
                                                                                                                        window.location = `/Accounting/Supplier/${selectedSupplierRow.supplierName}/${selectedSupplierRow.supplierId}/Account/${account.supplierAccount1}/${account.supplierAccountId}/Orders`
                                                                                                                    }}
                                                                                                                ><FiEye />{account.orderCount === 1 ? ` 1 Order` : ` ${account.orderCount} Orders`}</button>
                                                                                                            )}
                                                                                                            {permissions.viewSupplierStatement === true && (
                                                                                                                <button className='btn btn-success ms-1 btn-sm'
                                                                                                                    onClick={() => {
                                                                                                                        window.location = "/Accounting/Supplier/" + selectedSupplierRow.supplierId + "/Account/" + account.supplierAccountId + "/Statment";
                                                                                                                    }}
                                                                                                                ><FiEye /> Statement</button>
                                                                                                            )}
                                                                                                            {permissions.editSupplier === true && (
                                                                                                                <button className='btn btn-warning ms-1 btn-sm'
                                                                                                                    onClick={() => {
                                                                                                                        handleEdit(account.supplierAccountId)
                                                                                                                    }}
                                                                                                                ><FiEdit /> Edit</button>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <br />
                                                                                                    <span className="text-secondary">Currency: <b>{account.currency.currencySuffix}</b></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>)
                                                                                    })
                                                                                }
                                                                            {permissions.addSupplier === true && (
                                                                                <button
                                                                                    disabled={submitDisabled}
                                                                                    onClick={() => {
                                                                                        handleAddAccount(selectedSupplierRow)
                                                                                    }} className="btn btn-primary mb-2 mt-3" style={{ width: '100%' }} ><b><FiPlus /> Create New Account</b></button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </>)
            }


            <Modal show={showAccountModal} onHide={handleCloseAccountModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <label className="col-md-6 col-form-label">Account Name</label>
                            <input className="form-control"
                                placeholder="Enter account name"
                                type="text"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                required />
                        </div>
                        <div className="col-md-6">
                            <label className="col-md-6 col-form-label">Currency</label>
                            <select className="form-control" value={supplierAccount.currencyId} onChange={(e) => setCurrencyId(e.target.value)} required disabled={editingAccount}>
                                <option value="0" key="0">-- Select Currency Name --</option>
                                {currencies.length > 0 && (
                                    currencies.map((c, index) => {
                                        return (<>
                                            <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                        </>)
                                    })
                                )}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAccountModal}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitDisabled} onClick={(e) => submitForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSupplierModal} onHide={handleCloseSupplierModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Supplier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Supplier Name</label>
                            <input className="form-control" type="text" value={supplierName} onChange={(e) => setSupplierName(e.target.value)} required />
                        </div>
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Phone Number</label>
                            <input className="form-control" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                        </div>
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Address</label>
                            <input className="form-control" type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </div>
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Country</label>
                            <input className="form-control" type="text" value={country} onChange={(e) => setCountry(e.target.value)} required />
                        </div>
                        <div className="col-md-12">
                            <label className="col-md-6 col-form-label">Loading Port</label>
                            <input className="form-control" type="text" value={loadingPort} onChange={(e) => setLoadingPort(e.target.value)} required />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSupplierModal}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitDisabled} onClick={(e) => submitSupplierForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
export default Supplier