import { Global } from '../Variables/Global';
import axiosInstance from './axiosConfig';

export const Get = (params = {}) => {
    var config = {
        method: 'get',
        url: "Customer/GetCustomersWithOrdersCount",
        headers: {},
        params,
    };

    return axiosInstance(config).then(response => response.data);
}