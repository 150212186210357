import { Route, Routes } from "react-router-dom";
import ListAddresses from "../Pages/Accounting/Customers/CustomerAddresses/ListAddresses";
import ManageAddresses from "../Pages/Accounting/Customers/CustomerAddresses/ManageAddresses";
import Customers from "../Pages/Accounting/Customers/Customers";
import ManageCustomers from "../Pages/Accounting/Customers/ManageCustomers";
import ViewCustomerOrder from "../Pages/Accounting/Customers/CustomerOrders/ViewCustomerOrder";
import Expenses from "../Pages/Accounting/Expenses/Expenses";
import ManageExpenses from "../Pages/Accounting/Expenses/ManageExpenses";
import ExpensesType from "../Pages/Accounting/ExpensesType/ExpensesType";
import ManageExpensesType from "../Pages/Accounting/ExpensesType/ManageExpensesType";
import ListCommercialInvoice from "../Pages/Accounting/Supplier/CommercialInvoice/ListCommercialInvoice";
import ManageCommercialInvoice from "../Pages/Accounting/Supplier/CommercialInvoice/ManageCommercialInvoice";
import ViewSupplier from "../Pages/Accounting/Supplier/Components/ViewSupplier";
import ManageSupplier from "../Pages/Accounting/Supplier/ManageSupplier";
import Supplier from "../Pages/Accounting/Supplier/Supplier";
import ViewSupplierAccount from "../Pages/Accounting/Supplier/SupplierAccounts/ViewSupplierAccount";
import ViewSupplierOrder from "../Pages/Accounting/Supplier/SupplierOrders/ViewSupplierOrder";
import Company from "../Pages/Company/Comapny";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Departments from "../Pages/HR/Departments/Departments";
import ManageDepartment from "../Pages/HR/Departments/ManageDepartment";
import Employees from "../Pages/HR/Employees/Employees";
import ManageEmployee from "../Pages/HR/Employees/ManageEmployee";
import Permissions from "../Pages/HR/Employees/Permissions";
import ManagePosition from "../Pages/HR/Positions/ManagePosition";
import Positions from "../Pages/HR/Positions/Positions";
import ManageShippingCompanies from "../Pages/Logistics/ShippingCompanies/ManageShippingCompanies";
import ShippingCompany from "../Pages/Logistics/ShippingCompanies/ShippingCompanies";
import ManageProduct from "../Pages/Settings/Product/ManageProduct";
import Product from "../Pages/Settings/Product/Product";
import ManageSize from "../Pages/Settings/Size/ManageSize";
import Size from "../Pages/Settings/Size/Size";
import Orders from "../Pages/Sales/Orders";
import SalesOrder from "../Pages/Sales/SalesOrder";
import ManageOrders from "../Pages/Sales/ManageOrders";
import ManageType from "../Pages/Settings/Type/ManageType";
import Type from "../Pages/Settings/Type/Type";
import ManageUnit from "../Pages/Settings/Unit/ManageUnit";
import Unit from "../Pages/Settings/Unit/Unit";
import Currency from "../Pages/Settings/Currency/Currency";
import ManageCurrency from "../Pages/Settings/Currency/ManageCurrency";
import ListCustomerOrders from "../Pages/Accounting/Customers/CustomerOrders/ListCustomerOrders";
import ManageAccountCustomer from "../Pages/Accounting/Customers/CustomerAccounts/ManageAccountCustomer";
import ListAccountCustomer from "../Pages/Accounting/Customers/CustomerAccounts/ListAccountCustomer";
import ActiveOrders from "../Pages/Accounting/ActiveSalesOrders/ActiveOrders";
import ManageCustomerOrder from "../Pages/Accounting/Customers/CustomerOrders/ManageCustomerOrder";
import ViewCompany from "../Pages/Company/ViewCompany";
import AccountStatment from "../Pages/Accounting/Supplier/SupplierAccounts/Statment/AccountStatment";
import ListBanks from "../Pages/Accounting/Bank/ListBanks";
import CustomerAccountStatment from "../Pages/Accounting/Customers/CustomerAccounts/Statement/CustomerAccountStatement";
import ListOrderAdvancedPayments from "../Pages/Accounting/Customers/CustomerOrders/Payments/ListOrderAdvancedPayments";
import ListOrderAdvancedDiscount from "../Pages/Accounting/Customers/CustomerOrders/Discounts/ListOrderAdvancedDiscount";
import ListFreight from "../Pages/Logistics/Freights/ListFreight";
import ListProfits from "../Pages/Finance/Profits/ListProfits";
import ListBankStatments from "../Pages/Accounting/Bank/BankStatment/ListBankStatments";
import ShippingLines from "../Pages/Logistics/Tracking/ShippingLines";
import Shipments from "../Pages/Logistics/Tracking/Shipments";
import ShipmentDetails from "../Pages/Logistics/Tracking/ShipmentDetails";
import ShipmentLiveTracking from "../Pages/Logistics/Tracking/ShipmentLiveTracking";
import CustomerAccountDueTable from "../Pages/Accounting/Customers/CustomerAccounts/Statement/CustomerDueTable";
import React, { useEffect, useState } from "react";
import Forwarder from "../Pages/Accounting/Forwarders/Forwarder";
import ListProfitsByClientAccount from "../Pages/Finance/Profits/ListProfitsByClientAccount";
import ForwarderAccountStatment from "../Pages/Accounting/Forwarders/ForwarderAccountStatment";
import InvoicingCustomers from "../Pages/Invoicing/Customers";
import Invoices from "../Pages/Invoicing/Invoices";
import BankChargesTypes from "../Pages/Settings/BankChargesTypes/BankChargesTypes";
import PrintInvoice from "../Pages/Invoicing/PrintInvoice";
import { getDecodedToken } from '../utils/utils';
import ManageCompany from "../Pages/Company/ManageCompany";
import ManageCompanyAddress from "../Pages/Company/ManageCompanyAddress";
import CustomerInvoices from "../Pages/Invoicing/CustomerInvoices";
import ShippingCostsTypes from "../Pages/Settings/ShippingCostsTypes/ShippingCostsTypes";
import ListSupplierOrders from "../Pages/Accounting/Supplier/SupplierOrders/ListSupplierOrders";
import ListAllSuppliersOrders from "../Pages/Accounting/Supplier/SupplierOrders/ListAllSuppliersOrders";
import NotificationsCenter from "../Pages/NotificationsCenter/NotificationsCenter";
import SubmittedOrders from "../Pages/Sales/SubmittedOrders";

const PrivateRoutes = () => {
    const [userPermissions, setUserPermissions] = useState({});
    const [defaultScreen, setDefaultScreen] = useState(0);


    useEffect(() => {
        var ds = localStorage.getItem("Def@ult$creeN");
        setDefaultScreen(ds);
        setTimeout(() => {
            const decodedToken = getDecodedToken();
            const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
            var x = JSON.parse(pJoin);
            setUserPermissions(() => x);
        }, 500);
    }, [])
    return (<Routes>
        <Route path="/" element={
            defaultScreen == 1 ? <Dashboard />
                : defaultScreen == 2 ? <Supplier />
                    : defaultScreen == 3 ? <Customers />
                        : defaultScreen == 4 ? <ListBanks />
                            : defaultScreen == 5 ? <Shipments />
                                : defaultScreen == 6 ? <Orders />
                                    : defaultScreen == 7 ? <ActiveOrders />
                                        : defaultScreen == 8 ? <InvoicingCustomers />
                                            : userPermissions.viewDashboard == true ? <Dashboard /> : <Shipments />
        } />
        <Route path="/Dashboard" element={<Dashboard />} />
        {/* HR */}
        <Route path="/HR/Employees" element={<Employees />} />
        <Route path="/HR/Employee/:EmployeeId" element={<ManageEmployee />} />
        <Route path="/HR/Employee/Permission/:PermissionId" element={<Permissions />} />

        <Route path="/HR/Departments" element={<Departments />} />
        <Route path="/HR/Departments/:DepartmentId" element={<ManageDepartment />} />

        <Route path="/HR/Positions" element={<Positions />} />
        <Route path="/HR/Positions/:PositionId" element={<ManagePosition />} />

        {/* Sales */}
        <Route path="/Sales/Orders" element={<Orders />} />
        <Route path="/Sales/SubmittedOrders" element={<SubmittedOrders />} />
        <Route path="/Sales/Orders/:OrderId" element={<ManageOrders />} />
        <Route path="/Sales/SalesOrder" element={<SalesOrder />} />
        <Route path="/Sales/SalesOrder/:SalesOrderId" element={<SalesOrder />} />

        {/* Logistics */}
        <Route path="/Logistics/Forwarder" element={<ShippingCompany />} />
        <Route path="/Logistics/Forwarder/:ShippingCompanyId" element={<ManageShippingCompanies />} />
        <Route path="/Logistics/ShippingLines" element={<ShippingLines />} />
        <Route path="/Logistics/Shipments" element={<Shipments />} />
        <Route path="/Logistics/ShipmentDetails/:TrackingInfoId" element={<ShipmentDetails />} />
        <Route path="/Logistics/ShipmentLiveTracking/:ContainerCode" element={<ShipmentLiveTracking />} />


        {/*Invoicing*/}
        <Route path="/Invoicing/CustomerInvoices" element={<CustomerInvoices />} />


        {/* Accounting */}
        <Route path="/Accounting/Banks" element={<ListBanks />} />
        <Route path="/Accounting/Banks/Statment/:BankId/Account/:BankAccountId" element={<ListBankStatments />}></Route>

        <Route path="/Accounting/Supplier" element={<Supplier />} />
        <Route path="/Accounting/ActiveOrders" element={<ActiveOrders />} />
        <Route path="/Accounting/Supplier/:SupplierId" element={<ManageSupplier />} />
        <Route path="/Accounting/Supplier/View/:SupplierId" element={<ViewSupplier />} />
        <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId" element={<ViewSupplierAccount />} />
        {/* <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId/Orders" element={<ListSupplierOrders />} /> */}
        <Route path="/Accounting/Supplier/:SupplierName/:SupplierId/Account/:SupplierAccountName/:AccountId/Orders" element={<ListSupplierOrders />} />
        <Route path="/Accounting/Supplier/Orders" element={<ListAllSuppliersOrders />} />

        <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId/Add" element={<ViewSupplierAccount isNew={true} />} />
        <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId/Statment" element={<AccountStatment />} />
        <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId/Order/:OrderId" element={<ViewSupplierOrder />} />
        <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId/Order/:OrderId/CommercialInvoices" element={<ListCommercialInvoice />} />
        <Route path="/Accounting/Supplier/:SupplierId/Account/:AccountId/Order/:OrderId/CommercialInvoices/:InvoiceId" element={<ManageCommercialInvoice />} />

        <Route path="/Accounting/Customer" element={<Customers />} />
        <Route path="/Accounting/Customer/:CustomerId" element={<ManageCustomers />} />
        <Route path="/Accounting/Customer/:CustomerId/Addresses" element={<ListAddresses />} />
        <Route path="/Accounting/Customer/:CustomerId/Addresses/:AddressId" element={<ManageAddresses />} />
        <Route path="/Accounting/Customer/View/:CustomerId" element={<ListAccountCustomer />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId" element={<ManageAccountCustomer />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId/Statment" element={<CustomerAccountStatment />} />
        <Route path="/Accounting/Customer/:CustomerName/:CustomerId/Account/:CustomerAccountName/:AccountId/Orders" element={<ListCustomerOrders />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId/Order/:OrderId" element={<ManageCustomerOrder />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId/View/Order/:OrderId" element={<ViewCustomerOrder />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId/Payments" element={<ListOrderAdvancedPayments />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId/Discounts" element={<ListOrderAdvancedDiscount />} />
        <Route path="/Accounting/Customer/:CustomerId/Account/:AccountId/DueTable" element={<CustomerAccountDueTable />} />
        <Route path="/Accounting/Forwarders/:ForwarderId/Account/:AccountId/Statment" element={<ForwarderAccountStatment />} />

        <Route path="/Accounting/Expenses" element={<Expenses />} />
        <Route path="/Accounting/Expenses/:ExpensesId" element={<ManageExpenses />} />

        <Route path="/Logistics/Freights" element={<ListFreight />} />
        <Route path="/Accounting/Forwarders" element={<Forwarder />} />

        <Route path="/Accounting/ExpensesType" element={<ExpensesType />} />
        <Route path="/Accounting/ExpensesType/:ExpensesTypeId" element={<ManageExpensesType />} />

        {/* Settings */}
        <Route path="/Settings/Product" element={<Product />} />
        <Route path="/Settings/Product/:ProductId" element={<ManageProduct />} />
        <Route path="/Settings/Size" element={<Size />} />
        <Route path="/Settings/Size/:SizeId" element={<ManageSize />} />
        <Route path="/Settings/Type" element={<Type />} />
        <Route path="/Settings/Type/:TypeId" element={<ManageType />} />
        <Route path="/Settings/Unit" element={<Unit />} />
        <Route path="/Settings/Unit/:UnitId" element={<ManageUnit />} />
        <Route path="/Settings/Currency" element={<Currency />} />
        <Route path="/Settings/Currency/:CurrencyId" element={<ManageCurrency />} />
        <Route path="/Settings/ShippingCostsOptions" element={<ShippingCostsTypes />} />
        <Route path="/Company" element={<Company />} />
        <Route path="/Company/:CompanyId" element={<ManageCompany />} />
        <Route path="/Company/:CompanyId/:CompanyAddressId" element={<ManageCompanyAddress />} />
        <Route path="/Company/View/:CompanyId" element={<ViewCompany />} />
        <Route path="/Settings/BankChargesTypes" element={<BankChargesTypes />} />

        {/* Profits */}
        <Route path="/Finance/Profits" element={<ListProfits />} />
        <Route path="/Finance/Profits/:ClientAccountId" element={<ListProfitsByClientAccount />} />


        {/* Invoicing */}
        <Route path="/Invoicing/Customers" element={<InvoicingCustomers />} />
        <Route path="/Invoicing/Customers/:CustomerId/Invoices/:CustomerName" element={<Invoices />} />
        <Route path="/Invoicing/:CustomerName/:CustomerId/PrintInvoice/:InvoiceType/:PrintInvoiceId" element={<PrintInvoice />} />

        {/* Notifications Center */}
        <Route path="/NotificationsCenter" element={<NotificationsCenter />} />
    </Routes>)
}
export default PrivateRoutes;