
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const Departments = () => {
     
    const [departments, setDepartments] = useState();

    const columns = [
        {
            name: '#',
            selector: row => row.departmentId,
            width: "60px",
        },
        {
            name: 'Department Name',
            selector: row => row.departmentName,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {permissions.editDepartment == true && (
                            <Link to={"/hr/departments/" + row.departmentId} className="btn btn-outline-warning mr-3">
                                <FiEdit />
                            </Link>)}
                        {permissions.deleteDepartment == true && (
                            <button type="button" className="btn btn-outline-danger">
                                <FiTrash2 />
                            </button>)}
                    </>
                )
            },
        },
    ];

    const GetDepartments = () => {
        var config = {
            method: 'get',
            url: "Department/GetDepartments",
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setDepartments(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        document.title = "Departments";
        GetDepartments();
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
    }, [])
    return (
        <>
            {permissions.viewDepartment == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4>Departments</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Departments</li>
                                        </ol>
                                    </div >
                                </div >
                            </div >
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>
                                                    <h4 className="card-title">List Departments</h4>
                                                </div>
                                                <div className='col-12 col-md-6 text-right'>
                                                    {permissions.addDepartment == true && (
                                                        <Link to="/HR/Departments/0" className="btn btn-primary">Add Department</Link>
                                                    )}
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={departments}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >

                    </div >
                </>
            )}

        </>
    )
}
export default Departments