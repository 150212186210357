// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal} from "react-bootstrap";
import { UncontrolledTooltip } from "reactstrap";

import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { toast } from "react-toastify";
import { FiPlus, FiLink } from 'react-icons/fi';
import { Global } from "../../../Variables/Global";
import { formatDate } from "../../../Classes/Util";
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const ListFreight = () => {
     
    const [freights, setFreights] = useState([]);

    const [freight, setFreight] = useState({
        supplierId: 0,
        supplierProformaId: 0,
        clientInvoiceId: 0,
        clientInvoiceNumber: "",
        clientId: 0,
        clientName: "",
        supplierCommercialInvoiceId: 0,
        forwarderInvoice: "",
        forwarderId: 0,
        forwarderAccountId: 0,
        currencyId: 0,
        currencySuffix: "",
        amount: 0.0,
        date: formatDate(new Date()),
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        supplierAccountCurrencyId: 0,
        supplierAccountCurrencySuffix: "",
        exchangeRate: 1.0,
        notes: "",
        isCredit: false
    });
    
    const invoiceTypes = [
        {
            id: 1,
            label: "Proforma"
        },
        {
            id: 2,
            label: "Commercial Invoice"
        },
    ];
    const [selectedInvoiceType, setSelectedInvoiceType] = useState();
    
    const GetFreights = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "Freight/GetFreights",
            headers: {},
            params: { companyId }
        };
        axiosInstance(config)
            .then(function (response) {
                setFreights(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliersWithoutFreight/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [forwarderAccounts, setForwarderAccounts] = useState([])
    const [forwarders, setForwarders] = useState([])
    const GetForwarders = () => {
        var config = {
            method: 'get',
            url: "Forwarder/GetForwarders?companyId=" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setForwarders(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSupplierChange = (supplierId) => {
        GetSupplierCommercialInvoicesBySupplierId(supplierId);
        GetSupplierProformasSupplierId(supplierId);
    }

    const handleSupplierProformaChange = (supplierOrderId, eraseEditinigShipment) => {
        GetClientProformasForSupplierProformaId(supplierOrderId);
        setFreight((prevState) => ({ ...prevState, 
            supplierCommercialInvoiceId: 0,
            clientInvoiceId: 0,
            clientInvoiceNumber: "",
            clientId: 0,
            clientName: "",
        }));
        
        // if(eraseEditinigShipment === true) {
        //     setEditingShipment((prevState) => ({ ...prevState, 
        //         supplierCommercialInvoiceId: 0,
        //         clientInvoiceId: 0,
        //         clientInvoiceNumber: "",
        //         clientId: 0,
        //         clientName: "",
        //     }));
        // }
    }

    const [supplierProformas, setSupplierProformas] = useState([])
    const GetSupplierProformasSupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierProformasWithoutFreightBySupplierId/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierProformas(response.data);
                // props.setProducts(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [clientProformas, setClientProformas] = useState([])
    const GetClientProformasForSupplierProformaId = (supplierProformaId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrdersFromSupplierProformaId/" + supplierProformaId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setClientProformas(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [supplierInvoices, setSupplierInvoices] = useState([])
    const GetSupplierCommercialInvoicesBySupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoicesWithoutFreightBySupplierId",
            headers: {},
            params: {
                "supplierId": supplierId,
                "isCreditFreight": isCreditFreight
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierInvoices(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const submitFreightForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidFreight()) {
            // if (localStorage.getItem("S0m8N!") === null) { //Add Product Size
                var config = {
                    method: 'post',
                    url: "Freight/AddFreight",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: freight
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Freight Added Successfully")
                        handleCloseFreightModal();
                        setFreights(response.data);
                        setSubmitDisabled(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                        setSubmitDisabled(false);
                    });
        }
    }

    const IsValidFreight = () => {
        if (freight.supplierId === 0 && freight.supplierCommercialInvoiceId === 0 && freight.forwarderId === 0 && freight.forwarderAccountId === 0 && freight.forwarderInvoice === "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (freight.supplierId === 0) {
            toast.error("Select the supplier.");
            return false;
        }
        else if (freight.supplierCommercialInvoiceId === 0 && freight.supplierProformaId === 0) {
            toast.error("Select the invoice.");
            return false;
        }
        else if (freight.forwarderId === "") {
            toast.error("Select the forwarder.");
            return false;
        }
        else if (freight.forwarderAccountId === "") {
            toast.error("Select the forwarder account.");
            return false;
        }
        else if (freight.forwarderInvoice === "") {
            toast.error("Enter the forwarder invoice.");
            return false;
        } else if (freight.amount <= 0.0){
            toast.error("Enter the amount.");
            return false;
        }else if (freight.supplierCommercialInvoiceId === 0 && freight.supplierProformaId > 0 && freight.clientInvoiceId === 0) {
            toast.error("Select the client invoice.");
            return false;
        }
        return true;
    }

    const [sameCurrency, setSameCurrency] = useState(true)
    const [isCreditFreight, setIsCreditFreight] = useState(false)

    const [permissions, setPermissions] = useState([]);

    const [showFreightModal, setShowFreightModal] = useState(false)
    const handleCloseFreightModal = () => {
        setShowFreightModal(false);
    }

    const [linkFreightToCommercial, setLinkFreightToCommercial] = useState({
        freightId: 0,
        supplierCommercialInvoiceId: 0,
    });

    const [linkingCommercialForForwarderInvoice, setLinkingCommercialForForwarderInvoice] = useState('')
    const [showLinkCommercialModal, setShowLinkCommercialModal] = useState(false)
    const handleCloseLinkCommercialModal = () => {
        setShowLinkCommercialModal(false);
    }
    const handleLinkFreightToCommercial = (FreightId) => {
        GetSupplierCommercialInvoicesByFreightId(FreightId);
    }

    const [supplierCommercialInvoicesByFreight, setSupplierCommercialInvoicesByFreight] = useState([])
    const GetSupplierCommercialInvoicesByFreightId = (freightId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetUnFreightSupplierCommercialInvoicesByFreightId/" + freightId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoicesByFreight(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    const submitLinkFreightForm = (e) => {
        e.preventDefault();
            var config = {
                method: 'put',
                url: "Freight/LinkFreightToCommercialInvoice",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: linkFreightToCommercial
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Freight Linked Successfully")
                        handleCloseLinkCommercialModal();
                        GetFreights();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetFreights();
        GetSuppliers();
        GetForwarders();
        document.title = "Freights"
    }, [])

    const columns = [
        {
            name: '#',
            selector: row => row.freightId,
            width:"60px",
            style: {textAlign: 'center'}
        },
        {
            name: 'Forwarder',
            selector: row => {
                return (
                    <>
                        <div>
                            <span className="text-wrap">{row.forwarderName}</span>
                            <br />
                            <span className="text-wrap"><b>#{row.forwarderAccountName}</b></span>
                            {/* <span><Link to={`/Logistics/ShipmentLiveTracking/${row.containerNumber}`}><b>#{row.blReferenceNo}</b></Link></span> */}
                        </div>
                    </>
                )
            },
            width:"200px",
        },
        {
            name: 'Freight',
            selector: row => {
                return (
                    <>
                        <div>
                            <span className="text-wrap">{row.forwarderInvoice}</span>
                            <br />
                            <span className="text-wrap"><b>{row.currencySuffix} {row.amount.toLocaleString()}</b></span>
                            {/* <span><Link to={`/Logistics/ShipmentLiveTracking/${row.containerNumber}`}><b>#{row.blReferenceNo}</b></Link></span> */}
                        </div>
                    </>
                )
            },
            width:"160px",
        },
        {
            name: 'Invoice Date',
            selector: row => {
                return (
                    <>
                        <div>
                            <span className="text-wrap">{row.date.split("T")[0]}</span>
                            {/* <br />
                            <span><b>#{row.currencySuffix}{row.amount.toLocaleString()}</b></span> */}
                            {/* <span><Link to={`/Logistics/ShipmentLiveTracking/${row.containerNumber}`}><b>#{row.blReferenceNo}</b></Link></span> */}
                        </div>
                    </>
                )
            },
            width:"150px",
        },
        // {
        //     name: 'Status',
        //     selector: row => {
        //         if(row.statusId == 0){
        //             return (
        //                 <>
        //                     <span style={{color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>Pending</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 20){
        //             return (
        //                 <>
        //                     <span style={{color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 30){
        //             return (
        //                 <>
        //                     <span style={{color: '#292b2c', backgroundColor: '#d3d5d6', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 35){
        //             return (
        //                 <>
        //                     <span style={{color: '#41b6d9', backgroundColor: '#eff9fc', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 40){
        //             return (
        //                 <>
        //                     <span style={{color: '#936b16', backgroundColor: '#fdf0d3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 45){
        //             return (
        //                 <>
        //                     <span style={{color: '#35834f', backgroundColor: '#def8e6', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 50){
        //             return (
        //                 <>
        //                     <span style={{color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //         if(row.statusId == 60){
        //             return (
        //                 <>
        //                     <span style={{color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>{row.status}</b></span>
        //                 </>
        //             )
        //         }
        //     },
        // },
        // {
        //     name: 'Carrier',
        //     selector: row => row.shippingLine,
        // },
        {
            name: 'Supplier',
            selector: row => {
                return (
                    <>
                        {/* <div style={{textAlign: 'center'}}> */}
                        <div>
                            <span className="text-wrap">{row.supplierName}</span>
                            <br />
                            <span className="text-wrap"><b>#{row.supplierCommercialInvoice}</b></span>
                        </div>
                    </>
                )
            },
        },
        // {
        //     name: 'Supplier Invoice',
        //     selector: row => '#' + row.supplierInvoice,
        // },
        // {
        //     name: 'POL',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div >
        //                     <span>{row.pol}</span>
        //                     <br />
        //                     <span><b>{row.loadingDate}</b></span>
        //                 </div>
        //             </>
        //         )
        //     },
        //     // width: '20%',
        //     // style: {alignItems: 'center', textAlign: 'center'}
        // },
        // {
        //     name: 'POD',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div>
        //                     <span>{row.pod}</span>
        //                     <br />
        //                     <span><b>{row.arrivalDate}</b></span>
        //                 </div>
        //             </>
        //         )
        //     },
        // },
        {
            name: 'Client',
            selector: row => {
                return (
                    <>
                        {/* <div style={{textAlign: 'center'}}> */}
                        <div>
                            <span className="text-wrap">{row.clientName}</span>
                            <br />
                            <span className="text-wrap"><b>#{row.clientCommercialInvoice}</b></span>
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Notes',
            selector: row => {
                return (
                    <>
                        {/* <div style={{textAlign: 'center'}}> */}
                        <div>
                            <span className="text-wrap">{row.notes}</span>
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Status',
            selector: row => {
                // if(row.paid === true){
                    return (
                        <div className="mt-2 mb-2">
                            {
                                row.isCredit === true ?
                                <span style={{color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>Credit</b></span>
                                :
                                row.paid === true ?
                                <span style={{color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>Payment Settled</b></span>
                                :
                                <span style={{color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5}}><b>Payment Required</b></span>
                            }
                            {
                                row.hasCommercial === true && 
                                <>
                                    <br/>
                                    <button onClick={() => {
                                            setLinkFreightToCommercial((prevState) => ({ ...prevState, 
                                                freightId: row.freightId
                                            }))
                                            setLinkingCommercialForForwarderInvoice(row.forwarderInvoice);
                                            handleLinkFreightToCommercial(row.freightId);
                                            setShowLinkCommercialModal(true);
                                        }} className="btn btn-outline-primary btn-sm mt-1"><b><FiLink /> Add Commercial</b></button>
                                </>
                            }
                        </div>
                    )
            },
            width:"200px",
        }
    ];

    return (
        <>
            {permissions.viewFreight == null || permissions.viewFreight == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>List of Freights</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">List of Freights</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>
                                        {/* <button onClick={() => {
                                                reloadFreights();
                                            }} className="ml-1 btn btn-secondary"><FiRefreshCw/> Refresh</button> */}
                                        {permissions.addFreight == true && (
                                            <>
                                                <button onClick={() => {
                                                    setIsCreditFreight(true);
                                                    setFreight({
                                                        supplierId: 0,
                                                        supplierProformaId: 0,
                                                        clientInvoiceId: 0,
                                                        clientInvoiceNumber: "",
                                                        clientId: 0,
                                                        clientName: "",
                                                        supplierCommercialInvoiceId: 0,
                                                        forwarderInvoice: "",
                                                        forwarderId: 0,
                                                        forwarderAccountId: 0,
                                                        currencyId: 0,
                                                        currencySuffix: "",
                                                        amount: 0.0,
                                                        date: formatDate(new Date()),
                                                        userId: localStorage.getItem("uTsReD"),
                                                        companyId: localStorage.getItem("C0m8N!"),
                                                        supplierAccountCurrencyId: 0,
                                                        supplierAccountCurrencySuffix: "",
                                                        exchangeRate: 1.0,
                                                        notes: "",
                                                        isCredit: true
                                                    });
                                                    setShowFreightModal(true);
                                                }} className="ml-1 btn btn-secondary"><FiPlus/> Add Credit Freight</button>
                                                 <button onClick={() => {
                                                    setIsCreditFreight(false);
                                                    setFreight({
                                                        supplierId: 0,
                                                        supplierProformaId: 0,
                                                        clientInvoiceId: 0,
                                                        clientInvoiceNumber: "",
                                                        clientId: 0,
                                                        clientName: "",
                                                        supplierCommercialInvoiceId: 0,
                                                        forwarderInvoice: "",
                                                        forwarderId: 0,
                                                        forwarderAccountId: 0,
                                                        currencyId: 0,
                                                        currencySuffix: "",
                                                        amount: 0.0,
                                                        date: formatDate(new Date()),
                                                        userId: localStorage.getItem("uTsReD"),
                                                        companyId: localStorage.getItem("C0m8N!"),
                                                        supplierAccountCurrencyId: 0,
                                                        supplierAccountCurrencySuffix: "",
                                                        exchangeRate: 1.0,
                                                        notes: "",
                                                        isCredit: false
                                                    });
                                                    setShowFreightModal(true);
                                                }} className="ml-1 btn btn-primary"><FiPlus/> Add Freight</button>
                                            </>
                                        )}
                                    </div>
                                </div> 
                            </div>
                            {/* <div className="row" style={{height: 20}}>
                                <div className='col-sm-12 text-right' style={{marginTop: -20}}>
                                    <div className='flex justify-end'>
                                        {settings != null && settings.shipmentsLastUpdatedAt != null &&(
                                            <span>Last update: {dateFormat((settings.shipmentsLastUpdatedAt), "dddd, mmmm dS, yyyy  HH:MM")} (UTC)</span>
                                        )}
                                    </div>
                                </div> 
                            </div> */}
                            <div className="row">
                                <div className="col-12">
                                    {/* <DataTable columns={columns} data={freights} /> */}

                                    <DataTableExtensions
                                        columns={columns}
                                        data={freights}
                                        print={false}
                                        export={false}
                                        >
                                        <DataTable
                                            noHeader
                                            pagination
                                            paginationPerPage={50}
                                            paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                            highlightOnHover
                                        />
                                        </DataTableExtensions>




                                </div>
                            </div>
                            {/* {shipments.map((s) => (
                                <div key={s.id}>
                                    <div>

                                        <div className="flex justify-between items-center p-2">
                                            <span>
                                                <strong>{s.supplierName}</strong> - {' '}
                                                <span>{s.address + " - " + s.phone}</span>
                                            </span>

                                            <div className="flex">
                                                <button className='btn btn-primary btn-sm' onClick={() => handleAddAccount(s)}><FiPlus/> Add Account</button>
                                                <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                                    setShowSupplierModal(true);
                                                    GetSupplier(s.supplierId);
                                                    localStorage.setItem("S0m8N!", s.supplierId);
                                                }}><FiEdit /> Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <table className='table'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th width="40%">Account</th>
                                                    <th width="20%">Currency</th>
                                                    <th width="20%">Orders</th>
                                                    <th width="20%">Options</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {s.accounts.map(x => <tr key={x.id}>
                                                    <td>{x.supplierAccount1}</td>
                                                    <td>{x.currency.currencySuffix}</td>
                                                    <td>
                                                        <button className='btn btn-success btn-sm' onClick={() => window.location = `/Accounting/Supplier/${s.supplierId}/Account/${x.supplierAccountId}`}><FiEye />{x.orderCount > 0 ? (x.orderCount === 1 ? ` 1 Order` : ` ${x.orderCount} Orders`) : ' Add Orders'}</button>
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-primary btn-sm' onClick={() => window.location = "/Accounting/Supplier/" + s.supplierId + "/Account/" + x.supplierAccountId + "/Statment"}><FiEye /> Account Statement</button>
                                                        <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                                            handleEdit(x.supplierAccountId)
                                                        }}><FiEdit /> Edit</button>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Modal show={showAccountModal} onHide={handleCloseAccountModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Manage Account</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <label className="col-md-6 col-form-label">Account Name</label>
                                                    <input className="form-control" type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-md-6 col-form-label">Currency</label>
                                                    <select className="form-control" value={supplierAccount.currencyId} onChange={(e) => setCurrencyId(e.target.value)} required disabled={editingAccount}>
                                                        <option value="0" key="0">-- Select Currency Name --</option>
                                                        {currencies.length > 0 && (
                                                            currencies.map((c, index) => {
                                                                return (<>
                                                                    <option value={c.currencyId} key={index}>{c.currencySuffix + " - " + c.currencyName}</option>
                                                                </>)
                                                            })
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseAccountModal}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={(e) => submitForm(e)}>
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            ))} */}



                            <Modal size="lg"  show={showFreightModal} onHide={handleCloseFreightModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{isCreditFreight ? "Credit Freight" : "Freight"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="mb-3 row">
                                        <div className="col-6">
                                            <label>Supplier</label>
                                             <Select
                                                required
                                                placeholder="-- Select Supplier --"
                                                isSearchable={true}
                                                options={suppliers.map(c => ({
                                                    value: c,
                                                    label: c.supplierName
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setFreight((prevState) => ({ ...prevState, 
                                                        supplierId: e.value.supplierId
                                                    }))
                                                    handleSupplierChange(e.value.supplierId);
                                                }}>
                                            </Select>
                                        </div>
                                        <div className="col-6">
                                            <label>Invoice Type</label>
                                            <Select
                                                required
                                                placeholder="-- Select Option --"
                                                isSearchable={true}
                                                options={invoiceTypes.map(i => ({
                                                    value: i.id,
                                                    label: i.label
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setSelectedInvoiceType(
                                                       e.value
                                                    );
                                                }}>
                                            </Select>
                                        </div>
                                        {/* <div className="col-6">
                                            <label>Supplier Invoice</label>
                                            <Select
                                                required
                                                placeholder="-- Select Invoice --"
                                                isSearchable={true}
                                                options={supplierInvoices.map(i => ({
                                                    value: i,
                                                    label: "#" + i.invoiceNumber
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setFreight((prevState) => ({ ...prevState, 
                                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId,
                                                        supplierAccountCurrencyId: e.value.currencyId,
                                                        supplierAccountCurrencySuffix: e.value.currencySuffix
                                                    }))
                                                    // handleSupplierInvoiceChange(e.value);
                                                    setSameCurrency(freight.currencyId === 0 || e.value.currencyId === freight.currencyId);
                                                    if(sameCurrency){
                                                        setFreight((prevState) => ({ 
                                                            ...prevState, 
                                                            exchangeRate: 1.0,
                                                        }))
                                                    }
                                                }}>
                                            </Select>
                                        </div> */}
                                    </div>

                                    <div className="mb-3 row">
                                    {
                                            selectedInvoiceType == 1 &&
                                            <div className="col-6">
                                                <label>Supplier Proforma</label>
                                                <Select
                                                    required
                                                    placeholder="-- Select Invoice --"
                                                    isSearchable={true}
                                                    options={supplierProformas.map(i => ({
                                                        value: i,
                                                        label: "#" + i.invoiceNumber
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setFreight((prevState) => ({ ...prevState, 
                                                            supplierProformaId: e.value.sellOrderId,
                                                            supplierAccountCurrencyId: e.value.currencyId,
                                                            supplierAccountCurrencySuffix: e.value.currencySuffix
                                                        }));
                                                        handleSupplierProformaChange(e.value.sellOrderId, true);
                                                        // handleSupplierInvoiceChange(e.value);
                                                        setSameCurrency(freight.currencyId === 0 || e.value.currencyId === freight.currencyId);
                                                        if(sameCurrency){
                                                            setFreight((prevState) => ({ 
                                                                ...prevState, 
                                                                exchangeRate: 1.0,
                                                            }));
                                                        }
                                                    }}>
                                                </Select>
                                            </div>
                                        }
                                        {
                                            selectedInvoiceType == 2 &&
                                            <div className="col-6">
                                                <label>Supplier Commercial Invoice</label>
                                                <Select
                                                    required
                                                    placeholder="-- Select Invoice --"
                                                    isSearchable={true}
                                                    options={supplierInvoices.map(i => ({
                                                        value: i,
                                                        label: "#" + i.invoiceNumber
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setFreight((prevState) => ({ ...prevState, 
                                                            supplierCommercialInvoiceId: e.value.commercialInvoiceId,
                                                            supplierAccountCurrencyId: e.value.currencyId,
                                                            supplierAccountCurrencySuffix: e.value.currencySuffix
                                                        }))
                                                        setSameCurrency(freight.currencyId === 0 || e.value.currencyId === freight.currencyId);
                                                        if(sameCurrency){
                                                            setFreight((prevState) => ({ 
                                                                ...prevState, 
                                                                exchangeRate: 1.0,
                                                            }))
                                                        }
                                                    }}>
                                                </Select>
                                            </div>
                                        }
                                        {
                                            selectedInvoiceType === 1 && freight.supplierProformaId != null && freight.supplierProformaId > 0 &&
                                            <div className="col-6">
                                                <label>Client Proforma</label>
                                                <Select
                                                    required
                                                    placeholder="-- Select Client Invoice --"
                                                    isSearchable={true}
                                                    options={clientProformas.map(i => ({
                                                        value: i,
                                                        label: "#" + i.invoiceNumber + " - " + i.customerName
                                                    }))}
                                                    valuekey
                                                    onChange={(e) => {
                                                        setFreight((prevState) => ({ ...prevState, 
                                                            clientInvoiceId: e.value.customerOrderId,
                                                            clientInvoiceNumber: e.value.invoiceNumber,
                                                            clientId: e.value.customerId,
                                                            clientName: e.value.customerName,
                                                        }))
                                                    }}>
                                                </Select>
                                            </div>
                                        }
                                    </div>
                                    <br/>
                                    <div className="mb-3 row">

                                        <div className="col-6">
                                            <label>Forwarder</label>
                                            <Select
                                                required
                                                placeholder="-- Select Forwarder --"
                                                isSearchable={true}
                                                options={forwarders.map(f => ({
                                                    value: f,
                                                    label: f.name
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setFreight((prevState) => ({ ...prevState, 
                                                        // forwarderName: e.value.name,
                                                        forwarderId: e.value.forwarderId
                                                    }));
                                                    // handleSupplierInvoiceChange(e.value);
                                                    setForwarderAccounts(e.value.accounts);
                                                }}>
                                            </Select>
                                        </div>
                                        <div className="col-6">
                                            <label>Forwarder Account</label>
                                            <Select
                                                required
                                                placeholder="-- Select Forwarder Account--"
                                                isSearchable={true}
                                                options={forwarderAccounts.map(fa => ({
                                                    value: fa,
                                                    label: fa.name
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setFreight((prevState) => ({ ...prevState, 
                                                        // forwarderName: e.value.name,
                                                        forwarderAccountId: e.value.forwarderAccountId,
                                                        currencySuffix: e.value.currency.currencySuffix,
                                                        currencyId: e.value.currency.currencyId
                                                    }));
                                                    setSameCurrency(freight.supplierAccountCurrencyId === 0 || e.value.currency.currencyId === freight.supplierAccountCurrencyId);
                                                    if(sameCurrency){
                                                        setFreight((prevState) => ({ 
                                                            ...prevState, 
                                                            exchangeRate: 1.0,
                                                        }))
                                                    }
                                                    // handleSupplierInvoiceChange(e.value);
                                                    // setForwarderAccounts(e.value.accounts);
                                                }}>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-6">
                                            <label>Freight Invoice</label>
                                          {/* <label className="col-md-6 col-form-label">BL Reference Number</label> */}
                                            <input className="form-control" 
                                            type="text" 
                                            placeholder='Enter invoice number'
                                            value={freight.forwarderInvoice} 
                                            onChange={(e) =>
                                                setFreight((prevState) => ({ ...prevState, 
                                                    forwarderInvoice: e.target.value
                                                }))
                                            } required />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Date</label>
                                            <input className="form-control" type="date" value={freight.date}
                                                onChange={(e) => {
                                                    setFreight((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        date: e.target.value
                                                    }))
                                                }
                                                } required />
                                        </div>
                                        
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-6">
                                            <label>{isCreditFreight ? "Credit Amount" : "Amount"}</label>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">
                                                    {freight.currencySuffix}
                                                </span>
                                                
                                                <input type="number" className="form-control" required
                                                    value={freight.amount}
                                                    onChange={(e) => {
                                                        setFreight((prevState) => ({ ...prevState, 
                                                            amount: e.target.value
                                                        }))
                                                    }}>
                                                </input>
                                            </div>
                                        </div>
                                        {sameCurrency == false && (
                                            <>
                                                <div className="col-6">
                                                    <label>{freight.currencySuffix} to {freight.supplierAccountCurrencySuffix} Exchange Rate <i class="fas fa-info-circle" id="tooltip1"/>
                                                        <UncontrolledTooltip placement="top" target="tooltip1">
                                                        Since the forwarder account currency is different than the supplier invoice currency, the exchange rate is required.
                                                        </UncontrolledTooltip></label>
                                                    <input type="number" className="form-control" required 
                                                        value={freight.exchangeRate}
                                                        step={0.1}
                                                        onChange={(e) => {
                                                            setFreight((prevState) => ({
                                                                ...prevState,
                                                                exchangeRate: e.target.value
                                                            }));
                                                        }}>
                                                    </input>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <br/>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <label>Notes</label>
                                                <textarea className="form-control" type="text" value={freight.notes} onChange={(e) => {
                                                    setFreight((prevState) => ({ ...prevState, 
                                                        notes: e.target.value,
                                                    }))
                                                }}/>
                                            </div>
                                        </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseFreightModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" disabled={submitDisabled} onClick={(e) => submitFreightForm(e)}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        
                            <Modal size="md"  show={showLinkCommercialModal} onHide={handleCloseLinkCommercialModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Link Freight #{linkingCommercialForForwarderInvoice} to Commercial Invoice</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="mb-3 row">
                                        <div className="col-12">
                                            <label>Supplier Commercial Invoice</label>
                                            <Select
                                                required
                                                placeholder="-- Select Invoice --"
                                                isSearchable={true}
                                                options={supplierCommercialInvoicesByFreight.map(i => ({
                                                    value: i.sci,
                                                    label: "#" + i.sci.invoiceNumber
                                                }))}
                                                valuekey
                                                onChange={(e) => {
                                                    setLinkFreightToCommercial((prevState) => ({ ...prevState, 
                                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId
                                                    }))
                                                }}>
                                            </Select>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseLinkCommercialModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={(e) => submitLinkFreightForm(e)}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </>)
            }
        </>

    )
}
export default ListFreight;