import React, { isValidElement, useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { Global } from '../../../Variables/Global';
import { GetCurrencies } from '../../../api/HttpService'
import { RiShipFill } from 'react-icons/ri';
import { FiRefreshCcw, FiRefreshCw, FiPlus, FiEdit, FiTrash2, FiDelete2 } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiLink } from "react-icons/fi";
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import DataTable, { Alignment } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import dateFormat from 'dateformat';
import { Card, CardBody, Col, Progress, Row, CardHeader, CardText } from "reactstrap"
import Dropzone from "react-dropzone"
import { getDecodedToken } from '../../../utils/utils';
import moment from 'moment';
import './loader.css';
import axiosInstance from '../../../api/axiosConfig';
import { UncontrolledTooltip } from "reactstrap";
import { formatDate } from "../../../Classes/Util";


const Shipments = () => {
    const pId = useParams();
    const [shipmentDetails, setShipmentDetails] = useState({});
    const [settings, setSettings] = useState();
    const navigate = useNavigate();
    const [selectedTabNumber, setSelectedTabNumber] = useState(1);
    // const [shipment, setShipment] = useState({
    //     supplierId: 0,
    //     supplierProformaId: 0,
    //     supplierCommercialInvoiceId: 0,
    //     blContainersRef: "",
    //     containerNumber: "",
    //     shippingLine: "", 
    //     userId: localStorage.getItem("uTsReD"),
    //     shippingLineId: 0, 
    //     companyId: localStorage.getItem("C0m8N!"),
    //     incotermId: 0,
    //     incotermName: "",
    //     clientInvoiceId: 0,
    //     clientInvoiceNumber: "",
    //     clientId: 0,
    //     clientName: "",
    // });

    const [showAddShippingCostModal, setShowAddShippingCostModal] = useState(false)
    const [showDeleteShippingCostModal, setShowDeleteShippingCostModal] = useState(false)
    const handleCloseAddShippingCostModal = () => {
        setShowAddShippingCostModal(false);
    }
    const handleCloseDeleteShippingCostModal = () => {
        setShowDeleteShippingCostModal(false);
    }


    const [showDeleteShippingBLModal, setShowDeleteShippingBLModal] = useState(false)
    const handleCloseDeleteShippingBLModal = () => {
        setShowDeleteShippingBLModal(false);
    }

    const [editingShippingCostId, setEditingShippingCostId] = useState(0);
    const [deletingShippingCostId, setDeletingShippingCostId] = useState(0);
    const [deletingShippingCost, setDeletingShippingCost] = useState({});

    const DeleteShippingCost = (e) => {
        e.preventDefault();
        var config = {
            method: 'delete',
            url: "ShippingCost/DeleteShippingCost",
            headers: {},
            params: {
                'ShippingCostId': deletingShippingCostId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setShipmentShippingCosts(response.data);
                handleCloseDeleteShippingCostModal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [shippingCost, setShippingCost] = useState({
        invoiceNumber: "",
        forwarderId: 0,
        shippingCostsOptionId: 0,
        trackingInfoId: shipmentDetails.trackingInfoId,
        forwarderAccountId: 0,
        currencyId: 0,
        currencySuffix: "",
        amount: 0.0,
        date: formatDate(new Date()),
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        exchangeRate: 1.0,
        notes: "",
        isCredit: false
    });

    const [isCredit, setIsCredit] = useState(false);
    const [sameCurrency, setSameCurrency] = useState(true);
    const [forwarderAccounts, setForwarderAccounts] = useState([])
    const [forwarders, setForwarders] = useState([])
    const GetForwarders = () => {
        var config = {
            method: 'get',
            url: "Forwarder/GetForwarders?companyId=" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setForwarders(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [shippingCostTypes, setShippingCostTypes] = useState([])
    const [shipmentShippingCosts, setShipmentShippingCosts] = useState([])

    const GetShippingCostTypes = () => {
        var config = {
            method: 'get',
            url: "ShippingCostType/GetShippingCostsOptions?companyId=" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingCostTypes(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetShipmentShippingCosts = (trackingInfoId) => {
        var config = {
            method: 'get',
            url: "ShippingCost/GetShippingCosts?",
            headers: {},
            params: {
                'TrackingInfoId': trackingInfoId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setShipmentShippingCosts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitShippingCostForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidShippingCost()) {
            if (editingShippingCostId > 0) {
                var config = {
                    method: 'put',
                    url: "ShippingCost/EditShippingCost",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    params: {
                        'ShippingCostId': editingShippingCostId
                    },
                    data: shippingCost
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Shipping Cost Updated Successfully")
                        handleCloseAddShippingCostModal();
                        setEditingShippingCostId(0);
                        setShipmentShippingCosts(response.data);
                        setSubmitDisabled(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                        setSubmitDisabled(false);
                    });
            } else {
                var config = {
                    method: 'post',
                    url: "ShippingCost/AddShippingCost",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: shippingCost
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Shipping Cost Added Successfully")
                        handleCloseAddShippingCostModal();
                        setShipmentShippingCosts(response.data);
                        setSubmitDisabled(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data)
                        setSubmitDisabled(false);
                    });
            }
        } else {
            setSubmitDisabled(false);
        }
    }

    const IsValidShippingCost = () => {
        if (shippingCost.shippingCostsOptionId === 0 && shippingCost.forwarderId === 0 && shippingCost.forwarderAccountId === 0 && shippingCost.invoiceNumber === "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (shippingCost.shippingCostsOptionId === 0) {
            toast.error("Select the shipping cost invoice type.");
            return false;
        }
        else if (shippingCost.forwarderId === "") {
            toast.error("Select the forwarder.");
            return false;
        }
        else if (shippingCost.forwarderAccountId === "") {
            toast.error("Select the forwarder account.");
            return false;
        }
        else if (shippingCost.invoiceNumber === "") {
            toast.error("Enter the invoice number.");
            return false;
        } else if (shippingCost.amount <= 0.0) {
            toast.error("Enter the amount.");
            return false;
        }
        return true;
    }

    const [tracking, setTracking] = useState({
        shippingLineId: 0,
        shippingLine: "",
        blContainersRef: "",
        containerNumber: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
    });

    const [selectedCommercialInvoices, setSelectedCommercialInvoices] = useState([{
        supplierCommercialInvoiceId: 0,
        commercialInvoiceNumber: '',
    }]);
    // const [selectedCommercialInvoicesIds, setSelectedCommercialInvoicesIds] = useState([]);
    const [availableCommercialInvoices, setAvailableCommercialInvoices] = useState([]);
    const handleCommercialInvoiceAdd = () => {
        if (availableCommercialInvoices.length == 0 || supplierCommercialInvoices.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
            return;
        }
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        setSelectedCommercialInvoices([
            ...selectedCommercialInvoices,
            {
                supplierCommercialInvoiceId: 0,
                commercialInvoiceNumber: '',
            }
        ]);
        if (availableCommercialInvoices.length == 0 || supplierCommercialInvoices.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
        } else {
            setAddAnotherCommercialInvoiceButtonDisabled(false);
        }
    }

    const [availableSupplierCommercialInvoicesByTrackingInfo, setAvailableSupplierCommercialInvoicesByTrackingInfo] = useState([]);
    const handleLinkCommercialInvoicesAdd = () => {
        if (availableSupplierCommercialInvoicesByTrackingInfo.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
            return;
        }
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        setSelectedCommercialInvoices([
            ...selectedCommercialInvoices,
            {
                supplierCommercialInvoiceId: 0,
                commercialInvoiceNumber: '',
            }
        ]);

        if (availableSupplierCommercialInvoicesByTrackingInfo.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == selectedCommercialInvoices.length) {
            setAddAnotherCommercialInvoiceButtonDisabled(true);
        } else {
            setAddAnotherCommercialInvoiceButtonDisabled(false);
        }
    }

    // const [order, setOrder] = useState({
    //     supplierId: 0,
    //     supplierProformaId: 0,
    //     supplierCommercialInvoiceId: 0,
    //     userId: localStorage.getItem("uTsReD"),
    //     companyId: localStorage.getItem("C0m8N!"),
    //     incotermId: 0,
    //     incotermName: "",
    //     clientInvoiceId: 0,
    //     clientInvoiceNumber: "",
    //     clientId: 0,
    //     clientName: "",
    // });

    const [editingTrackingInfoId, setEditingTrackingInfoId] = useState(0);
    const [editingShipment, setEditingShipment] = useState({
        trackingInfoId: "",
        supplierId: 0,
        supplierProformaId: 0,
        supplierCommercialInvoiceId: 0,
        blContainersRef: "",
        containerNumber: "",
        shippingLine: "",
        userId: localStorage.getItem("uTsReD"),
        shippingLineId: 0,
        companyId: localStorage.getItem("C0m8N!"),
        incotermId: 0,
        incotermName: "",
        clientInvoiceId: 0,
        clientInvoiceNumber: "",
        clientId: 0,
        clientName: "",
    });


    const [deletingTrackingInfoId, setDeletingTrackingInfoId] = useState(0);
    const [deletingBLNumber, setDeletingBLNumber] = useState("");
    const [showDeleteShipmentModal, setShowDeleteShipmentModal] = useState(false);

    const [showEditIncotermsModal, setShowEditIncotermsModal] = useState(false);
    const [showEditNotesModal, setShowEditNotesModal] = useState(false);
    const [editingIncoterms, setEditingIncoterms] = useState({
        incotermId: 0,
        incotermName: "",
    });

    const [editingNotes, setEditingNotes] = useState({
        notes: "",
    });
    const handleCloseEditIncotermsModal = () => {
        setShowEditIncotermsModal(false);
    }

    const handleCloseEditNotesModal = () => {
        setShowEditNotesModal(false);
    }

    const submitDeleteShipmentForm = (e) => {
        e.preventDefault();
        var config = {
            method: 'delete',
            url: "TrackingInfo/DeleteShipment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                trackingInfoId: shipmentDetails.trackingInfoId,
                userId: localStorage.getItem("uTsReD"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Shipment deleted Successfully")
                    handleCloseDeleteShipmentModal();
                    navigate("/Logistics/Shipments");
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const [linkShipmentToCommercial, setLinkShipmentToCommercial] = useState({
        trackingInfoId: 0,
        supplierCommercialInvoiceId: 0,
        // userId: localStorage.getItem("uTsReD")
    });

    const trackingOptions = [
        {
            id: 1,
            label: "Container Number"
        },
        {
            id: 2,
            label: "MBL / Booking Number"
        }
    ];

    const [selectedTrackingOption, setSelectedTrackingOption] = useState(0);

    const GetShipmentDetails = (trackingInfoId) => {
        var userId = localStorage.getItem("uTsReD");
        var config = {
            method: 'get',
            url: "TrackingInfo/GetShipmentDetails",
            headers: {},
            params: { userId, trackingInfoId }
        };
        axiosInstance(config)
            .then(function (response) {
                setShipmentDetails(response.data);
                GetShipmentShippingCosts(response.data.trackingInfoId);
            })
            .catch(function (error) {
                toast.error(error.response.data);
                console.log(error);
            });
    }

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const GetShipmentDetailsAttachment = (trackingInfoId) => {
        var config = {
            method: 'get',
            url: 'TrackingInfo/GetShipmentDetailsAttachment',
            headers: {
                'Content-Type': 'application/json'
            },
            params: { trackingInfoId }
        };

        axiosInstance(config)
            .then(function (response) {
                setUploadedFiles(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const renderFilePreview = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return (
                    <a href={`${file.path}`} target="_blank" rel="noopener noreferrer">
                        <img
                            src={`${file.path}`}
                            alt={file.name}
                            height="50"
                            width="100"
                            className="auth-logo-light"
                            style={{ marginRight: '10px', marginTop: '10px' }}
                        />
                    </a>
                );
            case 'pdf':
            case 'txt':
                return (
                    <a
                        href={`${file.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="auth-logo-light"
                        style={{ marginRight: '10px', marginTop: '10px' }}
                    >
                        {file.name}
                    </a>
                );
            default:
                return <span>Unsupported file type</span>;
        }
    }
    const [selectedFiles, setselectedFiles] = useState([])

    const handleAcceptedFiles = (acceptedFiles) => {

        //Mapped the preview before uploading 
        const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        }));
        setselectedFiles(prevFiles => [...prevFiles, ...mappedFiles]);

        uploadFiles(acceptedFiles);
    };
    const [uploadProgress, setUploadProgress] = useState({});
    const uploadFiles = async (files) => {
        const companyCode = localStorage.getItem("C0m8N!");
        const userCode = localStorage.getItem("uTsReD");
        const trackingInfoId = pId.TrackingInfoId;

        files.forEach(async (file) => {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('companycode', companyCode);
            formData.append('usercode', userCode);
            formData.append('trackingInfoId', trackingInfoId);

            const config = {
                method: 'post',
                url: `${Global.API_URL}SupplierSalesOrder/Upload`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentCompleted
                    }));
                }
            };

            try {
                const response = await axiosInstance(config);
                if (response.status === 200) {
                    toast.success(`Upload Succeed for ${file.name}`);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: 100
                    }));
                    GetShipmentDetailsAttachment(pId.TrackingInfoId);
                    console.log(`File successfully uploaded: ${file.name}`, response.data);
                }
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                toast.error(`Something Went Wrong with ${file.name}`);
            }
        });
    };
    function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
    const handleDelete = (attachmentId) => {
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteAttachment/" + attachmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetShipmentDetailsAttachment(pId.TrackingInfoId);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }
    // const GetAppSettings = () => {
    //     var companyId = localStorage.getItem("C0m8N!");
    //     var config = {
    //         method: 'get',
    //         url: "Settings/GetAppSettings",
    //         headers: {},
    //         params: { companyId }
    //     };
    //     // companyId: localStorage.getItem("C0m8N!")
    //     axiosInstance(config)
    //         .then(function (response) {
    //             setSettings(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const reloadShipment = () => {
        var userId = localStorage.getItem("uTsReD");
        var companyId = localStorage.getItem("C0m8N!");
        var trackingInfoId = shipmentDetails.trackingInfoId;
        setSubmitDisabled(true);
        var config = {
            method: 'get',
            url: "TrackingInfo/ReloadShipment",
            headers: {},
            params: { userId, companyId, trackingInfoId }
        };
        axiosInstance(config)
            .then(function (response) {
                setShipmentDetails(response.data);
                setSubmitDisabled(false);
            })
            .catch(function (error) {
                console.log(error);
                setSubmitDisabled(false);
            });
    }

    const DeleteTrackingBL = () => {
        var userId = localStorage.getItem("uTsReD");
        var trackingInfoId = shipmentDetails.trackingInfoId;
        var companyId = localStorage.getItem("C0m8N!");

        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "TrackingInfo/DeleteTrackingBL",
            headers: {},
            params: { userId, companyId, trackingInfoId }
        };
        axiosInstance(config)
            .then(function (response) {
                setShipmentDetails(response.data);
                setSubmitDisabled(false);
                setShowDeleteShippingBLModal(false);
            })
            .catch(function (error) {
                console.log(error);
                setSubmitDisabled(false);
                setShowDeleteShippingBLModal(false);
            });
    }

    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliersForUntrackedInvoices/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [incoterms, setIncoterms] = useState([])
    const GetIncoterms = () => {
        var config = {
            method: 'get',
            url: "Incoterms/GetIncoterms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setIncoterms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [shippingLines, setShippingLines] = useState([])
    const GetShippingLines = () => {
        var config = {
            method: 'get',
            url: "ShippingLine/GetEnabledShippingLinesByCompany/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingLines(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSupplierChange = (supplierId) => {
        GetSupplierProformasSupplierId(supplierId);
        GetSupplierCommercialInvoicesBySupplierId(supplierId);
        setSelectedCommercialInvoices([{
            supplierCommercialInvoiceId: 0,
            commercialInvoiceNumber: '',
        }]);
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        // setSelectedCommercialInvoicesIds([]);
    }

    const handleSupplierProformaChange = (supplierOrderId, eraseEditinigShipment) => {
        GetClientProformasForSupplierProformaId(supplierOrderId);
        // setShipment((prevState) => ({ ...prevState, 
        //     supplierCommercialInvoiceId: 0,
        //     clientInvoiceId: 0,
        //     clientInvoiceNumber: "",
        //     clientId: 0,
        //     clientName: "",
        // }));

        setSelectedCommercialInvoices([{
            supplierCommercialInvoiceId: 0,
            commercialInvoiceNumber: '',
        }]);
        setAddAnotherCommercialInvoiceButtonDisabled(false);
        // setSelectedCommercialInvoicesIds([]);

        if (eraseEditinigShipment === true) {
            setEditingShipment((prevState) => ({
                ...prevState,
                supplierCommercialInvoiceId: 0,
                clientInvoiceId: 0,
                clientInvoiceNumber: "",
                clientId: 0,
                clientName: "",
            }));
        }
    }

    const [addAnotherCommercialInvoiceButtonDisabled, setAddAnotherCommercialInvoiceButtonDisabled] = useState(false);
    const handleSupplierCommercialChange = () => {
        // setShipment((prevState) => ({ ...prevState, 
        //     supplierProformaId: 0,
        //     clientInvoiceId: 0,
        //     clientInvoiceNumber: "",
        //     clientId: 0,
        //     clientName: "",
        // }));
        setEditingShipment((prevState) => ({
            ...prevState,
            supplierProformaId: 0,
            clientInvoiceId: 0,
            clientInvoiceNumber: "",
            clientId: 0,
            clientName: "",
        }));

        if (showLinkCommercialModal === true) {
            var ids = selectedCommercialInvoices.map((inv) => inv.supplierCommercialInvoiceId);
            // setSelectedCommercialInvoicesIds(ids);
            var availableList = supplierCommercialInvoicesByTrackingInfo.filter(function (item) {
                return ids.indexOf(item.sci.commercialInvoiceId) === -1;
            })

            setAvailableSupplierCommercialInvoicesByTrackingInfo(availableList);
            if (availableList.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == selectedCommercialInvoices.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        } else {
            var ids = selectedCommercialInvoices.map((inv) => inv.supplierCommercialInvoiceId);
            // setSelectedCommercialInvoicesIds(ids);
            var availableList = supplierCommercialInvoices.filter(function (item) {
                return ids.indexOf(item.sci.commercialInvoiceId) === -1;
            })

            setAvailableCommercialInvoices(availableList);
            if (availableList.length == 0 || supplierCommercialInvoices.length == selectedCommercialInvoices.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        }
    }

    const deleteSelectedCommercialInvoice = (id) => {
        var list = selectedCommercialInvoices.filter(function (item) {
            return item.supplierCommercialInvoiceId != id;
        });
        setSelectedCommercialInvoices(list);
        var ids = list.map((inv) => inv.supplierCommercialInvoiceId);
        if (showLinkCommercialModal === true) {
            var availableList = supplierCommercialInvoicesByTrackingInfo;
            if (ids != null && ids.length > 0) {
                availableList = supplierCommercialInvoicesByTrackingInfo.filter(function (item) {
                    return ids.indexOf(item.sci.commercialInvoiceId) === -1;
                })
            }

            setAvailableSupplierCommercialInvoicesByTrackingInfo(availableList);
            if (availableList.length == 0 || supplierCommercialInvoicesByTrackingInfo.length == list.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        } else {
            var availableList = supplierCommercialInvoices.filter(function (item) {
                return ids.indexOf(item.sci.commercialInvoiceId) === -1;
            })

            setAvailableCommercialInvoices(availableList);
            if (availableList.length == 0 || supplierCommercialInvoices.length == list.length) {
                setAddAnotherCommercialInvoiceButtonDisabled(true);
            } else {
                setAddAnotherCommercialInvoiceButtonDisabled(false);
            }
        }
        // handleSupplierCommercialChange();
    }


    const handleLinkShipmentToCommercial = (trackingInfoId) => {
        GetSupplierCommercialInvoicesByTrackingInfoId(trackingInfoId);
    }

    const [supplierCommercialInvoicesByTrackingInfo, setSupplierCommercialInvoicesByTrackingInfo] = useState([])
    const GetSupplierCommercialInvoicesByTrackingInfoId = (trackingInfoId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetUntrackedSupplierCommercialInvoicesByTrackingInfoId/" + trackingInfoId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoicesByTrackingInfo(response.data);
                setAvailableSupplierCommercialInvoicesByTrackingInfo(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [supplierProformas, setSupplierProformas] = useState([])
    const GetSupplierProformasSupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetUntrackedSupplierOrdersBySupplierId/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierProformas(response.data);
                // props.setProducts(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [clientProformas, setClientProformas] = useState([])
    const GetClientProformasForSupplierProformaId = (supplierProformaId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrdersFromSupplierProformaId/" + supplierProformaId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setClientProformas(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const trackingForOptions = [
        {
            id: 1,
            label: "Proforma"
        },
        {
            id: 2,
            label: "Commercial Invoice"
        },
    ];

    const [selectedTrackingForOption, setSelectedTrackingForOption] = useState();

    const [supplierCommercialInvoices, setSupplierCommercialInvoices] = useState([])
    const GetSupplierCommercialInvoicesBySupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetUntrackedSupplierCommercialInvoicesBySupplierId/" + supplierId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoices(response.data);
                setAvailableCommercialInvoices(response.data);
                // props.setProducts(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const submitEditIncotermsForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'put',
            url: "TrackingInfo/EditIncoterms",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editingIncoterms,
            params: {
                userId: localStorage.getItem("uTsReD"),
                trackingInfoId: shipmentDetails.trackingInfoId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Incoterms Updated Successfully")
                    setShipmentDetails(response.data);
                    handleCloseEditIncotermsModal();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }

    const AssignOrderToUser = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'put',
            url: "TrackingInfo/AssignOrderToUser",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                userId: localStorage.getItem("uTsReD"),
                trackingInfoId: shipmentDetails.trackingInfoId,
                assignToUserId: localStorage.getItem("uTsReD"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Order assigned successfully!")
                    setShipmentDetails(response.data);
                    handleCloseConfirmAssignOrderToUser();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }
    
    const UnassignOrderToUser = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'put',
            url: "TrackingInfo/UnAssignOrderToUser",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                userId: localStorage.getItem("uTsReD"),
                trackingInfoId: shipmentDetails.trackingInfoId,
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Order Unassigned successfully!")
                    setShipmentDetails(response.data);
                    handleCloseConfirmUnAssignOrderToUser();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }

    const submitEditNotesForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'put',
            url: "TrackingInfo/EditNotes",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editingNotes.notes,
            params: {
                userId: localStorage.getItem("uTsReD"),
                trackingInfoId: shipmentDetails.trackingInfoId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Notes Updated Successfully")
                    setShipmentDetails(response.data);
                    handleCloseEditNotesModal();
                    setSubmitDisabled(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
                setSubmitDisabled(false);
            });
    }

    const submitTrackingInfoForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (isValidTrackingInfo()) {
            var config = {
                method: 'put',
                url: "TrackingInfo/AddTrackingForExistingOrder",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: tracking,
                params: {
                    trackingInfoId: shipmentDetails.trackingInfoId,
                    userId: localStorage.getItem("uTsReD")
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Tracking Info Added Successfully")
                        handleCloseAddTrackingInfoModal();
                        setShipmentDetails(response.data);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const submitEditShipmentForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidEditShipment()) {
            var config = {
                method: 'put',
                url: "TrackingInfo/EditShipment",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: editingShipment,
                params: {
                    trackingInfoId: editingTrackingInfoId
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Shipment Updated Successfully")
                        handleCloseEditShipmentModal();
                        handleCloseLinkShipmentToOrderModal();
                        setShipmentDetails(response.data);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }



    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const submitLinkShipmentForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (linkShipmentToCommercial.trackingInfoId != null && linkShipmentToCommercial.trackingInfoId > 0 && selectedCommercialInvoices.length > 0) {
            linkShipmentToCommercial.commercialInvoicesIds = selectedCommercialInvoices.map((i) => i.supplierCommercialInvoiceId);
            var config = {
                method: 'put',
                url: "TrackingInfo/LinkShipmentToCommercialInvoice",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: linkShipmentToCommercial
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Shipment Linked Successfully")
                        handleCloseLinkCommercialModal();
                        setShipmentDetails(response.data);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const IsValidEditShipment = () => {
        if (editingShipment.supplierId === 0 && editingShipment.shippingLine === "" && editingShipment.blContainersRef === "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (editingShipment.supplierId === 0) {
            toast.error("Select the supplier.");
            return false;
        }
        else if (editingShipment.supplierCommercialInvoiceId === 0 && editingShipment.supplierProformaId === 0) {
            toast.error("Select the invoice.");
            return false;
        }
        else if (editingShipment.shippingLine === "") {
            toast.error("Select the shipping line.");
            return false;
        }
        else if (selectedTrackingOption === 1 && editingShipment.containerNumber === "") {
            toast.error("Enter the container number.");
            return false;
        }
        else if (selectedTrackingOption === 2 && editingShipment.blContainersRef === "") {
            toast.error("Enter the BL reference.");
            return false;
        }
        else if (editingShipment.supplierCommercialInvoiceId === 0 && editingShipment.supplierProformaId > 0 && editingShipment.clientInvoiceId === 0) {
            toast.error("Select the client invoice.");
            return false;
        }
        return true;
    }


    const isValidTrackingInfo = () => {
        if (tracking.shippingLine === "") {
            toast.error("Select the shipping line.");
            return false;
        }
        else if (selectedTrackingOption === 1 && tracking.containerNumber === "") {
            toast.error("Enter the container number.");
            return false;
        }
        else if (selectedTrackingOption === 2 && tracking.blContainersRef === "") {
            toast.error("Enter the BL reference.");
            return false;
        }
        else if (tracking.blContainersRef === "" && tracking.containerNumber === "") {
            toast.error("Enter all required fields.");
            return false;
        }
        return true;
    }

    const [permissions, setPermissions] = useState([]);

    const [linkingCommercialForBL, setLinkingCommercialForBL] = useState('');
    const [showLinkCommercialModal, setShowLinkCommercialModal] = useState(false);
    const handleCloseLinkCommercialModal = () => {
        setShowLinkCommercialModal(false);
    }

    const [showCreateCommercialInvoiceModal, setShowCreateCommercialInvoiceModal] = useState(false);
    const handleCloseCreateCommercialInvoiceModal = () => {
        setShowCreateCommercialInvoiceModal(false);
    }

    const [commercialInvoice, setCommercialInvoice] = useState({
        sellOrderId: 0,
        invoiceNumber: "",
        date: moment(new Date()).format("yyyy-MM-DD"),
        proforma: "",
        userId: localStorage.getItem("uTsReD"),
        notes: "",
        clientInvoiceNumber: "",
    });

    const [proformaProducts, setProformaProducts] = useState([])
    const GetProformaProducts = () => {
        var config = {
            method: 'get',
            url: "TrackingInfo/GetPendingOrderProducts?trackingInfoId=" + pId.TrackingInfoId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setProformaProducts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [selectedProductsIds, setSelectedProductsIds] = useState([]);
    const addProduct = (product) => {
        if (selectedProductsIds.includes(product.supplierOrderProductId)) {
            return;
        }
        setSelectedProductsIds((prevIds) => [...prevIds, product.supplierOrderProductId]);
    }
    const deleteProduct = (supplierOrderProductId, index) => {
        setSelectedProductsIds((prevIds) => prevIds.filter(id => id !== supplierOrderProductId));
    }

    const [showAddTrackingInfoModal, setShowAddTrackingInfoModal] = useState(false);
    const handleCloseAddTrackingInfoModal = () => {
        setShowAddTrackingInfoModal(false);
    }
    const [showConfirmAssignOrderToUser, setShowConfirmAssignOrderToUser] = useState(false);
    const handleCloseConfirmAssignOrderToUser = () => {
        setShowConfirmAssignOrderToUser(false);
    }
    const [showConfirmUnAssignOrderToUser, setShowConfirmUnAssignOrderToUser] = useState(false);
    const handleCloseConfirmUnAssignOrderToUser = () => {
        setShowConfirmUnAssignOrderToUser(false);
    }

    const handleCloseDeleteShipmentModal = () => {
        setDeletingTrackingInfoId(0);
        setShowDeleteShipmentModal(false);
    }

    const [showEditShipmentModal, setShowEditShipmentModal] = useState(false)
    const handleCloseEditShipmentModal = () => {
        setShowEditShipmentModal(false);
    }
    const [showLinkShipmentToOrderModal, setShowLinkShipmentToOrderModal] = useState(false)
    const handleCloseLinkShipmentToOrderModal = () => {
        setShowLinkShipmentToOrderModal(false);
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetShipmentDetails(pId.TrackingInfoId);
        GetShipmentDetailsAttachment(pId.TrackingInfoId);
        GetSuppliers();
        GetShippingLines();
        GetIncoterms();
        GetShippingCostTypes();
        GetForwarders();
        document.title = "Shipment Details";
    }, [])


    const AddCommercialInvoice = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        var config = {
            method: 'post',
            url: "TrackingInfo/AddCommercialInvoice",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'TrackingInfoId': shipmentDetails.trackingInfoId,
                'UserId': localStorage.getItem("uTsReD"),
            },
            data: {
                'SupplierOrderId': shipmentDetails.supplierOrderId,
                'CustomerOrderId': shipmentDetails.clientProformaInvoiceId,
                'SupplierInvoiceNumber': commercialInvoice.invoiceNumber,
                'Date': commercialInvoice.date,
                'Notes': commercialInvoice.notes,
                'Products': proformaProducts
                    .filter(product => selectedProductsIds.includes(product.supplierOrderProductId))
                    .map(product => ({
                        'SupplierProductId': product.supplierOrderProductId,
                        'ProductQuantity': product.qty,
                        'Fcl': product.fcl
                    }))
            }
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully");
                    setShipmentDetails(response.data);
                    handleCloseCreateCommercialInvoiceModal();
                }
                setSubmitDisabled(false);
            })
            .catch(function (error) {
                toast.error(error.response.data);
                setSubmitDisabled(false);
            });
    }

    const [editingCommercialId, setEditingCommercialId] = useState(0);
    const [deletingCommercialId, setDeletingCommercialId] = useState(0);

    const handleShowDeleteInvoice = () => setShowDeleteCommercialInvoice(true);
    const [showAddCommercialInvoice, setShowAddCommercialInvoice] = useState(false);
    const [showDeleteCommercialInvoice, setShowDeleteCommercialInvoice] = useState(false);
    const handleCloseDeleteInvoice = () => setShowDeleteCommercialInvoice(false);
    const handleCloseAddCommercialInvoice = () => setShowAddCommercialInvoice(false);
    const handleShowAddCommercialInvoice = () => setShowAddCommercialInvoice(true);

    const EditCommericalInvoice = (e) => {
        e.preventDefault();

        setSubmitDisabled(true);

        if (editingCommercialId > 0) {
            var config = {
                method: 'put',
                url: "SupplierCommercialInvoice/EditSupplierCommercialInvoice/" + editingCommercialId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: commercialInvoice
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Updated Successfully")
                        setShowAddCommercialInvoice(false)
                        GetShipmentDetails(pId.TrackingInfoId);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                });
        }
        setSubmitDisabled(false);
    }

    const DeleteInvoice = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (deletingCommercialId > 0) {
            var config = {
                method: 'delete',
                url: "TrackingInfo/DeleteCommercialInvoice",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                params: {
                    'SupplierCommercialInvoiceId': deletingCommercialId,
                    'TrackingInfoId': pId.TrackingInfoId,
                    'UserId': localStorage.getItem("uTsReD"),
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Deleted Successfully")
                        setShowDeleteCommercialInvoice(false)
                        GetShipmentDetails(pId.TrackingInfoId);
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    setSubmitDisabled(false);
                    setShowDeleteCommercialInvoice(false)
                });
        } 
        setSubmitDisabled(false);
        setShowDeleteCommercialInvoice(false)
    }

    return (
        <>
            {permissions.viewShipments == null || permissions.viewShipments == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4">
                                    <div className="page-title-box">
                                        {
                                            shipmentDetails.blReferenceNo == null ?
                                                <h4>Order Details</h4>
                                                :
                                                <h4>Details for Shipment with BL #{shipmentDetails.blReferenceNo}</h4>
                                        }
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Shipment Details</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-8 text-right'>
                                    <div className='flex justify-end'>
                                        {shipmentDetails.userId != null && shipmentDetails.userId > 0 && shipmentDetails.shippingLine != null && (
                                            <button
                                                disabled={submitDisabled}
                                                onClick={() => {
                                                    reloadShipment();
                                                }} className="ml-1 btn btn-secondary"><FiRefreshCw /> Refresh</button>
                                        )}
                                        {shipmentDetails.userId != null && shipmentDetails.userId > 0 && permissions.deleteShipment == true && (
                                            <button
                                                disabled={submitDisabled}
                                                onClick={() => {
                                                    //DeleteShipment();
                                                    setShowDeleteShipmentModal(true);
                                                }} className="ml-1 btn btn-danger"><FiTrash2 /> Delete Tracking Order</button>
                                        )}

                                        {( shipmentDetails != null && shipmentDetails.supplierId > 0 && shipmentDetails.userId == null || shipmentDetails.userId == 0) && (
                                            <button
                                                disabled={submitDisabled}
                                                onClick={() => {
                                                    setShowConfirmAssignOrderToUser(true);
                                                }} className="ml-1 btn btn-danger"><i className="fas fa-user-plus"></i> <b>Assign Order To Me</b></button>
                                            // 
                                        )}
                                        {shipmentDetails.userId != null && (
                                            <button
                                                disabled={submitDisabled}
                                                onClick={() => {
                                                    setShowConfirmUnAssignOrderToUser(true);
                                                }} className="ml-1 btn btn-danger"><i className="fas fa-user-minus"></i> <b> Unassign  Order</b></button>
                                            // 
                                        )}
                                    </div>
                                </div>
                            </div>

                            <Row style={{ display: 'flex', height: '100%' }}>
                                <Col sm={6} style={{ flex: 1, display: 'flex' }}>
                                    <Card style={{ flex: 1 }}>
                                        <CardBody>
                                            <table cellPadding={10}>
                                                <tr>
                                                    <td>
                                                        <div role="alert">
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <h5 class="alert-heading font-18"><i class="fas fa-file-invoice" /> Invoices</h5>
                                                                </div>
                                                                <table cellPadding={10}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div role="alert">
                                                                                    <div className="row" style={{ display: 'flex', height: '100%' }}>
                                                                                        <div className="col-6" style={{ flex: 1, display: 'flex' }}>
                                                                                            <div class="alert alert-outline-info fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                                <h6 class="alert-heading font-18">Supplier</h6>
                                                                                                <span className="text-secondary">{shipmentDetails.supplierName || <Skeleton width={100} />}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 flex" style={{ flex: 1, display: 'flex' }}>
                                                                                            <div class="alert alert-outline-info fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                                <h6 class="alert-heading font-18">Client</h6>
                                                                                                <span className="text-secondary">{shipmentDetails.clientName || <Skeleton width={100} />}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="col-12 mb-3">
                                                                    <div class="alert alert-outline-info fade show" role="alert">
                                                                        <h6 class="alert-heading font-18">Payment Terms</h6>
                                                                        <span className="text-secondary">{shipmentDetails.paymentTermsName || <Skeleton width={100} />}</span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    shipmentDetails != null && shipmentDetails.commercialInvoices != null && shipmentDetails.commercialInvoices.map((c) => {
                                                                        return (<>
                                                                            <div className="col-12">
                                                                                <div class="col-12 alert alert-info fade show" role="alert">
                                                                                    <div className="row ">
                                                                                        <div className="col-7">
                                                                                            <h6 class="alert-heading font-18"><b>{c.supplierCommercialInvoiceNumber ? ("#" + c.supplierCommercialInvoiceNumber) : <Skeleton width={100} />}</b></h6>
                                                                                            <h6 class="alert-heading font-18"><b>{c.supplierCommercialInvoiceNumber ? (c.clientCommercialInvoiceNumber ? ("#" + c.clientCommercialInvoiceNumber) : "-") : <Skeleton width={100} />}</b></h6>
                                                                                        </div>
                                                                                        <div className='col-5 text-right'>
                                                                                            <div className="d-flex justify-content-end align-items-center">
                                                                                                <h6 className="alert-heading font-18 mb-0">{c.commercialInvoiceDate.split('T')[0]}</h6>
                                                                                                {c.isEditable ? (
                                                                                                    <>
                                                                                                        <Button variant="warning" size="sm" className="me-1 ms-2" onClick={(e) => {
                                                                                                            setEditingCommercialId(c.supplierCommercialInvoiceId);
                                                                                                            setCommercialInvoice((prevState) => ({
                                                                                                                ...prevState,
                                                                                                                invoiceNumber: c.supplierCommercialInvoiceNumber,
                                                                                                                clientInvoiceNumber: c.clientCommercialInvoiceNumber,
                                                                                                                date: c.commercialInvoiceDate.split('T')[0],
                                                                                                                notes: c.notes,
                                                                                                            }));
                                                                                                            handleShowAddCommercialInvoice();
                                                                                                        }}>
                                                                                                            <FiEdit />
                                                                                                        </Button>
                                                                                                        <Button variant="danger" size="sm" onClick={(e) => {
                                                                                                            setDeletingCommercialId(c.supplierCommercialInvoiceId);
                                                                                                            handleShowDeleteInvoice();
                                                                                                        }}>
                                                                                                            <FiTrash2 />
                                                                                                        </Button>
                                                                                                    </>
                                                                                                ) : null}
                                                                                            </div>
                                                                                            <br />
                                                                                            {/*<div className='flex justify-end'>*/}
                                                                                            {/*    <h6 class="alert-heading font-18">{c.commercialInvoiceDate.split('T')[0]}</h6>*/}
                                                                                            {/*</div>*/}
                                                                                            <div className='flex justify-end'>
                                                                                                {
                                                                                                    c.remainingTotal == 0.0 ?
                                                                                                        <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Payment Settled</b></span>
                                                                                                        :
                                                                                                        (c.paidAmount > 0.0) ?
                                                                                                            <span style={{ color: '#936b16', backgroundColor: '#fdf0d3', borderColor: '#fce8be', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Advanced Settled</b></span>
                                                                                                            :
                                                                                                            <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Payment Required</b></span>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='flex justify-end'>
                                                                                                {
                                                                                                    c.remainingFreight > 0 &&
                                                                                                    <span style={{ color: '#936b16', backgroundColor: '#fdf0d3', borderColor: '#fce8be', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>Freight Not Settled</b></span>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="text-wrap">
                                                                                            {
                                                                                                c.products.map((p) => {
                                                                                                    return <li><span className="text-secondary">{p}</span><br /></li>
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>)
                                                                    })
                                                                }
                                                                {
                                                                    shipmentDetails != null && (shipmentDetails.commercialInvoices == null || shipmentDetails.commercialInvoices.length === 0) &&
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div class="col-12 alert alert-outline-info fade show" role="alert">
                                                                                <div className="row ">
                                                                                    <div className="col-7">
                                                                                        <h6 class="alert-heading font-18"><b>#{shipmentDetails.supplierInvoice}</b></h6>
                                                                                        <h6 class="alert-heading font-18"><b>#{shipmentDetails.clientProformaInvoice}</b></h6>
                                                                                    </div>
                                                                                    <div className="text-wrap">
                                                                                        {shipmentDetails.products != null &&
                                                                                            shipmentDetails.products.map((p) => {
                                                                                                return <li><span className="text-secondary">{p}</span><br /></li>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    shipmentDetails.hasCommercial === true &&
                                                                    <div className={shipmentDetails.canAddCommercialInvoice === true ? "col-6" : "col-12"}>
                                                                        {permissions.addShipment === true && (
                                                                            <button onClick={() => {
                                                                                setLinkShipmentToCommercial((prevState) => ({
                                                                                    ...prevState,
                                                                                    trackingInfoId: shipmentDetails.trackingInfoId
                                                                                }))
                                                                                setSelectedCommercialInvoices([{
                                                                                    supplierCommercialInvoiceId: 0,
                                                                                    commercialInvoiceNumber: '',
                                                                                }])
                                                                                setLinkingCommercialForBL(shipmentDetails.blReferenceNo);
                                                                                handleLinkShipmentToCommercial(shipmentDetails.trackingInfoId);
                                                                                setShowLinkCommercialModal(true);
                                                                            }} className="btn btn-primary mb-2"
                                                                                disabled={shipmentDetails.trackingInfoId == null || shipmentDetails.userId == null || shipmentDetails.userId == 0}
                                                                                style={{ width: '100%' }}><b> <i className="mdi mdi-link-variant-remove"></i> Link Existing Invoice</b></button>
                                                                        )}
                                                                    </div>
                                                                }
                                                                {
                                                                    shipmentDetails.canAddCommercialInvoice === true &&
                                                                    <div className={shipmentDetails.hasCommercial === true ? "col-6" : "col-12"}>
                                                                        {permissions.addShipment === true && (
                                                                            <button className="btn btn-outline-primary"
                                                                                disabled={shipmentDetails.trackingInfoId == null || shipmentDetails.userId == null || shipmentDetails.userId == 0}
                                                                                onClick={() => {
                                                                                    setCommercialInvoice({
                                                                                        sellOrderId: 0,
                                                                                        invoiceNumber: "",
                                                                                        date: moment(new Date()).format("yyyy-MM-DD"),
                                                                                        proforma: "",
                                                                                        userId: localStorage.getItem("uTsReD"),
                                                                                        notes: ""
                                                                                    });
                                                                                    setProformaProducts([]);
                                                                                    setSelectedProductsIds([]);
                                                                                    GetProformaProducts();
                                                                                    setShowCreateCommercialInvoiceModal(true);
                                                                                }} style={{ width: '100%' }}><b><FiPlus /> Add Final Invoice</b></button>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={6} style={{ flex: 1, display: 'flex' }}>
                                    <Card style={{ flex: 1 }}>
                                        <CardBody>
                                            <table cellPadding={10} style={{ width: '100%' }}>
                                                <tr>
                                                    <td>
                                                        <div role="alert">
                                                            {
                                                                shipmentDetails.trackingInfoId != null && (shipmentDetails.blReferenceNo == null || shipmentDetails.blReferenceNo == "") &&
                                                                    (shipmentDetails.containerNumber == null || shipmentDetails.containerNumber == "")  ?
                                                                    <button
                                                                        disabled={shipmentDetails.userId == null }
                                                                        onClick={() => {
                                                                        setTracking({
                                                                            shippingLineId: 0,
                                                                            shippingLine: "",
                                                                            blContainersRef: "",
                                                                            containerNumber: "",
                                                                            userId: localStorage.getItem("uTsReD"),
                                                                            companyId: localStorage.getItem("C0m8N!"),
                                                                        });

                                                                        setShowAddTrackingInfoModal(true);
                                                                    }} className="btn btn-primary mb-2" style={{ width: '100%' }} ><b> <i className="fas fa-ship"></i> Add Tracking Info</b></button>
                                                                    :
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <h5 class="alert-heading font-18"><i class="fas fa-ship" /> Tracking</h5>
                                                                            </div>
                                                                            <div className='col-4 text-right'>

                                                                                <div className='justify-end'>
                                                                                    {
                                                                                        shipmentDetails.statusId == 20 ?
                                                                                            <span style={{ color: '#292b2c', backgroundColor: '#e9eaea', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>In Progress</b></span>
                                                                                            :
                                                                                            shipmentDetails.statusId == 30 ?
                                                                                                <span style={{ color: '#292b2c', backgroundColor: '#d3d5d6', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{shipmentDetails.status}</b></span>
                                                                                                :
                                                                                                shipmentDetails.statusId == 35 ?
                                                                                                    <span style={{ color: '#41b6d9', backgroundColor: '#eff9fc', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{shipmentDetails.status}</b></span>
                                                                                                    :
                                                                                                    shipmentDetails.statusId == 40 ?
                                                                                                        <span style={{ color: '#936b16', backgroundColor: '#fdf0d3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{shipmentDetails.status}</b></span>
                                                                                                        :
                                                                                                        shipmentDetails.statusId == 45 ?
                                                                                                            <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{shipmentDetails.status}</b></span>
                                                                                                            :
                                                                                                            shipmentDetails.statusId == 50 ?
                                                                                                                <span style={{ color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{shipmentDetails.status}</b></span>
                                                                                                                :
                                                                                                                shipmentDetails.statusId == 60 ?
                                                                                                                    <span style={{ color: '#197088', backgroundColor: '#d4f1f9', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{shipmentDetails.status}</b></span>
                                                                                                                    : <></>
                                                                                    }
                                                                                    {
                                                                                        shipmentDetails != null && shipmentDetails.containerNumber != null && 
                                                                                        <button onClick={() => {
                                                                                            setShowDeleteShippingBLModal(true);
                                                                                        }} className="btn btn-danger btn-sm ms-2" ><FiTrash2 /></button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-8">
                                                                                <p>
                                                                                    Shipping Line: <b>{shipmentDetails.shippingLine  || <Skeleton width={100} />}</b>
                                                                                    <br />
                                                                                    Bill of Lading: <b>{shipmentDetails.blReferenceNo || <Skeleton width={100} />}</b>
                                                                                    <br />
                                                                                    Container Number: <b>{shipmentDetails.containerNumber || ""}</b>
                                                                                    <br />
                                                                                    Number of Containers: <b>{shipmentDetails.blContainerCount ? `${shipmentDetails.blContainerCount} (${shipmentDetails.containerTEU} TEU)` : ""}</b>
                                                                                </p>
                                                                            </div>
                                                                            {
                                                                                shipmentDetails != null && shipmentDetails.containerNumber != null && shipmentDetails.statusId > 20 && shipmentDetails.statusId < 50 &&
                                                                                <div className='col-4 text-right justify-end'>
                                                                                    <button onClick={() => {
                                                                                            navigate(`/Logistics/ShipmentLiveTracking/${shipmentDetails.containerNumber}`)
                                                                                        }} className="btn btn-primary btn-sm mt-2" ><i className="mdi mdi-sail-boat"/> Live Tracking</button>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        {/* <div className="row"> */}
                                                                            <div class="col-12 mb-0 alert alert-info fade show" role="alert">
                                                                                <div className="row">
                                                                                    <div className="col-8">
                                                                                        {
                                                                                            shipmentDetails.pol == null || shipmentDetails.pol == "" ?
                                                                                                <div className="loader">
                                                                                                    <span><i>Waiting for tracking<span className="dots" /></i></span>
                                                                                                </div>
                                                                                                :
                                                                                                <h5 class="alert-heading font-18"><b>{shipmentDetails.pol}, {shipmentDetails.fromCountry || ""}</b></h5>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='col-4 text-right'>
                                                                                        <div className='flex justify-end'>
                                                                                            <h5 class="alert-heading font-18"><b>{shipmentDetails.loadingDate || ""}</b></h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    <span className="text-secondary">{shipmentDetails.supplierName || <Skeleton width={100} />}</span>
                                                                                </div>
                                                                                {
                                                                                    shipmentDetails.statusId >= 45 ?
                                                                                        <Progress className="mt-3" color="success" value={100} />
                                                                                        :
                                                                                        <Progress className="mt-3" striped animated color="bg-primary" value={shipmentDetails.trackingProgress || <Skeleton width={100} />} />
                                                                                }
                                                                                <div className="row mt-3">
                                                                                    <div className="col-8">
                                                                                        {
                                                                                            shipmentDetails.pod == null || shipmentDetails.pod == "" ?
                                                                                                <div className="loader">
                                                                                                    <span><i>Waiting for tracking<span className="dots" /></i></span>
                                                                                                </div>
                                                                                                :
                                                                                                <h5 class="alert-heading font-18"><b>{shipmentDetails.pod}, {shipmentDetails.toCountry || <Skeleton width={100} />}</b></h5>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='col-4 text-right'>
                                                                                        <div className='flex justify-end'>
                                                                                            <h5 class="alert-heading font-18"><b>{shipmentDetails.arrivalDate || <Skeleton width={80} />}</b></h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br />
                                                                                    <span className="text-secondary">{shipmentDetails.clientName || <Skeleton width={100} />}</span>
                                                                                </div>
                                                                            </div>
                                                                        {/* </div> */}
                                                                    </>
                                                            }

                                                            {
                                                                shipmentDetails.trackingInfoId != null && (shipmentDetails.incotermName == null || shipmentDetails.incotermName == "") && permissions.addShipment === true ?
                                                                    <button disabled={shipmentDetails.userId == null} onClick={() => {
                                                                        setEditingIncoterms({
                                                                            incotermId: 0,
                                                                            incotermName: ""
                                                                        });
                                                                        setShowEditIncotermsModal(true);
                                                                    }} className="btn btn-primary mb-2 mt-3" style={{ width: '100%' }} ><b><FiPlus /> Add Incoterms</b></button>
                                                                    :
                                                                    <>
                                                                        <div class="col-12 mt-3 alert alert-info fade show" role="alert">
                                                                            <div className="row ">
                                                                                <div className="col-8">
                                                                                    <h5 class="alert-heading font-18">Incoterms:</h5>
                                                                                </div>
                                                                                <div className='col-4 text-right'>
                                                                                    <div className='flex justify-end'>
                                                                                        {permissions.editShipment === true && (
                                                                                            <button className='btn btn-warning  btn-sm'
                                                                                                disabled={shipmentDetails.trackingInfoId == null || shipmentDetails.userId == null || shipmentDetails.userId == 0}
                                                                                                onClick={() => {
                                                                                                    setEditingIncoterms({
                                                                                                        incotermId: shipmentDetails.incotermId,
                                                                                                        incotermName: shipmentDetails.incotermName
                                                                                                    });
                                                                                                    setShowEditIncotermsModal(true);
                                                                                                }}><FiEdit /></button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                                <span className="text-secondary">{shipmentDetails.incotermName || <Skeleton width={100} />}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }

                                                            <div class="col-12 mt-3 alert alert-info fade show" role="alert">
                                                                <div className="row ">
                                                                    <div className="col-8">
                                                                        <h5 class="alert-heading font-18">Notes:</h5>
                                                                    </div>
                                                                    <div className='col-4 text-right'>
                                                                        <div className='flex justify-end'>
                                                                            {permissions.editShipment === true && (
                                                                                <button className='btn btn-warning  btn-sm'
                                                                                    disabled={shipmentDetails.trackingInfoId == null || shipmentDetails.userId == null || shipmentDetails.userId == 0}
                                                                                    onClick={() => {
                                                                                        setEditingNotes({
                                                                                            notes: shipmentDetails.notes
                                                                                        });
                                                                                        setShowEditNotesModal(true);
                                                                                    }}><FiEdit /></button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <span className="text-secondary">{shipmentDetails.notes != null && shipmentDetails.notes.length > 0 ? shipmentDetails.notes : (shipmentDetails.trackingInfoId == null ? <Skeleton width={100} /> : "-")}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ display: 'flex', height: '100%' }}>
                                <Col sm={6} style={{ flex: 1, display: 'flex' }}>
                                    <Card style={{ flex: 1 }}>
                                        <CardBody >
                                            <table cellPadding={10} style={{ width: '100%' }}>
                                                <tr>
                                                    <td >
                                                        <div role="alert">
                                                            <div className="row" >
                                                                <div className="col-12">
                                                                    {/* <h5 class="alert-heading font-18"><i class="ion ion-md-attach"/> Attachments</h5> */}
                                                                    <h5 className=" mb-3 alert-heading font-18"><i class="fas fa-money-check-alt" /> Shipping Costs</h5>
                                                                    {
                                                                        shipmentShippingCosts != null && shipmentShippingCosts.length > 0 &&
                                                                        <>
                                                                            {
                                                                                shipmentShippingCosts.map((c) => {
                                                                                    return (<>
                                                                                        <div className="col-12" >
                                                                                            <div class="col-12 alert alert-info fade show" role="alert">
                                                                                                <div className="row ">
                                                                                                    <div className="col-7">
                                                                                                        <h6 class="alert-heading font-18"><b>{c.shippingCostOptionName ? c.shippingCostOptionName : <Skeleton width={100} />}</b></h6>
                                                                                                        <h6 class="alert-heading font-18"><b>{c.invoiceNumber ? ("#" + c.invoiceNumber) : <Skeleton width={100} />}</b></h6>
                                                                                                        <h6 class="alert-heading font-18">{c.date.split('T')[0]}</h6>
                                                                                                    </div>
                                                                                                    <div className='col-5 text-right'>
                                                                                                        <div className='flex justify-end'>
                                                                                                            {permissions.editShipment === true && (
                                                                                                                <button className='btn btn-warning btn-sm'
                                                                                                                    // disabled={shipmentDetails.trackingInfoId == null || shipmentDetails.userId == null || shipmentDetails.userId == 0}
                                                                                                                    onClick={() => {
                                                                                                                        setEditingShippingCostId(c.shippingCostId);
                                                                                                                        setShippingCost((prevState) => ({
                                                                                                                            ...prevState,
                                                                                                                            trackingInfoId: shipmentDetails.trackingInfoId,
                                                                                                                            invoiceNumber: c.invoiceNumber,
                                                                                                                            forwarderId: c.forwarderId,
                                                                                                                            forwarderAccountId: c.forwarderAccountId,
                                                                                                                            currencyId: c.currencyId,
                                                                                                                            currencySuffix: c.currencySuffix,
                                                                                                                            amount: c.amount,
                                                                                                                            date: c.date,
                                                                                                                            exchangeRate: c.exchangeRate,
                                                                                                                            isPaid: c.isPaid,
                                                                                                                            notes: c.notes,
                                                                                                                            forwarderAccountName: c.forwarderAccountName,
                                                                                                                            forwarderName: c.forwarderName,
                                                                                                                            shippingCostOptionName: c.shippingCostOptionName,
                                                                                                                            shippingCostsOptionId: c.shippingCostsOptionId,
                                                                                                                            isCredit: c.isCredit
                                                                                                                        }));

                                                                                                                        var forwarder = forwarders.filter(function (item) {
                                                                                                                            return item.forwarderId == c.forwarderId;
                                                                                                                        })[0];

                                                                                                                        setForwarderAccounts(forwarder.accounts);
                                                                                                                        setSameCurrency(shipmentDetails.supplierAccountCurrencyId === 0 || c.currencyId === shipmentDetails.supplierAccountCurrencyId);
                                                                                                                        setShowAddShippingCostModal(true);
                                                                                                                    }}><FiEdit /></button>
                                                                                                            )}
                                                                                                            {permissions.deleteShipment === true && (
                                                                                                                <button className='btn btn-danger btn-sm ms-1'
                                                                                                                    disabled={c.isPaid === true}
                                                                                                                    onClick={() => {
                                                                                                                        setDeletingShippingCostId(c.shippingCostId);
                                                                                                                        setDeletingShippingCost({
                                                                                                                            invoiceNumber: c.invoiceNumber,
                                                                                                                            shippingCostOptionName: c.shippingCostOptionName,
                                                                                                                        });
                                                                                                                        setShowDeleteShippingCostModal(true);
                                                                                                                    }}><FiTrash2 /></button>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className='flex justify-end mt-1'>
                                                                                                            {
                                                                                                                c.isPaid === true ? (
                                                                                                                    <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                                                                                        <b>Payment Settled</b>
                                                                                                                    </span>
                                                                                                                ) : c.isCredit === true ? (
                                                                                                                    <span style={{ color: '#35834f', backgroundColor: '#def8e6', borderColor: '#cdf4da', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                                                                                        <b>Credit</b>
                                                                                                                    </span>
                                                                                                                ) : (
                                                                                                                    <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                                                                                        <b>Payment Required</b>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="text-wrap">
                                                                                                        <span className="text-secondary">Forwarder: <b>{c.forwarderName} - {c.forwarderAccountName}</b></span><br />
                                                                                                        <span className="text-secondary">Amount: <b>{c.currencySuffix} {c.amount.toLocaleString()}</b></span><br />
                                                                                                        {
                                                                                                            c.notes != null && c.notes != "" && <>
                                                                                                                <span className="text-secondary">Notes: <i>{c.notes}</i></span><br /></>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>)
                                                                                })}
                                                                        </>
                                                                    }
                                                                    {permissions.addShipment === true && shipmentDetails.userId != null && (
                                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                                            <button onClick={() => {
                                                                                setEditingShippingCostId(0);
                                                                                setShippingCost((prevState) => ({
                                                                                    ...prevState,
                                                                                    trackingInfoId: shipmentDetails.trackingInfoId,
                                                                                    invoiceNumber: "",
                                                                                    forwarderId: 0,
                                                                                    forwarderAccountId: 0,
                                                                                    currencyId: 0,
                                                                                    currencySuffix: "",
                                                                                    amount: 0.0,
                                                                                    date: formatDate(new Date()),
                                                                                    exchangeRate: 1.0,
                                                                                    notes: "",
                                                                                    forwarderAccountName: null,
                                                                                    forwarderName: null,
                                                                                    shippingCostOptionName: null,
                                                                                    isCredit: false
                                                                                }));
                                                                                setIsCredit(false);
                                                                                setShowAddShippingCostModal(true);
                                                                            }} className="btn btn-primary mb-2" style={{ flex: '1' }} ><FiPlus /> Add Shipping Cost</button>

                                                                            <button onClick={() => {
                                                                                setEditingShippingCostId(0);
                                                                                setShippingCost((prevState) => ({
                                                                                    ...prevState,
                                                                                    trackingInfoId: shipmentDetails.trackingInfoId,
                                                                                    invoiceNumber: "",
                                                                                    forwarderId: 0,
                                                                                    forwarderAccountId: 0,
                                                                                    currencyId: 0,
                                                                                    currencySuffix: "",
                                                                                    amount: 0.0,
                                                                                    date: formatDate(new Date()),
                                                                                    exchangeRate: 1.0,
                                                                                    notes: "",
                                                                                    forwarderAccountName: null,
                                                                                    forwarderName: null,
                                                                                    shippingCostOptionName: null,
                                                                                    isCredit: true
                                                                                }));
                                                                                setIsCredit(true);
                                                                                setShowAddShippingCostModal(true);
                                                                            }} className="btn btn-outline-primary mb-2" style={{ flex: '1' }} ><FiPlus /> Add Credit</button>
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </CardBody>

                                    </Card>
                                </Col>
                                <Col sm={6} style={{ flex: 1, display: 'flex' }}>
                                    <Card style={{ flex: 1 }}>
                                        <CardBody>
                                            <h5 className=" mb-3 alert-heading font-18"><i class="ion ion-md-attach" /> Attachments</h5>

                                            <Dropzone onDrop={handleAcceptedFiles}>
                                                {({ getRootProps, getInputProps }) => {
                                                    const rootProps = getRootProps();
                                                    const inputProps = getInputProps();

                                                    return (
                                                        <div {...rootProps}>
                                                            {permissions.addShipment === true && shipmentDetails.userId != null && (
                                                                <button
                                                                    onClick={rootProps.onClick}
                                                                    className="btn btn-primary mb-2 mt-3"
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <b><FiPlus /> Upload Attachments</b>
                                                                </button>
                                                            )}
                                                            <input {...inputProps} style={{ display: 'none' }} />
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                            <br />
                                            <Row>
                                                {uploadedFiles.map((file, index) => (
                                                    <Col sm={6} key={index} className="mb-1">
                                                        <Card style={{ position: 'relative' }}>
                                                            {permissions.deleteShipment === true && (
                                                                <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                                                    <button
                                                                        disabled={shipmentDetails.userId == null}
                                                                        className="btn btn-danger btn-md"
                                                                        onClick={() => handleDelete(file.attachmentId)}
                                                                    >
                                                                        <FiTrash2 />
                                                                    </button>
                                                                </div>
                                                            )}
                                                            <CardBody>
                                                                <span>{file.name}</span>
                                                                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {renderFilePreview(file)}
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardBody>

                                    </Card>
                                </Col>
                            </Row>


                        </div>
                    </div>
                </>)
            }
            <Modal size="lg" show={showEditShipmentModal} onHide={handleCloseEditShipmentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Shipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Supplier</label>
                            <Select
                                required
                                placeholder="-- Select Supplier --"
                                isSearchable={true}
                                defaultValue={{ label: editingShipment.supplierName, value: editingShipment.supplierId }}
                                options={suppliers.map(c => ({
                                    value: c,
                                    label: c.supplierName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingShipment((prevState) => ({
                                        ...prevState,
                                        supplierId: e.value.supplierId,
                                        supplierProformaId: 0,
                                        supplierCommercialInvoiceId: 0,
                                    }))
                                    handleSupplierChange(e.value.supplierId);
                                }}>
                            </Select>
                        </div>
                        <div className="col-6">
                            <label>Tracking for</label>
                            <Select
                                required
                                placeholder="-- Select Option --"
                                isSearchable={true}
                                options={trackingForOptions.map(i => ({
                                    value: i.id,
                                    label: i.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingForOption(
                                        e.value
                                    );
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        {
                            selectedTrackingForOption === 1 &&
                            <div className="col-6">
                                <label>Supplier Proforma</label>
                                <Select
                                    required
                                    // isDisabled={selectedTrackingForOption != 1}
                                    placeholder="-- Select Proforma --"
                                    isSearchable={true}
                                    defaultValue={{ label: editingShipment.supplierProformaId > 0 ? editingShipment.supplierInvoice : "", value: editingShipment.supplierProformaId ?? 0 }}
                                    options={supplierProformas.map(p => ({
                                        value: p.jSupplierOrder,
                                        label: p.jSupplierOrder.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            supplierProformaId: e.value.supplierOrderId
                                        }))
                                        handleSupplierProformaChange(e.value.supplierOrderId, true);
                                    }}>
                                </Select>
                            </div>
                        }
                        {
                            selectedTrackingForOption === 2 &&
                            <div className="col-6">
                                <label>Supplier Commercial Invoice</label>
                                <Select
                                    required
                                    // isDisabled={selectedTrackingForOption!=2}
                                    placeholder="-- Select Invoice --"
                                    isSearchable={true}
                                    defaultValue={{ label: editingShipment.supplierCommercialInvoiceId > 0 ? editingShipment.supplierInvoice : "", value: editingShipment.supplierCommercialInvoiceId ?? 0 }}
                                    options={supplierCommercialInvoices.map(i => ({
                                        value: i.sci,
                                        label: "#" + i.sci.invoiceNumber
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            supplierCommercialInvoiceId: e.value.commercialInvoiceId
                                        }))
                                        handleSupplierCommercialChange();
                                    }}>
                                </Select>
                            </div>
                        }
                        {
                            selectedTrackingForOption === 1 && editingShipment.supplierProformaId != null && editingShipment.supplierProformaId > 0 &&
                            <div className="col-6">
                                <label>Client Proforma</label>
                                <Select
                                    required
                                    placeholder="-- Select Client Invoice --"
                                    isSearchable={true}
                                    defaultValue={{
                                        label: editingShipment.clientInvoiceId > 0 ? ("#" + editingShipment.clientInvoiceNumber + " - " + editingShipment.clientName) : "",
                                        value: editingShipment
                                    }}
                                    options={clientProformas.map(i => ({
                                        value: i,
                                        label: "#" + i.invoiceNumber + " - " + i.customerName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            clientInvoiceId: e.value.customerOrderId,
                                            clientInvoiceNumber: e.value.invoiceNumber,
                                            clientId: e.value.customerId,
                                            clientName: e.value.customerName,
                                        }))
                                    }}>
                                </Select>
                            </div>
                        }
                    </div>
                    <br />
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Incoterms</label>
                            <Select
                                required
                                placeholder="-- Select Incoterms --"
                                isSearchable={true}
                                defaultValue={{ label: editingShipment.incotermName, value: editingShipment.incotermId }}
                                options={incoterms.map(i => ({
                                    value: i,
                                    label: i.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingShipment((prevState) => ({
                                        ...prevState,
                                        incotermId: e.value.incotermId,
                                        incotermName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                    </div>
                    <br />
                    {
                        editingShipment.statusId <= 20 &&
                        <>
                            <div className="mb-3 row">
                                <div className="col-6">
                                    <label>Shipping Line</label>
                                    <Select
                                        required
                                        placeholder="-- Select Shipping Line --"
                                        isSearchable={true}
                                        isDisabled={editingShipment.statusId != null && editingShipment.statusId > 0}
                                        defaultValue={{ label: editingShipment.shippingLine, value: editingShipment.shippingLineId }}
                                        options={shippingLines.map(l => ({
                                            value: l,
                                            label: l.name
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            setEditingShipment((prevState) => ({
                                                ...prevState,
                                                shippingLine: e.value.name,
                                                shippingLineId: e.value.shippingLineId
                                            }))
                                            // handleSupplierInvoiceChange(e.value);
                                        }}>
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <label>Track with</label>
                                    <Select
                                        required
                                        placeholder="-- Select Tracking Option --"
                                        isSearchable={true}
                                        isDisabled={editingShipment.statusId > 0}
                                        options={trackingOptions.map(l => ({
                                            value: l,
                                            label: l.label
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            setSelectedTrackingOption(e.value.id);
                                        }}>
                                    </Select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                {
                                    selectedTrackingOption === 2 &&
                                    <div className="col-6">
                                        <label>BL Reference Number</label>
                                        <input className="form-control"
                                            type="text"
                                            // disabled={selectedTrackingOption != 2 || editingShipment.statusId > 0}
                                            placeholder='Enter BL number'
                                            value={editingShipment.blContainersRef}
                                            onChange={(e) =>
                                                setEditingShipment((prevState) => ({
                                                    ...prevState,
                                                    blContainersRef: e.target.value.trim()
                                                }))
                                            } required />
                                    </div>
                                }
                                {
                                    selectedTrackingOption === 1 &&
                                    <div className="col-6">
                                        <label>Container Number</label>
                                        <input className="form-control"
                                            type="text"
                                            // disabled={selectedTrackingOption != 1 || editingShipment.statusId > 0}
                                            placeholder='Enter container number'
                                            value={editingShipment.containerNumber}
                                            onChange={(e) =>
                                                setEditingShipment((prevState) => ({
                                                    ...prevState,
                                                    containerNumber: e.target.value.trim()
                                                }))
                                            } required />
                                    </div>
                                }
                            </div>
                            <br />
                        </>
                    }
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control" type="text" value={editingShipment.notes} onChange={(e) => {
                                setEditingShipment((prevState) => ({
                                    ...prevState,
                                    notes: e.target.value,
                                }))
                            }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditShipmentModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitEditShipmentForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>


            <Modal show={showLinkShipmentToOrderModal} onHide={handleCloseLinkShipmentToOrderModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Shipment for Invoice #{editingShipment.supplierInvoice}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        {
                            editingShipment.statusId <= 20 &&
                            <div className="col-12">
                                <label>Shipping Line</label>
                                <Select
                                    required
                                    placeholder="-- Select Shipping Line --"
                                    isSearchable={true}
                                    // isDisabled={editingShipment.statusId != null && editingShipment.statusId > 0}
                                    // defaultValue={{label: editingShipment.shippingLine, value: editingShipment.shippingLineId}}
                                    options={shippingLines.map(l => ({
                                        value: l,
                                        label: l.name
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        setEditingShipment((prevState) => ({
                                            ...prevState,
                                            shippingLine: e.value.name,
                                            shippingLineId: e.value.shippingLineId
                                        }))
                                        // handleSupplierInvoiceChange(e.value);
                                    }}>
                                </Select>
                            </div>
                        }
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Track with</label>
                            <Select
                                required
                                placeholder="-- Select Tracking Option --"
                                isSearchable={true}
                                // isDisabled={editingShipment.statusId > 0}
                                options={trackingOptions.map(l => ({
                                    value: l,
                                    label: l.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingOption(e.value.id);
                                }}>
                            </Select>
                        </div>
                    </div>
                    {
                        <div className="mb-3 row">
                            {
                                selectedTrackingOption === 2 &&
                                <div className="col-12">
                                    <label>BL Reference Number</label>
                                    <input className="form-control"
                                        type="text"
                                        // disabled={selectedTrackingOption != 2 || editingShipment.statusId > 0}
                                        placeholder='Enter BL number'
                                        value={editingShipment.blContainersRef}
                                        onChange={(e) =>
                                            setEditingShipment((prevState) => ({
                                                ...prevState,
                                                blContainersRef: e.target.value.trim()
                                            }))
                                        } required />
                                </div>
                            }
                            {
                                selectedTrackingOption === 1 &&
                                <div className="col-12">
                                    <label>Container Number</label>
                                    <input className="form-control"
                                        type="text"
                                        // disabled={selectedTrackingOption != 1 || editingShipment.statusId > 0}
                                        placeholder='Enter container number'
                                        value={editingShipment.containerNumber}
                                        onChange={(e) =>
                                            setEditingShipment((prevState) => ({
                                                ...prevState,
                                                containerNumber: e.target.value.trim()
                                            }))
                                        } required />
                                </div>
                            }
                        </div>
                    }
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLinkShipmentToOrderModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitEditShipmentForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showLinkCommercialModal} onHide={handleCloseLinkCommercialModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Link BL #{linkingCommercialForBL} to Commercial Invoices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className=" mt-3">Supplier Commercial Invoices</label>
                        <div>
                            {
                                selectedCommercialInvoices.map((co, index) => (
                                    <div key={index}>
                                        <div className='mb-3 row'>
                                            <div className="col-10 mb-1 mt-1">
                                                <Select
                                                    required
                                                    placeholder="-- Select Invoice --"
                                                    isSearchable={true}
                                                    options={availableSupplierCommercialInvoicesByTrackingInfo.map(i => ({
                                                        value: i.sci,
                                                        label: "#" + i.sci.invoiceNumber
                                                    }))}
                                                    valuekey

                                                    value={co.commercialInvoiceNumber ? { value: co.supplierCommercialInvoiceId, label: co.commercialInvoiceNumber } : null}
                                                    onChange={(e) => {
                                                        const selectedCO = e.value;
                                                        co.supplierCommercialInvoiceId = selectedCO.commercialInvoiceId;
                                                        co.commercialInvoiceNumber = selectedCO.invoiceNumber;
                                                        setSelectedCommercialInvoices(prevInvoices => {
                                                            const updatedInvoices = [...prevInvoices];
                                                            updatedInvoices[index] = {
                                                                ...updatedInvoices[index],
                                                                supplierCommercialInvoiceId: selectedCO.commercialInvoiceId,
                                                                commercialInvoiceNumber: selectedCO.invoiceNumber
                                                            };
                                                            return updatedInvoices;
                                                        });
                                                        handleSupplierCommercialChange();
                                                    }}
                                                >
                                                </Select>
                                            </div>
                                            <div className="col-1 mb-1 mt-1">
                                                {permissions.deleteShipment === true && (
                                                    <button className="btn btn-danger btn-md" onClick={() => {
                                                        deleteSelectedCommercialInvoice(co.supplierCommercialInvoiceId);
                                                    }}>
                                                        <FiTrash2 />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {permissions.addShipment === true && (
                            <button disabled={addAnotherCommercialInvoiceButtonDisabled}
                                className="waves-effect waves-light btn btn-md btn-info mr-3"
                                onClick={() => handleLinkCommercialInvoicesAdd()}><FiPlus /> Add Another Invoice</button>
                        )}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLinkCommercialModal}>
                        Close
                    </Button>
                    <Button variant="primary"
                        disabled={submitDisabled}
                        onClick={(e) => submitLinkShipmentForm(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteShipmentModal} onHide={handleCloseDeleteShipmentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Shipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete shipment with BL #{shipmentDetails.blReferenceNo}?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteShipmentModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => submitDeleteShipmentForm(e)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showEditIncotermsModal} onHide={handleCloseEditIncotermsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Incoterms for BL #{shipmentDetails.blReferenceNo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Incoterms</label>
                            <Select
                                required
                                placeholder="-- Select Incoterms --"
                                isSearchable={true}
                                Value={editingIncoterms.incotermName == null ? null : { label: editingIncoterms.incotermName, value: editingIncoterms.incotermId }}
                                options={incoterms.map(i => ({
                                    value: i,
                                    label: i.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingIncoterms((prevState) => ({
                                        ...prevState,
                                        incotermId: e.value.incotermId,
                                        incotermName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditIncotermsModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitEditIncotermsForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={showAddCommercialInvoice} onHide={handleCloseAddCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Commercial Invoice #{commercialInvoice.invoiceNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="row ">
                                <div className="col-12 mb-3">
                                    <label>Invoice Number</label>
                                    <input className="form-control"
                                        placeholder="Enter commercial invoice number"
                                        value={commercialInvoice.invoiceNumber}
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                invoiceNumber: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-12 mb-3">
                                    <label>Customer Invoice Number</label>
                                    <input className="form-control"
                                        placeholder="Enter client commercial invoice number"
                                        value={commercialInvoice.clientInvoiceNumber}
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                clientInvoiceNumber: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-12 mb-3">
                                    <label>Date</label>
                                    <input className="form-control" type="date" defaultValue={commercialInvoice.date}
                                        onChange={(e) => setCommercialInvoice((prevState) => ({
                                            ...prevState,
                                            date: e.target.value
                                        }))}></input>
                                </div>
                                <div className="col-12">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        placeholder="Your notes (Optional)"
                                        value={commercialInvoice.notes}
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                notes: e.target.value
                                            }));
                                        }}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialInvoice}>
                        Close
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => EditCommericalInvoice(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDeleteCommercialInvoice} onHide={handleCloseDeleteInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this commercial invoice?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseDeleteInvoice}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => DeleteInvoice(e)}>
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showEditNotesModal} onHide={handleCloseEditNotesModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Notes for BL #{shipmentDetails.blReferenceNo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                placeholder="Your notes (Optional)"
                                type="text" value={editingNotes.notes}
                                onChange={(e) => {
                                    setEditingNotes((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value,
                                    }))
                                }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditNotesModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitEditNotesForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showAddTrackingInfoModal} onHide={handleCloseAddTrackingInfoModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Tracking Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Shipping Line</label>
                            <Select
                                required
                                placeholder="-- Select Shipping Line --"
                                isSearchable={true}
                                options={shippingLines.map(l => ({
                                    value: l,
                                    label: l.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setTracking((prevState) => ({
                                        ...prevState,
                                        shippingLine: e.value.name,
                                        shippingLineId: e.value.shippingLineId
                                    }))
                                    // handleSupplierInvoiceChange(e.value);
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Track with</label>
                            <Select
                                required
                                placeholder="-- Select Tracking Option --"
                                isSearchable={true}
                                options={trackingOptions.map(l => ({
                                    value: l,
                                    label: l.label
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setSelectedTrackingOption(e.value.id);
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        {
                            selectedTrackingOption === 2 &&
                            <div className="col-12">
                                <label>BL Reference Number</label>
                                <input className="form-control"
                                    type="text"
                                    maxLength={20}
                                    // disabled={selectedTrackingOption!=2}
                                    placeholder='Enter BL number'
                                    value={tracking.blContainersRef}
                                    onChange={(e) => {
                                        setTracking((prevState) => ({
                                            ...prevState,
                                            blContainersRef: e.target.value.trim()
                                        }))
                                    }} required />
                            </div>
                        }
                        {
                            selectedTrackingOption === 1 &&
                            <div className="col-12">
                                <label>Container Number</label>
                                <input className="form-control"
                                    type="text"
                                    // disabled={selectedTrackingOption!=1}
                                    placeholder='Enter container number'
                                    value={tracking.containerNumber}
                                    onChange={(e) =>
                                        setTracking((prevState) => ({
                                            ...prevState,
                                            containerNumber: e.target.value.trim()
                                        }))
                                    } required />
                            </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddTrackingInfoModal}>
                        Close
                    </Button>
                    {
                        <Button variant="primary"
                            disabled={submitDisabled}
                            onClick={(e) => submitTrackingInfoForm(e)}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showCreateCommercialInvoiceModal} onHide={handleCloseCreateCommercialInvoiceModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Commercial Invoice for Order #{shipmentDetails.supplierInvoice}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="row ">
                                <div className="col-6 mb-3">
                                    <label>Supplier Invoice Number</label>
                                    <input className="form-control"
                                        value={commercialInvoice.invoiceNumber}
                                        placeholder="Enter commercial invoice number"
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                invoiceNumber: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-6 mb-3">
                                    <label>Date</label>
                                    <input className="form-control" type="date" defaultValue={commercialInvoice.date}
                                        onChange={(e) => setCommercialInvoice((prevState) => ({
                                            ...prevState,
                                            date: e.target.value
                                        }))}></input>
                                </div>
                                <div className="col-12">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        placeholder="Your notes (Optional)"
                                        value={commercialInvoice.notes}
                                        onChange={(e) => {
                                            setCommercialInvoice((prevState) => ({
                                                ...prevState,
                                                notes: e.target.value
                                            }));
                                        }}></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {/* Products */}
                                <div>
                                    <h5 htmlFor="txtPositionName" className="col-md-11 mt-3">Products</h5>
                                    <hr />
                                    <table className='table table-bordered'>
                                        <thead className='bg-light'>
                                            <tr>
                                                <th className="col-md-1"></th>
                                                <th className="col-md-5">Description</th>
                                                <th className="col-md-3">Number of Containers</th>
                                                <th className="col-md-3">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {proformaProducts.map((product, index) => (
                                                <tr key={index}>
                                                    <td className="col-md-1">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedProductsIds.includes(product.supplierOrderProductId)}
                                                            onChange={(e) => e.target.checked ? addProduct(product) : deleteProduct(product.supplierOrderProductId, index)}
                                                        />
                                                    </td>
                                                    <td className="col-md-5">
                                                        <label>
                                                            {product.productName}, {product.productTypeName}, {product.productSizeName}
                                                            <br />
                                                            (Available: {product.pendingCfcl} FCL, {product.pendingCquantity} {product.productUnit1})
                                                        </label>
                                                    </td>
                                                    <td className="col-md-3">
                                                        <input type="number"
                                                            className="form-control"
                                                            placeholder="Number of FCLs"
                                                            value={product.fcl}
                                                            min="0"
                                                            max={product.pendingCfcl}
                                                            onChange={(e) => {
                                                                const value = Math.max(0, Math.min(product.pendingCfcl, e.target.value)); // Ensure value stays within bounds
                                                                product.fcl = value;
                                                                setProformaProducts(prevProducts => {
                                                                    const updatedProducts = [...prevProducts];
                                                                    updatedProducts[index] = {
                                                                        ...updatedProducts[index],
                                                                        fcl: value
                                                                    };
                                                                    return updatedProducts;
                                                                });
                                                                addProduct(product);
                                                            }} />
                                                    </td>
                                                    <td className="col-md-3">
                                                        <input type="number"
                                                            className="form-control"
                                                            placeholder={`Quantity in ${product.productUnit1}`}
                                                            value={product.qty}
                                                            min="0"
                                                            onChange={(e) => {
                                                                const value = Math.max(e.target.value, 0); // Ensure value stays within bounds
                                                                product.qty = value;
                                                                setProformaProducts(prevProducts => {
                                                                    const updatedProducts = [...prevProducts];
                                                                    updatedProducts[index] = {
                                                                        ...updatedProducts[index],
                                                                        qty: value
                                                                    };
                                                                    return updatedProducts;
                                                                });
                                                                addProduct(product);
                                                            }} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseCreateCommercialInvoiceModal}>
                        Close
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => AddCommercialInvoice(e)}
                    >
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showConfirmAssignOrderToUser} onHide={handleCloseConfirmAssignOrderToUser} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{shipmentDetails.clientName} <b>(#{shipmentDetails.clientProformaInvoice})</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to assign this order to you?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseConfirmAssignOrderToUser}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => AssignOrderToUser(e)}
                    >
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showConfirmUnAssignOrderToUser} onHide={handleCloseConfirmUnAssignOrderToUser} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{shipmentDetails.clientName} <b>(#{shipmentDetails.clientProformaInvoice})</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to unassign this order?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseConfirmUnAssignOrderToUser}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => UnassignOrderToUser(e)}
                    >
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showAddShippingCostModal} onHide={handleCloseAddShippingCostModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{shippingCost.isCredit ? "Add Credit" : "Add Shipping Cost"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>{shippingCost.isCredit ? "Credit Type" : "Shipping Cost Type"}</label>
                            <Select
                                required
                                placeholder="-- Select Type --"
                                defaultValue={shippingCost.shippingCostOptionName ? { label: shippingCost.shippingCostOptionName, value: shippingCost.shippingCostsOptionId } : null}
                                isSearchable={true}
                                options={shippingCostTypes.map(f => ({
                                    value: f,
                                    label: f.name
                                }))}

                                valuekey
                                onChange={(e) => {
                                    setShippingCost((prevState) => ({
                                        ...prevState,
                                        shippingCostsOptionId: e.value.shippingCostsOptionId,
                                        shippingCostOptionName: e.value.name,
                                    }));
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Forwarder</label>
                            <Select
                                required
                                placeholder="-- Select Forwarder --"
                                isSearchable={true}
                                defaultValue={shippingCost.forwarderName ? { label: shippingCost.forwarderName, value: shippingCost.forwarderId } : null}
                                options={forwarders.map(f => ({
                                    value: f,
                                    label: f.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setShippingCost((prevState) => ({
                                        ...prevState,
                                        forwarderId: e.value.forwarderId,
                                        forwarderName: e.value.name
                                    }));
                                    setForwarderAccounts(e.value.accounts);
                                }}>
                            </Select>
                        </div>
                        <div className="col-6">
                            <label>Forwarder Account</label>
                            <Select
                                required
                                placeholder="-- Select Forwarder Account--"
                                isSearchable={true}
                                defaultValue={shippingCost.forwarderAccountName ? { label: shippingCost.forwarderAccountName, value: shippingCost.forwarderAccountId } : null}
                                options={forwarderAccounts.map(fa => ({
                                    value: fa,
                                    label: fa.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setShippingCost((prevState) => ({
                                        ...prevState,
                                        forwarderAccountId: e.value.forwarderAccountId,
                                        forwarderAccountName: e.value.name,
                                        currencySuffix: e.value.currency.currencySuffix,
                                        currencyId: e.value.currency.currencyId
                                    }));
                                    setSameCurrency(shipmentDetails.supplierAccountCurrencyId === 0 || e.value.currency.currencyId === shipmentDetails.supplierAccountCurrencyId);
                                    if (sameCurrency) {
                                        setShippingCost((prevState) => ({
                                            ...prevState,
                                            exchangeRate: 1.0,
                                        }))
                                    }
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>Invoice Number</label>
                            <input className="form-control"
                                type="text"
                                placeholder='Enter invoice number'
                                value={shippingCost.invoiceNumber}
                                onChange={(e) =>
                                    setShippingCost((prevState) => ({
                                        ...prevState,
                                        invoiceNumber: e.target.value
                                    }))
                                } required />
                        </div>
                        <div className="col-6">
                            <label htmlFor="txtPositionName" className="col-md-12 col-form-label">Date</label>
                            <input className="form-control" type="date" value={shippingCost.date.split('T')[0]}
                                onChange={(e) => {
                                    setShippingCost((prevState) =>
                                    ({
                                        ...prevState,
                                        date: e.target.value
                                    }))
                                }
                                } required />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-6">
                            <label>{isCredit ? "Credit Amount" : "Amount"}</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    {shippingCost.currencySuffix}
                                </span>

                                <input type="number" className="form-control" required
                                    value={shippingCost.amount}
                                    disabled={shippingCost.isPaid === true}
                                    onChange={(e) => {
                                        setShippingCost((prevState) => ({
                                            ...prevState,
                                            amount: e.target.value
                                        }))
                                    }}>
                                </input>
                            </div>
                        </div>
                        {sameCurrency == false && (
                            // supplierAccountCurrencyId
                            // supplierAccountCurrencySuffix
                            <>
                                <div className="col-6">
                                    <label>{shippingCost.currencySuffix} to {shipmentDetails.supplierAccountCurrencySuffix} Exchange Rate <i class="fas fa-info-circle" id="tooltip1" />
                                        <UncontrolledTooltip placement="top" target="tooltip1">
                                            Since the forwarder account currency is different than the supplier invoice currency, the exchange rate is required.
                                        </UncontrolledTooltip></label>
                                    <input type="number" className="form-control" required
                                        value={shippingCost.exchangeRate}
                                        step={0.1}
                                        onChange={(e) => {
                                            setShippingCost((prevState) => ({
                                                ...prevState,
                                                exchangeRate: e.target.value
                                            }));
                                        }}>
                                    </input>
                                </div>
                            </>
                        )}
                    </div>
                    <br />
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control" type="text" value={shippingCost.notes} onChange={(e) => {
                                setShippingCost((prevState) => ({
                                    ...prevState,
                                    notes: e.target.value,
                                }))
                            }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddShippingCostModal}>
                        Close
                    </Button>
                    <Button variant="primary"
                        disabled={submitDisabled} onClick={(e) =>
                            submitShippingCostForm(e)
                        }>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteShippingCostModal} onHide={handleCloseDeleteShippingCostModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{deletingShippingCost.shippingCostOptionName} <b>#{deletingShippingCost.invoiceNumber}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to delete this invoice?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseDeleteShippingCostModal}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => DeleteShippingCost(e)}
                    >
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteShippingBLModal} onHide={handleCloseDeleteShippingBLModal} backdrop="static">
                <Modal.Body>
                    <h5>Are you sure you want to remove BL number?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseDeleteShippingBLModal}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={submitDisabled}
                        onClick={(e) => DeleteTrackingBL(e)}
                    >
                        Yes, Proceed
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export default Shipments