import { Route, Routes, Navigate } from "react-router-dom";
import Forget from "../Pages/Login/Forget";
import SetNewPassword from "../Pages/Login/SetNewPassword";
import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import React from "react";
import LockScreen from "../Pages/Login/auth-lock-screen";
import { getDecodedToken } from '../utils/utils';
import { Global } from '../Variables/Global';

const AppRoutes = (props) => {
    const decodedToken = getDecodedToken();
    const isAuthenticated = !!decodedToken;
    var screenReloded = sessionStorage.getItem('screenReloded') === 'true';
    var sessionExpired = sessionStorage.getItem('sessionExpired') === 'true';
    var userId = localStorage.getItem("uTsReD");

    let mainComponent;
    if (sessionExpired) {
        localStorage.clear();
        mainComponent = <Navigate to="/login" />;

    }
    else if (isAuthenticated) {
        mainComponent = <Dashboard />;

    }
    else {
        if (userId || sessionExpired) {
            sessionStorage.setItem('sessionExpired', 'true');
            mainComponent = <Navigate to="/auth-lock-screen" />;
            localStorage.clear();

        } else
            mainComponent = <Navigate to="/login" />;
        if (!screenReloded) {
            window.location.reload();
            sessionStorage.setItem('screenReloded', 'true');
        }
    }

    return (
        <Routes>
            <Route path="/login" element={<Login setUser={props.setUser} setIsLogged={props.setIsLogged} />} />
            <Route path="/forgetpassword" element={<Forget />} />
            <Route path="/setnewpassword/:EmployeeId" element={<SetNewPassword setUser={props.setUser} setIsLogged={props.setIsLogged} />} />
            <Route path="/test" element={<Dashboard />} />
            <Route path="/auth-lock-screen" element={<LockScreen setUser={props.setUser} setIsLogged={props.setIsLogged} />} />
            <Route path="*" element={mainComponent} />
        </Routes>
    );
};

export default AppRoutes;
