import React, { useEffect, useState } from "react";
import { Global } from '../../Variables/Global';
import { FiEdit, FiEye, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axiosInstance from '../../api/axiosConfig';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Card, CardBody, Col, Row } from "reactstrap"
import { formatDateString } from '../../utils/utils';
// import { format, formatDistanceToNow, parseISO } from 'date-fns';

// const parseISO = require('date-fns/parseISO');
// const format = require('date-fns/format');
// const formatDistanceToNow = require('date-fns/formatDistanceToNow');



const NotificationsCenter = () => {
  // Function to calculate relative time
   // Function to calculate relative time

  
      
    const pId = useParams();
    const navigate = useNavigate();

    const [notifications, setNotifications] = useState([])
    const GetNotifications = () => {
        var config = {
            method: 'get',
            url: "Notifications/GetNotifications",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params:{
                'UserId': localStorage.getItem("uTsReD"),
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setNotifications(response.data.notifications);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const DeleteNotification = (notificationCenterId) => {
        var config = {
            method: 'delete',
            url: "Notifications/DeleteNotification",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params:{
                'UserId': localStorage.getItem("uTsReD"),
                'NotificationCenterId': notificationCenterId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setNotifications(response.data.notifications);
                handleCloseDeleteAllNotificationsModal();
                handleCloseDeleteNotificationModal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const SetNotificationRead = (notificationCenterId) => {
        var config = {
            method: 'put',
            url: "Notifications/SetNotificationRead",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params:{
                'UserId': localStorage.getItem("uTsReD"),
                'NotificationCenterId': notificationCenterId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setNotifications(response.data.notifications);
                // handleCloseDeleteAllNotificationsModal();
                // handleCloseDeleteNotificationModal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [showDeleteAllNotificationsModal, setShowDeleteAllNotificationsModal] = useState(false)
    const [showDeleteNotificationModal, setShowDeleteNotificationModal] = useState(false)
    const [deletingNotificationId, setDeletingNotificationId] = useState(0)

    const handleCloseDeleteAllNotificationsModal = () => {
        setShowDeleteAllNotificationsModal(false)
    }
    const handleShowDeleteAllNotificationsModal = () => {
        setShowDeleteAllNotificationsModal(true);
    }

    const handleCloseDeleteNotificationModal = () => {
        setShowDeleteNotificationModal(false)
    }
    const handleShowDeleteNotificationModal = (notificationCenterId) => {
        setDeletingNotificationId(notificationCenterId);
        setShowDeleteNotificationModal(true);
    }

    const setSelectedNotificationRow = (row) => {
        if (!row.actionValue) {
          // Handle cases where row.actionValue might be empty or undefined
          console.error('row.actionValue is empty or undefined!');
          return;
        }
      
        var url = Global.Host + row.actionValue;
        // Check if row.actionValue is a full URL using a regular expression
        const isFullUrl = /^(http|https):\/\/[^ "]+$/.test(url);
      
        SetNotificationRead(row.notificationCenterId);
        if (isFullUrl) {
          // Open the URL in a new tab using window.open()
          window.open(url, '_blank');
        } else {
          // Handle cases where row.actionValue is not a full URL (optional)
          console.warn('row.actionValue is not a full URL. Consider internal navigation or other handling.');
          // You can add logic here to handle non-full URLs, such as relative paths or internal navigation
        }
      };

      function sanitizeHTML(html) {
        const tempDiv = document.createElement('div');
        tempDiv.textContent = html;
        return tempDiv.innerHTML;
      }

    useEffect(() => {
        // const decodedToken = getDecodedToken();
        // const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        // var x = JSON.parse(pJoin);
        // setPermissions(() => x);
        GetNotifications();
        document.title = "Notifications Center";
    }, [])
    
    
    const columns = [
        {
            name: 'Actions',
            cell: (row) => (
                <button
                    className="btn btn-danger btn-sm mr-3"
                    onClick={() => handleShowDeleteNotificationModal(row.notificationCenterId)}
                >
                    <FiTrash2 />
                </button>
            ),
            width: "80px",
            conditionalCellStyles: [
                {
                    when: row => !row.isRead,
                    style: {
                        backgroundColor: 'rgba(245, 178, 37, 0.1)',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: 'Notification Details',
            cell: (row) => (
                <div
                    onClick={() => setSelectedNotificationRow(row)}
                    className="p-3 hover:bg-gray-100 transition duration-300 cursor-pointer"
                    style={{ display: 'flex', flexGrow: 1, minWidth: 0 }}
                >
                    <div style={{ flexGrow: 1, minWidth: 0 }}>
                        <div className="row" style={{ width: '100%', display: 'flex', minWidth: 0 }}>
                            <div className="col-9">
                                <strong className="text-wrap text-lg text-gray-800">{row.title}</strong>
                            </div>
                            <div className='col-3 text-right'>
                                <span style={{ color: '#936b16', backgroundColor: '#fdf0d3' , borderRadius: 5, padding: '0 5px' }}>{formatDateString(row.date)}</span>
                                {/* <span className="text-wrap" style={{ color: "#777777" }}>
                                    {formatDateString(row.date)}
                                </span> */}
                            </div>
                        </div>
                        {row.body && (
                            <>
                                <br />
                                <span className="text-wrap" style={{ color: "#777777", width: '100%' }} dangerouslySetInnerHTML={{ __html: row.body }} />
                            </>
                        )}
                    </div>
                </div>
            ),
            conditionalCellStyles: [
                {
                    when: row => !row.isRead,
                    style: {
                        backgroundColor: 'rgba(245, 178, 37, 0.1)',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
        {
            name: '',
            cell: (row) => (
                <div onClick={() => handleShowDeleteAllNotificationsModal(row.notificationCenterId)} className="align-items-center justify-content-end cursor-pointer">
                    <i className="ti-angle-right"></i>
                </div>
            ),
            width: "50px",
            conditionalCellStyles: [
                {
                    when: row => !row.isRead,
                    style: {
                        backgroundColor: 'rgba(245, 178, 37, 0.1)',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            ],
        },
    ];
    
    

    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer', // Set cursor to pointer to indicate clickable rows
            },
        },
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="page-title-box">
                                <h4>Notifications Center</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item active">Notifications Center</li>
                                </ol>
                            </div>
                        </div>
                        <div className='col-sm-3 text-right'>
                            <div className='flex justify-end'>
                                <button onClick={() => {
                                    setShowDeleteAllNotificationsModal(true);
                                }
                                } className="ml-1 btn btn-danger"><FiTrash2/> Clear All</button>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ height: '75vh' }}>
                        {/* Suppliers List */}
                        <div className="col-sm-12" style={{ height: '100%', overflowY: 'auto' }}>
                        <DataTableExtensions columns={columns} data={notifications} print={false} export={false}>
                            <DataTable
                                onRowClicked={(row) => setSelectedNotificationRow(row)}
                                noHeader={true}
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="md"  show={showDeleteAllNotificationsModal} onHide={handleCloseDeleteAllNotificationsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete All Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete all notifications?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteAllNotificationsModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteNotification(-1)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md"  show={showDeleteNotificationModal} onHide={handleCloseDeleteNotificationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this notification?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteNotificationModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteNotification(deletingNotificationId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
export default NotificationsCenter