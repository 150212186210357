import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import DataTable from "react-data-table-component"
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Variables/Global";
import { formatDate } from '../../../../../Classes/Util'
import { getDecodedToken } from '../../../../../utils/utils';
import axiosInstance from '../../../../../api/axiosConfig';

const ListOrderAdvancedPayments = (props) => {

    const pId = useParams();


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    // const handleShowEdit = () => setShowEdit(true);

    const [payments, setPayments] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [payment, setPayment] = useState({
        customerAccountPayment: 0,
        notes: "",
        paymentDate: formatDate(new Date()),
        accountId: pId.AccountId,
        bankId: 0,
        bankAccountId: 0,
        bankAmount: 0,
        exchangeRate: 1,
        customerOrderId: 0,
        customerName: "",
        customerAccount: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
    })

    const columns = [
        {
            name: '#',
            selector: row => row.customerAccountPaymentId,
            width: "60px"
        },
        {
            name: 'Payment Value',
            selector: row => row.customerAccountPayment1.toLocaleString() + " " + currency.currencySuffix,
        },
        {
            name: 'Date',
            selector: row => row.paymentDate ? row.paymentDate.split("T")[0] : row.createdDate.split("T")[0],
        },
        {
            name: 'Notes',
            selector: row => {
                return (
                    <>
                        <div>
                            <span className="text-wrap">{row.notes ?? ''}</span>
                        </div>
                    </>
                )
            },
        },
        {
            name: 'Options',
            width: "130px",
            selector: row => {
                return (
                    <>
                        {permissions.editCustomer === true &&
                            <button className='btn btn-warning  btn-sm ml-1' onClick={() => {
                                handleEdit(row)
                            }}><FiEdit /></button>
                        }
                        {/* <Link to={"/Accounting/customer/" + props.customerId + "/Account/" + props.customerAccountId + "/Order/" + row.customerOrderId} className="btn btn-outline-success mr-3"><FiEye /></Link>
                        <button onClick={() => { props.setcustomerOrderId(row.customerOrderId); props.setShowcustomerOrderList(2) }} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </button> */}
                        {permissions.deleteCustomer === true &&
                            <button className='btn btn-danger btn-sm ml-1' onClick={() => {
                                DeletePayment(row.customerAccountPaymentId)
                            }}><FiTrash2 /></button>
                        }
                        {/* <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button> */}
                    </>
                )
            },
        },
    ];

    const GetAdvancedPayments = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccountPayments/GetCustomerAccountPayments/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setPayments(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [account, setAccount] = useState({
        customerAccount1: ""
    });
    const GetAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setAccount(response.data)
                GetCurrency(response.data.currencyId);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [customer, setCustomer] = useState({
        customerName: ""
    })
    const GetCustomer = (CustomerId) => {
        var config = {
            method: 'get',
            url: "Customer/GetCustomers/" + CustomerId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomer(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [banks, setBanks] = useState([])
    const GetBanks = () => {
        var config = {
            method: 'get',
            url: "Bank/GetCompanyBanks/" + localStorage.getItem("C0m8N!"),
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBanks(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [bankAccounts, setBankAccounts] = useState([])
    const GetBankAccounts = (bankId, bankAccountId) => {
        var config = {
            method: 'get',
            url: "BankAccount/GetBankAccounts/" + bankId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBankAccounts(response.data.data);
                if (bankAccountId > 0) {
                    const bankAccount = response.data.data.find(x => x.bankAccountId === bankAccountId);
                    setSameCurrency(bankAccount.currencyId === currency.currencyId);
                    GetBankCurrency(bankAccount.currencyId);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [sameCurrency, setSameCurrency] = useState(true)
    const [bankCurrency, setBankCurrency] = useState({
        currencySuffix: "",
    }
    )

    const GetBankCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBankCurrency(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const CalculateExchangeRate = (bankAmount) => {
        var x = bankAmount;
        var y = payment.customerAccountPayment;
        var z = y === 0 ? 0 : x / y;
        setPayment((prevState) => ({ ...prevState, exchangeRate: z }))
    }

    const CalculateBankAmount = (exchangeRate) => {
        var x = exchangeRate;
        var y = payment.customerAccountPayment;
        var z = y * x;
        setPayment((prevState) => ({ ...prevState, bankAmount: z }))
    }

    const reloadBankAmount = (originalPayment) => {
        var x = originalPayment;
        var y = payment.exchangeRate;
        var z = x * y;
        console.log(z)
        setPayment((prevState) => ({ ...prevState, bankAmount: z }))
        console.log(payment)
    }


    const CalculateEditExchangeRate = (bankAmount) => {
        var x = bankAmount;
        var y = editingPayment.customerAccountPayment;
        var z = y === 0 ? 0 : x / y;
        setEditingPayment((prevState) => ({ ...prevState, exchangeRate: z }))
    }

    const CalculateEditBankAmount = (exchangeRate) => {
        var x = exchangeRate;
        var y = editingPayment.customerAccountPayment;
        var z = y * x;
        setEditingPayment((prevState) => ({ ...prevState, bankAmount: z }))
    }
    const reloadEditBankAmount = (originalPayment) => {
        var x = originalPayment;
        var y = editingPayment.exchangeRate;
        var z = x * y;
        console.log(z)
        setEditingPayment((prevState) => ({ ...prevState, bankAmount: z }))
        console.log(payment)
    }
    const IsValid = () => {
        if (payment.bankId === 0 || payment.bankAccountId === 0) {

            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }
    const AddAdvancedPayments = (e) => {
        e.preventDefault();
        if (IsValid()) {

            setSubmitDisabled(true);
            payment.customerAccount = account.customerAccount1;
            payment.customerName = customer.customerName;
            payment.customerId = customer.customerId;
            // payment.customerAccountId = account.customerAccountId;

            // const amount = payment.bankAmount ? payment.bankAmount : payment.customerAdvancedPayment;

            var config = {
                method: 'post',
                url: "CustomerAccountPayments/AddCustomerAccountPayment/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: payment
                // data: {
                //     ...payment,
                // customerAdvancedPayment: amount,
                // exchangeRate: payment.bankAmount / payment.customerAdvancedPayment,
                // customerAccountPaymentId: paymentData.customerAccountPaymentId,
                // customerAccountId: paymentData.accountId
                // }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Added Successfully")
                        setShow(false)
                        GetAdvancedPayments(pId.AccountId)
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setSubmitDisabled(false);
                    toast.error("Something Went Wrong")
                });
        }
    }

    const EditPayment = (e) => {
        e.preventDefault();

        editingPayment.customerAccount = account.customerAccount1;
        editingPayment.customerName = customer.customerName;
        editingPayment.customerId = customer.customerId;
        var config = {
            method: 'put',
            url: "CustomerAccountPayments/EditCustomerAccountPayment/" + editingPayment.customerAccountPaymentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editingPayment
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Updated Successfully")
                    setShowEdit(false)
                    GetAdvancedPayments(pId.AccountId)
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const DeletePayment = (customerAccountPaymentId) => {
        // e.preventDefault();

        editingPayment.customerAccount = account.customerAccount1;
        editingPayment.customerName = customer.customerName;
        editingPayment.customerId = customer.customerId;
        var config = {
            method: 'delete',
            url: "CustomerAccountPayments/DeleteCustomerAccountPayment/" + customerAccountPaymentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            // data: editingPayment
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Deleted Successfully")
                    // setShow(false)
                    GetAdvancedPayments(pId.AccountId)
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const [editingPayment, setEditingPayment] = useState({
        customerAccountPayment: 0,
        notes: "",
        paymentDate: formatDate(new Date()),
        accountId: pId.AccountId,
        bankId: 0,
        bankAccountId: 0,
        bankAmount: 0,
        exchangeRate: 1,
        customerOrderId: 0,
        customerName: "",
        customerAccount: "",
        userId: localStorage.getItem("uTsReD"),
    })

    const handleEdit = (payment) => {
        // setEditingPayment(payment);
        setEditingPayment((prevState) => ({
            ...prevState,
            // accountId: pId.AccountId,
            customerAccountPayment: payment.customerAccountPayment1,
            paymentDate: payment.createdDate.split("T")[0],
            customerAccountPaymentId: payment.customerAccountPaymentId,
            bankId: payment.bankId,
            customerOrderId: 0,
            bankAccountId: payment.bankAccountId,
            bankAmount: payment.bankAmount,
            exchangeRate: payment.bankExchange,
            notes: payment.notes,
            userId: localStorage.getItem("uTsReD"),
        }));

        setShowEdit(true);
        GetBankAccounts(payment.bankId, payment.bankAccountId);
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAdvancedPayments(pId.AccountId);
        GetAccount(pId.AccountId);
        GetCustomer(pId.CustomerId);
        GetBanks();
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Client Account</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Customer/" + customer.customerId + "/Account/" + account.customerAccountId + "/Statment"}>{customer.customerName} - {account.customerAccount1}</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Payments</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <h4 className="card-title">Payments for {customer.customerName} - {account.customerAccount1}</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                            {permissions.addCustomer === true && (
                                                <button className="btn btn-primary mr-3" onClick={handleShow}>Add Payment</button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <hr></hr>
                                            <DataTable
                                                columns={columns}
                                                data={payments} />
                                        </div>
                                    </div>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add a Payment</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-12">
                                                    <label>Payment Value</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-text">{currency.currencySuffix}</div>
                                                        <input type="number" className="form-control" required
                                                            onChange={(e) => {
                                                                setPayment((prevState) => ({
                                                                    ...prevState,
                                                                    customerAccountPayment: e.target.value
                                                                }));
                                                                reloadBankAmount(e.target.value);
                                                            }}>
                                                        </input>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <label>Date</label>
                                                    <input className="form-control mb-3" type="date" value={payment.paymentDate}
                                                        onChange={(e) => {
                                                            setPayment((prevState) => ({
                                                                ...prevState,
                                                                paymentDate: e.target.value
                                                            }));
                                                        }}></input>
                                                </div>
                                                <div className="col-12">
                                                    <label>Bank</label>
                                                    <select className="form-control mb-3"
                                                        onChange={(e) => {
                                                            setPayment((prevState) => ({
                                                                ...prevState,
                                                                bankId: e.target.value
                                                            }));
                                                            GetBankAccounts(e.target.value, 0);
                                                        }}>
                                                        <option>-- Select Bank --</option>
                                                        {banks.map((b) => (
                                                            <option value={b.bankId}>{b.bankName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12">
                                                    <label>Bank Accounts</label>
                                                    <select className="form-control mb-3"
                                                        onChange={(e) => {
                                                            setPayment((prevState) => ({
                                                                ...prevState,
                                                                bankAccountId: e.target.value
                                                            }));
                                                            const bankAccount = bankAccounts.find(x => x.bankAccountId === Number(e.target.value));
                                                            // if(bankAccount.currencyId === currency.currencyId){
                                                            //     setSameCurrency(true);
                                                            //     GetBankCurrency(bankAccount.currencyId);
                                                            // }
                                                            // else{
                                                            //     setSameCurrency(false);
                                                            //     setPayment((prevState) => ({
                                                            //         ...prevState,
                                                            //         exchangeRate: 1.0,
                                                            //     }));
                                                            //     CalculateBankAmount(1.0);
                                                            // }
                                                            setEditingPayment((prevState) => ({
                                                                ...prevState,
                                                                exchangeRate: 1.0,
                                                            }));
                                                            CalculateEditBankAmount(1.0);

                                                            if (bankAccount.currencyId === currency.currencyId) {
                                                                setSameCurrency(true);
                                                            }
                                                            else {
                                                                setSameCurrency(false);
                                                                GetBankCurrency(bankAccount.currencyId);
                                                                // CalculateEditBankAmount(1.0);
                                                            }
                                                        }}>
                                                        <option value="">-- Select Bank Account--</option>
                                                        {bankAccounts.map((b) => (
                                                            <option value={b.bankAccountId}>{b.bankAccount1}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {sameCurrency == false && (
                                                    <>
                                                        <div className="col-6">
                                                            <label>Bank Amount</label>
                                                            <div className="input-group">
                                                                <div className="input-group-text">{bankCurrency.currencySuffix}</div>
                                                                <input type="number" className="form-control "
                                                                    value={payment.bankAmount}
                                                                    onChange={(e) => {
                                                                        setPayment((prevState) => ({
                                                                            ...prevState,
                                                                            bankAmount: e.target.value
                                                                        }));
                                                                        CalculateExchangeRate(e.target.value);
                                                                    }}>
                                                                </input>
                                                            </div>

                                                        </div>
                                                        <div className="col-6">
                                                            <label>Exchange Rate</label>
                                                            <input type="number" className="form-control mb-3"
                                                                value={payment.exchangeRate}
                                                                onChange={(e) => {
                                                                    setPayment((prevState) => ({
                                                                        ...prevState,
                                                                        exchangeRate: e.target.value
                                                                    }));
                                                                    CalculateBankAmount(e.target.value);
                                                                }}>
                                                            </input>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="col-12">
                                                    <label>Notes</label>
                                                    <textarea className="form-control"
                                                        onChange={(e) => {
                                                            setPayment((prevState) => ({
                                                                ...prevState,
                                                                notes: e.target.value
                                                            }));
                                                        }}></textarea>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-secondary" onClick={handleClose}>
                                                Close
                                            </button>
                                            <button className="btn btn-primary" disabled={submitDisabled} onClick={(e) => AddAdvancedPayments(e)}>
                                                Save Changes
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Payment Value</label>
                            <div className="input-group mb-3">
                                <div className="input-group-text">{currency.currencySuffix}</div>
                                <input type="number" className="form-control" required
                                    value={editingPayment.customerAccountPayment}
                                    onChange={(e) => {
                                        setEditingPayment((prevState) => ({
                                            ...prevState,
                                            customerAccountPayment: e.target.value
                                        }));
                                        reloadEditBankAmount(e.target.value);
                                    }}>
                                </input>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3" type="date" value={editingPayment.paymentDate}
                                onChange={(e) => {
                                    setEditingPayment((prevState) => ({
                                        ...prevState,
                                        paymentDate: e.target.value
                                    }));
                                }}></input>
                        </div>
                        <div className="col-12">
                            <label>Bank</label>
                            <select className="form-control mb-3"
                                value={editingPayment.bankId}
                                onChange={(e) => {
                                    setEditingPayment((prevState) => ({
                                        ...prevState,
                                        bankId: e.target.value
                                    }));
                                    GetBankAccounts(e.target.value);
                                }}>
                                <option>-- Select Bank --</option>
                                {banks.map((b) => (
                                    <option value={b.bankId}>{b.bankName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12">
                            <label>Bank Accounts</label>
                            <select className="form-control mb-3"
                                value={editingPayment.bankAccountId}
                                onChange={(e) => {
                                    setEditingPayment((prevState) => ({
                                        ...prevState,
                                        bankAccountId: e.target.value
                                    }));
                                    const bankAccount = bankAccounts.find(x => x.bankAccountId === Number(e.target.value));

                                    // setSameCurrency(bankAccount.currencyId === currency.currencyId);
                                    setEditingPayment((prevState) => ({
                                        ...prevState,
                                        exchangeRate: 1.0,
                                    }));
                                    CalculateEditBankAmount(1.0);

                                    if (bankAccount.currencyId === currency.currencyId) {
                                        setSameCurrency(true);
                                    }
                                    else {
                                        setSameCurrency(false);
                                        GetBankCurrency(bankAccount.currencyId);
                                        // CalculateEditBankAmount(1.0);
                                    }
                                }}>
                                <option value="">-- Select Bank Account--</option>
                                {bankAccounts.map((b) => (
                                    <option value={b.bankAccountId}>{b.bankAccount1}</option>
                                ))}
                            </select>
                        </div>
                        {sameCurrency == false && (
                            <>
                                <div className="col-6">
                                    <label>Bank Amount</label>
                                    <div className="input-group">
                                        <div className="input-group-text">{bankCurrency.currencySuffix}</div>
                                        <input type="number" className="form-control "
                                            value={editingPayment.bankAmount}
                                            onChange={(e) => {
                                                setEditingPayment((prevState) => ({
                                                    ...prevState,
                                                    bankAmount: e.target.value
                                                }));
                                                CalculateEditExchangeRate(e.target.value);
                                            }}>
                                        </input>
                                    </div>

                                </div>
                                <div className="col-6">
                                    <label>Exchange Rate</label>
                                    <input type="number" className="form-control mb-3"
                                        value={editingPayment.exchangeRate.toLocaleString()}
                                        onChange={(e) => {
                                            setEditingPayment((prevState) => ({
                                                ...prevState,
                                                exchangeRate: e.target.value
                                            }));
                                            CalculateEditBankAmount(e.target.value);
                                        }}>
                                    </input>
                                </div>
                            </>
                        )}
                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                value={editingPayment.notes}
                                onChange={(e) => {
                                    setEditingPayment((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseEdit}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) =>
                        EditPayment(e)
                    }>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ListOrderAdvancedPayments