import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import { Global } from "../../../../../Variables/Global";
import { useParams } from "react-router-dom"
import Select from "react-select";
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { UncontrolledTooltip } from "reactstrap";
import { getDecodedToken } from '../../../../../utils/utils';
import axiosInstance from '../../../../../api/axiosConfig';

const ListOrderProducts = (props) => {

    const pId = useParams();
     
    const [orders, setOrders] = useState([]);

    const [showAddOrderProduct, setShowAddOrderProduct] = useState(false);
    const handleCloseAddOrderProduct = () => setShowAddOrderProduct(false);
    const handleShowAddOrderProduct = () => setShowAddOrderProduct(true);
    const [permissions, setPermissions] = useState([]);

    // const [product, setProduct] = useState({
    //     CustomerOrderId: props.orderId,
    //     productId: 0,
    //     productSizeId: 0,
    //     productUnitId: 0,
    //     productTypeId: 0,
    //     numberOfContainers: "",
    //     quantity: "",
    //     unitPrice: "",
    //     pendingFCL: 0,
    //     pendingQuantity: 0
    // })

    const [orderProduct, setOrderProduct] = useState({
        supplierOrderProductId: 0,
        customerOrderId: props.orderId,
        productQuantity: 0,
        fcl: 0,
        unitPrice: 0,
        supplierId: 0,
        userId: localStorage.getItem("uTsReD"),
        proforma: "",
        description: "",
        commissionPerFCL: 0.0,
        // currencySuffix: currency.currencySuffix,
        // currencyId: currency.currencyId,
        exchangeRate: 1.0,
        // price: 0,
        // quantity: 0,
        // fcl: 0,
        // productId: 0,
        unit: ""
    });

    const [editingProductId, setEditingProductId] = useState(0);


    const AddProduct = (e) => {
        e.preventDefault();
        if (validateOrderProductForm() == true) {
            var config = {
                method: 'post',
                url: "CustomerOrderProduct/AddCustomerOrderProduct/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: orderProduct
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        GetCustomerOrderProducts(pId.OrderId);
                        props.GetCustomerOrderProductTotal(pId.OrderId);
                        toast.success("Added Successfully")
                        setShowAddOrderProduct(false)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data)
                });
        }
    }

    const EditProduct = (e) => {
        e.preventDefault();
        if (validateOrderProductForm() == true) {
            var config = {
                method: 'put',
                url: "CustomerOrderProduct/EditCustomerOrderProduct/" + editingProductId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: orderProduct
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        GetCustomerOrderProducts(pId.OrderId);
                        props.GetCustomerOrderProductTotal(pId.OrderId);
                        toast.success("Edited Successfully")
                        setShowAddOrderProduct(false)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
        }
    }


    // const AddCustomerAccountOrderProduct = (customerOrderProductId) => {
    //     setTimeout(() => {
    //         var config = {
    //             method: 'post',
    //             url: "CustomerAccountStatment/AddCustomerOrderProduct/" + pId.AccountId + "/CustomerOrderProduct/" + customerOrderProductId,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Access-Control-Allow-Origin': Global.Host
    //             },
    //             data: customerAccountOrderProduct
    //         };

    //         axiosInstance(config)
    //             .then(function (response) {
    //                 if (response.status === 200) {

    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    //     }, 1000);
    // }

    const validateOrderProductForm = () => {

        if(orderProduct.supplierId == null || orderProduct.supplierId == 0){
            toast.warn("Please select the supplier.");
            return false;
        }

        if(orderProduct.supplierOrderProductId == null || orderProduct.supplierOrderProductId == 0){
            toast.warn("Please select the product.");
            return false;
        }

        if(orderProduct.fcl > orderProduct.pendingFcl){
            toast.warn("Number of FCL should be less or equal to the product's available FCL.");
            return false;
        }

        if(orderProduct.fcl == 0 && orderProduct.pendingFcl > 0){
            toast.warn("Number of FCL can't be zero.");
            return false;
        }

        if(orderProduct.productQuantity > orderProduct.pendingQuantity){
            toast.warn("Quantity should be less or equal to the product's available quantity.");
            return false;
        }

        if(orderProduct.productQuantity === 0.0){
            toast.warn("Quantity can't be zero.");
            return false;
        }

        if(orderProduct.fcl < 0){
            toast.warn("Number of FCL can't be negative.");
            return false;
        }

        if(orderProduct.productQuantity < 0){
            toast.warn("Quantity can't be negative.");
            return false;
        }

        if(orderProduct.unitPrice == null || orderProduct.unitPrice == 0){
            toast.warn("Price can't be zero.");
            return false;
        }

        if(orderProduct.unitPrice < 0){
            toast.warn("Price can't be negative.");
            return false;
        }
       
        return true;
    }

    const [products, setProducts] = useState([])
    const GetProducts = () => {
        var config = {
            method: 'get',
            url: "Product/GetProducts",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setProducts(response.data);
                GetAccount(pId.AccountId);
            })
            .catch(function (error) {
                console.log(error);
                GetAccount(pId.AccountId);
            });
    }



    const columns = [
        {
            name: '#',
            selector: row => row.customerOrderProduct.customerOrderProductId,
            width: "60px"
        },
        {
            name: 'Description',
            selector: row => row.product.productName + ", " + row.type.productTypeName + ", " + row.size.productSizeName,
        },
        {
            name: 'FCL',
            width: "70px",
            selector: row => row.customerOrderProduct.fcl,
        },
        {
            name: 'Qty',
            width: "120px",
            selector: row => row.customerOrderProduct.productQuantity.toLocaleString() + " " + row.unit.productUnit1,
        },
        {
            name: 'Unit Price',
            selector: row => row.customerOrderProduct.unitPrice.toLocaleString() + " " + currency.currencySuffix,
        },
        {
            name: 'Total Price',
            selector: row => (row.customerOrderProduct.productQuantity * row.customerOrderProduct.unitPrice).toLocaleString() + " " + currency.currencySuffix,
        },
        {
            name: 'Commission',
            selector: row => (row.customerOrderProduct.fcl * orderProduct.commissionPerFCL).toLocaleString() + " " + currency.currencySuffix,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                    {permissions.editCustomer === true &&
                    <>
                        <button type="button" className="btn btn-warning  btn-sm ml-1" onClick={() => {
                            setEditingProductId(row.customerOrderProduct.customerOrderProductId);
                            handleSupplierChange(row.customerOrderProduct.supplierId);

                            setOrderProduct((prevState) => ({
                                ...prevState,
                                productQuantity: row.customerOrderProduct.productQuantity,
                                fcl: row.customerOrderProduct.fcl,
                                unitPrice: row.customerOrderProduct.unitPrice,
                                supplierId: row.customerOrderProduct.supplierId,
                                supplierName: suppliers.find((s) => {
                                    return s.supplierId === row.customerOrderProduct.supplierId;
                                  }).supplierName,
                                supplierOrderProductId: row.supplierProduct.supplierOrderProductId,
                                productDescription: row.product.productName + ", " + row.type.productTypeName + ", " + row.size.productSizeName,
                                description: " FCL, " + row.product.productName + ", " + row.type.productTypeName + ", " + row.size.productSizeName,
                                unit: row.unit.productUnit1,
                                // description: row.product.productName + ", " + row.type.productTypeName + ", " + row.size.productSizeName,
                                // unit: row.customerOrderProduct.unit,
                            }));
                            // handleCustomerAccountOrderProduct(row.supplierProduct.supplierOrderProductId);
                            
                            handleShowAddOrderProduct();
                        }}>
                            <FiEdit />
                        </button>


                        <button type="button" className="btn btn-danger btn-sm ml-1" onClick={() => {
                            DeleteCustomerOrderProduct(row.customerOrderProduct.customerOrderProductId);
                        }}>
                            <FiTrash2 />
                        </button>
                    </>
                }</>)
            },
        },
    ];


    const DeleteCustomerOrderProduct = (productId) => {
        var config = {
            method: 'delete',
            url: "CustomerOrderProduct/DeleteCustomerOrderProduct/" + productId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                // props.setProducts(response.data)
                // setOrders(response.data);
                GetCustomerOrderProducts(props.orderId);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
            });
    }


    const GetCustomerOrderProducts = (orderId) => {
        var config = {
            method: 'get',
            url: "CustomerOrderProduct/GetCustomerOrderProduct/" + orderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                props.setProducts(response.data)
                setOrders(response.data);
                GetProducts();
            })
            .catch(function (error) {
                console.log(error);
                GetProducts();
            });
    }


    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliers/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
                GetCustomerOrder(pId.OrderId);
            })
            .catch(function (error) {
                console.log(error);
                GetCustomerOrder(pId.OrderId);
            });
    }

    const handleSupplierChange = (supplierId) => {
        GetSupplierOrderProductBySupplierId(supplierId);
        setOrderProduct((prevState) => ({ ...prevState, 
            supplierId: supplierId,
            supplierOrderProductId: 0,
            description: ""
        }))
    }
    const [supplierOrderProducts, setSupplierOrderProducts] = useState([])
    const GetSupplierOrderProductBySupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierOrderProduct/GetSupplierOrderProductBySupplierId/" + supplierId + "/Account/" + pId.AccountId + "/Currency/" + account.currencyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setSupplierOrderProducts(response.data);
                props.setProducts(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // const [customerAccountOrderProduct, setCustomerAccountOrderProduct] = useState({
    //     performa: "",
    //     description: "",
    //     price: 0,
    //     quantity: 0,
    //     fcl: 0,
    //     productId: 0,
    //     unit: ""
    // })

    const handleCustomerAccountOrderProduct = (e) => {

        var _description = "";
        var _unit = "";
        supplierOrderProducts.forEach(element => {
            if (element.supplierOrderProducts.supplierOrderProductId == e) {
                console.log(element)

                _description = " FCL, " + element.productName + ", " + element.productSizeName + ", " + element.productTypeName;
                _unit = element.productUnit1
            }
        });

        if(_description != ""){
            setOrderProduct((prevState) => ({
                ...prevState,
                description: _description,  
                unit: _unit,
            }));
        }

        // setCustomerAccountOrderProduct((prevState) => ({
        //     ...prevState,
        //     unit: _unit,
        // }));
    }

    const GetCustomerOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrderProduct((prevState) => ({
                    ...prevState,
                    proforma: response.data.invoiceNumber,
                    commissionPerFCL: response.data.commission
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [account, setAccount] = useState("");
    const GetAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data);
                GetCurrency(response.data.currencyId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [sameCurrency, setSameCurrency] = useState(true)
    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                GetSuppliers();
            })
            .catch(function (error) {
                console.log(error);
                GetSuppliers();
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetCustomerOrderProducts(props.orderId);
        
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <h4 className="card-title">List Order Products</h4>
                </div>
                <div className='col-12 col-md-6 text-right'>
                    <button className="btn btn-primary" onClick={(e) =>{
                        setEditingProductId(0);
                        setOrderProduct({
                            supplierOrderProductId: 0,
                            customerOrderId: props.orderId,
                            productQuantity: 0,
                            fcl: 0,
                            unitPrice: 0,
                            supplierId: 0,
                            userId: localStorage.getItem("uTsReD"),
                            proforma: "",
                            description: "",
                            commissionPerFCL: 0.0,
                            unit: ""
                        });
                        handleShowAddOrderProduct();
                    }}>Add Product</button>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={orders}
            />
            <Modal size="lg" show={showAddOrderProduct} onHide={handleCloseAddOrderProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Order Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Supplier</label>
                            {editingProductId > 0 ?
                            <Select
                                placeholder="-- Select Supplier --"
                                isSearchable={true}
                                isDisabled={true}
                                defaultValue={{label: orderProduct.supplierName, value: orderProduct.supplierId}}
                                options={suppliers.map(c => ({
                                    value: c,
                                    label: c.supplierName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    handleSupplierChange(e.value.supplierId);
                                    setOrderProduct((prevState) => ({
                                        ...prevState,
                                        supplierName: e.value.supplierName
                                    }))
                                }}>
                            </Select>
                            :
                            <Select
                                placeholder="-- Select Supplier --"
                                isSearchable={true}
                                options={suppliers.map(c => ({
                                    value: c,
                                    label: c.supplierName
                                }))}
                                valuekey
                                onChange={(e) => {
                                    handleSupplierChange(e.value.supplierId);
                                    setOrderProduct((prevState) => ({
                                        ...prevState,
                                        supplierName: e.value.supplierName
                                    }))
                                }}>
                            </Select>
                            }

                            <label className="mt-3">Products</label>
                            {editingProductId > 0 ?
                            <Select
                                placeholder="-- Select Supplier Product --"
                                isSearchable={true}
                                isDisabled={true}
                                defaultValue={{label: orderProduct.productDescription, value: orderProduct.supplierOrderProductId}}
                                options={supplierOrderProducts.map(c => ({
                                    value: c.supplierOrderProducts,
                                    label: ('#' + c.supplierOrders.invoiceNumber + ' - ' + c.productName + ', ' + c.productSizeName + ', ' + c.productTypeName + ' ('+c.supplierOrderProducts.pendingFcl + ' FCL / ' + c.supplierOrderProducts.pendingQuantity + ' ' + c.productUnit1 + ') (' + c.currency.currencySuffix + ' ' + c.supplierOrderProducts.unitPrice + ')')
                                }))}
                                valuekey
                                // value={{label: orderProduct.productDescription, value: orderProduct.supplierOrderProductId}}
                                onChange={(e) => {
                                    setOrderProduct((prevState) => ({
                                        ...prevState,
                                        supplierOrderProductId: e.value.supplierOrderProductId,
                                        productDescription: e.value.productName,
                                        pendingQuantity: e.value.pendingQuantity,
                                        pendingFcl: e.value.pendingFcl,
                                    }));
                                    handleCustomerAccountOrderProduct(e.value.supplierOrderProductId);
                                }}>
                            </Select>
                            :
                                <Select
                                placeholder="-- Select Supplier Product --"
                                isSearchable={true}
                                options={supplierOrderProducts.map(c => ({
                                    value: c,
                                    label: ('#' + c.supplierOrders.invoiceNumber + ' - ' + c.productName + ', ' + c.productSizeName + ', ' + c.productTypeName + ' ('+c.supplierOrderProducts.pendingFcl + ' FCL / ' + c.supplierOrderProducts.pendingQuantity + ' ' + c.productUnit1 + ') (' + c.currency.currencySuffix + ' ' + c.supplierOrderProducts.unitPrice + ')')
                                }))}
                                valuekey
                                // value={{label: orderProduct.productDescription, value: orderProduct.supplierOrderProductId}}
                                onChange={(e) => {
                                    setOrderProduct((prevState) => ({
                                        ...prevState,
                                        supplierOrderProductId: e.value.supplierOrderProducts.supplierOrderProductId,
                                        productDescription: e.value.supplierOrderProducts.productName,
                                        pendingQuantity: e.value.supplierOrderProducts.pendingQuantity,
                                        pendingFcl: e.value.supplierOrderProducts.pendingFcl,
                                        currencySuffix: e.value.currency.currencySuffix,
                                        currencyId: e.value.currency.currencyId,
                                        exchangeRate: 1.0
                                    }));
                                    setSameCurrency(e.value.currency.currencyId == currency.currencyId);
                                    handleCustomerAccountOrderProduct(e.value.supplierOrderProducts.supplierOrderProductId);
                                }}>
                            </Select>
                            }
                            {/* <label>
                                {orderProduct.supplierOrderProductId}
                            </label> */}

                            {(sameCurrency == false) ? (
                                <>
                                 <div className="row mb-3 mt-3">
                                    <div className="col-6">
                                        <label>{orderProduct.currencySuffix} to {currency.currencySuffix} Exchange Rate <i class="fas fa-info-circle" id="tooltip1"/>
                                            <UncontrolledTooltip placement="top" target="tooltip1">
                                            Since this client account currency is different than the supplier invoice currency, the exchange rate is required.
                                            </UncontrolledTooltip></label>
                                        <input type="number" className="form-control" required 
                                            value={orderProduct.exchangeRate}
                                            step={0.1}
                                            onChange={(e) => {
                                                setOrderProduct((prevState) => ({
                                                    ...prevState,
                                                    exchangeRate: e.target.value
                                                }));
                                            }}>
                                        </input>
                                        {/* <span class="bg-warning badge badge-warning"> */}
                                            {/* <div>
                                                <i class="typcn typcn-info bg-warning badge badge-warning">Since the forwarder account currency is different than the supplier<br/>invoice currency, the exchange rate is required in order to calculate the profit.</i>
                                            </div> */}
                                        {/* </span> */}
                                    </div>
                                    <div className="col-6">
                                    <label>Number of Containers</label>
                                    <input type="number" className="form-control"
                                    value={orderProduct.fcl}
                                        onChange={(e) => {
                                            setOrderProduct((prevState) => ({
                                                ...prevState,
                                                fcl: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                    </div>
                                </>
                            ) :

                            <div className="row mb-3 mt-3">
                                <div className="col-6">
                                    <label>Number of Containers</label>
                                    <input type="number" className="form-control"
                                    value={orderProduct.fcl}
                                        onChange={(e) => {
                                            setOrderProduct((prevState) => ({
                                                ...prevState,
                                                fcl: e.target.value
                                            }));
                                        }}></input>
                                </div>
                            </div>
                            }

                            <div className="row ">
                                <div className="col-6">
                                    <label>Quantity</label>
                                    <input type="number" className="form-control"
                                        value={orderProduct.productQuantity.toLocaleString()}
                                        onChange={(e) => {
                                            setOrderProduct((prevState) => ({
                                                ...prevState,
                                                productQuantity: e.target.value
                                            }));
                                            // setCustomerAccountOrderProduct((prevState) => ({
                                            //     ...prevState,
                                            //     quantity: e.target.value,
                                            // }));
                                        }}></input>
                                </div>
                                <div className="col-6">
                                    <label>Unit Price</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-text">{currency.currencySuffix}</div>
                                        <input type="number" className="form-control"
                                           value={orderProduct.unitPrice}
                                           onChange={(e) => {
                                            setOrderProduct((prevState) => ({
                                                ...prevState,
                                                unitPrice: e.target.value
                                            }));
                                        }}></input>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddOrderProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={(e) =>{
                        if(editingProductId>0){
                            // handleCustomerAccountOrderProduct(orderProduct.supplierOrderProductId);
                            EditProduct(e)
                        }
                        else{
                            AddProduct(e)
                        }
                    }}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ListOrderProducts