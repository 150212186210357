import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';

const ManageSize = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [sizeName, setSizeName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [companies, setCompanies] = useState([]);

    const GetProductSize = (productSizeId) => {
        var config = {
            method: 'get',
            url: "ProductSize/GetProductSize/" + productSizeId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                document.title = response.data.productSizeName;
                setSizeName(response.data.productSizeName)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCompanies = () => {
        var config = {
            method: 'get',
            url: "Company/GetCompany",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { 
                productSizeName: sizeName, 
                userId: localStorage.getItem("uTsReD"),
                companyId: companyId 
            }
            if (pId.SizeId == "0") { //Add Product Size
                var config = {
                    method: 'post',
                    url: "ProductSize/AddProductSize",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Size Added Successfully")
                            navigate("/Settings/Size")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Product Size
                var config = {
                    method: 'put',
                    url: "ProductSize/EditProductSize/" + pId.SizeId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Size Updated Successfully")
                            navigate("/Settings/Size")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (sizeName == "" && companyId == "0") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (sizeName == "") {
            toast.error("Enter Size Name");
            return false;
        }
        else if (companyId == "0") {
            toast.error("Select Company");
            return false;
        }
        return true;
    }

    useEffect(() => {
        GetCompanies();
        if (pId.SizeId != "0") {
            setPageType("Edit");
            GetProductSize(pId.SizeId)
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Sizes</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Settings/Size">Sizes</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Sizes</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Size</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Settings/Size" className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label htmlFor="txtSizeName" className="col-md-2 col-form-label">Size Name</label>
                                            <input className="form-control" type="text" value={sizeName} id="txtSizeName" onChange={(e) => setSizeName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-2 col-form-label">Company</label>
                                            <select className="form-control" value={companyId} onChange={(e) => setCompanyId(e.target.value)} required>
                                                <option value="0" key="0">-- Select Company Name --</option>
                                                {companies.length > 0 && (
                                                    companies.map((c, index) => {
                                                        return (<>
                                                            <option value={c.companyId} key={index}>{c.companyName}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ManageSize;