import React, { useEffect, useState } from "react";
import { Global } from '../../../Variables/Global';
import { GetCurrencies } from '../../../api/HttpService'
import { FiRefreshCcw, FiRefreshCw, FiPlus } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import DataTable, { Alignment } from "react-data-table-component";
import { BsAlignCenter } from 'react-icons/bs';
import dateFormat from 'dateformat';
import { getDecodedToken } from '../../../utils/utils';


const ShipmentLiveTracking = () => {
    const pId = useParams();
    const [permissions, setPermissions] = useState([]);
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Live"
    }, []);

    return (
        <>
        {permissions.viewShipments == null || permissions.viewShipments == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content" >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>Live Tracking</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Live Tracking</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* style="height: 550px;width: 100%;" */}
                        {/* <div height="600px"> */}
                            <iframe src={"https://shipsgo.com/iframe/where-is-my-container/" + pId.ContainerCode} id="IframeShipsgoLiveMap" height="550px" allowFullScreen width="100%"></iframe>
                        {/* </div> */}
                    </div>
                </>)
            }
            
        </>);
}
export default ShipmentLiveTracking;