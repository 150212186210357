import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { Global } from "../../../../Variables/Global";
import { FiEdit, FiEye, FiTrash2, FiPlus } from 'react-icons/fi';
import { toast } from "react-toastify";
import axiosInstance from '../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../utils/utils';
import { formatDate } from "../../../Sales/SalesOrder";
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const ListCustomerOrders = (props) => {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const pId = useParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [orders, setOrders] = useState([]);
    const [permissions, setPermissions] = useState([]);


    const [showAddCustomerOrder, setShowAddCustomerOrder] = useState(false);
    const handleCloseAddCustomerOrder = () => setShowAddCustomerOrder(false);
    const handleShowAddCustomerOrder = () => setShowAddCustomerOrder(true);

    const [customerOrder, setCustomerOrder] = useState({
        // salesOrderId: 0,
        // payImmediately: false,
        invoiceNumber: "",
        otherCharges: "",
        notes: "",
        paymentTermsId: "",
        paymentTermsName: "",
        date: formatDate(new Date()),
        proformaFileURL: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        customerAccountId: pId.AccountId,
    });

    const submitCustomerOrderForm = (e) => {
        e.preventDefault();
        if (IsValidCustomerOrder()) {
            var config = {
                method: 'post',
                url: "CustomerOrder/AddCustomerOrder",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: customerOrder
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        navigate("/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/View/Order/" + response.data)
                        toast.success("Order Created Successfully")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data)
                });
        }

    }

    const IsValidCustomerOrder = () => {
        if (customerOrder.invoiceNumber == "" && customerOrder.date == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (customerOrder.invoiceNumber == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (customerOrder.date == "") {
            toast.error("Enter Date");
            return false;
        }
        else if (customerOrder.paymentTermsId == null || customerOrder.paymentTermsId == 0) {
            toast.error("Select payment terms");
            return false;
        }
        return true;
    }


    const columns = [
        {
            name: '#',
            selector: row => row.customerOrderId,
            width: "60px"
        },
        {
            name: 'Invoice Number',
            selector: row => row.invoiceNumber,
            width: "250px"
        },
        {
            name: 'Date',
            selector: row => row.date.split('T')[0],
        },
        {
            name: 'Note',
            selector: row => row.notes,
        },
        {
            name: 'Options',
            selector: row => {
                return (
                    <>
                        <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/View/Order/" + row.customerOrderId} className="btn btn-outline-success mr-3">
                            <FiEye />
                        </Link>
                        <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Order/" + row.customerOrderId} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </Link>
                        {/* <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button> */}

                        <button type="button" className="btn btn-outline-danger" onClick={() => {
                            DeleteCustomerOrder(row.customerOrderId);
                        }}>
                            <FiTrash2 />
                        </button>
                    </>
                )
            },
        },
    ];


    const [filteredInvoices, setFilteredInvoices] = useState([]);
    // Handle search input change
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if (value == null || value == '') {
            setFilteredInvoices(orders);
        }
        // Filter invoices based on the search query
        const filtered = orders.filter(invoice =>
            invoice.invoiceNumber.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredInvoices(filtered);
    }


    const [paymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [currency, setCurrency] = useState("")
    const GetCurreny = () => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrencyByCustomerAccountId/" + pId.AccountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                GetCustomerOrders(pId.AccountId);
            })
            .catch(function (error) {
                console.log(error);
                GetCustomerOrders(pId.AccountId);
            });
    }

    const GetCustomerOrders = (customerAccountId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrders/" + customerAccountId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setOrders(response.data);
                setFilteredInvoices(response.data); // Initialize filteredInvoices with all invoices
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
                setLoading(false);
            });
    }


    const DeleteCustomerOrder = (customerOrderId) => {
        var config = {
            method: 'delete',
            url: "CustomerOrder/DeleteCustomerOrder/" + customerOrderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                GetCustomerOrders(pId.AccountId);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
            });
    }

    // const [customer, setCustomer] = useState("")
    // const GetCustomer = (customerId) => {
    //     var config = {
    //         method: 'get',
    //         url: "Customer/GetCustomers/" + customerId,
    //         headers: {},
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             console.log(response.data)
    //             setCustomer(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }


    // const [account, setAccount] = useState("")
    // const GetAccount = (accountId) => {
    //     var config = {
    //         method: 'get',
    //         url: "CustomerAccount/GetCustomerAccount/" + accountId,
    //         headers: {},
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setAccount(response.data);
    //             document.title = response.data.customerName + ' - ' +  response.data.customerAccount1 + ' Orders';
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }


    const trackingStatusStyles = {
        0: { color: '#292b2c', backgroundColor: '#e9eaea' },
        20: { color: '#292b2c', backgroundColor: '#e9eaea' },
        30: { color: '#292b2c', backgroundColor: '#d3d5d6' },
        35: { color: '#41b6d9', backgroundColor: '#eff9fc' },
        40: { color: '#936b16', backgroundColor: '#fdf0d3' },
        45: { color: '#35834f', backgroundColor: '#def8e6' },
        50: { color: '#197088', backgroundColor: '#d4f1f9' },
        60: { color: '#197088', backgroundColor: '#d4f1f9' },
    };

    useEffect(() => {
        setLoading(true);
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetCurreny();
        document.title = pId.CustomerName;
        // GetCustomer(pId.CustomerId)
        // GetAccount(pId.AccountId)
    }, [])
    return (
        <>
            {permissions.viewCustomerOrders == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-9">
                                    <div className="page-title-box">
                                        <h4>Clients</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item"><Link onClick={() => { navigate(-1) }}>Clients</Link></li>
                                            <li className="breadcrumb-item">
                                                <Link onClick={() => { navigate(-1) }}>{pId.CustomerName}</Link>
                                            </li>
                                            <li className="breadcrumb-item">{pId.CustomerAccountName}</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-3 text-right'>
                                    <div className='flex justify-end'>
                                        {permissions.manageCustomerOrder === true && (
                                            <button onClick={() => {
                                                GetPaymentTerms();
                                                handleShowAddCustomerOrder();
                                            }
                                            } className="ml-1 btn btn-primary"><FiPlus /> Add Client Order</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <section>
                                <div className="container-fluid">
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                placeholder="Invoice Number"
                                                value={searchQuery}
                                                onChange={handleSearchInputChange}
                                                style={{ marginBottom: '10px' }}
                                            />
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                                {loading ? (
                                                    Array.from({ length: 3 }).map((_, index) => (
                                                        <div key={index} style={{
                                                            border: '1px solid #ddd',
                                                            borderRadius: '5px',
                                                            padding: '15px',
                                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                            flex: '1 1 100%',
                                                            boxSizing: 'border-box',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            backgroundColor: '#fff'
                                                        }}>
                                                            <Skeleton height={30} style={{ marginBottom: '10px' }} />
                                                            <Skeleton height={20} style={{ marginBottom: '10px' }} />
                                                            <Skeleton count={3} height={20} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    filteredInvoices.length === 0 ? (
                                                        <div>No invoices found</div>
                                                    ) : (
                                                        filteredInvoices.map(invoice => (
                                                            <div key={invoice.customerOrderId} style={{
                                                                border: '1px solid #ddd',
                                                                borderRadius: '5px',
                                                                padding: '15px',
                                                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                                flex: '1 1 100%',
                                                                boxSizing: 'border-box',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                backgroundColor: invoice.pendingFCLs > 0 ? '#fff' : '#F1FCF3',
                                                            }}>
                                                                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <a href={`/Accounting/Customer/${pId.CustomerId}/Account/${pId.AccountId}/View/Order/${invoice.customerOrderId}`} style={{ textDecoration: 'none', color: '#f5b225' }}>
                                                                        <b>#{invoice.invoiceNumber}</b>
                                                                    </a>
                                                                    <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                        {invoice.date ? invoice.date.split('T')[0] : 'No Date'}
                                                                    </span>
                                                                </div>

                                                                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <span style={{ whiteSpace: 'pre-wrap' }}>
                                                                        {invoice.products}
                                                                    </span>

                                                                    <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                        <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{currency.currencySuffix} {invoice.totalPrice.toLocaleString()}</b></span>

                                                                        {invoice.pendingFCLs > 0 &&
                                                                            <>
                                                                                <br />
                                                                                <span style={{ textAlign: 'right', color: '#936b16', backgroundColor: '#fdf0d3', borderColor: '#fce8be', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>{invoice.pendingFCLs == 1 ? "1 Pending FCL" : invoice.pendingFCLs + " Pending FCLs"} </span>
                                                                            </>
                                                                        }
                                                                        <br />
                                                                        <span style={{ textAlign: 'right', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                                            <i>{invoice.notes}</i>
                                                                        </span>
                                                                    </span>
                                                                </div>

                                                                {/* {invoice.notes != null && invoice.notes.length > 0 &&
                                                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                        <span style={{ whiteSpace: 'pre-wrap', color: '#a3a3a3' }}>
                                                            <i>Notes: {invoice.notes}</i>
                                                        </span>
                                                    </div>
                                                    } */}

                                                                <hr />
                                                                <div style={{ flexGrow: 1 }}>
                                                                    {invoice.commercialInvoices && invoice.commercialInvoices.length > 0 ? (
                                                                        invoice.commercialInvoices.map(c => (
                                                                            <div key={c.invoiceNumber} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                                <a href={`/Accounting/Customer/${pId.CustomerId}/Account/${pId.AccountId}/View/Order/${invoice.customerOrderId}`} style={{ textDecoration: 'none', color: '#3eb7ba' }}>
                                                                                    <b>{c.invoiceNumber}</b>
                                                                                    <span style={{ color: '#a3a3a3' }}>
                                                                                        {" - " + (c.date ? c.date.split('T')[0] : 'No Date')}
                                                                                    </span>
                                                                                </a>
                                                                                <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                                    {(c.tracking != null && c.tracking.statusId > 0) &&
                                                                                        <>
                                                                                            {
                                                                                                c.tracking.statusId < 50 ?
                                                                                                    <span style={{ ...trackingStatusStyles[c.tracking.statusId], borderRadius: 5, padding: '0 5px' }}><b>{c.tracking.status} - {c.tracking.arrivalDate}</b></span>
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            c.tracking.statusId === 50 &&
                                                                                                            <span style={{ ...trackingStatusStyles[c.tracking.statusId], borderRadius: 5, padding: '0 5px' }}><b>{c.tracking.status} - {c.tracking.dischargeDate}</b></span>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <span style={{ color: '#a3a3a3' }}><i>No commercial invoices available</i></span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <Modal show={showAddCustomerOrder} onHide={handleCloseAddCustomerOrder}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Order
                                {/* <h5><small>Remaining Total: {currency.currencySuffix} {commercialDiscount.remainingTotal.toLocaleString()}</small></h5> */}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <label>Invoice Number</label>
                                    <div className="input-group mb-3">
                                        <input className="form-control"
                                            type="text"
                                            placeholder="Enter the invoice number"
                                            value={customerOrder.invoiceNumber}
                                            onChange={(e) => {
                                                setCustomerOrder((prevState) => ({
                                                    ...prevState,
                                                    invoiceNumber: e.target.value
                                                }));
                                            }}>
                                        </input>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label>Date</label>
                                    <input className="form-control mb-3"
                                        type="date"
                                        value={customerOrder.date}
                                        onChange={(e) => {
                                            setCustomerOrder((prevState) => ({
                                                ...prevState,
                                                date: e.target.value
                                            }));
                                        }}></input>
                                </div>
                                <div className="col-12 mb-3">
                                    <label>Payment Terms</label>
                                    <Select
                                        placeholder="-- Select Payment Terms --"
                                        isSearchable={true}
                                        value={customerOrder.paymentTermsId > 0 ? { label: customerOrder.paymentTermsName, value: customerOrder.paymentTermsId } : null}
                                        options={paymentTerms.map(p => ({
                                            value: p,
                                            label: p.name
                                        }))}
                                        valuekey
                                        onChange={(e) => {
                                            setCustomerOrder((prevState) => ({
                                                ...prevState,
                                                paymentTermsId: e.value.paymentTermsId,
                                                paymentTermsName: e.value.name
                                            }))
                                        }}>
                                    </Select>
                                </div>
                                <div className="col-12">
                                    <label>Commission (Per FCL)</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">{currency.currencySuffix}</span>
                                        <input className="form-control"
                                            type="number"
                                            step="1"
                                            value={customerOrder.commission}
                                            placeholder="Commission (Optional)"
                                            onChange={(e) => {
                                                setCustomerOrder((prevState) => ({
                                                    ...prevState,
                                                    commission: e.target.value
                                                }));
                                            }}>
                                        </input>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label>Notes</label>
                                    <textarea className="form-control"
                                        placeholder="Your notes (Optional)"
                                        value={customerOrder.notes}
                                        onChange={(e) => {
                                            setCustomerOrder((prevState) => ({
                                                ...prevState,
                                                notes: e.target.value
                                            }));
                                        }}></textarea>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={handleCloseAddCustomerOrder}>
                                Close
                            </button>
                            <button className="btn btn-primary" onClick={(e) => submitCustomerOrderForm(e)}>
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
            }
        </>
    )
}
export default ListCustomerOrders