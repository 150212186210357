import React, { useEffect, useState } from "react";
import { Global } from '../../Variables/Global';
import { FiPlus, FiTrash2, FiEdit, FiEye } from 'react-icons/fi';
import { FcNext } from 'react-icons/fc';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from "react-select";
import DataTable from "react-data-table-component";
import { getDecodedToken } from '../../utils/utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { UncontrolledTooltip } from "reactstrap";
import axiosInstance from '../../api/axiosConfig';

const Orders = () => {
    //const axios = require('axios').default;
    //const token = localStorage.getItem('token');
    //axios.defaults.headers.Authorization = `Bearer ${token}`;
    //if (!token) {
    //    window.location.reload();
    //}

    const [createdAt, setCreatedAt] = useState()
    const pId = useParams();
    const [settings, setSettings] = useState();
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate();

    const GetAppSettings = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "Settings/GetAppSettings",
            headers: {},
            params: { companyId }
        };

        axiosInstance(config)
            .then(function (response) {
                setSettings(response.data);
                //if (response.status === 203) {
                //    sessionStorage.setItem('sessionExpired', 'true');
                //    window.location.reload();
                //}
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data);
            });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        document.title = "Sales Orders";
        var _date = moment();
        setCreatedAt(_date.format("yyyy-MM-DD"))
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAppSettings();
        GetSupplierSalesOrders();
    }, [])

    const [salesOrders, setSalesOrders] = useState([])

    const GetSupplierSalesOrders = () => {
        var config = {
            method: 'get',
            url: 'SupplierSalesOrder/GetSalesOrders/' + localStorage.getItem("uTsReD"),
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSalesOrders(response.data.data);
                console.log(response.data.data)
            })
            .catch(function (error) {
                
                console.log(error);
            });

    }
    const createSalesOrder = () => {
        const body = {
            CompanyId: localStorage.getItem("C0m8N!"),
            userId: localStorage.getItem("uTsReD"),
        }
        var config = {
            method: 'post',
            url:  "SupplierSalesOrder/AddSalesOrder",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    navigate('/Sales/SalesOrder/' + response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const columns = [
        {
            name: 'User',
            width: "100px",
            selector: row => {
                return (
                    <>
                        <div className="mt-3 mb-3 text-center">
                            <div id={"tooltip" + row.userId} >
                                {row.profile && row.profile !== "" ? (
                                    <img
                                        src={row.profile}
                                        alt={row.profile}
                                        width="65"
                                        height="65"
                                    className="rounded-circle img-thumbnail"
                                        style={{
                                            borderRadius: '50%', // This makes the image rounded
                                        }}
                                    />
                                ) : (
                                        <h4
                                            className="text-center rounded-circle img-thumbnail d-flex align-items-center justify-content-center"
                                            style={{
                                                width: 65,
                                                height: 65,
                                                lineHeight: 65,
                                                borderRadius: '50%',
                                                margin: 0,
                                            }}
                                        >
                                            {row.userAvatar ?? ""}
                                        </h4>
                                )}
                                <UncontrolledTooltip placement="top" target={"tooltip" + row.userId}>
                                    {row.userDescription}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Completion',
            width: "150px",
            selector: row => row.percentageOfCompletion,
            cell: row => (
                <div style={{ cursor: 'pointer', width: '100%' }}>
                    <ProgressBar
                        now={row.percentageOfCompletion}
                        style={{ height: '20px', fontSize: '15px' }} // Example styles
                    ></ProgressBar>
                </div>
            ),
        },
        {
            name: 'Date',
            width: "150px",
            selector: row => row.createdAt.split("T")[0],
        },
        {
            name: 'Suppliers',
            selector: row => {
                return (
                    <>
                        <div className="text-wrap mt-3 mb-3 user-row" style={{ cursor: 'pointer' }}>
                            {
                                row.suppliersNames.map((p, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <p style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: parseHtml(p).trim() }} />
                                            {index < row.suppliersNames.length - 1 && <hr />}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>
                    </>
                );
            }
        },
        {
            name: 'Clients',
            selector: row => {
                return (
                    <>
                        <div className="text-wrap mt-3 mb-3 user-row" style={{ cursor: 'pointer' }}>
                            {
                                row.customersNames.map((p, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <p style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: parseHtml(p).trim() }} />
                                            {index < row.customersNames.length - 1 && <hr />}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>
                    </>)
            }
        },
        {
            name: 'Options',
            width: "140px",
            selector: row => {
                return (
                    <>
                        {permissions.editSalesOrder === true &&
                            <button type="button" className="btn btn-success mr-3" onClick={() => {
                                navigate("/Sales/SalesOrder/" + row.salesOrderId)
                            }}>
                                <FiEye /> View
                            </button>}
                    </>
                )
            },
        }
    ];
    const handleRowClick = (row) => {
        navigate("/Sales/SalesOrder/" + row.salesOrderId)
    };
    function parseHtml(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const content = doc.body.innerHTML; // Use innerHTML to preserve tags
        return content;
    }

    return (
        <>
            {permissions.viewSalesOrders == null || permissions.viewSalesOrders === false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <div className="page-content"  >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="page-title-box">
                                        <h4>List of Sales Orders</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">List of Sales Orders</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-8 text-right'>
                                    <div className='flex justify-end'>

                                        {/*<Link to="/Sales/SalesOrder" className="btn btn-primary">Add Supplier Order</Link>*/}
                                        {
                                            permissions.addSalesOrder === true &&
                                            <button onClick={() => {
                                                createSalesOrder();
                                            }} className="ml-1 btn btn-primary"><FiPlus /> Add Sales Order</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {/*<DataTable columns={columns} data={salesOrders} onRowClicked={handleRowClick} /> The Row is Clickable*/} 
                                    <DataTable columns={columns} data={salesOrders} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
            }
        </>

    )
}
export default Orders