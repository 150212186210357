import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../Variables/Global";
import { FiEdit, FiTrash2, FiPlus } from 'react-icons/fi';
import ListCommercialInvoice from "../CommercialCustomerOrder/CommercialInvoices/ListCommercialInvoice";
import ListOrderProducts from "./Products/ListOrderProducts";
import Select from "react-select";
import axiosInstance from '../../../../api/axiosConfig';
import { Tabs, Tab, Alert, Badge } from 'react-bootstrap';
import { Card, CardBody, Col, Row } from "reactstrap"
import Skeleton from 'react-loading-skeleton';
import { UncontrolledTooltip } from "reactstrap";
import Dropzone from "react-dropzone"
import { getDecodedToken } from '../../../../utils/utils';

const ViewCustomerOrder = () => {
    var pId = useParams();
    var navigate = useNavigate();


    const trackingStatusStyles = {
        0: { color: '#292b2c', backgroundColor: '#e9eaea' },
        20: { color: '#292b2c', backgroundColor: '#e9eaea' },
        30: { color: '#292b2c', backgroundColor: '#d3d5d6' },
        35: { color: '#41b6d9', backgroundColor: '#eff9fc' },
        40: { color: '#936b16', backgroundColor: '#fdf0d3' },
        45: { color: '#35834f', backgroundColor: '#def8e6' },
        50: { color: '#197088', backgroundColor: '#d4f1f9' },
        60: { color: '#197088', backgroundColor: '#d4f1f9' },
    };

    const [customerName, setCustomerName] = useState("");
    const [permissions, setPermissions] = useState([]);



    const [showEditCustomerOrder, setShowEditCustomerOrder] = useState(false);
    const handleCloseEditCustomerOrder = () => setShowEditCustomerOrder(false);
    const handleShowEditCustomerOrder = () => setShowEditCustomerOrder(true);
    const [showDeleteCustomerOrder, setShowDeleteCustomerOrder] = useState(false);
    const handleCloseDeleteCustomerOrder = () => setShowDeleteCustomerOrder(false);
    const handleShowDeleteCustomerOrder = () => setShowDeleteCustomerOrder(true);


    const [editingProductId, setEditingProductId] = useState(0);
    // const [showEditCustomerOrderProduct, setShowEditCustomerOrderProduct] = useState(false);
    // const handleCloseEditCustomerOrderProduct = () => setShowEditCustomerOrderProduct(false);
    // const handleShowEditCustomerOrderProduct = () => setShowEditCustomerOrderProduct(true);
    const [showDeleteCustomerOrderProduct, setShowDeleteCustomerOrderProduct] = useState(false);
    const handleCloseDeleteCustomerOrderProduct = () => setShowDeleteCustomerOrderProduct(false);
    const handleShowDeleteCustomerOrderProduct = () => setShowDeleteCustomerOrderProduct(true);
    const [deletingCustomerOrderProductId, setDeletingCustomerOrderProductId] = useState(0);


    const [showAddOrderProduct, setShowAddOrderProduct] = useState(false);
    const handleCloseAddOrderProduct = () => setShowAddOrderProduct(false);
    const handleShowAddOrderProduct = () => setShowAddOrderProduct(true);



    const [showAddCustomerCommercialInvoice, setShowAddCustomerCommercialInvoice] = useState(false);
    const handleShowAddCustomerCommercialInvoice = () => setShowAddCustomerCommercialInvoice(true);
    const handleCloseAddCustomerCommercialInvoice = () => setShowAddCustomerCommercialInvoice(false);

    const [showEditCommercialInvoice, setShowEditCommercialInvoice] = useState(false);

    const handleCloseEditCommercialInvoice = () => setShowEditCommercialInvoice(false);
    const handleShowEditCommercialInvoice = () => setShowEditCommercialInvoice(true);
    const [editingCommercialInvoiceId, setEditingCommercialInvoiceId] = useState(0);
    const [editingCommercialInvoice, setEditingCommercialInvoice] = useState({
        customerCommercialInvoice: "",
        userId: localStorage.getItem("uTsReD"),
        customerCommercialInvoiceDate: "",
        notes: "",
    })

    const [deletingCommercialInvoice, setDeletingCommercialInvoice] = useState("");
    const [deletingCommercialInvoiceId, setDeletingCommercialInvoiceId] = useState(0);
    const [showDeleteCommercialInvoice, setShowDeleteCommercialInvoice] = useState(false);
    const handleCloseDeleteCommercialInvoice = () => {
        setDeletingCommercialInvoiceId(0);
        setShowDeleteCommercialInvoice(false);
    }

    const [orderProduct, setOrderProduct] = useState({
        supplierOrderProductId: 0,
        customerOrderId: pId.OrderId,
        productQuantity: 0,
        fcl: 0,
        unitPrice: 0,
        supplierId: 0,
        userId: localStorage.getItem("uTsReD"),
        proforma: "",
        description: "",
        commissionPerFCL: 0.0,
        exchangeRate: 1.0,
        pendingFcl: 0,
        unit: ""
    });

    const [suppliers, setSuppliers] = useState([])
    const GetSuppliers = () => {
        var config = {
            method: 'get',
            url: "Supplier/GetCompanySuppliers/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setSuppliers(response.data);
                // GetCustomerOrder(pId.OrderId);
            })
            .catch(function (error) {
                console.log(error);
                // GetCustomerOrder(pId.OrderId);
            });
    }

    const handleSupplierChange = (supplierId) => {
        GetSupplierOrderProductBySupplierId(supplierId);
        setOrderProduct((prevState) => ({
            ...prevState,
            supplierId: supplierId,
            supplierOrderProductId: 0,
            description: ""
        }))
    }
    const [supplierOrderProducts, setSupplierOrderProducts] = useState([])
    const GetSupplierOrderProductBySupplierId = (supplierId) => {
        var config = {
            method: 'get',
            url: "SupplierOrderProduct/GetSupplierOrderProductBySupplierId/" + supplierId + "/Account/" + pId.AccountId + "/Currency/" + account.currencyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setSupplierOrderProducts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [sameCurrency, setSameCurrency] = useState(true)

    const handleCustomerAccountOrderProduct = (e) => {

        var _description = "";
        var _unit = "";
        supplierOrderProducts.forEach(element => {
            if (element.supplierOrderProducts.supplierOrderProductId == e) {
                console.log(element)

                _description = " FCL, " + element.productName + ", " + element.productSizeName + ", " + element.productTypeName;
                _unit = element.productUnit1
            }
        });

        if (_description != "") {
            setOrderProduct((prevState) => ({
                ...prevState,
                description: _description,
                unit: _unit,
            }));
        }
    }

    const AddOrderProduct = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (validateOrderProductForm() == true) {
            var config = {
                method: 'post',
                url: "CustomerOrderProduct/AddCustomerOrderProduct/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: orderProduct
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        GetCustomerOrder(order.customerOrderId);
                        // props.GetCustomerOrderProductTotal(pId.OrderId);
                        toast.success("Added Successfully")
                        // setShowAddOrderProduct(false)
                        handleCloseAddOrderProduct();
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data)
                    handleCloseAddOrderProduct();
                    setSubmitDisabled(false);
                });
        }
        setSubmitDisabled(false);
    }

    const validateOrderProductForm = () => {

        if (orderProduct.supplierId == null || orderProduct.supplierId == 0) {
            toast.warn("Please select the supplier.");
            return false;
        }

        if (orderProduct.supplierOrderProductId == null || orderProduct.supplierOrderProductId == 0) {
            toast.warn("Please select the product.");
            return false;
        }

        if (editingProductId > 0) {

            if (orderProduct.fcl > (orderProduct.pendingFcl + orderProduct.oldFcl)) {
                toast.warn("Number of FCL should be less or equal to the product's available FCL.");
                return false;
            }
        }
        else {

            if (orderProduct.fcl > orderProduct.pendingFcl) {
                toast.warn("Number of FCL should be less or equal to the product's available FCL.");
                return false;
            }
        }
        if (orderProduct.fcl == 0 && orderProduct.pendingFcl > 0) {
            toast.warn("Number of FCL can't be zero.");
            return false;
        }

        if (orderProduct.productQuantity > orderProduct.pendingQuantity) {
            toast.warn("Quantity should be less or equal to the product's available quantity.");
            return false;
        }

        if (orderProduct.productQuantity === 0.0) {
            toast.warn("Quantity can't be zero.");
            return false;
        }

        if (orderProduct.fcl < 0) {
            toast.warn("Number of FCL can't be negative.");
            return false;
        }

        if (orderProduct.productQuantity < 0) {
            toast.warn("Quantity can't be negative.");
            return false;
        }

        if (orderProduct.unitPrice == null || orderProduct.unitPrice == 0) {
            toast.warn("Price can't be zero.");
            return false;
        }

        if (orderProduct.unitPrice < 0) {
            toast.warn("Price can't be negative.");
            return false;
        }

        return true;
    }

    const EditOrderProduct = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (validateOrderProductForm() == true) {
            var config = {
                method: 'put',
                url: "CustomerOrderProduct/EditCustomerOrderProduct/" + editingProductId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: orderProduct
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        GetCustomerOrder(pId.OrderId);
                        // GetCustomerOrderProducts(pId.OrderId);
                        // props.GetCustomerOrderProductTotal(pId.OrderId);
                        toast.success("Updated Successfully")
                        // setShowAddOrderProduct(false)
                        handleCloseAddOrderProduct();
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    handleCloseAddOrderProduct();
                    setSubmitDisabled(false);
                });
        }
        setSubmitDisabled(false);
    }


    const DeleteCustomerOrder = () => {
        var config = {
            method: 'delete',
            url: "CustomerOrder/DeleteCustomerOrder/" + order.customerOrderId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                toast.success("Order deleted successfully.");
                handleCloseDeleteCustomerOrder();
                navigate(-1);
            })
            .catch(function (error) {
                console.log(error);
                handleCloseDeleteCustomerOrder();
                toast.error(error.response.data.message);
            });
    }

    const DeleteCustomerOrderProduct = () => {
        var config = {
            method: 'delete',
            url: "CustomerOrderProduct/DeleteCustomerOrderProduct/" + deletingCustomerOrderProductId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                GetCustomerOrder(order.customerOrderId);
                handleCloseDeleteCustomerOrderProduct();
                // props.setProducts(response.data)
                // setOrders(response.data);
                // GetCustomerOrderProducts(props.orderId);
            })
            .catch(function (error) {
                console.log(error);
                handleCloseDeleteCustomerOrderProduct();
                toast.error(error.response.data.message);
            });
    }


    const [editingOrder, setEditingOrder] = useState({
        // salesOrderId: 0,
        // payImmediately: false,
        invoiceNumber: "",
        otherCharges: "",
        notes: "",
        paymentTermsId: "",
        paymentTermsName: "",
        date: "",
        proformaFileURL: "",
        userId: localStorage.getItem("uTsReD"),
        companyId: localStorage.getItem("C0m8N!"),
        supplierAccountId: pId.AccountId,
    })

    const submitCustomerOrderForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidCustomerOrder()) {
            var config = {
                method: 'put',
                url: "CustomerOrder/EditCustomerOrder/" + pId.OrderId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: editingOrder
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        setOrder(response.data);
                        // GetSupplierOrderTotal(pId.OrderId);
                        handleCloseEditCustomerOrder();
                        // navigate("/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Orders")
                        toast.success("Order Updated Successfully")
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data)
                    setSubmitDisabled(false);
                });
        }
        setSubmitDisabled(false);
    }

    const IsValidCustomerOrder = () => {
        if (editingOrder.invoiceNumber == "" && editingOrder.date == "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (editingOrder.invoiceNumber == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (editingOrder.date == "") {
            toast.error("Enter Date");
            return false;
        }
        else if (editingOrder.paymentTermsId == null || editingOrder.paymentTermsId == 0) {
            toast.error("Select payment terms");
            return false;
        }
        return true;
    }



    // const [orderProducts, setOrderProducts] = useState([])
    // const GetOrderProducts = (orderId) => {
    //     var config = {
    //         method: 'get',
    //         url: "CustomerOrderProduct/GetCustomerOrderProduct/" + orderId,
    //         headers: {},
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setOrderProducts(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const [account, setAccount] = useState({
        customerAccountId: 0,
        customerAccount1: "",
        currencyId: 0
    })

    const [order, setOrder] = useState({
        customerOrderId: 0,
        customerOrder1: "",
        invoiceNumber: "",
        supplierInvoices: "",
        date: "",
    })



    const GetCustomer = (CustomerId) => {
        var config = {
            method: 'get',
            url: "Customer/GetCustomers/" + CustomerId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomerName(response.data.customerName);
                GetCustomerAccount(pId.AccountId);
            })
            .catch(function (error) {
                console.log(error);
                GetCustomerAccount(pId.AccountId);
            });
    }
    const GetCustomerAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data)
                GetCurrency(response.data.currencyId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [currency, setCurrency] = useState("");
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                GetCustomerOrder(pId.OrderId);
                GetCustomerAttachment(pId.OrderId);
            })
            .catch(function (error) {
                console.log(error);
                GetCustomerOrder(pId.OrderId);
            });
    }

    const EditCustomerCommercialInvoice = (e) => {
        e.preventDefault();
        // if (validateForm() == true) {
        var config = {
            method: 'put',
            url: "CustomerCommercialInvoice/EditCustomerCommercialInvoice/" + editingCommercialInvoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editingCommercialInvoice
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    // GetCustomerOrderProducts(pId.OrderId);
                    GetCommercialInvoices(pId.OrderId);
                    toast.success("Edited Successfully")
                    setShowEditCommercialInvoice(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data)
            });
        // }
    }

    const submitDeleteInvoiceForm = (e) => {
        e.preventDefault();
        var config = {
            method: 'delete',
            url: "CustomerCommercialInvoice/DeleteCustomerCommercialInvoice/" + deletingCommercialInvoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetCommercialInvoices(pId.OrderId);
                    toast.success("Invoice deleted Successfully")
                    setShowDeleteCommercialInvoice(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data)
            });
    }

    const GetCustomerOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrder(response.data);
                document.title = '#' + response.data.invoiceNumber;
                // GetOrderProducts(pId.OrderId);
                GetCommercialInvoices(pId.OrderId);
                // GetCustomerOrderProductTotal(pId.OrderId);
            })
            .catch(function (error) {
                console.log(error);
                GetCommercialInvoices(pId.OrderId);
                // GetCustomerOrderProductTotal(pId.OrderId);
            });
    }

    const isValidCommercialInvoiceInfo = () => {
        if (customerCommercialInvoice.customerCommercialInvoice === "" || customerCommercialInvoice.customerCommercialInvoice === 0) {
            toast.error("Make sure to enter Customer Commercial Invoice");
            return false;
        }
        else if (customerCommercialInvoice.supplierCommercialInvoiceId === "" || customerCommercialInvoice.supplierCommercialInvoiceId === 0) {
            toast.error("Make sure to select Supplier Commercial Invoice");
            return false;
        }
        return true;
    }

    const AddCustomerCommercialInvoice = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (isValidCommercialInvoiceInfo()) {
            var config = {
                method: 'post',
                url: "CustomerCommercialInvoice/AddCustomerCommercialInvoice/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: customerCommercialInvoice
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        toast.success("Added Successfully");
                        GetCommercialInvoices(pId.OrderId);
                        handleCloseAddCustomerCommercialInvoice();
                        setSubmitDisabled(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.response.data);
                    handleCloseAddCustomerCommercialInvoice();
                    setSubmitDisabled(false);
                });
        } else {
            setSubmitDisabled(false);
        }
    }

    const GetCommercialInvoices = (orderId) => {
        var config = {
            method: 'get',
            url: "CustomerCommercialInvoice/GetCustomerCommercialInvoicesWithDetails",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            params: {
                'OrderId': orderId
            }
        };

        axiosInstance(config)
            .then(function (response) {
                setCommercialInvoices(response.data);
                console.log(response.data);
                GetSupplierCommercialInvoices();
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data);
                GetSupplierCommercialInvoices();
            });
    }

    // const [orderTotal, setOrderTotal] = useState(0)
    // const GetCustomerOrderProductTotal = (orderId) => {
    //     var config = {
    //         method: 'get',
    //         url: "CustomerOrderProduct/GetCustomerCommercialInvoiceTotalPrice/" + orderId,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': Global.Host
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             console.log(response.data)
    //             setOrderTotal(response.data);

    //             GetCommercialInvoices(pId.OrderId);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             GetCommercialInvoices(pId.OrderId);
    //         });
    // }

    const [paymentTerms, setPaymentTerms] = useState([])
    const GetPaymentTerms = () => {
        var config = {
            method: 'get',
            url: "PaymentTerms/GetPaymentTerms/" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setPaymentTerms(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [show, setShow] = useState(false);

    const addCommercialInvoice = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [supplierCommercialInvoices, setSupplierCommercialInvoices] = useState([])
    const GetSupplierCommercialInvoices = () => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoicesByCustomerOrder/" + pId.OrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoices(response.data)
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [customerCommercialInvoice, setCustomerCommercialInvoice] = useState({
        customerCommercialInvoice: "",
        supplierCommercialInvoiceId: 0,
        customerOrderId: pId.OrderId,
        userId: localStorage.getItem("uTsReD"),
        customerCommercialInvoiceDate: (new Date()).toISOString().split('T')[0],//.substring(0, 10),
        notes: "",
    });

    // const AddCustomerCommercialInvoice = (e) => {
    //     e.preventDefault();
    //     var config = {
    //         method: 'post',
    //         url: "CustomerCommercialInvoice/AddCustomerCommercialInvoice/" + pId.AccountId,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': Global.Host
    //         },
    //         data: model
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             if (response.status === 200) {
    //                 toast.success("Added Successfully")
    //                 GetCommercialInvoices(pId.OrderId);
    //                 setShow(false)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             toast.error("Something Went Wrong")
    //         });
    // }

    const [commercialInvoices, setCommercialInvoices] = useState([])
    // const GetCommercialInvoices = (orderId) => {
    //     var config = {
    //         method: 'get',
    //         url: 'CustomerCommercialInvoice/GetCustomerCommercialInvoice/' + orderId,
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             console.log(response.data);
    //             setCustomerCommercialInvoice(response.data);
    //             GetSupplierCommercialInvoices();
    //         })
    //         .catch(function (error) {
    //             console.log(error);

    //             GetSupplierCommercialInvoices();
    //         });
    // }


    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetSuppliers();
        if (pId.OrderId !== "0") {
            GetCustomer(pId.CustomerId)
            GetShippingCostTypes();
        }
        else {
            navigate("/Accounting/Customer/View/" + pId.CustomerId)
        }
    }, [])



    const [selectedFiles, setselectedFiles] = useState([])
    const handleAcceptedFiles = (acceptedFiles) => {

        //Mapped the preview before uploading 
        const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        }));
        setselectedFiles(prevFiles => [...prevFiles, ...mappedFiles]);

        uploadFiles(acceptedFiles);
    };
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const uploadFiles = async (files) => {
        const companyCode = localStorage.getItem("C0m8N!");
        const userCode = localStorage.getItem("uTsReD");
        const customerOrderId = pId.OrderId;

        files.forEach(async (file) => {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('companycode', companyCode);
            formData.append('usercode', userCode);
            formData.append('customerOrderId', customerOrderId);

            const config = {
                method: 'post',
                url: `${Global.API_URL}SupplierSalesOrder/Upload`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: percentCompleted
                    }));
                }
            };

            try {
                const response = await axiosInstance(config);
                if (response.status === 200) {
                    toast.success(`Upload Succeed for ${file.name}`);
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: 100
                    }));
                    GetCustomerAttachment(pId.OrderId);
                    console.log(`File successfully uploaded: ${file.name}`, response.data);
                }
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                toast.error(`Something Went Wrong with ${file.name}`);
            }
        });
    };
    const GetCustomerAttachment = (customerOrderId) => {
        var config = {
            method: 'get',
            url: 'CustomerOrder/GetCustomerAttachment',
            headers: {
                'Content-Type': 'application/json'
            },
            params: { customerOrderId }
        };

        axiosInstance(config)
            .then(function (response) {
                setUploadedFiles(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const handleDelete = (attachmentId) => {
        var config = {
            method: 'post',
            url: "SupplierSalesOrder/DeleteAttachment/" + attachmentId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    GetCustomerAttachment(pId.OrderId);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data);
            });
    }
    const renderFilePreview = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return (
                    <a href={`${file.path}`} target="_blank" rel="noopener noreferrer">
                        <img
                            src={`${file.path}`}
                            alt={file.name}
                            height="50"
                            width="100"
                            className="auth-logo-light"
                            style={{ marginRight: '10px', marginTop: '10px' }}
                        />
                    </a>
                );
            case 'pdf':
            case 'txt':
            case 'doc':
            case 'docx':
            case 'xls':
            case 'csv':
                return (
                    <a
                        href={`${file.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="auth-logo-light"
                        style={{ marginRight: '10px', marginTop: '10px' }}
                    >
                        {file.name}
                    </a>
                );
            default:
                return <span>Unsupported file type</span>;
        }
    }

    const [editingCommercialInvoiceCostId, setEditingCommercialInvoiceCostId] = useState(0);
    const [deletingCommercialInvoiceCostId, setDeletingCommercialInvoiceCostId] = useState(0);
    const [showDeleteCommercialInvoiceCost, setShowDeleteCommercialInvoiceCost] = useState(false);
    const handleCloseDeleteCommercialInvoiceCost = () => setShowDeleteCommercialInvoiceCost(false);
    const handleShowDeleteCommercialInvoiceCost = () => setShowDeleteCommercialInvoiceCost(true);

    const [showAddCommercialInvoiceCost, setShowAddCommercialInvoiceCost] = useState(false);
    const handleCloseAddCommercialInvoiceCost = () => setShowAddCommercialInvoiceCost(false);
    const handleShowAddCommercialInvoiceCost = () => setShowAddCommercialInvoiceCost(true);
    const [commercialInvoiceCost, setCommercialInvoiceCost] = useState({
        customerCommercialInvoiceId: 0,
        amount: 0,
        shippingCostOptionId: 0,
        userId: localStorage.getItem("uTsReD"),
    });

    const [shippingCostTypes, setShippingCostTypes] = useState([])
    const GetShippingCostTypes = () => {
        var config = {
            method: 'get',
            url: "ShippingCostType/GetShippingCostsOptions?companyId=" + localStorage.getItem("C0m8N!"),
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingCostTypes(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const IsValidCommercialInvoiceCost = () => {
        if (commercialInvoiceCost.shippingCostOptionId === 0 && commercialInvoiceCost.amount === 0.0) {
            toast.error("Enter all required fields");
            return false;
        }
        else if (commercialInvoiceCost.shippingCostOptionId === 0) {
            toast.error("Select the shipping cost invoice type.");
            return false;
        } else if (commercialInvoiceCost.amount <= 0.0) {
            toast.error("Enter the amount.");
            return false;
        }
        return true;
    }

    const submitCommercialInvoiceCostForm = (e) => {
        e.preventDefault();
        setSubmitDisabled(true);
        if (IsValidCommercialInvoiceCost()) {
            if (editingCommercialInvoiceCostId > 0) {
                var config = {
                    method: 'put',
                    url: "CustomerCommercialInvoice/EditCustomerCommercialInvoiceShippingCost",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    params: {
                        'customerCommercialInvoiceShippingCostId': editingCommercialInvoiceCostId
                    },
                    data: commercialInvoiceCost
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Cost Updated Successfully")
                        handleCloseAddCommercialInvoiceCost();
                        GetCommercialInvoices(pId.OrderId);
                        GetCustomerOrder(pId.OrderId);
                        setSubmitDisabled(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                        setSubmitDisabled(false);
                    });
            } else {
                var config = {
                    method: 'post',
                    url: "CustomerCommercialInvoice/AddCustomerCommercialInvoiceShippingCost",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: commercialInvoiceCost
                };

                axiosInstance(config)
                    .then(function (response) {
                        toast.success("Cost Added Successfully")
                        handleCloseAddCommercialInvoiceCost();
                        // setShipmentShippingCosts(response.data);
                        GetCommercialInvoices(pId.OrderId);
                        setSubmitDisabled(false);
                        GetCustomerOrder(pId.OrderId);
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data)
                        setSubmitDisabled(false);
                    });
            }
        } else {
            setSubmitDisabled(false);
        }
    }

    const DeleteCommercialInvoiceCost = (costId) => {
        var config = {
            method: 'delete',
            url: "CustomerCommercialInvoice/DeleteCustomerCommercialInvoiceShippingCost?CustomerCommercialInvoiceShippingCostId=" + costId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                toast.success("Cost deleted successfully.");
                GetCommercialInvoices(pId.OrderId);
                handleCloseDeleteCommercialInvoiceCost();
                GetCustomerOrder(pId.OrderId);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.response.data.message);
            });
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link onClick={() => { navigate(-2) }}>{customerName}</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link onClick={() => { navigate(-1) }}>{account.customerAccount1}</Link>
                                    </li>
                                    <li className="breadcrumb-item"><a href="#">#{order.invoiceNumber}</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <Row >
                        <Col sm={5} className="flex">
                            <Card>
                                <CardBody>
                                    <table cellPadding={10}>
                                        <tr>
                                            <td>
                                                <div role="alert">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            {/* fas fa-info-circle */}
                                                            <h5 class="alert-heading font-18"><i class="fas fa-file-invoice" /> Order Details</h5>
                                                        </div>
                                                        <Col md={2} className="text-right">
                                                            {permissions.manageCustomerOrder === true && (
                                                                <div className="d-flex justify-content-end">
                                                                    <Button variant="outline-warning" size="sm" className="me-1"
                                                                        onClick={() => {

                                                                            // todo:
                                                                            GetPaymentTerms();

                                                                            setEditingOrder((prevState) => ({
                                                                                ...prevState,
                                                                                invoiceNumber: order.invoiceNumber,
                                                                                commission: order.commissionPerFCL,
                                                                                notes: order.notes,
                                                                                paymentTermsId: order.paymentTermsId,
                                                                                paymentTermsName: order.paymentTermsName,
                                                                                date: order.date.split('T')[0],
                                                                            }));

                                                                            handleShowEditCustomerOrder();
                                                                        }
                                                                        } ><FiEdit /></Button>
                                                                    <Button variant="outline-danger" size="sm" onClick={() => {
                                                                        handleShowDeleteCustomerOrder();
                                                                    }
                                                                    }><FiTrash2 /></Button>
                                                                </div>
                                                            )}
                                                        </Col>
                                                        <p>
                                                            Invoice Number: <b>{order.invoiceNumber != null && order.invoiceNumber.length > 0 ? '#' + order.invoiceNumber : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Supplier Invoices: <b>{order.supplierInvoices != null && order.supplierInvoices.length > 0 ? '#' + order.supplierInvoices : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Order Date: <b>{order.date != null && order.date.length > 0 ? order.date.split('T')[0] : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Commission Per FCL: <b>{order.commissionPerFCL != null && order.commissionPerFCL != null ? currency.currencySuffix + " " + order.commissionPerFCL.toLocaleString() : currency.currencySuffix + " 0"}</b>
                                                            <br />
                                                            {/* Total Commission: <b>{order.totalCommission != null && order.totalCommission != null ? currency.currencySuffix + " " + order.totalCommission.toLocaleString() : currency.currencySuffix + " 0"}</b>
                                                            <br /> */}

                                                            Total: <b>{order.totalPrice != null && order.totalPrice != null ? currency.currencySuffix + " " + order.totalPrice.toLocaleString() : currency.currencySuffix + " 0"}</b>
                                                            <br />
                                                            {/*Other Charges: <b>{orderTotal != null && orderTotal.otherCharges != null ? currency.currencySuffix + " " + orderTotal.otherCharges.toLocaleString() : <Skeleton width={100} />}</b>
                                                            <br />
                                                            Total: <b>{orderTotal != null && orderTotal.totalAmount != null ? currency.currencySuffix + " " + orderTotal.totalAmount.toLocaleString() : <Skeleton width={100} />}</b> */}
                                                        </p>
                                                        <table cellPadding={10}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div role="alert">
                                                                            <div className="row" style={{ display: 'flex', height: '100%' }}>
                                                                                <div className="col-6" style={{ flex: 1, display: 'flex' }}>
                                                                                    <div className="alert alert-warning fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                        <h6 className="alert-heading font-18"><i class="mdi mdi-cash-lock" /> Payment Terms</h6>
                                                                                        <span className="text-secondary">{order.paymentTermsName != null && order.paymentTermsName.length > 0 ? order.paymentTermsName : "-"}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6" style={{ flex: 1, display: 'flex' }}>
                                                                                    <div className="alert alert-warning fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                        <h6 className="alert-heading font-18"><i className="fas fa-pencil-alt" /> Notes</h6>
                                                                                        <span className="text-secondary">{order.notes}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <Tabs defaultActiveKey="products" className="mt-3 ms-2">
                                                            <Tab
                                                                eventKey="products"
                                                                title={
                                                                    <span>
                                                                        Products{' '}
                                                                        {/* <Badge className="rounded-pill bg-primary badge">{c.products?.length || 0}</Badge> */}
                                                                        {
                                                                            order.productsPrice > 0 &&
                                                                            <Badge className="rounded-pill bg-primary badge">{order.productsPrice.toLocaleString() || 0}</Badge>
                                                                        }
                                                                        {
                                                                            //orderTotal.orderProducts != null && orderTotal.orderProducts > 0 &&
                                                                            //<Badge className="rounded-pill bg-primary badge">{orderTotal.orderProducts.toLocaleString() || 0}</Badge>

                                                                        }
                                                                    </span>
                                                                }>
                                                                <div className="mt-3">
                                                                    {order.products != null && order.products.length > 0 && order.products.map((p, index) => {
                                                                        return (
                                                                            <Alert key={index} variant="primary" className="fade show">
                                                                                <Row>
                                                                                    <Col md={10}>
                                                                                        <h6 className="alert-heading font-18">
                                                                                            <b>{p.fcl} FCL ({p.productQuantity.toLocaleString()} {p.productUnitName}) {p.productName}, {p.productTypeName}, {p.productSizeName}</b>
                                                                                        </h6>
                                                                                        <span className="text-secondary">Unit Price: <b>{currency.currencySuffix} {p.unitPrice.toLocaleString()}</b></span><br />
                                                                                        <span className="text-secondary">Total Price: <b>{currency.currencySuffix} {(p.productQuantity * p.unitPrice).toLocaleString()}</b></span><br />
                                                                                    </Col>
                                                                                    <Col md={2} className="text-right">
                                                                                        {permissions.manageCustomerOrder === true && (
                                                                                            <div className="d-flex justify-content-end">
                                                                                                <Button variant="warning" size="sm" className="me-1" onClick={(e) => {
                                                                                                    setEditingProductId(p.customerOrderProductId);

                                                                                                    //GetSupplierOrderProductBySupplierId(p.supplierId);
                                                                                                    handleSupplierChange(p.supplierId);

                                                                                                    setOrderProduct((prevState) => ({
                                                                                                        ...prevState,
                                                                                                        customerOrderId: pId.OrderId,
                                                                                                        customerOrderProductId: p.customerOrderProductId,
                                                                                                        productQuantity: p.productQuantity,
                                                                                                        productUnitName: p.productUnitName,
                                                                                                        fcl: p.fcl,
                                                                                                        oldFcl: p.fcl,
                                                                                                        unitPrice: p.unitPrice,
                                                                                                        supplierId: p.supplierId,
                                                                                                        supplierName: suppliers && suppliers.length > 0
                                                                                                            ? (suppliers.find((s) => s.supplierId === p.supplierId)?.supplierName || "")
                                                                                                            : "",
                                                                                                        pendingFcl: p.pendingFcl,
                                                                                                        supplierOrderProductId: p.supplierOrderProductId,
                                                                                                        productDescription: p.productName + ", " + p.productTypeName + ", " + p.productSizeName,
                                                                                                        description: " FCL, " + p.productName + ", " + p.productTypeName + ", " + p.productSizeName,
                                                                                                        unit: p.productUnitName,
                                                                                                        // description: row.product.productName + ", " + row.type.productTypeName + ", " + row.size.productSizeName,
                                                                                                        // unit: row.customerOrderProduct.unit,
                                                                                                    }));
                                                                                                    handleShowAddOrderProduct();
                                                                                                }}><FiEdit /></Button>
                                                                                                <Button variant="danger" size="sm" onClick={() => {
                                                                                                    setDeletingCustomerOrderProductId(p.customerOrderProductId);
                                                                                                    handleShowDeleteCustomerOrderProduct();
                                                                                                }}><FiTrash2 /></Button>
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Alert>
                                                                        );
                                                                    })}
                                                                    {permissions.manageCustomerOrder === true && (
                                                                        <Button variant="outline-primary" className="w-100 mt-3"
                                                                            onClick={() => {
                                                                                setEditingProductId(0);
                                                                                setOrderProduct({
                                                                                    supplierOrderProductId: 0,
                                                                                    customerOrderId: order.customerOrderId,
                                                                                    productQuantity: 0,
                                                                                    fcl: 0,
                                                                                    unitPrice: 0,
                                                                                    supplierId: 0,
                                                                                    userId: localStorage.getItem("uTsReD"),
                                                                                    proforma: "",
                                                                                    description: "",
                                                                                    commissionPerFCL: 0.0,
                                                                                    unit: ""
                                                                                });
                                                                                handleShowAddOrderProduct();
                                                                            }}>
                                                                            <b><FiPlus /> Add New Product</b>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </Tab>
                                                            {/* <Tab
                                                                eventKey="payments"
                                                                title={
                                                                    <span>
                                                                        Advanced Payments{' '}
                                                                        <Badge className="rounded-pill bg-primary badge">{advancedPayments?.length || 0}</Badge>
                                                                        {
                                                                            orderTotal.orderAdvancedPayments != null && orderTotal.orderAdvancedPayments > 0 &&
                                                                            <Badge className="rounded-pill bg-primary badge">{orderTotal.orderAdvancedPayments.toLocaleString() || 0}</Badge>
                                                                        }
                                                                    </span>
                                                                }
                                                            >
                                                                <div className="mt-3">
                                                                    {advancedPayments != null && advancedPayments.length > 0 && advancedPayments.map((p, index) => {
                                                                        return (
                                                                            <Alert key={index} variant="primary" className="fade show">
                                                                                <Row>
                                                                                    <Col md={9}>
                                                                                        <h6 className="alert-heading font-18">
                                                                                            Amount: <b>{currency.currencySuffix} {p.supplierAdvancedPayment.toLocaleString()}</b>
                                                                                        </h6>
                                                                                        <span className="text-secondary"><i className="mdi mdi-bank" /> {p.bankAccount}</span><br />
                                                                                        {p.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {p.notes}</span>}
                                                                                    </Col>
                                                                                    <Col md={3} className="text-right">
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <h6 className="alert-heading font-14"><b>{p.supplierAdvancedPaymentDate.split("T")[0]}</b></h6>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Alert>
                                                                        );
                                                                    })}
                                                                    <Button variant="outline-primary" className="w-100 mt-3" onClick={handleShowAddAdvancedPayment}>
                                                                        <b><FiPlus /> Add New Payment</b>
                                                                    </Button>
                                                                </div>
                                                            </Tab>

                                                            <Tab
                                                                eventKey="discounts"
                                                                title={
                                                                    <span>
                                                                       Advanced Discounts{' '}
                                                                        <Badge className="rounded-pill bg-primary badge">{advancedDiscounts?.length || 0}</Badge>
                                                                        {
                                                                            orderTotal.orderAdvancedDiscounts != null && orderTotal.orderAdvancedDiscounts > 0 &&
                                                                            <Badge className="rounded-pill bg-primary badge">{orderTotal.orderAdvancedDiscounts.toLocaleString() || 0}</Badge>
                                                                        }
                                                                    </span>
                                                                }
                                                            >
                                                                <div className="mt-3">
                                                                    {advancedDiscounts != null && advancedDiscounts.length > 0 && advancedDiscounts.map((d, index) => {
                                                                        return (
                                                                            <Alert key={index} variant="primary" className="fade show">
                                                                                <Row>
                                                                                    <Col md={9}>
                                                                                        <h6 className="alert-heading font-18">
                                                                                            Amount: <b>{currency.currencySuffix} {d.advancedDiscount.toLocaleString()}</b>
                                                                                        </h6>
                                                                                        {d.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {d.notes}</span>}
                                                                                    </Col>
                                                                                    <Col md={3} className="text-right">
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <h6 className="alert-heading font-14"><b>{d.discountDate.split("T")[0]}</b></h6>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Alert>
                                                                        );
                                                                    })}
                                                                    <Button variant="outline-primary" className="w-100 mt-3" onClick={handleShowAdvancedDiscount}>
                                                                        <b><FiPlus /> Add New Discount</b>
                                                                    </Button>
                                                                </div>
                                                            </Tab> */}
                                                        </Tabs>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={7} className="flex">
                            <Card>
                                <CardBody>
                                    <table cellPadding={10}>
                                        <tr>
                                            <td>
                                                <div role="alert">
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <h5 class="alert-heading font-18"><i class="fas fa-file-alt" /> Commercial Invoices</h5>
                                                        </div>
                                                        <div className="mt-3">
                                                            {commercialInvoices != null && commercialInvoices.length > 0 && commercialInvoices.map((c, index) => {
                                                                return (
                                                                    <Alert key={index} style={{
                                                                        borderWidth: 3,
                                                                        backgroundColor: '#FAFAFA',
                                                                        borderColor: c.profit < 0 ? '#f64658' : '#ebfbf0',
                                                                      }} className="fade show">
                                                                        <Row>
                                                                            <Col md={10}>
                                                                                <p className="text-secondary">
                                                                                    Invoice Number: <b>{c.invoiceNumber != null && c.invoiceNumber.length > 0 ? '#' + c.invoiceNumber : <Skeleton width={100} />}</b>
                                                                                    <br />
                                                                                    Supplier Invoice Number: <b>{'#' + c.supplierInvoiceNumber}</b>
                                                                                    <br />
                                                                                    {c.profit != null && (
                                                                                        <>
                                                                                            {c.profit < 0 ? (
                                                                                                <span style={{ color: 'red' }}>Loss: <b>{Math.abs(c.profit).toLocaleString()} {currency.currencySuffix}</b></span>
                                                                                            ) : (
                                                                                                <span>Profit: <b>{c.profit.toLocaleString()} {currency.currencySuffix}</b></span>
                                                                                            )}
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    Invoice Date: <b>{c.date != null && c.date.length > 0 ? c.date.split('T')[0] : <Skeleton width={100} />}</b>
                                                                                    {
                                                                                        (c.tracking != null && c.tracking.statusId > 0 && c.tracking.statusId < 50 && c.tracking.arrivalDate != null) && <>
                                                                                            <br />
                                                                                            ETA: <b>{c.tracking.arrivalDate}</b>
                                                                                            <br />
                                                                                            BL : <b>{c.tracking.blReferenceNo}</b>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        (c.tracking != null && c.tracking.statusId === 50 && c.tracking.dischargeDate != null) && <>
                                                                                            <br />
                                                                                            Discharged on : <b>{c.tracking.dischargeDate}</b>
                                                                                            <br />
                                                                                            BL : <b>{c.tracking.blReferenceNo}</b>
                                                                                        </>
                                                                                    }
                                                                                     <br />
                                                                                    {
                                                                                        c.notes != null && c.notes.length > 0 && ("Notes: " + c.notes)
                                                                                    }
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="text-right">
                                                                                {permissions.manageCustomerOrder === true && (
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <Button variant="outline-warning" size="sm" className="me-1"
                                                                                            onClick={() => {
                                                                                                setEditingCommercialInvoiceId(c.customerCommercialInvoiceId);
                                                                                                setEditingCommercialInvoice((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    customerCommercialInvoice: c.invoiceNumber,
                                                                                                    customerCommercialInvoiceDate: c.date.split('T')[0],
                                                                                                    notes: c.notes,
                                                                                                }));

                                                                                                handleShowEditCommercialInvoice();
                                                                                            }
                                                                                            } ><FiEdit /></Button>
                                                                                        <Button variant="outline-danger" size="sm" onClick={() => {
                                                                                            // setDeletingCommercialId(c.commercialInvoiceId);
                                                                                            // handleShowDeleteInvoice();
                                                                                            setDeletingCommercialInvoiceId(c.customerCommercialInvoiceId);
                                                                                            setDeletingCommercialInvoice(c.invoiceNumber);
                                                                                            setShowDeleteCommercialInvoice(true);
                                                                                        }
                                                                                        }><FiTrash2 /></Button>
                                                                                    </div>
                                                                                )}
                                                                                <div className="d-flex justify-content-end mt-2">
                                                                                    {c.totalPrice !== null && (
                                                                                        <span style={{
                                                                                            color: '#8e3241',
                                                                                            backgroundColor: '#fbdde2',
                                                                                            borderColor: '#f9cbd3',
                                                                                            borderRadius: 5,
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            <b>{currency.currencySuffix} {c.totalPrice.toLocaleString()}</b>
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <div className="d-flex justify-content-end mt-2">
                                                                                    {(c.tracking != null && c.tracking.statusId > 0) &&
                                                                                        <>
                                                                                            {
                                                                                                c.tracking.statusId < 50 ?
                                                                                                    <span style={{ ...trackingStatusStyles[c.tracking.statusId], borderRadius: 5, padding: '0 5px' }}><b>{c.tracking.status}</b></span>
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            c.tracking.statusId === 50 &&
                                                                                                            <span style={{ ...trackingStatusStyles[c.tracking.statusId], borderRadius: 5, padding: '0 5px' }}><b>{c.tracking.status}</b></span>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Tabs defaultActiveKey="products" className="ms-2">
                                                                                <Tab
                                                                                    eventKey="products"
                                                                                    title={
                                                                                        <span>
                                                                                            Products{' '}
                                                                                            {/* <Badge className="rounded-pill bg-primary badge">{c.products?.length || 0}</Badge> */}
                                                                                            {
                                                                                                c.productsPrice > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.productsPrice.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }>
                                                                                    <div className="mt-3">
                                                                                        {c.products != null && c.products.length > 0 && c.products.map((p, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={9}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                <b>{p.fcl} FCL ({p.productQuantity.toLocaleString()} {p.productUnitName}) {p.productName}, {p.productTypeName}, {p.productSizeName}</b>
                                                                                                            </h6>
                                                                                                            {/* <span className="text-secondary">Unit Price: <b>{currency.currencySuffix} {p.unitPrice.toLocaleString()}</b></span><br />
                                                                                                            <span className="text-secondary">Total Price: <b>{currency.currencySuffix} {(p.productQuantity * p.unitPrice).toLocaleString()}</b></span><br /> */}
                                                                                                        </Col>
                                                                                                        <Col md={3} className="text-right">
                                                                                                            <div className="d-flex justify-content-end mt-2">
                                                                                                                <span style={{ color: '#8e3241', backgroundColor: '#fbdde2', borderColor: '#f9cbd3', borderRadius: 5, paddingLeft: 5, paddingRight: 5 }}><b>{currency.currencySuffix} {(p.productQuantity * p.unitPrice).toLocaleString()}</b></span>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        {/* <Button variant="outline-primary" className="w-100" onClick={()=> {
                                                                                            //  setCommercialProduct((prevState) => ({
                                                                                            //     ...prevState,
                                                                                            //     commercialInvoiceId: c.commercialInvoiceId,
                                                                                            //     performa: order.invoiceNumber,
                                                                                            //     commercialInvoice: c.invoiceNumber
                                                                                            // }));
                                                                                            // handleShowAddCommercialProduct();
                                                                                        }}>
                                                                                            <b><FiPlus /> Add New Product</b>
                                                                                        </Button> */}
                                                                                    </div>
                                                                                </Tab>
                                                                                <Tab
                                                                                    eventKey="charges"
                                                                                    title={
                                                                                        <span>
                                                                                            Charges{' '}
                                                                                            {
                                                                                                (c.otherCharges?.reduce((sum, p) => sum + p.amount, 0) > 0) && (
                                                                                                    <Badge className="rounded-pill bg-primary badge">
                                                                                                        {c.otherCharges.reduce((sum, p) => sum + p.amount, 0)}
                                                                                                    </Badge>
                                                                                                )
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <div className="mt-3">
                                                                                        {c.otherCharges != null && c.otherCharges.length > 0 && c.otherCharges.map((p, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={10}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                {p.name}: <b>{currency.currencySuffix} {p.amount.toLocaleString()}</b>
                                                                                                            </h6>
                                                                                                        </Col>
                                                                                                        <Col md={2} className="text-right">
                                                                                                            {permissions.manageCustomerOrder === true && (
                                                                                                                <div className="d-flex justify-content-end">
                                                                                                                    <Button variant="warning" size="sm" className="me-1" onClick={(e) => {
                                                                                                                        setEditingCommercialInvoiceCostId(p.customerCommercialInvoiceShippingCostId)

                                                                                                                        setCommercialInvoiceCost((prevState) => ({
                                                                                                                            ...prevState,
                                                                                                                            customerCommercialInvoiceId: c.customerCommercialInvoiceId,
                                                                                                                            amount: p.amount,
                                                                                                                            shippingCostOptionId: p.shippingCostsOptionId,
                                                                                                                            shippingCostOptionName: p.name
                                                                                                                        }));

                                                                                                                        handleShowAddCommercialInvoiceCost();
                                                                                                                    }}><FiEdit /></Button>
                                                                                                                    <Button variant="danger" size="sm" onClick={() => {
                                                                                                                        setDeletingCommercialInvoiceCostId(p.customerCommercialInvoiceShippingCostId)
                                                                                                                        handleShowDeleteCommercialInvoiceCost();
                                                                                                                    }}><FiTrash2 /></Button>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        {permissions.manageCustomerOrder === true && (
                                                                                            <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                                setEditingCommercialInvoiceCostId(0)
                                                                                                setCommercialInvoiceCost((prevState) => ({
                                                                                                    ...prevState,
                                                                                                    customerCommercialInvoiceId: c.customerCommercialInvoiceId,
                                                                                                    amount: 0,
                                                                                                    shippingCostOptionId: 0,
                                                                                                    shippingCostOptionName: ""
                                                                                                }));
                                                                                                handleShowAddCommercialInvoiceCost();
                                                                                            }}>
                                                                                                <b><FiPlus /> Add New Charge</b>
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                </Tab>
                                                                                {/* <Tab
                                                                                    eventKey="payments"
                                                                                    title={
                                                                                        <span>
                                                                                            Payments{' '}
                                                                                            <Badge className="rounded-pill bg-primary badge">{c.payments?.length || 0}</Badge>
                                                                                            {
                                                                                                c.invoiceTotals?.productTotalPayment > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.invoiceTotals?.productTotalPayment.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <div className="mt-3">
                                                                                        {c.payments != null && c.payments.length > 0 && c.payments.map((p, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={9}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                Amount: <b>{currency.currencySuffix} {p.paymentValue.toLocaleString()}</b>
                                                                                                            </h6>
                                                                                                            {p.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {p.notes}</span>}
                                                                                                        </Col>
                                                                                                        <Col md={3} className="text-right">
                                                                                                            <div className="d-flex justify-content-end">
                                                                                                                <h6 className="alert-heading font-14"><b>{p.invoiceDate.split("T")[0]}</b></h6>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                            setCommercialInvoicePayment((prevState) => ({
                                                                                                ...prevState,
                                                                                                commercialInvoiceId: c.commercialInvoiceId,
                                                                                                commercialInvoiceNumber: c.invoiceNumber,
                                                                                                invoiceNumber: order.invoiceNumber,
                                                                                                remainingTotal: c.invoiceTotals.remainingTotal,
                                                                                                supplierName: supplierName,
                                                                                                supplierOrderId: order.supplierOrderId,
                                                                                                supplierAccountName: account.supplierAccount1,

                                                                                            }));
                                                                                            handleShowAddCommercialInvoicePayment();
                                                                                        }}>
                                                                                            <b><FiPlus /> Add New Payment</b>
                                                                                        </Button>
                                                                                    </div>
                                                                                </Tab>

                                                                                <Tab
                                                                                    eventKey="discounts"
                                                                                    title={
                                                                                        <span>
                                                                                        Discounts{' '}
                                                                                            <Badge className="rounded-pill bg-primary badge">{c.discounts?.length || 0}</Badge>
                                                                                            {
                                                                                                c.invoiceTotals?.productTotalDiscount > 0 &&
                                                                                                <Badge className="rounded-pill bg-primary badge">{c.invoiceTotals?.productTotalDiscount.toLocaleString() || 0}</Badge>
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <div className="mt-3">
                                                                                        {c.discounts != null && c.discounts.length > 0 && c.discounts.map((d, index) => {
                                                                                            return (
                                                                                                <Alert key={index} variant="primary" className="fade show">
                                                                                                    <Row>
                                                                                                        <Col md={9}>
                                                                                                            <h6 className="alert-heading font-18">
                                                                                                                Amount: <b>{currency.currencySuffix} {d.commercialInvoiceDiscount.toLocaleString()}</b>
                                                                                                            </h6>
                                                                                                            {d.notes && <span className="text-secondary"><i className="fas fa-pencil-alt" /> {d.notes}</span>}
                                                                                                        </Col>
                                                                                                        <Col md={3} className="text-right">
                                                                                                            <div className="d-flex justify-content-end">
                                                                                                                <h6 className="alert-heading font-14"><b>{d.discountDate.split("T")[0]}</b></h6>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Alert>
                                                                                            );
                                                                                        })}
                                                                                        <Button variant="outline-primary" className="w-100" onClick={() => {
                                                                                              setCommercialDiscount((prevState) => ({
                                                                                                ...prevState,
                                                                                                commercialInvoiceNumber: c.invoiceNumber,
                                                                                                remainingTotal: c.invoiceTotals.remainingTotal,
                                                                                                commercialInvoiceId: c.commercialInvoiceId,
                                                                                                commercialInvoiceDiscount: 0,
                                                                                                discountDate: formatDate(new Date()),
                                                                                                notes: "",
                                                                                                commercialInvoice: c.invoiceNumber,
                                                                                                proforma: order.invoiceNumber,
                                                                                                userId: localStorage.getItem("uTsReD"),
                                                                                                addToStatement: false
                                                                                            }));
                                                                                            handleShowAddCommercialDiscount();
                                                                                        }}>
                                                                                            <b><FiPlus /> Add New Discount</b>
                                                                                        </Button>
                                                                                    </div>
                                                                                </Tab> */}
                                                                            </Tabs>
                                                                        </Row>
                                                                    </Alert>
                                                                );
                                                            })}
                                                            {permissions.manageCustomerOrder === true && (
                                                                <Button variant="primary" className="w-100 mt-3" onClick={(e) => {
                                                                    setCustomerCommercialInvoice((prevState) => ({
                                                                        ...prevState,
                                                                        customerCommercialInvoice: "",
                                                                        customerCommercialInvoiceId: 0,
                                                                        notes: "",
                                                                        customerCommercialInvoiceDate: (new Date()).toISOString().split('T')[0],//.substring(0, 10),
                                                                    }))
                                                                    handleShowAddCustomerCommercialInvoice();
                                                                }}>
                                                                    <b><FiPlus /> Add New Invoice</b>
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={12} style={{ flex: 1, display: 'flex' }}>
                            <Card style={{ flex: 1 }}>
                                <CardBody>
                                    <h5 className=" mb-3 alert-heading font-18"><i class="ion ion-md-attach" /> Attachments</h5>

                                    <Dropzone onDrop={handleAcceptedFiles}>
                                        {({ getRootProps, getInputProps }) => {
                                            const rootProps = getRootProps();
                                            const inputProps = getInputProps();

                                            return (
                                                <div {...rootProps}>
                                                    {permissions.manageCustomerOrder === true && (
                                                        <button
                                                            onClick={rootProps.onClick}
                                                            className="btn btn-primary mb-2 mt-3"
                                                            style={{ width: '100%' }}
                                                        >
                                                            <b><FiPlus /> Upload Attachments</b>
                                                        </button>
                                                    )}
                                                    <input {...inputProps} style={{ display: 'none' }} />
                                                </div>
                                            );
                                        }}
                                    </Dropzone>
                                    <br />
                                    <Row>
                                        {uploadedFiles.map((file, index) => (
                                            <Col sm={3} key={index} className="mb-1">
                                                <Card style={{ position: 'relative' }}>
                                                    {permissions.manageCustomerOrder === true && (
                                                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                                            <button
                                                                className="btn btn-danger btn-md"
                                                                onClick={() => handleDelete(file.attachmentId)}
                                                            >
                                                                <FiTrash2 />
                                                            </button>
                                                        </div>
                                                    )}
                                                    <CardBody>
                                                        <span>{file.name}</span>
                                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {renderFilePreview(file)}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal size="md" show={showAddCommercialInvoiceCost} onHide={handleCloseAddCommercialInvoiceCost}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Cost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Cost Type</label>
                            <Select
                                required
                                placeholder="-- Select Type --"
                                defaultValue={commercialInvoiceCost.shippingCostOptionName ? { label: commercialInvoiceCost.shippingCostOptionName, value: commercialInvoiceCost.shippingCostOptionId } : null}
                                isSearchable={true}
                                options={shippingCostTypes.map(f => ({
                                    value: f,
                                    label: f.name
                                }))}

                                valuekey
                                onChange={(e) => {
                                    setCommercialInvoiceCost((prevState) => ({
                                        ...prevState,
                                        shippingCostOptionId: e.value.shippingCostsOptionId,
                                        shippingCostOptionName: e.value.name,
                                    }));
                                }}>
                            </Select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Amount</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    {currency.currencySuffix}
                                </span>

                                <input type="number" className="form-control" required
                                    value={commercialInvoiceCost.amount}
                                    // disabled={shippingCost.isPaid === true}
                                    onChange={(e) => {
                                        setCommercialInvoiceCost((prevState) => ({
                                            ...prevState,
                                            amount: e.target.value
                                        }))
                                    }}>
                                </input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddCommercialInvoiceCost}>
                        Close
                    </Button>
                    <Button variant="primary"
                        disabled={submitDisabled} onClick={(e) =>
                            submitCommercialInvoiceCostForm(e)
                        }>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteCommercialInvoiceCost} onHide={handleCloseDeleteCommercialInvoiceCost}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Cost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this cost?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCommercialInvoiceCost}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteCommercialInvoiceCost(deletingCommercialInvoiceCostId)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditCustomerOrder} onHide={handleCloseEditCustomerOrder}>
                <Modal.Header closeButton>
                    <Modal.Title>Update info for #{order.invoiceNumber}
                        {/* <h5><small>Remaining Total: {currency.currencySuffix} {commercialDiscount.remainingTotal.toLocaleString()}</small></h5> */}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Invoice Number</label>
                            <div className="input-group mb-3">
                                {/* <span className="input-group-text">{currency.currencySuffix}</span> */}
                                <input className="form-control"
                                    type="text"
                                    placeholder="Enter the invoice number"
                                    value={editingOrder.invoiceNumber}
                                    onChange={(e) => {
                                        setEditingOrder((prevState) => ({
                                            ...prevState,
                                            invoiceNumber: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                        </div>
                        <div className="col-12">
                            <label>Date</label>
                            <input className="form-control mb-3"
                                type="date"
                                value={editingOrder.date}
                                onChange={(e) => {
                                    setEditingOrder((prevState) => ({
                                        ...prevState,
                                        date: e.target.value
                                    }));
                                }}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Payment Terms</label>
                            <Select
                                placeholder="-- Select Payment Terms --"
                                isSearchable={true}
                                value={editingOrder.paymentTermsId > 0 ? { label: editingOrder.paymentTermsName, value: editingOrder.paymentTermsId } : null}
                                options={paymentTerms.map(p => ({
                                    value: p,
                                    label: p.name
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingOrder((prevState) => ({
                                        ...prevState,
                                        paymentTermsId: e.value.paymentTermsId,
                                        paymentTermsName: e.value.name
                                    }))
                                }}>
                            </Select>
                        </div>
                        <div className="col-12">
                            <label>Commission (Per FCL)</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">{currency.currencySuffix}</span>
                                <input className="form-control"
                                    type="number"
                                    step="1"
                                    value={editingOrder.commission}
                                    placeholder="Commission (Optional)"
                                    onChange={(e) => {
                                        setEditingOrder((prevState) => ({
                                            ...prevState,
                                            commission: e.target.value
                                        }));
                                    }}>
                                </input>
                            </div>
                        </div>

                        <div className="col-12">
                            <label>Notes</label>
                            <textarea className="form-control"
                                placeholder="Your notes (Optional)"
                                value={editingOrder.notes}
                                onChange={(e) => {
                                    setEditingOrder((prevState) => ({
                                        ...prevState,
                                        notes: e.target.value
                                    }));
                                }}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseEditCustomerOrder}>
                        Close
                    </button>
                    <button className="btn btn-primary" disabled={submitDisabled} onClick={(e) => submitCustomerOrderForm(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>


            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label>Invoice Number</label>
                            <input className="form-control" placeholder="Invoice Number" onChange={(e) => setCustomerCommercialInvoice((prevState) => ({
                                ...prevState, customerCommercialInvoice: e.target.value
                            }))}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Supplier Commercial Invoice</label>
                            <Select
                                placeholder="-- Select Commercial Invoice --"
                                isSearchable={true}
                                options={supplierCommercialInvoices.map(x => ({
                                    value: x,
                                    label: x.invoiceNumber
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setCustomerCommercialInvoice((prevState) => ({
                                        ...prevState, 
                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId,
                                        customerCommercialInvoiceDate: e.value.date.split('T')[0]
                                    }))
                                }}></Select>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Date</label>
                            <input className="form-control" type="date" value={customerCommercialInvoice.customerCommercialInvoiceDate} onChange={(e) => setCustomerCommercialInvoice((prevState) => ({
                                ...prevState, customerCommercialInvoiceDate: e.target.value
                            }))}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Notes</label>
                            <textarea className="form-control" placeholder="Notes"
                                onChange={(e) => setCustomerCommercialInvoice((prevState) => ({
                                    ...prevState, notes: e.target.value
                                }))}></textarea>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => AddCustomerCommercialInvoice(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <Modal size="md" show={showDeleteCustomerOrder} onHide={handleCloseDeleteCustomerOrder}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Order #{order.invoiceNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this order?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCustomerOrder}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteCustomerOrder()}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal size="md" show={showDeleteCustomerOrderProduct} onHide={handleCloseDeleteCustomerOrderProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Order #{order.invoiceNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete this product?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCustomerOrderProduct}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => DeleteCustomerOrderProduct()}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showAddOrderProduct} onHide={handleCloseAddOrderProduct}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingOrder > 0 ? "Edit Order Product" : "Add Order Product"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <label>Supplier</label>
                            {editingProductId > 0 ?
                                <Select
                                    placeholder="-- Select Supplier --"
                                    isSearchable={true}
                                    isDisabled={true}
                                    defaultValue={{ label: orderProduct.supplierName, value: orderProduct.supplierId }}
                                    options={suppliers.map(c => ({
                                        value: c,
                                        label: c.supplierName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        handleSupplierChange(e.value.supplierId);
                                        setOrderProduct((prevState) => ({
                                            ...prevState,
                                            supplierName: e.value.supplierName
                                        }))
                                    }}>
                                </Select>
                                :
                                <Select
                                    placeholder="-- Select Supplier --"
                                    isSearchable={true}
                                    options={suppliers.map(c => ({
                                        value: c,
                                        label: c.supplierName
                                    }))}
                                    valuekey
                                    onChange={(e) => {
                                        handleSupplierChange(e.value.supplierId);
                                        setOrderProduct((prevState) => ({
                                            ...prevState,
                                            supplierName: e.value.supplierName
                                        }))
                                    }}>
                                </Select>
                            }

                            <label className="mt-3">Products</label>
                            {editingProductId > 0 ?
                                <Select
                                    placeholder="-- Select Supplier Product --"
                                    isSearchable={true}
                                    isDisabled={true}
                                    defaultValue={{ label: orderProduct.productDescription, value: orderProduct.supplierOrderProductId }}
                                    options={supplierOrderProducts.map(c => ({
                                        value: c,
                                        label: ('#' + c.supplierOrders.invoiceNumber + ' - ' + c.productName + ', ' + c.productSizeName + ', ' + c.productTypeName + ' (' + c.supplierOrderProducts.pendingFcl + ' FCL / ' + c.supplierOrderProducts.pendingQuantity + ' ' + c.productUnit1 + ') (' + c.currency.currencySuffix + ' ' + c.supplierOrderProducts.unitPrice + ')')
                                    }))}
                                    valuekey
                                    // value={{label: orderProduct.productDescription, value: orderProduct.supplierOrderProductId}}
                                    onChange={(e) => {
                                        setOrderProduct((prevState) => ({
                                            ...prevState,
                                            supplierOrderProductId: e.value.supplierOrderProductId,
                                            productDescription: e.value.productName,
                                            pendingQuantity: e.value.pendingQuantity,
                                            pendingFcl: e.value.pendingFcl,
                                            productUnitName: e.value.productUnit1,
                                            exchangeRate: 1.0
                                        }));
                                        handleCustomerAccountOrderProduct(e.value.supplierOrderProductId);
                                    }}>
                                </Select>
                                :
                                <Select
                                    placeholder="-- Select Supplier Product --"
                                    isSearchable={true}
                                    options={supplierOrderProducts.map(c => ({
                                        value: c,
                                        label: ('#' + c.supplierOrders.invoiceNumber + ' - ' + c.productName + ', ' + c.productSizeName + ', ' + c.productTypeName + ' (' + c.supplierOrderProducts.pendingFcl + ' FCL / ' + c.supplierOrderProducts.pendingQuantity + ' ' + c.productUnit1 + ') (' + c.currency.currencySuffix + ' ' + c.supplierOrderProducts.unitPrice + ')')
                                    }))}
                                    valuekey
                                    // value={{label: orderProduct.productDescription, value: orderProduct.supplierOrderProductId}}
                                    onChange={(e) => {
                                        setOrderProduct((prevState) => ({
                                            ...prevState,
                                            supplierOrderProductId: e.value.supplierOrderProducts.supplierOrderProductId,
                                            productDescription: e.value.supplierOrderProducts.productName,
                                            pendingQuantity: e.value.supplierOrderProducts.pendingQuantity,
                                            pendingFcl: e.value.supplierOrderProducts.pendingFcl,
                                            currencySuffix: e.value.currency.currencySuffix,
                                            currencyId: e.value.currency.currencyId,
                                            productUnitName: e.value.productUnit1,
                                            exchangeRate: 1.0
                                        }));
                                        setSameCurrency(e.value.currency.currencyId == currency.currencyId);
                                        handleCustomerAccountOrderProduct(e.value.supplierOrderProducts.supplierOrderProductId);
                                    }}>
                                </Select>
                            }
                            {/* <label>
                                {orderProduct.supplierOrderProductId}
                            </label> */}

                            {(sameCurrency == false) ? (
                                <>
                                    <div className="row mb-3 mt-3">
                                        <div className="col-6">
                                            <label>{orderProduct.currencySuffix} to {currency.currencySuffix} Exchange Rate <i class="fas fa-info-circle" id="tooltip1" />
                                                <UncontrolledTooltip placement="top" target="tooltip1">
                                                    Since this client account currency is different than the supplier invoice currency, the exchange rate is required.
                                                </UncontrolledTooltip></label>
                                            <input type="number" className="form-control" required
                                                value={orderProduct.exchangeRate}
                                                step={0.1}
                                                onChange={(e) => {
                                                    setOrderProduct((prevState) => ({
                                                        ...prevState,
                                                        exchangeRate: e.target.value
                                                    }));
                                                }}>
                                            </input>
                                            {/* <span class="bg-warning badge badge-warning"> */}
                                            {/* <div>
                                                <i class="typcn typcn-info bg-warning badge badge-warning">Since the forwarder account currency is different than the supplier<br/>invoice currency, the exchange rate is required in order to calculate the profit.</i>
                                            </div> */}
                                            {/* </span> */}
                                        </div>
                                        <div className="col-6">
                                            <label>Number of Containers</label>
                                            <input type="number" className="form-control"
                                                value={orderProduct.fcl}
                                                min={0}
                                                max={orderProduct.pendingFcl}
                                                onChange={(e) => {
                                                    setOrderProduct((prevState) => ({
                                                        ...prevState,
                                                        fcl: e.target.value
                                                    }));
                                                }}></input>
                                        </div>
                                    </div>
                                </>
                            ) :

                                <div className="row mb-3 mt-3">
                                    <div className="col-6">
                                        <label>Number of Containers</label>
                                        <input type="number" className="form-control"
                                            value={orderProduct.fcl}
                                            onChange={(e) => {
                                                setOrderProduct((prevState) => ({
                                                    ...prevState,
                                                    fcl: e.target.value
                                                }));
                                            }}></input>
                                    </div>
                                </div>
                            }

                            <div className="row ">
                                <div className="col-6">
                                    <label>Quantity ({orderProduct.productUnitName})</label>
                                    <input type="number" className="form-control"
                                        value={orderProduct.productQuantity.toLocaleString()}
                                        onChange={(e) => {
                                            setOrderProduct((prevState) => ({
                                                ...prevState,
                                                productQuantity: e.target.value
                                            }));
                                            // setCustomerAccountOrderProduct((prevState) => ({
                                            //     ...prevState,
                                            //     quantity: e.target.value,
                                            // }));
                                        }}></input>
                                </div>
                                <div className="col-6">
                                    <label>Unit Price</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-text">{currency.currencySuffix}</div>
                                        <input type="number" className="form-control"
                                            value={orderProduct.unitPrice}
                                            onChange={(e) => {
                                                setOrderProduct((prevState) => ({
                                                    ...prevState,
                                                    unitPrice: e.target.value
                                                }));
                                            }}></input>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseAddOrderProduct}>
                        Close
                    </button>
                    <button className="btn btn-primary" disabled={submitDisabled} onClick={(e) => {
                        if (editingProductId > 0) {
                            // handleCustomerAccountOrderProduct(orderProduct.supplierOrderProductId);
                            EditOrderProduct(e)
                        }
                        else {
                            AddOrderProduct(e)
                        }
                    }}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCustomerCommercialInvoice} onHide={handleCloseAddCustomerCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label>Invoice Number</label>
                            <input className="form-control" placeholder="Invoice Number" onChange={(e) => setCustomerCommercialInvoice((prevState) => ({
                                ...prevState, customerCommercialInvoice: e.target.value
                            }))}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Supplier Commercial Invoice</label>
                            <Select
                                placeholder="-- Select Commercial Invoice --"
                                isSearchable={true}
                                options={supplierCommercialInvoices.map(x => ({
                                    value: x,
                                    label: x.invoiceNumber
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setCustomerCommercialInvoice((prevState) => ({
                                        ...prevState,
                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId,
                                        customerCommercialInvoiceDate: e.value.date.split('T')[0]
                                    }))
                                }}></Select>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Date</label>
                            <input className="form-control" type="date" value={customerCommercialInvoice.customerCommercialInvoiceDate} onChange={(e) => setCustomerCommercialInvoice((prevState) => ({
                                ...prevState, customerCommercialInvoiceDate: e.target.value
                            }))}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Notes</label>
                            <textarea className="form-control" placeholder="Notes"
                                onChange={(e) => setCustomerCommercialInvoice((prevState) => ({
                                    ...prevState, notes: e.target.value
                                }))}></textarea>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddCustomerCommercialInvoice}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={submitDisabled} onClick={(e) => AddCustomerCommercialInvoice(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showEditCommercialInvoice} onHide={handleCloseEditCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label>Invoice Number</label>
                            <input className="form-control"
                                placeholder="Invoice Number"
                                value={editingCommercialInvoice.customerCommercialInvoice}
                                onChange={(e) => setEditingCommercialInvoice((prevState) => ({
                                    ...prevState, customerCommercialInvoice: e.target.value
                                }))}></input>
                        </div>
                        {/* <div className="col-12 mb-3">
                            <label>Supplier Commercial Invoice</label>
                            <Select
                                placeholder="-- Select Commercial Invoice --"
                                isSearchable={true}
                                options={commercialInvoices.map(x => ({
                                    value: x,
                                    label: x.invoiceNumber
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingCommercialInvoice((prevState) => ({
                                        ...prevState, 
                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId,
                                        customerCommercialInvoiceDate: e.value.date.split('T')[0]
                                    }))
                                }}></Select>
                        </div> */}
                        <div className="col-12 mb-3">
                            <label>Date</label>
                            <input className="form-control" type="date"
                                value={editingCommercialInvoice.customerCommercialInvoiceDate}
                                onChange={(e) => setEditingCommercialInvoice((prevState) => ({
                                    ...prevState, customerCommercialInvoiceDate: e.target.value
                                }))}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Notes</label>
                            <textarea className="form-control"
                                placeholder="Notes"
                                value={editingCommercialInvoice.notes}
                                onChange={(e) => setEditingCommercialInvoice((prevState) => ({
                                    ...prevState, notes: e.target.value
                                }))}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditCommercialInvoice}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => EditCustomerCommercialInvoice(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDeleteCommercialInvoice} onHide={handleCloseDeleteCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete commercial invoice #{deletingCommercialInvoice}?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCommercialInvoice}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => submitDeleteInvoiceForm(e)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
}
export default ViewCustomerOrder