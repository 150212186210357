import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';
import { getDecodedToken } from '../../../utils/utils';

const ManageEmployee = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [user, setUser] = useState({
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Email: "",
        Mobile: 0,
        Address: "",
        Extension: "",
        DepartmentId: 0,
        CreatedByUserId: localStorage.getItem("uTsReD"),
        PositionId: 0,
        DefaultScreenId: 0,
        profile: null,
    })
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file selected
        setUser({ ...user, profile: file });
    };
    const [positionName, setPositionName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [screens, setScreens] = useState([]);
    
    const GetScreens = () => {
        var companyId = localStorage.getItem("C0m8N!");
        var config = {
            method: 'get',
            url: "Settings/GetScreens",
            headers: {},
            params: { companyId }
        };
        // companyId: localStorage.getItem("C0m8N!")
        axiosInstance(config)
            .then(function (response) {
                setScreens(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const GetEmployee = (userId) => {
        var config = {
            method: 'get',
            url: "User/GetUser/" + userId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setUser((prevState) => ({ ...prevState, FirstName: response.data.firstName }))
                setUser((prevState) => ({ ...prevState, MiddleName: response.data.middleName }))
                setUser((prevState) => ({ ...prevState, LastName: response.data.lastName }))
                setUser((prevState) => ({ ...prevState, Email: response.data.email }))
                setUser((prevState) => ({ ...prevState, Mobile: response.data.mobile }))
                setUser((prevState) => ({ ...prevState, Address: response.data.address }))
                setUser((prevState) => ({ ...prevState, DepartmentId: response.data.departmentId }))
                setUser((prevState) => ({ ...prevState, PositionId: response.data.positionId }))
                setUser((prevState) => ({ ...prevState, DefaultScreenId: response.data.defaultScreenId }))
                setUser((prevState) => ({ ...prevState, profile: response.data.profile }))
                document.title = response.data.firstName + " " + response.data.lastName;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [departments, setDepartments] = useState([]);
    const GetDepartments = (companyId) => {
        var config = {
            method: 'get',
            url: 'Department/GetDepartmentsByCompany/' + companyId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                setDepartments(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [positions, setPositions] = useState([]);
    const GetPositions = (companyId) => {
        var config = {
            method: 'get',
            url: 'Position/GetPositionsByCompany/' + companyId,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setPositions(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const formData = new FormData();
            formData.append('firstName', user.FirstName);
            formData.append('middleName', user.MiddleName);
            formData.append('lastName', user.LastName);
            formData.append('email', user.Email);
            formData.append('mobile', user.Mobile);
            formData.append('address', user.Address);
            formData.append('extension', user.Extension);
            formData.append('departmentId', user.DepartmentId);
            formData.append('createdByUserId', user.CreatedByUserId);
            formData.append('positionId', user.PositionId);
            formData.append('defaultScreenId', user.DefaultScreenId);
            formData.append('companyId', localStorage.getItem("C0m8N!"));
            formData.append('profile', user.profile);

            if (pId.EmployeeId == "0") { //Add a new user
                var config = {
                    method: 'post',
                    url: "User/RegisterUser" ,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: formData
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Employee Added Successfully");
                            navigate("/HR/Employee/Permission/" + response.data);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error(error.response.data);
                    });
            }
            else { 
                var config = {
                    method: 'post',
                    url: "User/EditUser/" + pId.EmployeeId,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: formData
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("User Updated Successfully")
                            navigate(-1)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        // if (positionName == "" && companyId == "0") {
        //     toast.error("Enter all required fields");
        //     return false;
        // }
        // else if (positionName == "") {
        //     toast.error("Enter Position Name");
        //     return false;
        // }
        // else if (companyId == "0") {
        //     toast.error("Select Company");
        //     return false;
        // }
        return true;
    }

    const handleCancel = () => {
        navigate(-1);
    };
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        var companyId = localStorage.getItem("C0m8N!")
        GetPositions(companyId);
        GetDepartments(companyId);
        GetScreens();

        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);

        if (pId.EmployeeId != "0") {
            setPageType("Edit");
            GetEmployee(pId.EmployeeId)
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Employees</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/HR/Employees">Employees</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Employee</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <button onClick={handleCancel} className="btn btn-secondary mr-3">
                                                Cancel
                                            </button>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-2">
                                            <label>First Name</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" id="txtPositionName" required
                                                value={user.FirstName}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        FirstName: e.target.value
                                                    }))} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Middle Name</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" id="txtPositionName" required
                                                value={user.MiddleName}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        MiddleName: e.target.value
                                                    }))} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Last Name</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" id="txtPositionName" required
                                                value={user.LastName}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        LastName: e.target.value
                                                    }))} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Department</label>
                                        </div>
                                        <div className="col-md-10">
                                            <select className="form-control"
                                                value={user.DepartmentId}
                                                disabled={!permissions.editPermission}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        DepartmentId: e.target.value
                                                    }))}>
                                                <option>-- Select Department -- </option>
                                                {departments.map((d) => {
                                                    return (
                                                        <option value={d.departmentId}>{d.departmentName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Position</label>
                                        </div>
                                        <div className="col-md-10">
                                            <select className="form-control"
                                                value={user.PositionId}
                                                disabled={!permissions.editPermission}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        PositionId: e.target.value
                                                    }))}>
                                                <option>-- Select Position -- </option>
                                                {positions.map((p) => {
                                                    return (
                                                        <option value={p.userPositionId}>{p.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Default Screen</label>
                                        </div>
                                        <div className="col-md-10">
                                            <select className="form-control"
                                                value={user.DefaultScreenId}
                                                disabled={!permissions.editPermission}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        DefaultScreenId: e.target.value
                                                    }))}>
                                                <option>-- Select Screen -- </option>
                                                {screens.map((p) => {
                                                    return (
                                                        <option value={p.screenId}>{p.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Mobile</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" placeholder="Enter Phone Number"
                                                value={user.Mobile}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        Mobile: e.target.value
                                                    }))}></input>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Email</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" placeholder="Enter Email Here"
                                                value={user.Email}
                                                disabled={!permissions.editPermission}
                                                onChange={(e) =>
                                                    setUser((prevState) =>
                                                    ({
                                                        ...prevState,
                                                        Email: e.target.value
                                                    }))}></input>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <label>Picture</label>
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="file"
                                                required
                                                id="filelogo"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ManageEmployee;