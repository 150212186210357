import React from 'react';
import { Dropdown } from 'react-bootstrap';

const ExportDropdown = ({ permissions, setExportAll, DownloadCSV, setPopupVisible }) => {
	return (
		<Dropdown>
			<Dropdown.Toggle variant="warning" id="dropdown-basic">
				<i className="mdi mdi-export" style={{ marginRight: '8px' }}></i>
				Export
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{permissions.exportAllShipments && (
					<Dropdown.Item
						onClick={() => setExportAll(true)}
					>
						All Active Shipments
					</Dropdown.Item>
				)}
				{permissions.addShipment && (
					<Dropdown.Item
						onClick={() => DownloadCSV()}
					>
						My Active Shipments
					</Dropdown.Item>
				)}
				{permissions.addShipment && (
					<Dropdown.Item
						onClick={() => setPopupVisible(true)}
					>
						Client Shipments
					</Dropdown.Item>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default ExportDropdown;
