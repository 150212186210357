import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../Variables/Global";
import axiosInstance from '../../api/axiosConfig';


// import images
// import mercatorLogo from "../../../public/images/meractor-logo.png"


const Login = (props) => {
    /**
     * Variables
     */
    const axios = require('axios').default;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    /**
     * Submit function to login and move to private routes
     * @param {*} e 
     */
    const Submit = async (e) => {
        e.preventDefault();
        if (IsValidForm) {
            CreateToken(email, password);
        }
    }

    const CreateToken = (_email, _password) => {
        const data = { email: _email, password: _password.replace("/\//", "/\\//") };
        var config = {
            method: 'post',
            url: 'token',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: data
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200 || response.status === 203 ) {
                    if (response.data.changePassword == true) {
                        navigate("/setnewpassword/" + response.data.userId);
                    } else {
                        localStorage.setItem("token", response.data);
                        LoginUser(email, password);
                    }
                }
                else {
                    toast.error("Wrong email or password!")
                }
            })
            .catch(function (error) {
                // toast.error(error)
                toast.error(error.response.data)
            });
    }
    /**
     * LoginUser function will call API to get response.
     * Code 200: Success
     * Code 403: Wrong Email or Passowrd
     * Code 500: Something Wrong Occured in API
     * @param {*} _email 
     * @param {*} _password 
     */
    const LoginUser = (_email, _password) => {
        const data = { email: _email, password: _password.replace("/\//", "/\\//") };
        var config = {
            method: 'post',
            url: 'User/LoginUser',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: data
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    if(response.data.changePassword == true){
                        navigate("/setnewpassword/" + response.data.userId);
                    }else{
                        toast.success("Login Successful")
                        props.setIsLogged(true);
                        localStorage.setItem("uTsReD", response.data.userId)
                        localStorage.setItem("Def@ult$creeN", response.data.defaultScreenId)
                        sessionStorage.setItem('screenReloded', 'false');
                        sessionStorage.setItem('sessionExpired', 'false');
                        navigate("/");
                    }
                }
                else {
                    toast.error("Wrong email or password!")
                }
            })
            .catch(function (error) {
               // toast.error(error)
                toast.error(error.response.data)
            });
    }



    /**
     * 
     * @returns false in case email or password are empty
     * @returns true in case email and password exist
     */
    const IsValidForm = () => {
        if (email === null && password === null) {
            toast.error("Email & Password Required");
            return false;
        }
        else if (email === null) {
            toast.error("Email Required");
            return false;
        }
        else if (password === null) {
            toast.error("Password Required");
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden">
                                <div className="card-body pt-0">
                                    <h3 className="text-center">
                                        <img className="mt-5" src="/images/meractor-logo.png" alt="" height="80" />
                                    </h3>
                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>
                                        <p className="text-muted text-center">Sign in to continue to Mercator.</p>
                                        <form className="form-horizontal mt-4" action="index.html">
                                            <div className="mb-3">
                                                <label htmlFor="username">Email</label>
                                                <input type="text" className="form-control" id="username" placeholder="Enter email" required onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userpassword">Password</label>
                                                <input type="password" className="form-control" id="userpassword" placeholder="Enter password" required
                                                    onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                            <div className="mb-3 row mt-4">
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="customControlInline" />
                                                        <label className="form-check-label" htmlFor="customControlInline">Remember me
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={(e) => Submit(e)}>Log In</button>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0 row">
                                                <div className="col-12 mt-4">
                                                    <Link to={"/ForgetPassword"} className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                            Copyrights © {new Date().getFullYear()} Mercator. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Login;