import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../Variables/Global"
import axiosInstance from '../../../../api/axiosConfig';

const ManageAddresses = (props) => {
     
    const pId = useParams();
    const [pageType, setPageType] = useState("Add")

    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [street, setStreet] = useState("")
    const [building, setBuilding] = useState("")
    const [companyId, setCompanyId] = useState(0)
    const [floor, setFloor] = useState("")
    const [companies, setCompanies] = useState([]);

    const GetCompanies = () => {
        var config = {
            method: 'get',
            url: "Company/GetCompany",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const GetCustomerAddress = (customerAddressId) => {
        var config = {
            method: 'get',
            url: "CustomerAddress/GetCustomerAddress/" + customerAddressId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCountry(response.data.country)
                setCity(response.data.city)
                setStreet(response.data.street)
                setBuilding(response.data.building)
                setFloor(response.data.floor)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { country: country, city: city, street: street, building: building, floor: floor, companyId: companyId, userId: localStorage.getItem("uTsReD")  }
            if (props.customerAddressId == "0") { //Add Customer Address
                var config = {
                    method: 'post',
                    url: "CustomerAddress/AddCustomerAddress/" + pId.CustomerId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Address Added Successfully")
                            props.setShowCustomerList(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Customer Address
                var config = {
                    method: 'put',
                    url: "CustomerAddress/EditCustomerAddress/" + pId.AddressId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Address Updated Successfully")
                            props.setShowCustomerList(false)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }

    }
    const IsValid = () => {
        if (country == "" && city == "" && street == "" && building == "" && floor == "" && companyId == "0") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (country == "") {
            toast.error("Enter Country");
            return false;
        }
        else if (city == "") {
            toast.error("Enter City");
            return false;
        }
        else if (building == "") {
            toast.error("Enter Building");
            return false;
        }
        else if (street == "") {
            toast.error("Enter Street");
            return false;
        }
        else if (floor == "") {
            toast.error("Enter Floor");
            return false;
        }
        else if (companyId == "0") {
            toast.error("Select Company");
            return false;
        }
        return true;
    }
    useEffect(() => {
        GetCompanies();
        if (pId.AddressId != 0) {
            GetCustomerAddress(pId.AddressId)
            setPageType("Edit");
        }
    }, [])
    return (
        <>

            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    <li className="breadcrumb-item active">Client</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Client Address</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link className="btn btn-secondary mr-3" to={"/Accounting/Customer/" + pId.CustomerId + "/Addresses"}>Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Country</label>
                                            <input className="form-control" type="text" value={country} onChange={(e) => setCountry(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">City</label>
                                            <input className="form-control" type="text" value={city} onChange={(e) => setCity(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Street</label>
                                            <input className="form-control" type="text" value={street} onChange={(e) => setStreet(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Building</label>
                                            <input className="form-control" type="text" value={building} onChange={(e) => setBuilding(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Floor</label>
                                            <input className="form-control" type="text" value={floor} onChange={(e) => setFloor(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Company</label>
                                            <select className="form-control" value={companyId} onChange={(e) => setCompanyId(e.target.value)} required>
                                                <option value="0" key="0">-- Select Company Name --</option>
                                                {companies.length > 0 && (
                                                    companies.map((c, index) => {
                                                        return (<>
                                                            <option value={c.companyId} key={index}>{c.companyName}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default ManageAddresses