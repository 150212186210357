import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Input, Form } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../Variables/Global";
import axiosInstance from '../../api/axiosConfig';

const LockScreen = (props) => {
    document.title = "Lockscreen | Session Expired";
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const axios = require('axios').default;

    const Submit = async (e) => {
        e.preventDefault();
        if (IsValidForm) {
            CreateToken(email, password);
        }
    }

    const CreateToken = (_email, _password) => {
        const data = { email: _email, password: _password.replace("/\//", "/\\//") };
        var config = {
            method: 'post',
            url: 'token',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: data
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.changePassword == true) {
                        navigate("/setnewpassword/" + response.data.userId);
                    } else {
                        localStorage.setItem("token", response.data);
                        LoginUser(email, password);
                    }
                }
                else {
                    toast.error("Wrong email or password!")
                }
            })
            .catch(function (error) {
                // toast.error(error)
                toast.error("Wrong email or password!")
            });
    }

    const IsValidForm = () => {
        if (email === null && password === null) {
            toast.error("Email & Password Required");
            return false;
        }
        else if (email === null) {
            toast.error("Email Required");
            return false;
        }
        else if (password === null) {
            toast.error("Password Required");
            return false;
        }
        return true;
    }

    const LoginUser = (_email, _password) => {
        const data = { email: _email, password: _password.replace("/\//", "/\\//") };
        var config = {
            method: 'post',
            url: 'User/LoginUser',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: data
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.changePassword == true) {
                        navigate("/setnewpassword/" + response.data.userId);
                    } else {
                        toast.success("Session Unlocked")
                        props.setIsLogged(true);
                        localStorage.setItem("uTsReD", response.data.userId)
                        localStorage.setItem("Def@ult$creeN", response.data.defaultScreenId)
                        sessionStorage.setItem('screenReloded', 'false');
                        sessionStorage.setItem('sessionExpired', 'false');
                        navigate("/");
                    }
                }
                else {
                    toast.error("Wrong email or password!")
                }
            })
            .catch(function (error) {
                // toast.error(error)
                toast.error("Wrong email or password!")
            });
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="6" xl="5">
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <h3 className="text-center mt-5 mb-4">
                                        <Link to="/" className="d-block auth-logo">
                                            <img src="/images/logo-light.png" alt="" height="30" className="auth-logo-light" />
                                        </Link>
                                    </h3>
                                    <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center">Session Expired</h4>
                                        <p className="text-muted text-center">Hello, please enter your email and password!</p>
                                        <Form className="form-horizontal mt-4">

                                            <div className="user-thumb text-center mb-4">
                                                {/*<img src={user} className="rounded-circle avatar-md img-thumbnail" alt="thumbnail" />*/}
                                                <h6 className="font-size-16 mt-3">Mercator</h6>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="username">Email</label>
                                                <input type="text" className="form-control" id="username" placeholder="Enter email" required onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userpassword">Password</label>
                                                <input type="password" className="form-control" id="userpassword" placeholder="Enter password" required
                                                    onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                            <div className="col-6 text-end">
                                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={(e) => Submit(e)}>Unlock</button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            {/*<div className="mt-5 text-center">*/}
                            {/*    <p>Not you ? return <Link to="/pages-login" className=" text-primary"> Sign In </Link> </p>*/}
                            {/*    <p>� 2021 Lexa. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>*/}
                            {/*</div>*/}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>


    )
}

export default LockScreen
