import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "../../../../Classes/Util";
import { Global } from "../../../../Variables/Global";
import ListCommercialDiscount from "./ListCommercialDiscount";
import ListCommercialProduct from "./ListCommercialProduct";
import axiosInstance from '../../../../api/axiosConfig';

const ManageCommercialInvoice = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [showAddCommercialInvoicePayment, setShowAddCommercialInvoicePayment] = useState(false);
    const handleCloseAddCommercialInvoicePayment = () => setShowAddCommercialInvoicePayment(false);
    const handleShowAddCommercialInvoicePayment = () => setShowAddCommercialInvoicePayment(true);

    const [commercialProducts, setCommercialProducts] = useState([]);

    const [supplierCommercialInvoice, setSupplierCommercialInvoice] = useState({
        invoiceNumber: "",
        date: ""
    })

    const [account, setAccount] = useState({
        supplierAccountId: 0,
        supplierAccount1: "",
        currencyId: 0
    })

    const [order, setOrder] = useState({
        supplierOrderId: 0,
        supplierOrder1: "",
        invoiceNumber: "",
        date: ""
    })

    const [commercialInvoicePayment, setCommercialInvoicePayment] = useState({
        commercialInvoiceId: 0,
        paymentValue: 0,
        paymentDate: formatDate(new Date()),
        notes: "",
        commercialInvoicePaymentBankId: 0,
        commercialInvoicePaymentBankAccountId: 0,
        bankAmount: 0,
        commercialInvoiceExchangeRate: 1,
        userId: localStorage.getItem("uTsReD"),
        invoiceNumber: "",
        commercialInvoiceNumber: "",
        supplierOrderId: 0,
        supplierId: 0,
        supplierAccountId: 0,
        supplierName: "",
        supplierAccountName: "",
        companyId: localStorage.getItem("C0m8N!")
    })

    const [commercialInvoicePayments, setCommercialInvoicePayments] = useState();

    const [supplierName, setSupplierName] = useState("");
    // const [companyId, setCompanyId] = useState(0);

    const GetSupplier = (supplierId) => {
        var config = {
            method: 'get',
            url: "Supplier/GetSuppliers/" + supplierId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierName(response.data.supplierName)
                setCommercialInvoicePayment((prevState) => ({
                    ...prevState,
                    supplierName: response.data.supplierName,
                    supplierId: response.data.supplierId
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetSupplierAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "SupplierAccount/GetSupplierAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setAccount(response.data);
                setCommercialInvoicePayment((prevState) => ({
                    ...prevState,
                    supplierAccountId: response.data.supplierAccountId,
                    supplierAccountName: response.data.supplierAccount1
                }));
                GetCurrency(response.data.currencyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [currency, setCurrency] = useState("")
    const GetCurrency = (currencyId) => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrency(response.data);
                GetBanks(currencyId);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetSupplierOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "SupplierOrder/GetSupplierOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                console.log("supplierOrder", response.data)
                setOrder(response.data)
                // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                //     ...prevState,
                //     invoiceNumber: response.data.invoiceNumber
                // }));
                setCommercialInvoicePayment((prevState) => ({
                    ...prevState,
                    invoiceNumber: response.data.invoiceNumber,
                    supplierOrderId: response.data.orderId
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const columns = [
        {
            name: '#',
            selector: row => row.commercialInvoiceId,
            width: "60px"
        },
        {
            name: 'Payment Value',
            width: "150px",
            selector: row => row.paymentValue.toLocaleString() + " " + currency.currencySuffix,

        },
        {
            name: 'Date',
            width: "150px",
            selector: row => row.invoiceDate.split("T")[0],
        },
        {
            name: "Notes",
            selector: row => row.notes
        },

        // {
        //     name: 'Options',
        //     width: "170px",
        //     selector: row => {
        //         return (
        //             <>
        //                 <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + pId.OrderId + "/Payment/" + row.commercialInvoiceId} className="btn btn-outline-success mr-3" name="Paymount"><GiReceiveMoney /></Link>
        //                 <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + pId.OrderId + "/Discount/" + row.commercialInvoiceId} className="btn btn-outline-danger mr-3" name="Discount"><GiPayMoney/></Link>
        //             </>
        //         )
        //     },
        // },
    ];

    const GetCommercialInvoicePayments = (invoiceId) => {
        var config = {
            method: 'get',
            url: "CommercialInvoicePayment/GetCommercialInvoicePayment/" + invoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCommercialInvoicePayments(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCommercialInvoice = (invoiceId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommercialInvoice/" + invoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setSupplierCommercialInvoice(response.data)
                // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                //     ...prevState,
                //     commercialInvoice: response.data.invoiceNumber,
                //     commercialInvoiceId: response.data.InvoiceId
                // }));
                setCommercialInvoicePayment((prevState) => ({
                    ...prevState,
                    commercialInvoiceNumber: response.data.invoiceNumber,
                    // commercialInvoiceId: response.data.InvoiceId
                }));
                
                document.title = response.data.invoiceNumber;
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const AddCommercialInvoicePayment = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "CommercialInvoicePayment/AddCommercialInvoicePayment",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: commercialInvoicePayment
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully")
                    setShowAddCommercialInvoicePayment(false)
                    GetCommercialInvoicePayments(pId.InvoiceId);
                    // AddSupplierAccountAdvancedPayment();
                    GetSupplierCommericalInvoiceTotal(pId.InvoiceId);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }
    const [orderTotal, setOrderTotal] = useState({
        otherCharges: "",
        totalAmount: "",
        productsTotal: "",
        productTotalPayment: "",
        productTotalDiscount: "",
        remainingTotal: "",
        advancedPayment: "",
        advancedDiscount: ""
    })
    const GetSupplierCommericalInvoiceTotal = (invoiceId) => {
        var config = {
            method: 'get',
            url: "SupplierCommercialInvoice/GetSupplierCommerialOrderTotal/" + invoiceId + "/Order/" + pId.OrderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setOrderTotal(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [banks, setBanks] = useState([])
    const GetBanks = () => {
        var config = {
            method: 'get',
            url: "Bank/GetCompanyBanks/" + localStorage.getItem("C0m8N!"),
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setBanks(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [bankAccounts, setBankAccounts] = useState([])
    const GetBankAccounts = (bankId) => {
        // var axios = require('axios');

        var config = {
            method: 'get',
            url: 'BankAccount/GetBankAccounts/' + bankId,
            headers: {}
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode == 200) {
                    setBankAccounts(response.data.data)
                };
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const [sameCurrency, setSameCurrency] = useState(true)
    const CheckCurrency = (accountId) => {
        var config = {
            method: 'get',
            url: 'BankAccount/GetBankAccountCurrencyId/' + accountId,
            headers: {}
        };
        axiosInstance(config)
            .then(function (response) {
                if (response.data.statusCode == 200) {
                    setSameCurrency(response.data.data.currencyId == account.currencyId)
                    if (response.data.data.currencyId == account.currencyId) {
                        setCommercialInvoicePayment((prevState) => ({ ...prevState, 
                            commercialInvoiceExchangeRate: 1,
                            bankAmount: commercialInvoicePayment.paymentValue
                            }))
                    }
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }
   
    const reloadBankAmountCommercial = (originalPayment) => {
        var x = originalPayment;
        var y = commercialInvoicePayment.commercialInvoiceExchangeRate;
        var z = x * y;
        console.log(z)
        setCommercialInvoicePayment((prevState) => ({ ...prevState, bankAmount: z }))
    }

    const CalculateExchangeRateCommercial = (bankAmount) => {
        var x = bankAmount;
        var y = commercialInvoicePayment.paymentValue;
        var z = x / y;
        setCommercialInvoicePayment((prevState) => ({ ...prevState, commercialInvoiceExchangeRate: z }))
    }

    const CalculateBankAmountCommercial = (exchangeRate) => {
        var x = exchangeRate;
        var y = commercialInvoicePayment.paymentValue;
        var z = x * y;
        setCommercialInvoicePayment((prevState) => ({ ...prevState, bankAmount: z }))
    }

    useEffect(() => {
        if (pId.InvoiceId != "0") {
            setCommercialInvoicePayment((prevState) => ({
                ...prevState,
                commercialInvoiceId: pId.InvoiceId
            }))
            GetSupplierCommericalInvoiceTotal(pId.InvoiceId)
            GetSupplier(pId.SupplierId)
            GetSupplierAccount(pId.AccountId)
            GetSupplierOrder(pId.OrderId)
            GetCommercialInvoicePayments(pId.InvoiceId)
            GetCommercialInvoice(pId.InvoiceId)
        }
        else {
            navigate("/Accounting/Supplier/View/" + pId.SupplierId)
        }

    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4>Suppliers</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Supplier">Suppliers</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId}>{account.supplierAccount1}</Link>
                                    </li>
                                    <li className="breadcrumb-item"><a href="#">Order</a></li>
                                    <li className="breadcrumb-item"><a href="#">#{order.invoiceNumber}</a></li>
                                    <li className="breadcrumb-item"><Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + pId.OrderId + "/CommercialInvoices/"}>Invoice</Link></li>
                                    <li className="breadcrumb-item"><a href="#">{supplierCommercialInvoice.invoiceNumber}</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">Commercial Invoice: #{supplierCommercialInvoice.invoiceNumber} - Date: {order != null ? supplierCommercialInvoice.date.split("T")[0] : null}</h4>
                                            {/* {} */}
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to={"/Accounting/Supplier/" + pId.SupplierId + "/Account/" + pId.AccountId + "/Order/" + pId.OrderId + "/CommercialInvoices/"} className="btn btn-secondary mr-3">Cancel</Link>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="mb-3 row">
                                        <div className="col-12">
                                            <ListCommercialProduct currencySuffix={currency.currencySuffix} setCommercialProducts={setCommercialProducts} commercialProducts={commercialProducts} GetSupplierCommericalInvoiceTotal={GetSupplierCommericalInvoiceTotal} />
                                        </div>
                                    </div>
                                    {(commercialProducts.length > 0 || (commercialInvoicePayments != null && commercialInvoicePayments.length > 0)) && (
                                        <>
                                            <div className="row mt-5">
                                                <div className="col-12 col-md-6">
                                                    <h5>List Commercial Payments</h5>
                                                </div>
                                                <div className="col-12 col-md-6 text-right">
                                                    <button onClick={handleShowAddCommercialInvoicePayment} className="btn btn-primary mr-3">Add Payment</button>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <DataTable
                                                        columns={columns}
                                                        data={commercialInvoicePayments}
                                                    />
                                                </div>
                                            </div>
                                            <Modal show={showAddCommercialInvoicePayment} onHide={handleCloseAddCommercialInvoicePayment}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Add Payment for #{supplierCommercialInvoice.invoiceNumber}
                                                    <h5><small>Remaining Total: {orderTotal.remainingTotal.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>

                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row mb-3">
                                                                <div className="col-6">
                                                                    <label>Amount</label>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text">{currency.currencySuffix}</span>
                                                                        <input className="form-control" type="number" step="0.01"
                                                                            onChange={(e) => {
                                                                                setCommercialInvoicePayment((prevState) => ({
                                                                                    ...prevState,
                                                                                    paymentValue: e.target.value
                                                                                }));
                                                                                reloadBankAmountCommercial(e.target.value)
                                                                            }}></input>
                                                                     </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <label>Date</label>
                                                                    <input className="form-control" type="date" value={commercialInvoicePayment.paymentDate}
                                                                        onChange={(e) => {
                                                                            setCommercialInvoicePayment((prevState) => ({
                                                                                ...prevState,
                                                                                paymentDate: e.target.value
                                                                            }));
                                                                            // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                                                                            //     ...prevState,
                                                                            //     supplierAdvancedPaymentDate: e.target.value
                                                                            // }));
                                                                        }}></input>
                                                                </div>

                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-12">
                                                                    <label>Bank</label>
                                                                    <select className="form-control"
                                                                        onChange={(e) => {
                                                                            setCommercialInvoicePayment((prevState) => ({
                                                                                ...prevState,
                                                                                commercialInvoicePaymentBankId: e.target.value
                                                                            }));
                                                                            // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                                                                            //     ...prevState,
                                                                            //     supplierAdvancedPaymentBankId: e.target.value
                                                                            // }));
                                                                            GetBankAccounts(e.target.value)
                                                                        }}>
                                                                        <option>-- Select Bank --</option>
                                                                        {banks.map((b) => (
                                                                            <option value={b.bankId}>{b.bankName}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-12">
                                                                    <label>Bank Accounts</label>
                                                                    <select className="form-control"
                                                                        onChange={(e) => {
                                                                            setCommercialInvoicePayment((prevState) => ({
                                                                                ...prevState,
                                                                                commercialInvoicePaymentBankAccountId: e.target.value
                                                                            }));
                                                                            // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                                                                            //     ...prevState,
                                                                            //     supplierAdvancedPaymentBankAccountId: e.target.value
                                                                            // }));
                                                                            CheckCurrency(e.target.value)
                                                                        }}>
                                                                        <option>-- Select Bank Account --</option>
                                                                        {bankAccounts.map((ba) => (
                                                                            <option value={ba.bankAccountId}>{ba.bankAccount1}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {sameCurrency == false && (
                                                                <div className="row mb-3">
                                                                    <div className="col-6">
                                                                        <label>Bank Amount</label>
                                                                        <input className="form-control" type="number"
                                                                            value={commercialInvoicePayment.bankAmount}
                                                                            onChange={(e) => {
                                                                                // setCommercialInvoicePayment((prevState) => ({
                                                                                //     ...prevState,
                                                                                //     bankAmount: e.target.value
                                                                                // }));
                                                                                // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                                                                                //     ...prevState,
                                                                                //     supplierAdvancedBankPayment: e.target.value
                                                                                // }));
                                                                                setCommercialInvoicePayment((prevState) => ({
                                                                                    ...prevState,
                                                                                    bankAmount: e.target.value
                                                                                }));
                                                                                CalculateExchangeRateCommercial(e.target.value)
                                                                            }}></input>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <label>Exchange Rate</label>
                                                                        <input className="form-control" type="number"
                                                                            value={commercialInvoicePayment.commercialInvoiceExchangeRate}
                                                                            onChange={(e) => {
                                                                                setCommercialInvoicePayment((prevState) => ({
                                                                                    ...prevState,
                                                                                    commercialInvoiceExchangeRate: e.target.value
                                                                                }));
                                                                                // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                                                                                //     ...prevState,
                                                                                //     supplierAdvancedPaymentExchangeRate: e.target.value
                                                                                // }));
                                                                                CalculateBankAmountCommercial(e.target.value)
                                                                            }}></input>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <label>Notes</label>
                                                                    <textarea className="form-control"
                                                                        onChange={(e) => {
                                                                            setCommercialInvoicePayment((prevState) => ({
                                                                                ...prevState,
                                                                                notes: e.target.value
                                                                            }));
                                                                            // setSupplierAccountCommercialAdvancedPayment((prevState) => ({
                                                                            //     ...prevState,
                                                                            //     notes: e.target.value
                                                                            // }));
                                                                        }}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button className="btn btn-secondary" onClick={handleCloseAddCommercialInvoicePayment}>
                                                        Close
                                                    </button>
                                                    <button className="btn btn-primary" onClick={(e) => AddCommercialInvoicePayment(e)}>
                                                        Save Changes
                                                    </button>
                                                </Modal.Footer>
                                            </Modal>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <ListCommercialDiscount currencySuffix={currency.currencySuffix} 
                                                                            remainingTotal={orderTotal.remainingTotal}
                                                                            invoiceNumber={supplierCommercialInvoice.invoiceNumber}
                                                                            GetSupplierCommericalInvoiceTotal={GetSupplierCommericalInvoiceTotal} />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {orderTotal != null && (
                                        <div className="col-12 mb-3">
                                            <hr></hr>
                                            <h5>Products price: <small>{orderTotal.productsTotal.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Other charges: <small>{orderTotal.otherCharges.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Total: <small>{orderTotal.totalAmount.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Advanced Payment: <small>{orderTotal.advancedPayment.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Advanced Discounts: <small>{orderTotal.advancedDiscount.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Total Payments: <small>{orderTotal.productTotalPayment.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Total Discounts: <small>{orderTotal.productTotalDiscount.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                            <h5>Remaining Total: <small>{orderTotal.remainingTotal.toLocaleString() + " " + currency.currencySuffix}</small></h5>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>)
}
export default ManageCommercialInvoice