import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { Link, useParams } from "react-router-dom";
import { Global } from "../../../../Variables/Global";
import { FiEdit, FiEye, FiFileText, FiTrash2 } from 'react-icons/fi';
import axiosInstance from '../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../utils/utils';

const ListAccountCustomer = (props) => {

    const pId = useParams();
    const [accounts, setAccounts] = useState([]);
    const [customer, setCustomer] = useState("");
    const [permissions, setPermissions] = useState([])

    const columns = [
        {
            name: '#',
            selector: row => row.customerAccountId,
        },
        {
            name: 'Account Name',
            selector: row => row.customerAccount1,
        },
        {
            name: 'Currency',
            selector: row => row.currencySuffix + " - " + row.currencyName,
        },
        {
            name: 'Options',
            selector: row => {
                return (
                    <>
                        <Link className="btn btn-outline-info mr-3" to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + row.customerAccountId + "/Statment"}><FiFileText /></Link>
                        <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + row.customerAccountId + "/Orders"} className="btn btn-outline-success mr-3">
                            <FiEye />
                        </Link>
                        {permissions.editCustomer === true && (
                            <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + row.customerAccountId} className="btn btn-outline-warning mr-3">
                                <FiEdit />
                            </Link>
                        )}
                        {permissions.deleteCustomer === true && (
                            <button type="button" className="btn btn-outline-danger">
                                <FiTrash2 />
                            </button>
                        )}
                    </>
                )
            },
        },
    ];

    const GetCustomerAccounts = (customerId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccounts/" + customerId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setAccounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCustomer = (customerId) => {
        var config = {
            method: 'get',
            url: "Customer/GetCustomers/" + customerId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomer(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetCustomerAccounts(pId.CustomerId);
        GetCustomer(pId.CustomerId)
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item active">
                                        <Link to={"/Accounting/Customer"}>
                                            Clients</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Customer/View/" + pId.CustomerId}>{customer.customerName}</Link></li>
                                    <li className="breadcrumb-item active">Accounts</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <h4 className="card-title">List Clients Accounts</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                            <Link className="btn btn-secondary mr-3" to={"/Accounting/Customer"}>Cancel</Link>
                                            <Link className="btn btn-primary" to={"/Accounting/Customer/" + pId.CustomerId + "/Account/0"}>Add Clients Account</Link>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={accounts}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ListAccountCustomer