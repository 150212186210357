import React, { useEffect, useState } from "react";
import * as InvoicesService from '../../api/CustomerInvoicesService';
import { useParams } from 'react-router-dom';
import { getDecodedToken } from '../../utils/utils';

const Invoices = () => {
    const { CustomerId, CustomerName } = useParams();
    const [invoices, setInvoices] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredInvoices, setFilteredInvoices] = useState([]);

    // Load invoices for a customer
    const loadInvoices = (customerId) => {
        return InvoicesService.Get({
            customerId
        }).then(data => {
            setInvoices(data);
            setFilteredInvoices(data); // Initialize filteredInvoices with all invoices
        }).catch(err => {
            alert('Failed to load invoices');
        });
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.parse(decodedToken.pJoin);
        setPermissions(pJoin);
        loadInvoices(CustomerId);
        document.title = CustomerName;
    }, [CustomerId, CustomerName]);

    // Handle search input change
    const handleSearchInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        // Filter invoices based on the search query
        const filtered = invoices.filter(invoice =>
            invoice.invoiceNumber.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredInvoices(filtered);
    }

    return (
        <>
            {!permissions || !permissions.viewInvoicing ? (
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>You Have No Permission</h1>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="page-content">
                    <div style={{ padding: '20px' }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div style={{ marginBottom: '20px' }}>
                                        <h4>Clients</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item"><a href="/Invoicing/Customers">Invoicing</a></li>
                                            <li className="breadcrumb-item">{CustomerName}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            placeholder="Invoice Number"
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                            style={{ marginBottom: '10px' }}
                                        />
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                            {filteredInvoices.length === 0 ? (
                                                <div>No invoices found</div>
                                            ) : (
                                                filteredInvoices.map(invoice => (
                                                    <div key={invoice.customerOrderId} style={{
                                                        border: '1px solid #ddd',
                                                        borderRadius: '5px',
                                                        padding: '15px',
                                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                        flex: '1 1 calc(33.333% - 20px)',
                                                        boxSizing: 'border-box',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        backgroundColor: '#fff'
                                                    }}>
                                                        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <a href={`/Invoicing/${CustomerName}/${CustomerId}/PrintInvoice/Proforma/${invoice.customerOrderId}`} style={{ textDecoration: 'none', color: '#f5b225' }}>
                                                                    <b>#{invoice.invoiceNumber} (#{invoice.supplierInvoices })</b>
                                                                </a>
                                                                <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                    {invoice.date ? invoice.date.split('T')[0] : 'No Date'}
                                                                </span>
                                                            </div>
                                                            <div style={{ color: '#a3a3a3', marginTop: '5px' }}>
                                                                {invoice.productsString.split('\n').map((line, index) => (
                                                                    <div key={index}>
                                                                        <b>{line}</b>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <hr />
                                                        <div style={{ flexGrow: 1 }}>
                                                            {invoice.commercialInvoices && invoice.commercialInvoices.length > 0 ? (
                                                                invoice.commercialInvoices.map(c => (
                                                                    <div key={c.customerCommercialInvoiceId} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                                                        {/*<a href={`/Invoicing/${CustomerName}/${CustomerId}/Commercial/${c.customerCommercialInvoiceId}`} style={{ textDecoration: 'none', color: '#007bff' }}>*/}
                                                                        {/*    <b># {c.customerCommercialInvoice1}</b>*/}
                                                                        {/*</a>*/}
                                                                        <a href={`/Invoicing/${CustomerName}/${CustomerId}/PrintInvoice/Commercial/${c.customerCommercialInvoiceId}`} style={{ textDecoration: 'none', color: '#3eb7ba' }}>
                                                                            <b>#{c.customerCommercialInvoice1} ({c.supplierCommercialInvoice}) {c.totalFCLs} FCL</b>
                                                                        </a>
                                                                        <span style={{ textAlign: 'right', color: '#a3a3a3' }}>
                                                                            {c.customerCommercialInvoiceDate ? c.customerCommercialInvoiceDate.split('T')[0] : 'No Date'}
                                                                        </span>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <span style={{ color: '#a3a3a3' }}><i>No commercial invoices available</i></span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </>
    )
}

export default Invoices;
