import axios from "axios";
import React, { useEffect, useState } from "react";
import { Global } from "../../../Variables/Global";
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';
import { Link, useNavigate } from "react-router-dom";

const ListProfits = () => {
    const navigate = useNavigate();
    const [showProfitForCurrency, setShowProfitForCurrency] = useState();
    const [currenciesProfits, setCurrenciesProfits] = useState([]);
    const GetCurrenciesProfits = () => {
        var config = {
            method: 'get',
            url: 'Profits/GetCompanyProfits/' + localStorage.getItem("C0m8N!"),
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrenciesProfits(response.data);
                if(response.data.length > 0){
                    setShowProfitForCurrency(response.data[0].currencyId);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // const [accounts, setAccounts] = useState([]);
    // const GetCustomerAccounts = () => {
    //     var config = {
    //         method: 'get',
    //         url: 'CustomerAccount/GetCustomerAccountsByCompany/' + localStorage.getItem("C0m8N!"),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setAccounts(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    
    const goToExpenses = () => {
        navigate('/Accounting/Expenses'); // Replace with the path of the target screen
    };

    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
      setPermissions(() => x);
       document.title = "Profit";
        GetCurrenciesProfits();
        // GetCustomerAccounts();
    }, [])
    return (
        <>
        {permissions.viewProfits == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-8 mb-3">
                                            <h3>Profits</h3>
                                            {/* <h5>Total profits by currency</h5> */}
                                        </div>
                                        <div className="col-4">

                                        </div>
                                        <hr></hr>
                                    </div>
                                    <div className="row">
                                    <h5>Total profits by currency:</h5> 
                                        {currenciesProfits.length > 0 && (
                                             <>
                                                {/* <ul class="navtab-bg nav-justified nav nav-pills"> */}
                                                <ul class="nav nav-tabs ml-1 mt-1">
                                                    {
                                                        currenciesProfits.map(cp => (
                                                            <>
                                                                <li class="nav-item">
                                                                    <a class={(showProfitForCurrency == cp.currencyId ? "active nav-link" : "nav-link")} href="#" onClick={() => {
                                                                        setShowProfitForCurrency(cp.currencyId);
                                                                    }} style={{cursor: 'pointer;'}}><b>{cp.totalCurrencyProfit.toLocaleString()} {cp.currencySuffix}</b></a>
                                                                </li>
                                                            </>
                                                        ))
                                                    }
                                                </ul>

                                                <div class="tab-content p-3 text-muted">
                                                    {
                                                        currenciesProfits.map(cp => (
                                                            <>
                                                                {/* <h5 className="mt-3 ml-1">Total Profits: {cp.totalCurrencyProfit}</h5> */}
                                                                <div class={(showProfitForCurrency == cp.currencyId ? "tab-pane active" : "tab-pane")}>
                                                                    {
                                                                        (cp.expenses != null && cp.expenses > 0 && <>
                                                                            {/* <span>Expenses: {cp.expenses.toLocaleString()} {cp.currencySuffix}</span> */}
                                                                            <div role="alert" onClick={goToExpenses} style={{ cursor: 'pointer' }}>
                                                                                <div className="row" style={{ display: 'flex', height: '100%' }}>
                                                                                    <div className="col-6" style={{ flex: 1, display: 'flex' }}>
                                                                                        <div className="alert alert-warning fade show mb-0" role="alert" style={{ flex: 1 }}>
                                                                                            <h6 className="alert-heading font-18"><i class="mdi mdi-cash-minus" /> Expenses</h6>
                                                                                            <span className="text-secondary"><b>{cp.expenses.toLocaleString()} {cp.currencySuffix}</b></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>)
                                                                    }
                                                                    {
                                                                        (cp.customers.length > 0 && (
                                                                            <table className='table'>
                                                                                <thead className='table-light'>
                                                                                    <tr>
                                                                                        <th width="33%">Client</th>
                                                                                        <th width="33%">Account</th>
                                                                                        <th width="33%">Profit</th> 
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {cp.customers.map(c => (
                                                                                        <>
                                                                                        {c.accounts.map(x => 
                                                                                            <tr key={x.customerAccountId}>
                                                                                                <td>{c.customerName}</td>
                                                                                                <td><a href={"/Finance/Profits/" + x.customerAccountId} >{x.customerAccountName}</a></td>
                                                                                                <td >{x.totalProfit.toLocaleString()} {x.currencySuffix}</td>
                                                                                            </tr>
                                                                                        )}</>))}
                                                                                </tbody>
                                                                            </table>
                                                                        ))}
                                                                        
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )}
                                       
                                        {/* {customer.length > 0 && (
                                            customer.map((c) => (
                                                <>
                                                    <div className="col-12">

                                                        <h5 className="float-left" style={{ lineHeight: "32px" }}>{c.customerName}</h5>
                                                        <select className="float-left form-control w-250 ml-1">
                                                            <option>-- Select Account --</option>
                                                            {accounts.filter((i) => i.customerId == c.customerId).map((a) => (
                                                                <>
                                                                    <option value={a.customerAccountId}>{a.customerAccount1}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        <table className="table mt-3 float-left table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Invoice #</th>
                                                                    <th>Description</th>
                                                                    <th>Supplier</th>
                                                                    <th>Amount</th>
                                                                    <th>Supplier's Ammount</th>
                                                                    <th>Discount</th>
                                                                    <th>Supplier Discount</th>
                                                                    <th>Freight</th>
                                                                    <th>Profit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <hr></hr>
                                                </>
                                            ))
                                        )} */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)}
        </>
    )
}
export default ListProfits;