export const Global = {
    //Staging
    //API_URL: "https://mercatortraidingstagingapi.azurewebsites.net/api/",
    //Host: "https://mercatorwebstaging.azurewebsites.net/",

    //Live
    API_URL: "https://wmhapi.azurewebsites.net/api/",
    Host: "https://wmh.mercatorapp.com/",

    //Local
    //API_URL: "https://localhost:7044/api/",
    //Host: "http://localhost:3000/",

    //OLD LIVE (AHMAD)
    //API_URL: "/api/",
    //Host: "/",

    VersionNumber: "2.1.6"
}