import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';
import Select from "react-select";

const ManageCurrency = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [currencyName, setCurrencyName] = useState("");
    const [currencySuffix, setCurrencySuffix] = useState("");

    const GetCurrency = (currencyId) =>{
        var config = {
            method: 'get',
            url: "Currency/GetCurrency/" + currencyId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                document.title = response.data.currencyName;
                setCurrencyName(response.data.currencyName)
                setCurrencySuffix(response.data.currencySuffix)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = {
                currencyName: currencyName, currencySuffix: currencySuffix, companyId: localStorage.getItem("C0m8N!")
            }
            if (pId.CurrencyId == "0") { //Add Currency
                var config = {
                    method: 'post',
                    url: "Currency/AddCurrency",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if(response.status === 200){
                            toast.success("Currency Added Successfully")
                            navigate("/Settings/Currency")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Currency
                var config = {
                    method: 'put',
                    url: "Currency/EditCurrency/" + pId.CurrencyId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if(response.status === 200){
                            toast.success("Currency Updated Successfully")
                            navigate("/Settings/Currency")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (currencyName === "" && currencySuffix === "") {
            toast.error("Enter all required fields");
            return false;
        }
        else if (currencyName === "") {
            toast.error("Enter Currency Name");
            return false;
        }
        else if (currencySuffix === "") {
            toast.error("Enter Currency Suffix");
            return false;
        }
        return true;
    }

    const [BankAccounts, setBankAccounts] = useState([])
    const GetBankAccounts = () => {
        var config = {
            method: 'Post',
            url: "Bank/GetBankByCurrencyID/" + pId.CurrencyId,
            headers: {},
        };

        axiosInstance(config)
            .then(function (response) {
                setBankAccounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        if (pId.CurrencyId != "0") {
            setPageType("Edit");
            GetCurrency(pId.CurrencyId)
            GetBankAccounts()
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Currency</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Settings/Currency">Currency</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Currency</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Currency</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Settings/Currency" className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-4">
                                            <label className="col-md-4 col-form-label">Currency Name</label>
                                            <input className="form-control" type="text" value={currencyName} onChange={(e) => setCurrencyName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-md-4 col-form-label">Currency Suffix</label>
                                            <input className="form-control" type="text" value={currencySuffix} onChange={(e) => setCurrencySuffix(e.target.value)} required />
                                        </div> 
                                        {/*<div className="col-md-4">*/}
                                        {/*    <label>Invoice Bank Account</label>*/}
                                        {/*    <Select*/}
                                        {/*        required*/}
                                        {/*        placeholder="Select Invocie Bank"*/}
                                        {/*        isSearchable={true}*/}
                                        {/*        defaultvalue={currencyInvoiceBankDescription ? { value: currencyInvoiceBank, label: currencyInvoiceBankDescription } : null}*/}
                                        {/*        options={BankAccounts.map(i => ({*/}
                                        {/*            value: i.bankId,*/}
                                        {/*            label: i.iban*/}
                                        {/*        }))}*/}
                                        {/*        valuekey*/}
                                        {/*        onChange={(e) => {*/}
                                        {/*            setCurrencyInvoiceBank(e.value)*/}
                                        {/*            setCurrencyInvoiceBankDescription(e.label)*/}
                                        {/*        }}>*/}
                                        {/*    </Select>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ManageCurrency;