import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import axiosInstance from '../../../api/axiosConfig';

const ManageCustomers = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [customerName, setCustomerName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [companies, setCompanies] = useState([]);

    const GetCustomer = (customerId) => {
        var config = {
            method: 'get',
            url: "Customer/GetCustomers/" + customerId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomerName(response.data.customerName)
                setPhoneNumber(response.data.phone)
                setAddress(response.data.address)
                setCompanyId(response.data.companyId)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetCompanies = () => {
        var config = {
            method: 'get',
            url: "Company/GetCompany",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCompanies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (IsValid()) {
            const body = { customerName: customerName,
                phone: phoneNumber,
                address: "",
                userId: localStorage.getItem("uTsReD"),
                companyId: localStorage.getItem("C0m8N!")
         }
            if (pId.CustomerId == "0") { //Add Customer
                var config = {
                    method: 'post',
                    url: "Customer/AddCustomer",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Customer Added Successfully")
                            navigate("/Accounting/Customer")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Customer
                var config = {
                    method: 'put',
                    url: "Customer/EditCustomer/" + pId.CustomerId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: body
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Customer Updated Successfully")
                            navigate("/Accounting/Customer")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        if (customerName == "" || phoneNumber == "") {
            toast.error("Enter all required fields");
            return false;
        }
        return true;
    }

    useEffect(() => {
        GetCompanies();
        if (pId.CustomerId != "0") {
            setPageType("Edit");
            GetCustomer(pId.CustomerId)
        }
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Clients</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Client</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Client</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mb-3 row">
                        <div className="col-12">
                            <ul className="nav nav-pills nav-justified" role="tablist">
                                <li className="nav-item waves-effect waves-light">
                                    <button className="nav-link active" data-bs-toggle="tab" role="tab" aria-selected="false" onClick={() => setTab(1)}>
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Customer Info</span>
                                    </button>
                                </li>
                                <li className="nav-item waves-effect waves-light">
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false" onClick={() => setTab(2)}>
                                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                        <span className="d-none d-sm-block">Customer Address</span>
                                    </button>
                                </li>
                                <li className="nav-item waves-effect waves-light">
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false" onClick={() => setTab(3)}>
                                        <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                        <span className="d-none d-sm-block">Customer Accounts</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    {/* {tab == 1 && ( */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Client</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Accounting/Customer" className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Client Name</label>
                                            <input className="form-control" type="text" value={customerName} onChange={(e) => setCustomerName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-6 col-form-label">Phone Number</label>
                                            <input className="form-control" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                    {/* {tab == 2 && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        {showCustomerList == false ? (<ListAddresses customerId={pId.CustomerId} setShowCustomerList={setShowCustomerList} setCustomerAddressId={setCustomerAddressId} />) : (<ManageAddresses customerId={pId.CustomerId} setCustomerAddressId={setCustomerAddressId} customerAddressId={customerAddressId} setShowCustomerList={setShowCustomerList} companies={companies} />)}
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    {tab == 3 && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        {showCustomerAccountList == false ? (<ListAccountCustomer customerId={pId.CustomerId} setShowCustomerAccountList={setShowCustomerAccountList} setCustomerAccountId={setCustomerAccountId} />) : (<ManageAccountCustomer customerId={pId.CustomerId} setCustomerAccountId={setCustomerAccountId} customerAccountId={customerAccountId} setShowCustomerAccountList={setShowCustomerAccountList} companies={companies} />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        </>)
}
export default ManageCustomers