import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Variables/Global";
import moment from 'moment';
import axiosInstance from '../../../api/axiosConfig';

const ManageExpenses = () => {
    var pId = useParams();
    var navigate = useNavigate();
     
    const [pageType, setPageType] = useState("Add");
    const [expenses, setExpenses] = useState({
        companyId: localStorage.getItem("C0m8N!"),
        paymentTypeId: 0,
        paymentValue: 0,
        currencyId: 0,
        date: "",
        description: "",
        InvoiceFileURL: "",
        userId: localStorage.getItem("uTsReD"),
    })
    const [currencies, setCurrencies] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [expensesType, setExpensesType] = useState([])

    const GetProductExpenses = (expensesId) => {
        var config = {
            method: 'get',
            url: "ProductExpenses/GetExpenses/" + expensesId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setExpenses(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleExpnseUpdate = (e, index) => {
        switch (index) {
            case 0:
                setExpenses((prevState) => ({
                    ...prevState,
                    companyId: e
                }))
                break;
            case 1:
                setExpenses((prevState) => ({
                    ...prevState,
                    paymentTypeId: e
                }))
                break;
            case 2:
                setExpenses((prevState) => ({
                    ...prevState,
                    paymentValue: e
                }))
                break;
            case 3:
                setExpenses((prevState) => ({
                    ...prevState,
                    currencyId: e
                }))
                break;
            case 4:
                setExpenses((prevState) => ({
                    ...prevState,
                    date: new Date(e)
                }))
                break;
            case 5:
                setExpenses((prevState) => ({
                    ...prevState,
                    description: e
                }))
                break;
            case 6:
                setExpenses((prevState) => ({
                    ...prevState,
                    invoiceFileUrl: e
                }))
                break;
        }
    }

    // const GetCompanies = () => {
    //     var config = {
    //         method: 'get',
    //         url: "Company/GetCompany",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': Global.Host
    //         },
    //     };

    //     axiosInstance(config)
    //         .then(function (response) {
    //             setCompanies(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    const GetCurrencies = () => {
        var config = {
            method: 'get',
            url: "Currency/GetCurrencies",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCurrencies(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const GetExpensesTypes = () => {
        var config = {
            method: 'get',
            url: "ProductExpensesType/GetExpensesTypes",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setExpensesType(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const submitForm = (e) => {
        console.log(expenses)
        e.preventDefault();
        if (IsValid()) {
            if (pId.ExpensesId == "0") { //Add Product Expenses Type
                var config = {
                    method: 'post',
                    url: "ProductExpenses/AddExpense",
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: expenses
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Expenses Added Successfully")
                            navigate("/Accounting/Expenses")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
            else { // Update Product Expenses Type
                var config = {
                    method: 'put',
                    url: "ProductExpenses/EditExpenses/" + pId.ExpensesId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': Global.Host
                    },
                    data: expenses
                };

                axiosInstance(config)
                    .then(function (response) {
                        if (response.status === 200) {
                            toast.success("Expenses Updated Successfully")
                            navigate("/Accounting/Expenses")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error("Something Went Wrong")
                    });
            }
        }
    }

    const IsValid = () => {
        // if (expensesType == "" && companyId == "0") {
        //     toast.error("Enter all required fields");
        //     return false;
        // }
        // else if (expensesType == "") {
        //     toast.error("Enter Expenses Type");
        //     return false;
        // }
        // else if (companyId == "0") {
        //     toast.error("Select Company");
        //     return false;
        // }
        return true;
    }

    useEffect(() => {
        document.title = "Manage Expenses";
        GetCurrencies();
        // GetCompanies();
        GetExpensesTypes();
        if (pId.ExpensesId != "0") {
            setPageType("Edit");
            GetProductExpenses(pId.ExpensesId)
        }

        setExpenses((prevState) => ({
            ...prevState,
            date: new Date()
        }))
    }, [])
    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Expenses</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Expenses">Expenses</Link></li>
                                    <li className="breadcrumb-item active">{pageType} Expenses</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3 row">
                                        <div className="col-12 col-md-6">
                                            <h4 className="card-title">{pageType} Expenses</h4>
                                        </div>
                                        <div className="col-12 col-md-6 text-right">
                                            <Link to="/Accounting/Expenses" className="btn btn-secondary mr-3">Cancel</Link>
                                            <button className="btn btn-primary" onClick={(e) => submitForm(e)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-md-6">
                                            <label className="col-md-12 col-form-label">Expenses Type</label>
                                            <select className="form-control" value={expenses.paymentTypeId} onChange={(e) => handleExpnseUpdate(e.target.value, 1)} required>
                                                <option value="0" key="0">-- Select Expenses Type --</option>
                                                {expensesType.length > 0 && (
                                                    expensesType.map((c, index) => {
                                                        return (<>
                                                            <option value={c.expensesTypeId} key={index}>{c.expensesType1}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="txtSizeName" className="col-md-12 col-form-label">Expenses Value</label>
                                            <input className="form-control" type="number" step="0.01" value={expenses.paymentValue} id="txtSizeName" onChange={(e) => handleExpnseUpdate(e.target.value, 2)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="col-md-12 col-form-label">Expenses Currency</label>
                                            <select className="form-control" value={expenses.currencyId} onChange={(e) => handleExpnseUpdate(e.target.value, 3)} required>
                                                <option value="0" key="0">-- Select Expenses Currency --</option>
                                                {currencies.length > 0 && (
                                                    currencies.map((c, index) => {
                                                        return (<>
                                                            <option value={c.currencyId} key={index}>{c.currencySuffix}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="txtSizeName" className="col-md-12 col-form-label">Date</label>
                                            <input className="form-control" type="date" value={moment(expenses.date).format("yyyy-MM-DD")} id="txtSizeName" onChange={(e) => handleExpnseUpdate(e.target.value, 4)} required />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="txtSizeName" className="col-md-12 col-form-label">Invoice</label>
                                            <input className="form-control" type="file" value={expenses.invoiceFileURL} id="txtSizeName" onChange={(e) => handleExpnseUpdate(e.target.value, 6)} required />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label className="col-md-12 col-form-label">Company</label>
                                            <select className="form-control" value={expenses.companyId} onChange={(e) => handleExpnseUpdate(e.target.value, 0)} required>
                                                <option value="0" key="0">-- Select Company Name --</option>
                                                {companies.length > 0 && (
                                                    companies.map((c, index) => {
                                                        return (<>
                                                            <option value={c.companyId} key={index}>{c.companyName}</option>
                                                        </>)
                                                    })
                                                )}
                                            </select>
                                        </div> */}
                                        <div className="col-md-12">
                                            <label htmlFor="txtSizeName" className="col-md-12 col-form-label">Description</label>
                                            <textarea className="form-control" type="text" value={expenses.description} id="txtSizeName" onChange={(e) => handleExpnseUpdate(e.target.value, 5)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ManageExpenses;