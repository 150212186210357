import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Global } from '../../../Variables/Global';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { getDecodedToken } from '../../../utils/utils';
import axiosInstance from '../../../api/axiosConfig';

const ShippingLines = () =>{
     
    const [shippingLines, setShippingLines] = useState();

    const [search, setSearch] = useState("");

    const handleShippingLineEnableChange = (shippingLineId, enabled) => {
        // e.preventDefault();
        const body = { shippingLineId: shippingLineId, enabled: enabled }
        var config = {
            method: 'put',
            url: "ShippingLine/EditShippingLine/" + shippingLineId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: body
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Shipping Line Updated Successfully")
                    // navigate("/Logistics/ShippingCompany")
                    GetShippingLines();
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const columns = [
        {
            name: '#',
            selector: row => row.shippingLineId,
            width:"60px"
        },
        {
            name: 'Shipping Line',
            selector: row => row.name,
        },
        {
            name: 'Enabled',
            width:"170px",
            selector: row => {
                return (
                    <>
                        <div className="col-12" class="form-check form-switch form-switch-md ms-3" >
                                <input className="form-check-input" type="checkbox" id="customSwitch2" checked={row.enabled} 
                                onChange={(e) => handleShippingLineEnableChange(row.shippingLineId, e.target.checked)} disabled={permissions.editShippingLines == false} />
                        </div>
                    </>
                )
            },
        },
    ];

    const GetShippingLines = () => {
        var config = {
            method: 'get',
            url: "ShippingLine/GetShippingLines",
            headers: {},
            params: { search }
        };

        axiosInstance(config)
            .then(function (response) {
                setShippingLines(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [permissions, setPermissions] = useState([])
    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        document.title = "Shipping Lines";
        GetShippingLines();
    }, [search])
    return (
        <>
        {permissions.viewShippingLines == false ? (
                <>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>You Have No Permission</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                                <div className="col-sm-9">
                                    <div className="page-title-box">
                                        <h4>Shipping Lines</h4>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                            <li className="breadcrumb-item active">Shipping Lines</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='col-sm-3 text-right'>
                                    <div className='flex justify-end'>
                                        <input className='search form-control ml-1' placeholder='Search by shipping line name' type="search" name="search" onChange={(e) => setSearch(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                        <h4 className="card-title">List Shipping Lines</h4>
                                        </div>
                                    </div>
                                    
                                    <DataTable
                                        columns={columns}
                                        data={shippingLines}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>)}
        </>
    )
}
export default ShippingLines