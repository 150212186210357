var key = "Bhjw1YtjR3cbYl5FVTIIJUKDsPH2PtTI2ifeo0XvTU1hJ";
var CryptoJS = require("crypto-js");

export function formatDate(date) {
    return date.toISOString().substring(0, 10);
}
class Util {
    constructor() {
        this.key = key;
    }

    encrypt(text) {
        var encryptedtext = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            this.key
        ).toString();
        return encryptedtext;
    }

    decrypt(encryptedText) {
        var bytes = CryptoJS.AES.decrypt(encryptedText, this.key);
        var decryptedText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedText;
    }

    getSeconds(d1) {
        var d1 = new Date(d1);
        var d2 = new Date();
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return Math.floor((t2 - t1) / 1000);
    }

    getMinutes(d1) {
        var d1 = new Date(d1);
        var d2 = new Date();
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return Math.floor((t2 - t1) / (60 * 1000));
    }

    getHours(d1) {
        var d1 = new Date(d1);
        var d2 = new Date();
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        return Math.floor((t2 - t1) / (3600 * 1000));
    }
}

export default new Util();
