import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"
import { useParams } from "react-router-dom";
import { Global } from "../../../../../Variables/Global";
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FiLink, FiMinus, FiPlus } from "react-icons/fi";
import { UncontrolledTooltip } from "reactstrap";
import { getDecodedToken } from '../../../../../utils/utils';
import axiosInstance from '../../../../../api/axiosConfig';

const ListCommercialInvoice = (props) => {

    const pId = useParams();

    const columns = [
        {
            name: "Product",
            selector: row => {
                return (
                    <>
                        <div className="mt-2 mb-2">
                             <span className="text-wrap">{row.productName + ", " + row.productSizeName + ", " + row.productTypeName}</span>
                        </div>
                    </>
                )
            },
        },
        {
            name: "FCL",
            selector: row => row.fcl
        },
        {
            name: "Qty",
            selector: row => row.quantity.toLocaleString()
        },
        {
            name: "Unit Price",
            selector: row => row.unitPrice.toLocaleString()
        },
        {
            name: "Total Price",
            selector: row => row.totalPrice.toLocaleString()
        },
        {
            name: "Commission",
            selector: row => (row.fcl * commissionPerFCL).toLocaleString()
        }
    ];


    const [showEditCommercialInvoice, setShowEditCommercialInvoice] = useState(false);

    const handleCloseEditCommercialInvoice = () => setShowEditCommercialInvoice(false);
    const handleShowEditCommercialInvoice = () => setShowEditCommercialInvoice(true);
    const [editingCommercialInvoiceId, setEditingCommercialInvoiceId] = useState(0);
    const [editingCommercialInvoice, setEditingCommercialInvoice] = useState({
        customerCommercialInvoice: "",
        userId: localStorage.getItem("uTsReD"),
        customerCommercialInvoiceDate: "",
        notes: "",
    })

    const [commissionPerFCL, setCommissionPerFCL] = useState([])
    const GetCustomerOrder = (orderId) => {
        var config = {
            method: 'get',
            url: "CustomerOrder/GetCustomerOrder/" + orderId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCommissionPerFCL(response.data.commission);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const EditCustomerCommercialInvoice = (e) => {
        e.preventDefault();
        // if (validateForm() == true) {
        var config = {
            method: 'put',
            url: "CustomerCommercialInvoice/EditCustomerCommercialInvoice/" + editingCommercialInvoiceId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: editingCommercialInvoice
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    // GetCustomerOrderProducts(pId.OrderId);
                    props.GetCommercialInvoices(pId.OrderId);
                    toast.success("Edited Successfully")
                    setShowEditCommercialInvoice(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
        // }
    }

    const submitDeleteInvoiceForm = (e) => {
        e.preventDefault();
            var config = {
                method: 'delete',
                url: "CustomerCommercialInvoice/DeleteCustomerCommercialInvoice/" + deletingCommercialInvoiceId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        props.GetCommercialInvoices(pId.OrderId);
                        toast.success("Invoice deleted Successfully")
                        setShowDeleteCommercialInvoice(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
    }

    const submitAddFreightToAccountForm = (e) => {
        e.preventDefault();
            var config = {
                method: 'put',
                url: "CustomerCommercialInvoice/AddFreightToClientAccount",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                params: {
                    'freightId': addingFreightId,
                    'customerCommercialInvoiceId': addingToCommercialInvoiceId
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        props.GetCommercialInvoices(pId.OrderId);
                        toast.success("Freight added successfully to client account.")
                        handleCloseAddFreightToClientAccountModal();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
    }

    const submitRemoveFreightFromAccountForm = (e) => {
        e.preventDefault();
            var config = {
                method: 'delete',
                url: "CustomerCommercialInvoice/DeleteFreightFromClientAccount",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                params: {
                    'customerCommercialInvoiceId': removingFreightFromCommercialInvoiceId
                }
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {
                        props.GetCommercialInvoices(pId.OrderId);
                        toast.success("Freight removed successfully from client account.")
                        handleCloseRemoveFreightFromClientAccountModal();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Something Went Wrong")
                });
    }

    const [deletingCommercialInvoice, setDeletingCommercialInvoice] = useState("");
    const [deletingCommercialInvoiceId, setDeletingCommercialInvoiceId] = useState(0);
    const [showDeleteCommercialInvoice, setShowDeleteCommercialInvoice] = useState(false);
    const handleCloseDeleteCommercialInvoice = () => {
        setDeletingCommercialInvoiceId(0);
        setShowDeleteCommercialInvoice(false);
    }
    const [addingFreightId, setAddingFreightId] = useState(0);
    const [addingToCommercialInvoiceId, setAddingToCommercialInvoiceId] = useState(0);
    const [addingFreightNumber, setAddingFreightNumber] = useState('');
    const [showAddFreightToClientAccount, setShowAddFreightToClientAccount] = useState(false);

    const [removingFreightNumber, setRemovingFreightNumber] = useState('');
    const [removingFreightFromCommercialInvoiceId, setRemovingFreightFromCommercialInvoiceId] = useState(0);
    const [showRemoveFreightFromClientAccount, setShowRemoveFreightFromClientAccount] = useState(false);
    
    

    const handleCloseAddFreightToClientAccountModal = () => {
        setShowAddFreightToClientAccount(false);
        setAddingFreightId(0);
        setAddingToCommercialInvoiceId(0);
        setAddingFreightNumber('');
    }

    const handleCloseRemoveFreightFromClientAccountModal = () => {
        setRemovingFreightFromCommercialInvoiceId(0);
        setRemovingFreightNumber('');
        setShowRemoveFreightFromClientAccount(false);
    }

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetCustomerOrder(pId.OrderId);
        // GetCommercialInvoices(pId.OrderId);
        // setCustomerCommercialInvoice(props.customerCommercialInvoice);
    }, []);

    return (
        <>
            {props.customerCommercialInvoice.map((s) => (
                <div className="card">
                    <div className="card-body">
                        <div className="col-12 mb-3">
                            <div className="items-center mt-2">
                                <span>
                                    <div>
                                    <strong>#{s.customerCommercialInvoiceNumber}</strong> - {s.customerCommercialInvoiceDate.split('T')[0]}
                                    
                                    {permissions.editCustomer === true &&
                                        <>
                                            <button type="button" className="btn btn-warning  btn-sm ml-1" onClick={() => {
                                                setEditingCommercialInvoiceId(s.customerCommercialInvoiceId);
                                                setEditingCommercialInvoice((prevState) => ({
                                                    ...prevState,
                                                    customerCommercialInvoice: s.customerCommercialInvoiceNumber,
                                                    customerCommercialInvoiceDate: s.customerCommercialInvoiceDate.split('T')[0],
                                                    notes: s.notes,
                                                }));
                                                
                                                handleShowEditCommercialInvoice();
                                            }}>
                                                <FiEdit />
                                            </button>


                                            <button type="button" className="btn btn-danger btn-sm ml-1" onClick={() => {
                                                // DeleteCustomerOrderProduct(row.customerOrderProduct.customerOrderProductId);
                                                setDeletingCommercialInvoiceId(s.customerCommercialInvoiceId);
                                                setDeletingCommercialInvoice(s.customerCommercialInvoiceNumber);
                                                setShowDeleteCommercialInvoice(true);
                                            }}>
                                                <FiTrash2 />
                                            </button>
                                        </>
                                    }
                                </div>
                                {/* <br/> */}
                                <strong>Total: </strong>{s.totalPrice.toLocaleString()} {props.currencySuffix}
                                {
                                    s.notes != null && s.notes != "" && <>
                                    <br/>
                                        <strong>Notes:</strong> {s.notes}
                                    </>
                                }
                                <br/>
                                <strong>Supplier: </strong>{s.supplierName} <strong>(#{s.supplierCommercialInvoice})</strong>
                                {
                                    s.hasFreightId != null && s.hasFreightId > 0 && 
                                    <>
                                        <br/>
                                        {
                                            s.linkedFreightId != null && s.linkedFreightId > 0 ?
                                            <>
                                                <strong>Freight:</strong> #{s.freightInvoiceNumber} <strong>({s.freightAmount.toLocaleString()} {props.currencySuffix})</strong>
                                                <button onClick={() => {
                                                    setRemovingFreightFromCommercialInvoiceId(s.customerCommercialInvoiceId);
                                                    setRemovingFreightNumber(s.freightInvoiceNumber);
                                                    setShowRemoveFreightFromClientAccount(true);
                                                }} className="btn btn-outline-danger btn-sm ml-1" data-toggle="tooltip" data-placement="top" title="Remove Freight from Client Account"><b><FiMinus /></b></button>
                                            </>
                                            :
                                            <>
                                                <br/>
                                                <i>Freight invoice available <strong>#{s.freightInvoiceNumber}</strong> ({s.freightAmount.toLocaleString()} {props.currencySuffix})</i>
                                                  <button onClick={() => {
                                                    setAddingFreightId(s.hasFreightId);
                                                    setAddingToCommercialInvoiceId(s.customerCommercialInvoiceId);
                                                    setAddingFreightNumber(s.freightInvoiceNumber);
                                                    setShowAddFreightToClientAccount(true);
                                                }} className="btn btn-outline-primary btn-sm ml-1" data-toggle="tooltip" data-placement="top" title="Add Freight to Client Account"><b><FiPlus /></b></button>
                                          
                                            </>
                                        }
                                    </>
                                }

                                <DataTable  data={s.products} columns={columns}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            ))}

            <Modal show={showEditCommercialInvoice} onHide={handleCloseEditCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label>Invoice Number</label>
                            <input className="form-control"
                             placeholder="Invoice Number"
                             value={editingCommercialInvoice.customerCommercialInvoice}
                             onChange={(e) => setEditingCommercialInvoice((prevState) => ({
                                ...prevState, customerCommercialInvoice: e.target.value
                            }))}></input>
                        </div>
                        {/* <div className="col-12 mb-3">
                            <label>Supplier Commercial Invoice</label>
                            <Select
                                placeholder="-- Select Commercial Invoice --"
                                isSearchable={true}
                                options={commercialInvoices.map(x => ({
                                    value: x,
                                    label: x.invoiceNumber
                                }))}
                                valuekey
                                onChange={(e) => {
                                    setEditingCommercialInvoice((prevState) => ({
                                        ...prevState, 
                                        supplierCommercialInvoiceId: e.value.commercialInvoiceId,
                                        customerCommercialInvoiceDate: e.value.date.split('T')[0]
                                    }))
                                }}></Select>
                        </div> */}
                        <div className="col-12 mb-3">
                            <label>Date</label>
                            <input className="form-control" type="date" 
                                value={editingCommercialInvoice.customerCommercialInvoiceDate} 
                                onChange={(e) => setEditingCommercialInvoice((prevState) => ({
                                    ...prevState, customerCommercialInvoiceDate: e.target.value
                                }))}></input>
                        </div>
                        <div className="col-12 mb-3">
                            <label>Notes</label>
                            <textarea className="form-control"
                                placeholder="Notes"
                                value={editingCommercialInvoice.notes}
                                onChange={(e) => setEditingCommercialInvoice((prevState) => ({
                                    ...prevState, notes: e.target.value
                                }))}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditCommercialInvoice}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => EditCustomerCommercialInvoice(e)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md"  show={showDeleteCommercialInvoice} onHide={handleCloseDeleteCommercialInvoice}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Commercial Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to delete commercial invoice #{deletingCommercialInvoice}?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteCommercialInvoice}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => submitDeleteInvoiceForm(e)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md"  show={showAddFreightToClientAccount} onHide={handleCloseAddFreightToClientAccountModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Freight to Client Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to add freight <strong>#{addingFreightNumber}</strong> to the client account?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddFreightToClientAccountModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => submitAddFreightToAccountForm(e)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="md"  show={showRemoveFreightFromClientAccount} onHide={handleCloseRemoveFreightFromClientAccountModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Freight from Client Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <div className="col-12">
                            <label>Are you sure you want to remove freight <strong>#{removingFreightNumber}</strong> from the client account?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRemoveFreightFromClientAccountModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={(e) => submitRemoveFreightFromAccountForm(e)}>
                        Yes, Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ListCommercialInvoice