import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component"
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Variables/Global";
import { formatDate } from "../../../../../Classes/Util";
import axiosInstance from '../../../../../api/axiosConfig';
import { getDecodedToken } from '../../../../../utils/utils';

const ListOrderAdvancedDiscount = (props) => {

    const pId = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [permissions, setPermissions] = useState([]);

    const [discounts, setDiscounts] = useState([]);
    const [discount, setDiscount] = useState({
        customerAccountDiscount: 0,
        createdDate: formatDate(new Date()),
        notes: "",
        accountId: pId.AccountId,
        userId: localStorage.getItem("uTsReD"),
    })
    const columns = [
        {
            name: '#',
            selector: row => row.customerAccountDiscountId,
            width: "60px"
        },
        {
            name: 'Discount Amount',
            selector: row => row.customerAccountDiscount1.toLocaleString(),
        },
        {
            name: 'Date',
            selector: row => row.createdDate.split("T")[0],
        },
        {
            name: 'Notes',
            selector: row => row.notes,
        },
        {
            name: 'Options',
            width: "170px",
            selector: row => {
                return (
                    <>
                        {/* <Link to={"/Accounting/Customer/" + props.CustomerId + "/Account/" + props.CustomerAccountId + "/Order/" + row.CustomerOrderId} className="btn btn-outline-success mr-3"><FiEye /></Link>
                        <button onClick={() => { props.setCustomerOrderId(row.CustomerOrderId); props.setShowCustomerOrderList(2) }} className="btn btn-outline-warning mr-3">
                            <FiEdit />
                        </button>
                        <button type="button" className="btn btn-outline-danger">
                            <FiTrash2 />
                        </button> */}
                    </>
                )
            },
        },
    ];

    const [account, setAccount] = useState({
        customerAccount1: ""
    });
    const GetAccount = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccount/GetCustomerAccount/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                console.log(response.data)
                setAccount(response.data)
                // GetCurrency(response.data.currencyId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [customer, setCustomer] = useState({
        customerName: ""
    })
    const GetCustomer = (CustomerId) => {
        var config = {
            method: 'get',
            url: "Customer/GetCustomers/" + CustomerId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setCustomer(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const GetAdvancedDiscount = (accountId) => {
        var config = {
            method: 'get',
            url: "CustomerAccountDiscounts/GetCustomerAccountDiscounts/" + accountId,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
        };

        axiosInstance(config)
            .then(function (response) {
                setDiscounts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const AddAdvancedDiscount = (e) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: "CustomerAccountDiscounts/AddCustomerAccountDiscounts",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': Global.Host
            },
            data: discount
        };

        axiosInstance(config)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Added Successfully")
                    setShow(false)
                    GetAdvancedDiscount(pId.AccountId)
                    AddCustomerAccountAdvancedDiscount();
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Something Went Wrong")
            });
    }

    const [customerAccountAdvancedDiscount, setCustomerAccountAdvancedDiscount] = useState({
        performa: "",
        customerAdvancedDiscount: 0,
        userId: localStorage.getItem("uTsReD"),
        notes: "",
        date: formatDate(new Date()),
    })

    const AddCustomerAccountAdvancedDiscount = () => {
        setTimeout(() => {
            var config = {
                method: 'post',
                url: "CustomerAccountStatment/AddCustomerOrderAdvancedDiscount/" + pId.AccountId,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': Global.Host
                },
                data: customerAccountAdvancedDiscount
            };

            axiosInstance(config)
                .then(function (response) {
                    if (response.status === 200) {

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, 1000);
    }

    useEffect(() => {
        const decodedToken = getDecodedToken();
        const pJoin = JSON.stringify(JSON.parse(decodedToken.pJoin));
        var x = JSON.parse(pJoin);
        setPermissions(() => x);
        GetAdvancedDiscount(pId.AccountId);
        GetAccount(pId.AccountId);
        GetCustomer(pId.CustomerId);
    }, [])

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4>Client Account</h4>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Mercator</a></li>
                                    <li className="breadcrumb-item"><Link to="/Accounting/Customer">Clients</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Customer/" + customer.customerId + "/Account/" + account.customerAccountId + "/Statment"}>{customer.customerName} - {account.customerAccount1}</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Discounts</li>
                                    {/* <li className="breadcrumb-item">
                                        <Link to={"/Accounting/Supplier/View/" + pId.SupplierId}>{supplierName}</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                                    <li className="breadcrumb-item active">{account.supplierAccount1}</li> */}
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <h4 className="card-title">List Advanced Discounts for {customer.customerName} - {account.customerAccount1}</h4>
                                        </div>
                                        <div className='col-12 col-md-6 text-right'>
                                            {/* <Link to={"/Accounting/Customer/" + pId.CustomerId + "/Account/" + pId.AccountId + "/Statment"} className="btn btn-secondary mr-3">Cancel</Link> */}
                                            {permissions.addCustomer === true && (
                                                <button className="btn btn-primary" onClick={handleShow}>Add Discount</button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <DataTable
                                                columns={columns}
                                                data={discounts} />
                                        </div>
                                    </div>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add Discount</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-12">
                                                    <label>Discount Amount</label>
                                                    <input type="number" className="form-control mb-3" required
                                                        placeholder="Enter the discount amount"
                                                        onChange={(e) => {
                                                            setDiscount((prevState) => ({
                                                                ...prevState,
                                                                customerAccountDiscount: e.target.value
                                                            }));
                                                            setCustomerAccountAdvancedDiscount((prevState) => ({
                                                                ...prevState,
                                                                customerAdvancedDiscount: e.target.value
                                                            }))
                                                        }}>
                                                    </input>
                                                </div>
                                                <div className="col-12">
                                                    <label>Date</label>
                                                    <input className="form-control mb-3" type="date"
                                                        value={discount.createdDate}
                                                        onChange={(e) => {
                                                            setDiscount((prevState) => ({
                                                                ...prevState,
                                                                createdDate: e.target.value
                                                            }));
                                                            setCustomerAccountAdvancedDiscount((prevState) => ({
                                                                ...prevState,
                                                                date: e.target.value
                                                            }))
                                                        }}></input>
                                                </div>
                                                <div className="col-12">
                                                    <label>Notes</label>
                                                    <textarea className="form-control"
                                                        onChange={(e) => {
                                                            setDiscount((prevState) => ({
                                                                ...prevState,
                                                                notes: e.target.value
                                                            }));
                                                            setCustomerAccountAdvancedDiscount((prevState) => ({
                                                                ...prevState,
                                                                notes: e.target.value
                                                            }))
                                                        }}></textarea>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-secondary" onClick={handleClose}>
                                                Close
                                            </button>
                                            <button className="btn btn-primary" onClick={(e) => AddAdvancedDiscount(e)}>
                                                Save Changes
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
export default ListOrderAdvancedDiscount